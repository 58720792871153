import { Component, ElementRef, EventEmitter, Injector, OnDestroy, OnInit, Output } from '@angular/core';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { CommonUtil } from '../../../common/util/common.util';

@Component({
  selector: 'workbook-confirm-delete',
  styleUrls: ['workbook-confirm-delete.scss'],
  templateUrl: './workbook-confirm-delete.html',
})
export class WorkbookConfirmDelete extends AbstractComponent implements OnInit, OnDestroy {
  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  @Output() confirmDelete = new EventEmitter<boolean>();

  public confirmDeleteDel() {
    this.confirmDelete.emit(true);
  }

  public confirmDeleteCancel() {
    this.confirmDelete.emit(false);
  }
}
