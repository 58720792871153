<div
  *ngIf="widget"
  class="ddp-layout-chart page-chart"
  [ngClass]="{ 'ddp-page-mapview': selectChart == 'map' }"
  style="top: 0; left: 0; right: 0; bottom: 0; animation-duration: unset; animation: none"
>
  <div class="ddp-layout-contents2">
    <div class="ddp-wrap-chart">
      <div class="ddp-wrap-chart-lnb">
        <div class="ddp-view-chart-lnb">
          <div class="ddp-ui-chart-lnb">
            <div class="ddp-wrap-dropmenu">
              <div class="ddp-ui-dropmenu" [class.ddp-selected]="'data' === dataLayerKey">
                <div class="ddp-ui-drop-title" (click)="clickDataPanel('data')">
                  {{ 'msg.page.ui.panel.title' | translate }}
                  <span class="ddp-data-det">
                    {{
                      'msg.page.ui.num.dimensions.measures'
                        | translate
                          : {
                              dimensions: getCntShelfItem('DIMENSION'),
                              measures: getCntShelfItem('MEASURE')
                            }
                    }}
                  </span>
                  <em class="ddp-icon-drop-view"></em>
                </div>

                <div class="ddp-ui-drop-contents">
                  <div class="ddp-wrap-source-name">
                    <dashboard-datasource-combo
                      [enableInfo]="true"
                      [dataSources]="dataSourceList"
                      [initialValue]="dataSource"
                      (selectOption)="selectDataSource($event, true)"
                      (showInfo)="onDataPreviewPopup()"
                    ></dashboard-datasource-combo>
                  </div>

                  <div class="ddp-ui-drop-option">
                    <div class="ddp-form-search">
                      <em class="ddp-icon-search"></em>
                      <component-input
                        [compType]="'search'"
                        [showClear]="true"
                        [immediately]="true"
                        [value]="fieldSearchText"
                        [placeHolder]="'msg.page.ui.search.ph' | translate"
                        (changeValue)="fieldSearchText = $event; setFieldTotalPage()"
                      ></component-input>
                    </div>
                  </div>

                  <div class="ddp-wrap-drop-submenu" [class.ddp-selected]="isDataDimensionLayerShow">
                    <div class="ddp-ui-sub-title" (click)="isDataDimensionLayerShow = !isDataDimensionLayerShow">
                      <div class="ddp-ui-sub-titlein ddp-dimension">
                        <em class="ddp-icon-menumove"></em>
                        {{ 'msg.page.th.dimension' | translate }}
                        <a
                          href="javascript:"
                          (click)="$event.stopPropagation(); openCustomFieldPopup(null, 'DIMENSION')"
                          class="ddp-icon-add"
                        ></a>
                      </div>
                    </div>

                    <div class="ddp-wrap-list-source">
                      <ul
                        class="ddp-list-source ddp-list-dimension"
                        [dragula]="'dragbag'"
                        [dragulaModel]="pageDimensions"
                      >
                        <li
                          class="ddp-ui-dimension"
                          *ngFor="let field of pageDimensions; let idx = index"
                          (click)="onPivotSelect(field, true)"
                          [style.display]="isContainSearchText(fieldSearchText, field.name) ? 'block' : 'none'"
                          [attr.data-name]="field.name"
                          [attr.data-source]="'dimension'"
                          [class.ddp-selected]="fieldDetailLayer == field && showFieldIconsFl"
                        >
                          <em
                            *ngIf="'network' !== selectChart && 'map' !== selectChart"
                            [class.ddp-icon-columns]="field.pivot && field.pivot.length > 0 && field.pivot[0] == 'ROWS'"
                            [class.ddp-icon-rows]="field.pivot && field.pivot.length > 0 && field.pivot[0] == 'COLUMNS'"
                            [class.ddp-icon-cross]="
                              field.pivot && field.pivot.length > 0 && field.pivot[0] == 'AGGREGATIONS'
                            "
                            class="type-apply"
                          ></em>

                          <em
                            *ngIf="'network' === selectChart"
                            [class.icon-source]="field.pivot && field.pivot.length > 0 && field.pivot[0] == 'ROWS'"
                            [class.icon-target]="field.pivot && field.pivot.length > 0 && field.pivot[0] == 'COLUMNS'"
                            [class.icon-link]="
                              field.pivot && field.pivot.length > 0 && field.pivot[0] == 'AGGREGATIONS'
                            "
                            class="ddp-icon-network"
                          ></em>

                          <em
                            *ngIf="'map' === selectChart"
                            [class.ddp-icon-mapview1]="
                              field.pivot && field.pivot.length > 0 && 'MAP_LAYER0' === field.pivot[0]?.toString()
                            "
                            [class.ddp-icon-mapview2]="
                              field.pivot && field.pivot.length > 0 && 'MAP_LAYER1' === field.pivot[0]?.toString()
                            "
                            [class.ddp-icon-mapview3]="
                              field.pivot && field.pivot.length > 0 && 'MAP_LAYER2' === field.pivot[0]?.toString()
                            "
                          ></em>

                          <em
                            class="ddp-icon-dimension-ab"
                            *ngIf="field.logicalType?.toString() === 'STRING' || field.type === 'user_expr'"
                          ></em>

                          <em class="ddp-icon-dimension-array" *ngIf="field.logicalType?.toString() === 'ARRAY'"></em>

                          <em class="ddp-icon-dimension-latitude" *ngIf="field.logicalType?.toString() === 'LNT'"></em>
                          <em class="ddp-icon-dimension-longitude" *ngIf="field.logicalType?.toString() === 'LNG'"></em>

                          <em
                            class="ddp-icon-dimension-calen"
                            *ngIf="field.logicalType?.toString() === 'TIMESTAMP'"
                          ></em>

                          <em
                            class="ddp-icon-dimension-float"
                            *ngIf="
                              field.logicalType?.toString() === 'DOUBLE' || field.logicalType?.toString() === 'INTEGER'
                            "
                          ></em>

                          <em class="ddp-icon-dimension-tf" *ngIf="field.logicalType?.toString() === 'BOOLEAN'"></em>

                          <em
                            class="ddp-icon-dimension-point ddp-icon-map-view"
                            *ngIf="field.logicalType?.toString() === 'GEO_POINT'"
                          ></em>

                          <em
                            class="ddp-icon-dimension-line ddp-icon-map-view"
                            *ngIf="field.logicalType?.toString() === 'GEO_LINE'"
                          ></em>

                          <em
                            class="ddp-icon-dimension-polygon ddp-icon-map-view"
                            *ngIf="field.logicalType?.toString() === 'GEO_POLYGON'"
                          ></em>

                          <div class="ddp-wrap-icons">
                            <div class="ddp-wrap-user-preview" *ngIf="field.type === 'user_expr'">
                              <em
                                class="ddp-icon-control-user ddp-type"
                                (click)="$event.stopPropagation(); openCustomFieldPopup(field)"
                              ></em>
                              <div class="ddp-ui-tooltip-info">
                                <em class="ddp-icon-view-top"></em>
                                <span class="ddp-txt-tooltip">{{ unescapeCustomColumnExpr(field.expr) }}</span>
                              </div>
                            </div>

                            <em
                              class="ddp-icon-control-filter ddp-type"
                              [ngClass]="{ 'ddp-selected': field.useFilter }"
                              (click)="toggleFilter(field, $event)"
                            ></em>

                            <div class="ddp-btn-control">
                              <em
                                class="ddp-icon-control-more ddp-type"
                                (click)="openFieldDetailLayer($event, field)"
                              ></em>
                            </div>
                          </div>

                          <span
                            class="ddp-data-name"
                            [class.ddp-alias]="field['nameAlias'] && field['nameAlias']['nameAlias'] != field.name"
                          >
                            <span class="ddp-data-in">
                              {{ field.name }}
                              <em
                                *ngIf="field['nameAlias'] && field['nameAlias']['nameAlias'] != field.name"
                                class="ddp-icon-alias"
                                title="{{ field['nameAlias']['nameAlias'] }}"
                              >
                                ({{ field['nameAlias']['nameAlias'] }})
                              </em>
                            </span>
                          </span>
                        </li>
                      </ul>

                      <div class="ddp-ui-page">
                        <a
                          (click)="$event.stopPropagation(); this.fieldPrev(true)"
                          [class.ddp-disabled]="dimensionPage == 1"
                          href="javascript:"
                          class="ddp-prev"
                        >
                          <em><</em>
                          {{ 'msg.comm.btn.previous' | translate }}
                        </a>
                        <a
                          (click)="$event.stopPropagation(); this.fieldNext(true)"
                          [class.ddp-disabled]="dimensionPage == dimensionTotalPage"
                          href="javascript:"
                          class="ddp-next"
                        >
                          {{ 'msg.comm.btn.next' | translate }}
                          <em>></em>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="ddp-wrap-drop-submenu" [class.ddp-selected]="isDataMeasureLayerShow">
                    <div class="ddp-ui-sub-title" (click)="isDataMeasureLayerShow = !isDataMeasureLayerShow">
                      <div class="ddp-ui-sub-titlein ddp-measure">
                        <em class="ddp-icon-menumove"></em>
                        {{ 'msg.page.th.measure' | translate }}
                        <a
                          href="javascript:"
                          (click)="$event.stopPropagation(); openCustomFieldPopup(null, 'MEASURE')"
                          class="ddp-icon-add"
                        ></a>
                      </div>
                    </div>

                    <div class="ddp-wrap-list-source">
                      <ul class="ddp-list-source ddp-list-measure" [dragula]="'dragbag'" [dragulaModel]="pageMeasures">
                        <li
                          class="ddp-ui-measure"
                          style="display: flex"
                          *ngFor="let field of pageMeasures"
                          (click)="onPivotSelect(field, false)"
                          [style.display]="isContainSearchText(fieldSearchText, field.name) ? 'block' : 'none'"
                          [attr.data-name]="field.name"
                          [attr.data-source]="'measure'"
                          [class.ddp-selected]="fieldDetailLayer == field && showFieldIconsFl"
                        >
                          <em
                            *ngIf="'network' !== selectChart && 'map' !== selectChart"
                            [class.ddp-icon-columns]="field.pivot && field.pivot.length > 0 && field.pivot[0] == 'ROWS'"
                            [class.ddp-icon-rows]="field.pivot && field.pivot.length > 0 && field.pivot[0] == 'COLUMNS'"
                            [class.ddp-icon-cross]="
                              field.pivot && field.pivot.length > 0 && field.pivot[0] == 'AGGREGATIONS'
                            "
                            class="type-apply"
                          ></em>

                          <em
                            *ngIf="'network' === selectChart"
                            [class.icon-source]="field.pivot && field.pivot.length > 0 && field.pivot[0] == 'ROWS'"
                            [class.icon-target]="field.pivot && field.pivot.length > 0 && field.pivot[0] == 'COLUMNS'"
                            [class.icon-link]="
                              field.pivot && field.pivot.length > 0 && field.pivot[0] == 'AGGREGATIONS'
                            "
                            class="ddp-icon-network"
                          ></em>

                          <em
                            *ngIf="'map' === selectChart"
                            [class.ddp-icon-mapview1]="
                              field.pivot && field.pivot.length > 0 && 'MAP_LAYER0' === field.pivot[0]?.toString()
                            "
                            [class.ddp-icon-mapview2]="
                              field.pivot && field.pivot.length > 0 && 'MAP_LAYER1' === field.pivot[0]?.toString()
                            "
                            [class.ddp-icon-mapview3]="
                              field.pivot && field.pivot.length > 0 && 'MAP_LAYER2' === field.pivot[0]?.toString()
                            "
                          ></em>

                          <em class="ddp-icon-dimension-ab" *ngIf="field.logicalType?.toString() === 'STRING'"></em>
                          <em
                            class="ddp-icon-dimension-local"
                            *ngIf="field.logicalType?.toString() === 'LNG' || field.logicalType?.toString() === 'LNT'"
                          ></em>
                          <em
                            class="ddp-icon-dimension-calen"
                            *ngIf="field.logicalType?.toString() === 'TIMESTAMP'"
                          ></em>
                          <em class="ddp-icon-measure-array" *ngIf="field.logicalType?.toString() === 'ARRAY'"></em>
                          <em
                            class="ddp-icon-measure-sharp"
                            *ngIf="
                              field.logicalType?.toString() === 'DOUBLE' ||
                              field.logicalType?.toString() === 'INTEGER' ||
                              field.type === 'user_expr'
                            "
                          ></em>

                          <em class="ddp-icon-measure-tf" *ngIf="field.logicalType?.toString() === 'BOOLEAN'"></em>

                          <div class="ddp-wrap-icons" (clickOutside)="showFieldIconsFl = false">
                            <div class="ddp-wrap-user-preview" *ngIf="field.type === 'user_expr'">
                              <em
                                class="ddp-icon-control-calculat ddp-type ddp-selected"
                                (click)="$event.stopPropagation(); openCustomFieldPopup(field)"
                              ></em>
                              <div class="ddp-ui-tooltip-info">
                                <em class="ddp-icon-view-top"></em>
                                <span class="ddp-txt-tooltip">{{ field.expr }}</span>
                              </div>
                            </div>

                            <em
                              *ngIf="!isCustomMeasureField(field)"
                              class="ddp-icon-control-filter ddp-type"
                              [ngClass]="{ 'ddp-selected': field.useFilter, 'ddp-disabled': field.aggregated }"
                              (click)="toggleFilter(field, $event)"
                            ></em>

                            <div class="ddp-btn-control">
                              <em
                                class="ddp-icon-control-more ddp-type"
                                (click)="openFieldDetailLayer($event, field)"
                              ></em>
                            </div>
                          </div>

                          <span
                            class="ddp-data-name"
                            [class.ddp-alias]="
                              'user_expr' !== field.type &&
                              field['nameAlias'] &&
                              field['nameAlias']['nameAlias'] != field.name
                            "
                          >
                            <span class="ddp-data-in">
                              {{ field.name }}
                              <em
                                *ngIf="
                                  'user_expr' !== field.type &&
                                  field['nameAlias'] &&
                                  field['nameAlias']['nameAlias'] != field.name
                                "
                                class="ddp-icon-alias"
                              >
                                ({{ field['nameAlias']['nameAlias'] }})
                              </em>
                            </span>
                          </span>
                        </li>
                      </ul>

                      <div class="ddp-ui-page">
                        <a
                          (click)="$event.stopPropagation(); this.fieldPrev(false)"
                          [class.ddp-disabled]="measurePage == 1"
                          href="javascript:"
                          class="ddp-prev"
                        >
                          <em><</em>
                          {{ 'msg.comm.btn.previous' | translate }}
                        </a>
                        <a
                          (click)="$event.stopPropagation(); this.fieldNext(false)"
                          [class.ddp-disabled]="measurePage == measureTotalPage"
                          href="javascript:"
                          class="ddp-next"
                        >
                          {{ 'msg.comm.btn.next' | translate }}
                          <em>></em>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="ddp-wrap-dropmenu">
              <div class="ddp-ui-dropmenu" [class.ddp-selected]="'chart' === dataLayerKey">
                <div class="ddp-ui-drop-title" (click)="clickDataPanel('chart')">
                  {{ 'msg.page.ui.chart' | translate }}

                  <span class="ddp-data-det">{{ getChartTypeTransLate(selectChart) }}</span>
                  <em class="ddp-icon-drop-view"></em>
                </div>
                <div class="ddp-ui-drop-contents">
                  <div class="ddp-ui-chart-list">
                    <ul class="ddp-list-chart-img">
                      <li
                        [class.ddp-recommend]="recommendCharts.indexOf('bar') > -1"
                        [class.ddp-selected]="selectChart == 'bar'"
                        (click)="selectChart = 'bar'"
                        (mouseenter)="showInfoChart = 'bar'"
                        (mouseleave)="showInfoChart = ''"
                      >
                        <a href="javascript:">
                          <em class="ddp-s-graph-bar"></em>
                        </a>
                      </li>
                      <li
                        [class.ddp-recommend]="recommendCharts.indexOf('grid') > -1"
                        [class.ddp-selected]="selectChart == 'grid'"
                        (click)="selectChart = 'grid'"
                        (mouseenter)="showInfoChart = 'grid'"
                        (mouseleave)="showInfoChart = ''"
                      >
                        <a href="javascript:">
                          <em class="ddp-s-graph-txt"></em>
                        </a>
                      </li>
                      <li
                        [class.ddp-recommend]="recommendCharts.indexOf('input') > -1"
                        [class.ddp-selected]="selectChart == 'input'"
                        (click)="selectChart = 'input'"
                        (mouseenter)="showInfoChart = 'input'"
                        (mouseleave)="showInfoChart = ''"
                      >
                        <a href="javascript:">
                          <em class="ddp-s-graph-txt"></em>
                        </a>
                      </li>
                      <li
                        [class.ddp-recommend]="recommendCharts.indexOf('line') > -1"
                        [class.ddp-selected]="selectChart == 'line'"
                        (click)="selectChart = 'line'"
                        (mouseenter)="showInfoChart = 'line'"
                        (mouseleave)="showInfoChart = ''"
                      >
                        <a href="javascript:">
                          <em class="ddp-s-graph-line"></em>
                        </a>
                      </li>
                      <li
                        [class.ddp-recommend]="recommendCharts.indexOf('scatter') > -1"
                        [class.ddp-selected]="selectChart == 'scatter'"
                        (click)="selectChart = 'scatter'"
                        (mouseenter)="showInfoChart = 'scatter'"
                        (mouseleave)="showInfoChart = ''"
                      >
                        <a href="javascript:">
                          <em class="ddp-s-graph-scatter"></em>
                        </a>
                      </li>
                      <li
                        [class.ddp-recommend]="recommendCharts.indexOf('heatmap') > -1"
                        [class.ddp-selected]="selectChart == 'heatmap'"
                        (click)="selectChart = 'heatmap'"
                        (mouseenter)="showInfoChart = 'heatmap'"
                        (mouseleave)="showInfoChart = ''"
                      >
                        <a href="javascript:">
                          <em class="ddp-s-graph-heatmap"></em>
                        </a>
                      </li>
                      <li
                        [class.ddp-recommend]="recommendCharts.indexOf('pie') > -1"
                        [class.ddp-selected]="selectChart == 'pie'"
                        (click)="selectChart = 'pie'"
                        (mouseenter)="showInfoChart = 'pie'"
                        (mouseleave)="showInfoChart = ''"
                      >
                        <a href="javascript:">
                          <em class="ddp-s-graph-pie"></em>
                        </a>
                      </li>
                      <li
                        [class.ddp-recommend]="recommendCharts.indexOf('map') > -1"
                        [class.ddp-selected]="selectChart == 'map'"
                        (click)="selectChart = 'map'"
                        (mouseenter)="showInfoChart = 'map'"
                        (mouseleave)="showInfoChart = ''"
                      >
                        <a href="javascript:">
                          <em class="ddp-s-graph-map"></em>
                        </a>
                      </li>

                      <li
                        [class.ddp-recommend]="recommendCharts.indexOf('label') > -1"
                        [class.ddp-selected]="selectChart == 'label'"
                        (click)="selectChart = 'label'"
                        (mouseenter)="showInfoChart = 'label'"
                        (mouseleave)="showInfoChart = ''"
                      >
                        <a href="javascript:">
                          <em class="ddp-s-graph-kpi"></em>
                        </a>
                      </li>
                      <li
                        [class.ddp-recommend]="recommendCharts.indexOf('boxplot') > -1"
                        [class.ddp-selected]="selectChart == 'boxplot'"
                        (click)="selectChart = 'boxplot'"
                        (mouseenter)="showInfoChart = 'boxplot'"
                        (mouseleave)="showInfoChart = ''"
                      >
                        <a href="javascript:">
                          <em class="ddp-s-graph-boxplot"></em>
                        </a>
                      </li>
                      <li
                        [class.ddp-recommend]="recommendCharts.indexOf('waterfall') > -1"
                        [class.ddp-selected]="selectChart == 'waterfall'"
                        (click)="selectChart = 'waterfall'"
                        (mouseenter)="showInfoChart = 'waterfall'"
                        (mouseleave)="showInfoChart = ''"
                      >
                        <a href="javascript:">
                          <em class="ddp-s-graph-waterfall"></em>
                        </a>
                      </li>
                      <li
                        [class.ddp-recommend]="recommendCharts.indexOf('wordcloud') > -1"
                        [class.ddp-selected]="selectChart == 'wordcloud'"
                        (click)="selectChart = 'wordcloud'"
                        (mouseenter)="showInfoChart = 'wordcloud'"
                        (mouseleave)="showInfoChart = ''"
                      >
                        <a href="javascript:">
                          <em class="ddp-s-graph-wordcloud"></em>
                        </a>
                      </li>
                      <li
                        [class.ddp-recommend]="recommendCharts.indexOf('combine') > -1"
                        [class.ddp-selected]="selectChart == 'combine'"
                        (click)="selectChart = 'combine'"
                        (mouseenter)="showInfoChart = 'combine'"
                        (mouseleave)="showInfoChart = ''"
                      >
                        <a href="javascript:">
                          <em class="ddp-s-graph-combo"></em>
                        </a>
                      </li>
                      <li
                        [class.ddp-recommend]="recommendCharts.indexOf('treemap') > -1"
                        [class.ddp-selected]="selectChart == 'treemap'"
                        (click)="selectChart = 'treemap'"
                        (mouseenter)="showInfoChart = 'treemap'"
                        (mouseleave)="showInfoChart = ''"
                      >
                        <a href="javascript:">
                          <em class="ddp-s-graph-treemap"></em>
                        </a>
                      </li>
                      <li
                        [class.ddp-recommend]="recommendCharts.indexOf('radar') > -1"
                        [class.ddp-selected]="selectChart == 'radar'"
                        (click)="selectChart = 'radar'"
                        (mouseenter)="showInfoChart = 'radar'"
                        (mouseleave)="showInfoChart = ''"
                      >
                        <a href="javascript:">
                          <em class="ddp-s-graph-radar"></em>
                        </a>
                      </li>
                      <li
                        [class.ddp-recommend]="recommendCharts.indexOf('network') > -1"
                        [class.ddp-selected]="selectChart == 'network'"
                        (click)="selectChart = 'network'"
                        (mouseenter)="showInfoChart = 'network'"
                        (mouseleave)="showInfoChart = ''"
                      >
                        <a href="javascript:">
                          <em class="ddp-s-graph-network"></em>
                        </a>
                      </li>
                      <li
                        [class.ddp-recommend]="recommendCharts.indexOf('gauge') > -1"
                        [class.ddp-selected]="selectChart == 'gauge'"
                        (click)="selectChart = 'gauge'"
                        (mouseenter)="showInfoChart = 'gauge'"
                        (mouseleave)="showInfoChart = ''"
                      >
                        <a href="javascript:">
                          <em class="ddp-s-graph-gauge"></em>
                        </a>
                      </li>
                      <li
                        [class.ddp-recommend]="recommendCharts.indexOf('sankey') > -1"
                        [class.ddp-selected]="selectChart == 'sankey'"
                        (click)="selectChart = 'sankey'"
                        (mouseenter)="showInfoChart = 'sankey'"
                        (mouseleave)="showInfoChart = ''"
                      >
                        <a href="javascript:">
                          <em class="ddp-s-graph-sankey"></em>
                        </a>
                      </li>
                    </ul>

                    <div class="ddp-ui-chart-info" *ngIf="isShowChartInfo('bar')">
                      <span class="ddp-ui-name">{{ 'msg.page.ui.bar' | translate }}</span>
                      <div class="ddp-data-det">
                        {{ 'msg.page.ui.row' | translate }} :
                        {{ 'msg.page.ui.more-than-one.dimension.value' | translate }}
                        <br />
                        {{ 'msg.page.ui.intersect' | translate }}
                        : {{ 'msg.page.ui.more-than-one.measured.value' | translate }}
                      </div>
                    </div>

                    <div class="ddp-ui-chart-info" *ngIf="isShowChartInfo('grid')">
                      <span class="ddp-ui-name">{{ 'msg.page.ui.text-table' | translate }}</span>
                      <div class="ddp-data-det">
                        {{ 'msg.page.ui.row-or-col' | translate }}
                        : {{ 'msg.page.ui.more-than-one.dimension.value' | translate }}
                        <br />
                        {{ 'msg.page.ui.intersect' | translate }}
                        : {{ 'msg.page.ui.more-than-one.measured.value' | translate }}
                      </div>
                    </div>

                    <div class="ddp-ui-chart-info" *ngIf="isShowChartInfo('line')">
                      <span class="ddp-ui-name">{{ 'msg.page.ui.line' | translate }}</span>
                      <div class="ddp-data-det">
                        {{ 'msg.page.ui.row' | translate }} :
                        {{ 'msg.page.ui.more-than-one.dimension.value' | translate }}
                        <br />
                        {{ 'msg.page.ui.intersect' | translate }}
                        : {{ 'msg.page.ui.more-than-one.measured.value' | translate }}
                      </div>
                    </div>

                    <div class="ddp-ui-chart-info" *ngIf="isShowChartInfo('scatter')">
                      <span class="ddp-ui-name">{{ 'msg.page.ui.scatter' | translate }}</span>
                      <div class="ddp-data-det">
                        {{ 'msg.page.ui.row' | translate }} : {{ 'msg.page.ui.one.measured.value' | translate }}
                        <br />
                        {{ 'msg.page.ui.col' | translate }} : {{ 'msg.page.ui.one.measured.value' | translate }}
                        <br />
                        {{ 'msg.page.ui.intersect' | translate }}
                        : {{ 'msg.page.ui.more-than-one.dimension.value' | translate }}
                      </div>
                    </div>

                    <div class="ddp-ui-chart-info" *ngIf="isShowChartInfo('heatmap')">
                      <span class="ddp-ui-name">{{ 'msg.page.ui.heat-map' | translate }}</span>
                      <div class="ddp-data-det">
                        {{ 'msg.page.ui.row-or-col' | translate }}
                        : {{ 'msg.page.ui.more-than-one.dimension.value' | translate }}
                        <br />
                        {{ 'msg.page.ui.intersect' | translate }} : {{ 'msg.page.ui.one.measured.value' | translate }}
                      </div>
                    </div>

                    <div class="ddp-ui-chart-info" *ngIf="isShowChartInfo('pie')">
                      <span class="ddp-ui-name">{{ 'msg.page.ui.pie' | translate }}</span>
                      <div class="ddp-data-det">
                        {{ 'msg.page.ui.intersect' | translate }} :
                        {{ 'msg.page.ui.one.dimension.value' | translate }} ,
                        {{ 'msg.page.ui.one.measured.value' | translate }}
                      </div>
                    </div>

                    <div class="ddp-ui-chart-info" *ngIf="isShowChartInfo('label')">
                      <span class="ddp-ui-name">{{ 'msg.page.ui.kpi' | translate }}</span>
                      <div class="ddp-data-det">
                        {{ 'msg.page.ui.intersect' | translate }}
                        : {{ 'msg.page.ui.more-than-one.measured.value' | translate }}
                      </div>
                    </div>

                    <div class="ddp-ui-chart-info" *ngIf="isShowChartInfo('boxplot')">
                      <span class="ddp-ui-name">{{ 'msg.page.ui.box-plot' | translate }}</span>
                      <div class="ddp-data-det">
                        {{ 'msg.page.ui.row' | translate }} :
                        {{ 'msg.page.ui.more-than-one.dimension.value' | translate }}
                        <br />
                        {{ 'msg.page.ui.col' | translate }} : {{ 'msg.page.ui.one.dimension.value' | translate }}
                        <br />
                        {{ 'msg.page.ui.intersect' | translate }} : {{ 'msg.page.ui.one.measured.value' | translate }}
                      </div>
                    </div>

                    <div class="ddp-ui-chart-info" *ngIf="isShowChartInfo('waterfall')">
                      <span class="ddp-ui-name">{{ 'msg.page.ui.water-fall' | translate }}</span>
                      <div class="ddp-data-det">
                        {{ 'msg.page.ui.row' | translate }} : {{ 'msg.page.ui.one.time.dimension.value' | translate }}
                        <br />
                        {{ 'msg.page.ui.intersect' | translate }} : {{ 'msg.page.ui.one.measured.value' | translate }}
                      </div>
                    </div>

                    <div class="ddp-ui-chart-info" *ngIf="isShowChartInfo('wordcloud')">
                      <span class="ddp-ui-name">{{ 'msg.page.ui.word-cloud' | translate }}</span>
                      <div class="ddp-data-det">
                        {{ 'msg.page.ui.intersect' | translate }}
                        : {{ 'msg.page.ui.more-than-one.dimension.value' | translate }} ,
                        {{ 'msg.page.ui.one.measured.value' | translate }}
                      </div>
                    </div>

                    <div class="ddp-ui-chart-info" *ngIf="isShowChartInfo('combine')">
                      <span class="ddp-ui-name">{{ 'msg.page.ui.combine-chart' | translate }}</span>
                      <div class="ddp-data-det">
                        {{ 'msg.page.ui.row' | translate }} :
                        {{ 'msg.page.ui.more-than-one.dimension.value' | translate }}
                        <br />
                        {{ 'msg.page.ui.intersect' | translate }}
                        : {{ 'msg.page.ui.two-to-four.measured.value' | translate }}
                      </div>
                    </div>

                    <div class="ddp-ui-chart-info" *ngIf="isShowChartInfo('treemap')">
                      <span class="ddp-ui-name">{{ 'msg.page.ui.tree-map' | translate }}</span>
                      <div class="ddp-data-det">
                        {{ 'msg.page.ui.row' | translate }} : {{ 'msg.page.ui.one.dimension.value' | translate }}
                        <br />
                        {{ 'msg.page.ui.col' | translate }} :
                        {{ 'msg.page.ui.more-than-one.dimension.value' | translate }}
                        <br />
                        {{ 'msg.page.ui.intersect' | translate }} : {{ 'msg.page.ui.one.measured.value' | translate }}
                      </div>
                    </div>

                    <div class="ddp-ui-chart-info" *ngIf="isShowChartInfo('radar')">
                      <span class="ddp-ui-name">{{ 'msg.page.ui.radar-chart' | translate }}</span>
                      <div class="ddp-data-det">
                        {{ 'msg.page.ui.intersect' | translate }} :
                        {{ 'msg.page.ui.one.dimension.value' | translate }} ,
                        {{ 'msg.page.ui.more-than-one.measured.value' | translate }}
                      </div>
                    </div>

                    <div class="ddp-ui-chart-info" *ngIf="isShowChartInfo('network')">
                      <span class="ddp-ui-name">{{ 'msg.page.ui.network' | translate }}</span>
                      <div class="ddp-data-det">
                        {{ 'msg.page.ui.sub.shelf' | translate }} : {{ 'msg.page.ui.one.dimension.value' | translate }}
                        <br />
                        {{ 'msg.page.ui.target.shelf' | translate }} :
                        {{ 'msg.page.ui.one.dimension.value' | translate }}
                        <br />
                        {{ 'msg.page.ui.conn.shelf' | translate }} : {{ 'msg.page.ui.one.measured.value' | translate }}
                      </div>
                    </div>

                    <div class="ddp-ui-chart-info" *ngIf="isShowChartInfo('gauge')">
                      <span class="ddp-ui-name">{{ 'msg.page.ui.gauge-chart' | translate }}</span>
                      <div class="ddp-data-det">
                        {{ 'msg.page.ui.col' | translate }} :
                        {{ 'msg.page.ui.more-than-one.dimension.value' | translate }}
                        <br />
                        {{ 'msg.page.ui.intersect' | translate }} : {{ 'msg.page.ui.one.measured.value' | translate }}
                      </div>
                    </div>

                    <div class="ddp-ui-chart-info" *ngIf="isShowChartInfo('sankey')">
                      <span class="ddp-ui-name">{{ 'msg.page.ui.sankey' | translate }}</span>
                      <div class="ddp-data-det">
                        {{ 'msg.page.ui.row' | translate }} :
                        {{ 'msg.page.ui.more-than-two.dimension.value' | translate }}
                        <br />
                        {{ 'msg.page.ui.intersect' | translate }} : {{ 'msg.page.ui.one.measured.value' | translate }}
                      </div>
                    </div>

                    <div class="ddp-ui-chart-info" *ngIf="isShowChartInfo('map')">
                      <span class="ddp-ui-name">{{ 'msg.page.ui.map.view' | translate }}</span>
                      <div class="ddp-data-det">
                        {{ 'msg.page.ui.geo.layer' | translate }} : {{ 'msg.page.ui.geo.dimension.value' | translate }},
                        {{ 'msg.page.ui.more-than-one.dimension.value' | translate }}
                        , {{ 'msg.page.ui.more-than-one.measured.value' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <analysis-component
              [selectChart]="selectChart"
              [isChartShow]="isChartShow"
              [widgetConfiguration]="widgetConfiguration"
              [dataLayerKey]="dataLayerKey"
              (clickDataPanelNoti)="clickDataPanel($event)"
              (changeAnalysisPredictionNoti)="changeAnalysisPredictionLine()"
              (changeForecastNoti)="changeForecast()"
              (changeConfidenceNoti)="changeConfidence()"
              [uiOption]="uiOption"
              [shelf]="shelf"
              (changeAnalysisNoti)="changeDraw($event)"
            ></analysis-component>
          </div>
        </div>
      </div>

      <div
        class="ddp-wrap-chart-contents"
        [ngClass]="{
          'ddp-size':
            (rnbMenu !== 'filter' && !isChartShow) ||
            '' === rnbMenu ||
            (rnbMenu !== 'filter' && !possibleChartCheck(rnbMenu, selectChart))
        }"
      >
        <div class="ddp-view-chart-contents">
          <div class="ddp-wrap-naviarea ddp-clear" *ngIf="widget">
            <em class="ddp-icon-chart2"></em>
            <div class="ddp-ui-naviarea">
              <div
                class="ddp-box-navi ddp-check-none"
                (click)="!isPageNameEdit ? onNameEdit($event) : $event.stopPropagation()"
                [class.ddp-selected]="isPageNameEdit"
              >
                <div class="ddp-wrap-naviname">
                  <span class="ddp-data-naviname">
                    {{ widget.name }}
                    <em *ngIf="!isPageNameEdit" class="ddp-icon-edit2"></em>
                  </span>
                </div>

                <div class="ddp-wrap-input">
                  <component-input
                    *ngIf="isPageNameEdit"
                    [inputClass]="'ddp-input-navi'"
                    [value]="editingPageName"
                    [maxLen]="50"
                    (changeValue)="editingPageName = $event; isPageNameEdit ? onNameChange() : null"
                  ></component-input>
                  <span #pageNameCheck (click)="$event.stopPropagation(); onNameChange()" class="ddp-btn-check"></span>
                </div>
              </div>
            </div>

            <div *ngIf="!uiOption.split" class="ddp-ui-control">
              <div class="ddp-box-btnselect">
                <a
                  href="javascript:"
                  class="ddp-btn-selected"
                  *ngIf="
                    uiOption.legend &&
                    uiOption['type'] &&
                    (uiOption['type'].toString() != 'gauge' ||
                      (uiOption['type'].toString() == 'gauge' && 'measure' == uiOption?.color?.type.toString())) &&
                    uiOption['type'].toString() != 'sankey' &&
                    uiOption['type'].toString() != 'map'
                  "
                  [ngClass]="{ 'ddp-selected': uiOption.legend.auto }"
                  (click)="showLegend(!uiOption.legend.auto)"
                >
                  <em class="ddp-icon-tool-legend"></em>
                  {{ 'msg.page.ui.legend' | translate }}
                </a>

                <a
                  href="javascript:"
                  class="ddp-btn-selected"
                  *ngIf="uiOption['type']?.toString() != 'map' && uiOption.chartZooms"
                  [ngClass]="{ 'ddp-selected': uiOption.chartZooms[0].auto }"
                  (click)="showDataZoom(!uiOption.chartZooms[0].auto)"
                >
                  <em class="ddp-icon-tool-minimap"></em>
                  {{ 'msg.page.ui.minimap' | translate }}
                </a>
              </div>
            </div>
          </div>

          <page-pivot
            #pagePivot
            *ngIf="pivot && selectChart != 'map'"
            [(pivot)]="pivot"
            [(shelf)]="shelf"
            [(uiOption)]="uiOption"
            [widget]="widget"
            [(widgetConfig)]="widgetConfiguration"
            [chartType]="selectChart"
            [globalFilters]="widget.dashBoard.configuration['filters']"
            (deletePivotItem)="onDeletePivotItem($event)"
            (changePivotItem)="onChangePivotItem($event)"
            (changePivot)="onChangePivot($event)"
            (toggleFilter)="toggleFilter($event)"
            (changeFormat)="onChangePivotFormat($event)"
            (customField)="openCustomFieldPopup($event)"
            (showPopup)="onShowPopup($event)"
          ></page-pivot>
          <map-page-pivot
            #mapPivot
            *ngIf="shelf && selectChart == 'map'"
            [pageDimensions]="pageDimensions"
            [(pivot)]="pivot"
            [(shelf)]="shelf"
            [geoType]="geoType"
            [(uiOption)]="uiOption"
            [widget]="widget"
            [(widgetConfig)]="widgetConfiguration"
            [chartType]="selectChart"
            [globalFilters]="widget.dashBoard.configuration['filters']"
            (deletePivotItem)="onDeletePivotItem($event)"
            (changePivotItem)="onChangePivotItem($event)"
            (changeShelf)="onChangeShelf($event)"
            (toggleFilter)="toggleFilter($event)"
            (changeFormat)="onChangePivotFormat($event)"
            (customField)="openCustomFieldPopup($event)"
            (showPopup)="onShowPopup($event)"
            (changeLayer)="onChangeLayer(shelf)"
            (removeAnalysisLayer)="removeAnalysisLayer($event)"
          ></map-page-pivot>

          <div
            class="ddp-ui-chart-contents"
            [ngClass]="{
              'ddp-map-chart': 'map' == selectChart,
              'ddp-shelf1': 'map' == selectChart && 0 == shelf.layers.length - 1,
              'ddp-shelf2': 'map' == selectChart && 1 == shelf.layers.length - 1,
              'ddp-shelf3': 'map' == selectChart && uiOption['analysis'] != null && uiOption['analysis']['use'] == true
            }"
          >
            <div class="ddp-wrap-chart-layout">
              <div class="ddp-ui-chart-basic" *ngIf="selectChart == ''">
                <span class="ddp-ui-info">{{ 'msg.page.ui.sel.chart.type' | translate }}</span>

                <ul class="ddp-list-chart-type">
                  <li
                    [class.ddp-recommend]="recommendCharts.indexOf('bar') > -1"
                    [class.ddp-selected]="selectChart == 'bar'"
                    (click)="selectChart = 'bar'"
                  >
                    <a class="a-hover-tooltip" href="javascript:">
                      <em class="ddp-img-graph-bar"></em>
                      <span class="ddp-txt-name">{{ 'msg.page.ui.bar' | translate }}</span>
                    </a>
                  </li>
                  <li
                    [class.ddp-recommend]="recommendCharts.indexOf('grid') > -1"
                    [class.ddp-selected]="selectChart == 'grid'"
                    (click)="selectChart = 'grid'"
                  >
                    <a href="javascript:">
                      <em class="ddp-img-graph-txt"></em>
                      <span class="ddp-txt-name">{{ 'msg.page.ui.text-table' | translate }}</span>
                    </a>
                  </li>
                  <li
                    [class.ddp-recommend]="recommendCharts.indexOf('line') > -1"
                    [class.ddp-selected]="selectChart == 'line'"
                    (click)="selectChart = 'line'"
                  >
                    <a href="javascript:">
                      <em class="ddp-img-graph-line"></em>
                      <span class="ddp-txt-name">{{ 'msg.page.ui.line' | translate }}</span>
                    </a>
                  </li>
                  <li
                    [class.ddp-recommend]="recommendCharts.indexOf('scatter') > -1"
                    [class.ddp-selected]="selectChart == 'scatter'"
                    (click)="selectChart = 'scatter'"
                  >
                    <a href="javascript:">
                      <em class="ddp-img-graph-scatter"></em>
                      <span class="ddp-txt-name">{{ 'msg.page.ui.scatter' | translate }}</span>
                    </a>
                  </li>
                  <li
                    [class.ddp-recommend]="recommendCharts.indexOf('heatmap') > -1"
                    [class.ddp-selected]="selectChart == 'heatmap'"
                    (click)="selectChart = 'heatmap'"
                  >
                    <a href="javascript:">
                      <em class="ddp-img-graph-heatmap"></em>
                      <span class="ddp-txt-name">{{ 'msg.page.ui.heat-map' | translate }}</span>
                    </a>
                  </li>
                  <li
                    [class.ddp-recommend]="recommendCharts.indexOf('pie') > -1"
                    [class.ddp-selected]="selectChart == 'pie'"
                    (click)="selectChart = 'pie'"
                  >
                    <a href="javascript:">
                      <em class="ddp-img-graph-pie"></em>
                      <span class="ddp-txt-name">{{ 'msg.page.ui.pie' | translate }}</span>
                    </a>
                  </li>
                  <li
                    [class.ddp-recommend]="recommendCharts.indexOf('map') > -1"
                    [class.ddp-selected]="selectChart == 'map'"
                    (click)="selectChart = 'map'"
                  >
                    <a href="javascript:">
                      <em class="ddp-img-graph-map"></em>
                      <span class="ddp-txt-name">{{ 'msg.page.ui.map.view' | translate }}</span>
                    </a>
                  </li>
                  <li
                    [class.ddp-recommend]="recommendCharts.indexOf('label') > -1"
                    [class.ddp-selected]="selectChart == 'label'"
                    (click)="selectChart = 'label'"
                  >
                    <a href="javascript:">
                      <em class="ddp-img-graph-kpi"></em>
                      <span class="ddp-txt-name">{{ 'msg.page.ui.kpi' | translate }}</span>
                    </a>
                  </li>
                  <li
                    [class.ddp-recommend]="recommendCharts.indexOf('boxplot') > -1"
                    [class.ddp-selected]="selectChart == 'boxplot'"
                    (click)="selectChart = 'boxplot'"
                  >
                    <a href="javascript:">
                      <em class="ddp-img-graph-boxplot"></em>
                      <span class="ddp-txt-name">{{ 'msg.page.ui.box-plot' | translate }}</span>
                    </a>
                  </li>
                  <li
                    [class.ddp-recommend]="recommendCharts.indexOf('waterfall') > -1"
                    [class.ddp-selected]="selectChart == 'waterfall'"
                    (click)="selectChart = 'waterfall'"
                  >
                    <a href="javascript:">
                      <em class="ddp-img-graph-waterfall"></em>
                      <span class="ddp-txt-name">{{ 'msg.page.ui.water-fall' | translate }}</span>
                    </a>
                  </li>
                  <li
                    [class.ddp-recommend]="recommendCharts.indexOf('wordcloud') > -1"
                    [class.ddp-selected]="selectChart == 'wordcloud'"
                    (click)="selectChart = 'wordcloud'"
                  >
                    <a href="javascript:">
                      <em class="ddp-img-graph-wordcloud"></em>
                      <span class="ddp-txt-name">{{ 'msg.page.ui.word-cloud' | translate }}</span>
                    </a>
                  </li>
                  <li
                    [class.ddp-recommend]="recommendCharts.indexOf('combine') > -1"
                    [class.ddp-selected]="selectChart == 'combine'"
                    (click)="selectChart = 'combine'"
                  >
                    <a href="javascript:">
                      <em class="ddp-img-graph-combo"></em>
                      <span class="ddp-txt-name">{{ 'msg.page.ui.combine-chart' | translate }}</span>
                    </a>
                  </li>
                  <li
                    [class.ddp-recommend]="recommendCharts.indexOf('treemap') > -1"
                    [class.ddp-selected]="selectChart == 'treemap'"
                    (click)="selectChart = 'treemap'"
                  >
                    <a href="javascript:">
                      <em class="ddp-img-graph-treemap"></em>
                      <span class="ddp-txt-name">{{ 'msg.page.ui.tree-map' | translate }}</span>
                    </a>
                  </li>
                  <li
                    [class.ddp-recommend]="recommendCharts.indexOf('radar') > -1"
                    [class.ddp-selected]="selectChart == 'radar'"
                    (click)="selectChart = 'radar'"
                  >
                    <a href="javascript:">
                      <em class="ddp-img-graph-radar"></em>
                      <span class="ddp-txt-name">{{ 'msg.page.ui.radar-chart' | translate }}</span>
                    </a>
                  </li>
                  <li
                    [class.ddp-recommend]="recommendCharts.indexOf('network') > -1"
                    [class.ddp-selected]="selectChart == 'network'"
                    (click)="selectChart = 'network'"
                  >
                    <a href="javascript:">
                      <em class="ddp-img-graph-network"></em>
                      <span class="ddp-txt-name">{{ 'msg.page.ui.network' | translate }}</span>
                    </a>
                  </li>
                  <li
                    [class.ddp-recommend]="recommendCharts.indexOf('gauge') > -1"
                    [class.ddp-selected]="selectChart == 'gauge'"
                    (click)="selectChart = 'gauge'"
                  >
                    <a href="javascript:">
                      <em class="ddp-img-graph-gauge"></em>
                      <span class="ddp-txt-name">{{ 'msg.page.ui.gauge-chart' | translate }}</span>
                    </a>
                  </li>
                  <li
                    [class.ddp-recommend]="recommendCharts.indexOf('sankey') > -1"
                    [class.ddp-selected]="selectChart == 'sankey'"
                    (click)="selectChart = 'sankey'"
                  >
                    <a href="javascript:">
                      <em class="ddp-img-graph-sankey"></em>
                      <span class="ddp-txt-name">{{ 'msg.page.ui.sankey' | translate }}</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div *ngIf="selectChart != '' && !isChartShow" style="width: 100%; height: 100%">
                <div
                  class="ddp-ui-cahrt-layout ddp-box-layout1"
                  *ngIf="isShowGuide && this.guideLayout['layout6'].indexOf(selectChart) > -1"
                >
                  <div
                    class="ddp-ui-layout-center"
                    [dragula]="'dragbag'"
                    [dragulaModel]="[]"
                    [attr.data-container]="'layer-guide'"
                  >
                    <div class="ddp-ui-condition">
                      <em class="ddp-icon-mapview-guide"></em>
                      <span *ngIf="'GEO_POINT' === geoType?.toString()" class="ddp-txt-dimension">
                        {{ 'msg.page.pivot.layout.condition.dimension.over.desc' | translate: { value: '1' } }} (geo
                        type)
                      </span>
                      <span *ngIf="'GEO_POINT' !== geoType?.toString()" class="ddp-txt-dimension">
                        {{ 'msg.page.pivot.layout.condition.dimension.desc' | translate: { value: '1' } }} (geo type)
                      </span>
                    </div>

                    <em class="ddp-bg-chart-mapview" *ngIf="selectChart == 'map'"></em>
                  </div>
                </div>

                <div
                  class="ddp-ui-cahrt-layout ddp-box-layout2"
                  *ngIf="isShowGuide && this.guideLayout['layout5'].indexOf(selectChart) > -1"
                >
                  <div
                    class="ddp-ui-layout-center"
                    [dragula]="'dragbag'"
                    [dragulaModel]="[]"
                    [attr.data-container]="'aggregation-guide'"
                  >
                    <div class="dragIgnore ddp-ui-condition">
                      <em class="ddp-icon-cross2"></em>
                      <span class="ddp-txt-measure">
                        {{ 'msg.page.pivot.layout.condition.measure.desc' | translate: { value: '1' } }}
                      </span>
                    </div>

                    <em class="ddp-bg-chart-gauge" *ngIf="selectChart == 'gauge'"></em>
                  </div>
                  <div
                    class="ddp-ui-layout-bottom"
                    [dragula]="'dragbag'"
                    [dragulaModel]="[]"
                    [attr.data-container]="'row-guide'"
                  >
                    <div class="dragIgnore dragIgnore ddp-ui-condition">
                      <em class="ddp-icon-columns2"></em>
                      <span class="ddp-txt-dimension">
                        {{ 'msg.page.pivot.layout.condition.dimension.desc' | translate: { value: '1' } }}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  class="ddp-ui-cahrt-layout ddp-box-layout3"
                  *ngIf="isShowGuide && this.guideLayout['layout4'].indexOf(selectChart) > -1"
                >
                  <div
                    class="ddp-ui-layout-left"
                    [dragula]="'dragbag'"
                    [dragulaModel]="[]"
                    [attr.data-container]="'row-guide'"
                  >
                    <div class="dragIgnore ddp-ui-condition">
                      <em *ngIf="'network' !== selectChart" class="ddp-icon-columns2"></em>
                      <em *ngIf="'network' === selectChart" class="ddp-icon-target"></em>
                      <span
                        *ngIf="selectChart != 'scatter' && selectChart != 'network' && selectChart != 'boxplot'"
                        class="ddp-txt-dimension"
                      >
                        {{ 'msg.page.pivot.layout.condition.dimension.over.desc' | translate: { value: '1' } }}
                      </span>
                      <span *ngIf="selectChart == 'network' || selectChart == 'boxplot'" class="ddp-txt-dimension">
                        {{ 'msg.page.pivot.layout.condition.dimension.desc' | translate: { value: '1' } }}
                      </span>
                      <span *ngIf="selectChart == 'scatter'" class="ddp-txt-measure">
                        {{ 'msg.page.pivot.layout.condition.measure.desc' | translate: { value: '1' } }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="ddp-ui-layout-center"
                    [dragula]="'dragbag'"
                    [dragulaModel]="[]"
                    [attr.data-container]="'aggregation-guide'"
                  >
                    <div class="dragIgnore ddp-ui-condition">
                      <em *ngIf="'network' !== selectChart" class="ddp-icon-cross2"></em>
                      <em *ngIf="'network' === selectChart" class="ddp-icon-link"></em>
                      <span
                        *ngIf="
                          selectChart != 'scatter' &&
                          selectChart != 'network' &&
                          selectChart != 'heatmap' &&
                          selectChart != 'boxplot' &&
                          selectChart != 'treemap'
                        "
                        class="ddp-txt-measure"
                      >
                        {{ 'msg.page.pivot.layout.condition.measure.over.desc' | translate: { value: '1' } }}
                      </span>
                      <span
                        *ngIf="
                          selectChart == 'network' ||
                          selectChart == 'heatmap' ||
                          selectChart == 'boxplot' ||
                          selectChart == 'treemap'
                        "
                        class="ddp-txt-measure"
                      >
                        {{ 'msg.page.pivot.layout.condition.measure.desc' | translate: { value: '1' } }}
                      </span>
                      <span *ngIf="selectChart == 'scatter'" class="ddp-txt-dimension">
                        {{ 'msg.page.pivot.layout.condition.dimension.over.desc' | translate: { value: '1' } }}
                      </span>
                    </div>

                    <em class="ddp-bg-chart-bar" *ngIf="selectChart == 'bar'"></em>
                    <em class="ddp-bg-chart-boxplot" *ngIf="selectChart == 'boxplot'"></em>
                    <em class="ddp-bg-chart-combo" *ngIf="selectChart == 'combine'"></em>

                    <em class="ddp-bg-chart-heatmap" *ngIf="selectChart == 'heatmap'"></em>
                    <em class="ddp-bg-chart-kpi" *ngIf="selectChart == 'label'"></em>
                    <em class="ddp-bg-chart-line" *ngIf="selectChart == 'line'"></em>

                    <em class="ddp-bg-chart-network" *ngIf="selectChart == 'network'"></em>
                    <em class="ddp-bg-chart-pie" *ngIf="selectChart == 'pie'"></em>
                    <em class="ddp-bg-chart-randar" *ngIf="selectChart == 'radar'"></em>
                    <em class="ddp-bg-chart-scatter" *ngIf="selectChart == 'scatter'"></em>
                    <em class="ddp-bg-chart-table" *ngIf="selectChart == 'grid'"></em>
                    <em class="ddp-bg-chart-treemap" *ngIf="selectChart == 'treemap'"></em>
                    <em class="ddp-bg-chart-waterfall" *ngIf="selectChart == 'waterfall'"></em>
                    <em class="ddp-bg-chart-wordcloud" *ngIf="selectChart == 'wordcloud'"></em>
                  </div>
                  <div
                    class="ddp-ui-layout-bottom"
                    [dragula]="'dragbag'"
                    [dragulaModel]="[]"
                    [attr.data-container]="'column-guide'"
                  >
                    <div class="dragIgnore ddp-ui-condition">
                      <em *ngIf="'network' !== selectChart" class="ddp-icon-rows2"></em>
                      <em *ngIf="'network' === selectChart" class="ddp-icon-source"></em>
                      <span
                        *ngIf="selectChart != 'scatter' && selectChart != 'network' && selectChart != 'treemap'"
                        class="ddp-txt-dimension"
                      >
                        {{ 'msg.page.pivot.layout.condition.dimension.over.desc' | translate: { value: '1' } }}
                      </span>
                      <span *ngIf="selectChart == 'network' || selectChart == 'treemap'" class="ddp-txt-dimension">
                        {{ 'msg.page.pivot.layout.condition.dimension.desc' | translate: { value: '1' } }}
                      </span>
                      <span *ngIf="selectChart == 'scatter'" class="ddp-txt-measure">
                        {{ 'msg.page.pivot.layout.condition.measure.desc' | translate: { value: '1' } }}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  class="ddp-ui-cahrt-layout ddp-box-layout2"
                  *ngIf="isShowGuide && this.guideLayout['layout3'].indexOf(selectChart) > -1"
                >
                  <div
                    class="ddp-ui-layout-center"
                    [dragula]="'dragbag'"
                    [dragulaModel]="[]"
                    [attr.data-container]="'column-guide'"
                  >
                    <div class="dragIgnore ddp-ui-condition">
                      <em class="ddp-icon-rows2"></em>

                      <span *ngIf="selectChart == 'waterfall'" class="ddp-txt-dimension">
                        {{ 'msg.page.pivot.layout.condition.datetime.desc' | translate: { value: '1' } }}
                      </span>

                      <span *ngIf="selectChart == 'sankey'" class="ddp-txt-dimension">
                        {{ 'msg.page.pivot.layout.condition.dimension.over.desc' | translate: { value: '2' } }}
                      </span>
                    </div>

                    <em class="ddp-bg-chart-bar" *ngIf="selectChart == 'bar'"></em>
                    <em class="ddp-bg-chart-boxplot" *ngIf="selectChart == 'boxplot'"></em>
                    <em class="ddp-bg-chart-combo" *ngIf="selectChart == 'combine'"></em>

                    <em class="ddp-bg-chart-heatmap" *ngIf="selectChart == 'heatmap'"></em>
                    <em class="ddp-bg-chart-kpi" *ngIf="selectChart == 'label'"></em>
                    <em class="ddp-bg-chart-line" *ngIf="selectChart == 'line'"></em>

                    <em class="ddp-bg-chart-network" *ngIf="selectChart == 'network'"></em>
                    <em class="ddp-bg-chart-pie" *ngIf="selectChart == 'pie'"></em>
                    <em class="ddp-bg-chart-randar" *ngIf="selectChart == 'radar'"></em>
                    <em class="ddp-bg-chart-scatter" *ngIf="selectChart == 'scatter'"></em>
                    <em class="ddp-bg-chart-table" *ngIf="selectChart == 'grid'"></em>
                    <em class="ddp-bg-chart-treemap" *ngIf="selectChart == 'treemap'"></em>
                    <em class="ddp-bg-chart-waterfall" *ngIf="selectChart == 'waterfall'"></em>
                    <em class="ddp-bg-chart-wordcloud" *ngIf="selectChart == 'wordcloud'"></em>
                  </div>
                  <div
                    class="ddp-ui-layout-bottom"
                    [dragula]="'dragbag'"
                    [dragulaModel]="[]"
                    [attr.data-container]="'aggregation-guide'"
                  >
                    <div class="dragIgnore ddp-ui-condition">
                      <em class="ddp-icon-cross2"></em>

                      <span *ngIf="selectChart != 'control'" class="ddp-txt-measure">
                        {{ 'msg.page.pivot.layout.condition.measure.desc' | translate: { value: '1' } }}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  class="ddp-ui-cahrt-layout ddp-box-layout2"
                  *ngIf="isShowGuide && this.guideLayout['layout2'].indexOf(selectChart) > -1"
                >
                  <div
                    class="ddp-ui-layout-center"
                    [dragula]="'dragbag'"
                    [dragulaModel]="[]"
                    [attr.data-container]="'aggregation-guide'"
                  >
                    <div class="dragIgnore ddp-ui-condition">
                      <em class="ddp-icon-cross2"></em>

                      <span *ngIf="selectChart != 'combine'" class="ddp-txt-measure">
                        {{ 'msg.page.pivot.layout.condition.measure.over.desc' | translate: { value: '1' } }}
                      </span>
                      <span *ngIf="selectChart == 'combine'" class="ddp-txt-measure">
                        {{ 'msg.page.pivot.layout.condition.measure.over.desc' | translate: { value: '2' } }}
                      </span>
                    </div>

                    <em class="ddp-bg-chart-bar" *ngIf="selectChart == 'bar'"></em>
                    <em class="ddp-bg-chart-boxplot" *ngIf="selectChart == 'boxplot'"></em>
                    <em class="ddp-bg-chart-combo" *ngIf="selectChart == 'combine'"></em>

                    <em class="ddp-bg-chart-heatmap" *ngIf="selectChart == 'heatmap'"></em>
                    <em class="ddp-bg-chart-kpi" *ngIf="selectChart == 'label'"></em>
                    <em class="ddp-bg-chart-line" *ngIf="selectChart == 'line'"></em>

                    <em class="ddp-bg-chart-network" *ngIf="selectChart == 'network'"></em>
                    <em class="ddp-bg-chart-pie" *ngIf="selectChart == 'pie'"></em>
                    <em class="ddp-bg-chart-randar" *ngIf="selectChart == 'radar'"></em>
                    <em class="ddp-bg-chart-scatter" *ngIf="selectChart == 'scatter'"></em>
                    <em class="ddp-bg-chart-table" *ngIf="selectChart == 'grid'"></em>
                    <em class="ddp-bg-chart-treemap" *ngIf="selectChart == 'treemap'"></em>
                    <em class="ddp-bg-chart-waterfall" *ngIf="selectChart == 'waterfall'"></em>
                    <em class="ddp-bg-chart-wordcloud" *ngIf="selectChart == 'wordcloud'"></em>
                  </div>
                  <div
                    class="ddp-ui-layout-bottom"
                    [dragula]="'dragbag'"
                    [dragulaModel]="[]"
                    [attr.data-container]="'column-guide'"
                  >
                    <div class="dragIgnore ddp-ui-condition">
                      <em class="ddp-icon-rows2"></em>
                      <span class="ddp-txt-dimension">
                        {{ 'msg.page.pivot.layout.condition.dimension.over.desc' | translate: { value: '1' } }}
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  class="ddp-ui-cahrt-layout ddp-box-layout1"
                  *ngIf="isShowGuide && this.guideLayout['layout1'].indexOf(selectChart) > -1"
                >
                  <div
                    class="ddp-ui-layout-center"
                    [dragula]="'dragbag'"
                    [dragulaModel]="[]"
                    [attr.data-container]="'aggregation-guide'"
                  >
                    <div class="dragIgnore ddp-ui-condition">
                      <em class="ddp-icon-cross2"></em>
                      <span
                        *ngIf="selectChart != 'wordcloud' && selectChart != 'radar' && selectChart != 'pie'"
                        class="ddp-txt-measure"
                      >
                        {{ 'msg.page.pivot.layout.condition.measure.over.desc' | translate: { value: '1' } }}
                      </span>
                      <span *ngIf="selectChart == 'radar'" class="ddp-txt-measure">
                        {{ 'msg.page.pivot.layout.condition.measure.over.desc' | translate: { value: '1' } }}
                      </span>
                      <span *ngIf="selectChart == 'radar'" class="ddp-txt-dimension">
                        {{ 'msg.page.pivot.layout.condition.dimension.and.desc' | translate: { value: '1' } }}
                      </span>
                      <span *ngIf="selectChart == 'wordcloud'" class="ddp-txt-measure">
                        {{ 'msg.page.pivot.layout.condition.measure.desc' | translate: { value: '1' } }}
                      </span>
                      <span *ngIf="selectChart == 'wordcloud'" class="ddp-txt-dimension">
                        {{ 'msg.page.pivot.layout.condition.dimension.and.over.desc' | translate: { value: '1' } }}
                      </span>
                      <span *ngIf="selectChart == 'pie'" class="ddp-txt-dimension">
                        {{ 'msg.page.pivot.layout.condition.dimension.desc' | translate: { value: '1' } }}
                      </span>
                      <span *ngIf="selectChart == 'pie'" class="ddp-txt-measure">
                        {{ 'msg.page.pivot.layout.condition.measure.desc.add' | translate: { value: '1' } }}
                      </span>
                    </div>

                    <em class="ddp-bg-chart-bar" *ngIf="selectChart == 'bar'"></em>
                    <em class="ddp-bg-chart-boxplot" *ngIf="selectChart == 'boxplot'"></em>
                    <em class="ddp-bg-chart-combo" *ngIf="selectChart == 'combine'"></em>

                    <em class="ddp-bg-chart-heatmap" *ngIf="selectChart == 'heatmap'"></em>
                    <em class="ddp-bg-chart-kpi" *ngIf="selectChart == 'label'"></em>
                    <em class="ddp-bg-chart-line" *ngIf="selectChart == 'line'"></em>

                    <em class="ddp-bg-chart-network" *ngIf="selectChart == 'network'"></em>
                    <em class="ddp-bg-chart-pie" *ngIf="selectChart == 'pie'"></em>
                    <em class="ddp-bg-chart-randar" *ngIf="selectChart == 'radar'"></em>
                    <em class="ddp-bg-chart-scatter" *ngIf="selectChart == 'scatter'"></em>
                    <em class="ddp-bg-chart-table" *ngIf="selectChart == 'grid'"></em>
                    <em class="ddp-bg-chart-waterfall" *ngIf="selectChart == 'waterfall'"></em>
                    <em class="ddp-bg-chart-wordcloud" *ngIf="selectChart == 'wordcloud'"></em>
                    <em class="ddp-bg-chart-treemap" *ngIf="selectChart == 'treemap'"></em>
                  </div>
                </div>
              </div>

              <div
                class="ddp-ui-chart-main"
                [style.display]="selectChart == '' || isChartShow == false ? 'none' : 'block'"
              >
                <div class="ddp-ui-top ddp-clear">
                  <div class="ddp-ui-control"></div>
                </div>

                <div
                  [class.ddp-sankey]="isSankeyNotAllNode && !isNoData && isChartView && selectChart == 'sankey'"
                  class="ddp-ui-chart-area"
                >
                  <div *ngIf="limitInfo.isShow" class="ddp-top-control ddp-clear" style="right: 5px">
                    <div class="ddp-box-btn2">
                      <em class="ddp-icon-widget-info2"></em>

                      <div class="ddp-box-info ddp-limitation">
                        <em class="ddp-icon-info"></em>
                        <span
                          class="ddp-total"
                          [innerHTML]="
                            'msg.page.ui.limit-msg-1'
                              | translate
                                : {
                                    currentCnt: limitInfo.currentCnt | numberCommas,
                                    maxCnt: limitInfo.maxCnt | numberCommas
                                  }
                          "
                        ></span>
                        {{ 'msg.page.ui.limit-msg-2' | translate }}
                      </div>
                    </div>
                  </div>

                  <div
                    *ngIf="isSankeyNotAllNode && !isNoData && isChartView && selectChart == 'sankey'"
                    class="ddp-txt-detail"
                  >
                    ※ {{ 'msg.page.ui.sankey.data.part' | translate }}
                  </div>

                  <div *ngIf="isNoData" class="ddp-box-data-none">
                    <div class="ddp-wrap-data-none">
                      <div class="ddp-ui-data-none">
                        <div class="ddp-txt-none">
                          <selfai-bi-chart-icon [chartType]="widget?.configuration?.chart?.type"></selfai-bi-chart-icon>
                          <span class="ddp-data-name">{{ widget.name }}</span>
                          <span class="ddp-txt-det">
                            {{ 'msg.page.ui.no.data.show' | translate }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="isError" class="ddp-box-data-none">
                    <div class="ddp-wrap-data-none">
                      <div class="ddp-ui-data-none">
                        <div class="ddp-txt-none">
                          <selfai-bi-chart-icon
                            class="ddp-error"
                            [chartType]="widget?.configuration?.chart?.type"
                          ></selfai-bi-chart-icon>
                          <span class="ddp-data-name">{{ widget.name }}</span>
                          <span class="ddp-txt-det">{{ 'msg.page.ui.no.data.error' | translate }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="ddp-ui-page" style="display: none">
                    <a href="javascript:" class="ddp-btn-prev ddp-disabled"></a>
                    <span class="ddp-data-page">
                      <em>1</em>
                      /
                      <em>5</em>
                    </span>
                    <a href="javascript:" class="ddp-btn-next ddp-disabled"></a>
                  </div>

                  <div
                    style="width: 100%; height: 100%"
                    [style.display]="!isChartView ? 'block' : 'none'"
                    *ngIf="!isNoData && isAvaliableGrid()"
                  >
                    <grid-chart
                      #gridChart
                      *ngIf="!isChartView && resultData && gridUiOption"
                      [uiOption]="gridUiOption"
                      [viewMode]="true"
                      (noData)="onNoData()"
                      [resizeDelay]="800"
                    ></grid-chart>
                  </div>

                  <div
                    style="width: 100%; height: 100%"
                    [style.display]="!isError && !isNoData && isChartView ? 'block' : 'none'"
                  >
                    <bar-chart
                      #chart
                      [isPage]="true"
                      *ngIf="selectChart == 'bar'"
                      [uiOption]="uiOption"
                      (noData)="onNoData()"
                    ></bar-chart>

                    <line-chart
                      #chart
                      [isPage]="true"
                      *ngIf="selectChart == 'line'"
                      [uiOption]="uiOption"
                      (noData)="onNoData()"
                      (chartSelectInfo)="chartSelectInfo($event)"
                      [resizeDelay]="500"
                    ></line-chart>

                    <scatter-chart
                      #chart
                      [isPage]="true"
                      *ngIf="selectChart == 'scatter'"
                      [uiOption]="uiOption"
                      (noData)="onNoData()"
                      (chartSelectInfo)="chartSelectInfo($event)"
                      [resizeDelay]="500"
                    ></scatter-chart>

                    <grid-chart
                      #chart
                      [isPage]="true"
                      *ngIf="selectChart == 'grid'"
                      [uiOption]="uiOption"
                      (noData)="onNoData()"
                      (chartSelectInfo)="chartSelectInfo($event)"
                      [resizeDelay]="500"
                    ></grid-chart>

                    <heatmap-chart
                      #chart
                      [isPage]="true"
                      *ngIf="selectChart == 'heatmap'"
                      [uiOption]="uiOption"
                      (noData)="onNoData()"
                      (chartSelectInfo)="chartSelectInfo($event)"
                      [resizeDelay]="500"
                    ></heatmap-chart>

                    <boxplot-chart
                      #chart
                      [isPage]="true"
                      *ngIf="selectChart == 'boxplot'"
                      [uiOption]="uiOption"
                      (noData)="onNoData()"
                      (chartSelectInfo)="chartSelectInfo($event)"
                      [resizeDelay]="500"
                    ></boxplot-chart>

                    <pie-chart
                      #chart
                      [isPage]="true"
                      *ngIf="selectChart == 'pie'"
                      [uiOption]="uiOption"
                      ()="updateUIOption($event)"
                      (noData)="onNoData()"
                      (chartSelectInfo)="chartSelectInfo($event)"
                      [resizeDelay]="500"
                    ></pie-chart>

                    <label-chart
                      #chart
                      [isPage]="true"
                      *ngIf="selectChart == 'label'"
                      [uiOption]="uiOption"
                      (noData)="onNoData()"
                      (chartSelectInfo)="chartSelectInfo($event)"
                      [query]="query"
                      [widget]="widget"
                      [resizeDelay]="500"
                    ></label-chart>

                    <wordcloud-chart
                      #chart
                      [isPage]="true"
                      *ngIf="selectChart == 'wordcloud'"
                      [uiOption]="uiOption"
                      (noData)="onNoData()"
                      (chartSelectInfo)="chartSelectInfo($event)"
                      [resizeDelay]="500"
                    ></wordcloud-chart>

                    <waterfall-chart
                      #chart
                      [isPage]="true"
                      *ngIf="selectChart == 'waterfall'"
                      [uiOption]="uiOption"
                      (noData)="onNoData()"
                      (chartSelectInfo)="chartSelectInfo($event)"
                      [resizeDelay]="500"
                    ></waterfall-chart>

                    <combine-chart
                      #chart
                      [isPage]="true"
                      *ngIf="selectChart == 'combine'"
                      [uiOption]="uiOption"
                      (noData)="onNoData()"
                      (chartSelectInfo)="chartSelectInfo($event)"
                      [resizeDelay]="500"
                    ></combine-chart>

                    <treemap-chart
                      #chart
                      [isPage]="true"
                      *ngIf="selectChart == 'treemap'"
                      [uiOption]="uiOption"
                      (noData)="onNoData()"
                      (chartSelectInfo)="chartSelectInfo($event)"
                      [resizeDelay]="500"
                    ></treemap-chart>

                    <radar-chart
                      #chart
                      [isPage]="true"
                      *ngIf="selectChart == 'radar'"
                      [uiOption]="uiOption"
                      (noData)="onNoData()"
                      (chartSelectInfo)="chartSelectInfo($event)"
                      [resizeDelay]="500"
                    ></radar-chart>

                    <network-chart
                      #chart
                      [isPage]="true"
                      *ngIf="selectChart == 'network'"
                      [uiOption]="uiOption"
                      (noData)="onNoData()"
                      (chartSelectInfo)="chartSelectInfo($event)"
                      [resizeDelay]="500"
                    ></network-chart>

                    <sankey-chart
                      #chart
                      [isPage]="true"
                      *ngIf="selectChart == 'sankey'"
                      [uiOption]="uiOption"
                      (noData)="onNoData()"
                      (notAllNode)="isSankeyNotAllNode = $event"
                      (chartSelectInfo)="chartSelectInfo($event)"
                      [resizeDelay]="500"
                    ></sankey-chart>
                    <gauge-chart
                      #chart
                      [isPage]="true"
                      [sorts]="sorts"
                      *ngIf="selectChart == 'gauge'"
                      [uiOption]="uiOption"
                      (noData)="onNoData()"
                      (changePivotData)="onChangePivotData($event)"
                      (showGuide)="onShowGuide()"
                      (chartSelectInfo)="chartSelectInfo($event)"
                      [resizeDelay]="500"
                    ></gauge-chart>
                    <map-chart
                      #chart
                      [isPage]="true"
                      *ngIf="selectChart === 'map'"
                      (noData)="onNoData()"
                      (chartSelectInfo)="chartSelectInfo($event)"
                      [needToRemoveMapLayer]="isChartShow"
                      [uiOption]="uiOption"
                      (changeDraw)="changeDraw($event)"
                      [resizeDelay]="500"
                    ></map-chart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="ddp-wrap-chart-side"
        [ngClass]="{
          'ddp-selected':
            rnbMenu === 'filter' || (isChartShow && rnbMenu != '' && possibleChartCheck(rnbMenu, selectChart)),
          'type-popup': panelZIndex
        }"
        *ngIf="rnbMenu == 'filter' || ('' !== rnbMenu && isChartShow && possibleChartCheck(rnbMenu, selectChart))"
      >
        <div class="ddp-area-boardside ddp-chart-side-in">
          <common-option
            #commonOption
            *ngIf="isChartShow && 'common' === rnbMenu"
            [(uiOption)]="uiOption"
            [(pivot)]="pivot"
            (drawChartParam)="onSetDrawChartParam($event)"
            (changeAxisByStack)="changeAxisByStack($event)"
            (showConfirmPopup)="onShowPopup($event)"
          ></common-option>
          <color-option
            #colorOption
            *ngIf="isChartShow && possibleChartCheck('color', selectChart) && 'color' === rnbMenu"
            [(uiOption)]="uiOption"
            [(resultData)]="resultData"
            [pivot]="pivot"
            (drawChartParam)="onSetDrawChartParam($event)"
          ></color-option>
          <legend-option
            #legendOption
            *ngIf="isChartShow && possibleChartCheck('legend', selectChart) && 'legend' === rnbMenu"
            [(uiOption)]="uiOption"
          ></legend-option>
          <format-option
            #formatOption
            *ngIf="isChartShow && possibleChartCheck('format', selectChart) && 'format' === rnbMenu"
            (changeCommon)="onFormatCommonChange($event)"
            (changeEach)="onFormatEachChange($event)"
            (drawChartParam)="onSetDrawChartParam($event)"
            [(pivot)]="pivot"
            [(uiOption)]="uiOption"
          ></format-option>
          <yaxis-option
            #yAxisOption
            *ngIf="isChartShow && possibleChartCheck('yAxis', selectChart) && 'yAxis' === rnbMenu"
            [(uiOption)]="uiOption"
            [(pivot)]="pivot"
            (drawChartParam)="onSetDrawChartParam($event)"
          ></yaxis-option>
          <xaxis-option
            #xAxisOption
            *ngIf="isChartShow && possibleChartCheck('xAxis', selectChart) && 'xAxis' === rnbMenu"
            [(uiOption)]="uiOption"
            (drawChartParam)="onSetDrawChartParam($event)"
          ></xaxis-option>
          <secondary-axis-option
            #secondaryAxisOption
            *ngIf="isChartShow && possibleChartCheck('secondaryAxis', selectChart) && 'secondaryAxis' === rnbMenu"
            [(uiOption)]="uiOption"
            [(pivot)]="pivot"
            (drawChartParam)="onSetDrawChartParam($event)"
          ></secondary-axis-option>
          <datalabel-option
            #dataLabelOption
            *ngIf="isChartShow && possibleChartCheck('dataLabel', selectChart) && 'dataLabel' === rnbMenu"
            [(uiOption)]="uiOption"
            [pivot]="pivot"
          ></datalabel-option>
          <tooltip-option
            #tooltipOption
            *ngIf="isChartShow && possibleChartCheck('tooltip', selectChart) && 'tooltip' === rnbMenu"
            [(uiOption)]="uiOption"
            [pivot]="pivot"
          ></tooltip-option>
          <secondary-indicator-option
            #secondaryIndicatorOption
            *ngIf="
              isChartShow && possibleChartCheck('secondaryIndicator', selectChart) && 'secondaryIndicator' === rnbMenu
            "
            [(uiOption)]="uiOption"
            [(pivot)]="pivot"
            [widget]="widget"
          ></secondary-indicator-option>
          <split-option
            #splitOption
            *ngIf="isChartShow && possibleChartCheck('split', selectChart) && 'split' === rnbMenu"
            (drawChartParam)="onSetDrawChartParam($event)"
            [(uiOption)]="uiOption"
          ></split-option>
          <page-filter-panel
            *ngIf="'filter' === rnbMenu"
            [dashboard]="widget.dashBoard"
            [boardFilters]="boardFilters"
            [chartFilters]="widgetConfiguration.filters"
            (updateFilter)="updateFilter($event)"
            (deleteFilter)="deleteFilter($event)"
            (openUpdateFilterPopup)="openUpdateFilterPopup($event)"
            (changeGlobalToChart)="openConfirmPopup($event, 'toChartFilter')"
          ></page-filter-panel>
          <calc-option
            #calculateOption
            *ngIf="isChartShow && possibleChartCheck('calculatedRow', selectChart) && 'calculatedRow' === rnbMenu"
            [(uiOption)]="uiOption"
          ></calc-option>

          <map-common-option
            #mapCommonOption
            *ngIf="isChartShow && 'mapCommon' === rnbMenu"
            [(uiOption)]="uiOption"
          ></map-common-option>
          <map-layer-option
            #mapLayerOption
            *ngIf="
              isChartShow &&
              ('mapLayer1' === rnbMenu || 'mapLayer2' === rnbMenu || 'mapLayer3' === rnbMenu) &&
              !setDisableMapLayer()
            "
            [(uiOption)]="uiOption"
            [(shelf)]="shelf"
            [rnbMenu]="rnbMenu"
            (drawChartParam)="onSetDrawChartParam($event)"
            [data]="resultData?.data"
            (setZIndex)="onSetZIndex($event)"
          ></map-layer-option>
          <map-legend-option
            #mapLegendOption
            *ngIf="isChartShow && 'mapLegend' === rnbMenu"
            [(uiOption)]="uiOption"
            (drawChartParam)="onSetDrawChartParam($event)"
          ></map-legend-option>
          <map-format-option
            #mapFormatOption
            *ngIf="isChartShow && 'mapFormat' === rnbMenu"
            (changeCommon)="onFormatCommonChange($event)"
            (changeEach)="onFormatEachChange($event)"
            (drawChartParam)="onSetDrawChartParam($event)"
            [(shelf)]="shelf"
            [(uiOption)]="uiOption"
          ></map-format-option>
          <map-tooltip-option
            #mapTooltipOption
            *ngIf="isChartShow && 'mapTooltip' === rnbMenu"
            [(uiOption)]="uiOption"
            [(shelf)]="shelf"
            (drawChartParam)="onSetDrawChartParam($event)"
          ></map-tooltip-option>
        </div>
      </div>

      <div class="ddp-wrap-chart-menu">
        <a href="javascript:" (click)="toggleRnb('filter')" [class.ddp-selected]="rnbMenu == 'filter'">
          <em class="ddp-icon-menu-filter"></em>
        </a>
        <span class="ddp-ui-menu-layout" (mouseover)="drawChartTooltip($event)">
          <a
            *ngIf="possibleChartCheck('common', selectChart)"
            href="javascript:"
            (click)="uiOption && uiOption.split ? null : toggleRnb('common')"
            [ngClass]="{ 'ddp-disabled': !isChartShow || (uiOption && uiOption.split) }"
            [class.ddp-selected]="isChartShow && rnbMenu == 'common'"
          >
            <em class="ddp-icon-menu-general"></em>

            <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-right"></em>
              {{ 'msg.page.first.draw.chart' | translate }}
            </div>
          </a>

          <a
            *ngIf="possibleChartCheck('color', selectChart)"
            href="javascript:"
            (click)="uiOption && uiOption.split ? null : toggleRnb('color')"
            [ngClass]="{ 'ddp-disabled': !isChartShow || (uiOption && uiOption.split) }"
            [class.ddp-selected]="isChartShow && rnbMenu == 'color'"
          >
            <em class="ddp-icon-menu-color"></em>

            <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-right"></em>
              {{ 'msg.page.first.draw.chart' | translate }}
            </div>
          </a>

          <a
            *ngIf="possibleChartCheck('legend', selectChart)"
            href="javascript:"
            (click)="uiOption && uiOption.split ? null : toggleRnb('legend')"
            [ngClass]="{ 'ddp-disabled': !isChartShow || (uiOption && uiOption.split) }"
            [class.ddp-selected]="isChartShow && rnbMenu == 'legend'"
          >
            <em class="ddp-icon-menu-legend"></em>

            <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-right"></em>
              {{ 'msg.page.first.draw.chart' | translate }}
            </div>
          </a>

          <a
            *ngIf="possibleChartCheck('format', selectChart)"
            href="javascript:"
            (click)="uiOption && uiOption.split ? null : toggleRnb('format')"
            [ngClass]="{ 'ddp-disabled': !isChartShow || (uiOption && uiOption.split) }"
            [class.ddp-selected]="isChartShow && rnbMenu == 'format'"
          >
            <em class="ddp-icon-menu-num"></em>

            <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-right"></em>
              {{ 'msg.page.first.draw.chart' | translate }}
            </div>
          </a>

          <a
            *ngIf="possibleChartCheck('yAxis', selectChart)"
            href="javascript:"
            (click)="uiOption && uiOption.split ? null : toggleRnb('yAxis')"
            [ngClass]="{ 'ddp-disabled': !isChartShow || (uiOption && uiOption.split) }"
            [class.ddp-selected]="isChartShow && rnbMenu == 'yAxis'"
          >
            <em class="ddp-icon-menu-axis-y"></em>

            <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-right"></em>
              {{ 'msg.page.first.draw.chart' | translate }}
            </div>
          </a>

          <a
            *ngIf="possibleChartCheck('xAxis', selectChart)"
            href="javascript:"
            (click)="uiOption && uiOption.split ? null : toggleRnb('xAxis')"
            [ngClass]="{ 'ddp-disabled': !isChartShow || (uiOption && uiOption.split) }"
            [class.ddp-selected]="isChartShow && rnbMenu == 'xAxis'"
          >
            <em class="ddp-icon-menu-axis-x"></em>

            <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-right"></em>
              {{ 'msg.page.first.draw.chart' | translate }}
            </div>
          </a>

          <a
            *ngIf="possibleChartCheck('secondaryAxis', selectChart)"
            href="javascript:"
            (click)="uiOption && uiOption.split ? null : toggleRnb('secondaryAxis')"
            [ngClass]="{ 'ddp-disabled': !isChartShow || (uiOption && uiOption.split) }"
            [class.ddp-selected]="isChartShow && rnbMenu == 'secondaryAxis'"
          >
            <em class="ddp-icon-menu-axis"></em>

            <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-right"></em>
              {{ 'msg.page.first.draw.chart' | translate }}
            </div>
          </a>

          <a
            *ngIf="possibleChartCheck('dataLabel', selectChart)"
            href="javascript:"
            (click)="uiOption && uiOption.split ? null : toggleRnb('dataLabel')"
            [ngClass]="{ 'ddp-disabled': !isChartShow || (uiOption && uiOption.split) }"
            [class.ddp-selected]="isChartShow && rnbMenu == 'dataLabel'"
          >
            <em class="ddp-icon-menu-label"></em>

            <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-right"></em>
              {{ 'msg.page.first.draw.chart' | translate }}
            </div>
          </a>

          <a
            *ngIf="possibleChartCheck('tooltip', selectChart)"
            href="javascript:"
            (click)="uiOption && uiOption.split ? null : toggleRnb('tooltip')"
            [ngClass]="{ 'ddp-disabled': !isChartShow || (uiOption && uiOption.split) }"
            [class.ddp-selected]="isChartShow && rnbMenu == 'tooltip'"
          >
            <em class="ddp-icon-menu-tooltip"></em>

            <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-right"></em>
              {{ 'msg.page.first.draw.chart' | translate }}
            </div>
          </a>
          <a
            *ngIf="false && possibleChartCheck('split', selectChart)"
            href="javascript:"
            (click)="toggleRnb('split')"
            [ngClass]="{ 'ddp-disabled': !isChartShow }"
            [class.ddp-selected]="isChartShow && rnbMenu == 'split'"
          >
            <em class="ddp-icon-menu-split"></em>

            <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-right"></em>
              {{ 'msg.page.first.draw.chart' | translate }}
            </div>
          </a>

          <a
            *ngIf="possibleChartCheck('calculatedRow', selectChart)"
            href="javascript:"
            (click)="toggleRnb('calculatedRow')"
            [ngClass]="{ 'ddp-disabled': !isChartShow }"
            [class.ddp-selected]="isChartShow && rnbMenu == 'calculatedRow'"
          >
            <em class="ddp-icon-menu-calculated"></em>

            <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-right"></em>
              {{ 'msg.page.first.draw.chart' | translate }}
            </div>
          </a>

          <a
            *ngIf="possibleChartCheck('secondaryIndicator', selectChart)"
            href="javascript:"
            (click)="uiOption && uiOption.split ? null : toggleRnb('secondaryIndicator')"
            [ngClass]="{ 'ddp-disabled': !isChartShow || (uiOption && uiOption.split) }"
            [class.ddp-selected]="isChartShow && rnbMenu == 'secondaryIndicator'"
          >
            <em class="ddp-icon-menu-tooltip"></em>

            <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-right"></em>
              {{ 'msg.page.first.draw.chart' | translate }}
            </div>
          </a>

          <a
            *ngIf="selectChart == 'map'"
            href="javascript:"
            (click)="toggleRnb('mapCommon')"
            [ngClass]="{ 'ddp-disabled': !isChartShow }"
            [class.ddp-selected]="isChartShow && rnbMenu == 'mapCommon'"
          >
            <em class="ddp-icon-menu-general"></em>

            <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-right"></em>
              {{ 'msg.page.first.draw.chart' | translate }}
            </div>
          </a>

          <div *ngIf="selectChart == 'map'">
            <div *ngFor="let item of shelf.layers; let index = index">
              <a
                *ngIf="
                  uiOption['analysis'] == null ||
                  uiOption['analysis']['use'] == null ||
                  uiOption['analysis']['use'] == false ||
                  (uiOption['analysis']['use'] && index != shelf.layers.length - 1)
                "
                (click)="toggleMapLayer('mapLayer' + (index + 1), index)"
                [ngClass]="{
                  'ddp-disabled':
                    !isChartShow ||
                    setDisableShelf(index) ||
                    ('map' == selectChart && uiOption['analysis'] != null && uiOption['analysis']['use'] == true)
                }"
                [class.ddp-selected]="isChartShow && rnbMenu == 'mapLayer' + (index + 1) && !setDisableShelf(index)"
                href="javascript:"
              >
                <em *ngIf="index == 0" class="ddp-icon-menu-mapview1"></em>
                <em *ngIf="index == 1" class="ddp-icon-menu-mapview2"></em>

                <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-right"></em>
                  {{ 'msg.page.first.draw.chart' | translate }}
                </div>
              </a>

              <a
                *ngIf="
                  uiOption['analysis'] != null &&
                  uiOption['analysis']['use'] == true &&
                  index == shelf.layers.length - 1
                "
                (click)="toggleMapLayer('mapLayer' + (index + 1), index)"
                [class.ddp-selected]="isChartShow && rnbMenu == 'mapLayer' + (index + 1) && !setDisableShelf(index)"
                href="javascript:"
              >
                <em class="ddp-icon-menu-analysis"></em>

                <div class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-right"></em>
                  Analysis
                </div>
              </a>
            </div>
          </div>

          <a
            *ngIf="selectChart == 'map'"
            href="javascript:"
            (click)="toggleRnb('mapLegend')"
            [ngClass]="{ 'ddp-disabled': !isChartShow }"
            [class.ddp-selected]="isChartShow && rnbMenu == 'mapLegend'"
          >
            <em class="ddp-icon-menu-legend"></em>

            <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-right"></em>
              {{ 'msg.page.first.draw.chart' | translate }}
            </div>
          </a>

          <a
            *ngIf="selectChart == 'map'"
            href="javascript:"
            (click)="toggleRnb('mapFormat')"
            [ngClass]="{ 'ddp-disabled': !isChartShow }"
            [class.ddp-selected]="isChartShow && rnbMenu == 'mapFormat'"
          >
            <em class="ddp-icon-menu-num"></em>

            <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-right"></em>
              {{ 'msg.page.first.draw.chart' | translate }}
            </div>
          </a>

          <a
            *ngIf="selectChart == 'map'"
            href="javascript:"
            (click)="toggleRnb('mapTooltip')"
            [ngClass]="{ 'ddp-disabled': !isChartShow }"
            [class.ddp-selected]="isChartShow && rnbMenu == 'mapTooltip'"
          >
            <em class="ddp-icon-menu-tooltip"></em>

            <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-right"></em>
              {{ 'msg.page.first.draw.chart' | translate }}
            </div>
          </a>
        </span>
      </div>

      <div class="ddp-ui-buttons2">
        <a href="javascript:" class="ddp-btn-type-popup" (click)="close()">{{ 'msg.comm.btn.cancl' | translate }}</a>
        <a
          href="javascript:"
          class="ddp-btn-type-popup ddp-bg-black"
          [ngClass]="{ 'ddp-disabled': !isChartShow }"
          (click)="save()"
        >
          {{ 'msg.page.btn.save' | translate }}
        </a>
      </div>
    </div>
  </div>

  <app-custom-field
    *ngIf="isShowCustomFiled"
    [fields]="fields"
    [dataSource]="widget.configuration.dataSource"
    [customFields]="widget.configuration.customFields"
    [customField]="selectedCustomField"
    [selectedColumnType]="columnType"
    (updateColumn)="updateCustomFields($event)"
    (closeDialog)="isShowCustomFiled = false"
  ></app-custom-field>
  <app-confirm-modal (confirm)="confirm($event)"></app-confirm-modal>

  <data-preview
    *ngIf="isShowDataDetail"
    [field]="selectedField"
    [singleTab]="isColumnDetail"
    [initial]="dataSource"
    [source]="widget.dashBoard"
    (close)="isShowDataDetail = false"
  ></data-preview>
</div>

<page-data-context
  (openCustomFieldEvent)="openCustomFieldPopup($event)"
  (openColumnDetailEvent)="onColumnDetailPopup($event)"
  (deleteCustomFieldEvent)="deleteCustomField($event)"
  (changeAlias)="changeDatasourceFieldAlias($event)"
></page-data-context>

<app-config-filter (done)="configureFilter($event)" (close)="closeFilterPopup()"></app-config-filter>

<div class="sys-global-confirm-popup ddp-wrap-layout-popup" style="display: none">
  <div class="ddp-box-popup">
    <div class="ant-modal-body">
      <div class="ant-modal-confirm-body-wrapper">
        <div class="ant-modal-confirm-body">
          <span role="img" aria-label="exclamation-circle" class="anticon anticon-exclamation-circle">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              class=""
              data-icon="exclamation-circle"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
              ></path>
              <path
                d="M464 688a48 48 0 1096 0 48 48 0 10-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"
              ></path>
            </svg>
          </span>
          <span class="ant-modal-confirm-title">{{ 'msg.dp.ui.questions' | translate }}?</span>
          <div class="ant-modal-confirm-content"></div>
        </div>
        <div class="ant-modal-confirm-btns">
          <button type="button" class="ant-btn">
            <span>{{ 'msg.dp.ui.no' | translate }}</span>
          </button>
          <button type="button" class="ant-btn ant-btn-dangerous">
            <span>{{ 'msg.dp.ui.yes' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
