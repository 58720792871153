import { Component, ElementRef, EventEmitter, Injector, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import * as _ from 'lodash';

import { RoleSet, RoleSetScope, Workspace, createWorkspace } from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { CommonUtil } from '../../../common/util/common.util';
import { PublicType } from '../../../domain/workspace/workspace';
import { PermissionService } from '../../../user/service/permission.service';
import { WorkspaceService } from '../../service/workspace.service';
import { PermissionSchemaSetComponent } from '../permission/permission-schema-set.component';
import { PermissionSchemaComponent } from '../permission/permission-schema.component';

@Component({
  selector: 'app-create-workspace',
  templateUrl: './create-workspace.component.html',
})
export class CreateWorkspaceComponent extends AbstractComponent implements OnInit, OnDestroy {
  private _docOverflow: string;

  @ViewChild(PermissionSchemaComponent)
  private _permSchemaComp: PermissionSchemaComponent;

  @ViewChild(PermissionSchemaSetComponent, { static: true })
  private _permissionSchemaSetComp: PermissionSchemaSetComponent;

  @Output()
  public createComplete = new EventEmitter();

  public isShow = false;
  public shareWorkspace: Workspace;
  public sharedWorkspaceList: Workspace[];

  public isInvalidName = false;
  public errMsgName = '';

  public isInvalidDesc = false;
  public errMsgDesc = '';

  public isShowPredefinedRoleSetList = false;
  public isShowCustomRoleSetList = false;
  public isUseCustomRoleSet = false;

  public roleSetList: RoleSet[] = [];
  public selectedRoleSetInfo: RoleSet;
  public selectedRoleSetDetail: RoleSet;

  public params = {
    size: this.page.size,
    page: this.page.page,
    sort: { name: this.translateService.instant('msg.comm.ui.list.name.asc'), value: 'name,asc', selected: true },
  };

  get disableCreateWorkspace() {
    return (
      this.isInvalidName ||
      this.isInvalidDesc ||
      !this.shareWorkspace.name ||
      '' === this.shareWorkspace.name ||
      !this.selectedRoleSetDetail
    );
  }

  constructor(
    private workbookService: WorkspaceService,
    private workspaceService: WorkspaceService,
    protected permissionService: PermissionService,
    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    $(document.body).css('overflow', this._docOverflow);
  }

  public init() {
    this._docOverflow = $(document.body).css('overflow');
    $(document.body).css('overflow', 'hidden');

    this.roleSetList = [];
    this.selectedRoleSetInfo = null;
    this.selectedRoleSetDetail = null;
    this.isShowPredefinedRoleSetList = false;
    this.isShowCustomRoleSetList = false;
    this.isUseCustomRoleSet = false;

    this._loadRoleSets().then(() => {
      this.shareWorkspace = createWorkspace();
      this.isShow = true;
    });
  }

  public close() {
    this.sharedWorkspaceList = undefined;
    this.isInvalidName = undefined;
    $(document.body).css('overflow', this._docOverflow);
    this.isShow = false;
  }

  public async nameChange(newWorkspaceName) {
    this.shareWorkspace.name = newWorkspaceName;
    this.params.size = 100;

    this.loadingShow();

    if (_.isNil(this.sharedWorkspaceList)) {
      this.workspaceService
        .getSharedWorkspaces('forListView', this.params)
        .then((workspaces) => {
          if (workspaces['_embedded']) {
            this.sharedWorkspaceList = workspaces['_embedded']['workspaces'];
            this._checkDuplicateName(newWorkspaceName);
          } else {
            this.sharedWorkspaceList = [];
          }
        })
        .catch((error) => {
          this.alertPrimeService.error(this.translateService.instant('msg.space.alert.retrieve'));
          this.loadingHide();
        });
    } else if (this.sharedWorkspaceList.length > 0) {
      this._checkDuplicateName(newWorkspaceName);
    }

    this.loadingHide();
  }

  public clickComponent(event: MouseEvent) {
    event.stopPropagation();
    const $eventTarget: any = $(event.target);
    if (!$eventTarget.hasClass('ddp-type-selectbox') && 0 === $eventTarget.closest('.ddp-type-selectbox').length) {
      this.isShowCustomRoleSetList = false;
      this.isShowPredefinedRoleSetList = false;
    }
  }

  public selectRoleSet(item: RoleSet, isCustom: boolean = false) {
    this.selectedRoleSetInfo = item;
    this.permissionService
      .getRolesetDetail(item.id)
      .then((result: RoleSet) => {
        if (isCustom) {
          delete result.id;
          delete result.name;
          result.scope = RoleSetScope.PRIVATE;
        }
        this.selectedRoleSetDetail = result;
      })
      .catch((err) => this.commonExceptionHandler(err));
  }

  public createShareWorkspace() {
    if (this.disableCreateWorkspace) {
      this.shareWorkspace.name = this.shareWorkspace.name ? this.shareWorkspace.name.trim() : '';

      if (this.shareWorkspace.name == null || this.shareWorkspace.name.length === 0) {
        this.isInvalidName = true;
        this.errMsgName = this.translateService.instant('msg.alert.create.name.empty');
      }

      if (CommonUtil.getByte(this.shareWorkspace.name) > 150) {
        this.isInvalidName = true;
        this.errMsgName = this.translateService.instant('msg.alert.edit.name.len');
      }

      if (this.shareWorkspace.description != null && CommonUtil.getByte(this.shareWorkspace.description.trim()) > 450) {
        this.isInvalidDesc = true;
        this.errMsgDesc = this.translateService.instant('msg.alert.edit.description.len');
      }

      this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.error'));
      return;
    }

    this.loadingShow();

    (this.shareWorkspace as any).publicType = PublicType.SHARED;

    this._permSchemaComp.setRoleSets(this.shareWorkspace.id, this.shareWorkspace.name).then((roleSet: RoleSet) => {
      const createParam: any = _.cloneDeep(this.shareWorkspace);
      createParam['roleSets'] = ['/api/rolesets/' + roleSet.id];

      this.workbookService
        .createWorkspace(createParam)
        .then((workspace: Workspace) => {
          this.loadingHide();
          this.alertPrimeService.success(
            `'${this.shareWorkspace.name}' ` +
              this.translateService.instant('msg.space.alert.create.workspace.success'),
          );
          this.close();
          this.createComplete.emit(workspace.id);
        })
        .catch(() => {
          this.alertPrimeService.error(this.translateService.instant('msg.space.alert.create.workspace.fail'));
          this.loadingHide();
        });
    });
  }

  public changeRoleSetType(isCustom: boolean) {
    this.isUseCustomRoleSet = isCustom;
    this.selectedRoleSetInfo = null;
    if (isCustom) {
      this.selectedRoleSetDetail = new RoleSet();
    } else {
      this.selectedRoleSetDetail = null;
      this.selectRoleSet(this.roleSetList[0], false);
    }
  }

  public openPredefinedRoleSetOpts() {
    this.isUseCustomRoleSet = false;
    this.isShowCustomRoleSetList = false;
    this.isShowPredefinedRoleSetList = !this.isShowPredefinedRoleSetList;
  }

  public openCustomRoleSetOpts() {
    this.isUseCustomRoleSet = true;
    this.isShowPredefinedRoleSetList = false;
    this.isShowCustomRoleSetList = !this.isShowCustomRoleSetList;
  }

  public onClickOpenPermissionSchemaSet() {
    const cloneRoleSet: RoleSet = _.cloneDeep(this.selectedRoleSetDetail);
    this._permissionSchemaSetComp.init(cloneRoleSet, true, false);
  }

  public afterUpdatePermissionRoles(roleset: RoleSet) {
    this.selectedRoleSetDetail = roleset;
  }

  private _loadRoleSets(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loadingShow();
      const params = {
        page: this.page.page,
        size: 1000,
        sort: this.page.sort,
        scope: RoleSetScope.PUBLIC,
      };
      this.permissionService
        .getRolesets(params)
        .then((result) => {
          if (result && result['_embedded']) {
            this.roleSetList = result['_embedded']['roleSets'];
            this.selectRoleSet(this.roleSetList[0], false);
          }
          resolve();
          this.loadingHide();
        })
        .catch((err) => {
          this.commonExceptionHandler(err);
          reject();
        });
    });
  }

  private _checkDuplicateName(newWorkspaceName: string) {
    this.isInvalidName = this.sharedWorkspaceList.some((workspace) => {
      if (workspace.name === newWorkspaceName) {
        this.errMsgName = this.translateService.instant('msg.comm.ui.workspace.name.duplicated');
        return true;
      }
    });
  }
}
