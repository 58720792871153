import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

import {
  EChartType,
  IPageChart,
} from "./page-section-chart.component.interfaces";
import { CHARTS } from "./page-section-chart.component.values";

@Component({
  selector: "selfai-platform-page-section-chart",
  templateUrl: "./page-section-chart.component.html",
  styleUrls: ["./page-section-chart.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageSectionChartComponent {
  @Input() recommendCharts: any;
  @Input() selectChart: any;

  @Output() selectChartChange = new EventEmitter<EChartType>();

  #showInfoChart$ = new BehaviorSubject<EChartType>(null);

  isShowChartInfo$ = this.#showInfoChart$.pipe(
    map((chartType) => chartType ?? this.selectChart)
  );

  charts: IPageChart[] = CHARTS;

  clearChartInfo(): void {
    this.#showInfoChart$.next(null);
  }

  selectChartInfo(chartType: EChartType): void {
    this.#showInfoChart$.next(chartType);
  }
}
