import { Injectable, OnDestroy } from '@angular/core';
import { IGraphNode } from '@selfai-platform/pipeline-common';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EditorStateService implements OnDestroy {
  private readonly selectedNode = new ReplaySubject<IGraphNode | null>(1);
  constructor() {
    this.selectedNode.next(null);
  }

  getSelectedNode(): Observable<IGraphNode | null> {
    return this.selectedNode.asObservable();
  }

  setSelectedNode(node: IGraphNode | null): void {
    this.selectedNode.next(node);
  }

  ngOnDestroy(): void {
    this.selectedNode.complete();
  }
}
