import { Injectable } from '@angular/core';
import { KeycloakConfig } from 'keycloak-js';
import { AlertService } from '../alert/alert.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class KeycloakConfigService {
  constructor(private readonly configService: ConfigService, private readonly alertService: AlertService) {}

  getConfig(): KeycloakConfig {
    try {
      return this.configService.getConfig().keycloack;
    } catch (e: unknown) {
      this.alertService.error('Feature enabling config has errors. Check config.json');
      throw e;
    }
  }
}
