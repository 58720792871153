import {
  Dashboard,
  BoardConfiguration,
  BoardDataSource,
  Datasource,
  FieldNameAlias,
  FieldValueAlias,
  DatasourceField as Field,
} from '@selfai-platform/bi-domain';

import { setSingleDataSource } from './set-single-datasource';

export function setDatasourceForDashboard(boardInfo: Dashboard): [Dashboard, Datasource] {
  const masterDsInfo: Datasource = null;

  const dashboardConf: BoardConfiguration = boardInfo.configuration;
  const boardDs: BoardDataSource = dashboardConf && dashboardConf.dataSource ? dashboardConf.dataSource : undefined;

  if (boardInfo.hasOwnProperty('dataSources') && boardInfo.dataSources.length > 0 && boardDs) {
    const nameAliasList: FieldNameAlias[] = boardInfo.aliases
      .filter((alias) => alias['nameAlias'])
      .map((item) => <FieldNameAlias>item);
    const valueAliasList: FieldValueAlias[] = boardInfo.aliases
      .filter((alias) => alias['valueAlias'])
      .map((item) => <FieldValueAlias>item);

    let summary: { reorderDsList: Datasource[]; totalFields: Field[] } = { reorderDsList: [], totalFields: [] };
    if ('multi' === boardDs.type) {
      summary = boardDs.dataSources.reduce(
        (acc, currVal) => {
          const singleSummary: { reorderDsList: Datasource[]; totalFields: Field[] } = setSingleDataSource(
            currVal,
            boardInfo,
            nameAliasList,
            valueAliasList,
          );
          acc.reorderDsList = acc.reorderDsList.concat(singleSummary.reorderDsList);
          acc.totalFields = acc.totalFields.concat(singleSummary.totalFields);
          return acc;
        },
        { reorderDsList: [], totalFields: [] },
      );
    } else {
      summary = setSingleDataSource(boardDs, boardInfo, nameAliasList, valueAliasList);
    }

    boardInfo.dataSources = summary.reorderDsList;
    dashboardConf.fields = summary.totalFields;
  }

  boardInfo.configuration = dashboardConf;

  return [boardInfo, masterDsInfo];
}
