import { isNullOrUndefined } from 'util';

import { Component, ElementRef, HostListener, Injector, Input, OnDestroy, OnInit } from '@angular/core';

import * as _ from 'lodash';

import { Role, RoleSet } from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { WORKSPACE_PERMISSION } from '../../../common/permission/permission';
import { CommonUtil } from '../../../common/util/common.util';
import { PermissionService } from '../../../user/service/permission.service';

@Component({
  selector: 'app-permission-schema',
  templateUrl: './permission-schema.component.html',
})
export class PermissionSchemaComponent extends AbstractComponent implements OnInit, OnDestroy {
  private _orgRoleSet: RoleSet;

  public editRoleSet: RoleSet;

  public errorMsg = '';

  @Input('roleSet')
  set setRoleSet(roleSet: RoleSet) {
    if (roleSet.roles && 0 < roleSet.roles.length) {
      roleSet.roles.forEach((role: Role) => {
        role['orgName'] = role.name;
        if (role.permissions && 0 < role.permissions.length) {
          role.permissionNames = role.permissions.map((item) => {
            return <string>(item.name ? item.name : item);
          });
        }
      });
    } else {
      roleSet = this._getBasicRoleSet(roleSet);
    }
    this._orgRoleSet = _.cloneDeep(roleSet);
    this.editRoleSet = _.cloneDeep(roleSet);
    this.editRoleSet.removeRoleNames = [];
    this.changeDetect.markForCheck();
  }

  @Input() public editMode = true;
  @Input() public desc = true;
  @Input() public useAPI = true;

  constructor(
    private permissionService: PermissionService,
    protected element: ElementRef,
    protected injector: Injector,
  ) {
    super(element, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  @HostListener('click', ['$event.target'])
  public clickOther(target) {
    this.errorMsg = '';
    this.editRoleSet.roles.forEach((item) => delete item['error']);
    const $eventTarget: JQuery = $(target);
    if (!$eventTarget.hasClass('ddp-txt-edit') && 0 === $eventTarget.closest('.ddp-txt-edit').length) {
      this.editRoleSet.roles.forEach((item) => this.resetRoleName(item));
    }
  }

  public addRole() {
    const role: Role = new Role();
    role.name = 'new role';
    role['isNewRole'] = true;
    role['editName'] = 'new role';
    role.defaultRole = 0 === this.editRoleSet.roles.length;
    this.editRoleSet.roles.push(role);
  }

  public removeRole(removeIdx: number) {
    if (isNullOrUndefined(this.editRoleSet.roles[removeIdx]['isNewRole'])) {
      this.editRoleSet.removeRoleNames.push(this.editRoleSet.roles[removeIdx].name);
    }

    -1 < removeIdx && this.editRoleSet.roles.splice(removeIdx, 1);

    1 === this.editRoleSet.roles.length && (this.editRoleSet.roles[0].defaultRole = true);
  }

  public setRoleNameByKeyboard(event: KeyboardEvent, role: Role) {
    if (13 === event.keyCode) {
      this.setRoleName(role);
    } else if (27 === event.keyCode) {
      this.resetRoleName(role);
    }
  }

  public setRoleName(role: Role) {
    role.name = role['editName'];
    role['edit'] = false;
  }

  public resetRoleName(role: Role) {
    role['editName'] = role.name;
    role['edit'] = false;
  }

  public checkValidPerm(role: Role, permKey: string) {
    if (role.permissionNames) {
      return -1 !== role.permissionNames.indexOf(permKey);
    } else {
      return false;
    }
  }

  public togglePerm(role: Role, permKey: string) {
    if (this.editMode) {
      let isAdd = true;
      if (role.permissionNames) {
        const permIdx: number = role.permissionNames.indexOf(permKey);
        if (-1 === permIdx) {
          role.permissionNames.push(permKey);
          isAdd = true;
        } else {
          role.permissionNames.splice(permIdx, 1);
          role.permissionNames = role.permissionNames.filter((perm) => perm !== permKey);
          isAdd = false;
        }
      } else {
        role.permissionNames = [permKey];
        isAdd = true;
      }

      role.permissionNames = this._setPermissionDependency(role.permissionNames, isAdd);
    }
  }

  public clickDefaultRole(idx: number) {
    if (this.editMode) {
      this.editRoleSet.roles.forEach((item, index) => {
        item.defaultRole = index === idx;
      });
    }
  }

  public setRoleSets(newRoleSetName?: string, newRoleSetDesc?: string): Promise<any> {
    const roleSet: RoleSet = this.editRoleSet;
    const roles: Role[] = roleSet.roles;

    if (roles && 0 < roles.length) {
      let isSetDefault = false;
      let prevName = '';
      for (let idx = 0, nMax = roles.length; idx < nMax; idx++) {
        if ('' === roles[idx].name.trim()) {
          const errMsg: string = this.translateService.instant('msg.permission.alert.no-name');
          roles[idx]['error'] = true;
          this.errorMsg = errMsg;
          this.loadingHide();
          this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.error'));
          return Promise.reject(errMsg);
        }
        if (prevName === roles[idx].name.trim()) {
          const errMsg: string = this.translateService.instant('msg.permission.alert.duplicate-name');
          roles[idx]['error'] = true;
          this.errorMsg = errMsg;
          this.loadingHide();
          this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.error'));
          return Promise.reject(errMsg);
        }
        if (!roles[idx].permissionNames || 1 > roles[idx].permissionNames.length) {
          const errMsg: string = this.translateService.instant('msg.permission.alert.require-perm');
          roles[idx]['error'] = true;
          this.errorMsg = errMsg;
          this.loadingHide();
          this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.error'));
          return Promise.reject(errMsg);
        }

        isSetDefault || (isSetDefault = roles[idx].defaultRole);
        prevName = roles[idx].name.trim();
      }
      if (!isSetDefault) {
        const errMsg: string = this.translateService.instant('msg.permission.alert.require-default');
        this.errorMsg = errMsg;
        this.loadingHide();
        this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.error'));
        return Promise.reject(errMsg);
      }
    } else {
      const errMsg: string = this.translateService.instant('msg.permission.alert.require-role');
      this.errorMsg = errMsg;
      this.loadingHide();
      this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.error'));
      return Promise.reject(errMsg);
    }

    return new Promise((resolve, reject) => {
      if (this.useAPI && this.isPermissionManager()) {
        const params = RoleSet.convertRoleSetToParam(roleSet);

        if (roleSet.id) {
          newRoleSetName && (roleSet.name = newRoleSetName);
          newRoleSetDesc && (roleSet.description = newRoleSetDesc);
          this.permissionService
            .updateRoleset(roleSet.id, params)
            .then((result) => {
              resolve(result);
            })
            .catch((err) => {
              this.commonExceptionHandler(err);
              reject(err);
            });
        } else {
          params.name = newRoleSetName ? newRoleSetName : CommonUtil.getUUID();
          newRoleSetDesc && (params.description = newRoleSetDesc);
          this.permissionService
            .createRoleset(<RoleSet>params)
            .then((result) => {
              resolve(result);
            })
            .catch((err) => {
              this.commonExceptionHandler(err);
              reject(err);
            });
        }
      } else {
        resolve(roleSet);
      }
    });
  }

  private _setPermissionDependency(permNames: string[], isAdd: boolean): string[] {
    const perms: string[][] = [
      [
        WORKSPACE_PERMISSION.PERM_WORKSPACE_MANAGE_WORKBOOK.toString(),
        WORKSPACE_PERMISSION.PERM_WORKSPACE_EDIT_WORKBOOK.toString(),
        WORKSPACE_PERMISSION.PERM_WORKSPACE_VIEW_WORKBOOK.toString(),
      ],
      [
        WORKSPACE_PERMISSION.PERM_WORKSPACE_MANAGE_NOTEBOOK.toString(),
        WORKSPACE_PERMISSION.PERM_WORKSPACE_EDIT_NOTEBOOK.toString(),
        WORKSPACE_PERMISSION.PERM_WORKSPACE_VIEW_NOTEBOOK.toString(),
      ],
      [
        WORKSPACE_PERMISSION.PERM_WORKSPACE_MANAGE_WORKBENCH.toString(),
        WORKSPACE_PERMISSION.PERM_WORKSPACE_EDIT_WORKBENCH.toString(),
        WORKSPACE_PERMISSION.PERM_WORKSPACE_VIEW_WORKBENCH.toString(),
      ],
    ];

    if (isAdd) {
      for (let idx0 = 0; idx0 < 3; idx0++) {
        for (let idx1 = 0; idx1 < 3; idx1++) {
          if (-1 < permNames.indexOf(perms[idx0][idx1])) {
            for (let idx2 = idx1; idx2 < 3; idx2++) {
              if (-1 === permNames.indexOf(perms[idx0][idx2])) {
                permNames.push(perms[idx0][idx2]);
              }
            }
          }
        }
      }
    } else {
      for (let idx0 = 0; idx0 < 3; idx0++) {
        for (let idx1 = 2; idx1 >= 0; idx1--) {
          if (-1 === permNames.indexOf(perms[idx0][idx1])) {
            for (let idx2 = idx1; idx2 >= 0; idx2--) {
              if (-1 < permNames.indexOf(perms[idx0][idx2])) {
                permNames = permNames.filter((perm) => perm !== perms[idx0][idx2]);
              }
            }
          }
        }
      }
    }

    return permNames;
  }

  private _getBasicRoleSet(definedRoleSet?: RoleSet): RoleSet {
    if (definedRoleSet) {
      definedRoleSet.roles.push(RoleSet.getDefaultRole());
      return definedRoleSet;
    } else {
      return new RoleSet();
    }
  }
}
