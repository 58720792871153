import { Routes } from '@angular/router';
import { RoutingData, SemanticCode } from '@selfai-platform/shared';
import { AppAuthGuard } from '@selfai-platform/shell';

export const aggregatorRouting: Routes = [
  {
    path: 'aggregators',
    canActivate: [AppAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../components/aggregator-camel-route-list').then((m) => m.AggregatorCamelRouteListComponentModule),
        pathMatch: 'full',
        data: { semanticCode: SemanticCode.AggregatorList } as RoutingData,
      },
      {
        path: ':uuid',
        loadChildren: () =>
          import('../components/aggregator-camel-editor').then((m) => m.AggregatorCamelEditorComponentModule),
        data: { semanticCode: SemanticCode.AggregatorEditor } as RoutingData,
      },
    ],
  },
];
