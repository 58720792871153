export interface BoardSyncOptions {
  enabled: boolean;
  interval?: number;
}

export function createBoardSyncOptions(interval?: number): BoardSyncOptions {
  if (interval) {
    return {
      enabled: true,
      interval: interval,
    };
  }
  return {
    enabled: false,
  };
}
