<div
  class="ddp-ui-board-filter"
  *ngIf="selectionFilterList && selectionFilterList.length > 0"
  [style.padding-right]="showBtnWidget ? '96px' : '0px'"
>
  <div class="ddp-btn-slider" style="display: none">
    <a href="javascript:" class="ddp-btn-prev"></a>
    <a href="javascript:" class="ddp-btn-next"></a>
    <em class="ddp-bg-shadow"></em>
  </div>

  <div class="ddp-wrap-board-filter" [class.ddp-initial]="scrollFreezing">
    <div class="ddp-list-board-filter ddp-clear">
      <div *ngIf="dashboard && 0 < boardFilters.length" class="ddp-box-board-time">
        <a href="javascript:" class="ddp-btn-time"><em class="ddp-icon-time4"></em></a>

        <div class="ddp-ui-tooltip-info">
          <em class="ddp-icon-view-top"></em>

          <dl *ngFor="let filter of boardFilters" class="ddp-dl-info">
            <dt>{{ filter.field }}, {{ filter['dsName'] }}</dt>
            <dd [innerHTML]="filter['panelContents']"></dd>
          </dl>
        </div>
      </div>

      <div class="ddp-box-board-filter" *ngIf="selectionFilterList.length === 0">
        <div class="ddp-box-dataselect">
          {{ 'msg.board.filter.sel.data' | translate }}
        </div>
      </div>
      <div class="ddp-box-board-time">
        <a
          href="javascript:"
          class="ddp-btn-reset ddp-hover-tooltip"
          (click)="resetFilter()"
          *ngIf="selectionFilterList.length !== 0"
        >
          <em class="ddp-bg-shadow"></em>
          {{ 'msg.board.filter.btn.refresh' | translate }}

          <div class="ddp-ui-tooltip-info ddp-down">
            <em class="ddp-icon-view-top"></em>
            {{ 'msg.board.filter.refresh.filter' | translate }}
          </div>
        </a>
      </div>
      <div
        class="ddp-box-board-filter"
        *ngFor="let selectFilter of selectionFilterList"
        [class.ddp-time]="'interval' === selectFilter.type"
      >
        <div
          (click)="toggleLayer(selectFilter, $event)"
          [class.ddp-selected]="selectFilter.selected"
          class="ddp-type-selectbox ddp-type-filter"
        >
          <em (click)="remove(selectFilter, true)" class="ddp-icon-sclose2"></em>

          <span *ngIf="!isTimeRangeFilter(selectFilter)" class="ddp-data-filter">
            <span class="ddp-txt-data">{{ getLabel(selectFilter) }}</span>
            <em *ngIf="1 < getValueCnt(selectFilter)" class="ddp-out">
              {{ 'msg.board.filter.ui.more.cnt' | translate: { cnt: getValueCnt(selectFilter) - 1 } }}
            </em>
          </span>

          <span class="ddp-data-filter ddp-data-date" *ngIf="isTimeRangeFilter(selectFilter)">
            <span class="ddp-txt-data">
              {{ selectFilter.minTime }}
              <br />
              -{{ selectFilter.maxTime }}
            </span>
          </span>

          <span class="ddp-txt-selectbox">{{ getDisplayName(selectFilter) }}</span>

          <ul class="ddp-list-selectbox ddp-selectdown" *ngIf="selectFilter.selected" (clickOutside)="closeFilter()">
            <li *ngFor="let item of selectFilter.valueList">
              <a href="javascript:">{{ item }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
