import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { KeycloakConfig } from 'keycloak-js';
import { KeycloakParsedToken } from './keycloak-parsed-token';
import { PipelinePermission, PipelinePermissionScope } from './pipeline-permission.enum';
import { PermissionRequest } from './permission.model';
import { BiPermission } from './bi-permission.enum';
import { SelfaiPermission } from './selfai-permission';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private currentUserPermissions: SelfaiPermission[] = [];
  private currentPipelinePermissions: PipelinePermission[] = [];
  private currentBiPermissions: BiPermission[] = [];

  setRolesFromToken(keycloakConfig: KeycloakConfig, token: string) {
    try {
      const decodedToken = jwt_decode(token) as KeycloakParsedToken;
      if (decodedToken.resource_access[keycloakConfig.clientId]) {
        const clientRoles = decodedToken.resource_access[keycloakConfig.clientId].roles;
        const realmRoles = decodedToken.realm_access.roles;
        this.setPermissions([...clientRoles, ...realmRoles]);
      }
    } catch (error) {
      console.error('Permission calculation has failed with error', error);
    }
  }

  getPermissions(): SelfaiPermission[] {
    return [...this.currentUserPermissions];
  }

  getPipelinePermissions(): PipelinePermission[] {
    return [...this.currentPipelinePermissions];
  }

  getBiPermissions(): BiPermission[] {
    return [...this.currentBiPermissions];
  }

  checkPermission(permissionRequest: PermissionRequest): boolean {
    const permissionAction = `${permissionRequest.entity}:${permissionRequest.action}`;

    return this.currentUserPermissions.some((permission) => {
      return (
        permissionAction === permission ||
        `${permissionAction}:${PipelinePermissionScope.Any}` ||
        (permissionRequest.isOwner && permission === `${permissionAction}:${PipelinePermissionScope.Own}`) ||
        (permissionRequest.isPublic && permission.includes(permissionAction))
      );
    });
  }

  private setPermissions(roleNames: string[]): void {
    for (const roleName of roleNames) {
      if (Object.values(PipelinePermission).includes(roleName as PipelinePermission)) {
        this.currentPipelinePermissions.push(roleName as PipelinePermission);
        this.currentUserPermissions.push(roleName as PipelinePermission);
      }
      if (Object.values(BiPermission).includes(roleName as BiPermission)) {
        this.currentBiPermissions.push(roleName as BiPermission);
        this.currentUserPermissions.push(roleName as BiPermission);
      }
    }
  }
}
