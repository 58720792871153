import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { PageAccesDeniedComponent } from './page-access-denied.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    RouterModule.forChild([
      {
        path: '',
        pathMatch: 'full',
        component: PageAccesDeniedComponent,
      },
    ]),
  ],
  declarations: [PageAccesDeniedComponent],
  exports: [PageAccesDeniedComponent],
})
export class AccessDeniedPageModule {}
