<div class="ddp-wrap-layout-popup" *ngIf="isShow">
  <div class="ddp-box-popup">
    <a href="javascript:" class="ddp-btn-close" (click)="close()"></a>

    <div class="ddp-pop-title" *ngIf="'UPDATE_WORKBOOK' === inputInfo.type">
      {{ 'msg.book.edit.workbook.title' | translate }}
    </div>
    <div class="ddp-pop-title" *ngIf="'CLONE_DASHBOARD' === inputInfo.type">
      {{ 'msg.board.clone.title' | translate }}
    </div>

    <div class="ddp-wrap-editbox">
      <div class="ddp-wrap-edit2" [class.ddp-error]="isInvalidName">
        <label class="ddp-label-type">{{ 'msg.comm.ui.name' | translate }}</label>
        <input
          type="text"
          class="ddp-input-type"
          placeholder="{{ 'msg.comm.ui.create.name' | translate }}"
          [(ngModel)]="inputInfo.name"
          (focus)="isInvalidName = false"
          maxlength="50"
        />

        <span class="ddp-ui-error">{{ errMsgName }}</span>
      </div>

      <div class="ddp-wrap-edit2" [class.ddp-error]="isInvalidDesc">
        <label class="ddp-label-type">{{ 'msg.comm.ui.description' | translate }}</label>
        <input
          type="text"
          class="ddp-input-type"
          placeholder="{{ 'msg.comm.ui.create.desc' | translate }}"
          [(ngModel)]="inputInfo.desc"
          (focus)="isInvalidDesc = false"
          maxlength="150"
        />

        <span class="ddp-ui-error">{{ errMsgDesc }}</span>
      </div>

      <div class="ddp-txt-alert"></div>
    </div>

    <div class="ddp-ui-buttons">
      <a href="javascript:" class="ddp-btn-type-popup" (click)="close()">{{ 'msg.comm.btn.cancl' | translate }}</a>

      <a
        href="javascript:"
        class="ddp-btn-type-popup ddp-bg-black"
        [class.ddp-disabled]="isInvalidName || isInvalidDesc || !inputInfo.name || '' === inputInfo.name"
        (click)="btnDoneHandler()"
      >
        {{ 'msg.comm.btn.done' | translate }}
      </a>
    </div>
  </div>
</div>
