import { BehaviorSubject, Observable, Subscription, distinctUntilChanged, filter, map } from 'rxjs';

export interface Loader<T> {
  loading: boolean;
  success: boolean;
  error: boolean;
  value: T;
}

export class LoaderUtil<T> extends BehaviorSubject<Loader<T | undefined>> {
  constructor(value?: T) {
    super({
      loading: false,
      success: false,
      error: false,
      value,
    });
  }

  loadFromSource(
    loadDataSource: Observable<T>,
    subject?: {
      next?: ((value: T) => void) | null;
      error?: ((error: unknown) => void) | null;
      complete?: (() => void) | null;
    },
  ): Subscription {
    this.next(this.loading());

    return loadDataSource.subscribe({
      next: (value: T) => {
        this.next(this.loaded(value));
        subject?.next?.(value);
      },
      error: (err: unknown) => {
        this.next(this.catchError());
        subject?.error?.(err);
      },
      complete: subject?.complete || undefined,
    });
  }

  getLoading(): Observable<boolean> {
    return this.pipe(
      filter(Boolean),
      map(({ loading }) => loading),
      distinctUntilChanged(),
    );
  }

  getError(): Observable<boolean> {
    return this.pipe(
      filter(Boolean),
      map(({ error }) => error),
      distinctUntilChanged(),
    );
  }

  getSuccess(): Observable<boolean> {
    return this.pipe(
      filter(Boolean),
      map(({ success }) => success),
      distinctUntilChanged(),
    );
  }

  protected loaded(value: T): Loader<T> {
    return {
      error: false,
      loading: false,
      success: true,
      value,
    };
  }

  protected loading(): Loader<T | undefined> {
    return {
      ...this.value,
      error: false,
      loading: true,
      success: false,
    };
  }

  protected catchError(): Loader<T | undefined> {
    return {
      ...this.value,
      error: true,
      loading: false,
      success: false,
    };
  }
}
