import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationRef, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { ClickOutsideModule } from 'ng-click-outside';
import { CookieService } from 'ng2-cookies';
import { ClipboardModule } from 'ngx-clipboard';

import { BiDomainModule } from '@selfai-platform/bi-domain';
import { BiSharedModule } from '@selfai-platform/bi-shared';
import { SelfaiTranslateService, ShellModule } from '@selfai-platform/shell';

import { MembersService } from './admin/user-management/service/members.service';
import { AppComponent } from './app.component';
import { AuthService } from './auth/auth.service';
import { ChartAdapterModule } from './chart-list/chart-adapter.module';
import { EventBroadcaster } from './common/event/event.broadcaster';
import { FileModule } from './common/file.module';
import { CanDeactivateGuard } from './common/gaurd/can.deactivate.guard';
import { ConnectionListGuard } from './common/gaurd/connection-list.guard';
import { DatasourceManagementGuard } from './common/gaurd/datasource-management.guard';
import { MetadataManagementGuard } from './common/gaurd/metadata-management.guard';
import { SsoKeycloakGuard } from './common/gaurd/sso-keycloak.guard';
import { StagedbEnabledGuard } from './common/gaurd/stagedb-enabled.guard';
import { LoaderInterceptor } from './common/interceptor/loader-interceptor';
import { AbstractService } from './common/service/abstract.service';
import { CommonService } from './common/service/common.service';
import { PopupService } from './common/service/popup.service';
import { UnloadConfirmService } from './common/service/unload.confirm.service';
import { DashboardModule } from './dashboard/dashboard.module';
import { UpdateDashboardComponent } from './dashboard/update-dashboard.component';
import { StorageService } from './data-storage/service/storage.service';
import { LayoutComponent } from './layout/layout.component';
import { BiRoutes } from './routes';
import { ActivityService } from './user/service/activity.service';
import { CheckPermissionService } from './user/service/check-permission.service';
import { UserService } from './user/service/user.service';
import { WorkspaceTreeProviderModule } from './workspace/component/workspace-tree/workspace-tree-provider.module ';
import { UserWorkspaceService } from './workspace/service/user-workspace.service';
import { WorkspaceService } from './workspace/service/workspace.service';
import { WorkspaceShareModule } from './workspace/workspace-share.module';

library.add(fas, far);

@NgModule({
  declarations: [AppComponent, LayoutComponent],
  imports: [
    RouterModule.forChild(BiRoutes),
    CommonModule,
    BiSharedModule,
    BiDomainModule,
    ChartAdapterModule.forRoot(),
    WorkspaceTreeProviderModule.forRoot(),
    ClickOutsideModule,
    WorkspaceShareModule,
    FileModule,
    ClipboardModule,
    FontAwesomeModule,
    DashboardModule,
    TranslateModule,
    ShellModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    PopupService,
    SsoKeycloakGuard,
    UserService,
    CheckPermissionService,
    AuthService,
    CookieService,
    AbstractService,
    CanDeactivateGuard,
    UnloadConfirmService,
    EventBroadcaster,
    UpdateDashboardComponent,
    UserWorkspaceService,
    CookieService,
    MembersService,
    WorkspaceService,
    CommonService,
    ActivityService,
    MetadataManagementGuard,
    DatasourceManagementGuard,
    StorageService,
    StagedbEnabledGuard,
    ConnectionListGuard,
  ],
})
export class BiModule {
  constructor(public appRef: ApplicationRef, selfaiTranslateService: SelfaiTranslateService) {
    const currentLanguage = selfaiTranslateService.getCurrentLanguage();
    moment.locale(currentLanguage);
    selfaiTranslateService.$onLanguageChange.subscribe((language) => {
      moment.locale(language);
    });
  }
}
