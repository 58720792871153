import { Injectable } from '@angular/core';
import { LoaderUtil } from '@selfai-platform/shared';
import { Observable, Subject, catchError, concatMap, of, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { AggregatorProcessWithRoutes } from '../models/aggregator-process.model';
import { AggregatorAdapter } from './api';

@Injectable()
export class AggregatorProcessUpdateService extends LoaderUtil<void> {
  private queue = new Subject<AggregatorProcessWithRoutes>();

  constructor(private readonly aggregatorAdapter: AggregatorAdapter) {
    super();

    this.getQueue()
      .pipe(
        tap(() => {
          this.loading();
        }),
        concatMap((process) =>
          this.aggregatorAdapter.saveRoutes(process).pipe(
            catchError(() => {
              this.catchError();
              return of();
            }),
          ),
        ),
      )
      .subscribe(() => {
        this.loaded();
      });
  }

  saveProcess(processWithRoutes: AggregatorProcessWithRoutes): void {
    this.addQueue(processWithRoutes);
  }

  cloneProcess(uuid: string): void {
    this.aggregatorAdapter.loadProcessWithRoutes(uuid).subscribe((processWithRoutes) => {
      processWithRoutes.process.uuid = uuidv4();
      processWithRoutes.process.processName = `Copy ${processWithRoutes.process.processName}`;

      this.saveProcess(processWithRoutes);
    });
  }

  private addQueue(processWithRoutes: AggregatorProcessWithRoutes): void {
    this.queue.next(processWithRoutes);
  }

  private getQueue(): Observable<AggregatorProcessWithRoutes> {
    return this.queue.asObservable();
  }
}
