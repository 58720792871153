import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Session, SessionStatus, WorkflowClonePayload, WorkflowInfo } from '@selfai-platform/pipeline-common';
import { AlertService, UserProfileService } from '@selfai-platform/shared';
import { BehaviorSubject, combineLatest, filter, map, Observable, withLatestFrom } from 'rxjs';
import { SessionsManagerService } from '../../services/sessions-manager.service';
import { WorkflowApiService } from '../../services/workflow-api.service';

export interface Workflow extends WorkflowInfo {
  status?: SessionStatus;
  isOwner?: boolean;
  running: boolean;
  stopInProgress?: boolean;
}

export type WorkflowUpdateOptions = Pick<Workflow, 'id'> & Partial<Workflow>;

@Injectable()
export class WorkflowListComponentService extends BehaviorSubject<Workflow[]> {
  constructor(
    private readonly workflowApiService: WorkflowApiService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly sessionsManagerService: SessionsManagerService,
    private readonly userProfileService: UserProfileService,
  ) {
    super([]);
  }

  loadWorkflowList(): void {
    this.sessionsManagerService.loadSessionList();

    this.workflowApiService
      .getAllWorkflows()
      .pipe(map((workflowList) => workflowList.map((workflow) => ({ ...workflow, running: false }))))
      .subscribe({
        next: this.next.bind(this),
        error: () => {
          this.alertService.error('Error of loading workflow');
        },
      });
  }

  getWorkflowList(): Observable<Workflow[]> {
    return combineLatest([
      this.asObservable(),
      this.sessionsManagerService.getSessionList().pipe(filter(Boolean)),
      this.userProfileService.getUserProfile(),
    ]).pipe(
      map(([worflows, sessions, userProfile]) => {
        const sessionMap = new Map<string, Session>();
        sessions.forEach((session) => sessionMap.set(session.workflowId, session));

        return worflows.map((worflow) => ({
          ...worflow,
          status: sessionMap.get(worflow.id)?.status,
          isOwner: userProfile.id === worflow.ownerId,
          running: sessionMap.get(worflow.id)?.status === SessionStatus.RUNNING,
        }));
      }),
    );
  }

  getWorkflowById(id: string): Observable<Workflow | undefined> {
    return this.getWorkflowList().pipe(map((workflowList) => workflowList.find((workflow) => workflow.id === id)));
  }

  deleteWorkflow(id: string): void {
    this.workflowApiService
      .deleteWorkflow(id)
      .pipe(withLatestFrom(this.getWorkflowList()))
      .subscribe({
        next: ([, workflowList]) => {
          this.next(workflowList.filter((workflow) => workflow.id !== id));
        },
        error: () => {
          this.alertService.error('Error of deleting workflow');
        },
      });
  }

  cloneWorkflow(payload: WorkflowClonePayload): void {
    this.workflowApiService.cloneWorkflow(payload).subscribe({
      next: () => {
        this.loadWorkflowList();
      },
      error: () => {
        this.alertService.error('Error of cloning workflow');
      },
    });
  }

  addWorkflow(payload: WorkflowClonePayload): void {
    this.workflowApiService.createWorkflow(payload).subscribe({
      next: ({ workflowId }) => {
        this.router.navigate(['pipeline', 'workflow', workflowId]);
      },
      error: () => {
        this.alertService.error('Error of adding workflow');
      },
    });
  }

  getWorkflowFileUploadUrl(): string {
    return this.workflowApiService.getUploadWorkflowMethodUrl();
  }

  updateWorkflowState(updateOptions: WorkflowUpdateOptions): void {
    const updateWorkflow = this.getValue().map((workflow) => {
      if (workflow.id === updateOptions.id) {
        return { ...workflow, ...updateOptions };
      }
      return workflow;
    });
    this.next(updateWorkflow);
  }
}
