import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { AlertService } from '@selfai-platform/shared';

import { BI_ROOT_ROUTE } from '../../core';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  protected router: Router;

  constructor(
    protected injector: Injector,
    protected alertPrimeService: AlertService,
    @Inject(BI_ROOT_ROUTE) private readonly biRootRoute: string,
  ) {
    this.router = injector.get(Router);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const showLoading = !request.headers.has('hideLoading');
    if (showLoading) {
      this.totalRequests += 1;
    }
    if (request.responseType === 'json') {
      const jsonHeaders = request.headers.append('x-requested-with', 'XMLHttpRequest');
      request = request.clone({ headers: jsonHeaders });
    }
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err.error.details === 'User ip is not in whitelist.') {
          this.alertPrimeService.error(err.error.details);
          this.router.navigate([this.biRootRoute]);
          return of(err);
        }
        throw err;
      }),
      finalize(() => {
        if (showLoading) {
          this.decreaseRequests();
        }
      }),
    );
  }

  private decreaseRequests() {
    if (this.totalRequests > 0) {
      this.totalRequests -= 1;
    }
  }
}
