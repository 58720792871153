import { ModuleWithProviders, NgModule } from '@angular/core';
import { DashboardApiService } from './dashboard-api.service';
import { DashboardAdapter } from './dashboard.adapter';

@NgModule()
export class DashboardAdapterModule {
  static forRoot(): ModuleWithProviders<DashboardAdapterModule> {
    return {
      ngModule: DashboardAdapterModule,
      providers: [{ provide: DashboardAdapter, useClass: DashboardApiService }],
    };
  }
}
