import { Inject, Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { MAIN_MENU_CHUNK } from './main-menu-chunk.token';

@Injectable({ providedIn: 'root' })
export class MenuItemsService extends BehaviorSubject<MenuItem[]> {
  constructor(@Inject(MAIN_MENU_CHUNK) private readonly mainMenuChunks: (MenuItem | null)[]) {
    super([]);
  }

  get menu$() {
    return this.asObservable();
  }

  initMenu() {
    const menu: MenuItem[] = [];

    if (this.mainMenuChunks?.length) {
      const menuItems: MenuItem[] = this.mainMenuChunks.filter(
        (rootItem) => rootItem && (!this.menuIsEmpty(rootItem) || rootItem.routerLink || rootItem.url),
      ) as MenuItem[];
      this.sortRootItems(menuItems);
      menu.push(...menuItems);
    }

    this.next(this.mergeRootItemsById(menu));
  }

  private menuIsEmpty(menu: MenuItem): boolean {
    return !menu.items?.length;
  }

  private sortRootItems(menuItems: MenuItem[]): void {
    menuItems.sort((a, b) => {
      const sortA: number = a.state?.['sort'] || Infinity;
      const sortB: number = b.state?.['sort'] || Infinity;

      return sortA - sortB;
    });
  }

  private mergeRootItemsById(menuItems: MenuItem[]): MenuItem[] {
    const mergedItems = new Map<string | MenuItem, MenuItem>();

    menuItems.forEach((item) => {
      if (item.id) {
        const prevItems = mergedItems.get(item.id)?.items || [];
        mergedItems.set(item.id, { ...item, items: [...prevItems, ...(item.items || [])] });
      } else {
        mergedItems.set(item, item);
      }
    });

    return Array.from(mergedItems.values());
  }
}
