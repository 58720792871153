<p-confirmPopup></p-confirmPopup>

<p-table
  *ngIf="workflowList$ | async as workflowList"
  [value]="workflowList"
  [paginator]="workflowList.length > 10"
  [rows]="10"
  [showCurrentPageReport]="true"
  [tableStyle]="{ 'min-width': '100%' }"
  [rowsPerPageOptions]="[10, 25, 50]"
  [globalFilterFields]="['name', 'description']"
  sortField="updated"
  [sortOrder]="-1"
  styleClass="p-datatable-striped"
>
  <ng-template pTemplate="caption">
    <div class="flex justify-content-between">
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input pAutoFocus [autofocus]="true" pInputText type="text" (input)="filter($event)" placeholder="Search" />
      </span>
      <span>
        <button
          *ngIf="checkPermission(permissionAction.Create)"
          type="button"
          class="mr-3 p-button-primary"
          (click)="addWorkflow()"
          (keydown.enter)="addWorkflow()"
          pRipple
          pButton
          icon="pi pi-plus-circle"
          label="Add"
        ></button>
        <button
          *ngIf="checkPermission(permissionAction.Upload)"
          type="button"
          class="mr-3 p-button-primary p-button-outlined"
          (click)="uploadWorkflow()"
          (keydown.enter)="uploadWorkflow()"
          pRipple
          pButton
          icon="pi pi-upload"
          label="Upload"
        ></button>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="name" style="width: 20%">
        Name
        <p-sortIcon field="name"></p-sortIcon>
        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="description" style="width: 25%">
        Description
        <p-sortIcon field="description"></p-sortIcon>
        <p-columnFilter type="text" field="description" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="created" style="width: 12%">
        Created
        <p-sortIcon field="created"></p-sortIcon>
        <p-columnFilter type="date" field="created" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="updated" style="width: 12%">
        Last Modified
        <p-sortIcon field="updated"></p-sortIcon>
        <p-columnFilter type="date" field="updated" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="ownerName" style="width: 15%">
        Author
        <p-sortIcon field="ownerName"></p-sortIcon>
        <p-columnFilter type="text" field="ownerName" display="menu"></p-columnFilter>
      </th>
      <th pSortableColumn="running" style="width: 10%">
        Running
        <p-sortIcon field="running"></p-sortIcon>
        <p-columnFilter type="boolean" field="running" display="menu"></p-columnFilter>
      </th>
      <th style="width: 9%">Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-workflow>
    <tr>
      <td class="cursor-pointer">
        <a [routerLink]="['workflow', workflow.id]">{{ workflow.name }}</a>
      </td>
      <td tabindex="-1" class="cursor-pointer" [routerLink]="['workflow', workflow.id]">{{ workflow.description }}</td>
      <td tabindex="-1" class="cursor-pointer" [routerLink]="['workflow', workflow.id]">
        {{ workflow.created | date: 'dd.MM.yyyy, hh:mm' }}
      </td>
      <td tabindex="-1" class="cursor-pointer" [routerLink]="['workflow', workflow.id]">
        {{ workflow.updated | date: 'dd.MM.yyyy, hh:mm' }}
      </td>
      <td tabindex="-1">{{ workflow.ownerName }}</td>
      <td tabindex="-1">
        <button
          *ngIf="
            workflow.running &&
            !workflow.stopInProgress &&
            (checkPermission(permissionAction.Run, workflow.isOwner) ||
              checkPermission(permissionAction.Execute, workflow.isOwner))
          "
          type="button"
          pButton
          class="p-button-text p-button-link ml-2 p-0"
          appearance="icon"
          (click)="stopSessionWorkflow(workflow.id)"
          icon="pi pi-power-off"
          tooltipPosition="left"
          pTooltip="Stop session"
        ></button>
        <button
          *ngIf="
            workflow.running &&
            workflow.stopInProgress &&
            (checkPermission(permissionAction.Run, workflow.isOwner) ||
              checkPermission(permissionAction.Execute, workflow.isOwner))
          "
          type="button"
          pButton
          class="p-button-text p-button-link ml-2 p-0"
          appearance="icon"
          icon="pi pi-spin pi-spinner"
          tooltipPosition="left"
          pTooltip="Stop in progress"
        ></button>
      </td>
      <td>
        <div class="flex">
          <button
            *ngIf="checkPermission(permissionAction.Clone, workflow.isOwner)"
            type="button"
            pButton
            class="p-button-text p-button-link p-0"
            appearance="icon"
            (click)="cloneWorkflow(workflow.id)"
            icon="pi pi-copy"
            tooltipPosition="left"
            pTooltip="Clone workflow"
          ></button>
          <button
            *ngIf="checkPermission(permissionAction.Delete, workflow.isOwner)"
            type="button"
            pButton
            class="p-button-text p-button-link p-button-danger p-0"
            appearance="icon"
            (click)="removeWorkflow($event, workflow.id)"
            icon="pi pi-trash"
            tooltipPosition="left"
            pTooltip="Delete workflow"
          ></button>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft">
    <p-button
      *ngIf="checkPermission(permissionAction.Create)"
      pTooltip="Add new workflow"
      (click)="addWorkflow()"
      (keydown.enter)="addWorkflow()"
      type="button"
      icon="pi pi-plus-circle"
      styleClass="p-button-text"
      label="New workflow"
    ></p-button>
  </ng-template>
</p-table>
