<div *ngIf="isWidget && dashboard$ | async as dashboard" class="ddp-wrap-widget">
  <page-widget
    #widgetComponent
    *ngIf="isPageWidget"
    [widgetOption]="getBoardWidgetOpts()"
    [widget]="getWidget()"
    [dashboard]="dashboard"
    [layoutMode]="getLayoutMode()"
    [isShowTitle]="isShowTitle"
    [readonly]="readonly"
  ></page-widget>
  <text-widget
    #widgetComponent
    *ngIf="isTextWidget"
    [widget]="getWidget()"
    [layoutMode]="getLayoutMode()"
  ></text-widget>
  <filter-widget
    #widgetComponent
    *ngIf="isFilterWidget"
    [widget]="getWidget()"
    [dashboard]="dashboard"
    [layoutMode]="getLayoutMode()"
    [isShowTitle]="isShowTitle"
  ></filter-widget>
</div>
