import { FactoryProvider, Injector, ValueProvider } from '@angular/core';

import { MenuItem } from 'primeng/api';

import {
  BiPermission,
  KdConfigService,
  KdFeatureEnableService,
  KdFeatureName,
  PermissionService,
} from '@selfai-platform/shared';
import { provideMainMenuChunkFactory } from '@selfai-platform/shell';

import { BI_ROOT_ROUTE } from '../core';

export function providesKnowledgeDesignerMainMenuChunk(): FactoryProvider | ValueProvider {
  return provideMainMenuChunkFactory((injector: Injector) => {
    const biRootRoute = injector.get(BI_ROOT_ROUTE);
    const kdConfigService = injector.get(KdConfigService);
    const kdEnbaledFeatureService = injector.get(KdFeatureEnableService);
    const permissionService = injector.get(PermissionService);

    if (!kdConfigService.getConfig()?.enabled) {
      return null;
    }

    const biPermissions = permissionService.getBiPermissions();
    const items: MenuItem[] = [];

    if (
      kdEnbaledFeatureService.isEnabledFeature(KdFeatureName.WORKBOOKS) &&
      biPermissions.includes(BiPermission.PERM_SELFAI_WORKSPACE_VIEW)
    ) {
      items.push({
        label: 'shell.menu.workbooks',
        routerLink: [biRootRoute, 'workspace'],
        icon: 'pi pi-book',
      });
    }

    if (
      kdEnbaledFeatureService.isEnabledFeature(KdFeatureName.DASHBOARDS) &&
      biPermissions.includes(BiPermission.PERM_SELFAI_WORKBOOK_VIEW)
    ) {
      items.push({
        label: 'shell.menu.dashboards',
        routerLink: ['kd', 'dashboards'],
        icon: 'pi pi-chart-line',
      });
    }

    if (
      kdEnbaledFeatureService.isEnabledFeature(KdFeatureName.CHARTS) &&
      biPermissions.includes(BiPermission.PERM_SELFAI_DASHBOARD_VIEW)
    ) {
      items.push({
        label: 'shell.menu.charts',
        routerLink: ['kd', 'charts'],
        icon: 'pi pi-chart-bar',
      });
    }

    if (items.length < 0) {
      return null;
    }

    return {
      id: 'projects',
      label: 'shell.menu.knowledge-designer',
      state: { sort: 200 },
      items,
    };
  });
}
