import { HistoryEntity } from '../core';
import { Dashboard } from '../dashboard';
import { Datasource, ImplementorType } from '../datasource';
import { UserProfile } from '../user';

export interface Book extends HistoryEntity {
  id?: string;
  type?: string;
  name?: string;
  description?: string;
  favorite?: boolean;
  tag?: string;
  workspace?: any;
  folderId?: string;
  contents?: Contents;
  kernelType?: string;
  dsName?: string;
  checked?: boolean;
  edit?: boolean;
}

export function createBook(params: Partial<Book> = {}): Book { 
  return {
    ...params,
  }
}

export interface Workbook extends Book {
  configuration: any;
  pages: any[];
  dataSources: any[];
  workBookType?: string;
}

export function createWorkbook(params: Partial<Workbook> = {}): Workbook {
  return {
    ...createBook(),
    configuration: {},
    pages: [],
    dataSources: [],
    ...params,
  }
}

export interface WorkbookDefaultProjections extends Workbook {
  createdBy: UserProfile;
  modifiedBy: UserProfile;
  folderId: string;
  workspaceId: string;
}

export interface WorkbookDetailProjections extends Workbook {
  dashBoards?: Dashboard[];
  countOfComments?: number;
  dataSource?: Datasource[];
  workspaceId?: string;
}

export function createWorkbookDetailProjections(params: Partial<WorkbookDetailProjections> = {}): WorkbookDetailProjections {
  return {
    ...createWorkbook(),
    ...params,
  }
}

export interface Contents {
  dataSource: number;
  dashboard: number;
  connType: ImplementorType;
  connName: string;
  connValid: boolean;
}

export interface BookTree {
  name: string;
  id: string;
  type: string;

  books: BookTree[];
  dashBoards: BookTree[];
  hierarchies: any[];
}
