<div
  (mouseover)="setForceStyle(true)"
  (mouseout)="setForceStyle(false)"
  [ngClass]="{
    'ddp-active': isShowDownloadPopup,
    'ddp-popup-show': isShowDownloadPopup,
    'ddp-page-mapview': 'map' === chartType,
    'type-event-trigger': isShowEvtTriggerEditor
  }"
  class="ddp-box-widget ddp-box-graph"
  style="display: block"
>
  <div
    class="ddp-top-control ddp-clear"
    *ngIf="isViewMode && (!errorInfo || !errorInfo.show) && !isShowEvtTriggerEditor"
  >
    <div *ngIf="!readonly" class="ddp-ui-buttons">
      <div class="ddp-box-btn2" *ngIf="isRealTimeDs">
        <em (click)="toggleSync()" class="ddp-icon-widget-synch" [class.ddp-on]="isRealTimeWidget"></em>

        <div class="ddp-ui-tooltip-info">
          <em class="ddp-icon-view-down"></em>
          {{ 'msg.board.ui.sync' | translate }}
        </div>
      </div>

      <div class="ddp-box-btn2" *ngIf="limitInfo.isShow">
        <em class="ddp-icon-widget-info2"></em>

        <div class="ddp-box-info ddp-limitation">
          <em class="ddp-icon-info"></em>
          <span
            class="ddp-total"
            [innerHTML]="
              'msg.page.ui.limit-msg-1'
                | translate
                  : { currentCnt: limitInfo.currentCnt | numberCommas, maxCnt: limitInfo.maxCnt | numberCommas }
            "
          ></span>
          {{ 'msg.page.ui.limit-msg-2' | translate }}
        </div>
      </div>

      <div
        class="ddp-box-btn-hover"
        *ngIf="isShowChartTools && !isGridType() && chartFuncValidator.checkUseZoomByTypeString(chartType)"
      >
        <a (click)="changeMouseZoomMode('dragzoomin')" href="javascript:" class="ddp-btn-tool">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7zm-3-8c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm3 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm3 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
            />
          </svg>
        </a>
        <a (click)="changeMouseZoomMode('zoomin')" href="javascript:" class="ddp-btn-tool">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M13 10h-3v3h-2v-3h-3v-2h3v-3h2v3h3v2zm8.172 14l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z"
            />
          </svg>
        </a>
        <a (click)="changeMouseZoomMode('zoomout')" href="javascript:" class="ddp-btn-tool">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M13 10h-8v-2h8v2zm8.172 14l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z"
            />
          </svg>
        </a>
        <a (click)="changeMouseZoomMode('revert')" href="javascript:" class="ddp-btn-tool">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M13.818 16.646c-1.273.797-2.726 1.256-4.202 1.354l-.537-1.983c2.083-.019 4.132-.951 5.49-2.724 2.135-2.79 1.824-6.69-.575-9.138l-1.772 2.314-1.77-6.469h6.645l-1.877 2.553c3.075 2.941 3.681 7.659 1.423 11.262l7.357 7.357-2.828 2.828-7.354-7.354zm-11.024-1.124c-1.831-1.745-2.788-4.126-2.794-6.522-.005-1.908.592-3.822 1.84-5.452 1.637-2.138 4.051-3.366 6.549-3.529l.544 1.981c-2.087.015-4.142.989-5.502 2.766-2.139 2.795-1.822 6.705.589 9.154l1.774-2.317 1.778 6.397h-6.639l1.861-2.478z"
            />
          </svg>
        </a>
      </div>

      <div *ngIf="isShowChartTools && isNotMapType" [class.ddp-box-download]="duringDataDown" class="ddp-box-btn2">
        <button
          type="button"
          pButton
          class="p-button-text p-button-link w-auto py-1"
          appearance="icon"
          [icon]="duringDataDown ? 'pi pi-spin pi-spinner' : 'pi pi-file-excel'"
          (click)="showPreviewDownData($event)"
          pTooltip="{{ 'msg.book.widget.header.savetable' | translate }}"
          tooltipPosition="bottom"
        ></button>
      </div>
      <div *ngIf="isShowChartTools && !isGridType()" class="ddp-box-btn2">
        <button
          type="button"
          pButton
          class="p-button-text p-button-link w-auto py-1"
          appearance="icon"
          [icon]="duringImageDown ? 'pi pi-spin pi-spinner' : 'pi pi-image'"
          (click)="downloadChartImage()"
          pTooltip="{{ 'msg.book.widget.header.savechart' | translate }}"
          tooltipPosition="bottom"
        ></button>
      </div>

      <div class="ddp-box-btn2" *ngIf="checkPermissions.permissions.DASHBOARD.EDIT" (click)="editWidget()">
        <button
          type="button"
          pButton
          class="p-button-text p-button-link w-auto py-1"
          appearance="icon"
          icon="pi pi-pencil"
          (click)="editWidget()"
          pTooltip="{{ 'msg.comm.ui.edit' | translate }}"
          tooltipPosition="bottom"
        ></button>
      </div>

      <div class="ddp-box-btn2">
        <button
          [routerLink]="['/kd', 'preview', 'widget', widget.id]"
          type="button"
          pButton
          class="p-button-text p-button-link w-auto py-1"
          appearance="icon"
          [icon]="!isMaximize ? 'pi pi-window-maximize' : 'pi pi-window-minimize'"
          [pTooltip]="(isMaximize ? 'msg.comm.ui.edit' : 'msg.comm.ui.expansion') | translate"
          tooltipPosition="bottom"
        ></button>
      </div>

      <div class="ddp-ui-btnview" *ngIf="false">
        <div class="ddp-box-btn" [class.ddp-selected]="'chart' === widget.mode" (click)="changeMode('chart')">
          <em class="ddp-icon-widget-graph"></em>
          <div class="ddp-ui-tooltip-info">
            <em class="ddp-icon-view-down"></em>
            {{ 'msg.book.widget.header.chartview' | translate }}
          </div>
        </div>
        <div class="ddp-box-btn" [class.ddp-selected]="'grid' === widget.mode" (click)="changeMode('grid')">
          <em class="ddp-icon-widget-grid"></em>
          <div class="ddp-ui-tooltip-info">
            <em class="ddp-icon-view-down"></em>
            {{ 'msg.book.widget.header.tableview' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isShowWidgetName()" class="ddp-ui-graph-name">
    <span class="ddp-data-name">{{ widget.name }}</span>

    <div class="ddp-ui-tooltip-info">
      <em class="ddp-icon-view-top"></em>
      {{ widget.name }}
    </div>
  </div>

  <div
    class="ddp-view-widget ddp-view-widget-chart"
    *ngIf="widget"
    [class.ddp-view-name]="isShowWidgetName()"
    [style.display]="!isShowNoData && widget.mode === 'chart' ? 'block' : 'none'"
  >
    <bar-chart
      #chart
      *ngIf="chartType === 'bar' && uiOption"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></bar-chart>
    <grid-chart
      #chart
      *ngIf="chartType === 'grid' && uiOption"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></grid-chart>

    <input-grid-chart #chart *ngIf="chartType === 'input' && uiOption"></input-grid-chart>

    <line-chart
      #chart
      *ngIf="chartType === 'line' && uiOption"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></line-chart>

    <scatter-chart
      #chart
      *ngIf="chartType === 'scatter' && uiOption"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></scatter-chart>

    <heatmap-chart
      #chart
      *ngIf="chartType === 'heatmap' && uiOption"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></heatmap-chart>

    <pie-chart
      #chart
      *ngIf="chartType === 'pie' && uiOption"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></pie-chart>

    <label-chart
      #chart
      *ngIf="chartType === 'label' && uiOption"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      [query]="query"
      [widget]="widget"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></label-chart>

    <boxplot-chart
      #chart
      *ngIf="chartType === 'boxplot' && uiOption"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></boxplot-chart>

    <waterfall-chart
      #chart
      *ngIf="chartType === 'waterfall' && uiOption"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></waterfall-chart>

    <wordcloud-chart
      #chart
      *ngIf="chartType === 'wordcloud' && uiOption"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></wordcloud-chart>

    <combine-chart
      #chart
      *ngIf="chartType === 'combine' && uiOption"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></combine-chart>

    <treemap-chart
      #chart
      *ngIf="chartType === 'treemap' && uiOption"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></treemap-chart>

    <radar-chart
      #chart
      *ngIf="chartType === 'radar' && uiOption"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></radar-chart>

    <network-chart
      #chart
      *ngIf="chartType === 'network' && uiOption"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></network-chart>
    <sankey-chart
      #chart
      *ngIf="chartType === 'sankey' && uiOption"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></sankey-chart>
    <gauge-chart
      #chart
      *ngIf="chartType === 'gauge' && uiOption"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></gauge-chart>

    <map-chart
      #chart
      *ngIf="chartType === 'map' && uiOption"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
      (changeDraw)="changeDraw()"
    ></map-chart>
  </div>

  <div
    [style.display]="!isShowNoData && widget.mode === 'grid' ? 'block' : 'none'"
    style="width: 100%; height: 100%"
    *ngIf="isAvaliableGrid(widget)"
  >
    <grid-chart #gridChart *ngIf="gridUiOption" [uiOption]="gridUiOption" [viewMode]="true"></grid-chart>
  </div>

  <div *ngIf="isShowHierarchyView" class="ddp-view-widget ddp-view-widget-grid">
    <div class="ddp-box-data-none">
      <div class="ddp-wrap-data-none">
        <div class="ddp-ui-data-none">
          <div class="ddp-txt-none">
            <em class="ddp-img-chartnodata"></em>
            <span class="ddp-data-name">{{ widget.name }}</span>
            <span
              class="ddp-txt-det"
              [innerHTML]="'msg.board.ui.select-data' | translate: { value: parentWidget.name }"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [style.display]="isInvalidPivot ? 'block' : 'none'" class="ddp-view-widget ddp-view-widget-grid">
    <div class="ddp-box-data-none">
      <div class="ddp-wrap-data-none">
        <div class="ddp-ui-data-none">
          <div class="ddp-txt-none">
            <selfai-bi-chart-icon
              class="ddp-error"
              [chartType]="widget?.configuration?.chart?.type"
            ></selfai-bi-chart-icon>
            <span class="ddp-data-name">{{ widget.name }}</span>
            <span class="ddp-txt-det" [innerHTML]="'msg.board.ui.invalid-pivot' | translate"></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [style.display]="isShowNoData ? 'block' : 'none'" class="ddp-view-widget ddp-view-widget-grid">
    <div class="ddp-box-data-none">
      <div class="ddp-wrap-data-none">
        <div class="ddp-ui-data-none">
          <div class="ddp-txt-none">
            <selfai-bi-chart-icon [chartType]="widget?.configuration?.chart?.type"></selfai-bi-chart-icon>
            <span class="ddp-data-name">{{ widget.name }}</span>
            <span class="ddp-txt-det">
              {{ 'msg.page.ui.no.data.show' | translate }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <error-widget
    [isShow]="isError"
    [iconClass]="widgetChartIconClass"
    [widgetName]="widget.name"
    [errorInfo]="errorInfo"
  ></error-widget>

  <div *ngIf="isEditMode" class="ddp-hover-edit">
    <div class="ddp-hover-edit-in">
      <a (click)="editWidget()" href="javascript:" class="ddp-btn-edit"></a>
    </div>
  </div>

  <p-dialog
    appendTo="body"
    [modal]="true"
    header=" {{ 'msg.board.ui.view-chart-data' | translate }}"
    [(visible)]="isShowDownloadPopup"
    [style]="{ width: '700px', height: '500px' }"
    contentStyleClass="bi-incapsulation relative"
  >
    <div class="ddp-ui-preview-contents ddp-type-preview ddp-pop-datadetail">
      <div class="ddp-type-top-option">
        <ul class="ddp-ui-tab ddp-type">
          <li [class.ddp-selected]="!isOriginDown">
            <a (click)="drawDataGrid()" href="javascript:">{{ 'msg.board.ui.aggregated-data' | translate }}</a>
          </li>
          <li [class.ddp-selected]="isOriginDown">
            <a (click)="drawDataGrid(true)" href="javascript:">{{ 'msg.board.ui.original-data' | translate }}</a>
          </li>
        </ul>

        <div *ngIf="!isCanNotDownAggr" class="ddp-ui-rightoption ddp-type-option">
          <div (click)="showDownloadLayer($event)" class="ddp-box-btn2 ddp-box-selected ddp-selected">
            <a href="javascript:" class="ddp-link-txt">
              <em class="ddp-icon-widget-gridsave"></em>
              Download
            </a>
          </div>
          <div class="ddp-box-btn2 ddp-box-selected ddp-selected">
            <a href="javascript:" class="ddp-link-txt" (click)="saveWidget()">
              <em class="ddp-icon-widget-gridsave"></em>
              Save
            </a>
          </div>

          <data-download
            [title]="
              isOriginDown
                ? ('msg.board.download.title-org' | translate)
                : ('msg.board.download.title-aggr' | translate)
            "
            [query]="query"
            (startDownload)="duringDataDown = true"
            (endDownload)="duringDataDown = false"
          ></data-download>
        </div>
      </div>

      <div class="ddp-wrap-tab-contents">
        <div class="ddp-ui-tab-contents">
          <div class="ddp-wrap-grid-option ddp-clear">
            <div class="ddp-fleft">
              <component-input
                [compType]="'search'"
                [value]="srchText"
                [placeHolder]="'Search data'"
                (changeValue)="setSearchText($event)"
              ></component-input>
            </div>
          </div>

          <div *ngIf="!isCanNotDownAggr" #dataGrid grid-component class="ddp-ui-grid2" style="top: 44px"></div>
          <div *ngIf="isCanNotDownAggr" class="ddp-ui-grid2 ddp-type">
            <div class="ddp-ui-error-message">
              {{ 'msg.board.ui.cant-not-down-aggregate' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>

  <div
    *ngIf="isShowEvtTriggerEditor"
    #userFuncInputContainer
    style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 1"
  >
    <div class="ddp-top-control ddp-clear">
      <div class="ddp-ui-buttons">
        <a (click)="saveUserFunc()" href="javascript:" class="ddp-btn-solid-type">
          {{ 'msg.comm.btn.save' | translate }}
        </a>
        <div class="ddp-box-btn2">
          <em (click)="closeUserFuncInput()" class="ddp-icon-widget-cut-w ddp-hover-tooltip">
            <div class="ddp-ui-tooltip-info ddp-down">
              <em class="ddp-icon-view-top"></em>
              {{ 'msg.comm.ui.del' | translate }}
            </div>
          </em>
        </div>
      </div>

      <div *ngIf="isShowWidgetName()" class="ddp-top-label">{{ widget.name }}</div>
    </div>
    <div class="ddp-view-widget" style="padding: 25px 0 0 0">
      <textarea
        #userFuncInput
        [ngModel]="userCustomFunction"
        class="ddp-textarea-widget"
        placeholder="P.H Event trigger script"
        style="background: white"
      ></textarea>
    </div>
  </div>
</div>
