import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BiApiModule } from '@selfai-platform/bi-api';
import { WidgetApiToDomainService } from './widget/services/widget-api-to-domain.service';
import { DashboardAdapterModule, DashboardApiToDomainService, DashboardDomainService } from './dashboard';
import { WidgetDomainService } from './widget';

@NgModule({
  imports: [CommonModule, BiApiModule, DashboardAdapterModule.forRoot()],
})
export class BiDomainModule {}
