import { WORKSPACE_PERMISSION, Workspace, WorkspaceType } from '@selfai-platform/bi-domain';

import { UserRole } from '../../common/permission/user-role';
import { CommonUtil } from '../../common/util/common.util';
import { AbstractHistoryEntity } from '../common/abstract-history-entity';
import { UserProfile } from '../user/user-profile';

export class Workspaces {
  private workspaces: Workspace[];

  constructor(workspacesResponse: WorkspacesResponse) {
    this.workspaces = workspacesResponse._embedded.workspaces;
  }

  public getList() {
    return this.workspaces;
  }
}

export interface WorkspacesResponse {
  _embedded: {
    workspaces: Workspace[];
  };
  page: {
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
  };
}

export class WorkspaceAdmin extends AbstractHistoryEntity {
  public id: string;
  public name: string;
  public description: string;
  public owner: UserProfile;
  public publicType: PublicType;
  public published: boolean;
  public active: boolean;

  public statusShowFl = false;

  constructor(workspace?: Workspace) {
    super();
    if (workspace) {
      this.id = workspace.id;
      this.name = workspace.name;
      this.description = workspace.description;
      this.owner = workspace.owner;
      this.publicType = workspace.publicType;
      this.published = workspace.published;
      this.active = workspace.active;
    }
  }
}

export class CountByBookType {
  public workBook: number;
  public folder: number;
  public report: number;
  public workBench: number;
  public notebook: number;
}

export enum PublicType {
  PRIVATE = 'PRIVATE',
  SHARED = 'SHARED',
}

export class WorkspacePermissionChecker {
  private userRole: UserRole;
  private _permissions: WORKSPACE_PERMISSION[] = [];
  private readonly _isAvailableWorkbook: boolean = false;
  private readonly _isAvailableNotebook: boolean = false;
  private readonly _isAvailableWorkbench: boolean = false;
  private readonly _loginUserId: string;
  private readonly _isPublished: boolean = false;

  constructor(workspace: Workspace, userRole: UserRole) {
    if (workspace) {
      switch (workspace.type) {
        case WorkspaceType.TYPE_1:
          this._isAvailableNotebook = false;
          this._isAvailableWorkbench = true;
          this._isAvailableWorkbook = true;
          break;
        case WorkspaceType.TYPE_2:
          this._isAvailableNotebook = false;
          this._isAvailableWorkbench = false;
          this._isAvailableWorkbook = true;
          break;
        default:
          this._isAvailableNotebook = true;
          this._isAvailableWorkbench = true;
          this._isAvailableWorkbook = true;
          break;
      }
      workspace.published && (this._isPublished = workspace.published);
    }
    this._loginUserId = CommonUtil.getLoginUserId();
    this.userRole = userRole;
    this.setPermissionsByUserRole();
  }

  private setPermissionsByUserRole() {
    this._permissions = [];

    if (this.userRole.permissions.WORKBOOK.VIEW) {
      this._permissions.push(WORKSPACE_PERMISSION.PERM_WORKSPACE_VIEW_WORKBOOK);
    }

    if (this.userRole.permissions.WORKBOOK.EDIT) {
      this._permissions.push(WORKSPACE_PERMISSION.PERM_WORKSPACE_EDIT_WORKBOOK);
      this._permissions.push(WORKSPACE_PERMISSION.PERM_WORKSPACE_MANAGE_WORKBOOK);
      this._permissions.push(WORKSPACE_PERMISSION.PERM_WORKSPACE_MANAGE_WORKSPACE);
    }

    if (this.userRole.permissions.DASHBOARD.VIEW) {
      this._permissions.push(WORKSPACE_PERMISSION.PERM_WORKSPACE_VIEW_NOTEBOOK);
    }

    if (this.userRole.permissions.DASHBOARD.EDIT) {
      this._permissions.push(WORKSPACE_PERMISSION.PERM_WORKSPACE_EDIT_NOTEBOOK);
      this._permissions.push(WORKSPACE_PERMISSION.PERM_WORKSPACE_MANAGE_NOTEBOOK);
    }
  }

  public isWorkspaceGuest(): boolean {
    return 0 === this._permissions.length;
  }

  public isManageWorkspace(): boolean {
    return -1 !== this._permissions.indexOf(WORKSPACE_PERMISSION.PERM_WORKSPACE_MANAGE_WORKSPACE);
  }

  public isManageWsFolder(): boolean {
    return -1 !== this._permissions.indexOf(WORKSPACE_PERMISSION.PERM_WORKSPACE_MANAGE_FOLDER);
  }

  public isViewWorkbook(): boolean {
    return (
      this._isAvailableWorkbook &&
      (this._isPublished || -1 !== this._permissions.indexOf(WORKSPACE_PERMISSION.PERM_WORKSPACE_VIEW_WORKBOOK))
    );
  }

  public isWriteWorkbook(): boolean {
    return (
      this._isAvailableWorkbook &&
      (-1 < this._permissions.indexOf(WORKSPACE_PERMISSION.PERM_WORKSPACE_MANAGE_WORKBOOK) ||
        -1 < this._permissions.indexOf(WORKSPACE_PERMISSION.PERM_WORKSPACE_EDIT_WORKBOOK))
    );
  }

  public isCreateWorkbook(): boolean {
    return this._isAvailableWorkbook && this.userRole.permissions.WORKBOOK.CREATE;
  }

  public isDeleteWorkbook(): boolean {
    return this._isAvailableWorkbook && this.userRole.permissions.WORKBOOK.DELETE;
  }

  public isEditWorkbook(userId: string): boolean {
    return (
      this._isAvailableWorkbook && -1 !== this._permissions.indexOf(WORKSPACE_PERMISSION.PERM_WORKSPACE_EDIT_WORKBOOK)
    );
  }

  public isManageWorkbook(): boolean {
    return (
      this._isAvailableWorkbook && -1 !== this._permissions.indexOf(WORKSPACE_PERMISSION.PERM_WORKSPACE_MANAGE_WORKBOOK)
    );
  }

  public isViewNotebook(): boolean {
    return (
      this._isAvailableNotebook &&
      (this._isPublished || -1 !== this._permissions.indexOf(WORKSPACE_PERMISSION.PERM_WORKSPACE_VIEW_NOTEBOOK))
    );
  }

  public isWriteNotebook(): boolean {
    return (
      this._isAvailableNotebook &&
      (-1 < this._permissions.indexOf(WORKSPACE_PERMISSION.PERM_WORKSPACE_MANAGE_NOTEBOOK) ||
        -1 < this._permissions.indexOf(WORKSPACE_PERMISSION.PERM_WORKSPACE_EDIT_NOTEBOOK))
    );
  }

  public isEditNotebook(): boolean {
    return (
      this._isAvailableNotebook && -1 !== this._permissions.indexOf(WORKSPACE_PERMISSION.PERM_WORKSPACE_EDIT_NOTEBOOK)
    );
  }

  public isManageNotebook(): boolean {
    return (
      this._isAvailableNotebook && -1 !== this._permissions.indexOf(WORKSPACE_PERMISSION.PERM_WORKSPACE_MANAGE_NOTEBOOK)
    );
  }

  public isViewWorkbench(): boolean {
    return (
      this._isAvailableWorkbench &&
      (this._isPublished || -1 !== this._permissions.indexOf(WORKSPACE_PERMISSION.PERM_WORKSPACE_VIEW_WORKBENCH))
    );
  }

  public isWriteWorkbench(): boolean {
    return (
      this._isAvailableWorkbench &&
      (-1 < this._permissions.indexOf(WORKSPACE_PERMISSION.PERM_WORKSPACE_MANAGE_WORKBENCH) ||
        -1 < this._permissions.indexOf(WORKSPACE_PERMISSION.PERM_WORKSPACE_EDIT_WORKBENCH))
    );
  }

  public isEditWorkbench(userId: string): boolean {
    return (
      this._isAvailableWorkbench &&
      -1 !== this._permissions.indexOf(WORKSPACE_PERMISSION.PERM_WORKSPACE_EDIT_WORKBENCH) &&
      this._loginUserId === userId
    );
  }

  public isManageWorkbench(): boolean {
    return (
      this._isAvailableWorkbench &&
      -1 !== this._permissions.indexOf(WORKSPACE_PERMISSION.PERM_WORKSPACE_MANAGE_WORKBENCH)
    );
  }
}
