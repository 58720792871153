export enum ChartType {
  BAR = 'bar',
  GRID = 'grid',
  INPUT = 'input',
  LINE = 'line',
  SCATTER = 'scatter',
  HEATMAP = 'heatmap',
  PIE = 'pie',
  DPIE = 'dpie',
  DDONAT = 'ddonat',
  MAP = 'map',
  CONTROL = 'control',
  LABEL = 'label',
  LABEL2 = 'label2',
  BOXPLOT = 'boxplot',
  WATERFALL = 'waterfall',
  WORDCLOUD = 'wordcloud',
  COMBINE = 'combine',
  TREEMAP = 'treemap',
  RADAR = 'radar',
  GRAPH = 'graph',
  SANKEY = 'sankey',
  GAUGE = 'gauge',
}

export enum SeriesType {
  BAR = 'bar',
  LINE = 'line',
  SCATTER = 'scatter',
  HEATMAP = 'heatmap',
  PIE = 'pie',
  BOXPLOT = 'boxplot',
  WORDCLOUD = 'wordCloud',
  TREEMAP = 'treemap',
  RADAR = 'radar',
  GRAPH = 'graph',
  SANKEY = 'sankey',
}

export enum Position {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
  INSIDE = 'inside',
  INSIDETOP = 'insideTop',
  INSIDELEFT = 'insideLeft',
  INSIDERIGHT = 'insideRight',
  INSIDEBOTTOM = 'insideBottom',
  OUTSIDE = 'outside',
}

export enum PositionLabel {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
  INSIDE = 'inside',
  INSIDETOP = 'insideTop',
  INSIDELEFT = 'insideLeft',
  INSIDERIGHT = 'insideRight',
  INSIDEBOTTOM = 'insideBottom',
}

export enum AxisType {
  CATEGORY = 'category',
  VALUE = 'value',
  LOG = 'log',
  X = 'xAxis',
  Y = 'yAxis',
  SUB = 'subAxis',
}

export enum LineType {
  SOLID = 'solid',
  DASHED = 'dashed',
  DOTTED = 'dotted',
}

export enum SymbolType {
  POLYGON = 'polygon',
  CIRCLE = 'circle',
  RECT = 'rect',
  ROUNDRECT = 'roundRect',
  TRIANGLE = 'triangle',
  DIAMOND = 'diamond',
  PIN = 'pin',
  ARROW = 'arrow',
}

export enum SymbolFill {
  SINGLE = 'single',
  TRANSPARENT = 'transparent',
}

export enum FontStyle {
  NORMAL = 'normal',
  ITALIC = 'italic',
  OBLIQUE = 'oblique',
}

export enum FontWeight {
  NORMAL = 'normal',
  BOLD = 'bold',
  BOLDER = 'bolder',
  LIGHTER = 'lighter',
}

export enum Orient {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export enum AxisLabelRotate {
  HORIZONTAL = 0,
  VERTICAL = 90,
  SLOPE = 45,
}

export enum TriggerType {
  ITEM = 'item',
  AXIS = 'axis',
  NONE = 'none',
}

export enum TriggerAction {
  MOUSEMOVE = 'mousemove',
  CLICK = 'click',
  NONE = 'none',
}

export enum ImageFormat {
  PNG = 'png',
  JPEG = 'jpeg',
}

export enum ToolboxMagicType {
  LINE = 'line',
  BAR = 'bar',
  STACK = 'stack',
  TILED = 'tiled',
}

export enum ThrottleType {
  DEBOUNCE = 'debounce',
  fixrate = 'fixRate',
}

export enum BrushType {
  RECT = 'rect',
  POLYGON = 'polygon',
  LINEX = 'lineX',
  LINEY = 'lineY',
  KEEP = 'keep',
  CLEAR = 'clear',
}

export enum BrushMode {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

export enum GraphicType {
  IMAGE = 'image',
  TEXT = 'text',
  CIRCLE = 'circle',
  SECTOR = 'sector',
  RING = 'ring',
  POLYGON = 'polygon',
  POLYLINE = 'polyline',
  RECT = 'rect',
  LINE = 'line',
  BEZIERCURVE = 'bezierCurve',
  ARC = 'arc',
  GROUP = 'group',
}

export enum GraphicAction {
  MERGE = 'merge',
  REPLACE = 'replace',
  REMOVE = 'remove',
}

export enum VisualMapDimension {
  X = 0,
  Y = 1,
}

export enum AxisIndexType {
  X = 'xAxisIndex',
  Y = 'yAxisIndex',
}

export enum GraphLayoutType {
  NONE = 'none',
  FORCE = 'force',
  CIRCULAR = 'circular',
}

export enum LabelLayoutType {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum LabelTextLocation {
  HIDDEN = 'HIDDEN',
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
}

export enum LabelSecondaryIndicatorType {
  PERIOD = 'PERIOD',
  STANDARD = 'STANDARD',
}

export enum LabelSecondaryIndicatorPeriod {
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

export enum LabelSecondaryIndicatorMarkType {
  PERCENTAGE = 'PERCENTAGE',
  INCREMENTAL = 'INCREMENTAL',
}

export enum LabelStyleType {
  LINE = 'LINE',
  SOLID = 'SOLID',
}

export enum FontSize {
  SMALL = 'SMALL',
  NORMAL = 'NORMAL',
  LARGE = 'LARGE',
}

export enum UIFontStyle {
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
}

export enum ShelveType {
  COLUMNS = 'columns',
  ROWS = 'rows',
  AGGREGATIONS = 'aggregations',
}

export enum ShelveFieldType {
  DIMENSION = 'dimension',
  MEASURE = 'measure',
  CALCULATED = 'calculated',
  TIMESTAMP = 'timestamp',
  NONE = 'none',
}

export enum ChartPivotType {
  COLS = 'cols',
  ROWS = 'rows',
  AGGS = 'aggs',
}

export enum ChartColorType {
  DIMENSION = 'dimension',
  SERIES = 'series',
  MEASURE = 'measure',
  SINGLE = 'single',
}

export const ChartColorListMain = {
  SC1: [
    '#3452b5',
    '#f28a00',
    '#2b9a9e',
    '#ffd200',
    '#c3c3c3',
    '#4a95cf',
    '#7c5ac1',
    '#e03c8f',
    '#83bf47',
    '#fda08c',
    '#7b7b7b',
    '#fc79ac',
  ],
  SC2: [
    '#6ed0e4',
    '#026e7f',
    '#72b235',
    '#fb7661',
    '#fbb700',
    '#c22a32',
    '#e03c8f',
    '#5d9f27',
    '#9b7fe4',
    '#6344ad',
    '#fee330',
    '#c3c3c3',
  ],
  SC3: [
    '#fb7661',
    '#fee330',
    '#4a95cf',
    '#75c4be',
    '#0c8691',
    '#fbb700',
    '#ad037c',
    '#e03c8f',
    '#8d6dd2',
    '#58b5da',
    '#b5d994',
    '#83bf47',
  ],
  SC4: [
    '#4a95cf',
    '#fc79ac',
    '#b099f0',
    '#cd2287',
    '#adadad',
    '#ffd200',
    '#6ed0e4',
    '#fda08c',
    '#54b2ae',
    '#f8533b',
    '#f6a300',
    '#fee330',
  ],
  SC5: [
    '#f8533b',
    '#d73631',
    '#fda08c',
    '#fb6e2c',
    '#e5342c',
    '#9a0b2c',
    '#fca0c3',
    '#c22a32',
    '#fda08c',
    '#f23a2c',
    '#fb7661',
    '#fbb700',
  ],
  SC6: [
    '#f28a00',
    '#fbb700',
    '#f8533b',
    '#f6f4b7',
    '#f27603',
    '#fda08c',
    '#fee330',
    '#fb6e2c',
    '#ffd200',
    '#f9f6a1',
    '#fb7661',
    '#f6a300',
  ],
  SC7: [
    '#4b8a21',
    '#54b2ae',
    '#026e7f',
    '#83bf47',
    '#39751d',
    '#b5d994',
    '#0c8691',
    '#015268',
    '#5d9f27',
    '#2d681a',
    '#97cb63',
    '#72b235',
  ],
  SC8: [
    '#6ed0e4',
    '#3f72c1',
    '#58b5da',
    '#026e7f',
    '#54b2ae',
    '#8adfe9',
    '#4a95cf',
    '#3452b5',
    '#015268',
    '#75c4be',
    '#3f72c1',
    '#6ed0e4',
  ],
  SC9: [
    '#7c5ac1',
    '#7d0071',
    '#cd2287',
    '#ee5398',
    '#4c006a',
    '#9b7fe4',
    '#4c309a',
    '#b099f0',
    '#ad037c',
    '#fca0c3',
    '#e03c8f',
    '#fc79ac',
  ],

  VC1: ['#ffcaba', '#fb7661', '#f23a2c', '#d73631', '#9a0b2c'],
  VC2: ['#f6f4b7', '#fee330', '#fbb700', '#f28a00', '#fb6e2c'],
  VC3: ['#d1e5c2', '#97cb63', '#72b235', '#4b8a21', '#2d681a'],
  VC4: ['#b5e0e1', '#75c4be', '#2b9a9e', '#026e7f', '#064059'],
  VC5: ['#c4eeed', '#8adfe9', '#58b5da', '#3f72c1', '#23399f'],
  VC6: ['#efdffd', '#b099f0', '#8d6dd2', '#6344ad', '#391f8a'],
  VC7: ['#fcc9dd', '#fc79ac', '#e03c8f', '#ad037c', '#4c006a'],
  VC8: ['#ffcaba', '#fda08c', '#fb7661', '#f8533b', '#f23a2c', '#e5342c', '#d73631', '#c22a32', '#9a0b2c'],
  VC9: ['#f6f4b7', '#f9f6a1', '#fee330', '#ffd200', '#fbb700', '#f6a300', '#f28a00', '#f27603', '#fb6e2c'],
  VC10: ['#d1e5c2', '#b5d994', '#97cb63', '#83bf47', '#72b235', '#5d9f27', '#4b8a21', '#39751d', '#2d681a'],
  VC11: ['#b5e0e1', '#9ad5d2', '#75c4be', '#54b2ae', '#2b9a9e', '#0c8691', '#026e7f', '#015268', '#064059'],
  VC12: ['#c4eeed', '#a9e7eb', '#8adfe9', '#6ed0e4', '#58b5da', '#4a95cf', '#3f72c1', '#3452b5', '#23399f'],
  VC13: ['#efdffd', '#cdbaf8', '#b099f0', '#9b7fe4', '#8d6dd2', '#7c5ac1', '#6344ad', '#4c309a', '#391f8a'],
  VC14: ['#fcc9dd', '#fca0c3', '#fc79ac', '#ee5398', '#e03c8f', '#cd2287', '#ad037c', '#7d0071', '#4c006a'],
  VC15: ['#c22a32', '#f23a2c', '#fb7661', '#ffcaba', '#ededed', '#b5e0e1', '#75c4be', '#2b9a9e', '#0c8691'],
  VC16: ['#9a0b2c', '#d73631', '#f28a00', '#fbb700', '#f6f4b7', '#d1e5c2', '#75c4be', '#3f72c1', '#391f8a'],
  VC17: ['#ad037c', '#e03c8f', '#fc79ac', '#fcc9dd', '#ededed', '#d1e5c2', '#97cb63', '#72b235', '#4b8a21'],
  VC18: ['#fbb700', '#ffd200', '#fee330', '#f9f6a1', '#ededed', '#cdbaf8', '#b099f0', '#7c5ac1', '#4c309a'],
  VC19: ['#f27603', '#f28a00', '#fbb700', '#fee330', '#f6f4b7', '#c4eeed', '#6ed0e4', '#4a95cf', '#3452b5'],
};

export const ChartColorList = {
  ...ChartColorListMain,
  RVC1: Object.values(ChartColorListMain.VC1).reverse(),
  RVC2: Object.values(ChartColorListMain.VC2).reverse(),
  RVC3: Object.values(ChartColorListMain.VC3).reverse(),
  RVC4: Object.values(ChartColorListMain.VC4).reverse(),
  RVC5: Object.values(ChartColorListMain.VC5).reverse(),
  RVC6: Object.values(ChartColorListMain.VC6).reverse(),
  RVC7: Object.values(ChartColorListMain.VC7).reverse(),
  RVC8: Object.values(ChartColorListMain.VC8).reverse(),
  RVC9: Object.values(ChartColorListMain.VC9).reverse(),
  RVC10: Object.values(ChartColorListMain.VC10).reverse(),
  RVC11: Object.values(ChartColorListMain.VC11).reverse(),
  RVC12: Object.values(ChartColorListMain.VC12).reverse(),
  RVC13: Object.values(ChartColorListMain.VC13).reverse(),
  RVC14: Object.values(ChartColorListMain.VC14).reverse(),
  RVC15: Object.values(ChartColorListMain.VC15).reverse(),
  RVC16: Object.values(ChartColorListMain.VC16).reverse(),
  RVC17: Object.values(ChartColorListMain.VC17).reverse(),
  RVC18: Object.values(ChartColorListMain.VC18).reverse(),
  RVC19: Object.values(ChartColorListMain.VC19).reverse(),
};

export enum WaterfallBarColor {
  POSITIVE = '#c23531',

  NEGATIVE = '#304554',
}

export enum MeasureColorRange {
  OUTOF_RANGE = '#3c4950',

  DEFAULT = '#c94819',
}

export enum AxisDefaultColor {
  AXIS_LINE_COLOR = '#bfbfbf',

  LABEL_COLOR = '#8f96a0',

  LINE_COLOR = '#f2f2f2',
}

export enum ColorCustomMode {
  NONE = 'NONE',
  SECTION = 'SECTION',
  GRADIENT = 'GRADIENT',
}

export enum ColorRangeType {
  SECTION = 'section',
  GRADIENT = 'gradient',
}

export enum EventType {
  CUMULATIVE = 'cumulativeMode',

  CHANGE_PIVOT = 'changePivot',

  MAP_CHANGE_OPTION = 'mapChangeOption',

  MAP_SPATIAL_ANALYSIS = 'spatialAnalysis',
  MAP_SPATIAL_REANALYSIS = 'spatialReAnalysis',

  GRID_ORIGINAL = 'gridViewType',

  SERIES_VIEW = 'barSeriesViewType',

  GRANULARITY = 'onChangeGranularity',

  AGGREGATION = 'onChangeAggregationType',

  CHART_TYPE = 'chartType',

  FILTER = 'filter',

  PIVOT_ALIAS = 'pivotAlias',

  DASHBOARD_ALIAS = 'dashboardAlias',
}

export const GridCellColorList = {
  LINE1: [
    ['#a1e1f8', '#89cdeb', '#59a4d2', '#418fc5', '#297bb8', '#246ea5', '#1e6191', '#19537e', '#13466b', '|'],
    ['#777', '#777', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
  ],
  CONT1: [
    [
      '#253ba2',
      '#374daf',
      '#4668b5',
      '#567dbd',
      '#638fc0',
      '#85a6cc',
      '#a0bad7',
      '#cbd8e6',
      '#f9f9f9',
      '#f6d3d3',
      '#f1b8b8',
      '#eb9999',
      '#dc6e6e',
      '#cc4d4d',
      '#cc3333',
      '#b71414',
      '#990a00',
    ],
    [
      '#fff',
      '#fff',
      '#fff',
      '#fff',
      '#fff',
      '#fff',
      '#fff',
      '#777',
      '#777',
      '#777',
      '#fff',
      '#fff',
      '#fff',
      '#fff',
      '#fff',
      '#fff',
      '#fff',
    ],
  ],
};

export enum ChartGradientType {
  LINEAR = 'LINEAR',
  CONTRAST = 'CONTRAST',
  CUSTOM = 'CUSTOM',
}

export enum CellColorTarget {
  BACKGROUND = 'BACKGROUND',
  TEXT = 'TEXT',
}

export enum Operator {
  SUM = 'SUM',
  AVERAGE = 'AVERAGE',
  MAX = 'MAX',
  MIN = 'MIN',
  COUNT = 'COUNT',
}

export enum AxisOrientType {
  X = 'X',
  Y = 'Y',
}

export enum AxisLabelType {
  ROW = 'row',
  COLUMN = 'column',
  SUBROW = 'sub_row',
  SUBCOLUMN = 'sub_column',
  SIMPLE = 'simple',
  AGGREGATION = 'aggregation',
}

export enum LabelConvertType {
  NAME = 'name',
  SHOWNAME = 'showName',
  SHOWMARK = 'showMark',
  MARK = 'mark',
  SCALED = 'scaled',
  SHOWVALUE = 'showValue',
  SHOWLABEL = 'showLabel',
  ALIGN = 'align',
  AXISCONFIG = 'axisConfig',
}

export enum AxisLabelMark {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
  SLOPE = 'SLOPE',
}

export enum LegendConvertType {
  SHOW = 'show',
  COUNT = 'count',
}

export enum DataZoomConverType {
  SHOW = 'show',
  RANGE = 'range',
}

export enum SeriesConvertType {
  MARK = 'mark',
  SHOW = 'show',
  UNITTYPE = 'unitType',
  ACCUMULATE = 'isAccumulate',
  SHAPE = 'shape',
  ALIGN = 'align',
  VALIGN = 'valign',
  LAYOUT = 'layout',
  FORMAT = 'format',
  DECIMAL = 'decimal',
  BAR = 'bar',
  LINE = 'line',
  LABEL = 'label',
  ROTATE = 'rotate',
}

export enum BarMarkType {
  MULTIPLE = 'MULTIPLE',
  STACKED = 'STACKED',
}

export enum DataUnit {
  NONE = 'NONE',
  PERCENT = 'PERCENT',
}

export enum LineMarkType {
  LINE = 'LINE',
  AREA = 'AREA',
}

export enum LineCornerType {
  STRAIGHT = 'STRAIGHT',
  SMOOTH = 'SMOOTH',
}

export enum LineStyleType {
  POINT_LINE = 'POINT_LINE',
  POINT = 'POINT',
  LINE = 'LINE',
}

export enum LineMode {
  NORMAL = 'NORMAL',
  CUMULATIVE = 'CUMULATIVE',
}

export enum PointSize {
  NORMAL = 'NORMAL',
  SMALL = 'SMALL',
  LARGE = 'LARGE',
  XLARGE = 'XLARGE',
}

export enum GridViewType {
  PIVOT = 'PIVOT',
  MASTER = 'MASTER',
}

export enum AnnotationPosition {
  TOP_RIGHT = 'TOP_RIGHT',
  TOP_LEFT = 'TOP_LEFT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
}

export enum PieSeriesViewType {
  SECTOR = 'SECTOR',
  DONUT = 'DONUT',
}

export enum ValueFormat {
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
}

export enum DataZoomRangeType {
  COUNT = 'COUNT',
  PERCENT = 'PERCENT',
}

export enum AxisOrientType {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum ChartMouseMode {
  SINGLE = 'single',
  MULTI = 'multi',
  DRAGZOOMIN = 'dragzoomin',
  ZOOMIN = 'zoomin',
  ZOOMOUT = 'zoomout',
  REVERT = 'revert',
}

export enum ChartSelectMode {
  ADD = 'add',
  SUBTRACT = 'subtract',
  CLEAR = 'clear',
}

export enum UIPosition {
  AUTO = 'AUTO',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  CENTER = 'CENTER',
  TOP = 'TOP',
  MIDDLE = 'MIDDLE',
  BOTTOM = 'BOTTOM',
  RIGHT_BOTTOM = 'RIGHT_BOTTOM',
  LEFT_BOTTOM = 'LEFT_BOTTOM',
  RIGHT_TOP = 'RIGHT_TOP',
  LEFT_TOP = 'LEFT_TOP',
}

export enum UIChartDataLabelDisplayType {
  CATEGORY_NAME = 'CATEGORY_NAME',
  CATEGORY_VALUE = 'CATEGORY_VALUE',
  CATEGORY_PERCENT = 'CATEGORY_PERCENT',
  SERIES_NAME = 'SERIES_NAME',
  SERIES_VALUE = 'SERIES_VALUE',
  SERIES_PERCENT = 'SERIES_PERCENT',
  XAXIS_VALUE = 'XAXIS_VALUE',
  YAXIS_VALUE = 'YAXIS_VALUE',
  VALUE = 'VALUE',
  NODE_NAME = 'NODE_NAME',
  LINK_VALUE = 'LINK_VALUE',
  NODE_VALUE = 'NODE_VALUE',
  HIGH_VALUE = 'HIGH_VALUE',
  THREE_Q_VALUE = 'THREE_Q_VALUE',
  MEDIAN_VALUE = 'MEDIAN_VALUE',
  FIRST_Q_VALUE = 'FIRST_Q_VALUE',
  LOW_VALUE = 'LOW_VALUE',
  LAYER_NAME = 'LAYER_NAME',
  LOCATION_INFO = 'LOCATION_INFO',
  DATA_VALUE = 'DATA_VALUE',
}

export enum UIOrient {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
  BOTH = 'BOTH',
}

export enum PointShape {
  CIRCLE = 'CIRCLE',
  RECT = 'RECT',
  TRIANGLE = 'TRIANGLE',
  DIAMOND = 'DIAMOND',
  PIN = 'PIN',
  ARROW = 'ARROW',
}

export enum UIFormatType {
  NUMBER = 'number',
  CURRENCY = 'currency',
  PERCENT = 'percent',
  EXPONENT10 = 'exponent10',
  TIME_CONTINUOUS = 'time_continuous',
  TIME_CUSTOM = 'time_custom',
}

export enum UIFormatCurrencyType {
  KRW = 'KRW',
  USD = 'USD',
  USCENT = 'USCENT',
  GBP = 'GBP',
  JPY = 'JPY',
  EUR = 'EUR',
  CNY = 'CNY',
  RUB = 'RUB',
}

export enum UIFormatNumericAliasType {
  NONE = 'NONE',
  AUTO = 'AUTO',
  KILO = 'KILO',
  MEGA = 'MEGA',
  GIGA = 'GIGA',
}

export enum UIFormatSymbolPosition {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
}

export enum UIFontSize {
  DEFAULT = 'default',
  SMALLER = 'smaller',
  LARGER = 'larger',
}

export enum ChartAxisGridType {
  TEXT = 'text',
  NUMERIC = 'numeric',
  DATETIME = 'datetime',
}

export enum ChartAxisLabelType {
  VALUE = 'value',
  CATEGORY = 'category',
}

export enum DataLabelPosition {
  OUTSIDE_TOP = 'OUTSIDE_TOP',
  INSIDE_TOP = 'INSIDE_TOP',
  INSIDE_BOTTOM = 'INSIDE_BOTTOM',
  CENTER = 'CENTER',
  OUTSIDE_RIGHT = 'OUTSIDE_RIGHT',
  INSIDE_RIGHT = 'INSIDE_RIGHT',
  INSIDE_LEFT = 'INSIDE_LEFT',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
}

export enum TextAlign {
  DEFAULT = 'DEFAULT',
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

export enum ShelfType {
  PIVOT = 'pivot',
  GRAPH = 'graph',
  GEO = 'geo',
}

export enum LayerViewType {
  ORIGINAL = 'original',
  HASH = 'hash',
  CLUSTERING = 'clustering',
}

export enum FormatType {
  DEFAULT = 'default',
  GEO = 'geo',
  GEO_HASH = 'geo_hash',
  GEO_BOUNDARY = 'geo_boundary',
  GEO_JOIN = 'geo_join',
}
