import { Pipe, PipeTransform } from '@angular/core';
import { PermissionRequest } from '../permission.model';
import { PermissionService } from '../permission.service';

@Pipe({
  name: 'checkPermission',
})
export class CheckPermissionPipe implements PipeTransform {
  constructor(private readonly permissionService: PermissionService) {}

  transform(value: PermissionRequest): boolean {
    return this.permissionService.checkPermission(value);
  }
}
