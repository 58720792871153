import { HistoryEntity } from '../../core';
import { RoleSet, UserProfile, WORKSPACE_PERMISSION } from '../../user';
import { Book } from '../../workbook';
import { PublicType, WorkspaceType } from '../enums';
import { Hirearchies } from './folder.model';
import { NotebookConnector } from './notebook.model';
import { WorkspaceMember } from './workspace-member.model';

export interface Workspace extends HistoryEntity {
  id: string;
  name: string;
  description: string;
  Type: PublicType;
  publicType: PublicType;
  published: boolean;
  active: boolean;
  books: Book[];
  members: WorkspaceMember[];
  roleSets: RoleSet[];
  connectors: NotebookConnector[];
  favorite: boolean;
  type: WorkspaceType;
  roles: any;
  countByBookType: CountByBookType;
  countOfDataSources: number;
  countByMemberType: { user: number; group: number };
  hierarchies: Hirearchies[];
  permissions: WORKSPACE_PERMISSION[];
  booksContour: WorkspaceBooksCounter;

  owner: UserProfile;

  ownerId: string;
}

export interface CountByBookType {
  workBook: number;
  folder: number;
  report: number;
  workBench: number;
  notebook: number;
}

export interface WorkspaceBooksCounter {
  all: number;
  limit: number;
  offset: number;
}

export function createWorkspace(): Workspace {
  return {} as Workspace;
}
