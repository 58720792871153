import { Injectable } from '@angular/core';

import { PermissionService } from '@selfai-platform/shared';

import { PermissionType } from '../../common/permission/permission-type';
import { PermissionZoneName } from '../../common/permission/permission-zone';
import { UserRole } from '../../common/permission/user-role';

@Injectable({
  providedIn: 'root',
})
export class CheckPermissionService {
  private userRole: UserRole;

  constructor(permissionService: PermissionService) {
    this.userRole = new UserRole(permissionService.getPermissions());
  }

  public hasPermisson(zoneName: PermissionZoneName, permissionType: PermissionType) {
    return this.userRole.hasPermission(zoneName, permissionType);
  }

  public getRoles() {
    return this.userRole;
  }

  get permissions() {
    return this.userRole.permissions;
  }
}
