import { PermissionType } from './permission-type';

export enum PermissionZoneName {
  WORKBOOK = 'WORKBOOK',
  DASHBOARD = 'DASHBOARD',
  DATASOURCE = 'DATASOURCE',
  DATASET = 'DATASET',
  DATAFLOW = 'DATAFLOW',
  WORKSPACE = 'WORKSPACE',
}

export class PermissionZone {
  name: PermissionZoneName;
  permissions: PermissionType[];

  constructor(name: PermissionZoneName, permissions: PermissionType[]) {
    this.name = name;
    this.permissions = permissions;
  }
}
