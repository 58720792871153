import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileUploadModule } from 'primeng/fileupload';
import { WorkflowUploadDialogComponent } from './workflow-upload-dialog.component';

@NgModule({
  imports: [CommonModule, FileUploadModule],
  declarations: [WorkflowUploadDialogComponent],
  exports: [WorkflowUploadDialogComponent],
})
export class WorkflowUploadDialogComponentModule {}
