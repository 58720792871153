<div class="ddp-layout-popuptype" *ngIf="isShow">
  <em class="ddp-bg-popup"></em>
  <div class="ddp-ui-popup">
    <div class="ddp-ui-popup-title">
      <span class="ddp-txt-title-name">{{ 'msg.permission.ui.set-ws-perms-schema' | translate }}</span>
      <div class="ddp-ui-pop-buttons">
        <a (click)="close()" href="javascript:" class="ddp-btn-pop">{{ 'msg.comm.btn.cancl' | translate }}</a>
        <a (click)="done()" href="javascript:" class="ddp-btn-pop ddp-bg-black">
          {{ 'msg.comm.btn.done' | translate }}
        </a>
      </div>
    </div>

    <div class="ddp-ui-popup-contents" *ngIf="roleSet">
      <div class="ddp-schema-top">
        <span class="ddp-title">{{ 'msg.spaces.permission.schema.userroles' | translate }}</span>
      </div>

      <app-permission-schema [roleSet]="roleSet" [editMode]="editMode" [useAPI]="useAPI"></app-permission-schema>
    </div>
  </div>
</div>
