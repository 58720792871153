import { BiPermission } from './bi-permission.enum';
import { PermissionZone } from './permission-zone';

export class SelfaiRole {
  name: BiPermission;
  zones: PermissionZone[];

  constructor(name: BiPermission, zones: PermissionZone[]) {
    this.name = name;
    this.zones = zones;
  }
}
