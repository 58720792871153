import {
  EChartType,
  IPageChart,
} from "./page-section-chart.component.interfaces";

export const CHARTS: IPageChart[] = [
  {
    name: EChartType.Bar,
    title: "msg.page.ui.bar",
    data: [
      {
        title: "msg.page.ui.row",
        values: ["msg.page.ui.more-than-one.dimension.value"],
      },
      {
        title: "msg.page.ui.intersect",
        values: ["msg.page.ui.more-than-one.measured.value"],
      },
    ],
  },
  {
    name: EChartType.Grid,
    title: "msg.page.ui.text-table",
    data: [
      {
        title: "msg.page.ui.row-or-col",
        values: ["msg.page.ui.more-than-one.dimension.value"],
      },
      {
        title: "msg.page.ui.intersect",
        values: ["msg.page.ui.more-than-one.measured.value"],
      },
    ],
  },
  {
    name: EChartType.Line,
    title: "msg.page.ui.line",
    data: [
      {
        title: "msg.page.ui.row",
        values: ["msg.page.ui.more-than-one.dimension.value"],
      },
      {
        title: "msg.page.ui.intersect",
        values: ["msg.page.ui.more-than-one.measured.value"],
      },
    ],
  },
  {
    name: EChartType.Scatter,
    title: "msg.page.ui.scatter",
    data: [
      {
        title: "msg.page.ui.row",
        values: ["msg.page.ui.one.measured.value"],
      },
      {
        title: "msg.page.ui.col",
        values: ["msg.page.ui.one.measured.value"],
      },
    ],
  },
  {
    name: EChartType.Heatmap,
    title: "msg.page.ui.heat-map",
    data: [
      {
        title: "msg.page.ui.row-or-col",
        values: ["msg.page.ui.more-than-one.dimension.value"],
      },
      {
        title: "msg.page.ui.intersect",
        values: ["msg.page.ui.one.measured.value"],
      },
    ],
  },
  {
    name: EChartType.Pie,
    title: "msg.page.ui.pie",
    data: [
      {
        title: "msg.page.ui.intersect",
        values: [
          "msg.page.ui.one.dimension.value",
          "msg.page.ui.one.measured.value",
        ],
      },
    ],
  },
  {
    name: EChartType.Map,
    title: "msg.page.ui.map.view",
    data: [
      {
        title: "msg.page.ui.geo.layer",
        values: [
          "msg.page.ui.geo.dimension.value",
          "msg.page.ui.more-than-one.dimension.value",
          "msg.page.ui.more-than-one.measured.value",
        ],
      },
    ],
  },
  {
    name: EChartType.Label,
    title: "msg.page.ui.kpi",
    data: [
      {
        title: "msg.page.ui.intersect",
        values: ["msg.page.ui.more-than-one.measured.value"],
      },
    ],
  },
  {
    name: EChartType.Boxplot,
    title: "msg.page.ui.box-plot",
    data: [
      {
        title: "msg.page.ui.row",
        values: ["msg.page.ui.more-than-one.dimension.value"],
      },
      {
        title: "msg.page.ui.col",
        values: ["msg.page.ui.one.dimension.value"],
      },
      {
        title: "msg.page.ui.intersect",
        values: ["msg.page.ui.one.measured.value"],
      },
    ],
  },
  {
    name: EChartType.Waterfall,
    title: "msg.page.ui.water-fall",
    data: [
      {
        title: "msg.page.ui.row",
        values: ["msg.page.ui.one.time.dimension.value"],
      },
      {
        title: "msg.page.ui.intersect",
        values: ["msg.page.ui.one.measured.value"],
      },
    ],
  },
  {
    name: EChartType.Wordcloud,
    title: "msg.page.ui.word-cloud",
    data: [
      {
        title: "msg.page.ui.intersect",
        values: [
          "msg.page.ui.more-than-one.dimension.value",
          "msg.page.ui.one.measured.value",
        ],
      },
    ],
  },
  {
    name: EChartType.Combine,
    title: "msg.page.ui.combine-chart",
    data: [
      {
        title: "msg.page.ui.row",
        values: ["msg.page.ui.more-than-one.dimension.value"],
      },
      {
        title: "msg.page.ui.intersect",
        values: ["msg.page.ui.two-to-four.measured.value"],
      },
    ],
  },
  {
    name: EChartType.Treemap,
    title: "msg.page.ui.tree-map",
    data: [
      {
        title: "msg.page.ui.row",
        values: ["msg.page.ui.one.dimension.value"],
      },
    ],
  },
  {
    name: EChartType.Radar,
    title: "msg.page.ui.radar-chart",
    data: [
      {
        title: "msg.page.ui.intersect",
        values: [
          "msg.page.ui.one.dimension.value",
          "msg.page.ui.more-than-one.measured.value",
        ],
      },
    ],
  },
  {
    name: EChartType.Network,
    title: "msg.page.ui.network",
    data: [
      {
        title: "msg.page.ui.sub.shelf",
        values: ["msg.page.ui.one.dimension.value"],
      },
      {
        title: "msg.page.ui.target.shelf",
        values: ["msg.page.ui.one.dimension.value"],
      },
      {
        title: "msg.page.ui.conn.shelf",
        values: ["msg.page.ui.one.measured.value"],
      },
    ],
  },
  {
    name: EChartType.Gauge,
    title: "msg.page.ui.gauge-chart",
    data: [
      {
        title: "msg.page.ui.col",
        values: ["msg.page.ui.more-than-one.dimension.value"],
      },
      {
        title: "msg.page.ui.intersect",
        values: ["msg.page.ui.one.measured.value"],
      },
    ],
  },
  {
    name: EChartType.Sankey,
    title: "msg.page.ui.sankey",
    data: [
      {
        title: "msg.page.ui.row",
        values: ["msg.page.ui.more-than-two.dimension.value"],
      },
      {
        title: "msg.page.ui.intersect",
        values: ["msg.page.ui.one.measured.value"],
      },
    ],
  },
];
