import { Route } from '@angular/router';
import { PipelinePermission, RoutingData, SemanticCode } from '@selfai-platform/shared';
import { AppAuthGuard } from '@selfai-platform/shell';
import { WorkflowEditorComponent } from './workflow-editor/workflow-editor.component';

export const pipelineModuleRoutes: Route[] = [
  {
    path: 'pipeline',
    loadChildren: () =>
      import('../lib/wokflow/components/workflow-list/workflow-list.module').then((m) => m.WorkflowListComponentModule),
    canActivate: [AppAuthGuard],
    data: {
      semanticCode: SemanticCode.WorkflowList,
      roles: [PipelinePermission.WorkflowList],
    } as RoutingData,
  },
  //TODO move to WorkflowListComponentModule
  {
    path: 'pipeline/workflow/:id',
    component: WorkflowEditorComponent,
    canActivate: [AppAuthGuard],
    data: {
      semanticCode: SemanticCode.WorkflowEditor,
      roles: [PipelinePermission.WorkflowGetAny, PipelinePermission.WorkflowGetOwn],
    } as RoutingData,
  },
];
