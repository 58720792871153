<div class="ddp-box-widget">
  <div
    *ngIf="isAuthMgmtViewMode"
    class="ddp-top-control ddp-clear"
    [ngStyle]="{ 'margin-right': isVisibleScrollbar ? '20px' : '' }"
  >
    <div class="ddp-ui-buttons">
      <div class="ddp-box-btn2" (click)="editWidget()">
        <em class="ddp-icon-widget-edit"></em>

        <div class="ddp-ui-tooltip-info">
          <em class="ddp-icon-view-down"></em>
          {{ 'msg.comm.ui.edit' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="ddp-view-widget ddp-view-widget-text">
    <div
      *ngIf="widget"
      [innerHTML]="sanitizer.bypassSecurityTrustHtml(widget.configuration['contents'])"
      spellcheck="false"
    ></div>
  </div>

  <div *ngIf="isEditMode" class="ddp-hover-edit">
    <div class="ddp-hover-edit-in">
      <a (click)="editWidget()" href="javascript:" class="ddp-btn-edit"></a>
    </div>
  </div>
</div>
