import { Injectable, Injector } from '@angular/core';

import jwt_decode from 'jwt-decode';
import { KeycloakService } from 'keycloak-angular';

import { AuthService } from '../../auth/auth.service';
import { CookieConstant } from '../../common/constant/cookie.constant';
import { AbstractService } from '../../common/service/abstract.service';
import { CommonUtil } from '../../common/util/common.util';
import { User } from '../../domain/user/user';

import { KeycloakParsedToken } from './keycloakParsedToken';

@Injectable()
export class UserService extends AbstractService {
  private URL_USER = this.API_URL + 'users';

  constructor(
    protected readonly injector: Injector,
    private readonly keycloak: KeycloakService,
    private readonly authService: AuthService,
  ) {
    super(injector);
  }

  public async isLoggedIn(): Promise<boolean> {
    const loggedIn = await this.keycloak.isLoggedIn();

    if (loggedIn) {
      const token = this.cookieService.get(CookieConstant.KEY.LOGIN_TOKEN);
      if (!token) {
        this.authService.setCredentialsFromKeycloak();
      }
    }

    return loggedIn;
  }

  public isLoggedInErrorHandler(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.keycloak
        .updateToken()
        .then(() => {
          return this.authService.setCredentialsFromKeycloak();
        })
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  public login(user: User, basicHeader?: string): Promise<any> {
    return this.getToken(user, basicHeader);
  }

  public logout(): Promise<any> {
    return this.keycloak.logout();
  }

  public checkUserIp(user: User, basicHeader?: string): Promise<any> {
    return this.checkIp(user, basicHeader);
  }

  public getCurrentUser(username: string): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      const token = this.cookieService.get(CookieConstant.KEY.LOGIN_TOKEN);
      const decodedToken = jwt_decode(token) as KeycloakParsedToken;
      this.get<User>(this.URL_USER + '/' + username)
        .then((data) => {
          const user = data;
          user.fullName = decodedToken.preferred_username;
          user.email = decodedToken.email;
          resolve(user);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public join(user: User): Promise<any> {
    return this.postWithoutToken(this.API_URL + 'users/signup', user);
  }

  public duplicateId(username: string): Promise<any> {
    return this.getWithoutToken(this.URL_USER + `/username/${username}/duplicated`);
  }

  public duplicateEmail(email: string): Promise<any> {
    return this.getWithoutToken(this.URL_USER + `/email/${email}/duplicated`);
  }

  public validatePassword(params: any): Promise<any> {
    return this.postWithoutToken(this.API_URL + 'users/password/validate', params);
  }

  public resetPassword(email: string): Promise<any> {
    return this.postWithoutToken(this.URL_USER + '/password/reset', { email });
  }

  public getUserList(param: any, projection: string = 'forListView'): Promise<any> {
    let url = this.URL_USER;
    url += '?' + CommonUtil.objectToUrlString(param);
    return this.get(url + `&projection=${projection}`);
  }

  public getUserDetail(username: string, projection: string = 'forDetailView'): Promise<any> {
    return this.get<any>(this.URL_USER + `/${username}?projection=${projection}`);
  }

  public updateUser(username: string, params: any): Promise<any> {
    return this.patch(this.URL_USER + `/${username}`, params);
  }

  public updateInitialUser(username: string, params: any): Promise<any> {
    return this.postWithoutToken(this.URL_USER + `/password`, params);
  }

  public checkUserPassword(username: string, password: string) {
    return this.post(this.URL_USER + `/${username}/check/password`, {
      password: password,
    });
  }

  public getClientDetail(clientId: string) {
    return this.getWithoutToken(this.API_URL + `oauth/${clientId}`);
  }
}
