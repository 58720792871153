import { HistoryEntity, UserDetail } from '../../../core';
import { Action, RoleScope, Status } from '../../enums';

import { Permission } from './permission.model';
import { RoleSet } from './role-set.model';

export class Role implements Partial<HistoryEntity> {
  version?: number;
  createdBy?: UserDetail;
  createdTime?: string | Date;
  modifiedBy?: UserDetail;
  modifiedTime?: string | Date;
  lastAccessTime?: string | Date;
  id?: string;
  name?: string;
  editName?: string;
  orgName?: string;
  description?: string;
  scope?: RoleScope;
  predefined?: boolean;
  adminRole?: boolean;
  defaultRole?: boolean;
  refRole?: string;
  cntMembers?: number;
  permissions: Permission[] = [];
  users: User[] = [];
  roleSet: RoleSet | undefined;
  permissionNames: string[] = [];
  userCount?: number;
  groupCount?: number;
}

export interface User extends HistoryEntity {
  id: string;
  username: string;
  password: string;
  fullName: string;
  email: string;
  tel: string;
  status: Status;
  statusMessage: string;
  imageUrl: string;
  groups: Group[];
  op: Action;
  roleNames: string[];
  confirmPassword: string;
  statusShowFl?: boolean;
}

export interface Group extends HistoryEntity {
  id: string;

  name: string;

  description: string;

  predefined: boolean;

  memberCount: number;

  members: any[];

  readOnly: boolean;

  roleNames: string[];

  contexts: string;
}
