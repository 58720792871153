import { UIFormatSymbolPosition } from '../../../chart/enums';
import { UIChartFormatItem } from '../../../chart/models';
import { ByTimeUnit, TimeUnit } from './field';

export interface Format {
  type?: string;
  sign?: string;
  decimal?: number;
  useThousandsSep?: boolean;
  discontinuous?: boolean;
  unit?: TimeUnit;
  byUnit?: ByTimeUnit;
  isAll?: boolean;
  customSymbol?: CustomSymbol;
  abbr?: string;
  format?: string;
  timeZone?: string;
  locale?: string;
  each?: UIChartFormatItem[];
}

export function createFormat(params: Partial<Format> = {}): Format {
  return {
    ...params
  }
}

export interface CustomSymbol {
  value?: string;
  pos?: UIFormatSymbolPosition;
  abbreviations?: boolean;
}
