import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionRequest } from '../permission.model';
import { PermissionService } from '../permission.service';

@Directive({
  selector: '[selfaiPlatformCheckPermission]',
})
export class CheckPermissionDirective {
  @Input() set selfaiPlatformCheckPermission(permissionRequest: PermissionRequest) {
    const canView = this.permissionService.checkPermission(permissionRequest);

    if (canView && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!canView && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  private hasView = false;

  constructor(
    private readonly permissionService: PermissionService,
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainer: ViewContainerRef,
  ) {}
}
