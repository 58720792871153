import { generateUuid } from '@selfai-platform/shared';
import { PageWidgetConfiguration, createPageWidgetConfiguration } from './page-widget-configuration.model';
import { Widget } from './widget.model';

export interface PageWidget extends Widget {
  imageUrl?: string;
  configuration: PageWidgetConfiguration;
  dataSourcesIds?: string[];
  mode: 'chart' | 'grid';
  type: 'page';
}

export function createPageWidget(widgetOptions: Partial<PageWidget> = {}): PageWidget {
  return {
    id: generateUuid(),
    description: '',
    imageUrl: '',
    configuration: createPageWidgetConfiguration(),
    dataSourcesIds: [],
    mode: 'chart',
    type: 'page',
    name: '',
    ...widgetOptions,
  };
}
