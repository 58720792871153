import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import * as Keycloak from 'keycloak-js';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { UserProfile } from './user-profile';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService extends BehaviorSubject<UserProfile | null> {
  constructor(private readonly keycloakService: KeycloakService) {
    super(null);
    this.setUserProfile();
  }

  getUserProfile(): Observable<UserProfile> {
    return this.asObservable().pipe(filter(Boolean));
  }

  private async setUserProfile() {
    let profile;
    try {
      profile = (await this.keycloakService.loadUserProfile()) as Keycloak.KeycloakProfile;
    } catch (err) {
      console.error('Cannot load user profile', err);
    }

    this.next(new UserProfile(profile));
  }
}
