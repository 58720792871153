import { Filter, TimeRangeFilter, TimeRelativeFilter, TimeUnit } from '../../workbook';
import { getDateTimeFormat } from './get-date-time-format';
import moment from 'moment-timezone';

export function processTimeRangeFilter(filter: TimeRangeFilter): TimeRangeFilter {
  if (!filter.intervals?.length || !filter.timeUnit) {
    return filter;
  }

  filter.intervals = filter.intervals.map((interval) => {
    const [start, end] = interval.split('/');

    const newStart =
      start !== 'EARLIEST_DATETIME' && start !== 'LATEST_DATETIME'
        ? getDateTimeFormat(start, filter.timeUnit as TimeUnit, true)
        : start;
    const newEnd =
      end !== 'EARLIEST_DATETIME' && end !== 'LATEST_DATETIME'
        ? getDateTimeFormat(end, filter.timeUnit as TimeUnit, false)
        : end;

    return `${newStart}/${newEnd}`;
  });

  return filter;
}

function processTimeRelativeFilter(filter: TimeRelativeFilter): TimeRelativeFilter {
  const formatHasDisabledTimezone = filter.clzField && filter.clzField.format;
  // Fix after dashboard service editing
  //&& TimezoneService.DISABLE_TIMEZONE_KEY === filter.clzField.format.timeZone;

  filter.timeZone = formatHasDisabledTimezone ? undefined : filter.timeZone || moment.tz.guess();

  return filter;
}

const filterKeyMap: Record<string, string[]> = {
  interval: [
    'selector',
    'startDate',
    'endDate',
    'intervals',
    'timeZone',
    'locale',
    'format',
    'rrule',
    'relValue',
    'timeUnitUI',
    'timeUnit',
    'byTimeUnit',
    'minTime',
    'maxTime',
    'valueList',
    'candidateValues',
    'discontinuous',
    'granularity',
  ],
  include: ['selector', 'valueList', 'candidateValues', 'definedValues', 'sort'],
  timestamp: ['selectedTimestamps', 'timeFormat'],
  bound: ['min', 'max'],
  time_all: [],
  time_relative: ['relTimeUnit', 'tense', 'value', 'timeUnit', 'byTimeUnit', 'discontinuous', 'timeZone'],
  time_range: ['intervals', 'timeUnit', 'byTimeUnit', 'discontinuous'],
  time_list: ['valueList', 'candidateValues', 'timeUnit', 'byTimeUnit', 'discontinuous'],
  wildcard: ['contains', 'value'],
  measure_inequality: ['aggregation', 'inequality', 'value'],
  measure_position: ['aggregation', 'position', 'value'],
};

function filterKeys(filter: Filter): Partial<Filter> {
  const validKeys = filterKeyMap[filter.type].concat(['type', 'field', 'ref', 'dataSource']);
  const filteredKeysFilter = Object.keys(filter)
    .filter((key) => validKeys.includes(key))
    // TODO remove any and rewrite function
    .reduce((obj, key) => ({ ...obj, [key]: (<any>filter)[key] }), {});

  return filteredKeysFilter;
}

export function convertToServerSpecForDashboard(filter: Filter): Partial<Filter> {
  let processedFilter = filter;

  if (isTimeRangeFilter(filter)) {
    processedFilter = processTimeRangeFilter(filter as TimeRangeFilter);
  } else if (isTimeRelativeFilter(filter)) {
    processedFilter = processTimeRelativeFilter(filter as TimeRelativeFilter);
  }

  return filterKeys(processedFilter);
}

function isTimeRelativeFilter(filter: Filter): boolean {
  return filter.type === 'time_relative';
}

function isTimeRangeFilter(filter: Filter): boolean {
  return filter.type === 'time_range';
}
