import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AggregatorRouteApiModel } from '@selfai-platform/pipeline-common';
import { Observable, map, of } from 'rxjs';
import { AggregatorProcess, AggregatorProcessApi, AggregatorProcessListApi } from '../../models';
import { AggregatorCoreApiService } from './aggregator-core-api.service';
import { AggregatorAdapter } from './aggregator.adapter';
import { AggrtConfigService } from './aggrt-config.service';

@Injectable({
  providedIn: 'root',
})
export class AggregatorRoutesApiService extends AggregatorCoreApiService implements AggregatorAdapter {
  loadProcessWithRoutes(): Observable<AggregatorProcessApi> {
    const url = this.buildUrl('/routes/getCurrent');

    return this.http.get<AggregatorRouteApiModel[]>(url).pipe(
      map((routes) => ({
        routes,
        // for supporting with rnkb
        process: {} as AggregatorProcess,
      })),
    );
  }

  saveRoutes({ routes }: AggregatorProcessApi): Observable<void> {
    const url = this.buildUrl('/routes/update');

    return this.http.post<void>(url, routes);
  }

  loadProcessList(): Observable<AggregatorProcessListApi> {
    return of([]);
  }
  loadProcessListFull(): Observable<AggregatorProcessApi[]> {
    return of([]);
  }

  constructor(private readonly http: HttpClient, aggrtConfigService: AggrtConfigService) {
    super(aggrtConfigService);
  }
}
