import { Component, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';

import * as _ from 'lodash';

import { OptionGenerator } from '@selfai-platform/bi-chart-engine';
import {
  LogicalType,
  AxisLabelType,
  ChartAxisLabelType,
  ChartType,
  UIChartAxis,
  UIOption,
  UIOrient,
} from '@selfai-platform/bi-domain';

import { BaseOptionComponent } from './base-option.component';

import UI = OptionGenerator.UI;

@Component({
  selector: 'xaxis-option',
  templateUrl: './xaxis-option.component.html',
})
export class XAxisOptionComponent extends BaseOptionComponent {
  public xAxisRotateFlag = false;

  public nameUiOption: UIOption;

  public labelDirectionList: Object[] = [
    { name: '가로방향', value: 'HORIZONTAL' },
    { name: '세로방향', value: 'VERTICAL' },
  ];

  @Output('changeAxisName')
  public changeAxisNameEvent: EventEmitter<any> = new EventEmitter();

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  @Input('uiOption')
  public set setUiOption(uiOption: UIOption) {
    this.uiOption = uiOption;

    if (!this.uiOption.xAxis.label) {
      if (_.eq(ChartType.SCATTER, this.uiOption.type)) {
        this.uiOption.xAxis.label = UI.AxisLabel.axisLabelForValue(ChartAxisLabelType.VALUE);
      } else {
        this.uiOption.xAxis.label =
          this.uiOption['align'] && UIOrient.HORIZONTAL == this.uiOption['align']
            ? UI.AxisLabel.axisLabelForValue(ChartAxisLabelType.VALUE)
            : UI.AxisLabel.axisLabelForCategory(ChartAxisLabelType.CATEGORY);
      }
    }

    if (!this.uiOption.yAxis.label) {
      if (_.eq(ChartType.HEATMAP, this.uiOption.type)) {
        this.uiOption.yAxis.label = UI.AxisLabel.axisLabelForCategory(ChartAxisLabelType.CATEGORY);
      } else {
        this.uiOption.yAxis.label =
          this.uiOption['align'] && UIOrient.HORIZONTAL == this.uiOption['align']
            ? UI.AxisLabel.axisLabelForCategory(ChartAxisLabelType.CATEGORY)
            : UI.AxisLabel.axisLabelForValue(ChartAxisLabelType.VALUE);
      }
    }

    this.nameUiOption = _.cloneDeep(this.uiOption);
  }

  public axisName(axisLabelType: any, name: string): void {
    if (!this.uiOption.xAxis.showName) return;

    const value = name
      ? name
      : AxisLabelType.ROW === axisLabelType
      ? this.nameUiOption.xAxis.customName
      : this.nameUiOption.yAxis.customName;

    if (value && value.length > 20) {
      this.alertPrimeService.info(this.translateService.instant('msg.page.alert.axis.label.warn'));
      return;
    }

    if (_.eq(this.uiOption.xAxis.mode, axisLabelType)) {
      this.uiOption.xAxis.name = value;
    }

    if (_.isEmpty(value) || (value && _.isEmpty(value.trim()))) {
      delete this.uiOption.xAxis.customName;
    } else {
      this.uiOption.xAxis.customName = value.trim();
    }

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { xAxis: this.uiOption.xAxis });
    this.update();
    this.changeAxisNameEvent.emit();
  }

  public showAxisName(axisLabelType: any, show: boolean): void {
    if (_.eq(this.uiOption.xAxis.mode, axisLabelType)) {
      this.uiOption.xAxis.showName = show;
    }
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { xAxis: this.uiOption.xAxis });

    this.update();
  }

  public showAxisLabel(axisLabelType: any, show: boolean): void {
    if (_.eq(this.uiOption.xAxis.mode, axisLabelType)) {
      this.uiOption.xAxis.showLabel = show;
    }
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { xAxis: this.uiOption.xAxis });

    this.update();
  }

  public rotateAxisLabel(axisLabelType: AxisLabelType, rotate: number): void {
    if (!this.uiOption.xAxis.showLabel) return;

    this.xAxisRotateFlag = false;

    if (_.eq(this.uiOption.xAxis.mode, axisLabelType)) {
      this.uiOption.xAxis.label = this.uiOption.xAxis.label ? this.uiOption.xAxis.label : {};
      this.uiOption.xAxis.label.type = ChartAxisLabelType.CATEGORY;
      this.uiOption.xAxis.label['rotation'] = rotate;
    }
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { xAxis: this.uiOption.xAxis });

    this.update();
  }

  public showAxisConfig() {
    this.uiOption.xAxis.axisOption.showFl = !this.uiOption.xAxis.axisOption.showFl;
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { xAxis: this.uiOption.xAxis });
    this.update();
  }

  public changeAxisConfig(axisValue: number, type: string) {
    const axisConfig = this.uiOption.xAxis.axisOption;

    if (!axisConfig) return;

    switch (type) {
      case 'min':
        if (!axisValue) axisValue = axisConfig.originMin;
        else if (axisValue >= axisConfig.max) {
          this.alertPrimeService.info(
            this.translateService.instant('msg.page.chart.color.axis.config.start.limit.alert'),
          );
          axisValue = axisConfig.originMin;
          return;
        }
        axisConfig.min = axisValue;
        break;
      case 'max':
        if (!axisValue) axisValue = axisConfig.originMax;
        else if (axisValue <= axisConfig.min) {
          this.alertPrimeService.info(
            this.translateService.instant('msg.page.chart.color.axis.config.end.limit.alert'),
          );
          axisValue = axisConfig.originMax;
          return;
        }

        axisConfig.max = axisValue;
        break;
      case 'interval':
        axisConfig.max = !axisConfig.max ? axisConfig.originMax : axisConfig.max;
        axisConfig.min = !axisConfig.min ? axisConfig.originMin : axisConfig.min;

        const diffVal = axisConfig.max - axisConfig.min;

        if (diffVal < axisValue) {
          this.alertPrimeService.info(
            this.translateService.instant('msg.page.chart.color.axis.config.unit.limit.alert', {
              value: diffVal.toFixed(0),
            }),
          );

          axisValue = null;
          return;
        }

        axisConfig.interval = axisValue;
        break;
    }

    axisConfig.changeType = type;
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { xAxis: this.uiOption.xAxis });
    this.update();
  }

  public numericDimensionCheck(): boolean {
    const dimensionList = this.uiOption.fielDimensionList;

    if (!dimensionList) return false;

    const checkDimensionList = dimensionList.filter((item: any) => {
      if (
        (item.type == 'dimension' && item.field.logicalType == LogicalType.INTEGER) ||
        item.field.logicalType == LogicalType.DOUBLE
      ) {
        return item;
      }
    });

    return checkDimensionList.length == dimensionList.length;
  }

  public changeXAxisValue(axis: UIChartAxis): void {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { xAxis: axis });

    this.update();
  }

  public changeBaseline(axis: UIChartAxis): void {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { xAxis: axis });

    this.update({});
  }
}
