import { inject, InjectionToken } from '@angular/core';
import { AlertService, PipelineConfigService } from '@selfai-platform/shared';
import { RxStompConfig } from '@stomp/rx-stomp';

export const PPL_RX_STOMP_CONFIG = new InjectionToken('PipelineRxStompoConfig', {
  providedIn: 'root',
  factory: () => {
    return PipelineRxStompConfigFactory(inject(PipelineConfigService), inject(AlertService));
  },
});

export function PipelineRxStompConfigFactory(
  pipelineConfigSvc: PipelineConfigService,
  alertService: AlertService,
): RxStompConfig {
  try {
    const {
      socket,
      hosts: { socket: socketHost },
    } = pipelineConfigSvc.getConfig();

    return {
      // Which server?
      brokerURL: `${socketHost}/stomp`,

      // Headers
      // Typical keys: login, passcode, host
      connectHeaders: {
        login: socket.mqUser,
        passcode: socket.mqPass,
      },

      // How often to heartbeat?
      // Interval in milliseconds, set to 0 to disable
      heartbeatIncoming: 0, // Typical value 0 - disabled
      heartbeatOutgoing: 0, // Typical value 20000 - every 20 seconds

      // Wait in milliseconds before attempting auto reconnect
      // Set to 0 to disable
      // Typical value 500 (500 milli seconds)
      reconnectDelay: 200,

      // Will log diagnostics on console
      // It can be quite verbose, not recommended in production
      // Skip this key to stop logging to console
      debug: (msg: string): void => {
        // console.log(new Date(), msg);
      },
    };
  } catch (e: unknown) {
    alertService.error('Socket configuration has error. Check config.json');
    throw e;
  }
}
