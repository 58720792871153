import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckPermissionPipe } from './check-permission.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [CheckPermissionPipe],
  exports: [CheckPermissionPipe],
})
export class CheckPermissionPipeModule {}
