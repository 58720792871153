/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-input-rename */
import { isNullOrUndefined, isUndefined } from 'util';

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Injector,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import {
  BaseOption,
  ChartColorList,
  ChartSelectMode,
  ChartType,
  ColorRange,
  DatasourceField,
  EventType,
  FieldRole,
  GeoField,
  HeatmapColorList,
  LogicalType,
  MapBy,
  MapGeometryType,
  MapLayerStyle,
  MapLayerType,
  MapLineStyle,
  MapSymbolType,
  MapThickness,
  Pivot,
  SelectionColor,
  Shelf,
  ShelveFieldType,
  UIChartColorByDimension,
  UIChartZoom,
  UIHeatmapLayer,
  UILayers,
  UILineLayer,
  UIMapOption,
  UIOption,
  UIPolygonLayer,
  UIPosition,
  UISymbolLayer,
  UITileLayer,
  createChartSelectInfo,
} from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';
import * as _ from 'lodash';
import $ from 'jquery';
import { PROP_MAP_CONFIG } from '../../../const';
import { ColorOptionConverter, FormatOptionConverter, TooltipOptionConverter } from '../../../converters';
import { provideBaseChartServices } from '../../../services';
import { EChartService } from '../../../services/echart.service';
import { ChartUtil } from '../../../utils';
import { BaseChart } from '../../base-chart';

declare let ol;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'map-chart',
  templateUrl: 'map-chart.component.html',
  styleUrls: ['../chart-host.component.scss'],
  providers: [...provideBaseChartServices()],
})
export class MapChartComponent extends BaseChart implements AfterViewInit {
  @HostBinding('style.background-color') get backgroundColor(): string {
    return this.getUiMapOption().style === 'Colored'
      ? '#AAD3DF'
      : this.getUiMapOption().style === 'Dark'
      ? '#262626'
      : '#D4DADC';
  }

  @ViewChild('tooltip', { static: true })
  private tooltipEl: ElementRef;

  @ViewChild('feature', { static: true })
  private featureEl: ElementRef;

  private _propMapConf = sessionStorage.getItem(PROP_MAP_CONFIG);
  private _customMapLayers: { name: string; layer: any; isDefault: boolean }[] = [];

  @Input('needToRemoveMapLayer')
  set removeAllLayer(isChartShow: boolean) {
    if (isChartShow == false) {
      if (this.olmap) {
        this.layerMap.forEach((item) => this.olmap.removeLayer(item.layerValue));
        this.layerMap = [];
        this.olmap.removeLayer(this.osmLayer);
        this.olmap.removeLayer(this.cartoDarkLayer);
        this.olmap.removeLayer(this.cartoPositronLayer);
        this._customMapLayers.forEach((item) => this.olmap.removeLayer(item.layer));
      }
    }
  }

  private preZoomSize = 0;

  olmap: any = undefined;

  osmLayer = new ol.layer.Tile({
    preload: Infinity,
    source: new ol.source.OSM({
      attributions: this.attribution(),
      crossOrigin: 'anonymous',
    }),
  });

  cartoPositronLayer = new ol.layer.Tile({
    preload: Infinity,
    source: new ol.source.XYZ({
      url: 'http://{1-4}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
      attributions: this.attribution(),
      crossOrigin: 'anonymous',
    }),
  });

  cartoDarkLayer = new ol.layer.Tile({
    preload: Infinity,
    source: new ol.source.XYZ({
      url: 'http://{1-4}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
      attributions: this.attribution(),
      crossOrigin: 'anonymous',
    }),
  });

  layerMap: any = [];

  tooltipLayer = undefined;

  tooltipInfo = {
    enable: false,
    geometryType: String(MapGeometryType.POINT),
    num: 1,
    name: null,
    title: 'Geo info',
    coords: [],
    fields: [],
  };

  legendInfo = {
    enable: true,
    position: String(UIPosition.RIGHT_BOTTOM),
    layer: [],

    showFl: true,
  };

  @Output('changeDraw')
  changeDrawEvent: EventEmitter<any> = new EventEmitter();

  @Output('shelf')
  shelfEvent: EventEmitter<any> = new EventEmitter();

  isResize = false;

  constructor(
    elementRef: ElementRef,
    eChartService: EChartService,
    destroy$: DestroyService,
    injector: Injector,
    private cdr: ChangeDetectorRef,
  ) {
    super(elementRef, destroy$, eChartService, injector);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.layerMap && this.layerMap.forEach((item) => item.layerValue.setSource(undefined));
    this.osmLayer && this.osmLayer.setSource(undefined);
    this.cartoPositronLayer && this.cartoPositronLayer.setSource(undefined);
    this.cartoDarkLayer && this.cartoDarkLayer.setSource(undefined);
    this._customMapLayers && this._customMapLayers.forEach((item) => item.layer.setSource(undefined));

    if (this.olmap) {
      this.olmap
        .getLayers()
        .getArray()
        .forEach((layer) => {
          if ('function' === typeof layer.setSource) {
            layer.setSource(undefined);
          }
          this.olmap.removeLayer(layer);
        });
      this.olmap.getOverlays().forEach((overlay) => this.olmap.removeOverlay(overlay));
      this.olmap.getControls().forEach((control) => this.olmap.removeControl(control));
      this.olmap.setTarget(null);
      this.olmap = undefined;
    }
  }

  override ngAfterViewInit(): void {
    if (this._propMapConf) {
      const objConf = JSON.parse(this._propMapConf);
      if (objConf.baseMaps) {
        this._customMapLayers = objConf.baseMaps.map((item) => {
          return {
            name: item.name,
            layer: new ol.layer.Tile({
              source: new ol.source.XYZ({
                url: item.url,
                attributions: this.attribution(),
                crossOrigin: 'anonymous',
              }),
            }),
            isDefault: objConf.defaultBaseMap === item.name,
          };
        });
      }
    }
  }

  ngAfterContentInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const scope = this;
    $(this.elementRef.nativeElement).on({
      mouseleave: function () {
        if (!_.isUndefined(scope.tooltipLayer) && scope.tooltipLayer.length > 0) {
          scope.tooltipLayer.setPosition(undefined);
        }
      },
    });

    const canvas = this.featureEl.nativeElement;
    canvas.width = 30;
    canvas.height = 20;
    const context = canvas.getContext('2d');
    context.fillStyle = '#7E94DE';
    this.roundRect(context, 0, 0, canvas.width, canvas.height, 4, true, false);
  }

  private roundRect(context, x, y, width, height, radius, fill, stroke): void {
    if (typeof radius === 'undefined') {
      radius = 5;
    }
    if (typeof radius === 'number') {
      radius = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
      const defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
      for (const side in defaultRadius) {
        radius[side] = radius[side] || defaultRadius[side];
      }
    }
    context.beginPath();
    context.moveTo(x + radius.tl, y);
    context.lineTo(x + width - radius.tr, y);
    context.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    context.lineTo(x + width, y + height - radius.br);
    context.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    context.lineTo(x + radius.bl, y + height);
    context.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    context.lineTo(x, y + radius.tl);
    context.quadraticCurveTo(x, y, x + radius.tl, y);
    context.closePath();
    if (fill) {
      context.fill();
    }
    if (stroke) {
      context.stroke();
    }
  }

  override isValid(pivot: Pivot, shelf: Shelf): boolean {
    if (!shelf) return false;

    let valid = false;

    if (shelf.layers) {
      for (let index = 0; index < shelf.layers.length; index++) {
        const fields: any[] = shelf.layers[index].fields;
        for (const layer of fields) {
          if (layer.field && layer.field.logicalType && -1 !== layer.field.logicalType.toString().indexOf('GEO')) {
            valid = true;
          }
        }
      }
    }
    return valid;
  }

  isCurrentShelfValid(shelf: Shelf): boolean {
    if (!shelf) return false;

    let valid = false;

    for (let layerIndex = 0; shelf.layers.length > layerIndex; layerIndex++) {
      const fields: any[] = shelf.layers[layerIndex].fields;
      if (fields) {
        for (const layer of fields) {
          if (layer.field && layer.field.logicalType && -1 !== layer.field.logicalType.toString().indexOf('GEO')) {
            valid = true;
            break;
          }
        }
      }
    }

    return valid;
  }

  override draw(isKeepRange?: boolean): void {
    if (isKeepRange == false && !_.isUndefined(this.uiOption['isChangeStyle']) && this.uiOption['isChangeStyle']) {
      this.changePointSize();
      delete this.uiOption['isChangeStyle'];
      return;
    }

    this.isResize = false;

    if (
      !_.isUndefined(this.getUiMapOption().analysis) &&
      !_.isUndefined(this.getUiMapOption().analysis['use']) &&
      this.getUiMapOption().analysis['use'] == true &&
      this.getUiMapOption().layerNum == this.getUiMapOption().layers.length - 1
    ) {
      if (this.drawByType == EventType.MAP_SPATIAL_REANALYSIS) {
        this.layerMap.forEach((item) => {
          if (item.id == this.getUiMapOption().layerNum) {
            this.olmap.removeLayer(item.layerValue);
          }
        });
      }
      this.drawAnalysis();
      return;
    }

    if (!this.isValid(this.pivot, this.shelf)) {
      this.data.show = false;
      this.noData.emit();
      return;
    }

    if (!this.isCurrentShelfValid(this.shelf)) {
      this.removeLayer(this.getUiMapOption().layerNum);
    }

    this.checkOption(this.getUiMapOption());

    this.setMinMax();

    this.cdr.detectChanges();

    this.data.show = true;

    this.legendInfo.layer = [];

    const isMapCreation: boolean = this.createMap();

    for (let layerIndex = 0; layerIndex < this.getUiMapOption().layers.length; layerIndex++) {
      const source = new ol.source.Vector({ crossOrigin: 'anonymous' });

      const emptySource = new ol.source.Vector();

      if (
        this.data.length != this.getUiMapOption().layers.length &&
        !this.isGeoFieldCheck(this.shelf.layers, layerIndex)
      ) {
        if (layerIndex == 0) {
          this.data = _.concat({ features: [] }, this.data[0]);
        }
      }

      this.createFeature(source, layerIndex);

      this.createLayer(source, emptySource, isMapCreation, layerIndex);

      this.createLegend(layerIndex, false);
    }

    this.createMapOverLayEvent();

    if (this.drawByType != null || !_.isEmpty(this.drawByType)) {
      this.olmap.updateSize();
    }

    this.drawFinished.emit();

    if (!this.isPage) {
      this.selection();
    }
  }

  getUiMapOption(): UIMapOption {
    return <UIMapOption>this.uiOption;
  }

  resize(): void {
    this.isResize = true;
    this.onResize(null);
  }

  changeFoldLegend() {
    if (!this.isPage) {
      this.legendInfo.showFl = !this.legendInfo.showFl;
    }
  }

  protected override apply(initFl: boolean = true): void {}

  protected override initOption(): BaseOption {
    return {
      type: ChartType.MAP,
      series: [],
    };
  }

  protected override convertSeries(): BaseOption {
    return this.chartOption;
  }

  protected override selection(): void {
    if (MapLayerType.HEATMAP === this.getUiMapOption().layers[this.getUiMapOption().layerNum].type) {
      return;
    }

    this.addChartSelectEventListener();
  }

  override addChartSelectEventListener() {
    this.olmap.on('singleclick', this.mapSelectionListener);
  }

  override addChartMultiSelectEventListener() {}

  @HostListener('window:resize', ['$event'])
  protected onResize(event) {
    if (this.olmap) {
      this.olmap.updateSize();
    }
  }

  protected override setDimensionList(): UIOption {
    const shelve: any = [];
    for (let layerIndex = 0; this.getUiMapOption().layers.length > layerIndex; layerIndex++) {
      if (this.shelf && !_.isUndefined(this.shelf.layers[layerIndex])) {
        this.shelf.layers[layerIndex].fields.forEach((field) => {
          shelve.push(field);
        });
      }
    }

    if (shelve == null) return;

    const getShelveReturnString = (shelve: any, typeList: ShelveFieldType[]): string[] => {
      const resultList: string[] = [];
      shelve.map((item) => {
        if (
          (_.eq(item.type, typeList[0]) || _.eq(item.type, typeList[1])) &&
          item.field &&
          item.field.logicalType &&
          -1 == item.field.logicalType.indexOf('GEO')
        ) {
          resultList.push(item.name);
        }
      });
      return resultList;
    };

    this.uiOption.fieldList = getShelveReturnString(shelve, [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP]);
    if (this.uiOption.color) {
      const targetField = (<UIChartColorByDimension>this.uiOption.color).targetField;

      if (!_.isEmpty(targetField)) {
        if (this.uiOption.fieldList.indexOf(targetField) < 0)
          (<UIChartColorByDimension>this.uiOption.color).targetField = _.last(this.uiOption.fieldList);
      } else {
        (<UIChartColorByDimension>this.uiOption.color).targetField = _.last(this.uiOption.fieldList);
      }
    }
    return this.uiOption;
  }

  protected override setMeasureList(): UIOption {
    const shelve: any = [];
    for (let layerIndex = 0; this.getUiMapOption().layers.length > layerIndex; layerIndex++) {
      if (this.shelf && !_.isUndefined(this.shelf.layers[layerIndex])) {
        this.shelf.layers[layerIndex].fields.forEach((field) => {
          shelve.push(field);
        });
      }
    }

    const getShelveReturnField = (shelve: any, typeList: ShelveFieldType[]): DatasourceField[] => {
      const resultList: any[] = [];
      shelve.map((item) => {
        if (
          (_.eq(item.type, typeList[0]) || _.eq(item.type, typeList[1])) &&
          item.field &&
          ('user_expr' === item.field.type || (item.field.logicalType && -1 == item.field.logicalType.indexOf('GEO')))
        ) {
          resultList.push(item);
        }
      });
      return resultList;
    };

    this.uiOption.fieldMeasureList = getShelveReturnField(shelve, [
      ShelveFieldType.MEASURE,
      ShelveFieldType.CALCULATED,
    ]);

    this.uiOption.fielDimensionList = getShelveReturnField(shelve, [
      ShelveFieldType.DIMENSION,
      ShelveFieldType.TIMESTAMP,
    ]);
    return this.uiOption;
  }

  private createMap(): boolean {
    this.osmLayer.getSource().setAttributions(this.attribution());
    this.cartoPositronLayer.getSource().setAttributions(this.attribution());
    this.cartoDarkLayer.getSource().setAttributions(this.attribution());

    let layer;
    if (0 < this._customMapLayers.length) {
      layer = this._customMapLayers.find((item) => item.isDefault);
    }

    switch (this.getUiMapOption().style) {
      case MapLayerStyle.LIGHT.toString():
        layer = this.cartoPositronLayer;
        break;
      case MapLayerStyle.DARK.toString():
        layer = this.cartoDarkLayer;
        break;
      case MapLayerStyle.COLORED.toString():
        layer = this.osmLayer;
        break;
      default:
        // eslint-disable-next-line no-case-declarations
        const customLayer = this._customMapLayers.find((item) => this.getUiMapOption().style === item.name);
        if (customLayer) {
          layer = customLayer.layer;
        }
    }

    if (this.olmap) {
      this.layerMap.forEach((item) => this.olmap.removeLayer(item.layerValue));
      this.layerMap = [];

      this.olmap.removeLayer(this.osmLayer);
      this.olmap.removeLayer(this.cartoDarkLayer);
      this.olmap.removeLayer(this.cartoPositronLayer);

      this._customMapLayers.forEach((item) => this.olmap.removeLayer(item.layer));
      this.olmap.addLayer(layer);
      return false;
    }

    this.olmap = new ol.Map({
      loadTilesWhileAnimating: true,
      loadTilesWhileInteracting: true,
      view: new ol.View({
        center: [126, 37],
        zoom: 6,

        projection: 'EPSG:4326',
        maxZoom: 20,
        minZoom: 3,
      }),
      layers: [layer],
      target: this.elementRef.nativeElement,
    });
    this.olmap.un('moveend');
    this.olmap.on('moveend', this.zoomFunction);

    for (let i = 0; i < document.getElementsByClassName('ol-attribution').length; i++) {
      const element = document.getElementsByClassName('ol-attribution')[i] as HTMLElement;
      element.style.right = 'auto';
      element.style.left = '.5em';
    }

    this.olmap.updateSize();

    const zoomslider = new ol.control.ZoomSlider();
    this.olmap.addControl(zoomslider);

    return true;
  }

  private createLayer(source: any, emptySource: any, isMapCreation: boolean, layerIndex: number): void {
    const layer: UILayers = this.getUiMapOption().layers[layerIndex];

    let field = null;
    _.each(this.shelf.layers[layerIndex].fields, (fieldTemp: any) => {
      if (fieldTemp.field.logicalType && fieldTemp.field.logicalType.toString().indexOf('GEO') != -1) {
        field = fieldTemp;
        return false;
      }
    });
    let isLogicalType = false;
    if (field != null && field.field != null && field.field.logicalType != null) {
      isLogicalType = true;
      const geomType = field.field.logicalType.toString();

      if (_.eq(layer.type, MapLayerType.SYMBOL) || _.eq(layer.type, MapLayerType.CLUSTER)) {
        const symbolLayer = new ol.layer.Vector({
          source: _.eq(geomType, LogicalType.GEO_POINT) ? source : emptySource,
          style: _.eq(geomType, LogicalType.GEO_POINT)
            ? this.pointStyleFunction(layerIndex, this.data)
            : new ol.style.Style(),
        });

        symbolLayer.setZIndex(4);
        this.layerMap.push({ id: layerIndex, layerValue: symbolLayer });

        if (isMapCreation && this.getUiMapOption().showMapLayer) {
          this.olmap.addLayer(symbolLayer);
        } else {
          if (this.getUiMapOption().showMapLayer) {
            this.olmap.addLayer(symbolLayer);
          } else {
            this.olmap.removeLayer(symbolLayer);
          }
        }
      } else if (
        _.eq(layer.type, MapLayerType.LINE) ||
        _.eq(layer.type, MapLayerType.MULTILINESTRING) ||
        _.eq(layer.type, MapLayerType.POLYGON)
      ) {
        const symbolLayer = new ol.layer.Vector({
          source: source,
          style: this.mapStyleFunction(layerIndex, this.data),
        });

        symbolLayer.setZIndex(3);
        this.layerMap.push({ id: layerIndex, layerValue: symbolLayer });

        if (isMapCreation && this.getUiMapOption().showMapLayer) {
          this.olmap.addLayer(symbolLayer);
        } else {
          if (this.getUiMapOption().showMapLayer) {
            this.olmap.addLayer(symbolLayer);
          } else {
            this.olmap.removeLayer(symbolLayer);
          }
        }
      } else if (_.eq(layer.type, MapLayerType.HEATMAP)) {
        const getHeatMapLayerValue: UIHeatmapLayer = <UIHeatmapLayer>layer;

        const heatmapLayer = new ol.layer.Heatmap({
          source: _.eq(geomType, LogicalType.GEO_POINT) ? source : emptySource,

          gradient: HeatmapColorList[getHeatMapLayerValue.color.schema],
          opacity: 1 - getHeatMapLayerValue.color.transparency * 0.01,
          radius: getHeatMapLayerValue.radius,
          blur: getHeatMapLayerValue.blur * 0.7,
        });

        heatmapLayer.setZIndex(0);
        this.layerMap.push({ id: layerIndex, layerValue: heatmapLayer });

        if (isMapCreation && this.getUiMapOption().showMapLayer) {
          this.olmap.addLayer(heatmapLayer);
        } else {
          if (this.getUiMapOption().showMapLayer) {
            this.olmap.addLayer(heatmapLayer);

            if (isUndefined(HeatmapColorList[getHeatMapLayerValue.color.schema])) {
              heatmapLayer.setGradient(HeatmapColorList['HC1']);
            } else {
              heatmapLayer.setGradient(HeatmapColorList[getHeatMapLayerValue.color.schema]);
            }
            heatmapLayer.setOpacity(1 - getHeatMapLayerValue.color.transparency * 0.01);
            heatmapLayer.setRadius(getHeatMapLayerValue.radius);
            heatmapLayer.setBlur(getHeatMapLayerValue.blur * 0.7);
          } else {
            this.olmap.removeLayer(heatmapLayer);
          }
        }
      } else if (_.eq(layer.type, MapLayerType.TILE)) {
        const hexagonLayer = new ol.layer.Vector({
          source: _.eq(geomType, LogicalType.GEO_POINT) ? source : emptySource,
          style: _.eq(geomType, LogicalType.GEO_POINT)
            ? this.hexagonStyleFunction(layerIndex, this.data)
            : new ol.style.Style(),
        });

        hexagonLayer.setZIndex(1);
        this.layerMap.push({ id: layerIndex, layerValue: hexagonLayer });

        if (isMapCreation && this.getUiMapOption().showMapLayer) {
          this.olmap.addLayer(hexagonLayer);
        } else {
          if (this.getUiMapOption().showMapLayer) {
            this.olmap.addLayer(hexagonLayer);
          } else {
            this.olmap.removeLayer(hexagonLayer);
          }
        }
      }
    }
    this.cdr.detectChanges();

    const overlayLayerId: string = 'layerId' + (layerIndex + 1);

    if (
      (this.drawByType == EventType.CHART_TYPE || this.olmap.getOverlayById(overlayLayerId) == null) &&
      isLogicalType &&
      (this.shelf.layers[layerIndex].fields[this.shelf.layers[layerIndex].fields.length - 1] as any).field
        .logicalType != null &&
      'Infinity'.indexOf(source.getExtent()[0]) == -1 &&
      (_.isUndefined(this.uiOption['layers'][layerIndex]['changeCoverage']) ||
        this.uiOption['layers'][layerIndex]['changeCoverage'])
    ) {
      this.olmap.getView().fit(source.getExtent());
    } else {
      if (this.uiOption.chartZooms && this.uiOption.chartZooms.length > 0) {
        this.olmap.getView().setCenter([this.uiOption.chartZooms[0].startValue, this.uiOption.chartZooms[0].endValue]);
        this.olmap.getView().setZoom(this.uiOption.chartZooms[0].count);
      }
    }
  }

  private createFeature(source, layerIndex): void {
    const data = this.data[layerIndex];

    const features = [];
    let field = null;
    _.each(this.shelf.layers[layerIndex].fields, (fieldTemp: any) => {
      if (
        fieldTemp != null &&
        fieldTemp.field.logicalType &&
        fieldTemp.field.logicalType.toString().indexOf('GEO') != -1
      ) {
        field = fieldTemp;
        return false;
      }
    });
    if (field != null && field.field != null && field.field.logicalType != null) {
      const geomType = field.field.logicalType.toString();

      const shelf: GeoField[] = _.cloneDeep(this.shelf.layers[layerIndex].fields);
      this.checkFieldList(shelf, layerIndex);

      for (let i = 0; i < data.features.length; i++) {
        if (data.features[i].geometry.type.toString().toLowerCase().indexOf('point') != -1) {
          const pointFeature = new ol.format.GeoJSON().readFeature(data.features[i]);
          if (_.eq(geomType, LogicalType.GEO_POINT)) {
            let featureCenter = pointFeature.getGeometry().getCoordinates();
            if (featureCenter.length === 1) {
              const extent = pointFeature.getGeometry().getExtent();
              featureCenter = ol.extent.getCenter(extent);
              pointFeature.setGeometry(new ol.geom.Point(featureCenter));
            }
            if (this.uiOption.fieldMeasureList.length > 0) {
              const alias = ChartUtil.getFieldAlias(
                this.getUiMapOption().layers[layerIndex].color.column,
                this.shelf.layers[layerIndex].fields,
                this.getUiMapOption().layers[layerIndex].color.aggregationType,
              );

              if (data.valueRange[alias]) {
                pointFeature.set('weight', pointFeature.getProperties()[alias] / data.valueRange[alias].maxValue);
              }
            }
          }
          pointFeature.set('layerNum', layerIndex);
          pointFeature.set('isClustering', this.getUiMapOption().layers[layerIndex]['clustering']);
          features[i] = pointFeature;
          source.addFeature(features[i]);
        } else if (data.features[i].geometry.type.toString().toLowerCase().indexOf('polygon') != -1) {
          const polygonFeature = new ol.format.GeoJSON().readFeature(data.features[i]);
          polygonFeature.set('layerNum', layerIndex);
          features[i] = polygonFeature;
          source.addFeature(features[i]);
        } else if (
          data != null &&
          data.features[i] != null &&
          data.features[i].geometry != null &&
          data.features[i].geometry.type.toString().toLowerCase().indexOf('line') != -1
        ) {
          let line;
          if (data.features[i].geometry.type.toString().toLowerCase().indexOf('multi') != -1) {
            line = new ol.geom.MultiLineString(data.features[i].geometry.coordinates);
          } else {
            line = new ol.geom.LineString(data.features[i].geometry.coordinates);
          }
          const lineFeature = new ol.Feature({ geometry: line });
          if (!_.isNull(this.getUiMapOption().layers[layerIndex].color.column)) {
            const alias = ChartUtil.getFieldAlias(
              this.getUiMapOption().layers[layerIndex].color.column,
              this.shelf.layers[layerIndex].fields,
              this.getUiMapOption().layers[layerIndex].color.aggregationType,
            );
            lineFeature.set(alias, data.features[i].properties[alias]);
          }
          lineFeature.set('layerNum', layerIndex);
          features.push(lineFeature);
          source.addFeature(lineFeature);
        }
      }
    }
  }

  private mapStyleFunction = (layerNum, data, selectMode?: ChartSelectMode, dataIndex?: number) => {
    const scope = this;
    const styleOption: UIMapOption = this.getUiMapOption();
    const styleLayer: UILayers = styleOption.layers[layerNum];
    const styleData =
      !_.isUndefined(this.getUiMapOption().analysis) && this.getUiMapOption().analysis['use'] === true
        ? data[dataIndex]
        : data[layerNum];
    return function (feature, resolution) {
      const layerType = styleLayer.type;
      let featureColor = styleLayer.color.schema;
      const featureColorType = styleLayer.color.by;
      let symbolType = null;
      let outlineType = null;
      let lineDashType = null;
      let lineMaxVal = 1;
      let outlineColor = null;
      let featureSizeType = null;
      let featureThicknessType = null;
      let alias = ChartUtil.getFieldAlias(
        styleLayer.color.column,
        scope.shelf.layers[layerNum] as any,
        styleLayer.color.aggregationType,
      );

      if (
        !_.isUndefined(styleOption['analysis']) &&
        !_.isUndefined(styleOption['analysis']['use']) &&
        styleOption['analysis']['use']
      ) {
        if (!isNullOrUndefined(styleLayer.color.aggregationType)) {
          alias = styleLayer.color.aggregationType + '(' + alias + ')';
        }
      }

      if (_.eq(layerType, MapLayerType.SYMBOL) || _.eq(layerType, MapLayerType.CLUSTER)) {
        const symbolLayer: UISymbolLayer = <UISymbolLayer>styleLayer;
        symbolType = symbolLayer.symbol;
        outlineType = symbolLayer.outline ? symbolLayer.outline.thickness : null;
        outlineColor = symbolLayer.outline ? symbolLayer.outline.color : null;
        featureSizeType = symbolLayer.size.by;
      }

      if (_.eq(layerType, MapLayerType.LINE) || _.eq(layerType, MapLayerType.MULTILINESTRING)) {
        const lineLayer: UILineLayer = <UILineLayer>styleLayer;
        lineDashType = lineLayer.lineStyle;
        featureThicknessType = lineLayer.thickness.by;
        lineMaxVal = lineLayer.thickness.maxValue;
      }

      if (_.eq(layerType, MapLayerType.POLYGON) || _.eq(layerType, MapLayerType.MULTIPOLYGON)) {
        const polygonLayer: UIPolygonLayer = <UIPolygonLayer>styleLayer;
        outlineType = polygonLayer.outline ? polygonLayer.outline.thickness : null;
        outlineColor = polygonLayer.outline ? polygonLayer.outline.color : null;
      }

      if (_.eq(featureColorType, MapBy.MEASURE)) {
        if (styleLayer.color['ranges']) {
          for (const range of styleLayer.color['ranges']) {
            const rangeMax = range.fixMax;
            let rangeMin = range.fixMin;

            if (rangeMax === null) {
              if (feature.getProperties()[alias] > rangeMin) {
                featureColor = range.color;
              }
            } else {
              if (rangeMin === null) {
                const minValue = styleData.valueRange[alias].minValue;

                if (minValue >= 0) {
                  rangeMin = 0;
                } else {
                  rangeMin = minValue;
                }
              }

              if (feature.getProperties()[alias] >= rangeMin && feature.getProperties()[alias] <= rangeMax) {
                featureColor = range.color;
              }
            }
          }
        } else {
          const ranges = ColorOptionConverter.setMapMeasureColorRange(
            styleOption,
            styleData,
            scope.getColorList(styleLayer),
            layerNum,
            scope.shelf.layers[layerNum] as any,
          );

          const formatValue = (value) => {
            return parseFloat(
              (
                (Number(value) * Math.pow(10, styleOption.valueFormat.decimal)) /
                Math.pow(10, styleOption.valueFormat.decimal)
              ).toFixed(styleOption.valueFormat.decimal),
            );
          };

          for (const range of ranges) {
            const rangeMax = range.fixMax;
            let rangeMin = range.fixMin;

            if (rangeMax === null) {
              if (feature.getProperties()[alias] > rangeMin) {
                featureColor = range.color;
              }
            } else {
              if (rangeMin === null) {
                const minValue = styleData.valueRange[alias].minValue;

                if (minValue >= 0) {
                  rangeMin = 0;
                } else {
                  rangeMin = minValue;
                }
              }

              const value = formatValue(feature.getProperties()[alias]);

              if ((rangeMin == 0 && rangeMin == value) || (value >= rangeMin && value <= rangeMax)) {
                featureColor = range.color;
              }
            }
          }
        }
      } else if (_.eq(featureColorType, MapBy.DIMENSION)) {
        const ranges = scope.setDimensionColorRange(styleLayer, styleData, scope.getColorList(styleLayer), []);
        _.each(ranges, (range) => {
          if (_.eq(feature.getProperties()[alias], (range as any).column)) {
            featureColor = range.color;
            return false;
          }
        });
      } else if (_.eq(featureColorType, MapBy.NONE)) {
        featureColor = styleLayer.color.schema;
      }

      featureColor = scope.hexToRgbA(featureColor, 1 - styleLayer.color.transparency * 0.01);

      let outlineWidth = 0.00000001;
      if (_.eq(outlineType, MapThickness.THIN)) {
        outlineWidth = 1;
      } else if (_.eq(outlineType, MapThickness.NORMAL)) {
        outlineWidth = 2;
      } else if (_.eq(outlineType, MapThickness.THICK)) {
        outlineWidth = 3;
      }

      let lineDash = [1];
      if (_.eq(lineDashType, MapLineStyle.DOTTED)) {
        lineDash = [3, 3];
      } else if (_.eq(lineDashType, MapLineStyle.DASHED)) {
        lineDash = [4, 8];
      }

      let lineThickness = 2;

      if (_.eq(layerType, MapLayerType.LINE) || _.eq(layerType, MapLayerType.MULTILINESTRING)) {
        try {
          const lineLayer: UILineLayer = <UILineLayer>styleLayer;
          const lineAlias = ChartUtil.getFieldAlias(
            lineLayer.thickness.column,
            scope.shelf.layers[layerNum] as any,
            lineLayer.thickness.aggregationType,
          );

          if (!_.eq(lineLayer.thickness.column, 'NONE') && _.eq(featureThicknessType, MapBy.MEASURE)) {
            lineThickness = parseInt(feature.get(lineAlias)) / (styleData.valueRange[lineAlias].maxValue / lineMaxVal);
            if (lineThickness < 1) {
              lineThickness = 1;
            } else if (lineThickness > lineMaxVal) {
              lineThickness = lineMaxVal;
            }
          }
        } catch (error) {}
      }

      let filterFl = false;

      filterFl = scope.setFeatureSelectionMode(scope, feature);

      if ((filterFl || selectMode) && ChartSelectMode.ADD !== feature.getProperties()['selection']) {
        outlineWidth = 2;

        if (MapLayerStyle.DARK.toString() === styleOption.style) {
          featureColor = SelectionColor.FEATURE_DARK.toString();
          outlineColor = SelectionColor.OUTLINE_DARK.toString();
        } else {
          featureColor = SelectionColor.FEATURE_LIGHT.toString();
          outlineColor = SelectionColor.OUTLINE_LIGHT.toString();
        }
      }

      let style = new ol.style.Style();

      if (_.eq(layerType, MapLayerType.LINE) || _.eq(layerType, MapLayerType.MULTILINESTRING)) {
        style = new ol.style.Style({
          stroke: new ol.style.Stroke({
            color: featureColor,
            width: lineThickness,
            lineDash: lineDash,
          }),
        });
      } else if (_.eq(layerType, MapLayerType.POLYGON) || _.eq(layerType, MapLayerType.MULTIPOLYGON)) {
        style = new ol.style.Style({
          stroke: new ol.style.Stroke({
            color: outlineColor,
            width: outlineWidth,
          }),
          fill: new ol.style.Fill({
            color: featureColor,
          }),
        });
      }
      return style;
    };
  };

  private pointStyleFunction = (layerNum, data, selectMode?: ChartSelectMode, dataIndex?: number) => {
    const scope: any = this;
    const styleOption: UIMapOption = this.getUiMapOption();
    const styleLayer: UILayers = styleOption.layers[layerNum];
    const styleData =
      !_.isUndefined(this.getUiMapOption().analysis) && this.getUiMapOption().analysis['use'] === true
        ? data[dataIndex]
        : data[layerNum];
    return function (feature, resolution) {
      const symbolLayer: UISymbolLayer = <UISymbolLayer>styleLayer;
      const layerType = styleLayer.type;
      let featureColor = styleLayer.color.schema;
      const featureColorType = styleLayer.color.by;
      const symbolType = symbolLayer.symbol;
      const outlineType = symbolLayer.outline ? symbolLayer.outline.thickness : null;
      let outlineColor = symbolLayer.outline ? symbolLayer.outline.color : null;
      const lineMaxVal = 1;
      const featureSizeType = symbolLayer.size.by;
      let style = null;
      let alias = ChartUtil.getFieldAlias(
        styleLayer.color.column,
        scope.shelf.layers[layerNum].fields,
        styleLayer.color.aggregationType,
      );
      if (styleLayer.type == MapLayerType.CLUSTER) {
        alias = 'count';
      }

      let size = 0;
      let isClustering = false;

      if (
        !_.isUndefined(feature.getProperties()) &&
        !_.isUndefined(feature.getProperties()['isClustering']) &&
        !_.isUndefined(feature.getProperties().count) &&
        feature.getProperties()['isClustering'] == true
      ) {
        isClustering = true;
        size = feature.getProperties().count;
      }

      if (isClustering == false || size <= 1) {
        if (_.eq(featureColorType, MapBy.MEASURE)) {
          if (styleLayer.color['ranges']) {
            for (const range of styleLayer.color['ranges']) {
              const rangeMax = range.fixMax;
              let rangeMin = range.fixMin;

              if (rangeMax === null) {
                if (feature.getProperties()[alias] > rangeMin) {
                  featureColor = range.color;
                }
              } else {
                if (rangeMin === null) {
                  let minValue = 0;
                  if (styleData.valueRange[alias]) {
                    minValue = styleData.valueRange[alias].minValue;
                  }
                  if (minValue >= 0) {
                    rangeMin = 0;
                  } else {
                    rangeMin = minValue;
                  }
                }
                if (feature.getProperties()[alias] >= rangeMin && feature.getProperties()[alias] <= rangeMax) {
                  featureColor = range.color;
                }
              }
            }
          } else {
            const ranges = ColorOptionConverter.setMapMeasureColorRange(
              styleOption,
              styleData,
              scope.getColorList(styleLayer),
              layerNum,
              scope.shelf.layers[layerNum].fields,
            );

            const formatValue = (value) => {
              return parseFloat(
                (
                  (Number(value) * Math.pow(10, styleOption.valueFormat.decimal)) /
                  Math.pow(10, styleOption.valueFormat.decimal)
                ).toFixed(styleOption.valueFormat.decimal),
              );
            };
            for (const range of ranges) {
              const rangeMax = range.fixMax;
              let rangeMin = range.fixMin;
              if (rangeMax === null) {
                if (feature.getProperties()[alias] > rangeMin) {
                  featureColor = range.color;
                }
              } else {
                if (rangeMin === null) {
                  const minValue = styleData.valueRange[alias].minValue;

                  if (minValue >= 0) {
                    rangeMin = 0;
                  } else {
                    rangeMin = minValue;
                  }
                }

                const value = formatValue(feature.getProperties()[alias]);

                if ((rangeMin == 0 && rangeMin == value) || (value >= rangeMin && value <= rangeMax)) {
                  featureColor = range.color;
                }
              }
            }
          }
        } else if (_.eq(featureColorType, MapBy.DIMENSION)) {
          const ranges = scope.setDimensionColorRange(styleLayer, styleData, scope.getColorList(styleLayer), []);
          _.each(ranges, (range) => {
            if (_.eq(feature.getProperties()[alias], range.column)) {
              featureColor = range.color;
              return false;
            }
          });
        } else if (_.eq(featureColorType, MapBy.NONE)) {
          featureColor = styleLayer.color.schema;
        }
        featureColor = scope.hexToRgbA(featureColor, 1 - styleLayer.color.transparency * 0.01);

        let outlineWidth = 0.00000001;
        if (_.eq(outlineType, MapThickness.THIN)) {
          outlineWidth = 1;
        } else if (_.eq(outlineType, MapThickness.NORMAL)) {
          outlineWidth = 2;
        } else if (_.eq(outlineType, MapThickness.THICK)) {
          outlineWidth = 3;
        }

        let featureSize = 5;
        try {
          if (_.eq(featureSizeType, MapBy.MEASURE)) {
            featureSize =
              parseInt(
                feature.get(
                  ChartUtil.getFieldAlias((<UISymbolLayer>styleLayer).size.column, scope.shelf.layers[layerNum].fields),
                ),
              ) /
              (styleData.valueRange[
                ChartUtil.getFieldAlias((<UISymbolLayer>styleLayer).size.column, scope.shelf.layers[layerNum].fields)
              ].maxValue /
                30);
            if (featureSize < 5) {
              featureSize = 5;
            }
          }
        } catch (error) {}
        let lineThickness = 2;
        try {
          if (_.eq(featureSizeType, MapBy.MEASURE)) {
            lineThickness =
              parseInt(
                feature.get(
                  ChartUtil.getFieldAlias((<UISymbolLayer>styleLayer).size.column, scope.shelf.layers[layerNum].fields),
                ),
              ) /
              (styleData.valueRange[
                ChartUtil.getFieldAlias((<UISymbolLayer>styleLayer).size.column, scope.shelf.layers[layerNum].fields)
              ].maxValue /
                lineMaxVal);
            if (lineThickness < 1) {
              lineThickness = 1;
            }
          }
        } catch (error) {}

        let filterFl = false;

        filterFl = scope.setFeatureSelectionMode(scope, feature);

        if ((filterFl || selectMode) && ChartSelectMode.ADD !== feature.getProperties()['selection']) {
          outlineWidth = 2;

          if (MapLayerStyle.DARK.toString() === styleOption.style) {
            featureColor = SelectionColor.FEATURE_DARK.toString();
            outlineColor = SelectionColor.OUTLINE_DARK.toString();
          } else {
            featureColor = SelectionColor.FEATURE_LIGHT.toString();
            outlineColor = SelectionColor.OUTLINE_LIGHT.toString();
          }
        }

        style = new ol.style.Style({
          image: new ol.style.Circle({
            radius: 4,
            fill: new ol.style.Fill({
              color: featureColor,
            }),
          }),
          stroke: new ol.style.Stroke({
            color: featureColor,
            width: lineThickness,
          }),
          fill: new ol.style.Fill({
            color: featureColor,
          }),
        });

        if (
          symbolType == MapSymbolType.CIRCLE ||
          symbolType == MapSymbolType.SQUARE ||
          symbolType == MapSymbolType.TRIANGLE
        ) {
          if (
            isNullOrUndefined(styleLayer.pointRadius) ||
            isNaN(styleLayer.pointRadius) ||
            isNullOrUndefined(styleLayer['pointRadiusFrom'])
          ) {
            styleLayer.pointRadius = featureSize;
            styleLayer['pointRadiusFrom'] = featureSize;
          } else {
            if (
              !isNullOrUndefined(styleLayer['needToCalPointRadius']) &&
              styleLayer['needToCalPointRadius'] &&
              !isNullOrUndefined(styleLayer['pointRadiusTo'])
            ) {
              let maxValue: number = !isNullOrUndefined(styleLayer['size'].maxValue)
                ? parseFloat(_.cloneDeep(styleLayer['size'].maxValue))
                : _.cloneDeep(styleLayer.color.maxValue);
              const minValue: number = !isNullOrUndefined(styleLayer['size'].minValue)
                ? parseFloat(_.cloneDeep(styleLayer['size'].minValue))
                : _.cloneDeep(styleLayer.color.minValue);

              if (minValue < 0) {
                maxValue = maxValue + -minValue;
              }
              if (maxValue > 0 && maxValue < 1) {
                const countDecimals: number = maxValue.toString().split('.')[1].length;
                const decimalNum = Math.pow(10, countDecimals);
                maxValue = maxValue * decimalNum;
              } else if (maxValue == 0) {
                maxValue = 1;
              } else if (maxValue < 0) {
                maxValue = -maxValue;
              }
              let calFeatureSize = featureSize;
              if (!isNullOrUndefined(feature.getProperties()[styleLayer['size'].column])) {
                calFeatureSize = feature.getProperties()[styleLayer['size'].column] * (200 / maxValue);
              }
              if (calFeatureSize < styleLayer['pointRadiusFrom']) {
                calFeatureSize = styleLayer['pointRadiusFrom'];
              } else if (calFeatureSize > styleLayer['pointRadiusTo']) {
                calFeatureSize = styleLayer['pointRadiusTo'];
              }
              styleLayer.pointRadius = calFeatureSize;
            }
          }
        }
        switch (symbolType) {
          case MapSymbolType.CIRCLE:
            style = new ol.style.Style({
              image: new ol.style.Circle({
                radius: styleLayer.pointRadius,
                fill: new ol.style.Fill({
                  color: featureColor,
                }),
                stroke: new ol.style.Stroke({ color: outlineColor, width: outlineWidth }),
              }),
              stroke: new ol.style.Stroke({
                color: outlineColor,
                width: outlineWidth,
              }),
              fill: new ol.style.Fill({
                color: featureColor,
              }),
            });
            break;
          case MapSymbolType.SQUARE:
            style = new ol.style.Style({
              image: new ol.style.RegularShape({
                fill: new ol.style.Fill({ color: featureColor }),
                points: 4,
                radius: styleLayer.pointRadius,
                angle: Math.PI / 4,
                stroke: new ol.style.Stroke({ color: outlineColor, width: outlineWidth }),
              }),
              stroke: new ol.style.Stroke({
                color: outlineColor,
                width: outlineWidth,
              }),
              fill: new ol.style.Fill({
                color: featureColor,
              }),
            });
            break;
          case MapSymbolType.TRIANGLE:
            style = new ol.style.Style({
              image: new ol.style.RegularShape({
                fill: new ol.style.Fill({ color: featureColor }),
                points: 3,
                radius: styleLayer.pointRadius,
                rotation: Math.PI / 4,
                angle: -28,
                stroke: new ol.style.Stroke({ color: outlineColor, width: outlineWidth }),
              }),
              stroke: new ol.style.Stroke({
                color: outlineColor,
                width: outlineWidth,
              }),
              fill: new ol.style.Fill({
                color: featureColor,
              }),
            });
            break;
          case MapSymbolType.PIN:
            style = new ol.style.Style({
              image: new ol.style.Icon({
                color: featureColor,
                crossOrigin: 'anonymous',
                scale: featureSize * 0.1,
                src: '/assets/bi/images/ic_pin.png',
              }),
              stroke: new ol.style.Stroke({
                color: outlineColor,
                width: outlineWidth,
              }),
              fill: new ol.style.Fill({
                color: featureColor,
              }),
            });
            break;
          case MapSymbolType.PLAIN:
            style = new ol.style.Style({
              image: new ol.style.Icon({
                color: featureColor,
                crossOrigin: 'anonymous',
                scale: featureSize * 0.1,
                src: '/assets/bi/images/ic_map_airport.png',
              }),
              stroke: new ol.style.Stroke({
                color: outlineColor,
                width: outlineWidth,
              }),
              fill: new ol.style.Fill({
                color: featureColor,
              }),
            });
            break;
          case MapSymbolType.USER:
            style = new ol.style.Style({
              image: new ol.style.Icon({
                color: featureColor,
                crossOrigin: 'anonymous',
                scale: featureSize * 0.1,
                src: '/assets/bi/images/ic_map_human.png',
              }),
              stroke: new ol.style.Stroke({
                color: outlineColor,
                width: outlineWidth,
              }),
              fill: new ol.style.Fill({
                color: featureColor,
              }),
            });
            break;
          default:
            style = new ol.style.Style({
              stroke: new ol.style.Stroke({
                color: outlineColor,
                width: outlineWidth,
              }),
              fill: new ol.style.Fill({
                color: featureColor,
              }),
            });
            break;
        }
      } else {
        const canvas = scope.featureEl.nativeElement;
        style = new ol.style.Style({
          image: new ol.style.Icon({
            img: canvas,
            imgSize: [canvas.width, canvas.height],
            opacity: 0.85,
          }),
          text: new ol.style.Text({
            text: size.toString(),
            fill: new ol.style.Fill({
              color: '#fff',
            }),
            font: '10px sans-serif',
          }),
        });
      }
      return style;
    };
  };

  private hexagonStyleFunction = (layerNum, data, selectMode?: ChartSelectMode, dataIndex?: number) => {
    const scope: any = this;
    const styleOption: UIMapOption = this.getUiMapOption();
    const styleLayer: UILayers = styleOption.layers[layerNum];
    const alias = ChartUtil.getFieldAlias(
      styleLayer.color.column,
      scope.shelf.layers[layerNum].fields,
      styleLayer.color.aggregationType,
    );
    const styleData =
      !_.isUndefined(this.getUiMapOption().analysis) && this.getUiMapOption().analysis['use'] === true
        ? data[dataIndex]
        : data[layerNum];

    return function (feature, resolution) {
      let featureColor = styleLayer.color.schema;
      const featureColorType = styleLayer.color.by;

      if (_.eq(featureColorType, MapBy.MEASURE)) {
        if (styleLayer.color['ranges']) {
          for (const range of styleLayer.color['ranges']) {
            const rangeMax = range.fixMax;
            let rangeMin = range.fixMin;

            if (rangeMax === null) {
              if (feature.getProperties()[alias] > rangeMin) {
                featureColor = range.color;
              }
            } else {
              if (rangeMin === null) {
                const minValue = styleData.valueRange[alias].minValue;

                if (minValue >= 0) {
                  rangeMin = 0;
                } else {
                  rangeMin = minValue;
                }
              }

              if (feature.getProperties()[alias] >= rangeMin && feature.getProperties()[alias] <= rangeMax) {
                featureColor = range.color;
              }
            }
          }
        } else {
          const ranges = ColorOptionConverter.setMapMeasureColorRange(
            styleOption,
            styleData,
            scope.getColorList(styleLayer),
            layerNum,
            scope.shelf.layers[layerNum].fields,
          );

          const formatValue = (value) => {
            return parseFloat(
              (
                (Number(value) * Math.pow(10, styleOption.valueFormat.decimal)) /
                Math.pow(10, styleOption.valueFormat.decimal)
              ).toFixed(styleOption.valueFormat.decimal),
            );
          };

          for (const range of ranges) {
            const rangeMax = range.fixMax;
            let rangeMin = range.fixMin;

            if (rangeMax === null) {
              if (feature.getProperties()[alias] > rangeMin) {
                featureColor = range.color;
              }
            } else {
              if (rangeMin === null) {
                const minValue = styleData.valueRange[alias].minValue;

                if (minValue >= 0) {
                  rangeMin = 0;
                } else {
                  rangeMin = minValue;
                }
              }

              const value = formatValue(feature.getProperties()[alias]);

              if ((rangeMin == 0 && rangeMin == value) || (value >= rangeMin && value <= rangeMax)) {
                featureColor = range.color;
              }
            }
          }
        }
      } else if (_.eq(featureColorType, MapBy.DIMENSION)) {
        const ranges = scope.setDimensionColorRange(styleLayer, styleData, scope.getColorList(styleLayer), []);
        _.each(ranges, (range) => {
          if (_.eq(feature.getProperties()[alias], range.column)) {
            featureColor = range.color;
            return false;
          }
        });
      } else if (_.eq(featureColorType, MapBy.NONE)) {
        featureColor = styleLayer.color.schema;
      }

      featureColor = scope.hexToRgbA(featureColor, 1 - styleLayer.color.transparency * 0.01);

      let filterFl = false;

      filterFl = scope.setFeatureSelectionMode(scope, feature);

      if ((filterFl || selectMode) && ChartSelectMode.ADD !== feature.getProperties()['selection']) {
        if (MapLayerStyle.DARK.toString() === styleOption.style) {
          featureColor = SelectionColor.FEATURE_DARK.toString();
        } else {
          featureColor = SelectionColor.FEATURE_LIGHT.toString();
        }
      }

      const style = new ol.style.Style({
        stroke: new ol.style.Stroke({
          color: featureColor,
          width: 1,
        }),
        fill: new ol.style.Fill({
          color: featureColor,
        }),
      });

      return style;
    };
  };

  private attribution(): any {
    if (this.getUiMapOption()) {
      return this.getUiMapOption().licenseNotation;
    } else {
      return '© OpenStreetMap contributors';
    }
  }

  private createMapOverLayEvent(): void {
    this.olmap.getOverlays().forEach((overlay) => {
      this.olmap.removeOverlay(overlay);
    });

    this.tooltipLayer = [];
    for (let layerIndex = 0; this.getUiMapOption().layers.length > layerIndex; layerIndex++) {
      if (MapLayerType.HEATMAP !== this.getUiMapOption().layers[layerIndex].type) {
        this.tooltipLayer = new ol.Overlay({
          element: this.tooltipEl.nativeElement,
          positioning: 'top-center',
          stopEvent: false,
          id: 'layerId' + (layerIndex + 1),
        });
        this.olmap.addOverlay(this.tooltipLayer);
      }
    }

    if (!_.isNull(this.olmap.frameState_)) {
      this.preZoomSize = Math.round(this.olmap.frameState_.viewState.zoom);
    }
    this.olmap.un('pointermove', this.tooltipFunction);
    this.olmap.on('pointermove', this.tooltipFunction);
  }

  private tooltipFunction = (event) => {
    let tooltipTypeToShow = null;

    let feature = this.olmap.forEachFeatureAtPixel(event.pixel, (feature) => {
      return feature;
    });

    if (
      !feature ||
      (!_.isUndefined(feature.getProperties()) &&
        !_.isUndefined(feature.getProperties()['isClustering']) &&
        feature.getProperties()['isClustering'] == true &&
        !_.isUndefined(feature.getProperties()['count']) &&
        feature.getProperties()['count'] > 1) ||
      (!_.isUndefined(feature.getProperties()['layerNum']) && feature.getProperties()['layerNum'] == -5)
    ) {
      this.tooltipInfo.enable = false;
      this.cdr.detectChanges();
      if (!_.isUndefined(this.tooltipLayer) && this.tooltipLayer.length > 0) {
        this.tooltipLayer.setPosition(undefined);
      }

      if (!this.isPage) $(document).find('.ddp-ui-dash-contents').removeClass('ddp-tooltip');
      else $(document).find('.ddp-view-chart-contents').removeClass('ddp-tooltip');
      return;
    }

    const toolTipLayerNum = _.cloneDeep(
      !isNullOrUndefined(feature.getProperties().layerNum)
        ? feature.getProperties().layerNum
        : !isNullOrUndefined(feature.getProperties().features[0].get('layerNum'))
        ? feature.getProperties().features[0].get('layerNum')
        : 0,
    );

    if (
      !_.isUndefined(this.getUiMapOption().layers[toolTipLayerNum]) &&
      !_.isUndefined(this.getUiMapOption().layers[toolTipLayerNum].type) &&
      this.getUiMapOption().layers[toolTipLayerNum].type !== MapLayerType.HEATMAP
    ) {
      if (!this.isPage) $(document).find('.ddp-ui-dash-contents').addClass('ddp-tooltip');
      else $(document).find('.ddp-view-chart-contents').addClass('ddp-tooltip');

      const features = feature.get('features');
      if (!isNullOrUndefined(features)) {
        if (features.length > 1) {
          return;
        }
        feature = features[0];
      }
      if (
        tooltipTypeToShow == null ||
        this.getUiMapOption().layers[toolTipLayerNum].type == MapLayerType.SYMBOL ||
        this.getUiMapOption().layers[toolTipLayerNum].type == MapLayerType.CLUSTER
      ) {
        if (
          this.getUiMapOption().toolTip.displayTypes != undefined &&
          this.getUiMapOption().toolTip.displayTypes[17] !== null
        ) {
          tooltipTypeToShow = this.getUiMapOption().layers[toolTipLayerNum].type;
          this.tooltipInfo.num = toolTipLayerNum + 1;
          this.tooltipInfo.name = this.getUiMapOption().layers[toolTipLayerNum].name;
        } else {
          this.tooltipInfo.name = null;
        }

        this.tooltipInfo.geometryType = feature.getGeometry().getType();

        let coords = [0, 0];
        const extent = feature.getGeometry().getExtent();
        coords = ol.extent.getCenter(extent);

        this.tooltipInfo.coords = [];

        if (
          this.getUiMapOption().toolTip.displayTypes != undefined &&
          this.getUiMapOption().toolTip.displayTypes[18] !== null
        ) {
          if (_.eq(this.tooltipInfo.geometryType, String(MapGeometryType.LINE))) {
            this.tooltipInfo.coords[0] = coords[0];
            this.tooltipInfo.coords[coords.length - 1] = coords[coords.length - 1];
          } else {
            this.tooltipInfo.coords[0] = coords[0].toFixed(4) + ', ' + coords[1].toFixed(4);
          }
        }

        this.tooltipInfo.fields = [];

        if (
          this.getUiMapOption().toolTip.displayTypes != undefined &&
          this.getUiMapOption().toolTip.displayTypes[19] !== null
        ) {
          const aggregationKeys: any[] = [];

          let layerItems = [];

          if (
            !_.isUndefined(this.getUiMapOption().analysis) &&
            !_.isUndefined(this.getUiMapOption().analysis['use']) &&
            this.getUiMapOption().analysis['use'] === true
          ) {
            layerItems = _.cloneDeep(
              !_.isUndefined(this.shelf.layers[this.getUiMapOption().layerNum]) &&
                !_.isUndefined(this.shelf.layers[this.getUiMapOption().layerNum].fields) &&
                this.shelf.layers[this.getUiMapOption().layerNum].fields.length > 0
                ? this.shelf.layers[this.getUiMapOption().layerNum].fields
                : [],
            );
          } else {
            this.shelf.layers[toolTipLayerNum].fields.forEach((field) => {
              layerItems.push(field);
            });
          }

          for (const key in feature.getProperties()) {
            _.each(this.getUiMapOption().toolTip.displayColumns, (field, idx) => {
              if (_.eq(field, key)) {
                if (this.getUiMapOption().layers[this.getUiMapOption().layerNum].type == MapLayerType.CLUSTER) {
                  return false;
                }
                aggregationKeys.push({ idx: idx, key: key });
                return false;
              }
            });
          }

          if (
            (aggregationKeys.length >= 2 &&
              !_.isUndefined(this.getUiMapOption().analysis) &&
              this.getUiMapOption().analysis['use'] === true) ||
            (!_.isUndefined(this.getUiMapOption().analysis) &&
              !_.isUndefined(this.getUiMapOption().analysis.operation) &&
              !_.isUndefined(this.getUiMapOption().analysis.operation.aggregation) &&
              !_.isUndefined(this.getUiMapOption().analysis.operation.aggregation.type))
          ) {
            let aggregationKeyNum = 0;
            let isAggregationKeyNeedToRemove = false;
            for (let aggregationKeyIndex = 0; aggregationKeys.length > aggregationKeyIndex; aggregationKeyIndex++) {
              if (aggregationKeys[aggregationKeyIndex].key == 'count') {
                aggregationKeyNum = aggregationKeyIndex;
                isAggregationKeyNeedToRemove = true;
                break;
              }
            }
            if (isAggregationKeyNeedToRemove) {
              aggregationKeys.splice(aggregationKeyNum, 1);
            }
          }

          _.each(_.orderBy(aggregationKeys, ['idx']), (aggregationKey) => {
            let tooltipVal = feature.get(aggregationKey.key);
            if (
              aggregationKey.key !== 'geometry' &&
              aggregationKey.key !== 'weight' &&
              aggregationKey.key !== 'layerNum'
            ) {
              const field = {
                name: '',
                value: '',
              };
              if (aggregationKey.key === 'features') {
                field.name = aggregationKey.key;
                field.value = feature.get(aggregationKey.key).length;
              } else {
                if (typeof tooltipVal === 'number') {
                  tooltipVal = FormatOptionConverter.getFormatValue(tooltipVal, this.getUiMapOption().valueFormat);
                }
                field.name = aggregationKey.key;
                field.value = tooltipVal;
              }
              this.tooltipInfo.fields.push(field);
            }
          });
        }

        if (
          null === this.tooltipInfo.name &&
          0 === this.tooltipInfo.coords.length &&
          0 === this.tooltipInfo.fields.length
        ) {
          this.tooltipInfo.enable = false;
        } else {
          this.tooltipInfo.enable = true;
        }

        this.cdr.markForCheck();

        if (this.uiOption.toolTip) {
          let yOffset = -80;

          const sizeOfToolTipHeight = [];
          if (!_.isUndefined(this.tooltipInfo.fields) && this.tooltipInfo.fields.length > 0) {
            this.tooltipInfo.fields.forEach((field) => {
              if (field['name'] != null && !_.isUndefined(field['name'])) {
                sizeOfToolTipHeight.push(field['name']);
              }
              if (field['value'] != null && !_.isUndefined(field['value'])) {
                sizeOfToolTipHeight.push(field['value']);
              }
            });
          }
          if (sizeOfToolTipHeight.length > 0) {
            yOffset = yOffset - 25 * (sizeOfToolTipHeight.length / 1.2);
          }
          const offset = [-92, yOffset];
          this.tooltipLayer.setOffset(offset);
        }
        const toShowCoords = event.coordinate;
        if (_.eq(this.tooltipInfo.geometryType, String(MapGeometryType.LINE))) {
          toShowCoords[toShowCoords.length - 1] = toShowCoords[toShowCoords.length - 1] + 0.0018;
          this.tooltipLayer.setPosition(toShowCoords);
        } else {
          this.tooltipLayer.setPosition(toShowCoords);
        }
      }
    }
  };

  private createInteraction(): void {
    const dragBoxInteraction = new ol.interaction.DragBox({
      condition: ol.events.condition.shiftKeyOnly,
      style: new ol.style.Style({
        stroke: new ol.style.Stroke({
          color: 'yellow',
          width: 2,
        }),
      }),
    });

    this.olmap.getInteractions().extend([dragBoxInteraction]);
  }

  private createLegend(layerIndex: number, isAnalysis: boolean): void {
    if (!this.getUiMapOption().legend.auto) {
      this.legendInfo.enable = false;
      return;
    }

    if (!this.uiOption.legend.showName) {
      this.legendInfo.enable = false;
      return;
    }

    this.legendInfo.position = String(this.getUiMapOption().legend.pos);

    const legendInfo: any = {};

    const layer: UILayers = this.getUiMapOption().layers[layerIndex];

    let dataIndex: number = layerIndex;
    if (isAnalysis && _.isUndefined(this.getUiMapOption().analysis && this.getUiMapOption().analysis['use'] === true)) {
      dataIndex = this.data.length > -1 ? this.data.length : 0;
    }

    legendInfo.name = layer.name;

    if (
      (MapLayerType.SYMBOL === layer.type && (<UISymbolLayer>layer).symbol) ||
      (MapLayerType.CLUSTER === layer.type && (<UISymbolLayer>layer).symbol)
    ) {
      legendInfo.pointType = (<UISymbolLayer>layer).symbol.toString();
    } else {
      legendInfo.pointType = MapSymbolType.CIRCLE.toString();
    }

    legendInfo.color = [];

    const layerType =
      MapLayerType.SYMBOL === layer.type || MapLayerType.CLUSTER === layer.type
        ? 'Point'
        : MapLayerType.TILE === layer.type
        ? 'Hexagon'
        : layer.type.toString();

    legendInfo.type = _.startCase(layerType) + ' Color';

    if (
      (MapLayerType.SYMBOL === layer.type && _.eq((<UISymbolLayer>layer).size.by, MapBy.MEASURE)) ||
      (MapLayerType.CLUSTER === layer.type && _.eq((<UISymbolLayer>layer).size.by, MapBy.MEASURE))
    ) {
      legendInfo.radiusColumn =
        'By ' + ChartUtil.getFieldAlias((<UISymbolLayer>layer).size.column, this.shelf.layers[layerIndex].fields);
    }

    if (_.eq(layer.color.by, MapBy.DIMENSION)) {
      legendInfo.column =
        'By ' +
        ChartUtil.getFieldAlias(layer.color.column, this.shelf.layers[layerIndex].fields, layer.color.aggregationType);

      if (layer.color.ranges) {
        _.each(layer.color.ranges, (range) => {
          const colorInfo: any = {};
          colorInfo.color = range.color;
          colorInfo.column = range['column'];
          legendInfo.color.push(colorInfo);
        });
      } else {
        if (!_.eq(layer.color.column, MapBy.NONE)) {
          const ranges = this.setDimensionColorRange(layer, this.data[dataIndex], this.getColorList(layer), []);
          _.each(ranges, (range) => {
            const colorInfo: any = {};
            colorInfo.color = range.color;
            colorInfo.column = range['column'];
            if (colorInfo.column != 'undefined') {
              legendInfo.color.push(colorInfo);
            }
          });
        } else {
          _.each(this.getUiMapOption().fieldList, (field) => {
            const colorInfo: any = {};
            colorInfo.color = '#602663';
            colorInfo.column = field;
            legendInfo.color.push(colorInfo);
          });
        }
      }
    } else if (_.eq(layer.color.by, MapBy.MEASURE)) {
      legendInfo.column =
        'By ' +
        ChartUtil.getFieldAlias(layer.color.column, this.shelf.layers[layerIndex].fields, layer.color.aggregationType);

      if (layer.color.ranges) {
        _.each(layer.color.ranges, (range, index) => {
          let minVal: number = range.fixMin;
          let maxVal: number = range.fixMax;

          if (minVal === null) minVal = maxVal;
          if (maxVal === null) maxVal = minVal;

          const colorInfo: any = {};
          colorInfo.color = range.color;
          if (index == 0) {
            colorInfo.column = ' ＞ ' + FormatOptionConverter.getFormatValue(minVal, this.getUiMapOption().valueFormat);
          } else if (index == layer.color.ranges.length - 1) {
            colorInfo.column = ' ≤ ' + FormatOptionConverter.getFormatValue(maxVal, this.getUiMapOption().valueFormat);
          } else {
            colorInfo.column =
              FormatOptionConverter.getFormatValue(minVal, this.getUiMapOption().valueFormat) +
              ' ~ ' +
              FormatOptionConverter.getFormatValue(maxVal, this.getUiMapOption().valueFormat);
          }
          legendInfo.color.push(colorInfo);
        });
      } else {
        if (
          this.data[dataIndex].valueRange &&
          this.data[dataIndex].valueRange[
            ChartUtil.getFieldAlias(
              layer.color.column,
              this.shelf.layers[layerIndex].fields,
              layer.color.aggregationType,
            )
          ]
        ) {
          const ranges = ColorOptionConverter.setMapMeasureColorRange(
            this.getUiMapOption(),
            this.data[dataIndex],
            this.getColorList(layer),
            layerIndex,
            this.shelf.layers[layerIndex].fields,
          );

          _.each(ranges, (range, index) => {
            let minVal: number = range.fixMin;
            let maxVal: number = range.fixMax;

            if (minVal === null) minVal = maxVal;
            if (maxVal === null) maxVal = minVal;

            const colorInfo: any = {};
            colorInfo.color = range.color;
            if (index == 0) {
              colorInfo.column =
                ' ＞ ' + FormatOptionConverter.getFormatValue(minVal, this.getUiMapOption().valueFormat);
            } else if (index == ranges.length - 1) {
              colorInfo.column =
                ' ≤ ' + FormatOptionConverter.getFormatValue(maxVal, this.getUiMapOption().valueFormat);
            } else {
              colorInfo.column =
                FormatOptionConverter.getFormatValue(minVal, this.getUiMapOption().valueFormat) +
                ' ~ ' +
                FormatOptionConverter.getFormatValue(maxVal, this.getUiMapOption().valueFormat);
            }
            legendInfo.color.push(colorInfo);
          });
        }
      }
    } else if (_.eq(layer.color.by, MapBy.NONE)) {
      const colorInfo: any = {};
      colorInfo.color = layer.color.schema;

      if (_.eq(layer.type, MapLayerType.HEATMAP)) {
        colorInfo.color = HeatmapColorList[layer.color.schema][HeatmapColorList[layer.color.schema].length - 1];
      }
      _.each(this.shelf.layers[layerIndex].fields, (field: any) => {
        if (
          'user_expr' === field.field.type ||
          (field.field.logicalType && field.field.logicalType.toString().indexOf('GEO') != -1)
        ) {
          colorInfo.column = isUndefined(field.alias) ? field.fieldAlias : field.alias;
          return false;
        }
      });
      legendInfo.color.push(colorInfo);
    } else {
      return;
    }

    this.legendInfo.layer.push(legendInfo);

    this.legendInfo.enable = true;
  }

  private setDimensionColorRange(layer: UILayers, data: any, colorList: any, colorAlterList = []): ColorRange[] {
    const rangeList = [];
    const featureList = [];
    if (data) {
      _.each(data.features, (feature) => {
        featureList.push(feature.properties);
      });
    }

    const featuresGroup = _.groupBy(
      featureList,
      ChartUtil.getFieldAlias(
        layer.color.column,
        this.shelf.layers[this.getUiMapOption().layerNum].fields,
        layer.color.aggregationType,
      ),
    );
    _.each(Object.keys(featuresGroup), (column, index) => {
      const color = colorList[index % colorList.length];
      rangeList.push({ column: column, color: color });
    });

    return rangeList;
  }

  private checkOption(uiOption: UIMapOption): void {
    for (let index = 0; index < this.shelf.layers.length; index++) {
      let isAnalysisUse = false;
      let analysisAggrColumn: string;
      if (
        !_.isUndefined(this.uiOption['analysis']) &&
        !_.isUndefined(this.uiOption['analysis']['use']) &&
        this.uiOption['analysis']['use']
      ) {
        isAnalysisUse = this.uiOption['analysis']['use'];
        analysisAggrColumn = this.uiOption['analysis']['operation']['aggregation']['column'];
        if (index != this.shelf.layers.length - 1) {
          continue;
        }
      }

      const layer: UILayers = uiOption.layers[index];
      const shelf: GeoField[] = _.cloneDeep(this.shelf.layers[index].fields);

      const layerType: MapLayerType = layer.type;

      let field = null;
      _.each(this.shelf.layers[index].fields, (fieldTemp: any) => {
        if (
          fieldTemp != null &&
          fieldTemp.field.logicalType &&
          fieldTemp.field.logicalType.toString().indexOf('GEO') != -1
        ) {
          field = fieldTemp;
          return false;
        }
      });

      if (
        !this.drawByType ||
        String(this.drawByType) == '' ||
        (EventType.CHANGE_PIVOT == this.drawByType && uiOption.layerNum != index) ||
        isNullOrUndefined(field)
      ) {
        continue;
      }

      const geomType = field.field.logicalType.toString();

      if (_.eq(geomType, LogicalType.GEO_LINE) && !_.eq(layerType, MapLayerType.LINE)) {
        const lineLayer: UILineLayer = <UILineLayer>layer;
        lineLayer.type = MapLayerType.LINE;
        lineLayer.thickness = {
          by: MapBy.NONE,
          column: 'NONE',
          maxValue: 10,
        };
      } else if (_.eq(geomType, LogicalType.GEO_POLYGON) && !_.eq(layerType, MapLayerType.POLYGON)) {
        const polygonLayer: UIPolygonLayer = <UIPolygonLayer>layer;
        polygonLayer.type = MapLayerType.POLYGON;
        polygonLayer.outline;
      }

      if (_.eq(layerType, MapLayerType.SYMBOL)) {
        const symbolLayer: UISymbolLayer = <UISymbolLayer>layer;
        if (_.isUndefined(symbolLayer.clustering) || symbolLayer.clustering == null) {
          symbolLayer.clustering = false;
        }
      } else if (_.eq(layerType, MapLayerType.CLUSTER)) {
        const symbolLayer: UISymbolLayer = <UISymbolLayer>layer;
        if (_.isUndefined(symbolLayer.clustering) || symbolLayer.clustering == null) {
          symbolLayer.clustering = true;
        }
      }

      let isNone = true;
      let isDimension = false;
      let isMeasure = false;
      _.each(shelf, (field: any) => {
        if (isAnalysisUse && !_.isUndefined(field['isCustomField']) && field.alias == 'count') {
          isNone = false;
          isDimension = false;
          isMeasure = true;
        } else {
          if (
            'user_expr' === field.field.type ||
            (field.field.logicalType && field.field.logicalType.toString().indexOf('GEO') == -1)
          ) {
            isNone = false;
          }

          if (
            ('user_expr' === field.field.type ||
              (field.field.logicalType && field.field.logicalType.toString().indexOf('GEO') == -1)) &&
            _.eq(field.type, ShelveFieldType.DIMENSION)
          ) {
            isDimension = true;
          }
          if (_.eq(field.type, ShelveFieldType.MEASURE)) {
            isMeasure = true;
          }
        }
      });

      if (layerType == MapLayerType.CLUSTER) {
        isMeasure = true;
        isNone = false;
        isDimension = false;
        if (layer.color.by == MapBy.NONE) {
          isMeasure = false;
          isNone = true;
        }
      }

      this.checkFieldList(shelf, index);

      if (!isAnalysisUse) {
        if (_.isUndefined(layer.color.settingUseFl) || layer.color.settingUseFl == false) {
          layer.color.ranges = undefined;
          layer.color['settingUseFl'] = false;
        }
      }

      if (isNone) {
        layer.color.by = MapBy.NONE;
        if (layerType == MapLayerType.HEATMAP) {
          _.isUndefined(layer.color.heatMapSchema) || layer.color.heatMapSchema.indexOf('HC') == -1
            ? (layer.color.heatMapSchema = 'HC1')
            : layer.color.heatMapSchema;
          layer.color.schema = layer.color.heatMapSchema;
        } else if (layerType == MapLayerType.SYMBOL) {
          _.isUndefined(layer.color.symbolSchema) || layer.color.symbolSchema.indexOf('#') == -1
            ? (layer.color.symbolSchema = '#6344ad')
            : layer.color.symbolSchema;
          layer.color.schema = layer.color.symbolSchema;
        } else if (layerType == MapLayerType.TILE) {
          _.isUndefined(layer.color.tileSchema) || layer.color.tileSchema.indexOf('#') == -1
            ? (layer.color.tileSchema = '#6344ad')
            : layer.color.tileSchema;
          layer.color.schema = layer.color.tileSchema;
        } else if (layerType == MapLayerType.POLYGON) {
          _.isUndefined(layer.color.polygonSchema) || layer.color.polygonSchema.indexOf('#') == -1
            ? (layer.color.polygonSchema = '#6344ad')
            : layer.color.polygonSchema;
          layer.color.schema = layer.color.polygonSchema;
        } else if (layerType == MapLayerType.CLUSTER) {
          _.isUndefined(layer.color.clusterSchema) || layer.color.clusterSchema.indexOf('#') == -1
            ? (layer.color.clusterSchema = '#6344ad')
            : layer.color.clusterSchema;
          layer.color.schema = layer.color.clusterSchema;
        } else {
          layer.color.schema = '#6344ad';
        }
        layer.color.column = null;
        layer.color.aggregationType = null;
        if (!_.isUndefined(layer.noneColor)) {
          layer.color.schema = layer.noneColor;
        }
      } else if (isMeasure) {
        layer.color.by = MapBy.MEASURE;
        if (layerType == MapLayerType.HEATMAP) {
          _.isUndefined(layer.color.heatMapSchema) || layer.color.heatMapSchema.indexOf('HC') == -1
            ? (layer.color.heatMapSchema = 'HC1')
            : layer.color.heatMapSchema;
          layer.color.schema = layer.color.heatMapSchema;
        } else if (layerType == MapLayerType.SYMBOL) {
          _.isUndefined(layer.color.symbolSchema) || layer.color.symbolSchema.indexOf('VC') == -1
            ? (layer.color.symbolSchema = 'VC1')
            : layer.color.symbolSchema;
          layer.color.schema = layer.color.symbolSchema;
        } else if (layerType == MapLayerType.TILE) {
          _.isUndefined(layer.color.tileSchema) || layer.color.tileSchema.indexOf('VC') == -1
            ? (layer.color.tileSchema = 'VC1')
            : layer.color.tileSchema;
          layer.color.schema = layer.color.tileSchema;
        } else if (layerType == MapLayerType.POLYGON) {
          _.isUndefined(layer.color.polygonSchema) || layer.color.polygonSchema.indexOf('VC') == -1
            ? (layer.color.polygonSchema = 'VC1')
            : layer.color.polygonSchema;
          layer.color.schema = layer.color.polygonSchema;
        } else if (layerType == MapLayerType.CLUSTER) {
          _.isUndefined(layer.color.clusterSchema) || layer.color.clusterSchema.indexOf('VC') == -1
            ? (layer.color.clusterSchema = 'VC1')
            : layer.color.clusterSchema;
          layer.color.schema = layer.color.clusterSchema;
        } else {
          layer.color.schema = 'VC1';
        }
        if (_.isUndefined(layer.color.column) || Boolean(layer.color.column.trim())) {
          layer.color.column = uiOption.fieldMeasureList[0]['name'];
        }
        layer.color.aggregationType = uiOption.fieldMeasureList[0]['aggregationType'];
        if (isAnalysisUse) {
          uiOption.fieldMeasureList.forEach((item: any) => {
            if (item.name == analysisAggrColumn) {
              layer.color.column = item.name;
              layer.color.aggregationType = item.aggregationType;
            }
          });
        }
        if (!_.isUndefined(layer.measureColor)) {
          layer.color.schema = layer.measureColor;
        }
        if (isAnalysisUse) {
          let dataIndex = 0;
          this.data.length > 1 ? (dataIndex = this.data.length - 1) : (dataIndex = 0);
          layer.color.ranges = ColorOptionConverter.setMapMeasureColorRange(
            uiOption,
            this.data[dataIndex],
            this.getColorList(layer),
            index,
            shelf,
          );
        } else {
          if (_.isUndefined(layer.color.settingUseFl) || layer.color.settingUseFl == false) {
            layer.color.ranges = ColorOptionConverter.setMapMeasureColorRange(
              uiOption,
              this.data[index],
              this.getColorList(layer),
              index,
              shelf,
            );
          }
        }
      } else if (MapLayerType.TILE === layer.type && isDimension) {
        layer.color.by = MapBy.NONE;
        _.isUndefined(layer.color.tileSchema) || layer.color.tileSchema.indexOf('#') == -1
          ? (layer.color.tileSchema = '#6344ad')
          : layer.color.tileSchema;
        layer.color.column = null;
        layer.color.aggregationType = null;
        if (!_.isUndefined(layer.noneColor)) {
          layer.color.schema = layer.noneColor;
        }
      } else if (isDimension) {
        layer.color.by = MapBy.DIMENSION;
        if (layerType == MapLayerType.SYMBOL) {
          _.isUndefined(layer.color.symbolSchema) || layer.color.symbolSchema.indexOf('SC') == -1
            ? (layer.color.symbolSchema = 'SC1')
            : layer.color.symbolSchema;
          layer.color.schema = layer.color.symbolSchema;
        } else if (layerType == MapLayerType.TILE) {
          _.isUndefined(layer.color.tileSchema) || layer.color.tileSchema.indexOf('SC') == -1
            ? (layer.color.tileSchema = 'SC1')
            : layer.color.tileSchema;
          layer.color.schema = layer.color.tileSchema;
        } else if (layerType == MapLayerType.POLYGON) {
          _.isUndefined(layer.color.polygonSchema) || layer.color.polygonSchema.indexOf('SC') == -1
            ? (layer.color.polygonSchema = 'SC1')
            : layer.color.polygonSchema;
          layer.color.schema = layer.color.polygonSchema;
        } else if (layerType == MapLayerType.CLUSTER) {
          _.isUndefined(layer.color.clusterSchema) || layer.color.clusterSchema.indexOf('SC') == -1
            ? (layer.color.clusterSchema = 'SC1')
            : layer.color.clusterSchema;
          layer.color.schema = layer.color.clusterSchema;
        } else {
          layer.color.schema = 'SC1';
        }
        layer.color.column = uiOption.fielDimensionList[0]['name'];
        layer.color.aggregationType = null;
        if (!_.isUndefined(layer.dimensionColor)) {
          layer.color.schema = layer.dimensionColor;
        }
        if (uiOption.fielDimensionList[0]['format'])
          layer.color.granularity = uiOption.fielDimensionList[0]['format']['unit'].toString();
      }

      if (_.eq(layer.type, MapLayerType.SYMBOL) || layerType == MapLayerType.CLUSTER) {
        const symbolLayer: UISymbolLayer = <UISymbolLayer>layer;

        if (isNone || !isMeasure) {
          symbolLayer.size.by = MapBy.NONE;
        } else if (isMeasure) {
          symbolLayer.size.by = MapBy.MEASURE;
          if (Boolean(symbolLayer.size.column.trim()) || symbolLayer.size.column === 'NONE') {
            symbolLayer.size.column = uiOption.fieldMeasureList[0]['name'];
          }
        }
      } else if (_.eq(layer.type, MapLayerType.HEATMAP)) {
        if (!uiOption.fieldMeasureList || uiOption.fieldMeasureList.length === 0) {
          this.uiOption.legend.auto = false;
          this.uiOption.legend.showName = false;
        } else {
          this.uiOption.legend.auto = true;
        }
      } else if (_.eq(layer.type, MapLayerType.TILE)) {
        const hexagonLayer: UITileLayer = <UITileLayer>layer;
      } else if (_.eq(layer.type, MapLayerType.LINE) || _.eq(layer.type, MapLayerType.MULTILINESTRING)) {
        const lineLayer: UILineLayer = <UILineLayer>layer;

        if (isNone || !isMeasure) {
          lineLayer.thickness.by = MapBy.NONE;
        } else if (isMeasure) {
          lineLayer.thickness.by = MapBy.MEASURE;
          lineLayer.thickness.column = this.uiOption.fieldMeasureList[0]['name'];
        }
      } else if (_.eq(layer.type, MapLayerType.POLYGON)) {
      }

      if (!uiOption.toolTip.displayColumns) uiOption.toolTip.displayColumns = [];
      const itemsForTooltip = TooltipOptionConverter.returnTooltipDataValue(shelf);
      if (!isAnalysisUse) {
        const fieldsForTooltip = ChartUtil.returnNameFromField(itemsForTooltip);
        fieldsForTooltip.forEach((field) => {
          this.uiOption.toolTip.displayColumns.push(field);
        });
      } else {
        this.uiOption.toolTip.displayColumns = ChartUtil.returnNameFromField(itemsForTooltip);
      }
    }
  }

  private hexToRgbA(hex, alpha): string {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    } else {
      return 'rgba(255,255,255,1)';
    }
  }

  private zoomFunction = (event) => {
    const that = this;

    this.uiOption.chartZooms = this.additionalSaveDataZoomRange();

    const mapUIOption = <UIMapOption>this.uiOption;

    mapUIOption.zoomSize = Math.round(event.frameState.viewState.zoom);

    if (
      (_.isUndefined(mapUIOption.lowerCorner) && _.isUndefined(mapUIOption.upperCorner)) ||
      that.preZoomSize == 0 ||
      that.isResize
    ) {
      this.preZoomSize = mapUIOption.zoomSize;
      this.setUiExtent(event);
      this.isResize = false;
      return;
    }

    const preLowerCorner = mapUIOption.lowerCorner.split(' ');
    const preUpperCorner = mapUIOption.upperCorner.split(' ');
    const currentMapExtent = this.olmap.getView().calculateExtent(event.map.getSize());

    if (
      (Number(preLowerCorner[0]).toFixed(10) != currentMapExtent[0].toFixed(10) &&
        Number(preLowerCorner[1]).toFixed(10) != currentMapExtent[1].toFixed(10) &&
        Number(preUpperCorner[0]).toFixed(10) != currentMapExtent[2].toFixed(10) &&
        Number(preUpperCorner[1]).toFixed(10) != currentMapExtent[3].toFixed(10)) ||
      that.preZoomSize != mapUIOption.zoomSize
    ) {
      let isAllChangeCoverage = false;
      mapUIOption.layers.forEach((layer) => {
        if (!_.isUndefined(layer['changeCoverage']) && layer['changeCoverage'] == true) {
          isAllChangeCoverage = true;
        }
      });

      this.setUiExtent(event);
      if (
        mapUIOption.upperCorner.indexOf('NaN') != -1 ||
        mapUIOption.lowerCorner.indexOf('NaN') != -1 ||
        isAllChangeCoverage
      ) {
        mapUIOption.layers.forEach((layer) => {
          if (isAllChangeCoverage && !_.isUndefined(layer['changeCoverage'])) {
            layer['changeCoverage'] = false;
          }
        });
        return;
      }

      this.changeDrawEvent.emit();
    }

    if (!this.isPage) {
    }
  };

  private additionalSaveDataZoomRange(): UIChartZoom[] {
    const resultList: UIChartZoom[] = [];

    const center = this.olmap.getView().getCenter();

    resultList.push({ startValue: center[0], endValue: center[1], count: this.olmap.getView().getZoom() });

    return resultList;
  }

  private getMinZoom() {
    const width = this.elementRef.nativeElement.clientWidth;

    return Math.ceil(Math.LOG2E * Math.log(width / 256));
  }

  private getColorList(layer: UILayers): any[] {
    let colorList = [];
    if (_.eq(layer.type, MapLayerType.HEATMAP)) {
      colorList = HeatmapColorList[layer.color.schema];
    } else {
      colorList = ChartColorList[layer.color.schema];
    }
    return _.cloneDeep(colorList);
  }

  private mapSelectionListener = (event) => {
    const scope: any = this;

    let selectMode: ChartSelectMode;

    const selectData = [];

    let noneDataCnt = true;

    let layerNum = 0;

    let feature = this.olmap.forEachFeatureAtPixel(event.pixel, (feature) => {
      return feature;
    });

    if (feature) {
      const features = feature.get('features');
      if (!isNullOrUndefined(features)) {
        if (features.length > 1) {
          return;
        }
        feature = features[0];
      }

      layerNum = feature.getProperties()['layerNum'];

      if (feature.getProperties()['selection']) {
        selectMode = ChartSelectMode.SUBTRACT;
      } else {
        selectMode = ChartSelectMode.ADD;
      }

      const dimensionLayer = this.originShelf.layers[(<UIMapOption>this.uiOption).layerNum].fields.filter(
        (item: any) => {
          if (
            'dimension' === item.type &&
            ('user_expr' == item.field.type ||
              (item.field.logicalType && -1 == item.field.logicalType.toString().indexOf('GEO')))
          ) {
            return item;
          }
        },
      );

      const properties = _.cloneDeep(feature.getProperties());
      delete properties['geometry'];
      delete properties['layerNum'];

      for (const item of dimensionLayer) {
        for (const key in properties) {
          const alias = ChartUtil.getAlias(item);
          if (alias === key) {
            const dataValue = properties[key];

            if (ChartSelectMode.ADD === selectMode) {
              feature.set('selection', selectMode);

              if (item['data'] && -1 !== item['data'].indexOf(dataValue)) {
                item['dataCnt'][dataValue] = ++item['dataCnt'][dataValue];
              } else {
                if (!item['dataCnt']) item['dataCnt'] = {};
                item['dataCnt'][dataValue] = 1;
              }

              item['data'] = [dataValue];

              selectData.push(_.cloneDeep(item));
            } else {
              feature.unset('selection');

              item['dataCnt'][dataValue]--;

              if (0 == item['dataCnt'][dataValue]) {
                item['data'] = [dataValue];
                selectData.push(_.cloneDeep(item));
              }
            }
          }
        }
      }

      for (const item of selectData) {
        if (item['dataCnt']) {
          for (const key in item['dataCnt']) {
            if (0 < item['dataCnt'][key]) {
              noneDataCnt = false;
              break;
            }
          }
        }
      }
    } else {
      selectMode = ChartSelectMode.CLEAR;
    }

    if (ChartSelectMode.CLEAR === selectMode || (selectData && selectData.length > 0)) {
      this.chartSelectInfo.emit(createChartSelectInfo(selectMode, selectData, this.params));
    }

    if (ChartSelectMode.CLEAR === selectMode || (ChartSelectMode.SUBTRACT === selectMode && noneDataCnt)) {
      selectMode = undefined;
    }
  };

  private setFeatureSelectionMode(scope: any, feature): boolean {
    let filterFl = false;

    if (
      scope.widgetDrawParam &&
      scope.widgetDrawParam.selectFilterListList &&
      scope.widgetDrawParam.selectFilterListList.length > 0
    ) {
      _.each(scope.widgetDrawParam.selectFilterListList, (filter) => {
        _.each(filter.data, (data) => {
          const properties = feature.getProperties();

          if (properties[filter.alias] === data) {
            feature.set('selection', ChartSelectMode.ADD);
          }
        });
      });

      filterFl = true;
    }

    return filterFl;
  }

  private setMinMax() {
    if (this.shelf.layers.length == 0) {
      return;
    }

    for (let idx = 0; idx < this.shelf.layers.length; idx++) {
      const uiOption = this.getUiMapOption();
      const layer: UILayers = uiOption.layers[idx];
      const shelf: GeoField[] = _.cloneDeep(this.shelf.layers[idx].fields);

      let isAnalysisUse = false;
      if (
        !_.isUndefined(uiOption['analysis']) &&
        !_.isUndefined(uiOption['analysis']['use']) &&
        uiOption['analysis']['use']
      ) {
        isAnalysisUse = true;
      }

      let valueRange;
      if (isAnalysisUse) {
        if (idx != this.shelf.layers.length - 1) {
          continue;
        }

        let alias;
        if (_.isUndefined(layer.color.aggregationType)) {
          alias = layer.color.column;
        } else {
          alias = layer.color.aggregationType + '(' + layer.color.column + ')';
        }

        valueRange = _.cloneDeep(this.data[uiOption.analysis['layerNum']]['valueRange'][alias]);

        if (valueRange) {
          layer.color.minValue = valueRange.minValue;
          layer.color.maxValue = valueRange.maxValue;
        }
      } else {
        let alias = ChartUtil.getFieldAlias(layer.color.column, shelf, layer.color.aggregationType);

        if (layer.type == MapLayerType.CLUSTER && layer['clustering']) {
          alias = 'count';
        }

        if (_.isUndefined(this.data[idx])) {
          continue;
        }
        valueRange = _.cloneDeep(this.data[idx]['valueRange'][alias]);

        if (valueRange) {
          if (this.drawByType == EventType.MAP_CHANGE_OPTION || this.drawByType == EventType.CHANGE_PIVOT) {
            layer.color.minValue = valueRange.minValue;
            layer.color.maxValue = valueRange.maxValue;
          } else {
            _.isUndefined(layer.color.minValue) || layer.color.minValue > valueRange.minValue
              ? (layer.color.minValue = valueRange.minValue)
              : layer.color.minValue;
            _.isUndefined(layer.color.maxValue) || layer.color.maxValue < valueRange.maxValue
              ? (layer.color.maxValue = valueRange.maxValue)
              : layer.color.maxValue;
          }
        }
      }

      if (
        layer.type == MapLayerType.CLUSTER &&
        layer['clustering'] &&
        !_.isUndefined(layer.color.ranges) &&
        (_.isUndefined(layer.color.changeRange) || layer.color.changeRange) &&
        !isAnalysisUse
      ) {
        const colorList = this.getColorList(layer);
        const rangeList = uiOption.layers[idx].color.ranges;

        rangeList.reverse().forEach((item, index) => {
          colorList[index] = item.color;
        });
        layer.color.ranges = ColorOptionConverter.setMapMeasureColorRange(
          this.getUiMapOption(),
          this.data[idx],
          colorList,
          idx,
          shelf,
          rangeList,
        );
      }

      _.each(shelf, (field) => {
        if (_.eq(field.type, ShelveFieldType.MEASURE)) {
          if (
            !_.isUndefined(layer.color.ranges) &&
            _.eq(field.name, layer.color.column) &&
            (_.isUndefined(layer.color.changeRange) || layer.color.changeRange)
          ) {
            const colorList = this.getColorList(layer);
            const rangeList = uiOption.layers[idx].color.ranges;

            rangeList.reverse().forEach((item, index) => {
              colorList[index] = item.color;
            });
            if (isAnalysisUse) {
              if (
                !_.isUndefined(uiOption.analysis['includeCompareLayer']) &&
                uiOption.analysis['includeCompareLayer'] == true
              ) {
                uiOption['layers'][uiOption['layerNum']]['isColorOptionChanged'] = true;
                layer.color.ranges = ColorOptionConverter.setMapMeasureColorRange(
                  this.getUiMapOption(),
                  this.data[uiOption.analysis['layerNum'] + 1],
                  colorList,
                  idx,
                  shelf,
                  rangeList,
                );
              } else {
                layer.color.ranges = ColorOptionConverter.setMapMeasureColorRange(
                  this.getUiMapOption(),
                  this.data[uiOption.analysis['layerNum']],
                  colorList,
                  idx,
                  shelf,
                  rangeList,
                );
              }
            } else {
              layer.color.ranges = ColorOptionConverter.setMapMeasureColorRange(
                this.getUiMapOption(),
                this.data[idx],
                colorList,
                idx,
                shelf,
                rangeList,
              );
            }
          }
        }
      });
    }
  }

  private checkFieldList(shelf: any, layerIndex: number) {
    const getShelveReturnField = (shelf: any, typeList: ShelveFieldType[]): any[] => {
      const uiOption = this.uiOption;
      let analysisCountAlias: string;
      if (
        !_.isUndefined(uiOption['analysis']) &&
        !_.isUndefined(uiOption['analysis']['use']) &&
        uiOption['analysis']['use']
      ) {
        if (
          !_.isUndefined(uiOption['analysis']['operation']['aggregation']) &&
          !_.isUndefined(uiOption['analysis']['operation']['aggregation']['column']) &&
          uiOption['analysis']['operation']['aggregation']['column'] == 'count'
        ) {
          analysisCountAlias = uiOption['analysis']['operation']['aggregation']['column'];
        }
      }

      const resultList: any[] = [];
      shelf.map((item) => {
        if (!_.isUndefined(analysisCountAlias) && analysisCountAlias == item.alias) {
          resultList.push(item);
        } else {
          if (
            (_.eq(item.type, typeList[0]) || _.eq(item.type, typeList[1])) &&
            item.field &&
            ('user_expr' === item.field.type || (item.field.logicalType && -1 == item.field.logicalType.indexOf('GEO')))
          ) {
            resultList.push(item);
          }
        }
      });
      return resultList;
    };

    const layerOption = this.getUiMapOption().layers[layerIndex];

    if (layerOption.type.toString() == 'cluster' && layerOption['clustering']) {
      const defaultObject: any = {
        aggregationType: null,
        alias: 'count',
        type: 'measure',
        subRole: 'measure',
        name: 'count',
        isCustomField: true,
        field: {
          role: FieldRole.MEASURE,
          logicalType: LogicalType.INTEGER,
        },
      };
      const tempList: any[] = [];
      tempList.push(defaultObject);
      this.uiOption.fieldMeasureList = tempList;
    } else {
      this.uiOption.fieldMeasureList = getShelveReturnField(shelf, [
        ShelveFieldType.MEASURE,
        ShelveFieldType.CALCULATED,
      ]);

      this.uiOption.fielDimensionList = getShelveReturnField(shelf, [
        ShelveFieldType.DIMENSION,
        ShelveFieldType.TIMESTAMP,
      ]);
    }
  }

  private removeLayer(layerNumber: number) {
    if (this.getUiMapOption().layers.length < layerNumber + 1) {
      return;
    }
    this.layerMap.forEach((item, index) => {
      if (layerNumber == index) {
        this.olmap.removeLayer(item.layerValue);
      }
    });
    this.layerMap.splice(layerNumber, 1);
  }

  private setUiExtent(event) {
    const mapUIOption = <UIMapOption>this.uiOption;
    if (event) {
      const map = event.map;
      let mapExtent = map.getView().calculateExtent(map.getSize());

      mapExtent = new ol.proj.transformExtent(mapExtent, new ol.proj.get('EPSG:4326'), new ol.proj.get('EPSG:3857'));

      const bottomLeft = new ol.proj.toLonLat(new ol.extent.getBottomLeft(mapExtent));
      const topRight = new ol.proj.toLonLat(new ol.extent.getTopRight(mapExtent));

      mapUIOption.upperCorner = this.wrapLon(topRight[0]) + ' ' + topRight[1];

      mapUIOption.lowerCorner = this.wrapLon(bottomLeft[0]) + ' ' + bottomLeft[1];
    }
  }

  private wrapLon(value) {
    const lon = Math.floor((value + 180) / 360);
    return value - lon * 360;
  }

  private isGeoFieldCheck(layers: any, index): boolean {
    let valid = false;

    const fields: DatasourceField[] = layers[index].fields;

    if (fields) {
      for (const layer of fields) {
        // @ts-ignore
        if (layer.field && layer.field.logicalType && -1 !== layer.field.logicalType.toString().indexOf('GEO')) {
          valid = true;
        }
      }
    }
    return valid;
  }

  private drawAnalysis() {
    this.checkOption(this.getUiMapOption());

    this.setMinMax();

    const isMapCreation: boolean = this.createMap();

    this.legendInfo.layer = [];

    for (let dataIndex = 0; dataIndex < this.data.length; dataIndex++) {
      const dataType =
        _.isUndefined(this.data[dataIndex]['features']) || this.data[dataIndex]['features'].length <= 0
          ? null
          : this.data[dataIndex]['features'][0]['geometry']['type'].toString().toLowerCase();

      if (dataType == 'polygon' && this.getUiMapOption().analysis.includeCompareLayer == true && dataIndex == 0) {
        this.includeCompareLayer(dataIndex, isMapCreation);
      } else {
        const source = new ol.source.Vector({ crossOrigin: 'anonymous' });

        this.createAnalysisFeature(source, dataIndex);

        this.createAnalysisLayer(source, isMapCreation, dataIndex);
      }
    }

    this.createMapOverLayEvent();

    this.createLegend(this.getUiMapOption().layerNum, true);

    if (this.drawByType != null || !_.isEmpty(this.drawByType)) this.olmap.updateSize();

    this.drawFinished.emit();
  }

  private createAnalysisFeature(source, dataIndex): void {
    const data = this.data[dataIndex];

    const features = [];

    let isChangedType = false;

    for (let i = 0; i < data.features.length; i++) {
      if (!isChangedType) {
        const geometryType = data.features[i].geometry.type.toString().toLowerCase();
        if (geometryType == 'point') {
          if (
            !_.isUndefined(this.getUiMapOption().layers[this.getUiMapOption().layerNum]['clustering']) &&
            this.getUiMapOption().layers[this.getUiMapOption().layerNum]['clustering']
          ) {
            this.getUiMapOption().layers[this.getUiMapOption().layerNum].type = MapLayerType.CLUSTER;
          } else {
            this.getUiMapOption().layers[this.getUiMapOption().layerNum].type = MapLayerType.SYMBOL;
          }
        } else if (geometryType == 'multipolygon') {
          this.getUiMapOption().layers[this.getUiMapOption().layerNum].type = MapLayerType.POLYGON;
        } else {
          this.getUiMapOption().layers[this.getUiMapOption().layerNum].type = geometryType;
        }
        isChangedType = true;
      }

      if (data.features[i].geometry.type.toString().toLowerCase().indexOf('point') != -1) {
        const pointFeature = new ol.format.GeoJSON().readFeature(data.features[i]);
        pointFeature.set('layerNum', this.getUiMapOption().layerNum);
        pointFeature.set('isClustering', this.getUiMapOption().layers[this.getUiMapOption().layerNum]['clustering']);
        features[i] = pointFeature;
        source.addFeature(features[i]);
      } else if (
        data.features[i].geometry.type.toString().toLowerCase().indexOf('polygon') != -1 ||
        data.features[i].geometry.type.toString().toLowerCase().indexOf('multipolygon') != -1
      ) {
        const polygonFeature = new ol.format.GeoJSON().readFeature(data.features[i]);
        polygonFeature.set('layerNum', this.getUiMapOption().layerNum);
        features[i] = polygonFeature;
        source.addFeature(features[i]);
      } else if (
        data != null &&
        data.features[i] != null &&
        data.features[i].geometry != null &&
        data.features[i].geometry.type.toString().toLowerCase().indexOf('line') != -1
      ) {
        let line;
        if (data.features[i].geometry.type.toString().toLowerCase().indexOf('multi') != -1) {
          line = new ol.geom.MultiLineString(data.features[i].geometry.coordinates);
        } else {
          line = new ol.geom.LineString(data.features[i].geometry.coordinates);
        }
        const lineFeature = new ol.Feature({ geometry: line });
        if (!_.isNull(this.getUiMapOption().layers[this.getUiMapOption().layerNum].color.column)) {
          const alias = ChartUtil.getFieldAlias(
            this.getUiMapOption().layers[this.getUiMapOption().layerNum].color.column,
            this.shelf.layers[this.getUiMapOption().layerNum].fields,
            this.getUiMapOption().layers[this.getUiMapOption().layerNum].color.aggregationType,
          );
          lineFeature.set(alias, data.features[i].properties[alias]);
        }
        lineFeature.set('layerNum', this.getUiMapOption().layerNum);
        features.push(lineFeature);
        source.addFeature(lineFeature);
      }
    }
  }

  private createAnalysisLayer(source: any, isMapCreation: boolean, dataIndex: number): void {
    const layer: UILayers = this.getUiMapOption().layers[this.getUiMapOption().layerNum];

    if (_.eq(layer.type, MapLayerType.SYMBOL) || _.eq(layer.type, MapLayerType.CLUSTER)) {
      const symbolLayer = new ol.layer.Vector({
        source: source,
        style: this.pointStyleFunction(this.getUiMapOption().layerNum, this.data, null, dataIndex),
      });
      symbolLayer.setZIndex(4);
      this.layerMap.push({ id: this.getUiMapOption().layerNum, layerValue: symbolLayer });

      if (isMapCreation && this.getUiMapOption().showMapLayer) {
        this.olmap.addLayer(symbolLayer);
      } else {
        if (this.getUiMapOption().showMapLayer) {
          this.olmap.addLayer(symbolLayer);

          symbolLayer.setStyle(this.pointStyleFunction(this.getUiMapOption().layerNum, this.data, null, dataIndex));
        } else {
          this.olmap.removeLayer(symbolLayer);
        }
      }
    } else if (
      _.eq(layer.type, MapLayerType.LINE) ||
      _.eq(layer.type, MapLayerType.MULTILINESTRING) ||
      _.eq(layer.type, MapLayerType.POLYGON) ||
      _.eq(layer.type, MapLayerType.MULTIPOLYGON)
    ) {
      const symbolLayer = new ol.layer.Vector({
        source: source,
        style: this.mapStyleFunction(this.getUiMapOption().layerNum, this.data, null, dataIndex),
      });
      symbolLayer.setZIndex(3);
      this.layerMap.push({ id: this.getUiMapOption().layerNum, layerValue: symbolLayer });

      if (isMapCreation && this.getUiMapOption().showMapLayer) {
        this.olmap.addLayer(symbolLayer);
      } else {
        if (this.getUiMapOption().showMapLayer) {
          this.olmap.addLayer(symbolLayer);
        } else {
          this.olmap.removeLayer(symbolLayer);
        }
      }
    } else if (_.eq(layer.type, MapLayerType.HEATMAP)) {
      const getHeatMapLayerValue: UIHeatmapLayer = <UIHeatmapLayer>layer;

      const heatmapLayer = new ol.layer.Heatmap({
        source: source,

        gradient: HeatmapColorList[getHeatMapLayerValue.color.schema],
        opacity: 1 - getHeatMapLayerValue.color.transparency * 0.01,
        radius: getHeatMapLayerValue.radius,
        blur: getHeatMapLayerValue.blur * 0.7,
      });
      heatmapLayer.setZIndex(0);
      this.layerMap.push({ id: this.getUiMapOption().layerNum, layerValue: heatmapLayer });

      if (isMapCreation && this.getUiMapOption().showMapLayer) {
        this.olmap.addLayer(heatmapLayer);
      } else {
        if (this.getUiMapOption().showMapLayer) {
          this.olmap.addLayer(heatmapLayer);

          if (isUndefined(HeatmapColorList[getHeatMapLayerValue.color.schema])) {
            heatmapLayer.setGradient(HeatmapColorList['HC1']);
          } else {
            heatmapLayer.setGradient(HeatmapColorList[getHeatMapLayerValue.color.schema]);
          }
          heatmapLayer.setOpacity(1 - getHeatMapLayerValue.color.transparency * 0.01);
          heatmapLayer.setRadius(getHeatMapLayerValue.radius);
          heatmapLayer.setBlur(getHeatMapLayerValue.blur * 0.7);
        } else {
          this.olmap.removeLayer(heatmapLayer);
        }
      }
    } else if (_.eq(layer.type, MapLayerType.TILE)) {
      const hexagonLayer = new ol.layer.Vector({
        source: source,
        style: this.hexagonStyleFunction(this.getUiMapOption().layerNum, this.data, null, dataIndex),
      });
      hexagonLayer.setZIndex(1);
      this.layerMap.push({ id: this.getUiMapOption().layerNum, layerValue: hexagonLayer });

      if (isMapCreation && this.getUiMapOption().showMapLayer) {
        this.olmap.addLayer(hexagonLayer);
      } else {
        if (this.getUiMapOption().showMapLayer) {
          this.olmap.addLayer(hexagonLayer);
        } else {
          this.olmap.removeLayer(hexagonLayer);
        }
      }
    }
    this.cdr.detectChanges();

    if (
      this.drawByType == EventType.CHANGE_PIVOT &&
      'Infinity'.indexOf(source.getExtent()[0]) == -1 &&
      (_.isUndefined(this.uiOption['layers'][this.getUiMapOption().layerNum]['changeCoverage']) ||
        this.uiOption['layers'][this.getUiMapOption().layerNum]['changeCoverage'])
    ) {
      this.olmap.getView().fit(source.getExtent());
    } else {
      if (this.uiOption.chartZooms && this.uiOption.chartZooms.length > 0) {
        this.olmap.getView().setCenter([this.uiOption.chartZooms[0].startValue, this.uiOption.chartZooms[0].endValue]);
        this.olmap.getView().setZoom(this.uiOption.chartZooms[0].count);
      }
    }
  }

  private includeCompareLayer(dataIndex, isMapCreation) {
    const source = new ol.source.Vector({ crossOrigin: 'anonymous' });

    const data = this.data[dataIndex];

    const features = [];

    for (let i = 0; i < data.features.length; i++) {
      const polygonFeature = new ol.format.GeoJSON().readFeature(data.features[i]);
      polygonFeature.set('layerNum', -5);
      features[i] = polygonFeature;
      source.addFeature(features[i]);
    }

    const style = new ol.style.Style({
      stroke: new ol.style.Stroke({
        color: 2,
        lineDash: [3, 3],
      }),
      fill: new ol.style.Fill({
        color: 'rgba(255,0,255,0.1)',
      }),
    });

    const symbolLayer = new ol.layer.Vector({
      source: source,
      style: style,
    });
    symbolLayer.setZIndex(3);
    this.layerMap.push({ id: this.getUiMapOption().layerNum, layerValue: symbolLayer });

    if (isMapCreation && this.getUiMapOption().showMapLayer) {
      this.olmap.addLayer(symbolLayer);
    } else {
      if (this.getUiMapOption().showMapLayer) {
        this.olmap.addLayer(symbolLayer);
      } else {
        this.olmap.removeLayer(symbolLayer);
      }
    }
  }

  changePointSize() {
    let uiLayerIndex = 0;
    for (
      let uiOptionForLoopLayerIndex = 0;
      this.getUiMapOption().layers.length > uiOptionForLoopLayerIndex;
      uiOptionForLoopLayerIndex++
    ) {
      if (this.getUiMapOption().layers[uiOptionForLoopLayerIndex]['isChangePointRadius'] == true) {
        uiLayerIndex = uiOptionForLoopLayerIndex;
        delete this.uiOption['layers'][uiOptionForLoopLayerIndex]['isChangePointRadius'];
      }
    }

    if (!_.isUndefined(this.getUiMapOption().analysis) && this.getUiMapOption().analysis['use'] === true) {
      uiLayerIndex = this.getUiMapOption().analysis.layerNum;
    }
    for (
      let olmapForLoopLayerIndex = 0;
      this.olmap.getLayers().getArray().length > olmapForLoopLayerIndex;
      olmapForLoopLayerIndex++
    ) {
      if (this.layerMap[uiLayerIndex].layerValue == this.olmap.getLayers().getArray()[olmapForLoopLayerIndex]) {
        this.olmap
          .getLayers()
          .getArray()
          [olmapForLoopLayerIndex].getSource()
          .getFeatures()
          .forEach((feature) => {
            if (!_.isUndefined(this.getUiMapOption().analysis) && this.getUiMapOption().analysis['use'] === true) {
              feature.setStyle(
                this.pointStyleFunction(
                  this.getUiMapOption().layerNum,
                  this.data,
                  null,
                  this.getUiMapOption().analysis['layerNum'],
                ),
              );
            } else {
              feature.setStyle(this.pointStyleFunction(uiLayerIndex, this.data));
            }
          });
        this.layerMap[uiLayerIndex].layerValue = this.olmap.getLayers().getArray()[olmapForLoopLayerIndex];
      }
    }
    this.cdr.detectChanges();
  }
}
