import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import {
  AggregatorRouteApiModel,
  AggregatorRouteFormField,
  AggregatorRouteFormGroup,
  FilterCondition,
  StepApiModel,
  StepFilterApiModel,
  StepType,
} from '@selfai-platform/pipeline-common';
import { AlertService, DestroyService, LoadingService } from '@selfai-platform/shared';
import { BehaviorSubject, EMPTY, Observable, map } from 'rxjs';
import { catchError, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { handleHttpErrorResponse } from '../../utils';
import { AggregatorProcess } from '../models';
import { LOADING_ROUTES_LOAD, LOADING_ROUTES_SAVE } from '../tokens';
import { mapFormToNode } from '../utils';
import { AggregatorFormBuilderService } from './aggregator-form-builder.service';
import { AggregatorAdapter } from './api';

@Injectable()
export class AggregatorRouteListService {
  formGroupRoutes = this.getInitFormGroup();
  formValue$ = this.formGroupRoutes.valueChanges.pipe(startWith(this.formGroupRoutes.value));
  isSaved$ = new BehaviorSubject(true);
  process: AggregatorProcess = {} as AggregatorProcess;

  constructor(
    private readonly destroy$: DestroyService,
    private readonly aggregatorAdapter: AggregatorAdapter,
    private readonly alertService: AlertService,
    private readonly aggregatorFormBuilderService: AggregatorFormBuilderService,
    @Inject(LOADING_ROUTES_LOAD) private readonly loadingRoutesLoad: LoadingService,
    @Inject(LOADING_ROUTES_SAVE) private readonly loadingRoutesSave: LoadingService,
  ) {
    this.loadingRoutesSave
      .getState()
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ loading, success, error }) => {
        if (loading) {
          this.alertService.info("Routes are still saving. You can't edit any routes", { sticky: true });
        } else {
          this.alertService.clear();
        }

        if (success) {
          this.alertService.success('Routes have been saved successfully');
        }

        if (error) {
          this.alertService.error('Routes have not been saved');
        }
      });
  }

  loadRouteList(processUuid?: string): Observable<void> {
    this.loadingRoutesLoad.setLoading();
    this.clearRoutes();

    return this.aggregatorAdapter.loadProcessWithRoutes(processUuid as string).pipe(
      map(({ routes, process }) => {
        this.process = process;
        return routes.map(this.fillRequiredFields.bind(this));
      }),
      map((routes) => routes.forEach((route: AggregatorRouteApiModel) => this.initRoute(route))),
      tap(() => {
        this.loadingRoutesLoad.setSuccess();
      }),
      catchError((error: HttpErrorResponse) => {
        this.loadingRoutesLoad.setError(handleHttpErrorResponse(error));
        this.clearRoutes();

        return EMPTY;
      }),
    );
  }

  saveRoutes(): Observable<void> {
    this.loadingRoutesSave.setLoading();
    const routes: AggregatorRouteFormField[] = this.getRoutesData();
    const normalizedRoutes: AggregatorRouteApiModel[] = routes.map(this.normalizeRoute.bind(this));

    return this.aggregatorAdapter.saveRoutes({ routes: normalizedRoutes, process: this.process }).pipe(
      take(1),
      tap(() => {
        this.isSaved$.next(true);
        this.loadingRoutesSave.setSuccess();
      }),
      map(() => void 0),
      takeUntil(this.destroy$),
      catchError((error: HttpErrorResponse) => {
        this.loadingRoutesSave.setError(handleHttpErrorResponse(error));

        return EMPTY;
      }),
    );
  }

  getFormGroupRoutes(): FormGroup<AggregatorRouteFormGroup>[] {
    return Object.values(this.formGroupRoutes.controls);
  }

  addRoute(route: Omit<AggregatorRouteApiModel, 'uuid'>): string {
    const uuid = uuidv4();
    this.isSaved$.next(false);

    this.formGroupRoutes.addControl(
      uuid,
      this.aggregatorFormBuilderService.buildAggregatorItemForm({ ...route, uuid }),
    );

    return uuid;
  }

  initRoute(route: AggregatorRouteApiModel): void {
    this.formGroupRoutes.setControl(route.uuid, this.aggregatorFormBuilderService.buildAggregatorItemForm(route));
  }

  removeRoute(uuid: string): void {
    (this.formGroupRoutes as UntypedFormGroup).removeControl(uuid);
    this.isSaved$.next(false);
  }

  clearRoutes(): void {
    const form = this.formGroupRoutes as UntypedFormGroup;
    Object.keys(form.controls).forEach((controlName) => {
      form.removeControl(controlName, { emitEvent: false });
    });
  }

  getRoutesData(): AggregatorRouteFormField[] {
    return Object.values(this.formGroupRoutes.getRawValue()) as AggregatorRouteFormField[];
  }

  getRoutes(): Observable<AggregatorRouteFormField[]> {
    return this.formGroupRoutes.valueChanges.pipe(
      startWith(this.formGroupRoutes.value),
      map((aggregatorRoutes) => Object.values(aggregatorRoutes) as AggregatorRouteFormField[]),
    );
  }

  private getInitFormGroup(): FormGroup<{ [uuid: string]: FormGroup<AggregatorRouteFormGroup> }> {
    return new FormGroup<{ [uuid: string]: FormGroup<AggregatorRouteFormGroup> }>({});
  }

  private fillRequiredFields(route: AggregatorRouteApiModel): AggregatorRouteApiModel {
    return {
      ...route,
      input: Boolean(route.input),
      isActive: typeof route.isActive === 'undefined' ? true : Boolean(route.isActive),
    };
  }

  private normalizeRoute(route: AggregatorRouteFormField): AggregatorRouteApiModel {
    return { ...route, steps: route.steps.map((step) => this.normalizeStep(mapFormToNode(step))) };
  }

  private normalizeStep(step: StepApiModel): StepApiModel {
    if (step.type === StepType.FILTER) {
      const { steps, filterIgnoreCase, filterStartPosition, ...fields } = step;

      // clean unnecessary fields

      if (this.isBeginFromCondition(step)) {
        return {
          ...fields,
          filterStartPosition,
          steps: steps?.map(this.normalizeStep.bind(this)),
        } as StepApiModel;
      }

      if (this.isEqualToCondition(step)) {
        return {
          ...fields,
          filterIgnoreCase,
          steps: steps?.map(this.normalizeStep.bind(this)),
        } as StepApiModel;
      }

      return {
        ...fields,
        steps: steps?.map(this.normalizeStep.bind(this)),
      } as StepApiModel;
    }

    return step;
  }

  private isBeginFromCondition(step: StepApiModel): step is StepFilterApiModel {
    const { filterCondition } = step as StepFilterApiModel;

    return filterCondition === FilterCondition.BEGINS_FROM || filterCondition === FilterCondition.NOT_BEGINS_FROM;
  }

  private isEqualToCondition(step: StepApiModel): step is StepFilterApiModel {
    const { filterCondition } = step as StepFilterApiModel;

    return filterCondition === FilterCondition.EQUALS_TO || filterCondition === FilterCondition.NOT_EQUALS_TO;
  }
}
