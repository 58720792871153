import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { PageNotFoundComponent } from './page-not-found.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,

    RouterModule.forChild([
      {
        path: '',
        pathMatch: 'full',
        component: PageNotFoundComponent,
      },
    ]),
  ],
  declarations: [PageNotFoundComponent],
  exports: [PageNotFoundComponent],
})
export class PageNotFoundComponentModule {}
