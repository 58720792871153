import { Injectable, Injector } from '@angular/core';

import { RoleSet, RoleSetScope } from '@selfai-platform/bi-domain';

import { AbstractService } from '../../common/service/abstract.service';
import { CommonUtil } from '../../common/util/common.util';

@Injectable()
export class PermissionService extends AbstractService {
  constructor(protected injector: Injector) {
    super(injector);
  }

  public getRoles(params?: any): Promise<any> {
    let url = this.API_URL + 'roles?projection=forListView';
    if (params) {
      url += '&' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public getRoleDetail(roleId: string): Promise<any> {
    return this.get(this.API_URL + `roles/${roleId}?projection=forDetailView`);
  }

  public getAssignedRoleMember(roleId: string, params: any): Promise<any> {
    let url = this.API_URL + `roles/${roleId}/directories`;
    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public addRemoveAssignedRoleMember(roleId: string, params: any): Promise<any> {
    return this.patch(this.API_URL + `roles/${roleId}/directories`, params);
  }

  public getRolesets(params?: any): Promise<any> {
    let url = this.API_URL + 'rolesets?projection=forListView';
    if (params) {
      url += '&' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public getWorkspaceCustomRoleSet(workspaceId: string): Promise<any> {
    return this.getRolesets({ scope: RoleSetScope.PRIVATE, nameContains: workspaceId }).then((listResult) => {
      if (listResult['_embedded'] && listResult['_embedded']['roleSets']) {
        const roleSet: RoleSet = listResult['_embedded']['roleSets'][0];
        return Promise.resolve(roleSet);
      } else {
        return Promise.resolve(null);
      }
    });
  }

  public getRolesetDetail(rolesetId: string): Promise<any> {
    return this.get(this.API_URL + `rolesets/${rolesetId}?projection=forDetailView`);
  }

  public createRoleset(roleSet: RoleSet) {
    return this.post(this.API_URL + 'rolesets', roleSet);
  }

  public updateRoleset(roleSetId: string, params: any) {
    return this.put(this.API_URL + `rolesets/${roleSetId}`, params);
  }

  public copyRoleset(roleSetId: string) {
    return this.post(this.API_URL + `rolesets/${roleSetId}/copy`, null);
  }

  public deleteRoleset(roleSetId: string) {
    return this.delete(this.API_URL + `rolesets/${roleSetId}`);
  }

  public updateNameAndDescRoleset(roleSetId: string, params: any) {
    return this.patch(this.API_URL + `rolesets/${roleSetId}`, params);
  }

  public getWorkspacesByRoleSet(roleSetId: string, params: any) {
    let url = this.API_URL + `rolesets/${roleSetId}/workspaces?projection=forSimpleListView`;
    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public getPermissions(domain: string): Promise<string[]> {
    return this.get(this.API_URL + 'auth/' + domain + '/permissions');
  }

  public getPermissionCheck(domain: string, permission: string): Promise<boolean> {
    return this.get(this.API_URL + 'auth/' + domain + '/permissions/check?permissions=' + permission)
      .then((response) => Promise.resolve(response && response['hasPermission']))
      .catch(() => Promise.resolve(false));
  }
}
