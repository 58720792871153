import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, filter, skip, take } from 'rxjs';
import { PageParamsAdapter } from './page-params.adapter';
import { PageParams } from './page-params.model';

@Injectable()
export class UrlPageParamsService implements OnDestroy, PageParamsAdapter {
  private pageParams$ = new BehaviorSubject<PageParams | undefined>(undefined);
  private subscription = new Subscription();

  constructor(route: ActivatedRoute, private readonly router: Router) {
    route.queryParamMap.pipe(take(1)).subscribe((paramMap) => {
      const pageSize = +(paramMap.get('pageSize') || 10);
      const pageNumber = +(paramMap.get('pageNumber') || 1);
      const sortField = paramMap.get('sortField') || undefined;
      const sortOrder = (paramMap.get('sortOrder') as PageParams['sortOrder']) || undefined;
      const filters: string[] = paramMap.getAll('filters') || undefined;
      const query = paramMap.get('query') || undefined;

      this.pageParams$.next({
        pageSize,
        pageNumber,
        sortField,
        sortOrder,
        query,
        filters: (filters || []).map((filter) => filter.split(',')).map(([fieldName, value]) => ({ fieldName, value })),
      });
    });

    const subPageParams = this.getPageParams()
      .pipe(skip(1))
      .subscribe((pageParams) => {
        const queryParams = {
          pageSize: pageParams.pageSize,
          pageNumber: pageParams.pageNumber,
          sortField: pageParams.sortField,
          sortOrder: pageParams.sortOrder,
          query: pageParams.query,
          filters: pageParams.filters?.map((filter) => `${filter.fieldName},${filter.value}`),
        };

        this.router.navigate([], {
          relativeTo: route,
          queryParams: queryParams,
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
      });

    this.subscription.add(subPageParams);
  }

  setPageParams(pageParams: Partial<PageParams>): void {
    this.pageParams$.next({ ...(this.pageParams$.value || {}), ...pageParams } as PageParams);
  }

  getPageParams(): Observable<PageParams> {
    return this.pageParams$.asObservable().pipe(filter(Boolean));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
