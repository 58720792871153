<div class="workbook-nav-menu-wrapper">
  <div class="workbook-nav-section" #leftMenu>
    <ng-container *ngIf="selectedDashboard">
      <div
        class="workbook-nav-icon-screen"
        pTooltip="{{ 'msg.navbar.maximaze' | translate }}"
        tooltipPosition="bottom"
        (click)="gotoPresentationView(selectedDashboard)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-maximize"
        >
          <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3" />
        </svg>
      </div>
      <div
        class="workbook-nav-icon-edit"
        *ngIf="checkPermissions.permissions.DASHBOARD.EDIT"
        pTooltip="{{ 'msg.navbar.edit' | translate }}"
        tooltipPosition="bottom"
        (click)="changeMode('UPDATE')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-edit-2"
        >
          <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
        </svg>
      </div>
      <div
        *ngIf="checkPermissions.permissions.DASHBOARD.CREATE"
        class="workbook-nav-chart-plus"
        pTooltip="{{ 'msg.navbar.add' | translate }}"
        tooltipPosition="bottom"
      >
        <svg
          (click)="showChartsMenu()"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-plus"
        >
          <line x1="12" y1="5" x2="12" y2="19" />
          <line x1="5" y1="12" x2="19" y2="12" />
        </svg>
        <ul class="workbook-nav-chart-ul" *ngIf="this.showCharts" [@enterAnimationUp]>
          <li class="workbook-nav-chart-item-li" (click)="showChartsMenu(); addWidget('NEW_CHART')">
            {{ 'msg.navbar.chart' | translate }}
          </li>
          <li class="workbook-nav-chart-item-li" (click)="showChartsMenu(); addWidget('NEW_FILTER')">
            {{ 'msg.navbar.filter' | translate }}
          </li>
          <li class="workbook-nav-chart-item-li" (click)="showChartsMenu(); addWidget('NEW_TEXT')">
            {{ 'msg.navbar.text' | translate }}
          </li>
        </ul>
      </div>
      <div
        class="workbook-nav-delete"
        *ngIf="checkPermissions.permissions.DASHBOARD.DELETE"
        (click)="confirmDelFunc(dashBoard); $event.stopPropagation()"
        pTooltip="{{ 'msg.navbar.delete' | translate }}"
        tooltipPosition="bottom"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-trash"
        >
          <polyline points="3 6 5 6 21 6" />
          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
        </svg>
      </div>
      <div
        class="workbook-nav-copy"
        *ngIf="checkPermissions.permissions.DASHBOARD.CREATE"
        pTooltip="{{ 'msg.navbar.clone' | translate }}"
        tooltipPosition="bottom"
        (click)="copyDashboard(selectedDashboard.id, selectedDashboard.name); $event.stopPropagation()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-copy"
        >
          <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
          <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
        </svg>
      </div>
    </ng-container>

    <button
      type="button"
      pButton
      class="p-button-text p-button-link p-button-lg pr-3 pl-0 text-color"
      appearance="icon"
      [icon]="pdfDownloading ? 'pi pi-spin pi-spinner' : 'pi pi-file-pdf'"
      (click)="downloadPdf()"
      pTooltip="{{ 'msg.book.widget.header.saveDashboardPdf' | translate }}"
      tooltipPosition="bottom"
    ></button>

    <div class="workbook-nav-options" *ngIf="checkPermissions.permissions.DASHBOARD.EDIT">
      <div
        class="workbook-nav-options-menu"
        pTooltip="{{ 'msg.navbar.pins.search' | translate }}"
        tooltipPosition="bottom"
        (click)="pinMenu = !pinMenu"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-menu"
        >
          <line x1="8" y1="11" x2="14" y2="11" />
          <line x1="8" y1="8" x2="14" y2="8" />
          <line x1="8" y1="14" x2="14" y2="14" />
          <circle cx="11" cy="11" r="8" />
          <line x1="21" y1="21" x2="16.65" y2="16.65" />
        </svg>
      </div>
      <div
        class="workbook-nav-options-menu-2"
        pTooltip="{{ 'msg.navbar.pins.menu' | translate }}"
        tooltipPosition="bottom"
        (click)="pinSearch = !pinSearch"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-menu feather-menu-2"
        >
          <line x1="3" y1="12" x2="21" y2="12" />
          <line x1="3" y1="6" x2="21" y2="6" />
          <line x1="3" y1="18" x2="21" y2="18" />
        </svg>
      </div>
      <div
        class="grid-wrapper workbook-nav-options-menu-2"
        pTooltip="{{ 'msg.navbar.data-table' | translate }}"
        tooltipPosition="bottom"
      >
        <svg (click)="setGridMenu()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M6 6h-6v-6h6v6zm9-6h-6v6h6v-6zm9 0h-6v6h6v-6zm-18 9h-6v6h6v-6zm9 0h-6v6h6v-6zm9 0h-6v6h6v-6zm-18 9h-6v6h6v-6zm9 0h-6v6h6v-6zm9 0h-6v6h6v-6z"
          />
        </svg>
      </div>
      <ul class="workbook-nav-dropdown" *ngIf="dropdownMenu" [@enterAnimationUp]>
        <li class="workbook-nav-dropdown-wrapper-input">
          <input
            type="text"
            class="workbook-nav-dropdown-input"
            placeholder="search..."
            [(ngModel)]="searchDashbords"
            #searchInput
          />
          <div class="workbook-nav-dropdown-input-close" (click)="dropdownMenu = !dropdownMenu">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-x-circle"
            >
              <circle cx="12" cy="12" r="10" />
              <line x1="15" y1="9" x2="9" y2="15" />
              <line x1="9" y1="9" x2="15" y2="15" />
            </svg>
          </div>
        </li>
        <div class="workbook-nav-dropdown-wrapper-item">
          <li
            class="workbook-nav-dropdown-item"
            *ngFor="let dashboard of dashboardsPins | search: searchDashbords:'name'"
            (click)="setDashboard(dashboard)"
          >
            {{ dashboard.name }}
          </li>
        </div>
      </ul>
    </div>
  </div>
  <div class="workbook-nav-header">
    <div *ngIf="!selectedDashboard"></div>
    <div class="workbook-nav-ul-wrapper" *ngIf="selectedDashboard">
      <div
        class="workbook-nav-arrow-left"
        *ngIf="pinSearch && arrows"
        (click)="scrollLeft()"
        [@enterAnimationToggle]="searchDashbords"
      >
        <div class="workbook-nav-arrow-attachment-left"></div>
      </div>

      <ul
        cdkDropList
        cdkDropListOrientation="horizontal"
        (cdkDropListDropped)="drop($event)"
        class="workbook-nav-ul"
        *ngIf="pinSearch; dashboardsPins"
        #navigation
      >
        <li
          #navigationItem
          [id]="dashboard.id"
          class="workbook-nav-ul-li-item"
          [style.min-width]="dashboard.id === selectedDashboard.id ? '235px' : '115px'"
          *ngFor="let dashboard of dashboardsPins; let x = index"
          (click)="
            routeDashboard(dashboard);
            setDashboard(dashboard);
            this.hideGridMenu();
            this.clearWindowUI();
            $event.stopPropagation()
          "
          cdkDrag
        >
          <div class="cdk-drag-placeholder" *cdkDragPlaceholder></div>
          <div *ngIf="dashboard.id === selectedDashboard.id" style="width: 100%">
            <div class="workbook-nav-item">
              <ng-template [ngIf]="editName" [ngIfElse]="edit">
                <div
                  class="workbook-nav-icon"
                  *ngIf="checkPermissions.permissions.DASHBOARD.EDIT"
                  (click)="editName = !editName"
                  (loadeddata)="getWidthPinsMenu()"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-edit"
                  >
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
                  </svg>
                </div>
                <span class="dashBoard-name">{{ selectedDashboard.name ? selectedDashboard.name : '' }}</span>
              </ng-template>
              <ng-template #edit>
                <div class="workbook-nav-wrapper-input">
                  <input
                    class="workbook-nav-input"
                    type="text"
                    (keyup.enter)="editName = !editName; renameDashboard(inputName.value)"
                    [value]="selectedDashboard.name ? selectedDashboard.name : ''"
                    #inputName
                  />
                </div>

                <div
                  class="workbook-nav-icon pl-7 pr-0"
                  (click)="editName = !editName; renameDashboard(inputName.value)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-check"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                </div>
              </ng-template>
            </div>
          </div>
          <div *ngIf="dashboard.id !== selectedDashboard.id">
            <span class="dashBoard-name">{{ dashboard.name }}</span>
            <div
              class="closed"
              (click)="confirmDelFunc(dashboard.id); this.dashboards[x].pins = false; $event.stopPropagation()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-x-circle"
              >
                <circle cx="12" cy="12" r="10" />
                <line x1="15" y1="9" x2="9" y2="15" />
                <line x1="9" y1="9" x2="15" y2="15" />
              </svg>
            </div>
          </div>
        </li>
      </ul>
      <div class="workbook-nav-arrow-right" *ngIf="pinSearch && arrows" (click)="scrollRight()" [@enterAnimationToggle]>
        <div class="workbook-nav-arrow-attachment-right"></div>
      </div>
    </div>
    <div class="workbook-nav-btn-add" *ngIf="checkPermissions.permissions.DASHBOARD.CREATE" (click)="createDashboard()">
      + {{ 'msg.comm.btn.board' | translate }}
      <div class="ddp-ui-tooltip-info ddp-down">
        <em class="ddp-icon-view-top"></em>
        Add dashboard
      </div>
    </div>
  </div>
</div>

<div
  class="workbook-nav-pin-wrapper"
  *ngIf="pinMenu || !selectedDashboard"
  [@slideInOut]="dashboards.length"
  (load)="getWidthPinsMenu()"
>
  <div class="workbook-pin-search-wrapper" [@slideInOut]="pinMenu">
    <div class="workbook-pin-search">
      <input
        class="workbook-pin-search-input"
        [(ngModel)]="searchDashbords"
        type="text"
        placeholder="{{ 'msg.local.search' | translate }}"
        #searchPin
      />
      <label class="workbook-pin-search-active"></label>
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-search workbook-pin-search-icon"
    >
      <circle cx="11" cy="11" r="8" />
      <line x1="21" y1="21" x2="16.65" y2="16.65" />
    </svg>
    <div class="workbook-pin-checked" *ngIf="dashboards.length !== dashboardsPins.length" (click)="allPins(dashboards)">
      <img
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABmJLR0QA/wD/AP+gvaeTAAAA+ElEQVQ4ja3SIU9CURjG8R8YVAIabG6MjQ2HM2l1Nt1o8Cn4JHYCRTfZjH4Ao0kDxY7TYLHYdAZmUMO5d5Orcu9Bnnie9/z3vM85LEhLM7w2VvA8L3wZJ3jHwbyJ1nGJXXzgFVexSVZxjRtsoIUn9FGKAZ3jFtVvZw084hTlIpA2JkKxrYxXwxgvycwDBqj/BtrGPo6FdRoZv4I9NHGIC9zlpesL69T+8MsYYpQHKgmdjIV/lIWc4R6beaD0wht2/gNJNcFWBvKJXgyE8DpHmSS9JGknBjQQXmdoep1OAusWBdWFwkd+dtKNhc1SCqvmDRbR2iIgU/oC4gIxXdX5JxIAAAAASUVORK5CYII="
      />
    </div>
    <div class="workbook-pin-views" (click)="setViews()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-monitor"
      >
        <rect x="2" y="3" width="20" height="14" rx="2" ry="2" />
        <line x1="8" y1="21" x2="16" y2="21" />
        <line x1="12" y1="17" x2="12" y2="21" />
      </svg>
    </div>
  </div>

  <ul class="workbook-nav-pin-stack" *ngIf="pinMenu || !selectedDashboard">
    <div *ngIf="!this.selectedDashboard">
      <li
        class="workbook-nav-pin-item"
        *ngFor="let dashboard of dashboards; let i = index"
        (click)="setDashboard(dashboard); clearWindowUI(); $event.stopPropagation()"
      >
        {{ dashboard.name | short: 20 }}
        <div class="workbook-nav-pin-delete" (click)="confirmDelFunc(dashboard.id); $event.stopPropagation()">
          <svg
            class="feather feather-trash"
            fill="none"
            width="12"
            height="12"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polyline class="ng-tns-c2-1" points="3 6 5 6 21 6"></polyline>
            <path
              class="ng-tns-c2-1"
              d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
            ></path>
          </svg>
          <div class="ddp-ui-tooltip-info ddp-down">delete</div>
        </div>
        <div
          class="workbook-nav-pin-clone"
          (click)="copyDashboard(dashboard.id, dashboard.name); $event.stopPropagation()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-copy"
          >
            <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
            <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
          </svg>
          <div class="ddp-ui-tooltip-info ddp-down">clone</div>
        </div>
      </li>
    </div>
    <div *ngIf="this.selectedDashboard">
      <li
        class="workbook-nav-pin-item"
        [class.width200]="trumbView"
        [ngClass]="{
          'workbook-nav-pin-item-selected': dashboard.id === selectedDashboard.id
        }"
        *ngFor="let dashboard of dashboards | search: searchDashbords:'name'; let i = index"
        (click)="setDashboard(dashboard); clearWindowUI(); $event.stopPropagation()"
      >
        <div>
          <div
            *ngIf="checkId(dashboard.id) && selectedDashboard"
            class="pin-wrapper"
            (click)="deletePins(dashboard.id); this.dashboards[i].pins = false; $event.stopPropagation()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-toggle-left"
            >
              <rect
                x="1"
                y="5"
                width="22"
                height="14"
                rx="7"
                ry="7"
                fill="var(--color-primary)"
                stroke="white"
                [ngStyle]="{
                  stroke: dashboard.id === selectedDashboard.id ? 'white' : ''
                }"
              />
              <circle cx="8" cy="12" r="3" stroke="white" fill="white" />
            </svg>
            <div class="ddp-ui-tooltip-info ddp-down">unpin</div>
          </div>
          <div
            *ngIf="!checkId(dashboard.id)"
            class="pin-wrapper"
            (click)="addInPins(dashboard); dashboards[i].pins = true; $event.stopPropagation()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="13"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-toggle-right"
            >
              <rect
                x="1"
                y="5"
                width="22"
                height="14"
                rx="7"
                ry="7"
                fill="#ff5252"
                stroke="white"
                [ngStyle]="{
                  '': dashboard.id === selectedDashboard.id ? 'white' : ''
                }"
              />
              <circle cx="16" cy="12" r="3" fill="white" stroke="white" />
            </svg>
            <div class="ddp-ui-tooltip-info ddp-down">pin</div>
          </div>
          <div
            class="workbook-nav-pin-item-text-wrapper"
            [ngStyle]="{
              color: dashboard.id === selectedDashboard.id ? 'white' : ''
            }"
          >
            {{ dashboard.name | short: 20 }}
          </div>

          <div class="workbook-nav-pin-delete" (click)="confirmDelFunc(dashboard.id); $event.stopPropagation()">
            <svg
              class="feather feather-trash"
              fill="none"
              width="12"
              height="12"
              stroke="currentColor"
              [ngStyle]="{
                stroke: dashboard.id === selectedDashboard.id ? 'white' : ''
              }"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polyline class="ng-tns-c2-1" points="3 6 5 6 21 6"></polyline>
              <path
                class="ng-tns-c2-1"
                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
              ></path>
            </svg>
            <div class="ddp-ui-tooltip-info ddp-down">
              {{ 'msg.navbar.delete' | translate }}
            </div>
          </div>
          <div
            class="workbook-nav-pin-clone"
            (click)="copyDashboard(dashboard.id, dashboard.name); $event.stopPropagation()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 24 24"
              fill="none"
              [ngStyle]="{
                stroke: dashboard.id === selectedDashboard.id ? 'white' : ''
              }"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-copy"
            >
              <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
              <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
            </svg>
            <div class="ddp-ui-tooltip-info ddp-down">
              {{ 'msg.navbar.clone' | translate }}
            </div>
          </div>
        </div>
        <div class="thumbview-box--image" *ngIf="trumbView">
          <selfai-bi-dashbord-preview-image [imageUrl]="dashboard?.imageUrl"></selfai-bi-dashbord-preview-image>
        </div>
      </li>
    </div>
  </ul>
</div>
<app-workbook-action-btn *ngIf="showActionsBtnPanel"></app-workbook-action-btn>

<app-create-board
  *ngIf="isShowCreateDashboard"
  [workbookId]="workbook.id"
  [workbookName]="workbook.name"
  [workspaceId]="workbook.workspaceId"
  (close)="closeCreateDashboard()"
></app-create-board>
<selfai-bi-update-dashboard
  *ngIf="mode === 'UPDATE'"
  [startupCmd]="updateDashboardStartupCmd"
  [workbook]="workbook"
  [dashboards]="dashboards"
  (changeMode)="changeMode($event)"
  (updateComplete)="updateCompleteDashboard($event)"
  (createDashboard)="createDashboard()"
  (selectedDashboard)="loadAndSelectDashboard($event)"
></selfai-bi-update-dashboard>
