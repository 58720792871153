import { Injectable } from '@angular/core';
import { AlertService } from '../../alert/alert.service';
import { ConfigService } from '../config.service';
import { PipelineConfig } from './pipeline-config.model';

@Injectable()
export class PipelineConfigService {
  constructor(private readonly configService: ConfigService, private readonly alertService: AlertService) {}

  public getConfig(): PipelineConfig {
    try {
      return this.configService.getConfig().pipeline;
    } catch (e: unknown) {
      this.alertService.error('Configuration for Pipeline is not available. See config.json section pipeline');
      throw e;
    }
  }
}
