import { Injectable, Injector } from '@angular/core';

import { AbstractService } from '../../common/service/abstract.service';
import { CommonUtil } from '../../common/util/common.util';

@Injectable()
export class ActivityService extends AbstractService {
  constructor(protected injector: Injector) {
    super(injector);
  }

  public getActivities(username: string, params?: any): Promise<any> {
    let url = this.API_URL + `activities/user/${username}`;
    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }
}
