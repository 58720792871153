import { Injectable } from '@angular/core';

import _ from 'lodash';
import { Observable, from, map, tap } from 'rxjs';

import { Book } from '@selfai-platform/bi-domain';
import { Loader, LoaderUtil, PageParams } from '@selfai-platform/shared';

import { WorkspaceService } from './workspace.service';

@Injectable()
export class MyWorkspaceService extends LoaderUtil<Book[]> {
  pageParams: PageParams = {
    pageSize: 10,
    pageNumber: 1,
  };

  constructor(private readonly workspaceService: WorkspaceService) {
    super(undefined);
  }

  setPageParams(pageParams: Partial<PageParams>): void {
    this.pageParams = { ...this.pageParams, ...pageParams };
  }

  loadWorkbooks(pageParams?: PageParams): void {
    this.loadFromSource(
      from(
        this.workspaceService.getMyWorkspace('forDetailView', {
          limit: pageParams.pageSize || 10,
          offset: pageParams.pageSize * (pageParams.pageNumber - 1),
          filter: this.mapSorting(pageParams),
        }),
      ).pipe(
        tap(({ booksContour }) => {
          this.setPageParams({
            totalItems: booksContour.all,
          });
        }),
        map(({ books }) => books),
      ),
    );
  }

  getWorkbooksState(): Observable<Loader<Book[]>> {
    return this.asObservable();
  }

  private mapSorting(sort: Partial<Pick<PageParams, 'sortField' | 'sortOrder'>>) {
    if (!sort.sortField) {
      return 'nameup';
    }

    switch (sort.sortField) {
      case 'createdTime':
        return `create${this.mapSortingOrder(sort.sortOrder)}`;
      case 'modifiedTime':
        return `modified${this.mapSortingOrder(sort.sortOrder)}`;
      default:
        return `${sort.sortField}${this.mapSortingOrder(sort.sortOrder)}`;
    }
  }

  private mapSortingOrder(sortOrder?: PageParams['sortOrder']): string {
    return sortOrder === 'desc' ? 'down' : 'up';
  }
}
