import { Injectable } from '@angular/core';
import { Loader, LoaderUtil } from '@selfai-platform/shared';
import { Observable, filter, map } from 'rxjs';
import { AggregatorProcessList } from '../models/aggregator-process.model';
import { AggregatorAdapter } from './api';

@Injectable({ providedIn: 'root' })
export class AggregatorProcessListService extends LoaderUtil<AggregatorProcessList> {
  constructor(private readonly aggregatorAdapter: AggregatorAdapter) {
    super([]);
  }

  loadProcessList(): void {
    this.loadFromSource(this.aggregatorAdapter.loadProcessList());
  }

  getProcessListState(): Observable<Loader<AggregatorProcessList | undefined>> {
    return this.asObservable().pipe(filter(Boolean));
  }

  getProcessList(): Observable<AggregatorProcessList> {
    return this.getProcessListState().pipe(
      map(({ value }) => value),
      filter(Boolean),
    );
  }
}
