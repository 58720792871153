<div class="ddp-type-contents ddp-resize">
  <a (click)="closeComp()" href="javascript:" class="ddp-btn-close"></a>

  <div class="ddp-ui-name2 ddp-type3">
    {{ 'msg.board.create.title' | translate }}
    <em class="ddp-bg-order-line2-type"></em>
  </div>
  <div class="ddp-type-contents-in">
    <span *ngIf="isShowLabelAddDataSource" class="ddp-txt-info ddp-type">
      {{ 'msg.board.create.add-ds' | translate }}
    </span>
    <span *ngIf="!isShowLabelAddDataSource" class="ddp-txt-info ddp-type">
      {{ 'msg.board.create.set-relation' | translate }}
    </span>

    <create-board-ds-network [workspaceId]="workspaceId" (onChange)="checkAllowNext($event)"></create-board-ds-network>
  </div>

  <div *ngIf="isShowButtons" class="ddp-ui-buttons">
    <a (click)="closeComp()" href="javascript:" class="ddp-btn-type-popup">{{ 'msg.comm.btn.cancl' | translate }}</a>

    <a (click)="next()" [class.ddp-disabled]="isDenyNext" href="javascript:" class="ddp-btn-type-popup ddp-bg-black">
      {{ 'msg.comm.btn.next' | translate }}
    </a>
  </div>
</div>

<create-board-complete (close)="closeComp(true)"></create-board-complete>
