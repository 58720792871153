<div
  *ngIf="legendInfo.enable"
  #legend
  class="ddp-wrap-remark"
  style="position: absolute; z-index: 3"
  [class.ddp-left-bottom]="legendInfo.position === 'LEFT_BOTTOM'"
  [class.ddp-left-top]="legendInfo.position === 'LEFT_TOP'"
  [class.ddp-right-bottom]="legendInfo.position === 'RIGHT_BOTTOM'"
>
  <div class="ddp-layout-remark" [ngClass]="{ 'ddp-small': !legendInfo.showFl }" (click)="changeFoldLegend()">
    <div class="ddp-remark-size-s">
      <a *ngIf="!isPage" href="javascript:" class="ddp-btn-size-s"></a>
    </div>

    <div class="ddp-remark-size-b">
      <a *ngIf="!isPage" href="javascript:" class="ddp-btn-size"></a>

      <div *ngFor="let layer of legendInfo.layer; let index = index" class="ddp-ui-layer">
        <span class="ddp-label">{{ layer.name }}</span>
        <span class="ddp-data">{{ layer.type }} {{ layer.column }}</span>
        <ul class="ddp-list-remark">
          <li *ngFor="let field of layer.color">
            <em
              [class.ddp-bg-remark-r]="'CIRCLE' === layer.pointType?.toString()"
              [class.ddp-bg-remark]="'SQUARE' === layer.pointType?.toString()"
              [class.ddp-bg-remark-triangle]="'TRIANGLE' === layer.pointType?.toString()"
              [style.border-bottom-color]="'TRIANGLE' === layer.pointType?.toString() ? field.color : ''"
              [style.background-color]="'TRIANGLE' !== layer.pointType?.toString() ? field.color : ''"
            ></em>
            {{ field.column }}
          </li>
        </ul>
        <span *ngIf="layer.radiusColumn" class="ddp-data">Radius Range {{ layer.radiusColumn }}</span>
      </div>
    </div>
  </div>
</div>

<div #tooltip id="popup" style="z-index: 100">
  <div
    *ngIf="tooltipInfo.enable"
    class="ddp-ui-tooltip-info ddp-map-tooltip"
    style="min-width: 150px; display: block; z-index: 10"
  >
    <em class="ddp-tooltip-view"></em>
    <span class="ddp-txt-tooltip">
      <span *ngIf="tooltipInfo.name" class="ddp-label">
        <em [attr.class]="'ddp-icon-mapview' + tooltipInfo.num + '-w'"></em>
        {{ tooltipInfo.name }}
      </span>
      <div *ngIf="tooltipInfo.coords.length > 0" class="ddp-data-tooltip">
        <div class="ddp-title">{{ tooltipInfo.title }}</div>
        <div class="ddp-det">{{ tooltipInfo.coords[0] }}</div>
      </div>
      <div *ngIf="tooltipInfo.coords.length > 1" class="ddp-data-tooltip">
        <div class="ddp-det">{{ tooltipInfo.coords[tooltipInfo.coords.length - 1] }}</div>
      </div>
      <div *ngFor="let field of tooltipInfo.fields" class="ddp-data-tooltip">
        <div class="ddp-title">{{ field.name }}</div>
        <div class="ddp-det">{{ field.value }}</div>
      </div>
    </span>
  </div>
</div>

<canvas #feature style="visibility: hidden; position: fixed; top: 0px; left: 0px"></canvas>
