import { Injectable } from '@angular/core';
import {
  DatasetComponent,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
} from 'echarts/components';

import { LabelLayout, UniversalTransition } from 'echarts/features';

import { CanvasRenderer } from 'echarts/renderers';

import * as echarts from 'echarts/core';

@Injectable()
export class EChartService {
  private chartInstance: echarts.ECharts;

  constructor() {
    echarts.use([
      TitleComponent,
      TooltipComponent,
      GridComponent,
      DatasetComponent,
      TransformComponent,
      LabelLayout,
      UniversalTransition,
      CanvasRenderer,
    ]);
  }

  initChart(chartElement: HTMLElement, options?: echarts.EChartsCoreOption): echarts.ECharts {
    this.chartInstance = echarts.init(chartElement, 'exntu');

    if (options) {
      this.chartInstance.setOption(options);
    }

    return this.chartInstance;
  }

  getInstance(): echarts.ECharts {
    return this.chartInstance;
  }
}
