import { Injectable, Injector } from '@angular/core';

import { Dashboard, Book, Workbook, WorkbookDetailProjections, createBook } from '@selfai-platform/bi-domain';

import { AbstractService } from '../../common/service/abstract.service';
import { CommonUtil } from '../../common/util/common.util';
import { Comment } from '../../domain/comment/comment';
import { Page, PageResult } from '../../domain/common/page';

@Injectable({ providedIn: 'root' })
export class WorkbookService extends AbstractService {
  constructor(protected injector: Injector) {
    super(injector);
  }

  public getPinsWorkbook(workbookID): Promise<any> {
    return this.get(this.API_URL + `workbooks/${workbookID}/pins`);
  }

  public addPinsWorkbook(workbookID: string, dashboardID: string, params: object) {
    return this.post(this.API_URL + `workbooks/${workbookID}/pins/${dashboardID}`, params);
  }

  public addPinsWorkbookID(workbookID: string, dashboardID: string, id: string, params: string) {
    return this.post(this.API_URL + `workbooks/${workbookID}/pins/${dashboardID}/${id}`, params);
  }

  public changePlacePinsWorkbookID(workbookID: string, dashboardID: string, dashboard2ID: string, params: object) {
    return this.patch(this.API_URL + `workbooks/${workbookID}/pins/${dashboardID}/${dashboard2ID}`, params);
  }

  public deletePinsWorkbookID(workbookID: string, dashboardID: string) {
    return this.delete(this.API_URL + `workbooks/${workbookID}/pins/${dashboardID}`);
  }

  public deleteAllPins(worbookID: string) {
    return this.delete(this.API_URL + `workbooks/${worbookID}/pins`);
  }

  public addAllPins(worbookID: string, dashboardIds: string[]) {
    return this.post(this.API_URL + `workbooks/${worbookID}/pins`, dashboardIds);
  }

  public createWorkbook(book: Book): Promise<any> {
    return this.post(this.API_URL + 'books', book);
  }

  public createWorkbook2(params: {
    workspace: string;
    name: string;
    type: 'workbook' | 'folder';
    description?: string;
    folderId?: string;
  }): Promise<Book> {
    return this.post(this.API_URL + 'books', params);
  }

  public updateBook(book: Book): Promise<Workbook> {
    const param = createBook();
    param.name = book.name;
    param.description = book.description;
    return this.patch(this.API_URL + 'books/' + book.id, param);
  }

  public deleteWorkbook(id: string): Promise<Workbook> {
    return this.delete(this.API_URL + 'books/' + id);
  }

  public getWorkbook(id: string, projection: string = 'forDetailView'): Promise<WorkbookDetailProjections> {
    return this.get(this.API_URL + `workbooks/${id}?projection=${projection}`);
  }

  public getDashboards(
    id: string,
    order: { key: string; type: 'asc' | 'desc' },
    page: Page,
    projection: string = 'default',
    options?: any,
  ): Promise<{ page: PageResult; _embedded: { dashboards: Dashboard[] } }> {
    let url = this.API_URL + `workbooks/${id}/dashboards?projection=${projection}`;

    page.sort = order.key + ',' + order.type;
    url += '&' + CommonUtil.objectToUrlString(page);

    if (options) {
      url += '&' + CommonUtil.objectToUrlString(options);
    }

    return this.get(url);
  }

  public copyWorkbook(copyId: string, folderId?: string, options?: any): Promise<any> {
    let url = this.API_URL + 'books/' + copyId + '/copy';
    folderId && (url += '/' + folderId);
    options && (url += '?' + CommonUtil.objectToUrlString(options));
    return this.post(url, null);
  }

  public moveWorkbook(copyId: string, folderId?: string, options?: any): Promise<any> {
    let url = this.API_URL + 'books/' + copyId + '/move';
    folderId && (url += '/' + folderId);
    options && (url += '?' + CommonUtil.objectToUrlString(options));
    return this.post(url, null);
  }

  public setDashboardSort(workbookId: string, param: any) {
    return this.patch(this.API_URL + 'workbooks/' + workbookId + '/dashboards', param);
  }

  public getComments(id: string, page: Page): Promise<any> {
    let url = this.API_URL + `workbooks/${id}/comments?projection=default`;
    url += '&' + CommonUtil.objectToUrlString(page);
    return this.get(url);
  }

  public createComment(comment: Comment, id: string): Promise<any> {
    return this.post(this.API_URL + 'workbooks/' + id + '/comments', comment);
  }

  public updateComment(comment: Comment, workbookId: string): Promise<any> {
    const param = new Comment();
    param.contents = comment.modifyContents;

    return this.patch(this.API_URL + 'workbooks/' + workbookId + '/comments/' + comment.id, param);
  }

  public deleteComment(workbookId: string, commentId: string): Promise<any> {
    return this.delete(this.API_URL + 'workbooks/' + workbookId + '/comments/' + commentId);
  }
}
