import { Injectable } from '@angular/core';

import {
  BoardDataSourceRelation,
  Dashboard,
  DashobardFieldType,
  Datasource,
  FieldRole,
  Filter,
  InclusionFilter,
  InclusionSelectorType,
  PageWidget,
  PageWidgetConfiguration,
  SearchQueryRequest,
  ShelveFieldType,
} from '@selfai-platform/bi-domain';

import { DatasourceService } from '../../../../datasource/service/datasource.service';
import { DashboardUtil } from '../../../util';

export interface SearchQueryResponse {
  columns: SearchQueryResponseColumn[];
  info: { maxValue: number; minValue: number; totalCategory: number };
  rows: any[];
}

export interface SearchQueryResponseColumn {
  name: string;
  value: string[];
}

@Injectable()
export class PageWidgetAssociationDataService {
  constructor(private readonly datasourceService: DatasourceService) {}

  async createAssociationFilterFormExternalFilter(
    widget: PageWidget,
    dashboard: Dashboard,
    widgetConfiguration: PageWidgetConfiguration,
    association: BoardDataSourceRelation,
    sourceFilter: Filter,
  ): Promise<Pick<InclusionFilter, 'dataSource' | 'field' | 'type' | 'valueList' | 'selector'>> {
    const widgetDataSource: Datasource = DashboardUtil.getDataSourceFromBoardDataSource(
      dashboard,
      widgetConfiguration.dataSource,
    );
    const isSource = association.source === widgetDataSource.engineName;
    const sourceKey = Object.keys(association.columnPair)[0];
    const targetKey = association.columnPair[sourceKey];
    if (isSource) {
      const valueList = await this.getValueListForSource(
        widget,
        dashboard,
        association.target,
        sourceFilter,
        targetKey,
      );
      const associationFilter: Pick<InclusionFilter, 'dataSource' | 'field' | 'type' | 'valueList' | 'selector'> = {
        dataSource: widgetDataSource.engineName,
        field: sourceKey,
        type: 'include',
        valueList,
        selector: InclusionSelectorType.MULTI_LIST,
      };
      return associationFilter;
    } else {
      const valueList = await this.getValueListForSource(
        widget,
        dashboard,
        association.source,
        sourceFilter,
        sourceKey,
      );
      const associationFilter: Pick<InclusionFilter, 'dataSource' | 'field' | 'type' | 'valueList' | 'selector'> = {
        dataSource: widgetDataSource.engineName,
        field: targetKey,
        type: 'include',
        valueList,
        selector: InclusionSelectorType.MULTI_LIST,
      };
      return associationFilter;
    }
  }

  async getValueListForSource(
    widget: PageWidget,
    dashboard: Dashboard,
    datasourceName: string,
    sourceFilter: Filter,
    key: string,
  ): Promise<string[]> {
    const request = this.createAssociationDataRequest(widget, dashboard, datasourceName, [sourceFilter], key);
    const response = (await this.datasourceService.searchQuery(request, false)) as SearchQueryResponse;
    return response.columns.find((column) => column.name === key).value;
  }

  private convertSearchQueryResponseToAssociationData(
    response: SearchQueryResponse,
    primaryKey: string,
  ): Record<string, Record<string, unknown>> {
    const primaryColumn = response.columns.find((column) => column.name === primaryKey);
    const length = primaryColumn.value.length;
    const associationData: Record<string, Record<string, unknown>> = {};
    response.columns
      .filter((column) => column.name !== primaryKey)
      .forEach((column) => {
        const associationKey = primaryKey + column.name;
        associationData[associationKey] = {};
        for (let i = 0; i < length; i++) {
          associationData[associationKey][column.value[i]] = primaryColumn.value[i];
        }
      });
    return associationData;
  }

  private createAssociationDataRequest(
    widget: PageWidget,
    dashboard: Dashboard,
    sourceName: string,
    filters: Filter[],
    primaryKey: string,
  ) {
    const dataSource = dashboard.dataSources.find(
      (dataSource) => dataSource.name === sourceName || dataSource.engineName === sourceName,
    );
    const filtersField = filters.map((filter) => filter.field);
    const request: SearchQueryRequest = {
      context: {
        'discovery.route.uri': '/bi/workbook/' + dashboard?.workBook?.id || '',
        'discovery.dashboard.id': dashboard.id,
        'discovery.widget.id': widget.id,
      },
      userFields: [],
      filters: filters,
      pivot: {
        columns: dataSource.fields
          .filter(
            (field) =>
              field.role === FieldRole.DIMENSION && (field.name === primaryKey || filtersField.includes(field.name)),
          )
          .map((field) => {
            return {
              aggregationType: field.aggrType,
              name: field.name,
              alias: field.alias || field.name,
              subRole: field.role,
              subType: field.type,
              type: field.role.toLowerCase() as DashobardFieldType,
              format: field.format as any,
            };
          }),
        rows: [],
        aggregations: [
          {
            type: ShelveFieldType.MEASURE,
            aggregationType: 'NONE',
            name: 'count',
            subType: 'STRING',
            subRole: 'MEASURE',
            alias: 'COUNT(count)',
            format: {
              type: 'number',
              sign: 'KRW',
              decimal: 2,
              useThousandsSep: true,
              abbr: 'NONE',
              customSymbol: null,
            },
            aggregationTypeList: [],
          },
        ],
      },
      dataSource: {
        connType: dataSource.connType,
        name: dataSource.engineName,
        type: 'default',
        id: dataSource.id,
        temporary: dataSource.temporary,
      },
      limits: { limit: 1000000, sort: [] },
      aliases: [],
      resultFormat: {
        type: 'chart',
        mode: 'grid',
        options: { addMinMax: true, isOriginal: true },
        columnDelimeter: '―',
      },
    };
    return request;
  }
}
