import { DatasourceField } from '../../../../datasource';
import { ByTimeUnit, TimeUnit } from '../field/timestamp-field.model';
import { Filter, createFilter } from './filter.model';

export interface TimeFilter extends Filter {
  discontinuous?: boolean;
  timeUnit?: TimeUnit;
  byTimeUnit?: ByTimeUnit;
  clzField: DatasourceField;
}

export function createTimeFilter(field: DatasourceField): TimeFilter {
  const timeFilter = {
    ...createFilter(),
    clzField: field,
    discontinuous: false,
    field: field.name,
    ref: field.ref,
    dataSource: field.dataSource,
  };

  if (field.filteringSeq > -1) {
    timeFilter.ui.filteringSeq = field.filteringSeq + 1;
  }

  if (field.filteringOptions) {
    timeFilter.ui.filteringOptions = field.filteringOptions;
  }

  return timeFilter;
}
