import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Dashboard } from '../models';
import { DashboardStore } from './dashboard.store';

@Injectable({ providedIn: 'root' })
export class WidgetDashboardStore extends DashboardStore {
  override getSnapshotDashboard(widgetId: string): Dashboard | undefined {
    return super.getSnapshotDashboard(widgetId);
  }

  override selectDashboard(widgetId: string): Observable<Dashboard | undefined> {
    return super.selectDashboard(widgetId);
  }

  override selectDashboardLoading(widgetId: string): Observable<boolean> {
    return super.selectDashboardLoading(widgetId);
  }

  override selectDashboardError(widgetId: string): Observable<unknown> {
    return super.selectDashboardError(widgetId);
  }

  override selectDashboardSuccess(widgetId: string): Observable<boolean> {
    return super.selectDashboardSuccess(widgetId);
  }

  override updateDashboard(widgetId: string, dashboard: Dashboard): void {
    return super.updateDashboard(widgetId, dashboard);
  }

  override setSuccess(widgetId: string): void {
    return super.setSuccess(widgetId);
  }

  override setError(widgetId: string, error: unknown): void {
    return super.setError(widgetId, error);
  }

  override setLoading(widgetId: string): void {
    return super.setLoading(widgetId);
  }
}
