import { BaseOption, DataZoomType, Orient, UIOption } from '@selfai-platform/bi-domain';
import { DataZoomComponentOption, LegendComponentOption, VisualMapComponentOption } from 'echarts';
import * as _ from 'lodash';

export class LegendOptionConverter {
  public static convertLegend(option: BaseOption, uiOption: UIOption): BaseOption {
    option = this.convertLegendShow(option, uiOption);

    return option;
  }

  public static convertLegendShow(option: BaseOption, uiOption: UIOption): BaseOption {
    if (!uiOption.legend || _.isNull(uiOption.legend.auto) || _.isUndefined(uiOption.legend.auto)) return option;

    const show = uiOption.legend.auto;

    if (_.isUndefined(option.visualMap)) {
      (option.legend as LegendComponentOption).show = show;
    } else {
      (option.visualMap as VisualMapComponentOption).show = show;

      if (!Array.isArray(option.legend)) {
        option.legend.show = false;
      }
    }

    if (!_.isUndefined(option.grid) && !_.isUndefined(option.dataZoom)) {
      const sliderZooms = (option.dataZoom as DataZoomComponentOption[]).filter((dataZoom) => {
        return _.eq(dataZoom.type, DataZoomType.SLIDER);
      });
      if (!_.isEmpty(sliderZooms)) {
        _.gt(sliderZooms.length, 1)
          ? Orient.HORIZONTAL
          : _.eq(sliderZooms[0].orient, Orient.HORIZONTAL)
          ? Orient.VERTICAL
          : Orient.HORIZONTAL;
      }
    }

    return option;
  }
}
