import { Component, ElementRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import $ from 'jquery';

import { TextWidget } from '@selfai-platform/bi-domain';

import { EventBroadcaster } from '../../../common/event/event.broadcaster';
import { AbstractWidgetComponent } from '../abstract-widget.component';

@Component({
  selector: 'text-widget',
  templateUrl: './text-widget.component.html',
  styleUrls: ['./text-widget.component.css'],
})
export class TextWidgetComponent extends AbstractWidgetComponent implements OnInit, OnDestroy {
  public widget: TextWidget;

  @Input('widget')
  set setWidget(widget: TextWidget) {
    this.widget = widget;
  }

  constructor(
    public sanitizer: DomSanitizer,
    protected broadCaster: EventBroadcaster,
    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(broadCaster, elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.processStart();
    this.processEnd();
  }

  public ngAfterViewInit() {
    super.ngAfterViewInit();
    setTimeout(() => {
      this._setIsVisibleScrollbar();
      this.safelyDetectChanges();
    }, 1000);
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public setConfiguration(objConfig: any) {
    this.widget.configuration = objConfig;
  }

  private _setIsVisibleScrollbar() {
    const $container: JQuery = $(this.elementRef.nativeElement).find('.ddp-view-widget');
    const $contents: JQuery = $container.children();
    this.isVisibleScrollbar = $container.height() < $contents.height();
    this.safelyDetectChanges();
  }
}
