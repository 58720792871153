import { isNullOrUndefined } from 'util';

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import * as _ from 'lodash';

import {
  BoardDataSource,
  BoardDataSourceRelation,
  ConnectionType,
  Dashboard,
  Datasource,
  Filter,
  convertDsToMetaDs,
  createBoardDataSourceRelation,
  createTemporaryDatasource,
} from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { Modal } from '../../../common/domain/modal';
import { EventBroadcaster } from '../../../common/event/event.broadcaster';
import { CommonUtil } from '../../../common/util/common.util';

import { CreateBoardPopDsSelectComponent } from './create-board-pop-ds-select.component';
import { CreateBoardPopRelationComponent } from './create-board-pop-relation.component';

declare const vis;

@Component({
  selector: 'create-board-ds-network',
  templateUrl: './create-board-ds-network.component.html',
  styleUrls: ['./create-board-ds-network.component.css'],
})
export class CreateBoardDsNetworkComponent extends AbstractComponent implements OnInit, OnDestroy {
  @ViewChild(CreateBoardPopDsSelectComponent, { static: true })
  private _dataSourceSelectComp: CreateBoardPopDsSelectComponent;

  @ViewChild(CreateBoardPopRelationComponent, { static: true })
  private _relationPopComp: CreateBoardPopRelationComponent;

  @ViewChild('guideLine')
  private _guideLine: ElementRef;

  private _nodes: any;
  private _edges: any;

  private _network: any = null;

  private _dataSources: Datasource[] = [];
  private _boardDataSources: BoardDataSource[] = [];
  private _relations: BoardDataSourceRelation[] = [];

  private _isAlreadyViewGuide = false;

  public isRenderedNetwork = false;
  public isRelationEditMode = false;
  public isPossibleSettingRel = false;

  public candidateIngestionList: BoardDataSource[] = [];
  public ingestionTargetDatasource: BoardDataSource;
  public isShowDataIngestion = false;

  public selectedDataSource: BoardDataSource;
  public selectedRelation: BoardDataSourceRelation;

  public isShowMultiDsGuide = false;

  @Input()
  public workspaceId: string;

  @Input()
  public dashboard: Dashboard;

  @Output()
  public onChange: EventEmitter<{ isDenyNext?: boolean; isShowButtons?: boolean }> = new EventEmitter();

  constructor(protected elementRef: ElementRef, protected injector: Injector, protected broadCaster: EventBroadcaster) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.subscriptions.push(
      this.broadCaster
        .on('CREATE_BOARD_UPDATE_DS')
        .subscribe((data: { dataSource: BoardDataSource; candidateDataSources: Datasource[] }) => {
          const targetIdx = this._boardDataSources.findIndex((item) => item.id === data.dataSource.id);
          if (targetIdx !== -1) {
            this._boardDataSources[targetIdx] = data.dataSource;
          }

          data.candidateDataSources.forEach((ds) => {
            const dataSourceIdx: number = this._dataSources.findIndex((item) => item.id === ds.id);

            if (dataSourceIdx !== -1) {
              this._dataSources.push(ds);
            }
          });
        }),
    );

    this.subscriptions.push(
      this.broadCaster.on('CREATE_BOARD_REMOVE_DS').subscribe((data: { dataSourceId: string }) => {
        if (
          this._relations.some(
            (item) => item.ui.source.id === data.dataSourceId || item.ui.target.id === data.dataSourceId,
          )
        ) {
          const modal = new Modal();
          modal.name = this.translateService.instant('msg.board.create.confirm.del_ds.main');
          modal.description = this.translateService.instant('msg.board.create.confirm.del_ds.sub');
          modal.btnName = this.translateService.instant('msg.comm.btn.del');
          modal.afterConfirm = () => {
            this.selectedDataSource = null;
            this._removeDataSource(data.dataSourceId);
          };
          CommonUtil.confirm(modal);
        } else {
          this.selectedDataSource = null;
          this._removeDataSource(data.dataSourceId);
        }
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on('CREATE_BOARD_CLOSE_DS').subscribe(() => {
        this.selectedDataSource = null;
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on('CREATE_BOARD_CREATE_REL').subscribe((data: { relation: BoardDataSourceRelation }) => {
        this._relations.push(data.relation);
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on('CREATE_BOARD_EDIT_REL').subscribe((data: { relationId: string }) => {
        const relInfo = this._relations.find((item) => item.id === data.relationId);
        this._relationPopComp.editRelation(relInfo);
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on('CREATE_BOARD_UPDATE_REL').subscribe((data: { relation: BoardDataSourceRelation }) => {
        const targetIdx = this._relations.findIndex((item) => item.id === data.relation.id);
        -1 < targetIdx && (this._relations[targetIdx] = data.relation);
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on('CREATE_BOARD_REMOVE_REL').subscribe((data: { relationId: string }) => {
        this._removeRelation(data.relationId);
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on('CREATE_BOARD_RE_INGESTION').subscribe((data: { dataSource: BoardDataSource }) => {
        this._clearSelection();
        const targetDs: BoardDataSource = data.dataSource['orgDataSource'];
        targetDs.uiFilters = data.dataSource.uiFilters;
        this.candidateIngestionList.push(targetDs);
        this._showDataIngestion();
      }),
    );
  }

  public ngAfterViewInit() {
    if (this.dashboard) {
      this.isRenderedNetwork || this._renderNetworkBoard();
      this.safelyDetectChanges();
      const dataSources: Datasource[] = this.dashboard.dataSources;
      this._dataSources = dataSources;
      const boardDs: BoardDataSource = this.dashboard.configuration.dataSource;
      if ('multi' === boardDs.type) {
        boardDs.dataSources.forEach((item) => {
          const targetDs: Datasource = dataSources.find(
            (ds) =>
              item.engineName === ds.engineName ||
              (ds.connType === ConnectionType.LINK && item.engineName.startsWith(ds.engineName + '_')),
          );
          targetDs && (item.name = targetDs.name);

          if (targetDs.connType == ConnectionType.LINK && targetDs.engineName != item.engineName) {
            targetDs.engineName = item.engineName;
            targetDs.temporary = targetDs.temporary ? targetDs.temporary : createTemporaryDatasource();
          }

          this._addDataSource(convertDsToMetaDs(targetDs));
        });
        if (boardDs.associations) {
          boardDs.associations.forEach((item) => {
            const srcDs: Datasource = dataSources.find((ds) => ds.engineName === item.source);
            const tgtDs: Datasource = dataSources.find((ds) => ds.engineName === item.target);
            const srcKey: string = Object.keys(item.columnPair)[0];
            const tgtKey: string = item.columnPair[srcKey];
            item.id = CommonUtil.getUUID();
            item.ui = {
              source: convertDsToMetaDs(srcDs),
              target: convertDsToMetaDs(tgtDs),
              sourceField: srcDs.fields.find((field) => field.name === srcKey),
              targetField: tgtDs.fields.find((field) => field.name === tgtKey),
            };
            this._addEdgeByRelation(item);
          });
        }
        this.isPossibleSettingRel = true;
      } else {
        const foundDs: Datasource = dataSources.find(
          (ds) =>
            boardDs.engineName === ds.engineName ||
            (ds.connType === ConnectionType.LINK && boardDs.engineName.startsWith(ds.engineName + '_')),
        );
        if (foundDs) {
          if (foundDs.connType == ConnectionType.LINK && foundDs.engineName != boardDs.engineName) {
            foundDs.engineName = boardDs.engineName;
            foundDs.temporary = foundDs.temporary ? foundDs.temporary : createTemporaryDatasource();
          }

          const newBoardDs: BoardDataSource = convertDsToMetaDs(foundDs);
          if (boardDs.joins && boardDs.joins.length > 0) {
            newBoardDs.joins = boardDs.joins;
          }
          this._addDataSource(newBoardDs);
        }
      }
      this._bindEventNetworkBoard();
      this.safelyDetectChanges();
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  @HostListener('window:resize', ['$event'])
  protected onResize(event) {
    $('.sys-create-board-top-panel').css('height', '100%').css('height', '-=1px');
  }

  public getCntDataSources(): number {
    return this._boardDataSources.length;
  }

  public isInvalidate(): boolean {
    return (
      0 === this._boardDataSources.length ||
      this._relations.some(
        (item) => isNullOrUndefined(item.ui.sourceField) || isNullOrUndefined(item.ui.targetField),
      ) ||
      this.datasourcesSchemaInvalid()
    );
  }

  public getData(): {
    dataSources: Datasource[];
    boardDataSources: BoardDataSource[];
    relations: BoardDataSourceRelation[];
  } {
    return {
      dataSources: this._dataSources,
      boardDataSources: this._boardDataSources,
      relations: this._relations,
    };
  }

  public showPopupAddDataSource() {
    this._dataSourceSelectComp.open(
      this.workspaceId,
      this._boardDataSources.map((item) => item.id),
    );
  }

  public toggleGuide(setVisible?: string) {
    switch (setVisible) {
      case 'SHOW':
        this.isShowMultiDsGuide = true;
        break;
      case 'HIDE':
        this.isShowMultiDsGuide = false;
        break;
      default:
        this.isShowMultiDsGuide = !this.isShowMultiDsGuide;
    }

    this.safelyDetectChanges();
    this.animateGuide();
  }

  public animateGuide() {
    if (this.isShowMultiDsGuide) {
      localStorage.setItem('VIEW_MULTI_DS_GUIDE', 'YES');
      const $guideLine = $(this._guideLine.nativeElement);
      $guideLine.delay(500).animate({ paddingLeft: '270' }, 1000, () => {
        $guideLine.delay(500).animate({ paddingLeft: '0' }, 1000, () => {
          this.animateGuide();
        });
      });
    }
  }

  public onEditRelationMode() {
    this._network.addEdgeMode();
    this.isRelationEditMode = true;

    const isViewGuide: string = localStorage.getItem('VIEW_MULTI_DS_GUIDE');
    this._isAlreadyViewGuide = 'YES' === isViewGuide;
    this._isAlreadyViewGuide || this.toggleGuide('SHOW');

    this._clearSelection();
    this.safelyDetectChanges();

    this.onChange.emit({ isShowButtons: !this.isRelationEditMode });

    $('.sys-create-board-top-panel').css('height', '100%').css('height', '-=1px');
  }

  public offEditRelationMode() {
    this.isRelationEditMode = false;
    this._network && this._network.disableEditMode();
    this.toggleGuide('HIDE');
    this.safelyDetectChanges();

    this.onChange.emit({ isShowButtons: !this.isRelationEditMode });
  }

  public changeDataSources(data: { add: Datasource[]; remove: string[] }) {
    if (!this.isRenderedNetwork) {
      this._renderNetworkBoard();
      this._bindEventNetworkBoard();
    }

    if (0 < data.add.length) {
      const ids: string[] = this._nodes ? this._nodes.getIds() : [];
      data.add.forEach((item) => {
        const idIdx: number = ids.indexOf(item.id);
        if (-1 === idIdx) {
          const newDataSource: BoardDataSource = convertDsToMetaDs(item);
          newDataSource.type = 'default';
          if (ConnectionType.LINK.toString() === newDataSource.connType) {
            this.candidateIngestionList.push(newDataSource);
          } else {
            this._dataSources.push(item);
            this._addDataSource(convertDsToMetaDs(item));
          }
        }
      });
    }

    if (data.remove) {
      data.remove.forEach((id) => {
        this._removeDataSource(id);
      });
    }

    this._showDataIngestion();

    if (1 < this._boardDataSources.length) {
      this.isPossibleSettingRel = true;
      this.onEditRelationMode();
    } else {
      this.isPossibleSettingRel = false;
      this.offEditRelationMode();
    }
  }

  public zoomInNetwork() {
    let zoom: number = this._network.getScale() + 0.2;
    2.6 < zoom && (zoom = 2.6);
    this._network.moveTo({ scale: zoom });
  }

  public zoomOutNetwork() {
    let zoom: number = this._network.getScale() - 0.2;
    1 > zoom && (zoom = 1);
    this._network.moveTo({ scale: zoom });
  }

  public fitNetwork() {
    this._network.fit();
  }

  public cancelAddRelation(relationId: string) {
    this._removeRelation(relationId);
    this.onEditRelationMode();
  }

  public closeEssentialFilterPopup() {
    this.isShowDataIngestion = false;
    const cntNodes: number = this._nodes.getIds().length;
    if (0 === cntNodes) {
      this.isRenderedNetwork = false;

      this._destroyNetworkBoard();
    }
  }

  public finishDataIngestion(tempDatasource: {
    id: string;
    info: Datasource;
    dataSourceId: string;
    filters: Filter[];
  }) {
    this.isShowDataIngestion = false;
    this.safelyDetectChanges();

    const dataSource: BoardDataSource = convertDsToMetaDs(tempDatasource.info);
    dataSource.type = 'default';
    dataSource.metaDataSource = tempDatasource.info;
    dataSource.uiFilters = tempDatasource.filters;
    dataSource['temporaryId'] = tempDatasource.id;
    dataSource['orgDataSource'] = this.ingestionTargetDatasource;

    const targetIdx: number = this._dataSources.findIndex((item) => item.id === dataSource.id);
    if (-1 < targetIdx) {
      this._dataSources[targetIdx] = tempDatasource.info;
    } else {
      this._dataSources.push(tempDatasource.info);
    }
    this._addDataSource(dataSource);

    this._showDataIngestion();
  }

  private datasourcesSchemaInvalid(): boolean {
    const datasources = this._boardDataSources;
    let joins = [];

    datasources.forEach((ds) => {
      if (ds.joins) {
        joins = [...joins, ...ds.joins];
      }
    });

    const sameJoinInDatasources = datasources.some((ds) => {
      return joins.some((join) => {
        return join.id === ds.id;
      });
    });

    if (sameJoinInDatasources) {
      this.alertPrimeService.error(this.translateService.instant('msg.board.create.error.same.join'));
      return true;
    }

    return false;
  }

  private _showDataIngestion() {
    if (0 < this.candidateIngestionList.length) {
      this.ingestionTargetDatasource = this.candidateIngestionList.pop();
      this.isShowDataIngestion = true;
    }
  }

  private _renderNetworkBoard() {
    this.isRenderedNetwork = true;
    this.safelyDetectChanges();

    this._nodes = new vis.DataSet([]);
    this._edges = new vis.DataSet([]);

    this._network = new vis.Network(
      document.querySelector('.sys-ds-board'),
      {
        nodes: this._nodes,
        edges: this._edges,
      },
      {
        interaction: { hover: true },
        manipulation: { enabled: true },
        nodes: {
          margin: {
            top: 15,
            bottom: 15,
            left: 5,
            right: 5,
          },
          shadow: true,
          borderWidth: 1,
          scaling: {
            min: 30,
            max: 30,
          },
          size: 30,
          shape: 'ellipse',
          font: { size: 14 },
          labelHighlightBold: false,
          color: {
            border: '#fff',
            background: '#fff',
            highlight: { border: 'var(--color-primary)', background: '#f0f4ff' },
            hover: { border: 'var(--color-primary)', background: '#f0f4ff' },
          },
          heightConstraint: {
            minimum: 45,
          },
          widthConstraint: {
            minimum: 150,
            maximum: 150,
          },
        },
        edges: {
          width: 40,
          color: { color: '#d0d1d8', highlight: '#c1cef1', hover: '#b7b9c2', inherit: 'from', opacity: 1.0 },
          font: { color: '#343434', size: 12 },
          smooth: false,
          length: 200,
        },
        physics: {
          solver: 'forceAtlas2Based',
          forceAtlas2Based: {
            avoidOverlap: 1,
          },
        },
      },
    );

    this._network.moveTo({ scale: 1.6 });
  }

  private _bindEventNetworkBoard() {
    this._network.on('click', (params) => {
      if (1 === params.nodes.length) {
        this._selectDataSource(params.nodes[0]);
      } else if (1 === params.edges.length) {
        this._selectRelation(params.edges[0]);
      } else if (this.selectedRelation) {
        this._clearSelection();
      }
    });

    this._edges.on('*', (event, props) => {
      if ('add' === event) {
        this._addRelationByEdgeId(props.items[0]);
        setTimeout(() => {
          this.onEditRelationMode();
        }, 100);
      }
    });
  }

  private _unbindEventNetworkBoard() {
    this._network.off('click');
    this._edges.off('*');
  }

  private _destroyNetworkBoard() {
    if (null !== this._network) {
      this._network.destroy();
      this._network = null;
    }
  }

  private _addDataSource(ds: BoardDataSource) {
    this.isRenderedNetwork = true;

    const targetIdx: number = this._boardDataSources.findIndex((item) => item.id === ds.id);

    if (-1 < targetIdx) {
      this._boardDataSources[targetIdx] = ds;
    } else {
      this._boardDataSources.push(ds);

      const isKorean: boolean = this._checkKorean(ds.name);
      if (360 < (isKorean ? 2 * ds.name.length : ds.name.length) * 14) {
        const nodeName: string = isKorean ? ds.name.substr(0, 11) + '...' : ds.name.substr(0, 22) + '...';
        this._nodes.add({ id: ds.id, label: nodeName, title: ds.name });
      } else {
        this._nodes.add({ id: ds.id, label: ds.name, title: ds.name });
      }

      if (1 === this._nodes.getIds().length) {
        this._network.focus(ds.id);
      }
    }

    this.onChange.emit({ isDenyNext: this.isInvalidate() });
  }

  private _removeDataSource(dataSourceId: string) {
    const dsRemoveIdx: number = this._dataSources.findIndex((item) => item.id === dataSourceId);
    dsRemoveIdx && this._dataSources.splice(dsRemoveIdx, 1);

    this._nodes.remove({ id: dataSourceId });

    const removeIdx = this._boardDataSources.findIndex((item) => item.id === dataSourceId);
    -1 < removeIdx && this._boardDataSources.splice(removeIdx, 1);

    _.cloneDeep(this._relations).forEach((item) => {
      if (item.ui.source.id === dataSourceId || item.ui.target.id === dataSourceId) {
        this._removeRelation(item.id);
      }
    });

    this.isPossibleSettingRel = 1 < this._boardDataSources.length;

    const cntNodes: number = this._nodes.getIds().length;
    if (0 === cntNodes) {
      this.isRenderedNetwork = false;

      this._destroyNetworkBoard();
    } else if (1 === cntNodes) {
      this._network.focus(this._nodes.getIds()[0]);
    }

    this.onChange.emit({ isDenyNext: this.isInvalidate() });
  }

  private _selectDataSource(dsId: string) {
    if (!this.isRelationEditMode) {
      this._clearSelection();
      this.selectedDataSource = this._boardDataSources.find((item) => item.id === dsId);
      this.broadCaster.broadcast('CREATE_BOARD_SELECT_DS', { dataSource: this.selectedDataSource });
    }
  }

  private _addEdgeByRelation(relInfo: BoardDataSourceRelation) {
    this._edges.add({ id: relInfo.id, from: relInfo.ui.source.id, to: relInfo.ui.target.id });
    this._relations.push(relInfo);

    this.onChange.emit({ isDenyNext: this.isInvalidate() });
  }

  private _addRelationByEdgeId(edgeId: string) {
    const data = this._edges.get(edgeId);

    const isDuplicate: boolean = this._relations.some((rel: BoardDataSourceRelation) => {
      return (
        (rel.ui.source.id === data.from || rel.ui.target.id === data.from) &&
        (rel.ui.source.id === data.to || rel.ui.target.id === data.to)
      );
    });

    if (data.from === data.to || isDuplicate) {
      this._edges.remove({ id: edgeId });
    } else {
      const relInfo: BoardDataSourceRelation = createBoardDataSourceRelation();
      relInfo.id = data.id;
      relInfo.ui.source = this._boardDataSources.find((item) => item.id === data.from);
      relInfo.ui.target = this._boardDataSources.find((item) => item.id === data.to);
      this._relationPopComp.addRelation(relInfo);
    }

    this.onChange.emit({ isDenyNext: this.isInvalidate() });
  }

  private _removeRelation(relationId: string) {
    this._clearSelection();

    this._edges.remove({ id: relationId });

    const removeIdx = this._relations.findIndex((item) => item.id === relationId);
    -1 < removeIdx && this._relations.splice(removeIdx, 1);

    this.onChange.emit({ isDenyNext: this.isInvalidate() });
  }

  private _selectRelation(edgeId: string) {
    if (this.selectedRelation && this.selectedRelation.id === edgeId) {
      this._removeRelation(edgeId);
      if (this.isRelationEditMode) {
        setTimeout(() => {
          this.onEditRelationMode();
        }, 100);
      }
    } else {
      this._clearSelection();
      const relInfo: BoardDataSourceRelation = this._relations.find((item) => item.id === edgeId);
      this._edges.update({ id: relInfo.id, label: 'delete' });
      this._network.selectNodes([relInfo.ui.source.id, relInfo.ui.target.id]);
      this.selectedRelation = relInfo;
      this.broadCaster.broadcast('CREATE_BOARD_SELECT_REL', { relation: relInfo });
    }
  }

  private _clearSelection() {
    this.selectedRelation && this._edges.update({ id: this.selectedRelation.id, label: undefined });
    this.selectedDataSource = undefined;
    this.selectedRelation = undefined;
    this._network.unselectAll();
  }

  private _checkKorean(objStr): boolean {
    let isKorean = false;
    for (let idx = 0; idx < objStr.length; idx++) {
      if (
        (objStr.charCodeAt(idx) > 0x3130 && objStr.charCodeAt(idx) < 0x318f) ||
        (objStr.charCodeAt(idx) >= 0xac00 && objStr.charCodeAt(idx) <= 0xd7a3)
      ) {
        isKorean = true;
        break;
      }
    }
    return isKorean;
  }
}
