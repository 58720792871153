import { FactoryProvider, Injector, ValueProvider } from '@angular/core';
import { PipelinePermissionEntity, PermissionService, WorkflowPermissionAction } from '@selfai-platform/shared';
import { provideMainMenuChunkFactory } from '@selfai-platform/shell';

export function provideWorkflowMainMenuChunk(): FactoryProvider | ValueProvider {
  return provideMainMenuChunkFactory((injector: Injector) => {
    const permissionService = injector.get(PermissionService);

    if (
      !permissionService.checkPermission({
        entity: PipelinePermissionEntity.Workflows,
        action: WorkflowPermissionAction.List,
      })
    ) {
      return null;
    }

    return {
      id: 'engine',
      label: 'shell.menu.knowledge-engine',
      state: { sort: 300 },
      items: [
        {
          label: 'shell.menu.workflows',
          icon: 'pi pi-fw pi-sitemap',
          routerLink: '/pipeline',
        },
      ],
    };
  });
}
