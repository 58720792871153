import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';

import * as _ from 'lodash';

import { Role, RoleSet, RoleSetScope, Workspace } from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { EventBroadcaster } from '../../../common/event/event.broadcaster';
import { PermissionService } from '../../../user/service/permission.service';
import { WorkspaceService } from '../../service/workspace.service';

import { PermissionSchemaSetComponent } from './permission-schema-set.component';

@Component({
  selector: 'app-permission-schema-change',
  templateUrl: './permission-schema-change.component.html',
})
export class PermissionSchemaChangeComponent extends AbstractComponent implements OnInit, OnDestroy {
  @ViewChild(PermissionSchemaSetComponent, { static: true })
  private _permissionSchemaSetComp: PermissionSchemaSetComponent;

  private _workspace: Workspace;

  public isShow = false;
  public isShowRoleSetList = false;

  public currentRoleSet: RoleSet;

  public roleSetList: RoleSet[] = [];
  public selectedRoleSetInfo: RoleSet;
  public selectedRoleSetDetail: RoleSet;

  public isPermSchemaEditMode = false;

  constructor(
    private broadCaster: EventBroadcaster,
    protected permissionService: PermissionService,
    protected workspaceService: WorkspaceService,
    protected element: ElementRef,
    protected injector: Injector,
  ) {
    super(element, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public init(workspace: Workspace, currentRoleSet: RoleSet) {
    this._workspace = workspace;
    this.currentRoleSet = currentRoleSet;

    this.isShowRoleSetList = false;
    this.roleSetList = [];
    this.selectedRoleSetInfo = null;
    this.selectedRoleSetDetail = null;

    this._loadRoleSets().then(() => {
      this.changeDetect.markForCheck();
      this.isShow = true;
    });
  }

  public close() {
    this.isShow = false;
  }

  public done() {
    if (this.selectedRoleSetDetail.id) {
      this._changeRoleSetInWorkspace();
    } else {
      const params = this.selectedRoleSetDetail.getRequestParam();
      this.permissionService.createRoleset(params).then((result) => {
        this.selectedRoleSetDetail = result;
        this._changeRoleSetInWorkspace();
      });
    }
  }

  public getRoleSetName(roleSet: RoleSet) {
    return RoleSetScope.PRIVATE === roleSet.scope
      ? this.translateService.instant('msg.permission.ui.custom-schema')
      : roleSet.name;
  }

  public clickRoleSelectbox(role: Role) {
    role['isOpenNewRoleOpts'] = !role['isOpenNewRoleOpts'];
    this.currentRoleSet.roles.forEach((item: Role) => {
      if (item.name !== role.name) {
        item['isOpenNewRoleOpts'] = false;
      }
    });
  }

  public selectRoleSet(item: RoleSet) {
    if (this.currentRoleSet.name !== item.name) {
      this.selectedRoleSetInfo = item;
      if (RoleSetScope.PRIVATE === item.scope) {
        this.permissionService.getWorkspaceCustomRoleSet(this._workspace.id).then((wsCustomResult: RoleSet) => {
          if (wsCustomResult) {
            this.permissionService
              .getRolesetDetail(wsCustomResult.id)
              .then((itemResult) => {
                const customRoleSet: RoleSet = itemResult;
                customRoleSet.scope = RoleSetScope.PRIVATE;
                this.selectedRoleSetDetail = customRoleSet;
                this.isPermSchemaEditMode = true;
              })
              .catch((err) => this.commonExceptionHandler(err));
          } else {
            const customRoleSet: RoleSet = new RoleSet();
            customRoleSet.name = this._workspace.id;
            customRoleSet.description = this._workspace.name;
            this.selectedRoleSetDetail = customRoleSet;
            this.isPermSchemaEditMode = true;
          }
        });
      } else {
        this.permissionService
          .getRolesetDetail(item.id)
          .then((result) => {
            this.selectedRoleSetDetail = result;
            this.selectedRoleSetDetail.scope = RoleSetScope.PUBLIC;
            this.isPermSchemaEditMode = false;
          })
          .catch((err) => this.commonExceptionHandler(err));
      }
    }
  }

  public onClickOpenPermissionSchemaSet() {
    const cloneRoleSet: RoleSet = _.cloneDeep(this.selectedRoleSetDetail);
    this._permissionSchemaSetComp.init(cloneRoleSet, true, true);
  }

  public afterUpdatePermissionRoles(roleSet: RoleSet) {
    this.permissionService.getRolesetDetail(roleSet.id).then((result) => {
      if (result) {
        const customRoleSet: RoleSet = result;
        customRoleSet.scope = RoleSetScope.PRIVATE;
        this.selectedRoleSetDetail = customRoleSet;
        this.isPermSchemaEditMode = true;
      }
    });
  }

  private _loadRoleSets(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.loadingShow();
      const params = {
        page: this.page.page,
        size: 1000,
        sort: this.page.sort,
        scope: RoleSetScope.PUBLIC,
      };
      this.permissionService
        .getRolesets(params)
        .then((result) => {
          if (result && result['_embedded']) {
            let roleSetList: RoleSet[] = result['_embedded']['roleSets'];
            roleSetList = roleSetList.filter((item) => item.name !== this.currentRoleSet.name);
            if (RoleSetScope.PUBLIC === this.currentRoleSet.scope) {
              const privateRoleSet: RoleSet = new RoleSet();
              privateRoleSet.name = this.translateService.instant('msg.permission.ui.custom-schema');
              roleSetList.push(privateRoleSet);
            }
            this.roleSetList = roleSetList;
          }
          resolve();
          this.loadingHide();
        })
        .catch((err) => {
          this.commonExceptionHandler(err);
          reject();
        });
    });
  }

  private _changeRoleSetInWorkspace() {
    try {
      this.loadingShow();

      const changeRoleMapper = {};
      this.currentRoleSet.roles.forEach((item: Role) => {
        if (item['newRole']) {
          changeRoleMapper[item.name] = item['newRole']['name'];
        } else {
          throw '모든 Role은 변경할 Role을 지정해주어야 합니다.';
        }
      });

      this.workspaceService
        .changeRoleSetInWorkspace(
          this._workspace.id,
          this.currentRoleSet.name,
          this.selectedRoleSetDetail.name,
          changeRoleMapper,
        )
        .then(() => {
          this.broadCaster.broadcast('CHANGE_WORKSPACE_PERMS_SCHEMA', {
            id: this.selectedRoleSetDetail.id,
          });
          this.loadingHide();
          this.close();
        })
        .catch((err) => this.commonExceptionHandler(err));
    } catch (err) {
      this.alertPrimeService.error(err);
      this.loadingHide();
    }
  }
}
