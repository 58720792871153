import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { AccountSettingsPageComponent } from './account-settings-page.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    RouterModule.forChild([
      {
        path: '',
        pathMatch: 'full',
        component: AccountSettingsPageComponent,
      },
    ]),
  ],
  declarations: [AccountSettingsPageComponent],
  exports: [AccountSettingsPageComponent],
})
export class AccountSettingsModule {}
