import { isNull, isUndefined } from 'util';

import * as _ from 'lodash';

import { OptionGenerator } from '../utils/option-generator';

import {
  Axis,
  AxisDefaultColor,
  AxisLabelRotate,
  AxisLabelType,
  AxisType,
  BaseOption,
  ChartAxisLabelType,
  UIChartAxis,
  UIChartAxisLabelValue,
  UIOption,
  UIOrient,
} from '@selfai-platform/bi-domain';
import { FormatOptionConverter } from './format-option-converter';

import UI = OptionGenerator.UI;

export class AxisOptionConverter {
  public static axisMinMax: object = {
    xAxis: { min: 0, max: 0 },
    yAxis: { min: 0, max: 0 },
    subAxis: { min: 0, max: 0 },
  };

  public static convertAxis(chartOption: BaseOption, uiOption: UIOption, axisType: AxisType, data?: any): BaseOption {
    chartOption = this.convertAxisName(chartOption, uiOption, axisType);

    chartOption = this.convertAxisNameShow(chartOption, uiOption, axisType);

    chartOption = this.convertAxisLabelShow(chartOption, uiOption, axisType);

    chartOption = this.convertAxisLabelFormatter(chartOption, uiOption, axisType);

    chartOption = this.convertAxisLabelRotate(chartOption, uiOption, axisType);

    chartOption = this.convertAxisLabelMaxLength(chartOption, uiOption, axisType);

    chartOption = this.convertAxisMinMax(chartOption, uiOption, axisType, data);

    chartOption = this.convertAxisAutoScale(chartOption, uiOption, axisType, data);

    chartOption = this.convertAxisBackground(chartOption, uiOption, axisType);

    return chartOption;
  }

  public static convertAxisNameShow(chartOption: BaseOption, uiOption: UIOption, axisType: AxisType): BaseOption {
    const axisOption: UIChartAxis[] = this.getAxisOption(uiOption, axisType);

    const axis: Axis[] = chartOption[axisType] as Axis[];

    _.each(axis, (option, index) => {
      const isShow: boolean = axisOption[index].showName;

      option.name = !isShow ? '' : '' === option.name ? option.axisName : option.name;
    });

    return chartOption;
  }

  public static convertAxisName(chartOption: BaseOption, uiOption: UIOption, axisType: AxisType): BaseOption {
    const axisOption: UIChartAxis[] = this.getAxisOption(uiOption, axisType);

    const axis: Axis[] = chartOption[axisType] as Axis[];

    _.each(axis, (option, index) => {
      const name: string = axisOption[index].customName ? axisOption[index].customName : axisOption[index].name;

      option.name = _.isEmpty(name) ? option.axisName : name;
    });

    return chartOption;
  }

  public static convertAxisLabelShow(chartOption: BaseOption, uiOption: UIOption, axisType: AxisType): BaseOption {
    const axisOption: UIChartAxis[] = this.getAxisOption(uiOption, axisType);

    const axis: Axis[] = chartOption[axisType] as Axis[];

    _.each(axis, (option, index) => {
      const isShow: boolean = axisOption[index].showLabel;

      option.axisLabel.show = isShow;
      option.axisTick.show = isShow;
      option.axisLine.show = isShow;
    });

    return chartOption;
  }

  public static convertAxisLabelRotate(chartOption: BaseOption, uiOption: UIOption, axisType: AxisType): BaseOption {
    const axisOption: UIChartAxis[] = this.getAxisOption(uiOption, axisType);

    const axis: Axis[] = chartOption[axisType] as Axis[];

    _.each(axis, (option, index) => {
      const rotate: AxisLabelRotate =
        axisOption[index].label && axisOption[index].label['rotation']
          ? axisOption[index].label['rotation']
          : AxisLabelRotate.HORIZONTAL;

      option.axisLabel.rotate = rotate;
    });

    return chartOption;
  }

  public static convertAxisLabelFormatter(chartOption: BaseOption, uiOption: UIOption, axisType: AxisType): BaseOption {
    const axisOption: UIChartAxis[] = this.getAxisOption(uiOption, axisType);

    const axis: Axis[] = chartOption[axisType] as Axis[];

    _.each(axis, (option: Axis, index) => {
      if (uiOption['align'] && UIOrient.HORIZONTAL == uiOption['align']) {
        if (_.eq(AxisType.X, axisType)) {
          if (!axisOption[index].label) {
            axisOption[index].label = UI.AxisLabel.axisLabelForValue(ChartAxisLabelType.VALUE);
          } else {
            if (_.eq(axisOption[index].label.type, ChartAxisLabelType.CATEGORY)) {
              uiOption.yAxis.label = _.cloneDeep(axisOption[index].label);

              axisOption[index].label = UI.AxisLabel.axisLabelForValue(ChartAxisLabelType.VALUE);
            }
          }
        }
      }

      if (
        !_.eq(AxisLabelType.SUBCOLUMN, <UIChartAxisLabelValue>axisOption[index].mode) &&
        (!(<UIChartAxisLabelValue>axisOption[index].label) ||
          !_.eq((<UIChartAxisLabelValue>axisOption[index].label).type, AxisType.VALUE))
      ) {
        return chartOption;
      }

      const axisFormat = <UIChartAxisLabelValue>axisOption[index].label
        ? (<UIChartAxisLabelValue>axisOption[index].label).format
        : null;

      const format = axisFormat ? axisFormat : uiOption.valueFormat;

      const baseline: number = <number>axisOption[index].baseline;

      if (format) {
        option.axisLabel.formatter = (params): any => {
          return FormatOptionConverter.getFormatValue(params, format, baseline);
        };
      }
    });

    return chartOption;
  }

  public static convertAxisMinMax(
    chartOption: BaseOption,
    uiOption: UIOption,
    axisType: AxisType,
    data?: any,
  ): BaseOption {
    const axisOption: UIChartAxis[] = this.getAxisOption(uiOption, axisType);

    const axis: Axis[] = chartOption[axisType] as Axis[];

    _.each(axis, (option: Axis, index) => {
      if (
        <UIChartAxisLabelValue>axisOption[index].label &&
        _.eq((<UIChartAxisLabelValue>axisOption[index].label).type, AxisType.VALUE) &&
        axisOption[index].grid &&
        !axisOption[index].grid.autoScaled
      ) {
        if (axisOption[index].grid.min && axisOption[index].grid.min != 0) {
          option.min = axisOption[index].grid.min;
        }

        if (axisOption[index].grid.max && axisOption[index].grid.max != 0) {
          option.max = axisOption[index].grid.max;
        }
      }
    });

    return chartOption;
  }

  public static convertAxisAutoScale(
    chartOption: BaseOption,
    uiOption: UIOption,
    axisType: AxisType,
    data?: any,
  ): BaseOption {
    const axisOption: UIChartAxis[] = this.getAxisOption(uiOption, axisType);

    const axis: Axis[] = chartOption[axisType] as Axis[];

    _.each(axis, (option: Axis, index) => {
      if (
        <UIChartAxisLabelValue>axisOption[index].label &&
        _.eq((<UIChartAxisLabelValue>axisOption[index].label).type, AxisType.VALUE) &&
        axisOption[index].grid
      ) {
        let min = null;
        let max = null;
        let calculateMin = null;
        if (data.categories && data.categories.length > 0) {
          _.each(data.categories, (category) => {
            _.each(category.value, (value) => {
              if (min == null || value < min) {
                min = value;
              }
              if (max == null || value > max) {
                max = value;
              }
            });
          });
          calculateMin = Math.ceil(min - (max - min) * 0.05);

          max = max == null ? 0 : max;
        } else {
          calculateMin = Math.ceil(data.info.minValue - (data.info.maxValue - data.info.minValue) * 0.05);
          min = data.info.minValue;

          max = data.info.maxValue;
        }

        AxisOptionConverter.axisMinMax[axisType].min = min;
        AxisOptionConverter.axisMinMax[axisType].max = max;

        let baseline = 0;
        if (axisOption[index].baseline && axisOption[index].baseline != 0) {
          baseline = <number>axisOption[index].baseline;
        }

        if (baseline == 0 && axisOption[index].grid.autoScaled) {
          delete option.min;
          delete option.max;
          option.scale = true;
        } else {
          delete option.scale;
        }
      }
    });

    return chartOption;
  }

  public static convertAxisDefault(chartOption: BaseOption, uiOption: UIOption, axisType: AxisType): BaseOption {
    const axis: Axis[] = chartOption[axisType] as Axis[];

    _.each(axis, (option) => {
      if (!option.axisLine.lineStyle) option.axisLine = { lineStyle: {} };

      option.axisLine.lineStyle['color'] = AxisDefaultColor.AXIS_LINE_COLOR.toString();

      option.axisLabel.color = AxisDefaultColor.LABEL_COLOR.toString();

      option.nameTextStyle.color = AxisDefaultColor.LABEL_COLOR.toString();

      if (!option.nameTextStyle.padding) option.nameTextStyle.padding = [];

      if (AxisType.X == axisType) {
        option.nameTextStyle.padding = [10, 10, 0, 0];
        if (_.find(uiOption.chartZooms, { orient: 'VERTICAL' })) {
          if (!option.splitLine || !option.splitLine.lineStyle) option.splitLine = { lineStyle: {} };
          option.splitLine.lineStyle['color'] = AxisDefaultColor.LINE_COLOR.toString();
        }
      } else {
        option.nameTextStyle.padding = [0, 0, 10, 10];
        if (_.find(uiOption.chartZooms, { orient: 'HORIZONTAL' })) {
          if (!option.splitLine || !option.splitLine.lineStyle) option.splitLine = { lineStyle: {} };
          option.splitLine.lineStyle['color'] = AxisDefaultColor.LINE_COLOR.toString();
        }
      }
    });

    return chartOption;
  }

  public static convertAxisLabelMaxLength(chartOption: BaseOption, uiOption: UIOption, axisType: AxisType): BaseOption {
    const axisOption: UIChartAxis[] = this.getAxisOption(uiOption, axisType);

    const axis: Axis[] = chartOption[axisType] as Axis[];

    let maxLength: number;

    _.each(axis, (option, index) => {
      if (!axisOption[index].label) return;

      maxLength = axisOption[index].label['maxLength'];

      if (
        ChartAxisLabelType.CATEGORY == axisOption[index].label.type &&
        !isNull(maxLength) &&
        !isUndefined(maxLength)
      ) {
        option.data = option.data.map((item) => {
          if (typeof item == 'string') {
            return item.substr(0, maxLength) + (item.length > maxLength ? '...' : '');
          } else return item;
        });
      }
    });

    return chartOption;
  }

  public static convertAxisBackground(chartOption: BaseOption, uiOption: UIOption, axisType: AxisType): BaseOption {
    const axisOption: UIChartAxis[] = this.getAxisOption(uiOption, axisType);

    const axis: Axis[] = chartOption[axisType] as Axis[];

    _.each(axis, (option, index) => {
      if (axisOption[index].label && ChartAxisLabelType.CATEGORY == axisOption[index].label.type) {
        if (!option.splitArea) {
          option.splitArea = {};
          if (!option.splitArea.areaStyle) option.splitArea.areaStyle = {};
        }

        if (!axisOption[index].background) {
          option.splitArea.show = false;
        } else {
          option.splitArea.show = true;

          option.splitArea.areaStyle.color = ['#ffffff', axisOption[index].background.color];

          option.splitArea.areaStyle.opacity = axisOption[index].background.transparency;
        }
      }
    });

    return chartOption;
  }

  public static getAxisOption(uiOption: UIOption, axisType: AxisType): UIChartAxis[] {
    const axisOption: UIChartAxis[] = _.filter(
      _.compact(_.concat(uiOption.xAxis, uiOption.yAxis, uiOption.secondaryAxis)),
      (option) => {
        if (axisType == AxisType.X) {
          return _.eq(option.mode, AxisLabelType.ROW) || _.eq(option.mode, AxisLabelType.SUBROW);
        } else {
          return _.eq(option.mode, AxisLabelType.COLUMN) || _.eq(option.mode, AxisLabelType.SUBCOLUMN);
        }
      },
    );

    return axisOption;
  }
}
