import { ChangeDetectionStrategy, Component, ElementRef, Injector, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable, takeUntil } from 'rxjs';

import {
  BoardWidgetOptions,
  Dashboard,
  DashboardDomainService,
  LayoutMode,
  LayoutWidgetInfo,
  Widget,
} from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';

import { AbstractComponent } from '../../../common/component/abstract.component';

@Component({
  selector: 'dashboard-widget',
  templateUrl: './dashboard.widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class DashboardWidgetComponent extends AbstractComponent implements OnDestroy {
  @Input()
  public widget: Widget;
  @Input()
  public widgetOpts: BoardWidgetOptions;
  @Input()
  public layoutMode: LayoutMode;
  @Input()
  public widgetInfo: LayoutWidgetInfo;

  @Input() readonly = false;

  @Input() isDashboardLoaded = false;

  public isShowTitle = false;
  public isWidget = false;
  public isTextWidget = false;
  public isPageWidget = false;
  public isFilterWidget = false;

  dashboard$: Observable<Dashboard>;

  constructor(
    protected elementRef: ElementRef,
    protected activatedRoute: ActivatedRoute,
    protected injector: Injector,
    private readonly dashboardDomainService: DashboardDomainService,
    private readonly destroy$: DestroyService,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit(): void {
    if (this.widget && this.widgetOpts && this.layoutMode && this.widgetInfo) {
      if (!this.isDashboardLoaded) {
        this.dashboardDomainService.loadDashboard(this.widget.dashBoardId).pipe(takeUntil(this.destroy$)).subscribe();
      }

      this.dashboard$ = this.dashboardDomainService.getDashboard(this.widget.dashBoardId);
      this.init(this.widget, this.widgetOpts, this.layoutMode, this.widgetInfo);
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public init(widget: Widget, widgetOpts: BoardWidgetOptions, layoutMode: LayoutMode, widgetInfo: LayoutWidgetInfo) {
    super.ngOnInit();
    this.widget = widget;
    this.widgetOpts = widgetOpts;
    this.layoutMode = layoutMode;
    switch (widget.type) {
      case 'page':
        this.isPageWidget = true;
        break;
      case 'text':
        this.isTextWidget = true;
        break;
      case 'filter':
        this.isFilterWidget = true;
        break;
    }
    this.isWidget = true;
    widgetInfo && (this.isShowTitle = widgetInfo.title);
    this.safelyDetectChanges();
  }

  public getWidgetId(): string {
    return this.widget.id;
  }

  public getWidget(): Widget {
    return this.widget;
  }

  public getBoardWidgetOpts(): BoardWidgetOptions {
    return this.widgetOpts;
  }

  public getLayoutMode(): LayoutMode {
    return this.layoutMode;
  }
}
