import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  BaseOption,
  CHART_STRING_DELIMITER,
  ChartType,
  Pivot,
  Position,
  SeriesType,
  ShelveFieldType,
  ShelveType,
  SymbolType,
  UIChartDataLabelDisplayType,
  UIChartFormat,
  UIOption,
} from '@selfai-platform/bi-domain';

import { HeatmapSeriesOption } from 'echarts';
import { TooltipComponentOption } from 'echarts/components';
import * as _ from 'lodash';
import { FormatOptionConverter } from '../../converters';
import { provideBaseChartServices } from '../../services';
import { OptionGenerator } from '../../utils';
import { BaseChart } from '../base-chart';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'heatmap-chart',
  template: '',
  styleUrls: ['./chart-host.component.scss'],
  providers: [...provideBaseChartServices()],
})
export class HeatMapChartComponent extends BaseChart implements OnInit, AfterViewInit {
  public override isValid(shelve: Pivot): boolean {
    return (
      (this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.DIMENSION) +
        this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.TIMESTAMP) >
        0 ||
        this.getFieldTypeCount(shelve, ShelveType.ROWS, ShelveFieldType.DIMENSION) +
          this.getFieldTypeCount(shelve, ShelveType.ROWS, ShelveFieldType.TIMESTAMP) >
          0) &&
      (this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.MEASURE) > 0 ||
        this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.CALCULATED) > 0) &&
      this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.MEASURE) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.CALCULATED) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.ROWS, ShelveFieldType.MEASURE) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.ROWS, ShelveFieldType.CALCULATED) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.DIMENSION) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.TIMESTAMP) == 0
    );
  }

  public override draw(isKeepRange?: boolean): void {
    let colNameList: string[] = [];

    this.data.columns.map((column) => {
      const nameList: string[] = _.split(column.name, CHART_STRING_DELIMITER);

      let sliceList: string[] = [];

      if (0 == nameList.indexOf(this.pivot.aggregations[0].alias)) {
        sliceList = _.slice(nameList, 0, this.fieldInfo.rows.length);
      } else {
        sliceList = _.slice(nameList, this.fieldInfo.cols.length);
      }
      colNameList.push(_.join(sliceList, CHART_STRING_DELIMITER));
    });

    colNameList = _.uniq(colNameList);

    this.pivotInfo = { cols: colNameList, rows: colNameList, aggs: this.fieldInfo.aggs };

    super.draw(isKeepRange);
  }

  protected override initOption(): BaseOption {
    return {
      type: ChartType.HEATMAP,
      grid: [OptionGenerator.Grid.bothMode(10, 0, 0, 0, false, true)],
      legend: OptionGenerator.Legend.custom(false, false, Position.LEFT, SymbolType.CIRCLE, '100%', 20, 5),
      xAxis: [OptionGenerator.Axis.categoryAxis(Position.INSIDE, null, false, true, true, true)],
      yAxis: [OptionGenerator.Axis.categoryAxis(Position.INSIDE, null, true, true, true, true)],
      dataZoom: [
        OptionGenerator.DataZoom.horizontalDataZoom(),
        OptionGenerator.DataZoom.verticalDataZoom(),
        OptionGenerator.DataZoom.verticalInsideDataZoom(),
        OptionGenerator.DataZoom.horizontalInsideDataZoom(),
      ],
      tooltip: OptionGenerator.Tooltip.itemTooltip(),
      toolbox: OptionGenerator.Toolbox.hiddenToolbox(),
      brush: OptionGenerator.Brush.selectBrush(),
      series: [],
    };
  }

  protected override convertSeriesData(): BaseOption {
    (this.chartOption.series as HeatmapSeriesOption[]) = [
      {
        name: _.join(this.fieldInfo.aggs, CHART_STRING_DELIMITER),
        type: SeriesType.HEATMAP,
        data: [],
        itemStyle: {},
        label: { show: false, position: Position.INSIDE },
      },
    ];

    this.chartOption.series[0].data = this.data.columns.map((item) => {
      item.selected = false;
      item.itemStyle = OptionGenerator.ItemStyle.opacity1();
      return item;
    });

    this.chartOption.series[0].uiData = this.data.columns;

    this.chartOption.series[0].originData = _.cloneDeep(this.chartOption.series[0].data);

    return this.chartOption;
  }

  protected override selection(): void {
    this.addChartSelectEventListener();
  }

  protected override setUIData(): any {
    _.each(this.data.columns, (data) => {
      const nameList: string[] = _.split(data.name, CHART_STRING_DELIMITER);

      let categoryList: string[] = [];
      let seriesList: string[] = [];

      if (0 == nameList.indexOf(this.pivot.aggregations[0].alias)) {
        seriesList = nameList.splice(1, this.pivot.rows.length);
      } else {
        categoryList = nameList.splice(0, this.pivot.columns.length);

        seriesList = nameList.splice(0, this.pivot.rows.length);
      }

      data.categoryName = _.cloneDeep(
        _.join(_.slice(categoryList, 0, this.pivot.columns.length), CHART_STRING_DELIMITER),
      );

      data.seriesName = _.cloneDeep(_.join(_.slice(seriesList, 0, this.pivot.rows.length), CHART_STRING_DELIMITER));

      data.seriesValue = _.cloneDeep(data.value[2]);
    });

    return this.data.columns;
  }

  protected override additionalSeries(): BaseOption {
    (this.chartOption.series as HeatmapSeriesOption[])?.forEach((series) => {
      if (series.label) {
        series.label.formatter = (params): any => {
          const uiData = _.cloneDeep(series.data[params.dataIndex]);

          return this.getFormatHeatmapValueSeries(
            params,
            this.uiOption.valueFormat as UIChartFormat,
            this.uiOption,
            series,
            uiData,
          );
        };
      }
    });

    return this.chartOption;
  }

  protected override additionalTooltip(): BaseOption {
    if (this.chartOption.tooltip) {
      (this.chartOption.tooltip as TooltipComponentOption).formatter = (params): any => {
        const option = this.chartOption.series?.[params.seriesIndex];

        let uiData = _.cloneDeep(option?.uiData);

        if (uiData && uiData instanceof Array) uiData = option?.uiData[params.dataIndex];

        return this.getFormatHeatmapValueSeriesTooltip(
          params,
          this.uiOption.valueFormat as UIChartFormat,
          this.uiOption,
          option,
          uiData,
        );
      };
    }

    return this.chartOption;
  }

  protected override convertXAxisData(): BaseOption {
    if (this.uiOption.xAxis && this.chartOption.xAxis) {
      const xAxisName = this.uiOption.xAxis.customName
        ? this.uiOption.xAxis.customName
        : _.join(this.fieldInfo.cols, CHART_STRING_DELIMITER);
      this.chartOption.xAxis[0].name = xAxisName;
      this.chartOption.xAxis[0].axisName = _.join(this.fieldInfo.cols, CHART_STRING_DELIMITER);
      this.data.columns.map((column) => {
        const nameList: string[] = _.split(column.name, CHART_STRING_DELIMITER);

        let sliceList: string[] = [];

        if (0 == nameList.indexOf(this.pivot.aggregations[0].alias)) {
          sliceList = _.slice(nameList, 0, 1);
        } else {
          sliceList = _.slice(nameList, 0, this.fieldInfo.cols.length);
        }
        this.chartOption.xAxis?.[0].data?.push(_.join(sliceList, CHART_STRING_DELIMITER));
      });
      this.chartOption.xAxis[0].data = _.uniq(this.chartOption.xAxis[0].data);
    }

    return this.chartOption;
  }

  protected override convertYAxisData(): BaseOption {
    if (this.chartOption.yAxis && this.uiOption.yAxis) {
      const yAxisName = this.uiOption.yAxis.customName
        ? this.uiOption.yAxis.customName
        : _.join(this.fieldInfo.rows, CHART_STRING_DELIMITER);
      this.chartOption.yAxis[0].name = yAxisName;
      this.chartOption.yAxis[0].axisName = _.join(this.fieldInfo.rows, CHART_STRING_DELIMITER);

      this.data.columns.map((column) => {
        const nameList: string[] = _.split(column.name, CHART_STRING_DELIMITER);

        let sliceList: string[] = [];

        if (0 == nameList.indexOf(this.pivot.aggregations[0].alias)) {
          sliceList = _.slice(nameList, 1, this.fieldInfo.rows.length + 1);
        } else {
          sliceList = _.slice(nameList, this.fieldInfo.cols.length);
        }

        this.chartOption.yAxis?.[0].data?.push(_.join(sliceList, CHART_STRING_DELIMITER));
      });
      this.chartOption.yAxis[0].data = _.uniq(this.chartOption.yAxis[0].data);
    }

    return this.chartOption;
  }

  private getFormatHeatmapValueSeriesTooltip(
    params: any,
    format: UIChartFormat,
    uiOption?: UIOption,
    series?: any,
    uiData?: any,
  ): string {
    if (uiData) {
      if (!uiOption.toolTip) uiOption.toolTip = {};
      if (!uiOption.toolTip.displayTypes)
        uiOption.toolTip.displayTypes = FormatOptionConverter.setDisplayTypes(uiOption.type);

      let result: string[] = [];

      if (
        uiData['categoryName'] &&
        -1 !== uiOption.toolTip.displayTypes.indexOf(UIChartDataLabelDisplayType.CATEGORY_NAME)
      ) {
        const categoryNameList = _.split(uiData['categoryName'], CHART_STRING_DELIMITER);

        result = FormatOptionConverter.getTooltipName(categoryNameList, this.pivot.columns, result, true);
      }
      if (
        uiData['seriesName'] &&
        -1 !== uiOption.toolTip.displayTypes.indexOf(UIChartDataLabelDisplayType.SERIES_NAME)
      ) {
        const seriesNameList = _.split(uiData['seriesName'], CHART_STRING_DELIMITER);

        result = FormatOptionConverter.getTooltipName(seriesNameList, this.pivot.rows, result, true);
      }
      if (
        uiData['seriesValue'] &&
        -1 !== uiOption.toolTip.displayTypes.indexOf(UIChartDataLabelDisplayType.SERIES_VALUE)
      ) {
        const seriesValue = FormatOptionConverter.getTooltipValue(
          params.seriesName,
          this.pivot.aggregations,
          this.uiOption.valueFormat,
          uiData['seriesValue'],
        );

        result.push(seriesValue);
      }

      return result.join('<br/>');
    }

    return FormatOptionConverter.noUIDataFormatTooltip(uiOption, params, format, this.fieldInfo);
  }

  private getFormatHeatmapValueSeries(
    params,
    format: UIChartFormat,
    uiOption: UIOption,
    series?: any,
    uiData?: any,
  ): string {
    if (uiData) {
      if (!uiOption.dataLabel || !uiOption.dataLabel.displayTypes) return '';

      let isUiData = false;
      let result: string[] = [];

      if (
        uiData['categoryName'] &&
        -1 !== uiOption.dataLabel.displayTypes.indexOf(UIChartDataLabelDisplayType.CATEGORY_NAME)
      ) {
        const categoryNameList = _.split(uiData['categoryName'], CHART_STRING_DELIMITER);
        result = FormatOptionConverter.getTooltipName(categoryNameList, this.pivot.columns, result, false);
        isUiData = true;
      }
      if (-1 !== uiOption.dataLabel.displayTypes.indexOf(UIChartDataLabelDisplayType.SERIES_NAME)) {
        const seriesNameList = _.split(uiData['seriesName'], CHART_STRING_DELIMITER);
        result = FormatOptionConverter.getTooltipName(seriesNameList, this.pivot.rows, result, false);
        isUiData = true;
      }
      if (-1 !== uiOption.dataLabel.displayTypes.indexOf(UIChartDataLabelDisplayType.SERIES_VALUE)) {
        result.push(FormatOptionConverter.getFormatValue(uiData['seriesValue'], format));
        isUiData = true;
      }

      let label = '';

      if (isUiData) {
        for (let num = 0; num < result.length; num++) {
          if (num > 0) {
            label += '\n';
          }
          if (series.label && series.label.rich) {
            label += '{align|' + result[num] + '}';
          } else {
            label += result[num];
          }
        }
        return label;
      } else {
        return label;
      }
    }

    return FormatOptionConverter.noUIDataFormat(params, format);
  }
}
