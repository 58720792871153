import { Component, ElementRef, EventEmitter, Injector, OnDestroy, OnInit, Output } from '@angular/core';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { CommonUtil } from '../../../common/util/common.util';

@Component({
  selector: 'popup-input-namedesc',
  templateUrl: './popup-input-namedesc.component.html',
})
export class PopupInputNameDescComponent extends AbstractComponent implements OnInit, OnDestroy {
  public inputInfo: InputInfo;

  public isShow = false;

  public isInvalidName = false;
  public errMsgName = '';

  public isInvalidDesc = false;
  public errMsgDesc = '';

  @Output()
  public triggerDone = new EventEmitter<InputInfo>();

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public init(inputInfo: InputInfo) {
    this.inputInfo = inputInfo;
    this.isShow = true;
  }

  public close() {
    this.isShow = false;
  }

  public btnDoneHandler() {
    this.inputInfo.name = this.inputInfo.name ? this.inputInfo.name.trim() : '';
    if (this.inputInfo.name == null || this.inputInfo.name.length === 0) {
      this.isInvalidName = true;
      this.errMsgName = this.translateService.instant('msg.alert.edit.name.empty');
      return;
    }

    if (CommonUtil.getByte(this.inputInfo.name) > 150) {
      this.isInvalidName = true;
      this.errMsgName = this.translateService.instant('msg.alert.edit.name.len');
      return;
    }

    if (this.inputInfo.desc != null && CommonUtil.getByte(this.inputInfo.desc.trim()) > 450) {
      this.isInvalidDesc = true;
      this.errMsgDesc = this.translateService.instant('msg.alert.edit.description.len');
      return;
    }

    this.triggerDone.emit(this.inputInfo);
  }
}

class InputInfo {
  type: string;
  id: string;
  name: string;
  desc: string;
}
