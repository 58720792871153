import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Config } from './config.model';
import { firstValueFrom, Observable, tap } from 'rxjs';
import { SELFAI_APP_BASE_HREF } from '../tokens/base-href';

@Injectable()
export class ConfigService {
  private config!: Config;

  constructor(@Inject(SELFAI_APP_BASE_HREF) private readonly baseHref: string, private http: HttpClient) {}

  init(): Promise<Config> {
    return firstValueFrom(this.load().pipe(tap((config: Config) => (this.config = config))));
  }

  load(): Observable<Config> {
    const currentDate = new Date();
    return this.http.get<Config>(`${this.baseHref}config.json?ts=${currentDate.getTime()}`);
  }

  getConfig(): Config {
    return this.config;
  }
}
