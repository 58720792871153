import { ChartType } from '../../enums';

export const CHART_STRING_DELIMITER = '―';

export const SPEC_VERSION = 2;

export class FunctionValidator {
  private _useSelection: ChartType[] = [
    ChartType.BAR,
    ChartType.LINE,
    ChartType.GRID,
    ChartType.INPUT,
    ChartType.SCATTER,
    ChartType.HEATMAP,
    ChartType.PIE,
    ChartType.CONTROL,
    ChartType.BOXPLOT,
    ChartType.WATERFALL,
    ChartType.WORDCLOUD,
    ChartType.COMBINE,
    ChartType.SANKEY,
    ChartType.GAUGE,
  ];

  private _useMultiSelection: ChartType[] = [ChartType.BAR, ChartType.LINE, ChartType.SCATTER, ChartType.COMBINE];

  private _useZoom: ChartType[] = [
    ChartType.BAR,
    ChartType.LINE,
    ChartType.SCATTER,
    ChartType.HEATMAP,
    ChartType.CONTROL,
    ChartType.BOXPLOT,
    ChartType.WATERFALL,
    ChartType.COMBINE,
  ];

  private _useLegend: ChartType[] = [
    ChartType.BAR,
    ChartType.LINE,
    ChartType.SCATTER,
    ChartType.HEATMAP,
    ChartType.PIE,
    ChartType.CONTROL,
    ChartType.COMBINE,
    ChartType.RADAR,
    ChartType.GRAPH,
  ];

  private _useMiniMap: ChartType[] = [
    ChartType.BAR,
    ChartType.LINE,
    ChartType.SCATTER,
    ChartType.HEATMAP,
    ChartType.CONTROL,
    ChartType.BOXPLOT,
    ChartType.WATERFALL,
    ChartType.COMBINE,
  ];

  public checkUseSelectionByTypeString(type: string): boolean {
    return -1 < this._useSelection.indexOf(type.toLowerCase() as ChartType);
  }

  public checkUseSelectionByType(type: ChartType): boolean {
    return -1 < this._useSelection.indexOf(type);
  }

  public checkUseMultiSelectionByTypeString(type: string): boolean {
    return -1 < this._useMultiSelection.indexOf(type.toLowerCase() as ChartType);
  }

  public checkUseMultiSelectionByType(type: ChartType): boolean {
    return -1 < this._useMultiSelection.indexOf(type);
  }

  public checkUseZoomByTypeString(type: string): boolean {
    return -1 < this._useZoom.indexOf(type.toLowerCase() as ChartType);
  }

  public checkUseZoomByType(type: ChartType): boolean {
    return -1 < this._useZoom.indexOf(type);
  }

  public checkUseLegendByTypeString(type: string): boolean {
    return -1 < this._useLegend.indexOf(type.toLowerCase() as ChartType);
  }

  public checkUseLegendByType(type: ChartType): boolean {
    return -1 < this._useLegend.indexOf(type);
  }

  public checkUseMiniMapByTypeString(type: string): boolean {
    return -1 < this._useMiniMap.indexOf(type.toLowerCase() as ChartType);
  }

  public checkUseMiniMapByType(type: ChartType): boolean {
    return -1 < this._useMiniMap.indexOf(type);
  }
}
