import { isNullOrUndefined } from 'util';

import _ from 'lodash';

import {
  BoardDataSource,
  ConnectionType,
  Dashboard,
  Datasource,
  DatasourceField as Field,
  FieldNameAlias,
  FieldRole,
  FieldValueAlias,
  JoinMapping,
} from '@selfai-platform/bi-domain';

import { DashboardUtil } from '../../../../dashboard/util/dashboard.util';

import { setFieldAttribute } from './set-field-attribute';

export function setSingleDataSource(
  dataSource: BoardDataSource,
  boardInfo: Dashboard,
  nameAliasList: FieldNameAlias[],
  valueAliasList: FieldValueAlias[],
): { reorderDsList: Datasource[]; totalFields: Field[] } {
  let totalFields: Field[] = [];
  const reorderDsList: Datasource[] = [];

  const masterDsInfo: Datasource = DashboardUtil.getDataSourceFromBoardDataSource(boardInfo, dataSource);

  if (isNullOrUndefined(masterDsInfo)) {
    return { reorderDsList: [], totalFields: [] };
  }

  dataSource.name = masterDsInfo.name;
  if (ConnectionType.ENGINE === masterDsInfo.connType) {
    dataSource = _.merge(dataSource, {
      connType: masterDsInfo.connType,
      engineName: masterDsInfo.engineName,
    });
  } else if (ConnectionType.LINK === masterDsInfo.connType) {
    dataSource = _.merge(dataSource, {
      connType: dataSource.metaDataSource.connType,
      engineName: dataSource.metaDataSource.engineName,
    });
  }
  reorderDsList.push(masterDsInfo);

  const masterFields: Field[] = masterDsInfo.fields.map((fieldItem: Field) => {
    return setFieldAttribute(fieldItem, masterDsInfo, masterDsInfo, boardInfo, nameAliasList, valueAliasList);
  });
  if (dataSource.joins && 0 < dataSource.joins.length) {
    masterDsInfo.fields.forEach((fieldItem: Field) => (fieldItem.ref = masterDsInfo.engineName));
  }
  totalFields = totalFields.concat(masterFields);

  const objJoinValueKey: any = {};
  if (dataSource.joins && 0 < dataSource.joins.length) {
    dataSource.joins.forEach((joinItem: JoinMapping) => {
      {
        const oneDepthDsInfo: Datasource = boardInfo.dataSources.find((dsItem) => dsItem.id === joinItem.id);

        joinItem = _.merge(joinItem, { engineName: oneDepthDsInfo.engineName });

        reorderDsList.push(oneDepthDsInfo);

        objJoinValueKey[joinItem.id] = Object.keys(joinItem.keyPair).map(
          (keyItem: string) => joinItem.keyPair[keyItem],
        );

        totalFields = totalFields.concat(
          oneDepthDsInfo.fields
            .map((fieldItem) => {
              if (
                fieldItem.role !== FieldRole.TIMESTAMP &&
                fieldItem.name !== 'count' &&
                -1 === objJoinValueKey[joinItem.id].indexOf(fieldItem.name)
              ) {
                fieldItem = setFieldAttribute(fieldItem, masterDsInfo, oneDepthDsInfo);
                fieldItem.ref = joinItem.engineName;
                fieldItem.join = joinItem;

                return { ...fieldItem, join: joinItem };
              } else {
                return null;
              }
            })
            .filter((fieldItem) => fieldItem !== null),
        );
      }

      if (joinItem.join) {
        const twoDepthDsInfo: Datasource = boardInfo.dataSources.find((dsItem) => dsItem.id === joinItem.join.id);

        joinItem.join = _.merge(joinItem.join, { engineName: twoDepthDsInfo.engineName });

        reorderDsList.push(twoDepthDsInfo);

        objJoinValueKey[joinItem.join.id] = Object.keys(joinItem.join.keyPair).map(
          (keyItem: string) => joinItem.join.keyPair[keyItem],
        );

        totalFields = totalFields.concat(
          twoDepthDsInfo.fields
            .map((fieldItem) => {
              if (
                fieldItem.role !== FieldRole.TIMESTAMP &&
                fieldItem.name !== 'count' &&
                -1 === objJoinValueKey[joinItem.join.id].indexOf(fieldItem.name)
              ) {
                fieldItem = setFieldAttribute(fieldItem, masterDsInfo, twoDepthDsInfo);
                fieldItem.ref = joinItem.joinAlias + '.' + joinItem.join.engineName;

                return { ...fieldItem, join: joinItem };
              } else {
                return null;
              }
            })
            .filter((fieldItem) => fieldItem !== null),
        );
      }
    });
  }

  const joins = dataSource.joins;
  if (joins && joins.length > 0) {
    dataSource.type = 'mapping';
  }

  return {
    reorderDsList: reorderDsList,
    totalFields: totalFields,
  };
}

export function enreachFieldsWithJoin(fields: Field[], dashboard: Dashboard): Field[] {
  if (!dashboard.configuration.dataSource.joins) {
    return fields;
  }
  const objJoinValueKey = {};
  const masterDsInfo: Datasource = DashboardUtil.getDataSourceFromBoardDataSource(
    dashboard,
    dashboard.configuration.dataSource,
  );
  let fieldsWithJoin: Field[] = [...fields];
  dashboard.configuration.dataSource.joins.forEach((joinItem: JoinMapping) => {
    {
      const oneDepthDsInfo: Datasource = dashboard.dataSources.find((dsItem) => dsItem.id === joinItem.id);

      joinItem = _.merge(joinItem, { engineName: oneDepthDsInfo.engineName });

      objJoinValueKey[joinItem.id] = Object.keys(joinItem.keyPair).map((keyItem: string) => joinItem.keyPair[keyItem]);

      fieldsWithJoin = fieldsWithJoin.concat(
        oneDepthDsInfo.fields
          .map((fieldItem) => {
            if (
              fieldItem.role !== FieldRole.TIMESTAMP &&
              fieldItem.name !== 'count' &&
              -1 === objJoinValueKey[joinItem.id].indexOf(fieldItem.name)
            ) {
              fieldItem = setFieldAttribute(fieldItem, masterDsInfo, oneDepthDsInfo);
              fieldItem.ref = joinItem.joinAlias;
              fieldItem.join = joinItem;

              return { ...fieldItem, join: joinItem };
            } else {
              return null;
            }
          })
          .filter((fieldItem) => fieldItem !== null),
      );
    }

    if (joinItem.join) {
      const twoDepthDsInfo: Datasource = dashboard.dataSources.find((dsItem) => dsItem.id === joinItem.join.id);

      joinItem.join = _.merge(joinItem.join, { engineName: twoDepthDsInfo.engineName });

      objJoinValueKey[joinItem.join.id] = Object.keys(joinItem.join.keyPair).map(
        (keyItem: string) => joinItem.join.keyPair[keyItem],
      );

      fieldsWithJoin = fieldsWithJoin.concat(
        twoDepthDsInfo.fields
          .map((fieldItem) => {
            if (
              fieldItem.role !== FieldRole.TIMESTAMP &&
              fieldItem.name !== 'count' &&
              -1 === objJoinValueKey[joinItem.join.id].indexOf(fieldItem.name)
            ) {
              fieldItem = setFieldAttribute(fieldItem, masterDsInfo, twoDepthDsInfo);
              fieldItem.ref = joinItem.joinAlias;

              return { ...fieldItem, join: joinItem };
            } else {
              return null;
            }
          })
          .filter((fieldItem) => fieldItem !== null),
      );
    }
  });

  return fieldsWithJoin;
}
