import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'selfai-platform-page-section-buttons',
  templateUrl: './page-section-buttons.component.html',
  styleUrls: ['./page-section-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageSectionButtonsComponent {
  @Input() isDashboard: boolean;
  @Input() isChartShow: boolean;
  @Input() isError: boolean;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();
}
