import { FactoryProvider, Injector, ValueProvider } from '@angular/core';

import {
  BiPermission,
  KdConfigService,
  KdFeatureEnableService,
  KdFeatureName,
  PermissionService,
} from '@selfai-platform/shared';
import { provideMainMenuChunkFactory } from '@selfai-platform/shell';

export function provideDashboardMainMenuChunk(): FactoryProvider | ValueProvider {
  return provideMainMenuChunkFactory((injector: Injector) => {
    const kdConfigService = injector.get(KdConfigService);
    const kdEnbaledFeatureService = injector.get(KdFeatureEnableService);
    const permissionService = injector.get(PermissionService);

    if (
      !kdConfigService.getConfig()?.enabled ||
      !kdEnbaledFeatureService.isEnabledFeature(KdFeatureName.MAIN_DASHBOARD) ||
      !permissionService.getBiPermissions().includes(BiPermission.PERM_SELFAI_DASHBOARD_VIEW)
    ) {
      return null;
    }

    return {
      id: 'main',
      label: 'shell.menu.maindashboard',
      routerLink: ['/main-dashboard'],
      state: {
        sort: 100,
      },
      items: null,
    };
  });
}
