export enum EChartType {
  Bar = "bar",
  Grid = "grid",
  Line = "line",
  Scatter = "scatter",
  Pie = "pie",
  Map = "map",
  Label = "label",
  Boxplot = "boxplot",
  Waterfall = "waterfall",
  Wordcloud = "wordcloud",
  Combine = "combine",
  Radar = "radar",
  Funnel = "funnel",
  Gauge = "gauge",
  Heatmap = "heatmap",
  Treemap = "treemap",
  Sankey = "sankey",
  Network = "network",
}

export interface IPageChartItem {
  title: string;
  values: string[];
}

export interface IPageChart {
  name: EChartType;
  title: string;
  data: IPageChartItem[];
}
