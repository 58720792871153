import { Injectable, Optional } from '@angular/core';

import { Observable, filter, forkJoin, from, map, merge, mergeMap, of, switchMap, take, tap } from 'rxjs';

import {
  BoardConfiguration,
  BoardDataSource,
  Dashboard,
  LayoutWidgetInfo,
  createBoardSource,
  createLayoutWidgetInfo,
  Datasource,
  GetDashboardResult,
  WidgetApiToDomainService,
  DashboardDomainService,
} from '@selfai-platform/bi-domain';
import { Loader, LoaderUtil, UrlPageParamsService } from '@selfai-platform/shared';

import { ImageService } from '../../common/service/image.service';
import { setDataSourceAndRelations } from '../../dashboard';
import { GetWidgetResult, WidgetListQueryParams } from '../../dashboard/dto';
import { DashboardApiService } from '../../dashboard/service/dashboard-api.service';
import { DashboardUtil } from '../../dashboard/util/dashboard.util';
import { DatasourceService } from '../../datasource/service/datasource.service';
import { Chart, ChartApi, ChartList } from '../model';

import { ChartAdapter } from './chart.adapter';

const defaultWidgetPageParams: WidgetListQueryParams = {
  page: 0,
  size: 20,
  type: 'page',
  sort: 'modifiedTime,desc',
  projection: 'forPageWidgetListView',
};

@Injectable({ providedIn: 'root' })
export class ChartListService extends LoaderUtil<ChartList> {
  constructor(
    private readonly chartAdapter: ChartAdapter,
    private readonly imageService: ImageService,
    private readonly dashboardService: DashboardApiService,
    private readonly widgetService: WidgetApiToDomainService,
    private readonly dataSourceService: DatasourceService,
    private readonly dashboardDomainService: DashboardDomainService,
    @Optional() private readonly urlPageParamsService?: UrlPageParamsService,
  ) {
    super(undefined);
  }

  loadChartList(): void {
    this.getWidgetQueryPageParams().subscribe((widgetListQueryParams) => {
      this.loadFromSource(
        this.chartAdapter.loadList(widgetListQueryParams).pipe(
          tap(({ page }) => {
            this.urlPageParamsService?.setPageParams({
              pageNumber: page.number + 1,
              pageSize: page.size,
              totalItems: page.totalElements,
            });
          }),
          map((response) => ({
            ...response,
            charts: (response._embedded?.widgets || []).map(this.normalizeChart.bind(this)),
          })),
        ),
      );
    });
  }

  getChartListState(): Observable<Loader<ChartList>> {
    return this.asObservable().pipe(filter(Boolean));
  }

  removeChart(id: string, datasourceId: string): Observable<unknown> {
    return forkJoin([
      from(this.widgetService.getWidget(id)),
      from(this.dataSourceService.getDatasourceDetail(datasourceId)),
      this.dashboardDomainService.getDashboardByWidgetId(id),
    ]).pipe(
      switchMap(([widget, datasource, dashboard]) => {
        const dashboardConfig = this.removeWidgetFromGrid(id, dashboard);
        dashboardConfig.dataSource = this.getDashboardConfigurationFromDatasource(datasource);
        return from([
          this.dashboardService.updateDashboard(dashboard.id, {
            configuration: dashboardConfig,
          }),
          this.widgetService.deleteWidget(id),
        ]);
      }),
    );
  }

  moveChart(id: string, targetDashboardId: string, datasourceId: string): Observable<unknown> {
    return forkJoin([
      from(this.widgetService.getWidget(id)),
      from(this.dashboardService.getDashboard(targetDashboardId)),
      from(this.dataSourceService.getDatasourceDetail(datasourceId)),
      from(this.dashboardDomainService.getDashboardByWidgetId(id)),
    ]).pipe(
      switchMap(([oldWidget, targetDashboard, datasource, sourceDashboard]) =>
        this.chartAdapter
          .moveChart(id, targetDashboardId)
          .pipe(map((newWidget) => ({ oldWidget, newWidget, targetDashboard, datasource, sourceDashboard }))),
      ),
      mergeMap(({ oldWidget, newWidget, targetDashboard, datasource, sourceDashboard }) => {
        const sourceDashboardConfig = this.removeWidgetFromGrid(oldWidget.id, sourceDashboard);
        sourceDashboardConfig.dataSource = this.getDashboardConfigurationFromDatasource(datasource);
        const targetDashboardConfig = this.addWidgetToGrid(newWidget, targetDashboard);
        targetDashboardConfig.dataSource = this.getDashboardConfigurationFromDatasource(datasource);
        return merge(
          from([
            this.dashboardService.updateDashboard(targetDashboardId, {
              configuration: targetDashboardConfig,
              imageUrl: targetDashboard.imageUrl,
            }),
            this.dashboardService.updateDashboard(oldWidget.dashBoardId, {
              configuration: sourceDashboardConfig,
            }),
          ]),
        );
      }),
    );
  }

  copyToChart(id: string, targetDashboardId: string, datasourceId: string): Observable<unknown> {
    return forkJoin([
      this.chartAdapter.copyToChart(id, targetDashboardId),
      from(this.dashboardService.getDashboard(targetDashboardId)),
      from(this.dataSourceService.getDatasourceDetail(datasourceId)),
    ]).pipe(
      map(([widget, targetDashboard, datasource]) => ({ widget, targetDashboard, datasource })),
      switchMap(({ widget, targetDashboard, datasource }) => {
        const targetDashboardConfig = this.addWidgetToGrid(widget, targetDashboard);
        targetDashboardConfig.dataSource = this.getDashboardConfigurationFromDatasource(datasource);
        return from([
          this.dashboardService.updateDashboard(targetDashboardId, {
            configuration: targetDashboardConfig,
            imageUrl: targetDashboard.imageUrl,
          }),
        ]);
      }),
    );
  }

  private getDashboardConfigurationFromDatasource(datasourceFull: Datasource): BoardDataSource {
    const dataSource = createBoardSource();
    dataSource.name = datasourceFull.name;
    dataSource.id = datasourceFull.id;
    dataSource.engineName = datasourceFull.engineName;
    dataSource.temporary = false;
    dataSource.type = 'default';
    dataSource.uiDescription = datasourceFull.description;
    return dataSource;
  }

  private getWidgetQueryPageParams(): Observable<WidgetListQueryParams> {
    if (this.urlPageParamsService) {
      return this.urlPageParamsService.getPageParams().pipe(
        take(1),
        map((pageParams) => {
          return {
            ...defaultWidgetPageParams,
            page: pageParams.pageNumber - 1,
            size: pageParams.pageSize,
            containsText: pageParams.query,
            sort: pageParams.sortField && `${pageParams.sortField},${pageParams.sortOrder}`,
          };
        }),
      );
    }

    return of(defaultWidgetPageParams);
  }

  private normalizeChart(chartApi: ChartApi): Chart {
    return {
      ...chartApi,
      createdTime: new Date(chartApi.createdTime),
      modifiedTime: new Date(chartApi.modifiedTime),
      imageUrl: this.imageService.buildUrlImage(chartApi.imageUrl),
    };
  }

  private removeWidgetFromGrid(id: string, dashboard: Dashboard) {
    const widgets: LayoutWidgetInfo[] = dashboard.configuration.widgets
      .filter(({ ref: widgetId }) => widgetId !== id)
      .map((widget) => ({ ...widget, isInLayout: true }));

    dashboard.configuration = { ...dashboard.configuration, widgets };
    return DashboardUtil.getBoardConfiguration(dashboard);
  }

  private addWidgetToGrid(widget: GetWidgetResult, dashboard: Dashboard | GetDashboardResult): BoardConfiguration {
    const currentWidgets = dashboard?.configuration?.widgets || [];

    const widgets: LayoutWidgetInfo[] = [
      ...currentWidgets,
      createLayoutWidgetInfo(widget.id, widget.type || widget.configuration?.type),
    ].map((widget) => ({ ...widget, isInLayout: true }));

    dashboard.configuration = { ...dashboard.configuration, widgets };

    if (!DashboardUtil.findDataSourceOnBoard(dashboard, widget.configuration?.dataSource)) {
      return DashboardUtil.getBoardConfiguration(
        // TODO: remove any we have duplicates of Dashboard interface
        setDataSourceAndRelations(dashboard as any, [
          ...(dashboard.dataSources as any[]),
          widget.configuration.dataSource,
        ]),
      );
    }

    return DashboardUtil.getBoardConfiguration(dashboard);
  }
}
