import { Filter, createFilter } from '../../workbook';

export interface SelectionInfoData {
  alias: string;
  currentPivot: string;
  data: string[] | string;
  format: { type?: string; discontinuous?: boolean; unit?: string };
  granularity: string;
  name: string;
  segGranularity: string;
  subRole: string;
  subType: string;
  type: string;
}

export interface SelectionFilter extends Filter {
  selected: boolean;
  name: string;
  format: { type?: string; discontinuous?: boolean; unit?: string };
  alias?: string;
  fieldAlias?: string;
  pivotAlias?: string;

  minTime?: string;
  maxTime?: string;
  valueList?: string[];

  selectedWidgetId?: string;
}

export function createSelectionFilter(field: string, engineName: string): SelectionFilter {
  return {
    ...createFilter(),
    format: {},
    selected: false,
    field: field,
    dataSource: engineName,
    name: '',
  };
}

export function getDisplayName(filter: SelectionFilter): string {
  if (filter.pivotAlias) {
    return filter.pivotAlias;
  } else {
    if (filter.fieldAlias !== filter.alias) {
      if (filter.alias && filter.alias !== filter.name) {
        return filter.alias;
      } else {
        return filter.fieldAlias ? filter.fieldAlias : filter.name;
      }
    } else {
      return filter.name;
    }
  }
}

export function getLabel(filter: SelectionFilter) {
  if (!filter.valueList || filter.valueList.length < 1) {
    return '';
  } else {
    return filter.valueList[0];
  }
}

export function getValueCnt(filter: SelectionFilter): number {
  return filter.valueList?.length || 0;
}
