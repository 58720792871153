import { Injectable } from '@angular/core';

import { Dashboard, DashboardApiService } from '@selfai-platform/bi-domain';

@Injectable()
export class DashboardService {
  private _dashboard: Dashboard;
  constructor(private readonly dashboardApiService: DashboardApiService) {}

  public setCurrentDashboard(board: Dashboard) {
    this._dashboard = board;
  }

  public getCurrentDashboard() {
    return this._dashboard;
  }
}
