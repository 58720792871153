import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ShellConfig, SHELL_CONFIG } from '../../shell-config';
import { getStartPageWidgetsConfigs } from './home-page-base-widget/deafult-widgets';
import { HomePageWidgetConfig } from './home-page-base-widget/home-page-base-widget-config';

@Component({
  selector: 'selfai-platform-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePageComponent {
  widgetConfigs: HomePageWidgetConfig[];
  constructor(@Inject(SHELL_CONFIG) shellConfig: ShellConfig) {
    const widgetNames = shellConfig.startPage.widgets;
    this.widgetConfigs = getStartPageWidgetsConfigs(widgetNames);
  }
}
