import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import {
  GraphNodeOptionSerialized,
  TreeNodeCube,
  TreeNodeEmpty,
  TreeNodeOperation,
  TreeNodeRecycleBin,
  TreeNodeRemovedCube,
  TreeNodeType,
  TreeNodeWorkflow,
  WorkflowTreeModel,
} from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { EditorStateService, WorkflowStateService } from '@selfai-platform/storage';
import { ContextMenu } from 'primeng/contextmenu';
import { Tree } from 'primeng/tree';
import { combineLatest, filter, map, Observable, of, take, takeUntil, tap } from 'rxjs';
import { WorkflowDetailService } from '../../../wokflow/services/workflow-detail.service';
import { WorkflowEditorFacadeservice } from '../../../workflow-editor/workflow-editor-facade.service';
import { CopyPasteCubeService } from '../../services/copy-paste-cube.service';
import { CubeListContextMenuService } from './cube-list-context-menu.service';
import { CubeListRenameCubeService } from './cube-list-rename-cube.service';
import { CubeListWorkflowListService } from './cube-list-workflow-list.service';
import { getTreeNodeByCubeId } from './get-tree-node-by-cube-id';
import { mapWorkflowToTreeNode } from './map-workflow-to-tree-node';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'selfai-platform-cube-list',
  templateUrl: './cube-list.component.html',
  styleUrls: ['./cube-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DestroyService,
    CubeListContextMenuService,
    CubeListWorkflowListService,
    CubeListRenameCubeService,
    ConfirmationService,
  ],
})
export class CubeListComponent implements OnInit {
  renamedNode$!: Observable<TreeNodeCube | null>;
  newName = '';

  loadingWorkflowList = false;
  allNodesAreLoaded = false;
  selectedNode: TreeNodeCube | TreeNodeOperation | TreeNodeEmpty | TreeNodeRemovedCube | null = null;

  nodeType = TreeNodeType;
  @ViewChild(Tree, { static: false, read: Tree }) treeRef?: Tree;
  @ViewChild(ContextMenu, { static: false, read: ContextMenu }) contextMenu!: ContextMenu;

  @HostListener('document:paste', ['$event']) onPaste = (event: ClipboardEvent) => {
    this.copyPasteCubeService.pasteFromClipboard(event);
  };

  constructor(
    public readonly workflowList$: CubeListWorkflowListService,
    private readonly workflowStateService: WorkflowStateService,
    private readonly workflowDetailService: WorkflowDetailService,
    private readonly copyPasteCubeService: CopyPasteCubeService,
    private readonly cdr: ChangeDetectorRef,
    private readonly destroyService$: DestroyService,
    private readonly editorStateService: EditorStateService,
    private readonly cubeListContextMenuService: CubeListContextMenuService,
    private readonly cubeListRenameCubeService: CubeListRenameCubeService,
    private readonly workflowEditorFacadeService: WorkflowEditorFacadeservice,
  ) {}

  ngOnInit(): void {
    this.renamedNode$ = this.cubeListRenameCubeService.renamedNode$.pipe(
      tap((node) => {
        if (node) {
          this.newName = node.label as string;
        }
      }),
    );

    this.workflowList$.pipe(takeUntil(this.destroyService$)).subscribe(() => {
      const selectedNodeId = this.selectedNode?.data?.node.id;
      if (selectedNodeId) {
        this.setSelectedNode(selectedNodeId);
      }
    });

    this.editorStateService
      .getSelectedNode()
      .pipe(filter(Boolean), takeUntil(this.destroyService$))
      .subscribe((node) => {
        this.setSelectedNode(node.id);
      });
  }

  isCurrentWorkflowId(workflowId: string): Observable<boolean> {
    return this.workflowStateService.getWorkflowState().pipe(
      filter(Boolean),
      map(({ id }) => id === workflowId),
    );
  }

  nodeExpand(event: { node: TreeNodeWorkflow }) {
    if (event.node && !event.node.children && event.node.data?.workflowId) {
      event.node.leaf = true;
      event.node.children = [{ type: TreeNodeType.LOADING, leaf: true }];

      this.loadTreeNodeByWorkflowId(event.node.data.workflowId).subscribe((children) => {
        event.node.children = children;
        this.cdr.markForCheck();
      });
    }
  }

  cancelRenameCube() {
    this.cubeListRenameCubeService.cancelRenameCube();
  }

  saveRenameCube(): void {
    this.cubeListRenameCubeService.saveRenameCube(this.newName);
    this.newName = '';
  }

  copyToClipboard(cube: GraphNodeOptionSerialized): void {
    this.cubeListContextMenuService.copyToClipboard(cube);
  }

  toggle(event: Event, node: WorkflowTreeModel) {
    if (node.expanded) this.collapse(event, node);
    else this.expand(event, node);
  }

  expand(event: Event, node: WorkflowTreeModel) {
    node.expanded = true;

    if (this.treeRef?.virtualScroll) {
      this.treeRef?.updateSerializedValue();
    }

    this.treeRef?.onNodeExpand.emit({ originalEvent: event, node });
  }

  collapse(event: Event, node: WorkflowTreeModel) {
    node.expanded = false;
    if (this.treeRef?.virtualScroll) {
      this.treeRef?.updateSerializedValue();
    }
    this.treeRef?.onNodeCollapse.emit({ originalEvent: event, node });
  }

  showAndSelectCube(node: TreeNodeCube): void {
    const treeNodeWorkflow: TreeNodeWorkflow = node.parent?.parent as TreeNodeWorkflow;
    const workflowId = treeNodeWorkflow.data?.workflowId;
    const nodeId = node.data?.node.id;

    if (!workflowId || !nodeId) {
      return;
    }
    this.isCurrentWorkflowId(workflowId)
      .pipe(take(1))
      .subscribe((isInCurrentWorkflow) => {
        if (isInCurrentWorkflow) {
          this.workflowEditorFacadeService.selectNodes([nodeId]);
          this.workflowEditorFacadeService.moveToNodes([nodeId]);
        } else {
          this.workflowEditorFacadeService.unselectNodes();
        }
      });
  }

  onFilter(event: { filter: string }): void {
    if (this.treeRef && !this.allNodesAreLoaded) {
      // load all workflow nodes before filtering
      const filterInput = this.treeRef.filterViewChild.nativeElement as HTMLInputElement;
      filterInput.disabled = true;
      this.loadingWorkflowList = true;
      this.allNodesAreLoaded = true;

      combineLatest(
        (this.treeRef.value as TreeNodeWorkflow[]).map((node: TreeNodeWorkflow) => {
          if (!node.leaf && node.data) {
            return this.loadTreeNodeByWorkflowId(node.data.workflowId).pipe(
              map((children) => {
                node.children = children;
              }),
            );
          }
          return of(null);
        }),
      ).subscribe(() => {
        this.loadingWorkflowList = false;
        this.treeRef?._filter(event.filter);
        filterInput.disabled = false;
        filterInput.focus();
      });
    }
  }

  onContextMenu(node: TreeNodeCube, event: MouseEvent): void {
    this.showAndSelectCube(node);
    const menuItems = this.cubeListContextMenuService.getContextMenuActions(node, event);
    if (menuItems.length > 0) {
      this.contextMenu.model = menuItems;
      this.contextMenu.cd.markForCheck();
      this.contextMenu.show(event);
    }
  }

  //TODO: add del for not deleted nodes. Check evnet.target
  onKeydownDelete(event: Event) {
    if (!this.selectedNode) {
      return;
    }
    if (this.selectedNode.type === TreeNodeType.REMOVEDCUBE) {
      const target = event.target as EventTarget;
      this.cubeListContextMenuService.removeCubesFromRecyclebin(target, this.selectedNode);
    }
  }

  private loadTreeNodeByWorkflowId(
    workflowId: string,
  ): Observable<(TreeNodeOperation | TreeNodeEmpty | TreeNodeRecycleBin)[]> {
    return combineLatest([
      this.workflowDetailService.loadWorkflow(workflowId),
      this.isCurrentWorkflowId(workflowId),
    ]).pipe(
      map((data) => {
        return mapWorkflowToTreeNode(data[0], data[1]);
      }),
    );
  }

  private setSelectedNode(nodeId: string): void {
    if (!nodeId) {
      this.selectedNode = null;
    } else {
      const selectedNode = getTreeNodeByCubeId(nodeId, this.workflowList$.value);
      this.selectedNode = selectedNode;
    }
    this.cdr.markForCheck();
  }
}
