import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'selfai-platform-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent implements OnInit {
  message?: string;

  constructor(private router: Router) {
    this.message = this.router.getCurrentNavigation()?.extras.state?.['message'];
  }

  ngOnInit() {
    console.log('configured routes: ', this.router.config);
  }
}
