import { AfterViewInit, Component, OnInit } from '@angular/core';

import {
  BaseOption,
  ChartColorList,
  ChartColorType,
  ChartSelectMode,
  ChartType,
  ColorCustomMode,
  Pivot,
  PivotField,
  PivotTableInfo,
  PositionLabel,
  SeriesType,
  ShelveFieldType,
  ShelveType,
  UIChartColor,
  UIChartColorBySeries,
  UIChartDataLabel,
  UIChartDataLabelDisplayType,
  UIChartFormat,
  UIOption,
  UIPosition,
  createChartSelectInfo,
  createPivotTableInfo,
} from '@selfai-platform/bi-domain';
import { TreemapSeriesOption } from 'echarts';
import * as _ from 'lodash';
import { ColorOptionConverter, FormatOptionConverter } from '../../converters';
import { provideBaseChartServices } from '../../services';
import { OptionGenerator } from '../../utils';
import { BaseChart } from '../base-chart';

import Tooltip = OptionGenerator.Tooltip;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'treemap-chart',
  template: '',
  styleUrls: ['./chart-host.component.scss'],
  providers: [...provideBaseChartServices()],
})
export class TreeMapChartComponent extends BaseChart implements OnInit, AfterViewInit {
  private _prevTreePath: string[] = [];

  public override isValid(shelve: Pivot): boolean {
    return (
      this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.DIMENSION) == 1 &&
      this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.TIMESTAMP) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.MEASURE) +
        this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.CALCULATED) ==
        1 &&
      this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.MEASURE) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.CALCULATED) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.ROWS, ShelveFieldType.MEASURE) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.ROWS, ShelveFieldType.CALCULATED) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.DIMENSION) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.TIMESTAMP) == 0
    );
  }

  public override draw(isKeepRange?: boolean): void {
    const cols: string[] = this.data.columns.map((column: any) => {
      return column.name;
    });

    this.pivotInfo = createPivotTableInfo(cols, [], this.fieldInfo.aggs);

    super.draw(isKeepRange);
  }

  public override addChartSelectEventListener(): void {
    this.chart.off('click');
    this.chart.on('click', (params: any) => {
      if (this.userCustomFunction && '' !== this.userCustomFunction && -1 < this.userCustomFunction.indexOf('main')) {
        const strScript = '(' + this.userCustomFunction + ')';

        try {
          if (eval(strScript)({ name: 'SelectionEvent', data: params ? params.name : '' })) {
            return;
          }
        } catch (e) {
          console.error(e);
        }
      }

      let selectMode: ChartSelectMode;
      let selectedColValues: string[] = [];
      let selectedRowValues: string[] = [];

      if (_.isNull(params)) {
        selectMode = ChartSelectMode.CLEAR;
      } else if (params !== null) {
        const currTreePath = params.treePathInfo.map((item: any) => item.name);
        if (this._prevTreePath.length < currTreePath.length) {
          selectMode = ChartSelectMode.ADD;
          currTreePath.forEach((item: any, idx: number) => {
            if (idx >= this._prevTreePath.length) {
              if (1 === idx) {
                selectedColValues = [item];
              } else if (2 === idx) {
                selectedRowValues = [item];
              }
            }
          });
        } else {
          selectMode = ChartSelectMode.SUBTRACT;
          this._prevTreePath.forEach((item, idx) => {
            if (idx >= currTreePath.length) {
              if (1 === idx) {
                selectedColValues = [item];
              } else if (2 === idx) {
                selectedRowValues = [item];
              }
            }
          });
        }

        this._prevTreePath = currTreePath;
      } else {
        return;
      }

      if (this.params.externalFilters) this.params.externalFilters = false;

      const selectData = this.setSelectData(params, selectedColValues, selectedRowValues);

      this.chartSelectInfo.emit(createChartSelectInfo(selectMode, selectData, this.params));
    });
  }

  protected override initOption(): BaseOption {
    return {
      type: ChartType.TREEMAP,
      tooltip: Tooltip.itemTooltip(),
      series: [],
    };
  }

  protected override convertSeriesData(): BaseOption {
    const value = this.data.columns[0].value;

    this.chartOption.series = [
      {
        name: String(ChartType.TREEMAP),
        type: SeriesType.TREEMAP,
        width: '60%',
        height: '60%',
        data: value,
        leafDepth: 1,
      },
    ];

    return this.chartOption;
  }

  protected override selection(): void {
    this.addChartSelectEventListener();
  }

  protected override additionalSeries(): BaseOption {
    this.chartOption = this.convertTreemapLabelAlign(this.chartOption, this.uiOption);

    this.chartOption = this.convertTreemapFormatSeries(this.chartOption, this.uiOption);

    this.chartOption = this.convertTreemapColor(this.chartOption, this.uiOption);

    return this.chartOption;
  }

  private convertTreemapLabelAlign(chartOption: BaseOption, uiOption: UIOption): BaseOption {
    const label: UIChartDataLabel = uiOption.dataLabel;

    if (!label) return this.chartOption;

    const series: TreemapSeriesOption[] = chartOption.series as TreemapSeriesOption[];

    if (!label.hAlign) label.hAlign = UIPosition.CENTER;
    if (!label.vAlign) label.vAlign = UIPosition.CENTER;

    let hAlign = '';
    switch (label.hAlign) {
      case UIPosition.LEFT:
        hAlign = 'Left';
        break;
      case UIPosition.CENTER:
        hAlign = '';
        break;
      case UIPosition.RIGHT:
        hAlign = 'Right';
        break;
    }

    let vAlign = '';
    switch (label.vAlign) {
      case UIPosition.TOP:
        vAlign = 'Top';
        break;
      case UIPosition.CENTER:
        vAlign = '';
        break;
      case UIPosition.BOTTOM:
        vAlign = 'Bottom';
        break;
    }

    const align = 'inside' + vAlign + hAlign;

    _.each(series, (option) => {
      if (_.isUndefined(option.label)) {
        option.label = {};
      }

      option.label.position = align as PositionLabel;
    });

    return chartOption;
  }

  protected override additionalTooltip(): BaseOption {
    if (!Array.isArray(this.chartOption.tooltip)) {
      this.chartOption.tooltip.formatter = (params): any => {
        const option = this.chartOption.series[params.seriesIndex];

        let uiData = _.cloneDeep(option.uiData);

        if (uiData && uiData instanceof Array) uiData = option.uiData[params.dataIndex];

        return this.getFormatTreemapValueTooltip(
          params,
          this.uiOption,
          this.fieldInfo,
          this.uiOption.valueFormat,
          option,
          uiData,
        );
      };
    }
    return this.chartOption;
  }

  private convertTreemapFormatSeries(chartOption: BaseOption, uiOption: UIOption): BaseOption {
    let format: UIChartFormat = uiOption.valueFormat;
    if (_.isUndefined(format)) {
      return chartOption;
    }

    const axisFormat = FormatOptionConverter.getlabelAxisScaleFormat(uiOption);
    if (axisFormat) format = axisFormat;

    const series: TreemapSeriesOption[] = chartOption.series as TreemapSeriesOption[];

    _.each(series, (option) => {
      if (_.isUndefined(option.label)) {
        option.label = {};
      }
      if (_.isUndefined(option.label)) {
        option.label = {};
      }

      option.label.formatter = (params): any => {
        let uiData: any = _.cloneDeep(option.data);

        if (option.data instanceof Array) uiData = option.data[params.dataIndex];

        return this.getFormatTreemapValueSeries(params, format, uiOption, option, uiData);
      };
    });

    return chartOption;
  }

  private getFormatTreemapValueSeries(
    params: any,
    format: UIChartFormat,
    uiOption?: UIOption,
    series?: any,
    uiData?: any,
  ): string {
    if (uiData) {
      if (!uiOption.dataLabel || !uiOption.dataLabel.displayTypes) return '';

      let isUiData = false;
      const result: string[] = [];
      if (-1 !== uiOption.dataLabel.displayTypes.indexOf(UIChartDataLabelDisplayType.SERIES_NAME)) {
        result.push(params.name);
        isUiData = true;
      }
      if (-1 !== uiOption.dataLabel.displayTypes.indexOf(UIChartDataLabelDisplayType.SERIES_VALUE)) {
        result.push(FormatOptionConverter.getFormatValue(params.value, format));
        isUiData = true;
      }

      if (-1 !== uiOption.dataLabel.displayTypes.indexOf(UIChartDataLabelDisplayType.SERIES_PERCENT)) {
        let value = params.data.percentage;
        value = Math.floor(Number(value) * Math.pow(10, format.decimal)) / Math.pow(10, format.decimal);
        result.push(value + '%');
        isUiData = true;
      }

      let label = '';

      if (isUiData) {
        for (let num = 0; num < result.length; num++) {
          if (num > 0) {
            label += '\n';
          }
          if (series.label && series.label.rich) {
            label += '{align|' + result[num] + '}';
          } else {
            label += result[num];
          }
        }
        return label;
      } else {
        return label;
      }
    }

    return FormatOptionConverter.noUIDataFormat(params, format);
  }

  private getFormatTreemapValueTooltip(
    params: any,
    uiOption: UIOption,
    fieldInfo: PivotTableInfo,
    format: UIChartFormat,
    series?: any,
    uiData?: any,
  ): string {
    if (uiData) {
      if (!uiOption.toolTip) uiOption.toolTip = {};
      if (!uiOption.toolTip.displayTypes)
        uiOption.toolTip.displayTypes = FormatOptionConverter.setDisplayTypes(uiOption.type);

      let result: string[] = [];
      if (-1 !== uiOption.toolTip.displayTypes.indexOf(UIChartDataLabelDisplayType.SERIES_NAME)) {
        let pivotTarget: PivotField[] = [];

        if (1 == params.data.depth) {
          pivotTarget = this.pivot.columns;
        } else {
          pivotTarget = this.pivot.rows;
        }

        result = FormatOptionConverter.getTooltipName([params.name], pivotTarget, result, true);
      }
      if (-1 !== uiOption.toolTip.displayTypes.indexOf(UIChartDataLabelDisplayType.SERIES_VALUE)) {
        const seriesValueName = this.pivot.aggregations[0].alias;
        let seriesValue = FormatOptionConverter.getTooltipValue(
          seriesValueName,
          this.pivot.aggregations,
          format,
          params.value,
        );

        if (-1 !== uiOption.toolTip.displayTypes.indexOf(UIChartDataLabelDisplayType.SERIES_PERCENT)) {
          const value =
            Math.floor(Number(params.data.percentage) * Math.pow(10, format.decimal)) / Math.pow(10, format.decimal);

          seriesValue += ' (' + value + '%)';
        }

        result.push(seriesValue);
      }

      if (-1 !== uiOption.toolTip.displayTypes.indexOf(UIChartDataLabelDisplayType.SERIES_PERCENT)) {
        if (-1 == uiOption.toolTip.displayTypes.indexOf(UIChartDataLabelDisplayType.SERIES_VALUE)) {
          const seriesValueName = this.pivot.aggregations[0].alias;
          let seriesValue = FormatOptionConverter.getTooltipValue(
            seriesValueName,
            this.pivot.aggregations,
            format,
            params.data.percentage,
          );

          seriesValue += '%';

          result.push(seriesValue);
        }
      }

      return result.join('<br/>');
    }

    return FormatOptionConverter.noUIDataFormatTooltip(uiOption, params, format, fieldInfo);
  }

  private convertTreemapColor(chartOption: BaseOption, uiOption: UIOption): BaseOption {
    const color: UIChartColor = uiOption.color;

    if (!color) return chartOption;

    switch (color.type) {
      case ChartColorType.DIMENSION: {
        delete chartOption.visualMap;

        const depthValue = this.pivot.columns.length + this.pivot.rows.length;

        const colorAlpha = [0.3, 1];

        const levels = [];

        for (let index = 0; index <= depthValue; index++) {
          levels.push({ colorAlpha: colorAlpha, visualMin: 0 });
        }

        const schema = (<UIChartColorBySeries>this.uiOption.color).schema;
        const codes = _.cloneDeep(ChartColorList[schema]);

        _.each(chartOption.series, (option: TreemapSeriesOption) => {
          option.itemStyle.color = null;
          option.data.map((data, index) => {
            if (!data.itemStyle || !data.itemStyle) data.itemStyle = {};
            data.itemStyle.color = codes[index % codes.length];
          });

          option['levels'] = levels;
        });

        break;
      }
      case ChartColorType.MEASURE: {
        if (uiOption.color['customMode'] && ColorCustomMode.GRADIENT == uiOption.color['customMode']) {
          chartOption = ColorOptionConverter.convertColorByValueGradation(chartOption, uiOption);
        } else {
          chartOption = ColorOptionConverter.convertColorByValue(chartOption, uiOption);
        }
        break;
      }
    }

    return chartOption;
  }
}
