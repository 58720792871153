<div class="p-dialog-header sticky px-0 top-0 bg-white z-1 mb-3">
  <span class="p-dialog-title">
    {{ header }}
    <selfai-platform-error-button
      *ngIf="cubeState && cubeState.error"
      [state]="cubeState"
    ></selfai-platform-error-button>
  </span>

  <div class="p-dialog-header-icons">
    <button
      type="button"
      class="mr-3 p-button-success"
      (click)="clickSave.emit()"
      (keydown.enter)="clickSave.emit()"
      tabindex="-1"
      pRipple
      pButton
      label="Save"
      icon="pi pi-save"
    ></button>
    <button
      *ngIf="dialogHelperService"
      type="button"
      [ngClass]="{ 'p-dialog-header-icon p-dialog-header-maximize p-link': true }"
      (click)="maximizeDialog()"
      (keydown.enter)="maximizeDialog()"
      tabindex="-1"
      pRipple
    >
      <span
        class="p-dialog-header-maximize-icon"
        [ngClass]="dialogHelperService.maximizedDialog ? 'pi pi-window-minimize' : 'pi pi-window-maximize'"
      ></span>
    </button>
    <button
      [ngClass]="'p-dialog-header-icon p-dialog-header-maximize p-link'"
      type="button"
      (click)="closeDialog.emit()"
      (keydown.enter)="closeDialog.emit()"
      tabindex="-1"
    >
      <span class="p-dialog-header-close-icon pi pi-times"></span>
    </button>
  </div>
</div>
