import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, catchError, forkJoin, map, of, switchMap } from 'rxjs';

import { KdConfigService, normalizeApiEndpoint } from '@selfai-platform/shared';

import { JoinMapping, TimeRangeFilter } from '../../workbook';
import {
  BoardDataSource,
  BoardGlobalOptions,
  Dashboard,
  DashboardApi,
  DashboardListApiResponse,
  DashboardListQueryParams,
  LayoutWidgetInfo,
} from '../models';
import { processTimeRangeFilter } from '../utils';
import { DashboardAdapter } from './dashboard.adapter';
import { MetadataApiService } from './metadata-api.service';

const PATH_DASHBOARD_LIST = '/dashboards';
const PATH_DASHBOARD = '/dashboards';

@Injectable({
  providedIn: 'root',
})
export class DashboardApiService implements DashboardAdapter {
  get apiUrl(): string {
    const config = this.kdConfigService.getConfig();

    return [config.apiUrl, 'api'].join('/');
  }

  constructor(
    private readonly http: HttpClient,
    private readonly kdConfigService: KdConfigService,
    private metadataApiService: MetadataApiService,
  ) {}

  loadList(pageParams: DashboardListQueryParams): Observable<DashboardListApiResponse> {
    return this.http
      .get<DashboardListApiResponse>(this.buildUrl(PATH_DASHBOARD_LIST), {
        params: new HttpParams({ fromObject: pageParams as Record<string, string> }),
      })
      .pipe();
  }

  loadOne(id: string): Observable<DashboardApi> {
    return this.http.get<DashboardApi>(`${this.buildUrl(PATH_DASHBOARD)}/${id}?projection=forDetailView`).pipe(
      switchMap((board) => {
        const procMetas = board.dataSources.map((ds) =>
          this.metadataApiService.getMetadataForDataSource(ds.id).pipe(
            map((metadata) => {
              if (metadata?.length) {
                ds.uiMetaData = metadata[0];
              }
              return ds;
            }),
            catchError(() => of(ds)),
          ),
        );

        return forkJoin(procMetas).pipe(map(() => board));
      }),
    );
  }

  connectDataSource(dashboardId: string, dataSources: BoardDataSource[]): Observable<unknown> {
    const linksParam: string[] = dataSources.reduce((acc: string[], currVal: BoardDataSource) => {
      return acc.concat(this.getLinkDataSourceParam(currVal));
    }, []);

    return this.http.put(`/api/dashboards/${dashboardId}/datasources`, linksParam.join('\n'), {
      headers: new HttpHeaders({
        'Content-Type': 'text/uri-list',
      }),
    });
  }

  createDashboard(workbookId: string, dashboard: Dashboard, option: BoardGlobalOptions): Observable<Dashboard> {
    const url = this.buildUrl(PATH_DASHBOARD);
    const boardDs: BoardDataSource = dashboard.dataSource;

    let params = {
      workBook: `/api/workbooks/${workbookId}`,
      configuration: {
        dataSource: boardDs,
        options: option,
      },
      name: dashboard.name,
      description: dashboard.description,
      temporaryId: dashboard.temporaryId,
    };

    params = this.processParams({ ...params });

    return this.http.post<Dashboard>(url, params).pipe(
      switchMap((board: Dashboard) => {
        return this.connectDataSource(
          board.id as string,
          'multi' === boardDs.type ? (boardDs.dataSources as BoardDataSource[]) : [boardDs],
        ).pipe(map(() => board));
      }),
    );
  }

  // TODO: need typings
  private processParams(param: any): any {
    if (!param) {
      return param;
    }

    const { configuration, ...restParams } = param;
    const { dataSource, layout, widgets, filters, customFields, ...restConfiguration } = configuration;
    const { dataSources, engineName, connType, uiFields, uiFilters, metaDataSource, orgDataSource, ...restDataSource } =
      dataSource;

    const newDataSource = {
      ...restDataSource,
      name: dataSource.engineName || dataSource.name,
      //  dataSources: dataSources?.map(({ uiFields, metaDataSource, ...item }) => item),
    };

    const newFilters = filters
      ?.map((item: TimeRangeFilter) => (dataSource.temporary ? item.ui?.importanceType !== 'recommended' : true))
      .map((item: TimeRangeFilter) => processTimeRangeFilter(item));

    const newConfiguration = {
      ...restConfiguration,
      dataSource: newDataSource,
      widgets: widgets?.filter((item: LayoutWidgetInfo) => item.isInLayout),
      filters: newFilters,
      userDefinedFields: customFields ? Object.values(customFields) : undefined,
      content: layout?.content,
    };

    return { ...restParams, configuration: newConfiguration };
  }

  removeDashboard(id: string): Observable<unknown> {
    return this.http.delete<unknown>(`${this.buildUrl(PATH_DASHBOARD)}/${id}`);
  }

  moveDashboard(id: string, workbookId: string): Observable<unknown> {
    return of(null);
  }

  copyToDashboard(id: string, workbookId: string): Observable<unknown> {
    return of(null);
  }

  private buildUrl(endpoint: string): string {
    return `${this.apiUrl}/${normalizeApiEndpoint(endpoint)}`;
  }

  private getLinkDataSourceParam(dataSource: BoardDataSource): string[] {
    let links: string[] = [dataSource.id as string];
    if (dataSource.type === 'mapping') {
      dataSource.joins.forEach((join: JoinMapping) => {
        links.push(join.id as string);
        if (join.join && join.join.id) {
          links.push(join.join.id);
        }
      });
    }
    links = links.map((dsId) => this.buildUrl(`/datasources/${dsId}`));

    return links;
  }
}
