import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, map, tap } from 'rxjs';

import { KdConfigService, downloadFile, normalizeApiEndpoint } from '@selfai-platform/shared';

@Injectable({ providedIn: 'root' })
export class ChartPdfDownloadService {
  get apiUrl(): string {
    const config = this.kdConfigService.getConfig();

    return [config.apiUrl, 'api'].join('/');
  }

  constructor(private readonly http: HttpClient, private readonly kdConfigService: KdConfigService) {}

  downloadChartPdf(fileName = 'chart.pdf', widgetId: string): Observable<void> {
    return this.downloadPdf(fileName, 'chart', widgetId);
  }

  downloadDashboardPdf(fileName = 'dashboard.pdf', dashboardId: string): Observable<void> {
    return this.downloadPdf(fileName, 'dashboard', dashboardId);
  }

  private downloadPdf(filename: string, domain: 'dashboard' | 'chart', itemId: string): Observable<void> {
    const httpParams: HttpParams = new HttpParams({ fromObject: { domain, itemId } });

    return this.http
      .get(this.buildUrl('/images/load/pdf'), {
        params: httpParams,
        responseType: 'blob',
        headers: {
          Accept: '*/*',
        },
      })
      .pipe(
        tap((content: Blob) => {
          downloadFile(filename, content);
        }),
        map(() => void 0),
      );
  }

  private buildUrl(endpoint: string): string {
    return `${this.apiUrl}/${normalizeApiEndpoint(endpoint)}`;
  }
}
