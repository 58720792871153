import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';

import { Observable, takeUntil } from 'rxjs';

import { Dashboard, DashboardDomainService } from '@selfai-platform/bi-domain';
import { DestroyService, KdConfigService, KdFeatureName } from '@selfai-platform/shared';

import { PopupService } from '../../../common/service/popup.service';
import { DashboardComponent } from '../../dashboard.component';

@Component({
  selector: 'selfai-bi-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class MainDashboardComponent implements OnInit {
  dashboard$: Observable<Dashboard>;

  @ViewChild(DashboardComponent, { static: true })
  private dashboardComponentRef: DashboardComponent;

  constructor(
    private readonly kdConfigService: KdConfigService,
    private readonly dashboardService: DashboardDomainService,
    private readonly popupService: PopupService,
    private readonly destroy$: DestroyService,
  ) {}

  ngOnInit(): void {
    const mainDashboardId = this.kdConfigService.getConfig().features[KdFeatureName.MAIN_DASHBOARD]?.mainDashboardId;

    if (typeof mainDashboardId !== 'string') {
      console.warn('Main dashboard id is not defined, it is equal to', mainDashboardId);
      return;
    }

    // TODO: show loading status with prime-ng components
    this.dashboardService
      .getDashboardLoading(mainDashboardId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((loading) => {
        this.popupService.ptLoading = loading;
        if (loading) {
          this.dashboardComponentRef.showBoardLoading();
        } else {
          this.dashboardComponentRef.hideBoardLoading();
        }
      });

    this.dashboardService.loadDashboard(mainDashboardId).pipe(takeUntil(this.destroy$)).subscribe();

    this.dashboard$ = this.dashboardService.getDashboard(mainDashboardId);
  }
}
