import { Inject, Injectable } from '@angular/core';
import { PACKAGE_VERSION } from '../tokens';
import { IScriptState } from './script-state';
import { IScript, SELFAI_APP_SCRIPT_STORE } from './script-store';

@Injectable()
export class ScriptService {
  private scripts: { [scriptName: string]: { loaded: boolean; src: string } } = {};

  constructor(
    @Inject(SELFAI_APP_SCRIPT_STORE) private scriptStore: IScript[],
    @Inject(PACKAGE_VERSION) private readonly packageVersion: string,
  ) {
    scriptStore.forEach((script) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }

  load(...scripts: string[]) {
    const promises: Promise<IScriptState>[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise<IScriptState>((resolve, reject) => {
      //resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        //load script
        const script = document.createElement('script') as HTMLScriptElement;
        script.type = 'text/javascript';
        script.src = this.addParam(this.scripts[name].src, `v=${this.packageVersion}`);
        script.onload = () => {
          this.scripts[name].loaded = true;
          resolve({ script: name, loaded: true, status: 'Loaded' });
        };
        script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

  private addParam(src: string, param: string): string {
    const cleanSrc = src.endsWith('?') ? src.slice(-1) : src;

    return cleanSrc.includes('?') ? `${cleanSrc}&${param}` : `${cleanSrc}?${param}`;
  }
}
