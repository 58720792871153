import { TreeNodeCube, TreeNodeType } from '@selfai-platform/pipeline-common';
import { TreeNode } from 'primeng/api';

export function getTreeNodeByCubeId(cubeId: string, treeNodes: TreeNode[]): TreeNodeCube | null {
  const findNode = (nodes: TreeNode[]): TreeNodeCube | null => {
    for (const node of nodes) {
      if (node.type === TreeNodeType.CUBE && node.data?.node.id === cubeId) {
        return node as TreeNodeCube;
      }
      if (node.children) {
        const foundNode = findNode(node.children);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null;
  };
  return findNode(treeNodes);
}
