import { Observable } from 'rxjs';
import {
  BoardDataSource,
  BoardGlobalOptions,
  Dashboard,
  DashboardApi,
  DashboardListApiResponse,
  DashboardListQueryParams,
} from '../models';

export abstract class DashboardAdapter {
  abstract loadList(pageParams: DashboardListQueryParams): Observable<DashboardListApiResponse>;
  abstract loadOne(id: string): Observable<DashboardApi>;
  abstract connectDataSource(dashboardId: string, dataSources: BoardDataSource[]): Observable<unknown>;
  abstract createDashboard(workbookId: string, dashboard: Dashboard, option: BoardGlobalOptions): Observable<Dashboard>;
  abstract removeDashboard(id: string): Observable<unknown>;
  abstract moveDashboard(id: string, workbookId: string): Observable<unknown>;
  abstract copyToDashboard(id: string, workbookId: string): Observable<unknown>;
}
