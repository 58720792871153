import { Injectable } from '@angular/core';
import { KdConfig } from './kd-config.model';
import { ConfigService } from '../config.service';
import { KdFeatureName } from './kd-feature-name.enum';

@Injectable({
  providedIn: 'root',
})
export class KdConfigService {
  constructor(private readonly configService: ConfigService) {}

  public getConfig(): KdConfig {
    try {
      return this.configService.getConfig().knowledgeDesigner;
    } catch (e: unknown) {
      console.warn(
        'Configuration for Knowledge Designer is not available. See config.json section knowledgeDesigner',
        e,
      );
      return {
        apiUrl: 'not defined',
        enabled: false,
        features: {
          [KdFeatureName.MAIN_DASHBOARD]: {
            enabled: false,
            // main Dashboard Id
            mainDashboardId: null,
          },
          [KdFeatureName.WORKBOOKS]: {
            enabled: false,
          },
          [KdFeatureName.DASHBOARDS]: {
            enabled: false,
          },
          [KdFeatureName.CHARTS]: {
            enabled: false,
          },
          [KdFeatureName.DATAFLOWS]: {
            enabled: false,
          },
          [KdFeatureName.DATASETS]: {
            enabled: false,
          },
          [KdFeatureName.DATASOURCES]: {
            enabled: false,
          },
        },
      };
    }
  }
}
