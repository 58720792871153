import { HistoryEntity, PageResult } from '../../core';
import { PrDataSnapshot, SsType } from '../../data-preparation';
import {
  ConnectionType,
  DataSourceType,
  DatasourceStatus,
  FieldRole,
  Granularity,
  GranularityType,
  IngestionStatus,
  LogicalType,
  SourceType,
  TempDsStatus,
  TimestampType,
} from '../enums';
import { Connection, CreateConnectionData, CreateDatabaseData, CreateSourceSchemaData } from './connection.model';
import { DatasourceField } from './field.model';
import { Ingestion } from './ingestion.model';
import { MetadataColumn } from './metadata-column.model';

export interface Datasource extends HistoryEntity {
  id: string;
  name: string;
  engineName?: string;
  ownerId?: string;
  description?: string;
  dsType?: string;
  connType?: ConnectionType;
  srcType?: SourceType;
  granularity?: GranularityType;
  segGranularity?: GranularityType;
  partitionKeys?: string;
  partitionSeparator?: string;
  status?: DatasourceStatus;
  published: boolean;
  linkedWorkspaces: number;
  ingestion?: Ingestion;
  fields: DatasourceField[];
  snapshot?: PrDataSnapshot;

  connection?: Connection;
  summary?: DataSourceSummary;
  fieldsMatched: boolean;

  implementor?: string;
  owner?: any[];
  countOfDataSources: number;

  valid: boolean;

  num?: number;
  temporary?: TemporaryDatasource;
  uiMetaData?: DatasourceMetaData;
}

export function createDatasource(params: Partial<Datasource> = {}): Datasource {
  return {
    id: '',
    name: '',
    published: false,
    linkedWorkspaces: 0,
    fields: [],
    fieldsMatched: false,
    countOfDataSources: 0,
    valid: false,
    ...params,
  };
}

export interface DatasourceMetaData {
  name: string;
  id: string;
  description: string;
  columns: MetadataColumn[];
}

export interface DataSourceSummary {
  id: number;
  ingestionMinTime: Date;
  ingestionMaxTime: Date;
  lastAccessTime: Date;
  size: number;
  count: number;
}

export interface TemporaryDatasource {
  dataSourceId: string;
  expired: number;
  id: string;
  modifiedBy: string;
  modifiedTime: string;
  name: string;
  nextExpireTime: string;
  queryId: string;
  registeredTime: string;
  status: TempDsStatus;
  volatiled: boolean;
  filters?: any[];
}

export function createTemporaryDatasource(params: Partial<TemporaryDatasource> = {}): TemporaryDatasource {
  return {
    dataSourceId: '',
    expired: 0,
    id: '',
    modifiedBy: '',
    modifiedTime: '',
    name: '',
    nextExpireTime: '',
    queryId: '',
    registeredTime: '',
    status: TempDsStatus.ENABLE,
    volatiled: false,
    ...params,
  };
}

export interface DatasourceFile {
  filename: string;

  filepath: string;

  fileSize: number;

  filekey: string;

  sheets: string[];

  selectedSheetName: any;

  selectedFile: File;
}

export interface Hive {
  fields: DatasourceField[];

  partitionFields: DatasourceField[];

  data: any[];

  fileFormat: any;
}

export interface DatasourceInfo {
  datasource?: Datasource;
  datasourceId?: string;
  type?: SourceType;
  dsType?: DataSourceType;
  connType?: ConnectionType;
  fieldList?: DatasourceField[];
  fieldData?: CreateDataSourceFieldData[];
  connectionData?: CreateConnectionData;
  uploadData?: any;
  databaseData?: CreateDatabaseData;
  fileData?: any;
  snapshotData?: CreateSnapShotData;
  schemaData?: CreateSourceSchemaData;
  configureData?: CreateSourceConfigureData;
  ingestionData?: CreateSourceIngestionData;
  completeData?: CreateSourceCompleteData;
  workbenchFl?: boolean;
  isDisableDataSelect?: boolean;
  kafkaData?: KafkaData;
}

export function createDatasourceInfo(params: Partial<DatasourceInfo> = {}): DatasourceInfo {
  return {
    ...params,
  };
}

export interface CreateSnapShotData {
  snapshotList: any[];
  selectedSnapshot?: Partial<PrDataSnapshot>;
  searchText: string;
  selectedSnapshotTypeFilter?: TypeFilterObject;
  pageResult?: PageResult;
  sort: string;
  errorSnapshotIdList: string[];
}

export function createCreateSnapShotData(params: Partial<CreateSnapShotData> = {}): CreateSnapShotData {
  return {
    snapshotList: [],
    selectedSnapshot: undefined,
    searchText: '',
    pageResult: undefined,
    sort: 'createdTime,desc',
    errorSnapshotIdList: [],
    ...params,
  };
}

export interface CreateSourceConfigureData {
  _originFieldList: DatasourceField[];
  _originFieldData: any;

  filteredFieldList: DatasourceField[];

  timestampFieldList: DatasourceField[];

  selectedField: DatasourceField;

  selectedFieldDataList: any;

  selectedTimestampField: DatasourceField;

  selectedTimestampType: TimestampType;

  searchText: string;

  selectedLogicalTypeFilter: TypeFilterObject;
  selectedRoleTypeFilter: TypeFilterObject;

  timestampFieldData: any;

  selectedAction: {
    selectedType: string;
    selectedRoleType: TypeFilterObject;
    selectedLogicalType: TypeFilterObject;
  };
}

export interface CreateSourceIngestionData {
  queryGranularityList: GranularityObject[];

  selectedSegmentGranularity: GranularityObject;

  selectedQueryGranularity: GranularityObject;

  selectedRollUpType: {
    label: string;
    value: boolean;
  };

  tuningConfig: any[];

  isShowAdvancedSetting: boolean;

  startIntervalText: string;
  endIntervalText: string;

  intervalValidMessage: string;

  intervalValid: boolean;

  granularityUnit: number;
  selectedExpirationTime?: string;
  selectedIngestionType?: {
    label: string;
    value: string;
  };
  selectedIngestionScopeType?: {
    label: string;
    value: string;
  };
  selectedPartitionType?: {
    label: string;
    value: string;
  };
  jobProperties?: {
    key: string;
    value: string;
  }[];
  partitionKeyList?: string[];
  selectedBatchType?: {
    label: string;
    value: string;
  };
  selectedHour?: string;
  selectedMinute?: string;
  selectedWeeklyTime?: string;
  selectedDailyTime?: string;
  cronText?: string;
  ingestionOnceRow?: number;
  ingestionPeriodRow?: number;
  isStrictMode?: boolean;
  isClickedNext?: boolean;
  isShowPartitionValidResult?: boolean;
  partitionValidationResult?: boolean;
  partitionValidationResultMessage?: string;
  partitionValidationResultData?: any;
  dateList?: string[];
}

export interface CreateSourceCompleteData {
  sourceName: string;

  sourceDescription: string;

  isInvalidName: boolean;
  isInvalidDesc: boolean;

  nameInvalidMessage: string;
  descInvalidMessage: string;
}

export interface KafkaData {
  bootstrapServer: string;
  topic: string;
  type: string;
  fieldList: DatasourceField[];
  fieldData: CreateDataSourceFieldData[];
  totalRows: number;
  useKafkaData: boolean;
}

export interface CreateDataSourceFieldData {
  [fieldSqlName: string]: unknown;
  id?: number | string;
  _idProperty_?: number;
}

export interface SelectedFilter {
  label: string;
  value: string;
  checked?: boolean;
  icon: string;
}

export interface IngestionHistory extends HistoryEntity {
  dataSourceId: string;

  ingestionId: string;

  duration: number;

  status: IngestionStatus;

  cause: string;
}

export interface TypeFilterObject {
  label: string;
  value: string | LogicalType | FieldRole | SsType;
  icon?: string;
}

export interface GranularityObject {
  label: string;
  value: Granularity;
}
