import { isNullOrUndefined } from 'util';

import {
  Component,
  ElementRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import $ from 'jquery';
import cloneDeep from 'lodash/cloneDeep';

import {
  BoundFilter,
  Candidate,
  DIRECTION,
  Dashboard,
  DatasourceField as Field,
  Filter,
  FilterWidget,
  FilterWidgetConfiguration,
  InclusionFilter,
  InclusionSelectorType,
  InclusionSortBy,
  TimeFilter,
  TimeRangeFilter,
  TimeRelativeFilter,
  TimeRelativeTense,
  TimeUnit,
  createCandidate,
  createInclusionItemSort,
} from '@selfai-platform/bi-domain';

import { DestroyService } from '@selfai-platform/shared';
import { SubscribeArg } from '../../../common/domain/subscribe-arg';
import { EventBroadcaster } from '../../../common/event/event.broadcaster';
import { PopupService } from '../../../common/service/popup.service';
import { StringUtil } from '../../../common/util/string.util';
import { DatasourceService } from '../../../datasource/service/datasource.service';
import { setDatasourceForDashboard } from '../../component/dashboard-layout/dashboard-layout';
import { BoundFilterComponent } from '../../filters/bound-filter/bound-filter.component';
import { FilterSelectComponent } from '../../filters/component/filter-select/filter-select.component';
import { DashboardUtil } from '../../util/dashboard.util';
import { FilterUtil } from '../../util/filter.util';
import { AbstractWidgetComponent } from '../abstract-widget.component';

@Component({
  selector: 'filter-widget',
  templateUrl: './filter-widget.component.html',
  providers: [DestroyService],
})
export class FilterWidgetComponent extends AbstractWidgetComponent implements OnInit, OnDestroy {
  @ViewChild(FilterSelectComponent)
  private filterSelectComponent: FilterSelectComponent;

  @ViewChild(BoundFilterComponent)
  private _boundFilterComp: BoundFilterComponent;

  @ViewChild('filterWidget')
  private filterWidget: ElementRef;

  @Input('widget')
  public inputWidget: FilterWidget;

  @Input()
  dashboard: Dashboard;

  public list: string[];
  public searchText: string;
  public widget: FilterWidget;
  public pageNum = 0;
  public inclusionSelectorType = InclusionSelectorType;
  public candidateList: Candidate[] = [];
  public isSearchingCandidateAvailability = false;
  public selectedItems: Candidate[];
  public filter: Filter;
  public field: Field;
  public isTimeFilter = false;
  public isContinuousByAll = false;
  public isDiscontinuousFilter = false;
  public isAllTypeTimeFilter = false;
  public isRelativeTypeTimeFilter = false;
  public isRangeTypeTimeFilter = false;
  public isListTypeTimeFilter = false;
  private RISING_LAYER_Z_INDEX = 300;

  constructor(
    private popupService: PopupService,
    private datasourceService: DatasourceService,
    protected broadCaster: EventBroadcaster,
    protected elementRef: ElementRef,
    protected injector: Injector,
    private activatedRoute: ActivatedRoute,
  ) {
    super(broadCaster, elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();

    const popupSubscribe = this.popupService.filterView$.subscribe((data: SubscribeArg) => {
      if ('reset-general-filter' === data.name) {
        if (this.filter.ui.importanceType === 'general') {
          const inclusionFilter: InclusionFilter = <InclusionFilter>this.filter;
          inclusionFilter.valueList = [];
          switch (inclusionFilter.selector) {
            case InclusionSelectorType.SINGLE_COMBO:
              this.filterSelectComponent.reset(inclusionFilter.valueList);
              break;
            case InclusionSelectorType.MULTI_COMBO:
              this.filterSelectComponent.updateView(this.selectedItems);
              break;
            default:
              this._candidate(inclusionFilter);
          }
        }
      }
      this.safelyDetectChanges();
    });
    this.subscriptions.push(popupSubscribe);

    this.subscriptions.push(
      this.broadCaster.on<any>('CHANGE_FILTER_SELECTOR').subscribe((data) => {
        if (this.widget.id === data.widget.id) {
          this.setConfiguration(data.widget.configuration);
          this.safelyDetectChanges();
        }
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<any>('SET_WIDGET_CONFIG').subscribe((data) => {
        if (data.widgetId === this.widget.id) {
          this.setConfiguration(data.config);
          this.safelyDetectChanges();
        }
      }),
    );
  }

  public ngOnChanges(changes: SimpleChanges) {
    const widgetChanges: SimpleChange = changes.inputWidget;
    if (widgetChanges?.currentValue && this.dashboard) {
      this.widget = widgetChanges.currentValue;
      const filter: Filter = this.getFilter();

      this.field = DashboardUtil.getFieldByName(
        setDatasourceForDashboard(this.dashboard)[0],
        filter.dataSource,
        filter.field,
        filter.ref,
      );

      if (this.field) {
        this._candidate(filter);
      } else {
        this.processStart();
        this._showError({
          code: 'GB0000',
          details: this.translateService.instant('msg.board.error.missing-field'),
        });
        this.processEnd();
      }
    }
  }

  public ngAfterViewInit() {
    super.ngAfterViewInit();
    this.safelyDetectChanges();
  }

  get filteredCandidateList(): Candidate[] {
    const candidateValues: string[] = (this.filter as any).candidateValues;

    return this.candidateList.filter((item) => {
      return candidateValues.includes(item.name);
    });
  }

  public isListFilter() {
    return (
      this.convertToIncludeFilter(this.filter).selector === this.inclusionSelectorType.SINGLE_COMBO ||
      this.convertToIncludeFilter(this.filter).selector === this.inclusionSelectorType.MULTI_COMBO
    );
  }

  public mouseoutWidget() {
    if (this.filterSelectComponent && this.filterSelectComponent.isShowSelectList) {
      this.filterSelectComponent.toggleSelectList();
    }
  }

  public setConfiguration(objConfig: FilterWidgetConfiguration) {
    if (!this.isError) {
      this.widget.configuration = objConfig;
      this._candidate(this.getFilter());
    }
  }

  public getFilter(): Filter {
    const conf: FilterWidgetConfiguration = <FilterWidgetConfiguration>this.widget.configuration;
    const filter: Filter = cloneDeep(conf.filter);
    if (FilterUtil.isTimeFilter(filter)) {
      this._setTimeFilterStatus(<TimeFilter>filter);
    }
    return filter;
  }

  public convertToIncludeFilter(filter: Filter): InclusionFilter {
    return <InclusionFilter>filter;
  }

  public getWidgetName(): string {
    return this.widget && this.widget.name ? this.widget.name : this.getConfiguration().filter.field;
  }

  public getConfiguration(): FilterWidgetConfiguration {
    return <FilterWidgetConfiguration>this.widget.configuration;
  }

  public addDefineValues(filter: InclusionFilter) {
    if (filter.definedValues && filter.definedValues.length > 0) {
      this.candidateList = filter.definedValues.map((item) => this._stringToCandidate(item)).concat(this.candidateList);
    }
  }

  public checkAllInclude($event?: any) {
    if (this.isEditMode) {
      this.alertPrimeService.info(this.translateService.instant('msg.board.alert.not-select-editmode'));
      return;
    }

    const filter = <InclusionFilter>this.filter;

    if (filter.selector === InclusionSelectorType.MULTI_LIST) {
      const checked = $event.target ? $event.target.checked : $event.currentTarget.checked;
      if (checked) {
        filter.valueList = [];
        this.candidateList.forEach((candidate) => filter.valueList.push(candidate.name));
      } else {
        filter.valueList = [];
      }
    } else {
      filter.valueList = [];
    }
    this._broadcastChangeFilter(this.filter);
  }

  public toggleOptionsSelectComp(isShowOptions: boolean) {
    if (isShowOptions) {
      this._setContainerZIndex(this.RISING_LAYER_Z_INDEX);
    } else {
      this._setContainerZIndex('');
    }
  }

  public onSelectInclude(item: any) {
    const filter = <InclusionFilter>this.filter;

    if (this.isEditMode && filter.selector !== InclusionSelectorType.MULTI_COMBO) {
      this.alertPrimeService.info(this.translateService.instant('msg.board.alert.not-select-editmode'));
      return;
    }

    this._setContainerZIndex('');

    if (filter.selector === InclusionSelectorType.SINGLE_LIST) {
      filter.valueList = [];
      filter.valueList.push(item.name);
    } else if (filter.selector === InclusionSelectorType.MULTI_LIST) {
      if (-1 === filter.valueList.indexOf(item.name)) {
        filter.valueList.push(item.name);
      } else {
        const idx = filter.valueList.indexOf(item.name);
        filter.valueList.splice(idx, 1);
      }
    } else {
      if (Array.isArray(item)) {
        filter.valueList = item.map((data) => data.name);
      } else {
        filter.valueList = [];
        if (item !== 'ALL') {
          filter.valueList.push(item.name);
        }
      }
    }

    this.filter = filter;
    this._broadcastChangeFilter(this.filter);
  }

  public changeFilterEvent(filter: TimeFilter) {
    const isTimeFilter = FilterUtil.isTimeFilter(filter);
    if (isTimeFilter) {
      this.filter = cloneDeep(filter);
      this._broadcastChangeFilter(this.filter);
    }
  }

  public applyValue() {
    const filter = this._boundFilterComp.getData();

    if (filter.min > filter.max) filter.max = filter.min;
    if (filter.min < filter.minValue) filter.min = filter.minValue;
    if (filter.max < filter.minValue) filter.max = filter.minValue;
    if (filter.min > filter.maxValue) filter.min = filter.maxValue;
    if (filter.max > filter.maxValue) filter.max = filter.maxValue;
    this._broadcastChangeFilter(filter);
  }

  public candidateFromSearchText() {
    if (StringUtil.isEmpty(this.searchText)) {
      return;
    }

    this.loadingShow();
    this.datasourceService
      .getCandidateForFilter(this.filter, this.dashboard, [], null, null, this.searchText)
      .then((resultCandidates) => {
        if (resultCandidates && resultCandidates.length > 0) {
          resultCandidates.forEach((resultCandidate) => {
            if (this.existCandidate(resultCandidate.field) === false) {
              const candidate = createCandidate({
                name: resultCandidate.field,
                count: resultCandidate.count,
                isTemporary: true,
              });
              this.candidateList.unshift(candidate);
            }
          });

          this.safelyDetectChanges();
        }

        this.loadingHide();
      })
      .catch((error) => {
        this.commonExceptionHandler(error);
      });
  }

  public isNoFiltering(): boolean {
    const filter = <InclusionFilter>this.filter;

    return (
      (filter.selector === InclusionSelectorType.SINGLE_COMBO ||
        filter.selector === InclusionSelectorType.MULTI_COMBO) &&
      filter.valueList &&
      filter.valueList.length == 0
    );
  }

  private _broadcastChangeFilter(filter: Filter) {
    this.broadCaster.broadcast('CHANGE_FILTER_WIDGET', { filter: filter });
  }

  private _initialContainer() {
    this.isError = false;

    this.safelyDetectChanges();
    if (!this.isEditMode) {
      const filter: Filter = this.filter;
      const isInterval: boolean = 'interval' === filter.type;
      const isIncludeCombo: boolean =
        'include' === filter.type &&
        ((<InclusionFilter>filter).selector === InclusionSelectorType.SINGLE_COMBO ||
          (<InclusionFilter>filter).selector === InclusionSelectorType.MULTI_COMBO);
      if (isInterval || isIncludeCombo) {
        const $filterWidgetEl = $(this.filterWidget.nativeElement);
        $filterWidgetEl.closest('.lm_item .lm_stack').css({ 'z-index': 100, position: 'relative' });
        $filterWidgetEl.closest('.lm_content').css('overflow', 'inherit');
      }
    }
    this.isShowTitle || this._setIsVisibleScrollbar();
  }

  private _setContainerZIndex(index: number | string) {
    const $filterWidgetEl = $(this.filterWidget.nativeElement);
    if ('' === index) {
      $filterWidgetEl.closest('.lm_item .lm_stack').css({ 'z-index': '', position: '' });
    } else {
      $filterWidgetEl.closest('.lm_item .lm_stack').css({ 'z-index': index, position: 'relative' });
    }
  }

  private _candidate(filter: Filter) {
    this.processStart();
    this._hideError();

    if ('include' === filter.type || 'bound' === filter.type) {
      this.datasourceService
        .getCandidateForFilter(filter, this.dashboard, [], this.field)
        .then((result) => {
          if ('include' === filter.type) {
            const inclusionFilter: InclusionFilter = <InclusionFilter>filter;
            if (inclusionFilter['valueList'] && inclusionFilter.valueList.length > 0) {
              this.selectedItems = inclusionFilter.valueList.map((item) => this._stringToCandidate(item));
            }

            this.candidateList = [];

            this.addDefineValues(inclusionFilter);

            if (result && Array.isArray(result) && result.length > 100) {
              this.isSearchingCandidateAvailability = true;
            } else {
              this.isSearchingCandidateAvailability = false;
            }

            const selectedCandidateValues: string[] = inclusionFilter.candidateValues;

            if (selectedCandidateValues && 0 < selectedCandidateValues.length) {
              result.forEach((item) => {
                if (selectedCandidateValues.some((selectedItem) => item.field === selectedItem)) {
                  this.candidateList.push(this._objToCandidate(item, this.field));
                }
              });

              this.candidateList = this.candidateList.concat(
                selectedCandidateValues
                  .map((item) => this._stringToCandidate(item))
                  .filter((item) => -1 === this.candidateList.findIndex((can) => can.name === item.name)),
              );
            } else {
              result.forEach((item) => {
                this.candidateList.push(this._objToCandidate(item, this.field));
              });
            }

            if (isNullOrUndefined(inclusionFilter.sort)) {
              inclusionFilter.sort = createInclusionItemSort(InclusionSortBy.TEXT, DIRECTION.ASC);
            }
            if (InclusionSortBy.COUNT === inclusionFilter.sort.by) {
              this.candidateList.sort((val1: Candidate, val2: Candidate) => {
                return DIRECTION.ASC === inclusionFilter.sort.direction
                  ? val1.count - val2.count
                  : val2.count - val1.count;
              });
            } else {
              this.candidateList.sort((val1: Candidate, val2: Candidate) => {
                const name1: string = val1.name ? val1.name.toUpperCase() : '';
                const name2: string = val2.name ? val2.name.toUpperCase() : '';
                if (name1 < name2) {
                  return DIRECTION.ASC === inclusionFilter.sort.direction ? -1 : 1;
                }
                if (name1 > name2) {
                  return DIRECTION.ASC === inclusionFilter.sort.direction ? 1 : -1;
                }
                return 0;
              });
            }

            this.filter = inclusionFilter;
          } else if ('bound' === filter.type) {
            const boundFilter: BoundFilter = <BoundFilter>filter;
            if (result && result.hasOwnProperty('maxValue')) {
              if (boundFilter.min === 0 && boundFilter.max === 0) {
                boundFilter.min = result.minValue;
                boundFilter.max = result.maxValue;
              }
              boundFilter.maxValue = result.maxValue;
              boundFilter.minValue = result.minValue;
            } else {
              boundFilter.min = null;
              boundFilter.max = null;
              boundFilter.maxValue = null;
              boundFilter.minValue = null;
            }

            this.filter = boundFilter;
          }

          this._setQueryParameterAsDefaultValue();
          this._initialContainer();

          this.processEnd();
        })
        .catch((error) => {
          this._showError(error);
          this.candidateList = [];
          this.processEnd();
        });
    } else {
      this.filter = filter;

      this._setQueryParameterAsDefaultValue();
      this._initialContainer();
      this.processEnd();
    }
  }

  private _setQueryParameterAsDefaultValue() {
    if (this.filter) {
      this.activatedRoute.queryParams.subscribe((params) => {
        Object.keys(params).forEach((key) => {
          if (key !== this.filter.field) {
            return;
          }
          const value: any = params[key];
          if (this.filter.type === 'include') {
            const paramValues = Array.isArray(value) ? value : [value];
            this.selectedItems = this.candidateList.filter((item: Candidate) => {
              const matchedItems = paramValues.filter((param) => {
                return item.name === param;
              });
              return matchedItems.length > 0;
            });
          } else if (this.filter.type === 'bound') {
            const boundFilter: BoundFilter = <BoundFilter>this.filter;
            const paramValues: string[] = value.split(',');
            if (paramValues.length == 2) {
              const min = Number(paramValues[0]);
              const max = Number(paramValues[1]);
              if (!isNaN(min) && !isNaN(max)) {
                boundFilter.min = min;
                boundFilter.max = max;
                boundFilter.minValue = min;
                boundFilter.maxValue = max;
              }
            }
          } else if (this.filter.type === 'time_range') {
            const timeRangeFilter: TimeRangeFilter = <TimeRangeFilter>this.filter;
            if (Array.isArray(value)) {
              timeRangeFilter.intervals = value;
            } else {
              timeRangeFilter.intervals = [value];
            }
          } else if (this.filter.type === 'time_relative') {
            const timeRelativeFilter: TimeRelativeFilter = <TimeRelativeFilter>this.filter;
            const valueAttributes = value.split(',');
            valueAttributes.forEach((attr) => {
              const keyValue: string[] = attr.split(':');
              if (keyValue[0] === 'tense') {
                timeRelativeFilter.tense = TimeRelativeTense[keyValue[1]];
              } else if (keyValue[0] === 'relTimeUnit') {
                timeRelativeFilter.relTimeUnit = TimeUnit[keyValue[1]];
              } else if (keyValue[0] === 'value') {
                timeRelativeFilter.value = +keyValue[1];
              }
            });
          } else if (this.filter.type === 'time_list') {
            const paramValues = Array.isArray(value) ? value : [value];
            this.selectedItems = this.candidateList.filter((item: Candidate) => {
              const matchedItems = paramValues.filter((param) => {
                return item.name === param;
              });
              return matchedItems.length > 0;
            });
          }
        });
      });
    }
  }

  private _objToCandidate(item: any, field: Field): Candidate {
    const candidate = createCandidate();
    if (item.hasOwnProperty('field') && StringUtil.isNotEmpty(item['field'] + '')) {
      candidate.name = item['field'];
      candidate.count = item['count'];
    } else {
      candidate.name = item[field.name];
      candidate.count = item['count'];
    }
    return candidate;
  }

  private _stringToCandidate(item: string, isDefine: boolean = false): Candidate {
    const candidate = createCandidate({
      name: item,
      count: 0,
      isDefinedValue: isDefine,
    });
    return candidate;
  }

  private _setIsVisibleScrollbar() {
    if (this.filterWidget) {
      const $container: JQuery = $(this.filterWidget.nativeElement).find('.ddp-ui-widget-contents');
      const $contents: JQuery = $container.find('ul');
      this.isVisibleScrollbar = $container.height() < $contents.height();
      this.safelyDetectChanges();
    }
  }

  private _setTimeFilterStatus(timeFilter: TimeFilter) {
    this.isTimeFilter = true;
    this.isContinuousByAll = FilterUtil.isContinuousByAll(timeFilter);
    this.isDiscontinuousFilter = FilterUtil.isDiscontinuousTimeFilter(timeFilter);
    this.isAllTypeTimeFilter = FilterUtil.isTimeAllFilter(timeFilter);
    this.isRelativeTypeTimeFilter = FilterUtil.isTimeRelativeFilter(timeFilter);
    this.isRangeTypeTimeFilter = FilterUtil.isTimeRangeFilter(timeFilter);
    this.isListTypeTimeFilter = FilterUtil.isTimeListFilter(timeFilter);
  }

  private existCandidate(name: string): boolean {
    const filteredCandidates = this.candidateList.filter((candidate) => candidate.name === name);
    if (filteredCandidates != null && filteredCandidates.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}
