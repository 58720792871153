<table class="ddp-table-base" *ngIf="editRoleSet">
  <colgroup>
    <col width="*" />
    <col [ngStyle]="editMode ? { width: '95px' } : { width: '*' }" />
    <col [ngStyle]="editMode ? { width: '156px' } : { width: '*' }" />
    <col [ngStyle]="editMode ? { width: '156px' } : { width: '*' }" />
    <col [ngStyle]="editMode ? { width: '156px' } : { width: '*' }" />
    <col [ngStyle]="editMode ? { width: '104px' } : { width: '*' }" />
    <col *ngIf="editMode" width="52px" />
  </colgroup>
  <thead>
    <tr>
      <th rowspan="2">{{ 'msg.space.ui.set-permission-schema.userrole' | translate }}</th>
      <th rowspan="2">{{ 'msg.space.ui.set-permission-schema.defaultrole' | translate }}</th>
      <th>
        <strong>{{ 'msg.space.ui.set-permission-schema.workbook' | translate }}</strong>
      </th>
      <th>
        <strong>{{ 'msg.space.ui.set-permission-schema.notebook' | translate }}</strong>
      </th>
      <th>
        <strong>{{ 'msg.space.ui.set-permission-schema.workbench' | translate }}</strong>
      </th>
      <th>
        <strong>{{ 'msg.space.ui.set-permission-schema.workspace' | translate }}</strong>
      </th>
      <th *ngIf="editMode" rowspan="2"></th>
    </tr>
    <tr style="word-wrap: break-word">
      <th>
        <div class="ddp-data-name">{{ 'msg.space.ui.set-permission-schema.view' | translate }}</div>
        <div class="ddp-data-name">{{ 'msg.space.ui.set-permission-schema.create' | translate }}</div>
        <div class="ddp-data-name">{{ 'msg.space.ui.set-permission-schema.editany' | translate }}</div>
      </th>
      <th>
        <div class="ddp-data-name">{{ 'msg.space.ui.set-permission-schema.view' | translate }}</div>
        <div class="ddp-data-name">{{ 'msg.space.ui.set-permission-schema.create' | translate }}</div>
        <div class="ddp-data-name">{{ 'msg.space.ui.set-permission-schema.editany' | translate }}</div>
      </th>
      <th>
        <div class="ddp-data-name">{{ 'msg.space.ui.set-permission-schema.view' | translate }}</div>
        <div class="ddp-data-name">{{ 'msg.space.ui.set-permission-schema.create' | translate }}</div>
        <div class="ddp-data-name">{{ 'msg.space.ui.set-permission-schema.editany' | translate }}</div>
      </th>
      <th>
        <div class="ddp-data-name2">
          {{ 'msg.space.ui.set-permission-schema.createfolders' | translate }}
        </div>
        <div class="ddp-data-name2">
          {{ 'msg.space.ui.set-permission-schema.setconfig' | translate }}
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let role of editRoleSet.roles; let idx = index" [class.ddp-line]="role['hover']">
      <td [class.ddp-error]="role['error']">
        <span *ngIf="!editMode" class="ddp-data-role">{{ role.name }}</span>

        <div *ngIf="editMode" class="ddp-txt-edit" [class.ddp-selected]="role['edit']">
          <span class="ddp-data-name">
            {{ role.name }}
            <em (click)="role['edit'] = true" class="ddp-icon-edit2"></em>
          </span>
          <div class="ddp-wrap-input">
            <input
              type="text"
              class="ddp-input-edit"
              placeholder="{{ role.name }}"
              [(ngModel)]="role['editName']"
              (keypress)="setRoleNameByKeyboard($event, role)"
            />
            <span (click)="$event.stopPropagation(); setRoleName(role)" class="ddp-btn-check"></span>
          </div>
        </div>
      </td>

      <td (click)="clickDefaultRole(idx)">
        <span *ngIf="!editMode && role.defaultRole" class="ddp-select-role"></span>
        <label *ngIf="editMode" class="ddp-label-radio2">
          <input name="checkDefaultRole" type="radio" [disabled]="!editMode" [checked]="role.defaultRole" />
          <i class="ddp-icon-radio"></i>
        </label>
      </td>

      <td>
        <div
          (click)="togglePerm(role, 'PERM_WORKSPACE_VIEW_WORKBOOK')"
          class="ddp-data-det"
          [ngStyle]="{ cursor: editMode ? 'pointer' : 'default' }"
        >
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_VIEW_WORKBOOK')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_VIEW_WORKBOOK')">-</em>
        </div>
        <div
          (click)="togglePerm(role, 'PERM_WORKSPACE_EDIT_WORKBOOK')"
          class="ddp-data-det"
          [ngStyle]="{ cursor: editMode ? 'pointer' : 'default' }"
        >
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_EDIT_WORKBOOK')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_EDIT_WORKBOOK')">-</em>
        </div>
        <div
          (click)="togglePerm(role, 'PERM_WORKSPACE_MANAGE_WORKBOOK')"
          class="ddp-data-det"
          [ngStyle]="{ cursor: editMode ? 'pointer' : 'default' }"
        >
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_WORKBOOK')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_WORKBOOK')">-</em>
        </div>
      </td>

      <td>
        <div
          (click)="togglePerm(role, 'PERM_WORKSPACE_VIEW_NOTEBOOK')"
          class="ddp-data-det"
          [ngStyle]="{ cursor: editMode ? 'pointer' : 'default' }"
        >
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_VIEW_NOTEBOOK')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_VIEW_NOTEBOOK')">-</em>
        </div>
        <div
          (click)="togglePerm(role, 'PERM_WORKSPACE_EDIT_NOTEBOOK')"
          class="ddp-data-det"
          [ngStyle]="{ cursor: editMode ? 'pointer' : 'default' }"
        >
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_EDIT_NOTEBOOK')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_EDIT_NOTEBOOK')">-</em>
        </div>
        <div
          (click)="togglePerm(role, 'PERM_WORKSPACE_MANAGE_NOTEBOOK')"
          class="ddp-data-det"
          [ngStyle]="{ cursor: editMode ? 'pointer' : 'default' }"
        >
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_NOTEBOOK')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_NOTEBOOK')">-</em>
        </div>
      </td>

      <td>
        <div
          (click)="togglePerm(role, 'PERM_WORKSPACE_VIEW_WORKBENCH')"
          class="ddp-data-det"
          [ngStyle]="{ cursor: editMode ? 'pointer' : 'default' }"
        >
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_VIEW_WORKBENCH')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_VIEW_WORKBENCH')">-</em>
        </div>
        <div
          (click)="togglePerm(role, 'PERM_WORKSPACE_EDIT_WORKBENCH')"
          class="ddp-data-det"
          [ngStyle]="{ cursor: editMode ? 'pointer' : 'default' }"
        >
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_EDIT_WORKBENCH')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_EDIT_WORKBENCH')">-</em>
        </div>
        <div
          (click)="togglePerm(role, 'PERM_WORKSPACE_MANAGE_WORKBENCH')"
          class="ddp-data-det"
          [ngStyle]="{ cursor: editMode ? 'pointer' : 'default' }"
        >
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_WORKBENCH')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_WORKBENCH')">-</em>
        </div>
      </td>

      <td>
        <div
          (click)="togglePerm(role, 'PERM_WORKSPACE_MANAGE_FOLDER')"
          class="ddp-data-det2"
          [ngStyle]="{ cursor: editMode ? 'pointer' : 'default' }"
        >
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_FOLDER')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_FOLDER')">-</em>
        </div>
        <div
          (click)="togglePerm(role, 'PERM_WORKSPACE_MANAGE_WORKSPACE')"
          class="ddp-data-det2"
          [ngStyle]="{ cursor: editMode ? 'pointer' : 'default' }"
        >
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_WORKSPACE')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_WORKSPACE')">-</em>
        </div>
      </td>

      <td *ngIf="editMode">
        <a
          (click)="removeRole(idx)"
          (mouseenter)="role['hover'] = true"
          (mouseleave)="role['hover'] = false"
          href="javascript:"
          class="ddp-delete"
          *ngIf="!role.defaultRole"
        ></a>
      </td>
    </tr>
    <tr *ngIf="editMode">
      <td style="text-align: left">
        <a (click)="addRole()" href="javascript:" class="ddp-link-add">{{ 'msg.comm.btn.add' | translate }}</a>
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  </tbody>
</table>
<span *ngIf="'' !== errorMsg" style="color: #eb5f58; font-size: 12px">{{ errorMsg }}</span>

<div class="ddp-ui-ex" *ngIf="desc">
  <span class="ddp-label-ex">{{ 'msg.cluster.ui.create.explanation' | translate }}</span>
  <ul class="ddp-list-ex">
    <li>{{ 'msg.space.ui.set-permission-schema.rolefornewmembers' | translate }}</li>
    <li>{{ 'msg.space.ui.set-permission-schema.viewandread' | translate }}</li>
    <li>{{ 'msg.space.ui.set-permission-schema.createmodifydelete' | translate }}</li>
    <li>{{ 'msg.space.ui.set-permission-schema.edititemsbyothers' | translate }}</li>
    <li>{{ 'msg.space.ui.set-permission-schema.createmodifydeletefolders' | translate }}</li>
    <li>{{ 'msg.space.ui.set-permission-schema.editandconfigure' | translate }}</li>
  </ul>
</div>
