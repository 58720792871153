import { Component, ElementRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';

import * as _ from 'lodash';

import { Role } from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../../common/component/abstract.component';

@Component({
  selector: 'app-role-schema',
  templateUrl: './role-schema.component.html',
})
export class RoleSchemaComponent extends AbstractComponent implements OnInit, OnDestroy {
  public role: Role;

  @Input('role')
  set setRole(role: Role) {
    if (role && role.permissions && 0 < role.permissions.length) {
      role.permissionNames = role.permissions.map((item) => {
        return <string>(item.name ? item.name : item);
      });
    }
    this.role = _.cloneDeep(role);
  }

  constructor(protected element: ElementRef, protected override injector: Injector) {
    super(element, injector);
  }

  public checkValidPerm(role: Role, permKey: string) {
    return -1 !== role.permissionNames.indexOf(permKey);
  }
}
