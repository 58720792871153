class CookieKey {
  public LOGIN_TOKEN = 'LOGIN_TOKEN';

  public LOGIN_TOKEN_TYPE = 'LOGIN_TOKEN_TYPE';

  public REFRESH_LOGIN_TOKEN = 'REFRESH_LOGIN_TOKEN';

  public LOGIN_USER_ID = 'LOGIN_USER_ID';

  public WORKBOOK_CLOSE_DASHBOARD_LIST = 'WORKBOOK_CLOSE_DASHBOARD_LIST';

  public SAVE_USER_ID = 'SAVE_USER_ID';

  public CURRENT_WORKSPACE = 'CURRENT_WORKSPACE';

  public PERMISSION = 'PERMISSION';
}

export class CookieConstant {
  public static KEY: CookieKey = new CookieKey();
}
