import { Component, OnInit } from '@angular/core';
import { MenuItemsService, MenuService } from '@selfai-platform/shell';

@Component({
  selector: 'selfai-platform-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  readonly logoPath = 'assets/images/logo.svg';

  readonly menuIsOpened$ = this.menuService.isOpened$;
  readonly menuMode$ = this.menuService.menuMode$;
  readonly menu$ = this.menuItemService.menu$;

  constructor(private readonly menuService: MenuService, private readonly menuItemService: MenuItemsService) {}

  ngOnInit(): void {
    this.menuItemService.initMenu();
  }
}
