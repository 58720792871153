import { DashboardDetailViewApiModel } from '@selfai-platform/bi-api';
import cloneDeep from 'lodash/cloneDeep';
import { Datasource, FieldNameAlias, FieldValueAlias } from '../../datasource';
import { FilterWidget, PageWidget, Widget, widgetsApiResponseArrayToWidgetsNormalizer } from '../../widget';
import { Filter } from '../../workbook';
import { Dashboard, createBoardConfiguration, createBoardSource, createDashboard } from '../models';
import { migrationFromOldSpec } from './dashboard-api/migration-from-old-spec';
import { removeDuplicateFilters } from './dashboard-api/remove-dupliacte-filters';
import { setDatasourceForDashboard } from './dashboard-api/set-datasource-dashboard';

export function normalizeDashboardApiModelToDashboard(dashboardApi: DashboardDetailViewApiModel): Dashboard {
  let dashboard = createDashboard({
    id: dashboardApi.id,
    name: dashboardApi.name,
    description: dashboardApi.description || '',
    imageUrl: dashboardApi.imageUrl,
    main: dashboardApi.main || false,
    seq: dashboardApi.seq,
    configuration: createBoardConfiguration(<any>dashboardApi.configuration),
    aliases: (dashboardApi.aliases as (FieldNameAlias | FieldValueAlias)[]) || [],
    createdBy: dashboardApi.createdBy,
    createdTime: new Date(dashboardApi.createdTime),
    modifiedBy: dashboardApi.modifiedBy,
    modifiedTime: new Date(dashboardApi.modifiedTime),
    dataSources: (<unknown>dashboardApi.dataSources) as Datasource[],
    hiding: dashboardApi.hiding || false,
    dataSource: createBoardSource(),
    updateId: null,
    widgets: dashboardApi.widgets ? widgetsApiResponseArrayToWidgetsNormalizer(dashboardApi.widgets) : [],
  });

  // For legacy components
  dashboard = setDatasourceForDashboard(dashboard);
  dashboard = migrationFromOldSpec(dashboard);
  removeDuplicateFilters(dashboard);
  dashboard = convertSpecToUI(dashboard);

  // Need check necessity
  // this.dashboardLayoutFilterService
  // .initFilters(boardInfo)
  // .catch((err) => {
  //   console.error(err);
  // })
  // .finally(() => {
  //   boardInfo = convertSpecToUI(boardInfo);
  //   boardInfo = this.initWidgetsAndLayout(boardInfo, mode);
  //   resolve(boardInfo);
  // });

  return dashboard;
}

function convertSpecToUI(boardInfo: Dashboard): Dashboard {
  if (boardInfo.configuration['userDefinedFields']) {
    boardInfo.configuration.customFields = cloneDeep(objectToArray(boardInfo.configuration['userDefinedFields']));
  }

  if (boardInfo.dataSources) {
    const filters: Filter[] = getBoardFilters(boardInfo);
    if (filters && 0 < filters.length) {
      const uniqFilterKeyList: string[] = [];
      boardInfo.configuration.filters = filters.filter((filter: Filter) => {
        const uniqFilterKey: string = filter.dataSource + '_' + filter.field;
        if (-1 === uniqFilterKeyList.indexOf(uniqFilterKey)) {
          const filterDs: Datasource | undefined = boardInfo.dataSources.find((ds) => ds.id === filter.dataSource);
          filterDs && (filter.dataSource = filterDs.engineName);
          if (!filter.dataSource) {
            const fieldDs: Datasource | undefined = boardInfo.dataSources.find((ds) =>
              ds.fields.some((item) => item.name === filter.field),
            );
            fieldDs && (filter.dataSource = fieldDs.engineName);
          }
          uniqFilterKeyList.push(uniqFilterKey);
          return true;
        } else {
          return false;
        }
      });
    }

    const widgets: Widget[] | undefined = boardInfo.widgets;
    if (widgets?.length) {
      widgets.forEach((widget: Widget) => {
        if ('filter' === widget.type) {
          const filter: Filter = (<FilterWidget>widget).configuration.filter;
          const filterDs: Datasource | undefined = boardInfo.dataSources.find((ds) => ds.id === filter.dataSource);
          filterDs && (filter.dataSource = filterDs.engineName);

          if (!filter.dataSource) {
            const fieldDs: Datasource | undefined = boardInfo.dataSources.find((ds) =>
              ds.fields.some((item) => item.name === filter.field),
            );
            fieldDs && (filter.dataSource = fieldDs.engineName);
          }
        } else if ('page' === widget.type) {
          if ((widget as PageWidget).configuration.fields) {
            (widget as PageWidget).configuration.customFields = (widget as PageWidget).configuration.fields as any;
          }
        }
      });
    }
  }

  return boardInfo;
}

function objectToArray(obj: any) {
  if (!obj) {
    return [];
  } else if (obj.forEach) {
    return obj;
  } else {
    return Object.values(obj).reduce((acc: any[], currVal) => {
      acc.push(currVal);
      return acc;
    }, []);
  }
}

function getBoardFilters(board: Dashboard, isClone: boolean = false): Filter[] {
  const boardFilter: Filter[] = board.configuration ? board.configuration.filters : [];
  return isClone ? cloneDeep(boardFilter) : boardFilter;
}
