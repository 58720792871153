import { Routes } from '@angular/router';
import { RoutingData, SemanticCode } from '@selfai-platform/shared';
import { AppAuthGuard } from '@selfai-platform/shell';

export const aggregatorProccessRouting: Routes = [
  {
    path: 'aggregators',
    canActivate: [AppAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../components/aggregator-process-list/aggregator-process-list.module').then(
            (m) => m.AggregatorProcessListComponentModule,
          ),
        pathMatch: 'full',
        data: { semanticCode: SemanticCode.AggregatorProcessList } as RoutingData,
      },
      {
        path: ':processUuid',
        children: [
          {
            path: '',
            pathMatch: 'full',
            loadChildren: () =>
              import('../components/aggregator-camel-route-list').then(
                (m) => m.AggregatorCamelRouteListComponentModule,
              ),
            data: { semanticCode: SemanticCode.AggregatorList } as RoutingData,
          },
          {
            path: ':uuid',
            loadChildren: () =>
              import('../components/aggregator-camel-editor').then((m) => m.AggregatorCamelEditorComponentModule),
            data: { semanticCode: SemanticCode.AggregatorEditor } as RoutingData,
          },
        ],
      },
    ],
  },
];
