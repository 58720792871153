import { BaseOption, UIOption } from '@selfai-platform/bi-domain';
import { Observable } from 'rxjs';

export abstract class ChartOptionsService {
  abstract initEChartOptions(): BaseOption;
  abstract setUiOptions(uiOptions: UIOption): void;
  abstract getUiOptions(): UIOption;
  abstract setEChartOptions(chartOptions: BaseOption): void;
  abstract getEChartOptions(): BaseOption;
  abstract uiOptions$: Observable<UIOption>;
  abstract chartOptions$: Observable<BaseOption>;
}
