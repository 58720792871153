import { HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { saveAs } from 'file-saver';

import { Extension } from '../domain/extension';

import { AbstractService } from './abstract.service';

@Injectable()
export class CommonService extends AbstractService {
  public static extensions: Extension[] = [];

  constructor(protected injector: Injector) {
    super(injector);
  }

  public getExtensions(type: string): Promise<any> {
    return this.get(this.API_URL + `extensions/${type}`).then((items) => {
      items && 0 < items.length && (CommonService.extensions = items);
      return items;
    });
  }

  public downloadManual(lang: string) {
    const headers = new HttpHeaders({
      Accept: 'application/pdf,*/*;',
      'Content-Type': 'application/octet-binary',
    });
    this.http
      .get(this.API_URL + `common/manual/download?lang=${lang}`, { headers: headers, responseType: 'blob' })
      .toPromise()
      .then((result) => saveAs(result, 'user.manual.pdf'));
  }
}
