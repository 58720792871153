import { Component, ElementRef, Injector, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { AbstractPopupComponent } from '../../../common/component/abstract-popup.component';
import { EventBroadcaster } from '../../../common/event/event.broadcaster';

import { CreateBoardCompleteComponent } from './create-board-complete.component';
import { CreateBoardDsNetworkComponent } from './create-board-ds-network.component';

@Component({
  selector: 'app-create-board',
  templateUrl: './create-board.component.html',
})
export class CreateBoardComponent extends AbstractPopupComponent implements OnInit, OnDestroy {
  @ViewChild(CreateBoardCompleteComponent, { static: true })
  private _completeComp: CreateBoardCompleteComponent;

  @ViewChild(CreateBoardDsNetworkComponent, { static: true })
  public networkBoardComp: CreateBoardDsNetworkComponent;

  @Input()
  public workbookId: string;

  @Input()
  public workbookName: string;

  @Input()
  public workspaceId: string;

  public isDenyNext = true;
  public isShowButtons = true;

  constructor(protected elementRef: ElementRef, protected injector: Injector, protected broadCaster: EventBroadcaster) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.useUnloadConfirm = true;
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public get isShowLabelAddDataSource(): boolean {
    return 0 === this.networkBoardComp.getCntDataSources();
  }

  public next() {
    if (this.networkBoardComp.isInvalidate()) {
      return;
    }
    const data = this.networkBoardComp.getData();
    this._completeComp.openComp(this.workbookId, this.workbookName, data.boardDataSources, data.relations);
  }

  public closeComp(isForceClose: boolean = false) {
    if (isForceClose) {
      this.close();
    } else {
      this.unloadConfirmSvc.confirm().subscribe((isClose) => {
        isClose && this.close();
      });
    }
  }

  public checkAllowNext(data: { isDenyNext?: boolean; isShowButtons?: boolean }) {
    data.hasOwnProperty('isDenyNext') && (this.isDenyNext = data.isDenyNext);
    data.hasOwnProperty('isShowButtons') && (this.isShowButtons = data.isShowButtons);
    this.safelyDetectChanges();
  }
}
