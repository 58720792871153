<p-fileUpload
  #fileUpload
  mode="advanced"
  name="workflowFile"
  [url]="url"
  accept="application/json"
  [auto]="true"
  (onUpload)="onUpload()"
  (onError)="onError()"
>
  <ng-template let-file pTemplate="file">
    <div class="p-fileupload-row">
      <div class="p-fileupload-filename">{{ file.name }}</div>
      <div>{{ fileUpload.formatSize(file.size) }}</div>
    </div>
  </ng-template>
</p-fileUpload>
