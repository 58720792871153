<p-card [formGroup]="form">
  <selfai-platform-form-field label="Type">
    <p-dropdown
      appendTo="body"
      [autoDisplayFirst]="false"
      [options]="kindItems"
      [formControl]="formType.controls.kind"
      optionLabel="label"
      optionValue="value"
    ></p-dropdown>
    <div class="mt-3">
      <ng-container [ngSwitch]="formType.controls.kind.value">
        <ng-container
          *ngSwitchCase="kindExpression.ADDITION"
          [ngTemplateOutlet]="baseTypeParameter"
          [ngTemplateOutletContext]="{ form: formType }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="kindExpression.PERCENTAGE"
          [ngTemplateOutlet]="baseTypeParameter"
          [ngTemplateOutletContext]="{ form: formType }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="kindExpression.SUBSTRACTION"
          [ngTemplateOutlet]="baseTypeParameter"
          [ngTemplateOutletContext]="{ form: formType }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="kindExpression.DIVISION"
          [ngTemplateOutlet]="divisionParameter"
          [ngTemplateOutletContext]="{ form: formType }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="kindExpression.MULTIPLICATION"
          [ngTemplateOutlet]="baseTypeParameter"
          [ngTemplateOutletContext]="{ form: formType }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="kindExpression.EXPRESSION"
          [ngTemplateOutlet]="expressionParameter"
          [ngTemplateOutletContext]="{ form: formType }"
        ></ng-container>
      </ng-container>
    </div>
  </selfai-platform-form-field>
  <selfai-platform-form-field label="Result column">
    <input type="text" pInputText class="p-inputtext w-full" formControlName="resultColumn" />
  </selfai-platform-form-field>
</p-card>

<ng-template #baseTypeParameter let-form="form">
  <ng-container [formGroup]="form">
    <selfai-platform-form-field label="Source value #1">
      <ng-container
        [ngTemplateOutlet]="sourceValue"
        [ngTemplateOutletContext]="{ form: form.controls.sourceValue1 }"
      ></ng-container>
    </selfai-platform-form-field>

    <selfai-platform-form-field label="Source value #2">
      <ng-container
        [ngTemplateOutlet]="sourceValue"
        [ngTemplateOutletContext]="{ form: form.controls.sourceValue2 }"
      ></ng-container>
    </selfai-platform-form-field>
  </ng-container>
</ng-template>

<ng-template #divisionParameter let-form="form">
  <ng-container [formGroup]="form">
    <ng-container [ngTemplateOutlet]="baseTypeParameter" [ngTemplateOutletContext]="{ form: form }"></ng-container>
    <selfai-platform-form-field label="Division by zero strategy">
      <p-dropdown
        appendTo="body"
        [autoDisplayFirst]="false"
        [options]="divisionZeroStrategyItems"
        formControlName="divisionZeroStrategy"
      ></p-dropdown>
    </selfai-platform-form-field>
  </ng-container>
</ng-template>

<ng-template #sourceValue let-form="form">
  <ng-container [formGroup]="form">
    <p-dropdown
      appendTo="body"
      [autoDisplayFirst]="false"
      [options]="expressionSourceItems"
      formControlName="type"
      optionLabel="label"
      optionValue="value"
    ></p-dropdown>
    <ng-container [ngSwitch]="form.controls.type.value">
      <selfai-platform-form-field *ngSwitchCase="expressionSource.SELECT_EXISTING_COLUMN" label="Column">
        <selfai-platform-edit-selection-field formControlName="value"></selfai-platform-edit-selection-field>
      </selfai-platform-form-field>
      <selfai-platform-form-field *ngSwitchCase="expressionSource.INPUT_COLUMN_NAME" label="Column name">
        <input type="text" pInputText class="p-inputtext w-full" formControlName="value" />
      </selfai-platform-form-field>
      <selfai-platform-form-field *ngSwitchCase="expressionSource.INPUT_AMOUNT" label="Amount">
        <p-inputNumber formControlName="value" mode="decimal"></p-inputNumber>
      </selfai-platform-form-field>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #expressionParameter let-form="form">
  <ng-container [formGroup]="form">
    <selfai-platform-code-editor
      [language]="codeLanguage.SQL"
      formControlName="sqlExpression"
    ></selfai-platform-code-editor>
  </ng-container>
</ng-template>
