import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckPermissionDirective } from './check-permission.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [CheckPermissionDirective],
  exports: [CheckPermissionDirective],
})
export class CheckPermissionDirectiveModule {}
