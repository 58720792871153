import { Directive, ElementRef, EventEmitter, Inject, Input, Renderer2 } from '@angular/core';
@Directive({
  selector: '[focus]',
})
export class FocusDirective {
  @Input() focus: boolean;
  constructor(@Inject(ElementRef) private element: ElementRef) {}
  protected ngOnChanges() {
    this.element.nativeElement.focus();
  }
}
