import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '@selfai-platform/shared';
import { KeycloakService } from 'keycloak-angular';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class TokenRefreshExpiredInterceptor implements HttpInterceptor {
  constructor(private keycloakService: KeycloakService, private alertService: AlertService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((errResponse: unknown) => {
        if (errResponse instanceof HttpErrorResponse) {
          const keycloak = this.keycloakService.getKeycloakInstance();
          switch (errResponse.status) {
            case 403: // Forbidden
              if (errResponse.url && this.isBiApi(errResponse.url)) {
                // this.handleExpiredRefreshToken();
                this.alertService.error('You do not have permission to access this resource');
              }
              break;
            case 401: // Unauthorized
              if (
                // Refresh the expired token
                // Check if the OAuth endpoint was called and the error is because the refresh token expired
                keycloak.authServerUrl &&
                errResponse.url?.includes(keycloak.authServerUrl) &&
                errResponse.error.error === 'invalid_token'
              ) {
                this.handleExpiredRefreshToken();
              }
              break;

            case 400: // Bad Request
              if (
                keycloak.authServerUrl &&
                errResponse.url?.includes(keycloak.authServerUrl) &&
                errResponse.error.error === 'invalid_grant'
              ) {
                this.handleExpiredRefreshToken();
              }
              break;
          }
        }

        return throwError(() => errResponse);
      }),
    );
  }

  private isBiApi(url: string) {
    return url.replace(window.location.origin, '').startsWith('/api/');
  }

  handleExpiredRefreshToken(): void {
    this.keycloakService.login({
      redirectUri: window.location.href,
    });
  }
}
