import { FilteringOptionType } from '../../enums';

export interface Filter {
  dataSource?: string;
  field?: string;
  ref?: string;
  type: string;
  ui: {
    filteringSeq?: number;
    filteringOptions?: FilteringOptions;
    importanceType: string;
    widgetId?: string;
  };

  valueList?: any;
}

export function createFilter(params: Partial<Filter> = {}): Filter {
  return {
    type: '',
    ui: {
      importanceType: 'general',
    },
    ...params,
  };
}

export interface FilteringOptions {
  allowSelectors: string[];
  defaultSelector: string;
  type: FilteringOptionType;
}

export function createFilteringOptions(params: Partial<FilteringOptions> = {}): FilteringOptions {
  return {
    allowSelectors: [],
    defaultSelector: '',
    type: FilteringOptionType.INCLUSION,
    ...params,
  };
}
