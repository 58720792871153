import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { KeycloakConfigService } from '@selfai-platform/shared';

@Component({
  selector: 'selfai-platform-account-settings-page',
  templateUrl: './account-settings-page.component.html',
  styleUrls: ['./account-settings-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSettingsPageComponent implements OnInit {
  constructor(private readonly keycloakConfigService: KeycloakConfigService) {}

  ngOnInit(): void {
    const keycloakConfig = this.keycloakConfigService.getConfig();
    window.location.href = `${keycloakConfig.url}/realms/${keycloakConfig.realm}/account?referrer=${keycloakConfig.clientId}`;
  }
}
