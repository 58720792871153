import { Injectable, Injector } from '@angular/core';

import * as _ from 'lodash';
import { Observable, firstValueFrom, map } from 'rxjs';

import {
  DatasourceValidateExpressionRequestApiModel,
  DatasourceValidateExpressionResponseApiModel,
} from '@selfai-platform/bi-api';
import {
  BoardConfiguration,
  BoardDataSource,
  BoardGlobalOptions,
  BookTree,
  Dashboard,
  DashboardAdapter,
  Filter,
  JoinMapping,
} from '@selfai-platform/bi-domain';

import { AbstractService } from '../../common/service/abstract.service';
import { CommonUtil } from '../../common/util/common.util';
import { FilterUtil } from '../util/filter.util';

import { DashboardUtil } from '../util';
import { UpdateDashboardOptions } from './UpdateDashboardOptions';

@Injectable()
export class DashboardApiService extends AbstractService {
  constructor(protected injector: Injector) {
    super(injector);
  }

  public connectDashboardAndDataSource(boardId: string, dataSources: BoardDataSource[]) {
    const linksParam: string[] = dataSources.reduce((acc: string[], currVal: BoardDataSource) => {
      return acc.concat(this._getLinkDataSourceParam(currVal));
    }, []);

    return this.put(`/api/dashboards/${boardId}/datasources`, linksParam.join('\n'), 'text/uri-list');
  }

  public createDashboard(
    workbookId: string,
    dashboard: Dashboard,
    option: BoardGlobalOptions,
    callback?: (dashboard: Dashboard) => void,
  ): Promise<Dashboard> {
    const url = this.API_URL + 'dashboards';
    const boardDs: BoardDataSource = dashboard.dataSource;
    let params = {
      workBook: `/api/workbooks/${workbookId}`,
      configuration: {
        dataSource: boardDs,
        options: option,
      },
      name: dashboard.name,
      description: dashboard.description,
    };
    dashboard.temporaryId && (params['temporaryId'] = dashboard.temporaryId);

    params = this.convertSpecToServer(_.cloneDeep(params));
    return this.post(url, params).then((board: Dashboard) => {
      return new Promise((resolve) => {
        callback && callback(board);
        this.connectDashboardAndDataSource(board.id, 'multi' === boardDs.type ? boardDs.dataSources : [boardDs]).then(
          () => resolve(board),
        );
      });
    });
  }

  public getDashboard(dashboardId: string): Promise<Dashboard> {
    const dashboardAdapter = this.injector.get(DashboardAdapter);
    return firstValueFrom(
      (dashboardAdapter.loadOne(dashboardId) as Observable<any>).pipe(map(DashboardUtil.convertSpecToUI)),
    );
  }

  public deleteDashboard(id: string) {
    return this.delete(this.API_URL + 'dashboards/' + id);
  }

  public updateDashboard(dashboardId: string, options: UpdateDashboardOptions) {
    const apiParams: any = this.convertSpecToServer(_.cloneDeep(options));
    return this.patch(this.API_URL + 'dashboards/' + dashboardId, apiParams);
  }

  public updateDashboardFields(dashboardId: string, options: any) {
    return this.patch(this.API_URL + 'dashboards/' + dashboardId + '/fieldsoption', options);
  }

  public copyDashboard(dashboardId: string) {
    return this.get(this.API_URL + `dashboards/${dashboardId}?projection=forDetailView`).then((info: Dashboard) => {
      if (info.configuration.customFields) {
        {
          info.configuration['userDefinedFields'] = _.cloneDeep(
            CommonUtil.objectToArray(info.configuration.customFields),
          );
          delete info.configuration.customFields;
          info.configuration.widgets.forEach((item) => (item.isInLayout = true));
        }
        return this.updateDashboard(dashboardId, { configuration: info.configuration }).then(() => {
          return this.post(this.API_URL + 'dashboards/' + dashboardId + '/copy', null);
        });
      } else {
        return this.post(this.API_URL + 'dashboards/' + dashboardId + '/copy', null);
      }
    });
  }

  public getDashboardWidget(dashboardId: string, projection: string): Promise<BookTree> {
    return this.get(this.API_URL + `dashboards/${dashboardId}/widgets?projection=${projection}`);
  }

  public getCalculationFunction() {
    return this.get(this.API_URL + 'expressions/list');
  }

  public validate(
    param: DatasourceValidateExpressionRequestApiModel,
  ): Promise<DatasourceValidateExpressionResponseApiModel> {
    return this.post(this.API_URL + 'datasources/validate/expr', param);
  }

  public convertSpecToServer(param: any) {
    if (!param) {
      return param;
    }
    delete param['selectDatasource'];
    delete param['update'];
    if (param['configuration']) {
      const boardConf: BoardConfiguration = param['configuration'] as BoardConfiguration;
      if (boardConf.dataSource) {
        boardConf.dataSource.name = boardConf.dataSource.engineName || boardConf.dataSource.name;
        delete boardConf.dataSource.connType;
        delete boardConf.dataSource.engineName;
        delete boardConf.dataSource.uiFields;
        delete boardConf.dataSource.uiFilters;
        delete boardConf.dataSource.metaDataSource;
        delete boardConf.dataSource['orgDataSource'];
        if (boardConf.dataSource.dataSources) {
          boardConf.dataSource.dataSources.forEach((item) => {
            delete item.uiFields;
            delete item.metaDataSource;
          });
        }
      }
      boardConf.layout && (boardConf.content = _.cloneDeep(boardConf.layout.content));
      if (boardConf.widgets) {
        boardConf.widgets = boardConf.widgets.filter((item) => item.isInLayout);
        boardConf.widgets.forEach((item) => {
          delete item.isInLayout;
          delete item.isSaved;
          delete item.isLoaded;
        });
      }
      if (boardConf.filters) {
        boardConf.filters = boardConf.filters
          .filter((item) => {
            if (boardConf.dataSource.temporary) {
              return 'recommended' !== item.ui.importanceType;
            } else {
              return true;
            }
          })
          .map((item) => FilterUtil.convertToServerSpecForDashboard(item)) as Filter[];
      }
      delete boardConf.layout;
      delete boardConf.fields;

      if (boardConf.customFields) {
        boardConf['userDefinedFields'] = _.cloneDeep(CommonUtil.objectToArray(boardConf.customFields));
        delete boardConf.customFields;
      }
    }

    return param;
  }

  private _getLinkDataSourceParam(dataSource: BoardDataSource): string[] {
    let links: string[] = [dataSource.id];
    if (dataSource.type === 'mapping') {
      dataSource.joins.forEach((join: JoinMapping) => {
        links.push(join.id);
        join.join && links.push(join.join.id);
      });
    }
    links = links.map((dsId) => this.API_URL + 'datasources/' + dsId);

    return links;
  }
}
