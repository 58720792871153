<table class="ddp-table-base" *ngIf="role">
  <colgroup>
    <col width="180px" />
    <col width="180px" />
    <col width="180px" />
    <col width="104px" />
  </colgroup>
  <thead>
    <tr>
      <th><strong>Workbook</strong></th>
      <th><strong>Notebook</strong></th>
      <th><strong>Workbench</strong></th>
      <th><strong>Workspace</strong></th>
    </tr>
    <tr>
      <th>
        <div class="ddp-data-name">View</div>
        <div class="ddp-data-name">Create</div>
        <div class="ddp-data-name">Edit any</div>
      </th>
      <th>
        <div class="ddp-data-name">View</div>
        <div class="ddp-data-name">Create</div>
        <div class="ddp-data-name">Edit any</div>
      </th>
      <th>
        <div class="ddp-data-name">View</div>
        <div class="ddp-data-name">Create</div>
        <div class="ddp-data-name">Edit any</div>
      </th>
      <th>
        <div class="ddp-data-name2">
          Create
          <br />
          folders
        </div>
        <div class="ddp-data-name2">
          Set
          <br />
          config.
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <div class="ddp-data-det">
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_VIEW_WORKBOOK')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_VIEW_WORKBOOK')">-</em>
        </div>
        <div class="ddp-data-det">
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_EDIT_WORKBOOK')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_EDIT_WORKBOOK')">-</em>
        </div>
        <div class="ddp-data-det">
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_WORKBOOK')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_WORKBOOK')">-</em>
        </div>
      </td>

      <td>
        <div class="ddp-data-det">
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_VIEW_NOTEBOOK')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_VIEW_NOTEBOOK')">-</em>
        </div>
        <div class="ddp-data-det">
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_EDIT_NOTEBOOK')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_EDIT_NOTEBOOK')">-</em>
        </div>
        <div class="ddp-data-det">
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_NOTEBOOK')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_NOTEBOOK')">-</em>
        </div>
      </td>

      <td>
        <div class="ddp-data-det">
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_VIEW_WORKBENCH')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_VIEW_WORKBENCH')">-</em>
        </div>
        <div class="ddp-data-det">
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_EDIT_WORKBENCH')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_EDIT_WORKBENCH')">-</em>
        </div>
        <div class="ddp-data-det">
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_WORKBENCH')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_WORKBENCH')">-</em>
        </div>
      </td>

      <td>
        <div class="ddp-data-det2">
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_FOLDER')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_FOLDER')">-</em>
        </div>
        <div class="ddp-data-det2">
          <em *ngIf="checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_WORKSPACE')" class="ddp-icon-checked"></em>
          <em *ngIf="!checkValidPerm(role, 'PERM_WORKSPACE_MANAGE_WORKSPACE')">-</em>
        </div>
      </td>
    </tr>
  </tbody>
</table>
