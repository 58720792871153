import { ModuleWithProviders, NgModule } from '@angular/core';

import { DashboardApiService } from '../../../dashboard/service/dashboard-api.service';
import { PermissionService } from '../../../user/service/permission.service';
import { MyWorkspaceService } from '../../service/my-workspace.service';
import { WorkspaceService } from '../../service/workspace.service';

@NgModule()
export class WorkspaceTreeProviderModule {
  static forRoot(): ModuleWithProviders<WorkspaceTreeProviderModule> {
    return {
      ngModule: WorkspaceTreeProviderModule,
      providers: [MyWorkspaceService, WorkspaceService, DashboardApiService, PermissionService],
    };
  }
}
