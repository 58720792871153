import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KdConfigService, normalizeApiEndpoint } from '@selfai-platform/shared';

import { Observable } from 'rxjs';
import { DatasourceMetaData } from '../../datasource';

@Injectable({
  providedIn: 'root',
})
export class MetadataApiService {
  get apiUrl(): string {
    const config = this.kdConfigService.getConfig();

    return [config.apiUrl, 'api', 'metadatas'].join('/');
  }

  constructor(private readonly http: HttpClient, private readonly kdConfigService: KdConfigService) {}

  getMetadataForDataSource(
    dataSourceId: string,
    projection: 'forItemView' | 'forDetailView' = 'forItemView',
  ): Observable<DatasourceMetaData[] | undefined> {
    return this.http.post<DatasourceMetaData[] | undefined>(
      this.buildUrl(`/metasources/${dataSourceId}?projection=${projection}`),
      {},
    );
  }

  private buildUrl(endpoint: string): string {
    return `${this.apiUrl}/${normalizeApiEndpoint(endpoint)}`;
  }
}
