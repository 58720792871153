import { Injectable } from '@angular/core';
import { ChartType, Position, SymbolType, UIOption } from '@selfai-platform/bi-domain';
import { BarSeriesOption, ComposeOption } from 'echarts';
import { BehaviorSubject } from 'rxjs';
import { OptionGenerator } from '../../utils';
import { ChartOptionsService } from '../chart-options.service';

@Injectable()
export class BarChartOptionsService implements ChartOptionsService {
  private uiOptions = new BehaviorSubject<UIOption>({} as UIOption);
  private chartOptions = new BehaviorSubject<ComposeOption<BarSeriesOption>>({
    type: ChartType.BAR,
    grid: [OptionGenerator.Grid.verticalMode(10, 0, 0, 10, false, true, false)],
    xAxis: [OptionGenerator.Axis.categoryAxis(Position.INSIDE, null, false, true, true, true)],
    yAxis: [OptionGenerator.Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true)],
    legend: OptionGenerator.Legend.custom(false, false, Position.LEFT, SymbolType.CIRCLE, '100%', 20, 5),
    dataZoom: [OptionGenerator.DataZoom.horizontalDataZoom(), OptionGenerator.DataZoom.horizontalInsideDataZoom()],
    tooltip: OptionGenerator.Tooltip.itemTooltip(),
    toolbox: OptionGenerator.Toolbox.hiddenToolbox(),
    brush: OptionGenerator.Brush.selectBrush(),
    series: [],
  });

  uiOptions$ = this.uiOptions.asObservable();
  chartOptions$ = this.chartOptions.asObservable();

  setUiOptions(uiOptions: UIOption): void {
    this.uiOptions.next(uiOptions);
  }

  getUiOptions(): UIOption {
    return this.uiOptions.getValue();
  }

  setEChartOptions(chartOptions: ComposeOption<BarSeriesOption>): void {
    this.chartOptions.next(chartOptions);
  }

  getEChartOptions(): ComposeOption<BarSeriesOption> {
    return this.chartOptions.getValue();
  }

  // @deprecated use intead getEChartOptions() or chartOptions$ for observable
  initEChartOptions(): ComposeOption<BarSeriesOption> {
    return {
      type: ChartType.BAR,
      grid: [OptionGenerator.Grid.verticalMode(10, 0, 0, 10, false, true, false)],
      xAxis: [OptionGenerator.Axis.categoryAxis(Position.INSIDE, null, false, true, true, true)],
      yAxis: [OptionGenerator.Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true)],
      legend: OptionGenerator.Legend.custom(false, false, Position.LEFT, SymbolType.CIRCLE, '100%', 20, 5),
      dataZoom: [OptionGenerator.DataZoom.horizontalDataZoom(), OptionGenerator.DataZoom.horizontalInsideDataZoom()],
      tooltip: OptionGenerator.Tooltip.itemTooltip(),
      toolbox: OptionGenerator.Toolbox.hiddenToolbox(),
      brush: OptionGenerator.Brush.selectBrush(),
      series: [],
    };
  }
}
