export class UserProfile {
  public username: string;

  public fullName: string;

  public email: string;

  public imageUrl: string;

  public type: string;
}
