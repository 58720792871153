import { Injectable, Injector } from '@angular/core';

import { AbstractService } from '../../../common/service/abstract.service';
import { CommonUtil } from '../../../common/util/common.util';
import { Status } from '../../../domain/user/user';

@Injectable()
export class MembersService extends AbstractService {
  private path = 'users';

  constructor(protected injector: Injector) {
    super(injector);
  }

  public getRequestedUser(param: any, projection: string = 'forListView'): Promise<any> {
    let url: string = this.API_URL + this.path;

    if (param) {
      url += '?' + CommonUtil.objectToUrlString(param);
    }

    return this.get(url + `&projection=${projection}`);
  }

  public getResultDuplicatedUserName(username: string): Promise<any> {
    return this.get(this.API_URL + this.path + `/username/${username}/duplicated`);
  }

  public getResultDuplicatedEmail(email: string): Promise<any> {
    return this.get(this.API_URL + this.path + `/email/${email}/duplicated`);
  }

  public getUserDetail(userId: string, projection: string = 'forDetailView'): Promise<any> {
    const url: string = this.API_URL + this.path + `/${userId}`;

    return this.get(url + `?projection=${projection}`);
  }

  public deleteUser(userId: string): Promise<any> {
    return this.delete(this.API_URL + this.path + `/${userId}`);
  }

  public updateUser(userId: string, param: any): Promise<any> {
    return this.put(this.API_URL + this.path + `/${userId}/manual`, param);
  }

  public userPasswordReset(email: string): Promise<any> {
    return this.post(this.API_URL + this.path + '/password/reset', { email });
  }

  public updateUserStatus(username: string, status: string): Promise<any> {
    return this.post(this.API_URL + this.path + `/${username}/status/${status}`, null);
  }

  public createUser(params: object): Promise<any> {
    return this.post(this.API_URL + this.path + '/manual', params);
  }

  public getMemberApprovalList(param: any, projection: string = 'forListView'): Promise<any> {
    let url: string = this.API_URL + this.path;

    if (param) {
      url += '?' + CommonUtil.objectToUrlString(param);
    }

    return this.get(url + `&projection=${projection}`);
  }

  public rejectUser(username: string, params: any): Promise<any> {
    const url: string = this.API_URL + this.path + `/${username}/rejected`;

    return this.post(url, params);
  }

  public approveUser(username): Promise<any> {
    const url: string = this.API_URL + this.path + `/${username}/approved`;

    return this.post(url, {});
  }

  public getAccessHistory(param: any): Promise<any> {
    let url: string = this.API_URL + 'activities';

    if (param) {
      url += '?' + CommonUtil.objectToUrlString(param);
    }

    return this.get(url + '&projection=list');
  }
}
