<div class="ddp-ui-chart-side ddp-wrap-color-by ddp-show">
  <div class="ddp-ui-scroll">
    <div class="ddp-ui-side-title">
      {{ 'msg.page.ui.data.color.title' | translate }}
    </div>
    <div class="ddp-wrap-downmenu" *ngIf="possibleChartCheck('color', uiOption.type)">
      <div class="ddp-box-down">
        <div
          class="ddp-wrap-divide"
          [ngClass]="{ 'ddp-disabled': 'measure' === uiOption.color.type.toString() && uiOption.color['customMode'] }"
        >
          <div class="ddp-list-part ddp-divide">
            <div
              class="ddp-type-selectbox"
              (click)="colorTypeFlag = !colorTypeFlag; colorListFlag = false"
              (clickOutside)="colorTypeFlag = false"
              [excludeBeforeClick]="true"
            >
              <span class="ddp-txt-selectbox">
                {{
                  uiOption.color.type.toString() === 'single'
                    ? returnColorType('single')
                    : returnColorType(uiOption.color.type)
                }}
              </span>

              <ul class="ddp-list-selectbox ddp-selectdown" style="display: block" *ngIf="colorTypeFlag">
                <li (click)="changeColorType('single')" *ngIf="possibleChartCheck('colorN', uiOption.type)">
                  <a href="javascript:">{{ 'msg.space.ui.none' | translate }}</a>
                </li>
                <li (click)="changeColorType('series')" *ngIf="possibleChartCheck('colorS', uiOption.type)">
                  <a href="javascript:">{{ 'msg.page.li.color.series' | translate }}</a>
                </li>
                <li
                  (click)="changeColorType('dimension')"
                  *ngIf="possibleChartCheck('colorD', uiOption.type) && lineChartCheck(uiOption.type)"
                >
                  <a href="javascript:">{{ 'msg.page.li.color.dimension' | translate }}</a>
                </li>
                <li (click)="changeColorType('measure')" *ngIf="possibleChartCheck('colorV', uiOption.type)">
                  <a href="javascript:">{{ 'msg.page.li.color.measure' | translate }}</a>
                </li>
              </ul>
            </div>
          </div>

          <div
            class="ddp-list-part ddp-divide"
            *ngIf="
              'line' !== uiOption.type.toString() &&
              'gauge' !== uiOption.type.toString() &&
              'network' !== uiOption.type.toString() &&
              'sankey' !== uiOption.type.toString() &&
              'treemap' !== uiOption.type.toString() &&
              uiOption.color.type.toString() === 'dimension'
            "
          >
            <component-select
              [array]="uiOption.fielDimensionList"
              [viewKey]="'alias'"
              [defaultIndex]="getDimensionIndex()"
              (onSelected)="colorByDimension($event)"
            ></component-select>
          </div>

          <div class="ddp-list-part">
            <div class="ddp-ui-colorby">
              <div
                *ngIf="'single' !== uiOption.color.type.toString() && 'measure' !== uiOption.color.type.toString()"
                (click)="toggleColorList()"
                (clickOutside)="colorListFlag = false"
                class="ddp-wrap-colorby-button"
              >
                <em class="ddp-icon-colorby"></em>

                <button class="ddp-btn-color-series{{ checkDefaultSelectedColor() }} ddp-type"></button>
              </div>

              <div
                *ngIf="'single' !== uiOption.color.type.toString() && 'measure' === uiOption.color.type.toString()"
                (click)="toggleColorList()"
                (clickOutside)="colorListFlag = false"
                class="ddp-wrap-colorby-button"
              >
                <em class="ddp-icon-colorby"></em>

                <button
                  class="ddp-btn-color-type{{ checkMeasureSelectedColor() }} ddp-type"
                  [ngClass]="{ 'sys-inverted': isChartColorInverted() }"
                ></button>
              </div>

              <div class="ddp-popup-side" *ngIf="colorListFlag">
                <div class="ddp-pop-side-top">
                  {{ 'msg.page.ui.color.palette' | translate }}
                  <a href="javascript:" class="ddp-btn-close" (click)="colorListFlag = false"></a>
                </div>

                <div *ngIf="'measure' !== uiOption.color.type.toString()" class="ddp-pop-side-contents">
                  <ul class="ddp-list-series">
                    <li *ngFor="let item of defaultColorList">
                      <button
                        class="ddp-btn-color-series{{ item['index'] }} ddp-type"
                        [ngClass]="{ 'ddp-selected': item['colorNum'] == uiOption.color['schema'] }"
                        (click)="changeColor(item)"
                      ></button>
                    </li>
                  </ul>
                </div>

                <div *ngIf="'measure' === uiOption.color.type.toString()" class="ddp-pop-side-contents">
                  <div class="ddp-box-slider">
                    <div class="ddp-wrap-option-slider">
                      <span class="ddp-label-slider" style="width: 100px">
                        {{ 'msg.page.ui.color.invert' | translate }}
                      </span>

                      <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
                        <input
                          type="checkbox"
                          id="invertColor"
                          name="check"
                          [checked]="isChartColorInverted()"
                          (click)="invertColor()"
                        />
                        <label for="invertColor"><span class="ddp-slide"></span></label>
                      </div>
                    </div>
                  </div>
                  <span class="ddp-pop-sub-title">{{ 'msg.page.th.seq.display' | translate }}</span>
                  <ul class="ddp-list-series">
                    <li *ngFor="let item of measureColorList; let num = index">
                      <button
                        class="ddp-btn-color-type{{ item['index'] }} ddp-type"
                        [ngClass]="{
                          'ddp-selected': isChartColorSelected(item),
                          'sys-inverted':
                            isTemplateColorInverted === undefined ? isChartColorInverted() : isTemplateColorInverted
                        }"
                        (click)="changeColor(item, measureColorList[num])"
                      ></button>
                    </li>
                  </ul>

                  <span class="ddp-pop-sub-title">{{ 'msg.page.th.contrast.display' | translate }}</span>
                  <ul class="ddp-list-series">
                    <li *ngFor="let item of measureReverseColorList; let num = index">
                      <button
                        class="ddp-btn-color-type{{ item['index'] }} ddp-type"
                        [ngClass]="{
                          'ddp-selected': isChartColorSelected(item),
                          'sys-inverted':
                            isTemplateColorInverted === undefined ? isChartColorInverted() : isTemplateColorInverted
                        }"
                        (click)="changeColor(item, measureReverseColorList[num])"
                      ></button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="uiOption.color.type.toString() === 'series'" class="ddp-wrap-divide">
          <div class="ddp-wrap-option-slider">
            <span class="ddp-label-slider" style="width: 100px">
              {{ 'msg.page.chart.color.series.color.setting' | translate }}
            </span>

            <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
              <input type="checkbox" [checked]="uiOption.color['settingUseFl']" (click)="showUserColorSet()" />
              <label><span class="ddp-slide"></span></label>
            </div>
          </div>

          <div *ngIf="uiOption.color['settingUseFl']" class="ddp-list-sub2" style="padding: 0">
            <div *ngFor="let item of uiOption.color['mappingArray']; let index = index" class="ddp-ui-color-select">
              <div class="ddp-txt-label">
                <a
                  href="javascript:"
                  *ngIf="uiOption.color['settingUseFl']"
                  (click)="resetUserColorSet(item, index)"
                  class="ddp-btn-reset3"
                ></a>
                <div class="ddp-ui-txt">
                  <div class="ddp-box-color">
                    <color-picker
                      [showAlpha]="true"
                      [color]="item.color"
                      [showInitial]="true"
                      [showInput]="true"
                      [showUserColor]="true"
                      #colorPicker
                      (selected)="colorPaletteSelected($event, item)"
                    ></color-picker>
                  </div>
                  <span class="ddp-txt-data" title="{{ item.alias }}">{{ item.alias }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="'gauge' === uiOption.type.toString() && uiOption.color.type.toString() === 'dimension'"
          class="ddp-wrap-divide"
        >
          <div class="ddp-wrap-option-slider">
            <span class="ddp-label-slider" style="width: 100px">
              {{ 'msg.page.chart.color.series.color.setting' | translate }}
            </span>

            <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
              <input type="checkbox" [checked]="uiOption.color['settingUseFl']" (click)="showUserColorSet()" />
              <label><span class="ddp-slide"></span></label>
            </div>
          </div>

          <div *ngIf="uiOption.color['settingUseFl']" class="ddp-list-sub2">
            <div *ngFor="let item of uiOption.color['mappingArray']; let index = index" class="ddp-ui-color-select">
              <div class="ddp-txt-label">
                <a
                  href="javascript:"
                  *ngIf="uiOption.color['settingUseFl']"
                  (click)="resetGaugeUserColorSet(item, index)"
                  class="ddp-btn-reset3"
                ></a>

                <div class="ddp-ui-txt">
                  <div class="ddp-box-color">
                    <color-picker
                      [showAlpha]="true"
                      [color]="item.color"
                      [showInitial]="true"
                      [showInput]="true"
                      [showUserColor]="true"
                      #colorPicker
                      (selected)="colorGaugePaletteSelected($event, item)"
                    ></color-picker>
                  </div>
                  <span class="ddp-txt-data" title="{{ item.alias }}">{{ item.alias }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="uiOption.color.type.toString() === 'measure'" class="ddp-wrap-divide">
          <div class="ddp-wrap-option-slider">
            <span class="ddp-label-slider" style="width: 100px">
              {{ 'msg.page.chart.color.series.color.setting' | translate }}
            </span>

            <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
              <input
                type="checkbox"
                [checked]="uiOption.color['customMode']"
                (click)="changeColorRange()"
                name="check"
              />
              <label><span class="ddp-slide"></span></label>
            </div>
          </div>
          <div *ngIf="uiOption.color['customMode']" class="ddp-user-color">
            <div class="ddp-list-part">
              <component-select
                [array]="colorRangeTypeList"
                [defaultIndex]="!uiOption.color['customMode'] || 'SECTION' === uiOption.color['customMode'] ? 0 : 1"
                [viewKey]="'name'"
                (onSelected)="selectColorRangeType($event)"
              ></component-select>
            </div>

            <!--Тут новый код-->
            <ng-container *ngIf="uiOption.color['customMode'] === 'SECTION'">
              <ng-container *ngFor="let colorRange of uiOption.color['ranges']; let index = index">
                <selfai-bi-color-option-custom-default
                  [colorRange]="colorRange"
                  [isShowAdd]="index !== 0"
                  [isShowRemove]="
                    uiOption.color['ranges'].length - 1 !== index && 0 !== index && uiOption.color['ranges'].length > 3
                  "
                  (addColorRange)="addNewRange(index)"
                  (colorPaletteSelected)="colorPaletteSelected($event, colorRange)"
                  (changeMinColorRange)="changeRangeMinInput($event, index)"
                  (changeMaxColorRange)="changeRangeMaxInput($event, index)"
                  (removeColorRange)="removeColorRange($event, index)"
                ></selfai-bi-color-option-custom-default>
              </ng-container>
            </ng-container>

            <div *ngIf="'GRADIENT' === uiOption.color['customMode']" class="ddp-wrap-gradation2">
              <div class="ddp-ui-gradation">
                <gradation-generator
                  (changeGradation)="changeGradations($event)"
                  (clickOutside)="hideGradationColor()"
                ></gradation-generator>

                <div class="ddp-gradx-usercolor">
                  <div *ngFor="let item of uiOption.color['ranges']; let index = index" class="ddp-wrap-usercolor">
                    <div
                      *ngIf="separateValue > uiOption.color['ranges']['length']"
                      (click)="addGradientRange(index)"
                      class="ddp-list-blank"
                    >
                      {{ 'msg.page.chart.color.measure.new.range.add.description' | translate }}
                    </div>

                    <div class="ddp-list-usercolor">
                      <div class="ddp-box-color" id="ddp-box-color-{{ index }}">
                        <color-picker
                          [showAlpha]="true"
                          [color]="item.color"
                          [showInitial]="true"
                          [showInput]="true"
                          [showUserColor]="true"
                          #colorPicker
                          (selected)="gradationColorSelected($event, item)"
                          (showPicker)="showGradationColor(item, index)"
                          (hidePicker)="hideGradationColor(index)"
                        ></color-picker>
                      </div>
                      <div class="ddp-data-range ddp-clear">{{ item.value | number: '1.0-0' }}</div>
                      <em
                        *ngIf="uiOption.color['ranges'].length > 2"
                        (click)="deleteGradientRange(item.index)"
                        class="ddp-icon-listdelete"
                      ></em>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="uiOption.color['customMode']" class="ddp-data-sum">
            {{ 'msg.page.chart.color.measure.new.range.min' | translate }} : {{ minValue }} ~
            {{ 'msg.page.chart.color.measure.new.range.max' | translate }} : {{ maxValue }}
          </div>
          <a
            *ngIf="'SECTION' === uiOption.color['customMode']"
            (click)="equalColorRange()"
            href="javascript:"
            class="ddp-btn-set"
          >
            {{ 'msg.page.chart.color.measure.new.range.split.equal' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
