import { ModuleWithProviders, NgModule } from '@angular/core';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { provideAggregatorApi } from './aggregator-camel/providers';
import { WorkflowModule } from './wokflow/workflow.module';

@NgModule({
  imports: [WorkflowModule.forRoot(), MonacoEditorModule.forRoot()],
})
export class PipelineProviderModule {
  static forRoot(): ModuleWithProviders<PipelineProviderModule> {
    return {
      ngModule: PipelineProviderModule,
      providers: [provideAggregatorApi()],
    };
  }
}
