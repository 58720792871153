<div class="ddp-wrap-widget" (mouseover)="mouseOverHeader()" (mouseout)="mouseOutHeader()" style="cursor: move">
  <div #controlContainer class="ddp-box-widget" [class.ddp-box-size]="isMiniHeader">
    <div class="ddp-top-control ddp-clear">
      <div class="ddp-top-control-in" [ngClass]="{ 'ddp-size1': !isMiniHeader, 'ddp-size2': isMiniHeader }">
        <div class="ddp-data-top" *ngIf="isEditMode()">
          <div class="ddp-ui-tool" *ngIf="!isMiniHeader">
            <a
              *ngIf="!isTextWidget"
              (click)="toggleTitle()"
              [class.ddp-selected]="isShowTitle"
              href="javascript:"
              class="ddp-btn-tool ddp-hover-tooltip"
            >
              <em class="ddp-icon-tool-title"></em>
              <div class="ddp-ui-tooltip-info ddp-down">
                <em class="ddp-icon-view-top"></em>
                {{ 'msg.board.ui.tooltip.chart-name' | translate }}
              </div>
            </a>
            <a
              *ngIf="isValidLegend()"
              (click)="toggleLegend()"
              [class.ddp-selected]="isShowLegend()"
              href="javascript:"
              class="ddp-btn-tool ddp-hover-tooltip"
            >
              <em class="ddp-icon-tool-legend"></em>
              <div class="ddp-ui-tooltip-info ddp-down">
                <em class="ddp-icon-view-top"></em>
                {{ 'msg.page.ui.legend' | translate }}
              </div>
            </a>
            <a
              *ngIf="isValidMiniMap()"
              (click)="toggleMiniMap()"
              [class.ddp-selected]="isShowMiniMap()"
              href="javascript:"
              class="ddp-btn-tool ddp-hover-tooltip"
            >
              <em class="ddp-icon-tool-minimap"></em>
              <div class="ddp-ui-tooltip-info ddp-down">
                <em class="ddp-icon-view-top"></em>
                {{ 'msg.page.ui.minimap' | translate }}
              </div>
            </a>
          </div>

          <div
            *ngIf="widget && !isTextWidget && isShowTitle"
            (click)="editModeName($event)"
            class="ddp-ui-eidt-name"
            [class.ddp-selected]="isEditTitle"
          >
            <div class="ddp-data-enter">
              <span class="ddp-data-name">{{ widget.name }}</span>
            </div>
            <component-input
              *ngIf="isEditTitle"
              [value]="widget.name"
              [inputClass]="'ddp-input-edit'"
              [maxLen]="50"
              [placeHolder]="'msg.board.ui.name.ph' | translate"
              (inputBlur)="isEditTitle = false"
              (changeValue)="changeWidgetName($event)"
            ></component-input>
          </div>
        </div>

        <div class="ddp-ui-buttons" *ngIf="!isMiniHeader">
          <div class="ddp-box-btn2" *ngIf="isRealTimeDashboard()">
            <em (click)="toggleSync()" class="ddp-icon-widget-synch" [class.ddp-on]="isRealTimeWidget()"></em>

            <div class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-down"></em>
              {{ 'msg.board.ui.sync' | translate }}
            </div>
          </div>

          <div class="ddp-box-btn2" *ngIf="limitInfo.isShow">
            <em class="ddp-icon-widget-info2"></em>

            <div class="ddp-box-info ddp-limitation">
              <em class="ddp-icon-info"></em>
              <span
                class="ddp-total"
                [innerHTML]="
                  'msg.page.ui.limit-msg-1'
                    | translate
                      : { currentCnt: limitInfo.currentCnt | numberCommas, maxCnt: limitInfo.maxCnt | numberCommas }
                "
              ></span>
              {{ 'msg.page.ui.limit-msg-2' | translate }}
            </div>
          </div>

          <div class="ddp-box-btn2" *ngIf="isPageWidget">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-info"
            >
              <circle cx="12" cy="12" r="10" />
              <line x1="12" y1="16" x2="12" y2="12" />
              <line x1="12" y1="8" x2="12.01" y2="8" />
            </svg>

            <div class="ddp-box-layout4">
              <div class="ddp-wrap-data-detail">
                <div class="ddp-ui-top-title">{{ 'msg.board.ui.title.chartinfo' | translate }}</div>
                <table class="ddp-table-pop">
                  <colgroup>
                    <col width="70px" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>{{ 'msg.page.ui.datasource' | translate }}</th>
                      <td>{{ getDataSourceName() }}</td>
                    </tr>
                    <tr *ngIf="existPivot('columns')">
                      <th>{{ 'msg.page.ui.col' | translate }}</th>
                      <td>{{ getPivotFieldsStr('columns') }}</td>
                    </tr>
                    <tr *ngIf="existPivot('rows')">
                      <th>{{ 'msg.page.ui.row' | translate }}</th>
                      <td>{{ getPivotFieldsStr('rows') }}</td>
                    </tr>
                    <tr *ngIf="existPivot('aggregations')">
                      <th>{{ 'msg.page.ui.intersect' | translate }}</th>
                      <td>{{ getPivotFieldsStr('aggregations') }}</td>
                    </tr>
                    <tr *ngIf="existChartFilter()">
                      <th>{{ 'msg.page.ui.filter' | translate }}</th>
                      <td>{{ getChartFilterStr() }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div *ngIf="isEditMode()" class="ddp-box-btn2" (click)="editWidget()">
            <svg
              class="feather feather-edit-2"
              fill="none"
              height="24"
              stroke="black"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path class="ng-tns-c2-1" d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
            </svg>
          </div>

          <div *ngIf="isEditMode() && isPageWidget" class="ddp-box-btn2" (click)="copyPageWidget()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-copy"
            >
              <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
              <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
            </svg>
          </div>

          <div *ngIf="isDimensionFilterWidget" class="ddp-box-btn-hover">
            <a href="javascript:" class="ddp-btn-tool">
              <em
                [ngClass]="{
                  'ddp-icon-tool-list': isListDimensionFilter,
                  'ddp-icon-tool-selectbox': !isListDimensionFilter
                }"
              ></em>
            </a>
            <a (click)="setSelectorTypeList()" href="javascript:" class="ddp-btn-tool">
              <em class="ddp-icon-tool-list"></em>
            </a>
            <a (click)="setSelectorTypeCombo()" href="javascript:" class="ddp-btn-tool">
              <em class="ddp-icon-tool-selectbox"></em>
            </a>
          </div>

          <div class="ddp-ui-btnview" *ngIf="false">
            <div class="ddp-box-btn" [class.ddp-selected]="!isGridModePageWidget" (click)="onChangeWidgetMode('chart')">
              <em class="ddp-icon-widget-graph"></em>

              <div class="ddp-ui-tooltip-info">
                <em class="ddp-icon-view-down"></em>
                {{ 'msg.book.widget.header.chartview' | translate }}
              </div>
            </div>
            <div
              class="ddp-box-btn"
              [class.ddp-selected]="isGridModePageWidget"
              (click)="onChangeWidgetMode('grid')"
              *ngIf="widget.type == 'page' && isAvailableGrid(widget)"
            >
              <em class="ddp-icon-widget-grid"></em>

              <div class="ddp-ui-tooltip-info">
                <em class="ddp-icon-view-down"></em>
                {{ 'msg.book.widget.header.tableview' | translate }}
              </div>
            </div>
          </div>

          <div *ngIf="isEditMode()" (click)="removeWidget()" class="ddp-box-btn2">
            <svg
              class="feather feather-x-square"
              fill="none"
              height="24"
              stroke="black"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect height="18" rx="2" ry="2" width="18" x="3" y="3"></rect>
              <line x1="9" x2="15" y1="9" y2="15"></line>
              <line x1="15" x2="9" y1="9" y2="15"></line>
            </svg>
          </div>
        </div>

        <div class="ddp-ui-buttons" *ngIf="isMiniHeader">
          <div
            (click)="toggleDisplayMoreLayer()"
            [class.ddp-selected]="isShowMore"
            class="ddp-wrap-morebutton ddp-hover-tooltip"
          >
            <em class="ddp-icon-more"></em>
            <div class="ddp-ui-tooltip-info ddp-down">
              <em class="ddp-icon-view-top"></em>
              {{ 'msg.comm.ui.more' | translate }}
            </div>

            <div class="sys-widget-header-layer ddp-wrap-popup2">
              <ul class="ddp-list-popup">
                <li *ngIf="!isTextWidget" (click)="toggleTitle()">
                  <a href="javascript:">
                    <label class="ddp-label-checked">
                      <em class="ddp-icon-tool-title"></em>
                      {{ 'msg.board.ui.tooltip.chart-name' | translate }}
                      <span class="ddp-ui-check">
                        <input type="checkbox" [checked]="isShowTitle" />
                        <i></i>
                        <span class="ddp-txt-check"></span>
                      </span>
                    </label>
                  </a>
                </li>
                <li *ngIf="isValidLegend()" (click)="toggleLegend()">
                  <a href="javascript:">
                    <label class="ddp-label-checked">
                      <em class="ddp-icon-tool-legend"></em>
                      {{ 'msg.page.ui.legend' | translate }}
                      <span class="ddp-ui-check">
                        <input type="checkbox" [checked]="isShowLegend()" />
                        <i></i>
                        <span class="ddp-txt-check"></span>
                      </span>
                    </label>
                  </a>
                </li>
                <li *ngIf="isValidMiniMap()" (click)="toggleMiniMap()">
                  <a href="javascript:">
                    <label class="ddp-label-checked">
                      <em class="ddp-icon-tool-minimap"></em>
                      {{ 'msg.page.ui.minimap' | translate }}
                      <span class="ddp-ui-check">
                        <input type="checkbox" [checked]="isShowMiniMap()" />
                        <i></i>
                        <span class="ddp-txt-check"></span>
                      </span>
                    </label>
                  </a>
                </li>
              </ul>

              <ul class="ddp-list-popup">
                <li *ngIf="isDimensionFilterWidget">
                  <div class="ddp-list-txt">
                    <em class="ddp-icon-tool-display"></em>
                    {{ 'msg.board.ui.display' | translate }}
                  </div>

                  <div class="ddp-list-tab-button ddp-type">
                    <span (click)="setSelectorTypeCombo()" [class.ddp-selected]="!isListDimensionFilter">
                      <em class="ddp-icon-tool-selectbox"></em>
                    </span>
                    <span (click)="setSelectorTypeList()" [class.ddp-selected]="isListDimensionFilter">
                      <em class="ddp-icon-tool-list"></em>
                    </span>
                  </div>
                </li>
                <li (click)="editWidget()">
                  <a href="javascript:">
                    <em class="ddp-icon-tool-edit"></em>
                    {{ 'msg.comm.ui.edit' | translate }}
                  </a>
                </li>
                <li *ngIf="isPageWidget" (click)="copyPageWidget()">
                  <a href="javascript:">
                    <em class="ddp-icon-tool-copy"></em>
                    {{ 'msg.comm.ui.copy' | translate }}
                  </a>
                </li>
                <li (click)="removeWidget()">
                  <a href="javascript:">
                    <em class="ddp-icon-tool-remove"></em>
                    {{ 'msg.comm.ui.del' | translate }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
