import { Component, ElementRef, Injector, Input } from '@angular/core';

import * as _ from 'lodash';

import { FormatOptionConverter, TooltipOptionConverter } from '@selfai-platform/bi-chart-engine';
import { UIChartDataLabelDisplayType, UIOption } from '@selfai-platform/bi-domain';

import { LabelBaseOptionComponent } from './labelbase-option.component';

@Component({
  selector: 'tooltip-option',
  templateUrl: './tooltip-option.component.html',
})
export class TooltipOptionComponent extends LabelBaseOptionComponent {
  @Input('uiOption')
  public set setUiOption(uiOption: UIOption) {
    if (!uiOption.toolTip) {
      uiOption.toolTip = {};
    }

    if (!uiOption.toolTip.displayTypes) {
      uiOption.toolTip.displayTypes = FormatOptionConverter.setDisplayTypes(uiOption.type, this.pivot as any);
    }

    uiOption.toolTip.previewList = TooltipOptionConverter.setTooltipPreviewList(uiOption);

    if (typeof uiOption.toolTip.useDefaultFormat === 'undefined') uiOption.toolTip.useDefaultFormat = true;

    this.uiOption = uiOption;
  }

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public toggleDisplayType(displayType: string, typeIndex: number): void {
    if (!this.uiOption.toolTip.displayTypes) {
      this.uiOption.toolTip.displayTypes = [];
    }

    let isFind = false;
    _.each(this.uiOption.toolTip.displayTypes, (type, index) => {
      if (_.eq(type, displayType)) {
        isFind = true;
        this.uiOption.toolTip.displayTypes[index] = null;
      }
    });

    if (!isFind) {
      this.uiOption.toolTip.displayTypes[typeIndex] = UIChartDataLabelDisplayType[displayType];
    }

    this.uiOption.toolTip.previewList = TooltipOptionConverter.setTooltipPreviewList(this.uiOption);

    this.apply();
  }

  public apply(): void {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { toolTip: this.uiOption.toolTip });
    this.update();
  }

  public changeUseDefaultFormat(): void {
    this.uiOption.toolTip.useDefaultFormat = !this.uiOption.toolTip.useDefaultFormat;
    this.apply();
  }
}
