import { ConfigService, KeycloakConfigService, PermissionService } from '@selfai-platform/shared';
import { KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from '../auth/initializeKeycloak';

export function onAppInit(
  keycloakService: KeycloakService,
  configService: ConfigService,
  keycloakConfigService: KeycloakConfigService,
  permissionService: PermissionService,
): () => Promise<void> {
  return async () => {
    await configService.init();
    await initializeKeycloak(keycloakService, keycloakConfigService);
    const keycloakConfig = keycloakConfigService.getConfig();
    const token = await keycloakService.getToken();
    permissionService.setRolesFromToken(keycloakConfig, token);
  };
}
