<div
  #labelArea
  class="ddp-wrap-graph-kpi"
  [class.ddp-type]="uiOption.series && uiOption['layout'] && uiOption['layout'] === 'VERTICAL'"
  [class.ddp-size-m]="!uiOption?.fontSize || uiOption?.fontSize === 'NORMAL'"
  [class.ddp-size-s]="uiOption?.fontSize === 'SMALL'"
>
  <ng-container *ngIf="!(uiOption.series && uiOption['layout'] === 'VERTICAL')">
    <div
      *ngFor="let item of list; let index = index"
      [class.ddp-solid]="uiOption.chartStyle === 'SOLID'"
      [class.ddp-color1]="index % 8 === 0"
      [class.ddp-color2]="index % 8 === 1"
      [class.ddp-color3]="index % 8 === 2"
      [class.ddp-color4]="index % 8 === 3"
      [class.ddp-color5]="index % 8 === 4"
      [class.ddp-color6]="index % 8 === 5"
      [class.ddp-color7]="index % 8 === 6"
      [class.ddp-color8]="index % 8 === 7"
      class="ddp-view-data-kpi"
      style="padding: 0px"
    >
      <div class="ddp-wrap-data-kpi" *ngIf="item.show" [class.ddp-img-none]="!item.showIcon">
        <div class="ddp-area-data-kpi">
          <div *ngIf="item.showIcon" class="ddp-img-kpi">
            <svg
              *ngIf="item.iconType === 'USER'"
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              viewBox="0 0 190 160"
              style="enable-background: new 0 0 190 160"
              xml:space="preserve"
            >
              <g>
                <path
                  class="st0"
                  d="M172.7,80c0-42.8-34.8-77.7-77.7-77.7c-20.1,0-39.2,7.7-53.8,21.6c-1.8,1.8-1.9,4.7-0.1,6.5c1.8,1.8,4.7,1.9,6.5,0.1
                        C60.4,18.3,77.2,11.5,95,11.5c37.8,0,68.5,30.7,68.5,68.5c0,17.2-6.4,33.6-18,46.2c-12.9,14.1-31.3,22.2-50.5,22.2
                        c-18.4,0-36-7.5-48.9-20.5c0.1-0.3,0.2-0.7,0.2-1c0.1-0.3,0.1-0.5,0.2-0.8c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.2,0.1-0.5,0.2-0.7
                        c0.1-0.4,0.3-0.9,0.5-1.3c0.1-0.2,0.1-0.3,0.2-0.5c0.4-1.2,0.9-2.4,1.5-3.5c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.4,0.4-0.8,0.6-1.2
                        c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.3,0.4-0.7,0.6-1c0.1-0.2,0.3-0.4,0.4-0.7c0.2-0.3,0.4-0.6,0.6-1c0.1-0.2,0.3-0.4,0.4-0.7
                        c0.2-0.3,0.4-0.6,0.6-0.9c0.2-0.2,0.3-0.4,0.5-0.7c0.2-0.3,0.4-0.6,0.6-0.8c0.2-0.2,0.3-0.4,0.5-0.7c0.2-0.3,0.4-0.5,0.7-0.8
                        c0.2-0.2,0.4-0.4,0.5-0.6c0.2-0.3,0.5-0.5,0.7-0.8c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2,0.5-0.5,0.7-0.7c0.2-0.2,0.4-0.4,0.6-0.6
                        c0.2-0.2,0.5-0.5,0.7-0.7c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.2,0.5-0.4,0.8-0.7c0.2-0.2,0.4-0.4,0.7-0.5c0.3-0.2,0.5-0.4,0.8-0.6
                        c0.2-0.2,0.5-0.4,0.7-0.5c0.3-0.2,0.5-0.4,0.8-0.6c0.2-0.2,0.5-0.3,0.7-0.5c0.3-0.2,0.6-0.4,0.8-0.6c0.2-0.2,0.5-0.3,0.7-0.5
                        c0.3-0.2,0.6-0.3,0.9-0.5c0.2-0.1,0.5-0.3,0.8-0.4c0.3-0.2,0.6-0.3,0.9-0.5c0.3-0.1,0.5-0.3,0.8-0.4c0.3-0.2,0.6-0.3,0.9-0.4
                        c0.3-0.1,0.5-0.3,0.8-0.4c0.3-0.1,0.6-0.3,0.9-0.4c0.3-0.1,0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.3,0.9-0.4c0.3-0.1,0.5-0.2,0.8-0.3
                        c0.3-0.1,0.6-0.2,1-0.3c0.3-0.1,0.5-0.2,0.8-0.3c0.3-0.1,0.7-0.2,1-0.3c0.3-0.1,0.6-0.2,0.8-0.2c0.3-0.1,0.7-0.2,1-0.3
                        c0.3-0.1,0.6-0.1,0.8-0.2c0.3-0.1,0.7-0.1,1.1-0.2c0.3-0.1,0.6-0.1,0.8-0.2c0.4-0.1,0.7-0.1,1.1-0.2c0.3,0,0.5-0.1,0.8-0.1
                        c0.4-0.1,0.8-0.1,1.2-0.1c0.3,0,0.5-0.1,0.8-0.1c0.4,0,0.9-0.1,1.3-0.1c0.2,0,0.4,0,0.7,0c0.7,0,1.3,0,2,0h11.3
                        c13.3,0,25.8,5.9,34.3,16.2c1.6,2,4.5,2.2,6.5,0.6c2-1.6,2.2-4.5,0.6-6.5c-7.2-8.8-16.9-14.9-27.5-17.7c6.1-5.4,9.9-13.3,9.9-22
                        c0-16.2-13.2-29.4-29.4-29.4c-16.2,0-29.4,13.2-29.4,29.4c0,8.8,3.8,16.6,9.9,22c-0.2,0-0.3,0.1-0.5,0.1c-0.6,0.2-1.3,0.4-1.9,0.6
                        c-0.1,0-0.2,0-0.2,0.1c-0.7,0.2-1.4,0.5-2.1,0.7c-0.2,0.1-0.4,0.2-0.6,0.2c-0.5,0.2-0.9,0.4-1.4,0.6c-0.3,0.1-0.5,0.2-0.8,0.3
                        c-0.4,0.2-0.8,0.4-1.2,0.5c-0.3,0.1-0.5,0.3-0.8,0.4c-0.4,0.2-0.8,0.4-1.2,0.6c-0.3,0.1-0.5,0.3-0.8,0.4c-0.4,0.2-0.8,0.4-1.2,0.6
                        c-0.2,0.1-0.5,0.3-0.7,0.4c-0.4,0.2-0.8,0.5-1.2,0.7c-0.2,0.1-0.4,0.3-0.6,0.4c-0.5,0.3-1,0.6-1.5,1c-0.1,0.1-0.2,0.1-0.3,0.2
                        c-0.6,0.4-1.2,0.8-1.7,1.3c-0.2,0.1-0.3,0.3-0.5,0.4c-0.4,0.3-0.8,0.6-1.2,1c-0.2,0.2-0.4,0.4-0.6,0.5c-0.3,0.3-0.7,0.6-1,0.9
                        c-0.2,0.2-0.4,0.4-0.6,0.6c-0.3,0.3-0.6,0.6-0.9,0.9c-0.2,0.2-0.4,0.4-0.6,0.6c-0.3,0.3-0.6,0.6-0.9,0.9c-0.2,0.2-0.4,0.4-0.6,0.6
                        c-0.3,0.3-0.6,0.7-0.9,1c-0.2,0.2-0.3,0.4-0.5,0.6c-0.4,0.4-0.7,0.9-1,1.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.4,0.6-0.9,1.2-1.3,1.7
                        c-0.1,0.2-0.2,0.3-0.3,0.5c-0.3,0.4-0.6,0.9-0.9,1.3c-0.1,0.2-0.3,0.5-0.4,0.7c-0.2,0.4-0.5,0.8-0.7,1.2c-0.2,0.3-0.3,0.5-0.4,0.8
                        c-0.2,0.4-0.4,0.8-0.6,1.2c-0.1,0.3-0.3,0.5-0.4,0.8c-0.2,0.4-0.4,0.8-0.6,1.2c-0.1,0.3-0.3,0.5-0.4,0.8c-0.2,0.4-0.4,0.8-0.6,1.3
                        c-0.1,0.3-0.2,0.5-0.3,0.8c-0.2,0.5-0.4,1-0.6,1.5c-0.1,0.2-0.2,0.4-0.2,0.6c0,0,0,0.1,0,0.1C30.9,108,26.5,94.2,26.5,80
                        c0-2.5-2.1-4.6-4.6-4.6s-4.6,2.1-4.6,4.6c0,19.5,7.2,38.1,20.4,52.5c14.7,16,35.6,25.2,57.3,25.2c21.7,0,42.6-9.2,57.3-25.2
                        c0,0,0,0,0,0C165.4,118.1,172.7,99.5,172.7,80z M95,44c11.1,0,20.2,9.1,20.2,20.2c0,11.1-9.1,20.2-20.2,20.2s-20.2-9.1-20.2-20.2
                        C74.8,53.1,83.9,44,95,44z"
                />
              </g>
            </svg>

            <svg
              *ngIf="item.iconType === 'HITS'"
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              viewBox="0 0 190 160"
              style="enable-background: new 0 0 190 160"
              xml:space="preserve"
            >
              <g>
                <path
                  class="st0"
                  d="M146.9,67.3c10.8-5.7,18.1-17.1,18.1-30.1C165,18.4,149.7,3,130.9,3C112,3,96.7,18.4,96.7,37.2
                        c0,13,7.4,24.4,18.1,30.1c-10.8,5.7-18.1,17-18.1,30V153c0,2.2,1.8,3.9,3.9,3.9h60.4c2.2,0,3.9-1.8,3.9-3.9V97.3
                        C165,84.3,157.6,73,146.9,67.3z M104.6,37.2c0-14.5,11.8-26.3,26.3-26.3s26.3,11.8,26.3,26.3c0,14.5-11.8,26.3-26.3,26.3
                        S104.6,51.7,104.6,37.2z M157.1,149.1h-52.5V97.3c0-14.3,11.6-26,26-26h0.6c14.3,0,26,11.6,26,26V149.1z"
                />
                <path
                  class="st0"
                  d="M82.5,66.2H83c4.8,0,9.3,1.7,12.9,4.9c1.6,1.5,4.1,1.3,5.6-0.3c1.5-1.6,1.3-4.1-0.3-5.6
                        c-5-4.5-11.4-6.9-18.2-6.9h-0.2c-10.8,0-19.6-8.8-19.6-19.6c0-10.8,8.8-19.6,19.6-19.6c1.8,0,3.6,0.2,5.3,0.7
                        c2.1,0.6,4.3-0.6,4.9-2.7c0.6-2.1-0.6-4.3-2.7-4.9c-2.4-0.7-4.9-1-7.4-1c-15.1,0-27.5,12.3-27.5,27.5c0,9.9,5.3,18.6,13.2,23.5
                        C60.6,67,55.3,75.7,55.3,85.6v48.2c0,2.2,1.8,3.9,3.9,3.9h23.1c2.2,0,3.9-1.8,3.9-3.9c0-2.2-1.8-3.9-3.9-3.9H63.2V85.6
                        C63.2,74.9,71.9,66.2,82.5,66.2z"
                />
                <path
                  class="st0"
                  d="M53.8,61.9c-2.7-1.3-5.6-2-8.6-2H45c-6.7,0-12.1-5.4-12.1-12.1c0-6.7,5.4-12.1,12.1-12.1
                        c2.2,0,3.9-1.8,3.9-3.9c0-2.2-1.8-3.9-3.9-3.9c-11,0-20,9-20,20c0,6.5,3.2,12.4,8,16c-4.9,3.6-8,9.4-8,15.9v34.9
                        c0,2.2,1.8,3.9,3.9,3.9h14.1c2.2,0,3.9-1.8,3.9-3.9s-1.8-3.9-3.9-3.9H32.9V79.8c0-6.6,5.4-11.9,11.9-11.9h0.3
                        c1.8,0,3.6,0.4,5.2,1.2c2,1,4.3,0.1,5.3-1.8C56.6,65.2,55.7,62.9,53.8,61.9z"
                />
              </g>
            </svg>

            <svg
              *ngIf="item.iconType === 'TIME'"
              currentScale="1"
              version="1.1"
              id="Layer_1"
              x="100%"
              y="100%"
              viewBox="0 0 190 160"
            >
              <g>
                <path
                  class="st0"
                  d="M148.9,3.7H41.1c-1.7,0-3.1,1.4-3.1,3.1v14.6c0,1.7,1.4,3.1,3.1,3.1h8.8c0.4,12.8,3.4,24.9,8.8,35.2
                  c5.8,11.1,13.7,19.1,23,23.2c0.4,0.2,0.8,0.3,1.2,0.3c1.2,0,2.3-0.7,2.8-1.9c0.7-1.6,0-3.4-1.6-4.1C68.2,70.2,56.9,48.8,56,24.5
                  h92.9c1.7,0,3.1-1.4,3.1-3.1V6.8C152,5.1,150.6,3.7,148.9,3.7z M145.8,18.3H44.2V9.8h101.6L145.8,18.3L145.8,18.3z"
                />
                <path
                  class="st0"
                  d="M148.9,135.5h-8.8c-0.4-12.8-3.4-24.9-8.8-35.2c-4.7-8.9-10.7-15.9-17.7-20.3c5.9-3.8,11.1-9.3,15.4-16.2
                  c5.5-8.9,9.1-19.7,10.5-31.3c0.2-1.7-1-3.2-2.7-3.4c-1.7-0.2-3.2,1-3.4,2.7C130.8,53.2,120,71,105.9,77.2c-1.1,0.5-1.9,1.6-1.9,2.8
                  c0,1.2,0.7,2.3,1.9,2.8c15.9,6.9,27.2,28.3,28.1,52.7H69.1c1.1-9.5,12.3-17.1,25.9-17.1c9.8,0,19,4.1,23.4,10.5
                  c0.8,1.2,2.4,1.5,3.6,0.7c1.2-0.8,1.5-2.4,0.7-3.6c-5.4-7.8-16-12.7-27.6-12.7c-16.5,0-30.1,9.8-31.1,22.2H56
                  c0.7-18.2,7.1-35,17.4-45.2c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-11.5,11.4-18.6,29.8-19.2,49.6h-8.8
                  c-1.7,0-3.1,1.4-3.1,3.1v14.6c0,1.7,1.4,3.1,3.1,3.1h107.8c1.7,0,3.1-1.4,3.1-3.1v-14.6C152,136.9,150.6,135.5,148.9,135.5z
                   M145.8,150.1H44.2v-8.5h8.7h84.2h8.7V150.1z"
                />
                <path
                  class="st0"
                  d="M95,109.9c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.4,0.9-2.2c0-0.8-0.3-1.6-0.9-2.2c-0.6-0.6-1.4-0.9-2.2-0.9
                  c-0.8,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2C93.4,109.6,94.2,109.9,95,109.9z"
                />
                <path
                  class="st0"
                  d="M95,90.3c-0.8,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2c0.6,0.6,1.4,0.9,2.2,0.9
                  c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.4,0.9-2.2c0-0.8-0.3-1.6-0.9-2.2C96.6,90.6,95.8,90.3,95,90.3z"
                />
                <path
                  class="st0"
                  d="M97.2,77.8c-0.6-0.6-1.4-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2
                  c0.6,0.6,1.4,0.9,2.2,0.9c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.4,0.9-2.2C98.1,79.2,97.8,78.4,97.2,77.8z"
                />
                <path
                  class="st0"
                  d="M113.2,61.3c4.5-5.6,8.1-13.2,10.3-22c0.2-0.9,0-1.9-0.6-2.7c-0.6-0.8-1.5-1.2-2.4-1.2h-51
                  c-1,0-1.9,0.4-2.4,1.2c-0.6,0.8-0.8,1.7-0.6,2.7c2.2,8.7,5.8,16.3,10.3,22c5.3,6.5,11.5,9.9,18.2,9.9
                  C101.6,71.2,107.9,67.8,113.2,61.3z M95,65.1c-9.8,0-17.5-11.2-21.4-23.3h42.8C112.5,53.9,104.8,65.1,95,65.1z"
                />
                <path
                  class="st0"
                  d="M148.9,3.7H41.1c-1.7,0-3.1,1.4-3.1,3.1v14.6c0,1.7,1.4,3.1,3.1,3.1h8.8c0.4,12.8,3.4,24.9,8.8,35.2
                  c5.8,11.1,13.7,19.1,23,23.2c0.4,0.2,0.8,0.3,1.2,0.3c1.2,0,2.3-0.7,2.8-1.9c0.7-1.6,0-3.4-1.6-4.1C68.2,70.2,56.9,48.8,56,24.5
                  h92.9c1.7,0,3.1-1.4,3.1-3.1V6.8C152,5.1,150.6,3.7,148.9,3.7z M145.8,18.3H44.2V9.8h101.6L145.8,18.3L145.8,18.3z"
                />
                <path
                  class="st0"
                  d="M148.9,135.5h-8.8c-0.4-12.8-3.4-24.9-8.8-35.2c-4.7-8.9-10.7-15.9-17.7-20.3c5.9-3.8,11.1-9.3,15.4-16.2
                  c5.5-8.9,9.1-19.7,10.5-31.3c0.2-1.7-1-3.2-2.7-3.4c-1.7-0.2-3.2,1-3.4,2.7C130.8,53.2,120,71,105.9,77.2c-1.1,0.5-1.9,1.6-1.9,2.8
                  c0,1.2,0.7,2.3,1.9,2.8c15.9,6.9,27.2,28.3,28.1,52.7H69.1c1.1-9.5,12.3-17.1,25.9-17.1c9.8,0,19,4.1,23.4,10.5
                  c0.8,1.2,2.4,1.5,3.6,0.7c1.2-0.8,1.5-2.4,0.7-3.6c-5.4-7.8-16-12.7-27.6-12.7c-16.5,0-30.1,9.8-31.1,22.2H56
                  c0.7-18.2,7.1-35,17.4-45.2c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-11.5,11.4-18.6,29.8-19.2,49.6h-8.8
                  c-1.7,0-3.1,1.4-3.1,3.1v14.6c0,1.7,1.4,3.1,3.1,3.1h107.8c1.7,0,3.1-1.4,3.1-3.1v-14.6C152,136.9,150.6,135.5,148.9,135.5z
                   M145.8,150.1H44.2v-8.5h8.7h84.2h8.7V150.1z"
                />
                <path
                  class="st0"
                  d="M95,109.9c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.4,0.9-2.2c0-0.8-0.3-1.6-0.9-2.2c-0.6-0.6-1.4-0.9-2.2-0.9
                  c-0.8,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2C93.4,109.6,94.2,109.9,95,109.9z"
                />
                <path
                  class="st0"
                  d="M95,90.3c-0.8,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2c0.6,0.6,1.4,0.9,2.2,0.9
                  c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.4,0.9-2.2c0-0.8-0.3-1.6-0.9-2.2C96.6,90.6,95.8,90.3,95,90.3z"
                />
                <path
                  class="st0"
                  d="M97.2,77.8c-0.6-0.6-1.4-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2
                  c0.6,0.6,1.4,0.9,2.2,0.9c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.4,0.9-2.2C98.1,79.2,97.8,78.4,97.2,77.8z"
                />
                <path
                  class="st0"
                  d="M113.2,61.3c4.5-5.6,8.1-13.2,10.3-22c0.2-0.9,0-1.9-0.6-2.7c-0.6-0.8-1.5-1.2-2.4-1.2h-51
                  c-1,0-1.9,0.4-2.4,1.2c-0.6,0.8-0.8,1.7-0.6,2.7c2.2,8.7,5.8,16.3,10.3,22c5.3,6.5,11.5,9.9,18.2,9.9
                  C101.6,71.2,107.9,67.8,113.2,61.3z M95,65.1c-9.8,0-17.5-11.2-21.4-23.3h42.8C112.5,53.9,104.8,65.1,95,65.1z"
                />
                <path
                  class="st0"
                  d="M148.9,3.7H41.1c-1.7,0-3.1,1.4-3.1,3.1v14.6c0,1.7,1.4,3.1,3.1,3.1h8.8c0.4,12.8,3.4,24.9,8.8,35.2
                  c5.8,11.1,13.7,19.1,23,23.2c0.4,0.2,0.8,0.3,1.2,0.3c1.2,0,2.3-0.7,2.8-1.9c0.7-1.6,0-3.4-1.6-4.1C68.2,70.2,56.9,48.8,56,24.5
                  h92.9c1.7,0,3.1-1.4,3.1-3.1V6.8C152,5.1,150.6,3.7,148.9,3.7z M145.8,18.3H44.2V9.8h101.6L145.8,18.3L145.8,18.3z"
                />
                <path
                  class="st0"
                  d="M148.9,135.5h-8.8c-0.4-12.8-3.4-24.9-8.8-35.2c-4.7-8.9-10.7-15.9-17.7-20.3c5.9-3.8,11.1-9.3,15.4-16.2
                  c5.5-8.9,9.1-19.7,10.5-31.3c0.2-1.7-1-3.2-2.7-3.4c-1.7-0.2-3.2,1-3.4,2.7C130.8,53.2,120,71,105.9,77.2c-1.1,0.5-1.9,1.6-1.9,2.8
                  c0,1.2,0.7,2.3,1.9,2.8c15.9,6.9,27.2,28.3,28.1,52.7H69.1c1.1-9.5,12.3-17.1,25.9-17.1c9.8,0,19,4.1,23.4,10.5
                  c0.8,1.2,2.4,1.5,3.6,0.7c1.2-0.8,1.5-2.4,0.7-3.6c-5.4-7.8-16-12.7-27.6-12.7c-16.5,0-30.1,9.8-31.1,22.2H56
                  c0.7-18.2,7.1-35,17.4-45.2c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-11.5,11.4-18.6,29.8-19.2,49.6h-8.8
                  c-1.7,0-3.1,1.4-3.1,3.1v14.6c0,1.7,1.4,3.1,3.1,3.1h107.8c1.7,0,3.1-1.4,3.1-3.1v-14.6C152,136.9,150.6,135.5,148.9,135.5z
                   M145.8,150.1H44.2v-8.5h8.7h84.2h8.7V150.1z"
                />
                <path
                  class="st0"
                  d="M95,109.9c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.4,0.9-2.2c0-0.8-0.3-1.6-0.9-2.2c-0.6-0.6-1.4-0.9-2.2-0.9
                  c-0.8,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2C93.4,109.6,94.2,109.9,95,109.9z"
                />
                <path
                  class="st0"
                  d="M95,90.3c-0.8,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2c0.6,0.6,1.4,0.9,2.2,0.9
                  c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.4,0.9-2.2c0-0.8-0.3-1.6-0.9-2.2C96.6,90.6,95.8,90.3,95,90.3z"
                />
                <path
                  class="st0"
                  d="M97.2,77.8c-0.6-0.6-1.4-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2
                  c0.6,0.6,1.4,0.9,2.2,0.9c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.4,0.9-2.2C98.1,79.2,97.8,78.4,97.2,77.8z"
                />
                <path
                  class="st0"
                  d="M113.2,61.3c4.5-5.6,8.1-13.2,10.3-22c0.2-0.9,0-1.9-0.6-2.7c-0.6-0.8-1.5-1.2-2.4-1.2h-51
                  c-1,0-1.9,0.4-2.4,1.2c-0.6,0.8-0.8,1.7-0.6,2.7c2.2,8.7,5.8,16.3,10.3,22c5.3,6.5,11.5,9.9,18.2,9.9
                  C101.6,71.2,107.9,67.8,113.2,61.3z M95,65.1c-9.8,0-17.5-11.2-21.4-23.3h42.8C112.5,53.9,104.8,65.1,95,65.1z"
                />
              </g>
            </svg>

            <svg
              *ngIf="item.iconType === 'VIEW'"
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              viewBox="0 0 190 160"
              style="enable-background: new 0 0 190 160"
              xml:space="preserve"
            >
              <g>
                <path
                  class="st0"
                  d="M182.4,75.9c-2.3-1.3-5.2-0.5-6.5,1.7c-8.1,13.9-19.7,25.6-33.5,33.8c-14.3,8.4-30.6,12.9-47.3,12.9
                        c-16.7,0-33-4.5-47.3-12.9c-13-7.7-24.1-18.5-32.1-31.4c8-12.9,19-23.7,32.1-31.4C62,40.2,78.3,35.7,95,35.7
                        c21.6,0,42.6,7.5,59.3,21.2c2,1.7,5.1,1.4,6.7-0.6c1.7-2,1.4-5.1-0.6-6.7C142,34.5,118.8,26.2,95,26.2c-18.4,0-36.4,4.9-52.2,14.2
                        c-15.3,9-28,21.9-36.9,37.2c-0.9,1.5-0.9,3.3,0,4.8c8.9,15.4,21.7,28.2,36.9,37.2c15.8,9.3,33.8,14.2,52.2,14.2
                        c18.4,0,36.4-4.9,52.2-14.2c15.3-9,28-21.9,36.9-37.2C185.5,80.1,184.7,77.2,182.4,75.9z"
                />
                <path
                  class="st0"
                  d="M95,106.2c-6.7,0-13-2.5-17.9-7c-1.9-1.8-5-1.7-6.8,0.2c-1.8,1.9-1.7,5,0.2,6.8c6.6,6.2,15.3,9.6,24.4,9.6
                        c19.7,0,35.8-16,35.8-35.8S114.7,44.2,95,44.2c-19.7,0-35.8,16-35.8,35.8c0,2.6,2.1,4.8,4.8,4.8c2.6,0,4.8-2.1,4.8-4.8
                        c0-14.4,11.8-26.2,26.2-26.2c14.5,0,26.2,11.7,26.2,26.2S109.5,106.2,95,106.2z"
                />
              </g>
            </svg>
          </div>

          <div class="ddp-summary">
            <div *ngIf="uiOption.showLabel" class="ddp-data-wrap">
              <span class="ddp-txt-title">{{ item.title }}</span>
            </div>

            <ng-container *ngIf="!item.emphasized">
              <span
                [style.color]="
                  uiOption?.positiveNegativeColor?.positiveColor
                    ? item.isPositive
                      ? uiOption?.positiveNegativeColor?.positiveColor
                      : uiOption?.positiveNegativeColor?.negativeColor
                    : null
                "
                class="ddp-data-result"
                style="white-space: pre"
              >
                {{ item.value }}
              </span>

              <span
                *ngIf="item.targetValue"
                [class.ddp-up]="item.isTargetPositive"
                [class.ddp-down]="!item.isTargetPositive"
                class="ddp-data-result2"
              >
                {{ item.targetValue }}
              </span>
            </ng-container>

            <ng-container *ngIf="item.emphasized">
              <span
                [style.color]="
                  uiOption?.positiveNegativeColor?.positiveColor
                    ? item.isTargetPositive
                      ? uiOption?.positiveNegativeColor?.positiveColor
                      : uiOption?.positiveNegativeColor?.negativeColor
                    : null
                "
                [class.ddp-up]="item.isTargetPositive"
                [class.ddp-down]="!item.isTargetPositive"
                class="ddp-data-result"
              >
                {{ item.targetValue }}
              </span>
              <span class="ddp-data-value">({{ item.emphasizedMessage }} : {{ item.targetOriginalValue }})</span>

              <span class="ddp-data-result2" style="white-space: pre">{{ item.value }}</span>
            </ng-container>

            <span *ngIf="item.text" class="ddp-data-calen">{{ item.text }}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="uiOption.series && uiOption['layout'] === 'VERTICAL'">
    <div
      *ngFor="let item of list; let index = index"
      [class.ddp-solid]="uiOption.chartStyle === 'SOLID'"
      [class.ddp-color1]="index % 8 === 0"
      [class.ddp-color2]="index % 8 === 1"
      [class.ddp-color3]="index % 8 === 2"
      [class.ddp-color4]="index % 8 === 3"
      [class.ddp-color5]="index % 8 === 4"
      [class.ddp-color6]="index % 8 === 5"
      [class.ddp-color7]="index % 8 === 6"
      [class.ddp-color8]="index % 8 === 7"
      class="ddp-view-data-kpi"
      style="padding: 0px"
    >
      <div class="ddp-wrap-data-kpi" *ngIf="item.show" [class.ddp-img-none]="!item.showIcon">
        <div class="ddp-area-data-kpi">
          <div *ngIf="item.showIcon" class="ddp-img-kpi">
            <svg
              *ngIf="item.iconType === 'USER'"
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              viewBox="0 0 190 160"
              style="enable-background: new 0 0 190 160"
              xml:space="preserve"
            >
              <g>
                <path
                  class="st0"
                  d="M172.7,80c0-42.8-34.8-77.7-77.7-77.7c-20.1,0-39.2,7.7-53.8,21.6c-1.8,1.8-1.9,4.7-0.1,6.5c1.8,1.8,4.7,1.9,6.5,0.1
                        C60.4,18.3,77.2,11.5,95,11.5c37.8,0,68.5,30.7,68.5,68.5c0,17.2-6.4,33.6-18,46.2c-12.9,14.1-31.3,22.2-50.5,22.2
                        c-18.4,0-36-7.5-48.9-20.5c0.1-0.3,0.2-0.7,0.2-1c0.1-0.3,0.1-0.5,0.2-0.8c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.2,0.1-0.5,0.2-0.7
                        c0.1-0.4,0.3-0.9,0.5-1.3c0.1-0.2,0.1-0.3,0.2-0.5c0.4-1.2,0.9-2.4,1.5-3.5c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.4,0.4-0.8,0.6-1.2
                        c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.3,0.4-0.7,0.6-1c0.1-0.2,0.3-0.4,0.4-0.7c0.2-0.3,0.4-0.6,0.6-1c0.1-0.2,0.3-0.4,0.4-0.7
                        c0.2-0.3,0.4-0.6,0.6-0.9c0.2-0.2,0.3-0.4,0.5-0.7c0.2-0.3,0.4-0.6,0.6-0.8c0.2-0.2,0.3-0.4,0.5-0.7c0.2-0.3,0.4-0.5,0.7-0.8
                        c0.2-0.2,0.4-0.4,0.5-0.6c0.2-0.3,0.5-0.5,0.7-0.8c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2,0.5-0.5,0.7-0.7c0.2-0.2,0.4-0.4,0.6-0.6
                        c0.2-0.2,0.5-0.5,0.7-0.7c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.2,0.5-0.4,0.8-0.7c0.2-0.2,0.4-0.4,0.7-0.5c0.3-0.2,0.5-0.4,0.8-0.6
                        c0.2-0.2,0.5-0.4,0.7-0.5c0.3-0.2,0.5-0.4,0.8-0.6c0.2-0.2,0.5-0.3,0.7-0.5c0.3-0.2,0.6-0.4,0.8-0.6c0.2-0.2,0.5-0.3,0.7-0.5
                        c0.3-0.2,0.6-0.3,0.9-0.5c0.2-0.1,0.5-0.3,0.8-0.4c0.3-0.2,0.6-0.3,0.9-0.5c0.3-0.1,0.5-0.3,0.8-0.4c0.3-0.2,0.6-0.3,0.9-0.4
                        c0.3-0.1,0.5-0.3,0.8-0.4c0.3-0.1,0.6-0.3,0.9-0.4c0.3-0.1,0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.3,0.9-0.4c0.3-0.1,0.5-0.2,0.8-0.3
                        c0.3-0.1,0.6-0.2,1-0.3c0.3-0.1,0.5-0.2,0.8-0.3c0.3-0.1,0.7-0.2,1-0.3c0.3-0.1,0.6-0.2,0.8-0.2c0.3-0.1,0.7-0.2,1-0.3
                        c0.3-0.1,0.6-0.1,0.8-0.2c0.3-0.1,0.7-0.1,1.1-0.2c0.3-0.1,0.6-0.1,0.8-0.2c0.4-0.1,0.7-0.1,1.1-0.2c0.3,0,0.5-0.1,0.8-0.1
                        c0.4-0.1,0.8-0.1,1.2-0.1c0.3,0,0.5-0.1,0.8-0.1c0.4,0,0.9-0.1,1.3-0.1c0.2,0,0.4,0,0.7,0c0.7,0,1.3,0,2,0h11.3
                        c13.3,0,25.8,5.9,34.3,16.2c1.6,2,4.5,2.2,6.5,0.6c2-1.6,2.2-4.5,0.6-6.5c-7.2-8.8-16.9-14.9-27.5-17.7c6.1-5.4,9.9-13.3,9.9-22
                        c0-16.2-13.2-29.4-29.4-29.4c-16.2,0-29.4,13.2-29.4,29.4c0,8.8,3.8,16.6,9.9,22c-0.2,0-0.3,0.1-0.5,0.1c-0.6,0.2-1.3,0.4-1.9,0.6
                        c-0.1,0-0.2,0-0.2,0.1c-0.7,0.2-1.4,0.5-2.1,0.7c-0.2,0.1-0.4,0.2-0.6,0.2c-0.5,0.2-0.9,0.4-1.4,0.6c-0.3,0.1-0.5,0.2-0.8,0.3
                        c-0.4,0.2-0.8,0.4-1.2,0.5c-0.3,0.1-0.5,0.3-0.8,0.4c-0.4,0.2-0.8,0.4-1.2,0.6c-0.3,0.1-0.5,0.3-0.8,0.4c-0.4,0.2-0.8,0.4-1.2,0.6
                        c-0.2,0.1-0.5,0.3-0.7,0.4c-0.4,0.2-0.8,0.5-1.2,0.7c-0.2,0.1-0.4,0.3-0.6,0.4c-0.5,0.3-1,0.6-1.5,1c-0.1,0.1-0.2,0.1-0.3,0.2
                        c-0.6,0.4-1.2,0.8-1.7,1.3c-0.2,0.1-0.3,0.3-0.5,0.4c-0.4,0.3-0.8,0.6-1.2,1c-0.2,0.2-0.4,0.4-0.6,0.5c-0.3,0.3-0.7,0.6-1,0.9
                        c-0.2,0.2-0.4,0.4-0.6,0.6c-0.3,0.3-0.6,0.6-0.9,0.9c-0.2,0.2-0.4,0.4-0.6,0.6c-0.3,0.3-0.6,0.6-0.9,0.9c-0.2,0.2-0.4,0.4-0.6,0.6
                        c-0.3,0.3-0.6,0.7-0.9,1c-0.2,0.2-0.3,0.4-0.5,0.6c-0.4,0.4-0.7,0.9-1,1.3c-0.1,0.1-0.2,0.3-0.3,0.4c-0.4,0.6-0.9,1.2-1.3,1.7
                        c-0.1,0.2-0.2,0.3-0.3,0.5c-0.3,0.4-0.6,0.9-0.9,1.3c-0.1,0.2-0.3,0.5-0.4,0.7c-0.2,0.4-0.5,0.8-0.7,1.2c-0.2,0.3-0.3,0.5-0.4,0.8
                        c-0.2,0.4-0.4,0.8-0.6,1.2c-0.1,0.3-0.3,0.5-0.4,0.8c-0.2,0.4-0.4,0.8-0.6,1.2c-0.1,0.3-0.3,0.5-0.4,0.8c-0.2,0.4-0.4,0.8-0.6,1.3
                        c-0.1,0.3-0.2,0.5-0.3,0.8c-0.2,0.5-0.4,1-0.6,1.5c-0.1,0.2-0.2,0.4-0.2,0.6c0,0,0,0.1,0,0.1C30.9,108,26.5,94.2,26.5,80
                        c0-2.5-2.1-4.6-4.6-4.6s-4.6,2.1-4.6,4.6c0,19.5,7.2,38.1,20.4,52.5c14.7,16,35.6,25.2,57.3,25.2c21.7,0,42.6-9.2,57.3-25.2
                        c0,0,0,0,0,0C165.4,118.1,172.7,99.5,172.7,80z M95,44c11.1,0,20.2,9.1,20.2,20.2c0,11.1-9.1,20.2-20.2,20.2s-20.2-9.1-20.2-20.2
                        C74.8,53.1,83.9,44,95,44z"
                />
              </g>
            </svg>

            <svg
              *ngIf="item.iconType === 'HITS'"
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              viewBox="0 0 190 160"
              style="enable-background: new 0 0 190 160"
              xml:space="preserve"
            >
              <g>
                <path
                  class="st0"
                  d="M146.9,67.3c10.8-5.7,18.1-17.1,18.1-30.1C165,18.4,149.7,3,130.9,3C112,3,96.7,18.4,96.7,37.2
                        c0,13,7.4,24.4,18.1,30.1c-10.8,5.7-18.1,17-18.1,30V153c0,2.2,1.8,3.9,3.9,3.9h60.4c2.2,0,3.9-1.8,3.9-3.9V97.3
                        C165,84.3,157.6,73,146.9,67.3z M104.6,37.2c0-14.5,11.8-26.3,26.3-26.3s26.3,11.8,26.3,26.3c0,14.5-11.8,26.3-26.3,26.3
                        S104.6,51.7,104.6,37.2z M157.1,149.1h-52.5V97.3c0-14.3,11.6-26,26-26h0.6c14.3,0,26,11.6,26,26V149.1z"
                />
                <path
                  class="st0"
                  d="M82.5,66.2H83c4.8,0,9.3,1.7,12.9,4.9c1.6,1.5,4.1,1.3,5.6-0.3c1.5-1.6,1.3-4.1-0.3-5.6
                        c-5-4.5-11.4-6.9-18.2-6.9h-0.2c-10.8,0-19.6-8.8-19.6-19.6c0-10.8,8.8-19.6,19.6-19.6c1.8,0,3.6,0.2,5.3,0.7
                        c2.1,0.6,4.3-0.6,4.9-2.7c0.6-2.1-0.6-4.3-2.7-4.9c-2.4-0.7-4.9-1-7.4-1c-15.1,0-27.5,12.3-27.5,27.5c0,9.9,5.3,18.6,13.2,23.5
                        C60.6,67,55.3,75.7,55.3,85.6v48.2c0,2.2,1.8,3.9,3.9,3.9h23.1c2.2,0,3.9-1.8,3.9-3.9c0-2.2-1.8-3.9-3.9-3.9H63.2V85.6
                        C63.2,74.9,71.9,66.2,82.5,66.2z"
                />
                <path
                  class="st0"
                  d="M53.8,61.9c-2.7-1.3-5.6-2-8.6-2H45c-6.7,0-12.1-5.4-12.1-12.1c0-6.7,5.4-12.1,12.1-12.1
                        c2.2,0,3.9-1.8,3.9-3.9c0-2.2-1.8-3.9-3.9-3.9c-11,0-20,9-20,20c0,6.5,3.2,12.4,8,16c-4.9,3.6-8,9.4-8,15.9v34.9
                        c0,2.2,1.8,3.9,3.9,3.9h14.1c2.2,0,3.9-1.8,3.9-3.9s-1.8-3.9-3.9-3.9H32.9V79.8c0-6.6,5.4-11.9,11.9-11.9h0.3
                        c1.8,0,3.6,0.4,5.2,1.2c2,1,4.3,0.1,5.3-1.8C56.6,65.2,55.7,62.9,53.8,61.9z"
                />
              </g>
            </svg>

            <svg
              *ngIf="item.iconType === 'TIME'"
              currentScale="1"
              version="1.1"
              id="Layer_1"
              x="100%"
              y="100%"
              viewBox="0 0 190 160"
            >
              <g>
                <path
                  class="st0"
                  d="M148.9,3.7H41.1c-1.7,0-3.1,1.4-3.1,3.1v14.6c0,1.7,1.4,3.1,3.1,3.1h8.8c0.4,12.8,3.4,24.9,8.8,35.2
                  c5.8,11.1,13.7,19.1,23,23.2c0.4,0.2,0.8,0.3,1.2,0.3c1.2,0,2.3-0.7,2.8-1.9c0.7-1.6,0-3.4-1.6-4.1C68.2,70.2,56.9,48.8,56,24.5
                  h92.9c1.7,0,3.1-1.4,3.1-3.1V6.8C152,5.1,150.6,3.7,148.9,3.7z M145.8,18.3H44.2V9.8h101.6L145.8,18.3L145.8,18.3z"
                />
                <path
                  class="st0"
                  d="M148.9,135.5h-8.8c-0.4-12.8-3.4-24.9-8.8-35.2c-4.7-8.9-10.7-15.9-17.7-20.3c5.9-3.8,11.1-9.3,15.4-16.2
                  c5.5-8.9,9.1-19.7,10.5-31.3c0.2-1.7-1-3.2-2.7-3.4c-1.7-0.2-3.2,1-3.4,2.7C130.8,53.2,120,71,105.9,77.2c-1.1,0.5-1.9,1.6-1.9,2.8
                  c0,1.2,0.7,2.3,1.9,2.8c15.9,6.9,27.2,28.3,28.1,52.7H69.1c1.1-9.5,12.3-17.1,25.9-17.1c9.8,0,19,4.1,23.4,10.5
                  c0.8,1.2,2.4,1.5,3.6,0.7c1.2-0.8,1.5-2.4,0.7-3.6c-5.4-7.8-16-12.7-27.6-12.7c-16.5,0-30.1,9.8-31.1,22.2H56
                  c0.7-18.2,7.1-35,17.4-45.2c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-11.5,11.4-18.6,29.8-19.2,49.6h-8.8
                  c-1.7,0-3.1,1.4-3.1,3.1v14.6c0,1.7,1.4,3.1,3.1,3.1h107.8c1.7,0,3.1-1.4,3.1-3.1v-14.6C152,136.9,150.6,135.5,148.9,135.5z
                   M145.8,150.1H44.2v-8.5h8.7h84.2h8.7V150.1z"
                />
                <path
                  class="st0"
                  d="M95,109.9c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.4,0.9-2.2c0-0.8-0.3-1.6-0.9-2.2c-0.6-0.6-1.4-0.9-2.2-0.9
                  c-0.8,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2C93.4,109.6,94.2,109.9,95,109.9z"
                />
                <path
                  class="st0"
                  d="M95,90.3c-0.8,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2c0.6,0.6,1.4,0.9,2.2,0.9
                  c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.4,0.9-2.2c0-0.8-0.3-1.6-0.9-2.2C96.6,90.6,95.8,90.3,95,90.3z"
                />
                <path
                  class="st0"
                  d="M97.2,77.8c-0.6-0.6-1.4-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2
                  c0.6,0.6,1.4,0.9,2.2,0.9c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.4,0.9-2.2C98.1,79.2,97.8,78.4,97.2,77.8z"
                />
                <path
                  class="st0"
                  d="M113.2,61.3c4.5-5.6,8.1-13.2,10.3-22c0.2-0.9,0-1.9-0.6-2.7c-0.6-0.8-1.5-1.2-2.4-1.2h-51
                  c-1,0-1.9,0.4-2.4,1.2c-0.6,0.8-0.8,1.7-0.6,2.7c2.2,8.7,5.8,16.3,10.3,22c5.3,6.5,11.5,9.9,18.2,9.9
                  C101.6,71.2,107.9,67.8,113.2,61.3z M95,65.1c-9.8,0-17.5-11.2-21.4-23.3h42.8C112.5,53.9,104.8,65.1,95,65.1z"
                />
                <path
                  class="st0"
                  d="M148.9,3.7H41.1c-1.7,0-3.1,1.4-3.1,3.1v14.6c0,1.7,1.4,3.1,3.1,3.1h8.8c0.4,12.8,3.4,24.9,8.8,35.2
                  c5.8,11.1,13.7,19.1,23,23.2c0.4,0.2,0.8,0.3,1.2,0.3c1.2,0,2.3-0.7,2.8-1.9c0.7-1.6,0-3.4-1.6-4.1C68.2,70.2,56.9,48.8,56,24.5
                  h92.9c1.7,0,3.1-1.4,3.1-3.1V6.8C152,5.1,150.6,3.7,148.9,3.7z M145.8,18.3H44.2V9.8h101.6L145.8,18.3L145.8,18.3z"
                />
                <path
                  class="st0"
                  d="M148.9,135.5h-8.8c-0.4-12.8-3.4-24.9-8.8-35.2c-4.7-8.9-10.7-15.9-17.7-20.3c5.9-3.8,11.1-9.3,15.4-16.2
                  c5.5-8.9,9.1-19.7,10.5-31.3c0.2-1.7-1-3.2-2.7-3.4c-1.7-0.2-3.2,1-3.4,2.7C130.8,53.2,120,71,105.9,77.2c-1.1,0.5-1.9,1.6-1.9,2.8
                  c0,1.2,0.7,2.3,1.9,2.8c15.9,6.9,27.2,28.3,28.1,52.7H69.1c1.1-9.5,12.3-17.1,25.9-17.1c9.8,0,19,4.1,23.4,10.5
                  c0.8,1.2,2.4,1.5,3.6,0.7c1.2-0.8,1.5-2.4,0.7-3.6c-5.4-7.8-16-12.7-27.6-12.7c-16.5,0-30.1,9.8-31.1,22.2H56
                  c0.7-18.2,7.1-35,17.4-45.2c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-11.5,11.4-18.6,29.8-19.2,49.6h-8.8
                  c-1.7,0-3.1,1.4-3.1,3.1v14.6c0,1.7,1.4,3.1,3.1,3.1h107.8c1.7,0,3.1-1.4,3.1-3.1v-14.6C152,136.9,150.6,135.5,148.9,135.5z
                   M145.8,150.1H44.2v-8.5h8.7h84.2h8.7V150.1z"
                />
                <path
                  class="st0"
                  d="M95,109.9c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.4,0.9-2.2c0-0.8-0.3-1.6-0.9-2.2c-0.6-0.6-1.4-0.9-2.2-0.9
                  c-0.8,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2C93.4,109.6,94.2,109.9,95,109.9z"
                />
                <path
                  class="st0"
                  d="M95,90.3c-0.8,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2c0.6,0.6,1.4,0.9,2.2,0.9
                  c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.4,0.9-2.2c0-0.8-0.3-1.6-0.9-2.2C96.6,90.6,95.8,90.3,95,90.3z"
                />
                <path
                  class="st0"
                  d="M97.2,77.8c-0.6-0.6-1.4-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2
                  c0.6,0.6,1.4,0.9,2.2,0.9c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.4,0.9-2.2C98.1,79.2,97.8,78.4,97.2,77.8z"
                />
                <path
                  class="st0"
                  d="M113.2,61.3c4.5-5.6,8.1-13.2,10.3-22c0.2-0.9,0-1.9-0.6-2.7c-0.6-0.8-1.5-1.2-2.4-1.2h-51
                  c-1,0-1.9,0.4-2.4,1.2c-0.6,0.8-0.8,1.7-0.6,2.7c2.2,8.7,5.8,16.3,10.3,22c5.3,6.5,11.5,9.9,18.2,9.9
                  C101.6,71.2,107.9,67.8,113.2,61.3z M95,65.1c-9.8,0-17.5-11.2-21.4-23.3h42.8C112.5,53.9,104.8,65.1,95,65.1z"
                />
                <path
                  class="st0"
                  d="M148.9,3.7H41.1c-1.7,0-3.1,1.4-3.1,3.1v14.6c0,1.7,1.4,3.1,3.1,3.1h8.8c0.4,12.8,3.4,24.9,8.8,35.2
                  c5.8,11.1,13.7,19.1,23,23.2c0.4,0.2,0.8,0.3,1.2,0.3c1.2,0,2.3-0.7,2.8-1.9c0.7-1.6,0-3.4-1.6-4.1C68.2,70.2,56.9,48.8,56,24.5
                  h92.9c1.7,0,3.1-1.4,3.1-3.1V6.8C152,5.1,150.6,3.7,148.9,3.7z M145.8,18.3H44.2V9.8h101.6L145.8,18.3L145.8,18.3z"
                />
                <path
                  class="st0"
                  d="M148.9,135.5h-8.8c-0.4-12.8-3.4-24.9-8.8-35.2c-4.7-8.9-10.7-15.9-17.7-20.3c5.9-3.8,11.1-9.3,15.4-16.2
                  c5.5-8.9,9.1-19.7,10.5-31.3c0.2-1.7-1-3.2-2.7-3.4c-1.7-0.2-3.2,1-3.4,2.7C130.8,53.2,120,71,105.9,77.2c-1.1,0.5-1.9,1.6-1.9,2.8
                  c0,1.2,0.7,2.3,1.9,2.8c15.9,6.9,27.2,28.3,28.1,52.7H69.1c1.1-9.5,12.3-17.1,25.9-17.1c9.8,0,19,4.1,23.4,10.5
                  c0.8,1.2,2.4,1.5,3.6,0.7c1.2-0.8,1.5-2.4,0.7-3.6c-5.4-7.8-16-12.7-27.6-12.7c-16.5,0-30.1,9.8-31.1,22.2H56
                  c0.7-18.2,7.1-35,17.4-45.2c1.2-1.2,1.2-3.2,0-4.4c-1.2-1.2-3.2-1.2-4.4,0c-11.5,11.4-18.6,29.8-19.2,49.6h-8.8
                  c-1.7,0-3.1,1.4-3.1,3.1v14.6c0,1.7,1.4,3.1,3.1,3.1h107.8c1.7,0,3.1-1.4,3.1-3.1v-14.6C152,136.9,150.6,135.5,148.9,135.5z
                   M145.8,150.1H44.2v-8.5h8.7h84.2h8.7V150.1z"
                />
                <path
                  class="st0"
                  d="M95,109.9c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.4,0.9-2.2c0-0.8-0.3-1.6-0.9-2.2c-0.6-0.6-1.4-0.9-2.2-0.9
                  c-0.8,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2C93.4,109.6,94.2,109.9,95,109.9z"
                />
                <path
                  class="st0"
                  d="M95,90.3c-0.8,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2c0.6,0.6,1.4,0.9,2.2,0.9
                  c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.4,0.9-2.2c0-0.8-0.3-1.6-0.9-2.2C96.6,90.6,95.8,90.3,95,90.3z"
                />
                <path
                  class="st0"
                  d="M97.2,77.8c-0.6-0.6-1.4-0.9-2.2-0.9c-0.8,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.4-0.9,2.2c0,0.8,0.3,1.6,0.9,2.2
                  c0.6,0.6,1.4,0.9,2.2,0.9c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.4,0.9-2.2C98.1,79.2,97.8,78.4,97.2,77.8z"
                />
                <path
                  class="st0"
                  d="M113.2,61.3c4.5-5.6,8.1-13.2,10.3-22c0.2-0.9,0-1.9-0.6-2.7c-0.6-0.8-1.5-1.2-2.4-1.2h-51
                  c-1,0-1.9,0.4-2.4,1.2c-0.6,0.8-0.8,1.7-0.6,2.7c2.2,8.7,5.8,16.3,10.3,22c5.3,6.5,11.5,9.9,18.2,9.9
                  C101.6,71.2,107.9,67.8,113.2,61.3z M95,65.1c-9.8,0-17.5-11.2-21.4-23.3h42.8C112.5,53.9,104.8,65.1,95,65.1z"
                />
              </g>
            </svg>

            <svg
              *ngIf="item.iconType === 'VIEW'"
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              viewBox="0 0 190 160"
              style="enable-background: new 0 0 190 160"
              xml:space="preserve"
            >
              <g>
                <path
                  class="st0"
                  d="M182.4,75.9c-2.3-1.3-5.2-0.5-6.5,1.7c-8.1,13.9-19.7,25.6-33.5,33.8c-14.3,8.4-30.6,12.9-47.3,12.9
                        c-16.7,0-33-4.5-47.3-12.9c-13-7.7-24.1-18.5-32.1-31.4c8-12.9,19-23.7,32.1-31.4C62,40.2,78.3,35.7,95,35.7
                        c21.6,0,42.6,7.5,59.3,21.2c2,1.7,5.1,1.4,6.7-0.6c1.7-2,1.4-5.1-0.6-6.7C142,34.5,118.8,26.2,95,26.2c-18.4,0-36.4,4.9-52.2,14.2
                        c-15.3,9-28,21.9-36.9,37.2c-0.9,1.5-0.9,3.3,0,4.8c8.9,15.4,21.7,28.2,36.9,37.2c15.8,9.3,33.8,14.2,52.2,14.2
                        c18.4,0,36.4-4.9,52.2-14.2c15.3-9,28-21.9,36.9-37.2C185.5,80.1,184.7,77.2,182.4,75.9z"
                />
                <path
                  class="st0"
                  d="M95,106.2c-6.7,0-13-2.5-17.9-7c-1.9-1.8-5-1.7-6.8,0.2c-1.8,1.9-1.7,5,0.2,6.8c6.6,6.2,15.3,9.6,24.4,9.6
                        c19.7,0,35.8-16,35.8-35.8S114.7,44.2,95,44.2c-19.7,0-35.8,16-35.8,35.8c0,2.6,2.1,4.8,4.8,4.8c2.6,0,4.8-2.1,4.8-4.8
                        c0-14.4,11.8-26.2,26.2-26.2c14.5,0,26.2,11.7,26.2,26.2S109.5,106.2,95,106.2z"
                />
              </g>
            </svg>
          </div>

          <div class="ddp-summary">
            <div *ngIf="uiOption.showLabel" class="ddp-data-wrap">
              <span class="ddp-txt-title">{{ item.title }}</span>

              <ng-container *ngIf="!item.emphasized">
                <span
                  *ngIf="item.targetValue"
                  [class.ddp-plus]="item.isTargetPositive"
                  [class.ddp-minus]="!item.isTargetPositive"
                  class="ddp-data-result3"
                >
                  {{ item.targetValue }}
                </span>
              </ng-container>

              <ng-container *ngIf="item.emphasized">
                <span class="ddp-data-result3" style="white-space: pre">{{ item.value }}</span>
              </ng-container>
            </div>

            <ng-container *ngIf="!item.emphasized">
              <span
                [style.color]="
                  uiOption?.positiveNegativeColor?.positiveColor
                    ? item.isPositive
                      ? uiOption?.positiveNegativeColor?.positiveColor
                      : uiOption?.positiveNegativeColor?.negativeColor
                    : null
                "
                class="ddp-data-result"
                style="white-space: pre"
              >
                {{ item.value }}
              </span>
            </ng-container>

            <ng-container *ngIf="item.emphasized">
              <span
                [style.color]="
                  uiOption?.positiveNegativeColor?.positiveColor
                    ? item.isTargetPositive
                      ? uiOption?.positiveNegativeColor?.positiveColor
                      : uiOption?.positiveNegativeColor?.negativeColor
                    : null
                "
                [class.ddp-up]="item.isTargetPositive"
                [class.ddp-down]="!item.isTargetPositive"
                class="ddp-data-result"
              >
                {{ item.targetValue }}
              </span>
              <span class="ddp-data-value">({{ item.emphasizedMessage }} : {{ item.targetOriginalValue }})</span>

              <span class="ddp-data-result2" style="white-space: pre">{{ item.value }}</span>
            </ng-container>

            <span *ngIf="item.text" class="ddp-data-calen">{{ item.text }}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
