import { BiPermission } from './bi-permission.enum';
import { PermissionType } from './permission-type';
import { PermissionZone, PermissionZoneName } from './permission-zone';
import { SelfaiRole } from './selfai-role';

export function populateRoleDefinitions(): { [roleName: string]: SelfaiRole } {
  const selfaiRoleDefinitions: { [roleName: string]: SelfaiRole } = {};
  for (const roleName in BiPermission) {
    const splittedRoleName = roleName.split('_');
    const zone = splittedRoleName[2].toUpperCase();
    const permission = splittedRoleName[3].toUpperCase();
    selfaiRoleDefinitions[roleName] = new SelfaiRole(roleName as BiPermission, [
      new PermissionZone(zone as PermissionZoneName, [permission as PermissionType]),
    ]);
  }

  return selfaiRoleDefinitions;
}
