import { Component, ElementRef, Inject, Injector, Input } from '@angular/core';

import * as _ from 'lodash';

import { BI_ROOT_ROUTE } from '../../../core';
import { AbstractComponent } from '../abstract.component';

@Component({
  selector: 'user-information',
  templateUrl: './user-information.component.html',
})
export class UserInformationComponent extends AbstractComponent {
  public readonly UNKNOWN_USER = 'Unknown user';

  @Input('name')
  public userName: string;

  @Input('id')
  public userId: string;

  @Input('email')
  public userEmail: string;

  @Input('left')
  public isLeft = false;

  constructor(
    protected element: ElementRef,
    protected injector: Injector,
    @Inject(BI_ROOT_ROUTE) private readonly biRootRoute: string,
  ) {
    super(element, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public onClickViewDetail(): void {
    this._savePrevRouterUrl();

    this.router.navigate([this.biRootRoute, 'admin', 'user', 'members', this.userId]);
  }

  public invalidUserName() {
    return _.isNil(this.userName) || this.userName === '';
  }

  public validUserName() {
    return !this.invalidUserName();
  }

  public invalidUserId() {
    return _.isNil(this.userId) || this.userId === '';
  }

  private _savePrevRouterUrl(): void {
    this.cookieService.set('PREV_ROUTER_URL', this.router.url);
  }
}
