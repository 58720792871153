export class StringUtil {
  public static random(length: number): string {
    let str = '';

    const randomStr =
      'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z' +
      ',A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z' +
      ',1,2,3,4,5,6,7,8,9,0';

    const chars = randomStr.split(',');
    let randomIndex = 0;

    for (let i = 0; i < length; i = i + 1) {
      randomIndex = Math.floor(Math.random() * chars.length);

      str += chars[randomIndex];
    }

    return str;
  }

  public static isId(str: string): boolean {
    if (
      !str ||
      str.match(
        /(^[0-9].*(?=[0-9a-zA-Z\.]{2,19}$)(?=.*\d?)(?=.*[a-zA-Z])(?=.*[\.]?).*$)|(^[a-zA-Z].*(?=[0-9a-zA-Z\.]{2,19}$)(?=.*\d?)(?=.*[a-zA-Z]?)(?=.*[\.]?).*$)/,
      ) == null
    ) {
      return false;
    } else {
      return true;
    }
  }

  public static isEmail(str: string): boolean {
    if (
      !str ||
      str.match(
        /^(\'.*\'|[A-Za-z0-9_-]([A-Za-z0-9_-]|[\+\.])*)@(\[\d{1,3}(\.\d{1,3}){3}]|[A-Za-z0-9][A-Za-z0-9_-]*(\.[A-Za-z0-9][A-Za-z0-9_-]*)+)$/,
      ) == null
    ) {
      return false;
    } else {
      return true;
    }
  }

  public static isNumber(num) {
    if (isNaN(num) === false && Number(num) >= 0) {
      return true;
    } else {
      return false;
    }
  }

  public static isTel(str: string): boolean {
    if (!str || str.match(/^(\d+-?)+\d+$/) == null) {
      return false;
    } else {
      return true;
    }
  }

  public static isDomain(str: string): boolean {
    return /^(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(
      str,
    );
  }

  public static isURL(str: string): boolean {
    return /^(mirror|http|https):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(
      str,
    );
  }

  public static isHTTP(str: string): boolean {
    return /^(http):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(
      str,
    );
  }

  public static isHTTPS(str: string): boolean {
    return /^(https):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(
      str,
    );
  }

  public static ltrim(str: string): string {
    return str.replace(/^\s+/, '');
  }

  public static rtrim(str: string): string {
    return str.replace(/\s+$/, '');
  }

  public static escapeHtml(str: string): string {
    const entityMap = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;',
      '/': '&#x2F;',
    };

    return String(str).replace(/[&<>''\/]/g, (s) => {
      return entityMap[s];
    });
  }

  public static isFloat(str): boolean {
    const floatVal = str;

    if (isNaN(floatVal) || Infinity === floatVal || -Infinity === floatVal || Number(floatVal) < 0) {
      return false;
    } else {
      return true;
    }
  }

  public static isEmpty(param: string): boolean {
    return param === undefined || param === null || (typeof param !== 'number' && this.trim(param) === '');
  }

  public static isNotEmpty(param: string): boolean {
    return !StringUtil.isEmpty(param);
  }

  public static trim(param: string): string {
    return param.trim();
  }

  public static unescapeCustomColumnExpr(expr: string): string {
    let returnValue = expr;
    let idx = 0;
    while (returnValue.indexOf('"') > -1) {
      if (idx === 0 || idx % 2 === 0) {
        returnValue = returnValue.replace('"', '[');
      } else {
        returnValue = returnValue.replace('"', ']');
      }
      idx = idx + 1;
    }

    return returnValue;
  }

  public static isPassword(str: string): boolean {
    if (
      !str ||
      str.match(/^.*(?=^.{10,20}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^*()\-_=+\\\|\[\]{};:\'",.<>\/?`~]).*$/g) == null
    ) {
      return false;
    } else {
      return true;
    }
  }

  public static checkSingleQuote(str: string, opts?: any): [boolean, string] {
    if (opts) {
      opts.hasOwnProperty('isPairQuote') || (opts.isPairQuote = false);
      opts.hasOwnProperty('isWrapQuote') || (opts.isWrapQuote = false);
      opts.hasOwnProperty('isAllowBlank') || (opts.isAllowBlank = true);
    } else {
      opts = {
        isPairQuote: false,
        isWrapQuote: false,
        isAllowBlank: true,
      };
    }

    let checkStr: string = str + '';
    if (str) {
      const regExpWrapQuote = /^'(.*)'$/;
      const isWrappedQuote: boolean = regExpWrapQuote.test(checkStr);
      if (isWrappedQuote) {
        checkStr = checkStr.replace(regExpWrapQuote, '$1');
      }
      if (str === "'") {
        return [false, ''];
      }

      if (opts.isPairQuote) {
        if (
          /(["'])(?:(?=(\\?))\2.)*?\1/g.test(checkStr) ||
          !/[^\\]'/g.test(checkStr) ||
          (!opts.isAllowBlank && /^\s*$/g.test(checkStr))
        ) {
          return [true, opts.isWrapQuote && !isWrappedQuote ? "'" + str + "'" : str];
        } else {
          return [false, str];
        }
      } else {
        if (/[^\\]'/g.test(checkStr) || (!opts.isAllowBlank && /^\s*$/g.test(checkStr))) {
          return [false, str];
        } else {
          return [true, opts.isWrapQuote && !isWrappedQuote ? "'" + str + "'" : str];
        }
      }
    } else {
      return [false, ''];
    }
  }

  public static checkRegExp(str: string): boolean {
    const checkStr: string = str + '';
    if (str) {
      const regExpWrapSlash = /^\/(.*)\/$/;
      return regExpWrapSlash.test(checkStr);
    } else {
      return false;
    }
  }

  public static checkFormula(str: string): boolean {
    const regExpFormula =
      /count\(\)|sum(\([a-zA-Z0-9_]*\)|\('[a-zA-Z0-9_]*'\))|avg(\([a-zA-Z0-9_]*\)|\('[a-zA-Z0-9_]*'\))|min(\([a-zA-Z0-9_]*\)|\('[a-zA-Z0-9_]*'\))|max(\([a-zA-Z0-9_]*\)|\('[a-zA-Z0-9_]*'\))|^[a-zA-Z0-9_]*\s?=\s?([a-zA-Z0-9_]*|'[a-zA-Z0-9_]*')$/;
    return regExpFormula.test(str);
  }

  public static isAlphaNumericUnderscore(str: string): boolean {
    if (str.match(/^[a-zA-Z0-9_]+$/) == null) {
      return false;
    } else {
      return true;
    }
  }

  public static replaceURIEncodingInQueryString(str: string): string {
    return str.replace(/#|\?|%|;/g, encodeURIComponent);
  }
}
