import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { PageParamsAdapter } from './page-params.adapter';
import { PageParams } from './page-params.model';

@Injectable()
export class PageParamsService implements PageParamsAdapter {
  private pageParams$ = new BehaviorSubject<PageParams | undefined>({
    pageNumber: 1,
    pageSize: 10,
  });

  setPageParams(pageParams: Partial<PageParams>): void {
    this.pageParams$.next({ ...(this.pageParams$.value || {}), ...pageParams } as PageParams);
  }

  getPageParams(): Observable<PageParams> {
    return this.pageParams$.asObservable().pipe(filter(Boolean));
  }
}
