export enum BiPermission {
  PERM_SELFAI_DATASOURCE_VIEW = 'PERM_SELFAI_DATASOURCE_VIEW',
  PERM_SELFAI_DATASOURCE_CREATE = 'PERM_SELFAI_DATASOURCE_CREATE',
  PERM_SELFAI_DATASOURCE_EDIT = 'PERM_SELFAI_DATASOURCE_EDIT',
  PERM_SELFAI_DATASOURCE_DELETE = 'PERM_SELFAI_DATASOURCE_DELETE',
  PERM_SELFAI_DATASET_VIEW = 'PERM_SELFAI_DATASET_VIEW',
  PERM_SELFAI_DATASET_CREATE = 'PERM_SELFAI_DATASET_CREATE',
  PERM_SELFAI_DATASET_EDIT = 'PERM_SELFAI_DATASET_EDIT',
  PERM_SELFAI_DATASET_DELETE = 'PERM_SELFAI_DATASET_DELETE',
  PERM_SELFAI_DATAFLOW_VIEW = 'PERM_SELFAI_DATAFLOW_VIEW',
  PERM_SELFAI_DATAFLOW_CREATE = 'PERM_SELFAI_DATAFLOW_CREATE',
  PERM_SELFAI_DATAFLOW_EDIT = 'PERM_SELFAI_DATAFLOW_EDIT',
  PERM_SELFAI_DATAFLOW_DELETE = 'PERM_SELFAI_DATAFLOW_DELETE',
  PERM_SELFAI_WORKSPACE_VIEW = 'PERM_SELFAI_WORKSPACE_VIEW',
  PERM_SELFAI_WORKSPACE_CREATE = 'PERM_SELFAI_WORKSPACE_CREATE',
  PERM_SELFAI_WORKSPACE_EDIT = 'PERM_SELFAI_WORKSPACE_EDIT',
  PERM_SELFAI_WORKSPACE_DELETE = 'PERM_SELFAI_WORKSPACE_DELETE',
  PERM_SELFAI_WORKBOOK_VIEW = 'PERM_SELFAI_WORKBOOK_VIEW',
  PERM_SELFAI_WORKBOOK_CREATE = 'PERM_SELFAI_WORKBOOK_CREATE',
  PERM_SELFAI_WORKBOOK_EDIT = 'PERM_SELFAI_WORKBOOK_EDIT',
  PERM_SELFAI_WORKBOOK_DELETE = 'PERM_SELFAI_WORKBOOK_DELETE',
  PERM_SELFAI_DASHBOARD_VIEW = 'PERM_SELFAI_DASHBOARD_VIEW',
  PERM_SELFAI_DASHBOARD_CREATE = 'PERM_SELFAI_DASHBOARD_CREATE',
  PERM_SELFAI_DASHBOARD_EDIT = 'PERM_SELFAI_DASHBOARD_EDIT',
  PERM_SELFAI_DASHBOARD_DELETE = 'PERM_SELFAI_DASHBOARD_DELETE',
}
