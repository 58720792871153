import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SessionStatus, WorkflowClonePayload, WorkflowInfo } from '@selfai-platform/pipeline-common';
import {
  DestroyService,
  PipelinePermission,
  PipelinePermissionEntity,
  PermissionService,
  WorkflowPermissionAction,
} from '@selfai-platform/shared';
import { DialogService, provideDialogService } from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable, switchMap, take, takeUntil } from 'rxjs';
import { SessionsManagerService } from '../../services/sessions-manager.service';
import { WorkflowAddDialogComponent } from './workflow-add-dialog/workflow-add-dialog.component';
import { WorkflowCloneDialogComponent } from './workflow-clone-dialog/workflow-clone-dialog.component';
import { Workflow, WorkflowListComponentService } from './workflow-list-component.service';

@Component({
  selector: 'selfai-platform-workflow-list',
  templateUrl: './workflow-list.component.html',
  styleUrls: ['./workflow-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WorkflowListComponentService, DestroyService, provideDialogService()],
})
export class WorkflowListComponent implements OnInit, OnDestroy {
  workflowList$!: Observable<Workflow[]>;

  sessionStatus = SessionStatus;
  permission = PipelinePermission;
  permissionAction = WorkflowPermissionAction;

  @ViewChild(Table, { read: Table }) tableRef!: Table;

  constructor(
    private readonly workflowListComponentService: WorkflowListComponentService,
    private readonly dialogService: DialogService<WorkflowClonePayload, WorkflowInfo>,
    private readonly dialogServiceForUpload: DialogService<{ success?: boolean }>,
    private readonly confirmationService: ConfirmationService,
    private readonly destroy$: DestroyService,
    private readonly sessionsManagerService: SessionsManagerService,
    private readonly permissionService: PermissionService,
  ) {}

  ngOnInit(): void {
    this.sessionsManagerService.startPollingSession();
    this.workflowListComponentService.loadWorkflowList();
    this.workflowList$ = this.workflowListComponentService.getWorkflowList();
  }

  ngOnDestroy(): void {
    this.sessionsManagerService.stopPollingSession();
  }

  filter(event: Event): void {
    this.tableRef.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  removeWorkflow(event: Event, id: string): void {
    this.workflowListComponentService
      .getWorkflowById(id)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((workflow) => {
        this.confirmationService.confirm({
          target: event.target as EventTarget,
          message: `Are you sure to proceed?\n\r The operation will delete workflow "${
            workflow?.name || ''
          }".\n\r Deletion cannot be undone afterwards.`,
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.workflowListComponentService.deleteWorkflow(id);
          },
        });
      });
  }

  cloneWorkflow(id: string): void {
    this.workflowListComponentService
      .getWorkflowById(id)
      .pipe(
        take(1),
        switchMap((workflow) => {
          return this.dialogService.showDialog(WorkflowCloneDialogComponent, {
            data: workflow,
            header: `Clone workflow - ${workflow?.name || ''}`,
            width: '50%',
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((wokflow) => {
        if (wokflow) {
          this.workflowListComponentService.cloneWorkflow(wokflow);
        }
      });
  }

  addWorkflow(): void {
    this.dialogService
      .showDialog(WorkflowAddDialogComponent, {
        header: 'New workflow',
        width: '50%',
      })
      .subscribe((wokflow) => {
        if (wokflow) {
          this.workflowListComponentService.addWorkflow(wokflow);
        }
      });
  }

  uploadWorkflow(): void {
    import('./workflow-upload-dialog').then((index) => {
      this.dialogServiceForUpload
        .showDialog(index.WorkflowUploadDialogComponent, {
          header: 'Upload workflow',
          width: '30%',
        })
        .subscribe((uploadState) => {
          if (uploadState?.success) {
            this.workflowListComponentService.loadWorkflowList();
          }
        });
    });
  }

  stopSessionWorkflow(id: string): void {
    this.workflowListComponentService.updateWorkflowState({ id: id, stopInProgress: true });
    this.sessionsManagerService.stopSession(id);
  }

  checkPermission(permissionAction: WorkflowPermissionAction, isOwner?: boolean): boolean {
    return this.permissionService.checkPermission({
      entity: PipelinePermissionEntity.Workflows,
      isOwner,
      action: permissionAction,
    });
  }
}
