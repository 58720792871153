import { AfterViewInit, Component } from '@angular/core';

import { timer } from 'rxjs';

import { Dashboard, PresentationDashboard } from '@selfai-platform/bi-domain';
import { DialogService } from '@selfai-platform/shell';

@Component({
  selector: 'selfai-bi-modal-workbook-nav',
  templateUrl: './maximazed-workbook.component.html',
  styleUrls: ['./maximazed-workbook.component.scss'],
})
export class MaximazedWorkbookComponent implements AfterViewInit {
  selectedDashboard: Dashboard;

  constructor(private readonly dialogService: DialogService<undefined, PresentationDashboard>) {}

  ngAfterViewInit(): void {
    timer(400).subscribe(() => {
      this.selectedDashboard = this.dialogService.data;
    });
  }

  closeDialog(): void {
    this.dialogService.close();
  }
}
