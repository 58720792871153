import { Injectable } from '@angular/core';

// TODO: this typings just for development I don't want to have a conflict with other developers

interface ChartData {
  columns: ChartDataColumn[];
  rows: string[];
  nodes?: unknown[];
  links?: unknown[];
  categories?: { value: number[]; percentage: number[] }[];
  totalFeatures?: number;
}

interface ChartDataColumn extends ApiChartDataColumn {
  categoryName?: string[];
  categoryValue?: number[];
  categoryPercent?: number[];
  seriesName?: string | string[];
  seriesValue?: number[];
  seriesPercent?: number[];
}

interface ApiChartData {
  columns: ApiChartDataColumn[];
  rows: string[];
  categories?: { value: number[]; percentage: number[] }[];
}

interface ApiChartDataColumn {
  value?: number[];
  percentage?: number[];
  name?: string;
  rows?: string[];
}

interface Column {
  value?: number[];
  percentage?: number[];
}

@Injectable({
  providedIn: 'root',
})
export class ChartDataService {
  mapApiData(apiWidgetData: ApiChartData): ChartData {
    const addAllValues = (columns: Column[], type: keyof Column): number[] => {
      const list: number[] = Array(apiWidgetData.columns[0][type]?.length || 0).fill(0);
      columns.forEach((item) => {
        if (!item[type]) return;
        item[type].forEach((value, index) => {
          list[index] += value;
        });
      });
      return list;
    };

    return {
      ...apiWidgetData,
      columns: apiWidgetData.columns?.map((data, index) => {
        const newData: ChartDataColumn = { ...data };

        newData.categoryName = apiWidgetData.rows;

        newData.categoryValue = [];
        newData.categoryPercent = [];

        if (!apiWidgetData.categories || (apiWidgetData.categories && apiWidgetData.categories.length == 0)) {
          newData.categoryValue = addAllValues(apiWidgetData.columns, 'value');
          newData.categoryPercent = addAllValues(apiWidgetData.columns, 'percentage');
          newData.seriesName = apiWidgetData.rows;
        } else {
          if (apiWidgetData.categories) {
            for (const category of apiWidgetData.categories) {
              newData.categoryValue = [category.value[index]];
              newData.categoryPercent = [category.percentage[index]];
            }
          }
          newData.seriesName = data.name?.split('CHART_STRING_DELIMITER')[0];
        }

        newData.seriesValue = apiWidgetData.columns[index].value;
        newData.seriesPercent = [...(data.percentage || [])];

        return newData;
      }),
    };
  }

  hasNoData(data: ChartData): boolean {
    return (
      (!(data instanceof Array) && data?.columns?.length === 0 && data?.rows?.length === 0) ||
      (!(data instanceof Array) && data?.nodes?.length === 0 && data?.links?.length === 0) ||
      (data instanceof Array && data.length === 0)
    );
  }
}
