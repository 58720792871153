import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CreateWidgetResult } from '../../dashboard/dto';
import { WidgetListQueryParams } from '../../dashboard/dto/widget/widget-list.model';
import { ChartListApiResponse } from '../model';

@Injectable({
  providedIn: 'root',
})
export abstract class ChartAdapter {
  abstract loadList(queryParams: WidgetListQueryParams): Observable<ChartListApiResponse>;
  abstract removeChart(id: string): Observable<unknown>;
  abstract moveChart(id: string, dashboardId: string): Observable<CreateWidgetResult>;
  abstract copyToChart(id: string, dashboardId: string): Observable<CreateWidgetResult>;
}
