export enum WORKSPACE_PERMISSION {
  PERM_WORKSPACE_MANAGE_WORKSPACE = 'PERM_WORKSPACE_MANAGE_WORKSPACE',
  PERM_WORKSPACE_MANAGE_FOLDER = 'PERM_WORKSPACE_MANAGE_FOLDER',
  PERM_WORKSPACE_VIEW_WORKBOOK = 'PERM_WORKSPACE_VIEW_WORKBOOK',
  PERM_WORKSPACE_EDIT_WORKBOOK = 'PERM_WORKSPACE_EDIT_WORKBOOK',
  PERM_WORKSPACE_MANAGE_WORKBOOK = 'PERM_WORKSPACE_MANAGE_WORKBOOK',
  PERM_WORKSPACE_VIEW_NOTEBOOK = 'PERM_WORKSPACE_VIEW_NOTEBOOK',
  PERM_WORKSPACE_EDIT_NOTEBOOK = 'PERM_WORKSPACE_EDIT_NOTEBOOK',
  PERM_WORKSPACE_MANAGE_NOTEBOOK = 'PERM_WORKSPACE_MANAGE_NOTEBOOK',
  PERM_WORKSPACE_VIEW_WORKBENCH = 'PERM_WORKSPACE_VIEW_WORKBENCH',
  PERM_WORKSPACE_EDIT_WORKBENCH = 'PERM_WORKSPACE_EDIT_WORKBENCH',
  PERM_WORKSPACE_MANAGE_WORKBENCH = 'PERM_WORKSPACE_MANAGE_WORKBENCH',
}
