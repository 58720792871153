import { NgModule } from "@angular/core";
import { FieldIconComponent } from "./field-icon/field-icon.component";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [FieldIconComponent],
  imports: [CommonModule],
  exports: [FieldIconComponent],
})
export class BiSharedModule {}
