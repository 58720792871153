import {
  BaseOption,
  CHART_STRING_DELIMITER,
  Pivot,
  ShelveFieldType,
  UIOption,
  UISplit,
} from '@selfai-platform/bi-domain';
import * as echarts from 'echarts';
import * as _ from 'lodash';

export class LineChartSplit {
  public isSplit(uiOption: UIOption): boolean {
    if (uiOption.split) {
      return true;
    }

    return false;
  }

  public setSplitData(data: any, pivot: Pivot, uiOption: UIOption, chartOption: BaseOption): BaseOption {
    if (!this.isSplit(uiOption)) {
      return chartOption;
    }

    const split: UISplit = uiOption.split;

    const list: number[] = [];

    const width: number = split.column;

    const height: number = split.row;

    const grids = [];

    const xAxes = [];

    const yAxes = [];

    const series = [];

    const titles = [];

    let count = 0;

    const columnCount: number = data.columns.length;

    let measureName = '';

    const refineColumns: object[] = [];

    let splitByIndex = 0;
    const splitSeriesIndex: number[] = [];
    let aggregationDimensionCount = 0;

    let seriesList = [];

    const splitSeriesList = {};

    for (let num = 0; num < columnCount; num++) {
      if (count >= data.columns.length) {
        break;
      }

      let xAxisData = [];
      data.rows.map((row) => {
        const rowNameList = _.split(row, CHART_STRING_DELIMITER);
        if (rowNameList.length >= 1) {
          xAxisData.push(rowNameList[0]);
          xAxisData = _.uniq(xAxisData);
        }
      });

      const seriesdata = [];

      let dimensionCount = 0;
      _.each(pivot.aggregations, (item) => {
        if (!_.eq(item.type, ShelveFieldType.MEASURE)) {
          dimensionCount++;
        }
      });

      if (_.eq(split.by, 'MEASURES')) {
        const refineColumns: object[] = [];
        _.each(pivot.aggregations, (item) => {
          if (_.eq(item.type, ShelveFieldType.MEASURE)) {
            refineColumns.push({ name: item.alias, value: [] });
          }
        });

        if (count >= refineColumns.length) {
          break;
        }

        _.each(refineColumns, (measure) => {
          data.columns.map((column) => {
            const columnNameList = _.split(column.name, CHART_STRING_DELIMITER);

            _.each(columnNameList, (item) => {
              if (item.indexOf(measure['name']) != -1) {
                _.each(column.value, (value, index) => {
                  measure['value'][index] = measure['value'][index] ? measure['value'][index] + value : value;
                });
                return false;
              }
            });
          });
        });

        xAxisData.map((xAxis, xAxisIndex) => {
          seriesdata[xAxisIndex] = 0;

          data.rows.map((row, rowIndex) => {
            const rowNameList = _.split(row, CHART_STRING_DELIMITER);
            if (xAxis == rowNameList[0]) {
              refineColumns[count]['value'].map((value, valueIndex) => {
                if (rowIndex == valueIndex) {
                  seriesdata[xAxisIndex] += value;
                }
              });
            }
          });
        });
      } else {
        if (num == 0) {
          _.each(pivot.aggregations, (item) => {
            if (_.eq(item.type, ShelveFieldType.MEASURE)) {
              measureName = item.alias;
              return false;
            }
          });

          data.columns.map((column, columnIndex) => {
            const columnNameList = _.split(column.name, CHART_STRING_DELIMITER);
            let measureIndex = -1;
            _.each(columnNameList, (item, index) => {
              if (item.indexOf(measureName) != -1) {
                measureIndex = index;
                return false;
              }
            });

            if (measureIndex != -1) {
              refineColumns.push(column);
            }
          });

          _.each(pivot.aggregations, (item, index) => {
            if (!_.eq(item.type, ShelveFieldType.MEASURE)) {
              if (item.alias.indexOf(split.by) != -1) {
                splitByIndex = aggregationDimensionCount;
              } else {
                splitSeriesIndex.push(aggregationDimensionCount);
              }

              aggregationDimensionCount++;
            }
          });

          refineColumns.map((column) => {
            const columnNameList = _.split(column['name'], CHART_STRING_DELIMITER);
            if (columnNameList.length >= 1) {
              seriesList.push(columnNameList[splitByIndex]);
              seriesList = _.uniq(seriesList);
            }
          });

          if (aggregationDimensionCount > 1) {
            _.each(seriesList, (series, index) => {
              splitSeriesList[series] = [];
              const splitSeries = splitSeriesList[series];

              refineColumns.map((column) => {
                const columnNameList = _.split(column['name'], CHART_STRING_DELIMITER);
                if (columnNameList[splitByIndex] == series) {
                  _.each(splitSeriesIndex, (seriesIndex, index) => {
                    if (index == 0) {
                      splitSeries.push('');
                    } else {
                      splitSeriesList[series][splitSeries.length - 1] += '-';
                    }
                    splitSeriesList[series][splitSeries.length - 1] += columnNameList[seriesIndex];
                  });
                }
              });
            });
          }
        }

        if (
          (count >= seriesList.length && aggregationDimensionCount == 0) ||
          (aggregationDimensionCount >= 1 && count >= refineColumns.length)
        ) {
          break;
        }

        xAxisData.map((xAxis, xAxisIndex) => {
          seriesdata[xAxisIndex] = 0;

          data.rows.map((row, rowIndex) => {
            const rowNameList = _.split(row, CHART_STRING_DELIMITER);
            if (xAxis == rowNameList[0]) {
              refineColumns.map((column, columnIndex) => {
                if (column['name'].indexOf(seriesList[count]) != -1) {
                  column['value'].map((value, valueIndex) => {
                    if (rowIndex == valueIndex) {
                      seriesdata[xAxisIndex] += value;
                    }
                  });
                }
              });
            }
          });
        });
      }

      grids.push({
        show: true,
        borderWidth: 0,
        backgroundColor: '#fff',
        shadowColor: 'rgba(0, 0, 0, 0.3)',
        shadowBlur: 2,
      });

      xAxes.push({
        type: 'category',

        show: count >= columnCount - width || count >= width * height - width,
        gridIndex: count,
        data: xAxisData,
      });

      yAxes.push({
        type: 'value',
        show: true,

        axisLabel: {
          show: count % width === 0,
        },
        axisTick: {
          show: count % width === 0,
        },
        gridIndex: count,
      });

      series.push({
        type: 'line',
        name: data.columns[count]['name'],
        xAxisIndex: count,
        yAxisIndex: count,
        data: seriesdata,
        showSymbol: false,
      });

      titles.push({
        textAlign: 'center',
        text: 'chart_' + count,
        textStyle: {
          fontSize: 13,
          fontWeight: 'normal',
        },
      });

      count++;
    }

    if (aggregationDimensionCount > 1) {
      _.each(seriesList, (seriesName, seriesNameIndex) => {
        let seriesCount = 0;
        _.each(series, (item, index) => {
          const columnNameList = _.split(item.name, CHART_STRING_DELIMITER);
          if (columnNameList[splitByIndex] == seriesName) {
            grids[index]['seriesIndex'] = seriesNameIndex;

            if (seriesCount == 0) {
              xAxes[index].show = seriesNameIndex >= columnCount - width || seriesNameIndex >= width * height - width;
              yAxes[index].show = true;
              yAxes[index].axisLabel.show = seriesNameIndex % width === 0;
              yAxes[index].axisTick.show = seriesNameIndex % width === 0;
            } else {
              xAxes[index].show = false;
              yAxes[index].show = false;
              grids[index].borderWidth = 0;
            }
            seriesCount++;
          }
        });
      });
    }

    if (width > 1) {
      let minValue = 0;
      let maxValue = 0;

      _.each(series, (item) => {
        _.each(item.data, (value) => {
          if (value < minValue) {
            minValue = value;
          } else if (value > maxValue) {
            maxValue = value;
          }
        });
      });

      const interval: number = Math.ceil(maxValue / 5);

      _.each(yAxes, (item) => {
        item.min = minValue;
        item.max = maxValue;
        item.interval = interval;
      });
    }

    echarts.util.each(grids, function (grid, idx) {
      if (typeof grid['seriesIndex'] != 'undefined') {
        idx = grid['seriesIndex'];
      }

      const marginLeft = 10;

      grid.left = ((idx % width) / width) * 100 + marginLeft + '%';
      grid.top = (Math.floor(idx / width) / height) * 100 + 2 + '%';
      grid.width = (1 / width) * 100 - marginLeft + '%';
      grid.height = (1 / height) * 100 - 6 + '%';

      titles[idx].left = parseFloat(grid.left) + parseFloat(grid.width) / 2 + '%';
      titles[idx].top = parseFloat(grid.top) + '%';
    });

    chartOption.grid = grids;
    chartOption.xAxis = xAxes;
    chartOption.yAxis = yAxes;
    chartOption.series = series;

    delete chartOption.dataZoom;
    delete chartOption.brush;
    delete chartOption.legend;
    delete chartOption.toolbox;

    return chartOption;
  }
}
