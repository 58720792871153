import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Dashboard, DashboardState } from '../models';

@Injectable({ providedIn: 'root' })
export class DashboardStore {
  private dashboards: Record<string, DashboardState> = {};

  getDashboardState(dashboardId: string): DashboardState {
    this.createInitialEntity(dashboardId);

    return this.dashboards[dashboardId];
  }

  getSnapshotDashboard(dashboardId: string): Dashboard | undefined {
    return this.getDashboardState(dashboardId).entity.value;
  }

  selectDashboard(dashboardId: string): Observable<Dashboard | undefined> {
    return this.getDashboardState(dashboardId).entity.asObservable();
  }

  selectDashboardLoading(dashboardId: string): Observable<boolean> {
    return this.getDashboardState(dashboardId).loading.asObservable();
  }

  selectDashboardError(dashboardId: string): Observable<unknown> {
    return this.getDashboardState(dashboardId).error.asObservable();
  }

  selectDashboardSuccess(dashboardId: string): Observable<boolean> {
    return this.getDashboardState(dashboardId).succes.asObservable();
  }

  updateDashboard(dashboardId: string, dashboard: Dashboard): void {
    if (this.getDashboardState(dashboardId)) {
      this.getDashboardState(dashboardId).entity.next(dashboard);
    } else {
      this.getDashboardState(dashboardId).entity = new BehaviorSubject<Dashboard | undefined>(dashboard);
    }

    this.setSuccess(dashboardId);
  }

  setSuccess(dashboardId: string): void {
    this.getDashboardState(dashboardId).succes.next(true);
    this.getDashboardState(dashboardId).loading.next(false);
    this.getDashboardState(dashboardId).error.next(undefined);
  }

  setError(dashboardId: string, error: unknown): void {
    this.getDashboardState(dashboardId).error.next(error);
    this.getDashboardState(dashboardId).loading.next(false);
    this.getDashboardState(dashboardId).succes.next(false);
  }

  setLoading(dashboardId: string): void {
    this.getDashboardState(dashboardId).loading.next(true);
    this.getDashboardState(dashboardId).succes.next(false);
    this.getDashboardState(dashboardId).error.next(undefined);
  }

  private createInitialEntity(dashboardId: string): void {
    if (!this.dashboards[dashboardId]) {
      this.dashboards[dashboardId] = {
        entity: new BehaviorSubject<Dashboard | undefined>(undefined),
        loading: new BehaviorSubject(false),
        error: new BehaviorSubject<unknown | undefined>(undefined),
        succes: new BehaviorSubject(false),
      };
    }
  }
}
