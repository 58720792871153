import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { CheckboxControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ui-settings-switch',
  templateUrl: './switch.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { '(change)': 'onChange($event.target.checked ? $event.target.value : undefined)', '(blur)': 'onTouched()' },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiSettingsSwitchComponent),
      multi: true,
    },
  ],
})
export class UiSettingsSwitchComponent extends CheckboxControlValueAccessor {
  @Input() label: string;
  @Input() value?: string;

  disabled = false;
}
