import { isNullOrUndefined } from 'util';

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Injector,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import { DragulaService } from 'ng2-dragula';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

import {
  BarChartComponent,
  BaseChart,
  GridChartComponent,
  LineChartComponent,
  MapChartComponent,
  OptionGenerator,
} from '@selfai-platform/bi-chart-engine';
import {
  BarMarkType,
  BoardConfiguration,
  BoardDataSource,
  ChartColorType,
  ChartSelectInfo,
  ChartType,
  ConnectionType,
  createBoardSource,
  createDatasourceField,
  createPageWidget,
  createPageWidgetConfiguration,
  createPivot,
  createShelf,
  createSort,
  CustomField,
  Dashboard,
  DashboardDomainService,
  Datasource,
  DatasourceField,
  DIRECTION,
  EventType,
  ExpressionField,
  FieldPivot,
  FieldRole,
  Filter,
  Format,
  LegendConvertType,
  LogicalType,
  MapLayerType,
  PageWidget,
  PageWidgetConfiguration,
  Pivot,
  PivotField,
  SearchQueryRequest,
  Shelf,
  ShelfLayers,
  ShelveFieldType,
  Sort,
  SPEC_VERSION,
  UIMapOption,
  UIOption,
  WidgetApiToDomainService,
} from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';

import { AbstractPopupComponent } from '../common/component/abstract-popup.component';
import { ConfirmModalComponent } from '../common/component/modal/confirm/confirm.component';
import { CookieConstant } from '../common/constant/cookie.constant';
import { Modal } from '../common/domain/modal';
import { ImageService } from '../common/service/image.service';
import { CommonUtil } from '../common/util/common.util';
import { StringUtil } from '../common/util/string.util';
import { ChartLimitInfo, convertPageWidgetSpecToServer, getMainDataSources } from '../dashboard';
import { ConfigureFiltersComponent } from '../dashboard/filters/configure-filters.component';
import { DashboardApiService } from '../dashboard/service/dashboard-api.service';
import { WidgetService } from '../dashboard/service/widget.service';
import { DashboardUtil } from '../dashboard/util/dashboard.util';
import { FilterUtil } from '../dashboard/util/filter.util';
import { DatasourceService } from '../datasource/service/datasource.service';

import { ColorOptionComponent } from './chart-style/color-option/color-option.component';
import { CommonOptionComponent } from './chart-style/common-option.component';
import { DataLabelOptionComponent } from './chart-style/datalabel-option.component';
import { FormatOptionComponent } from './chart-style/format-option.component';
import { MapFormatOptionComponent } from './chart-style/map/map-format-option.component';
import { MapLayerOptionComponent } from './chart-style/map/map-layer-option.component';
import { MapTooltipOptionComponent } from './chart-style/map/map-tooltip-option.component';
import { SecondaryIndicatorComponent } from './chart-style/secondary-indicator.component';
import { AnalysisComponent } from './component/analysis/analysis.component';
import { AnalysisPredictionService } from './component/analysis/service/analysis.prediction.service';
import { HyperParameter } from './component/value/analysis';
import { POSSIBLE_CHART_OBJECT, POSSIBLE_MOUSE_MODE_OBJECT } from './consts';
import { PageFilterPanelComponent } from './filter/filter-panel.component';
import { PageDataContextComponent } from './page-data/page-data-context.component';
import { MapPagePivotComponent } from './page-pivot/map/map-page-pivot.component';
import { PagePivotComponent } from './page-pivot/page-pivot.component';

@Component({
  templateUrl: 'page-view.component.html',
  styleUrls: ['./page-view.component.css'],
  providers: [DestroyService],
})
export class PageViewComponent extends AbstractPopupComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ConfirmModalComponent)
  private confirmModalComponent: ConfirmModalComponent;

  @ViewChild('pagePivot')
  private pagePivot: PagePivotComponent;

  @ViewChild('mapPivot')
  private mapPivot: MapPagePivotComponent;

  @ViewChild('chart')
  private chart: BaseChart;

  // @ViewChild(NetworkChartComponent)
  // private networkChart: NetworkChartComponent;

  @ViewChild(MapChartComponent)
  private mapChart: MapChartComponent;

  @ViewChild('gridChart')
  private gridChart: GridChartComponent;

  @ViewChild('formatOption')
  private formatOption: FormatOptionComponent;

  @ViewChild('commonOption')
  private commonOption: CommonOptionComponent;

  @ViewChild('dataLabelOption')
  private dataLabelOption: DataLabelOptionComponent;

  @ViewChild('colorOption')
  private colorOption: ColorOptionComponent;

  @ViewChild('secondaryIndicatorOption')
  private secondaryIndicatorOption: SecondaryIndicatorComponent;

  @ViewChild(ColorOptionComponent)
  private colorComponent: ColorOptionComponent;

  @ViewChild(AnalysisComponent)
  public analysisComponent: AnalysisComponent;

  @ViewChild(LineChartComponent)
  private lineChartComponent: LineChartComponent;

  private selectChartSource: Subject<Object> = new Subject<Object>();
  private selectChart$ = this.selectChartSource.asObservable().pipe(debounceTime(100));

  @ViewChild(PageDataContextComponent, { static: true })
  private dataContext: PageDataContextComponent;

  @ViewChild(ConfigureFiltersComponent, { static: true })
  private _configFilterComp: ConfigureFiltersComponent;

  @ViewChild(PageFilterPanelComponent)
  private _filterPanelComp: PageFilterPanelComponent;

  @ViewChild('mapFormatOption')
  private mapFormatOption: MapFormatOptionComponent;

  @ViewChild('mapTooltipOption')
  private mapTooltipOption: MapTooltipOptionComponent;

  @ViewChild('mapLayerOption')
  private mapLayerOption: MapLayerOptionComponent;

  @Output('changeFieldAlias')
  public changeFieldAliasEvent: EventEmitter<DatasourceField> = new EventEmitter();

  protected isShowGuide: boolean;
  protected guideLayout: any = [];

  protected selectedCustomField: ExpressionField;

  public dataSource: Datasource;

  public isVersionCheck = false;

  public resultData: any;

  public isChartView = true;

  public gridUiOption: UIOption = {};

  public recommendCharts: string[] = [];

  public isShowCustomFiled = false;

  public customMeasures: ExpressionField[];

  public measures: DatasourceField[];

  public dimensions: DatasourceField[];

  public fieldsWCustom: DatasourceField[];

  public customDimensions: ExpressionField[];

  public MAX_PAGE_COUNT = 30;
  public pageDimensions: DatasourceField[] = [];
  public pageMeasures: DatasourceField[] = [];

  public dimensionPage = 1;
  public dimensionTotalPage = 1;

  public measurePage = 1;
  public measureTotalPage = 1;

  public widget: PageWidget;
  public originalWidget: PageWidget;

  public originalWidgetConfiguration: PageWidgetConfiguration;

  public isPageNameEdit = false;

  public editingPageName: string;

  public isShowDataDetail = false;

  public isColumnDetail = false;

  public isNoData = false;
  public isError = false;

  public limitInfo: ChartLimitInfo = { id: '', isShow: false, currentCnt: 0, maxCnt: 0 };

  public isSankeyNotAllNode = false;

  public query: SearchQueryRequest;

  public dataSourceList: Datasource[] = [];
  public fields: DatasourceField[] = [];
  public boardFilters: Filter[] = [];

  public geoType: LogicalType;

  public dataLayerKey: string;

  public isDataDimensionLayerShow: boolean;
  public isDataMeasureLayerShow: boolean;

  public isModelLayerShow: boolean;

  public fieldSearchText = '';
  public fieldDetailLayer: DatasourceField;
  public $fieldDetailLayer: JQuery;

  public rnbMenu = '';

  public columnType: string;

  public panelZIndex = false;

  public showInfoChart: string;

  public isChartShow: boolean;

  public showFieldIconsFl = false;

  public selectedField: DatasourceField;

  public dashboard: Dashboard;

  get widgetConfiguration(): PageWidgetConfiguration {
    return <PageWidgetConfiguration>this.widget.configuration;
  }

  get selectChart(): string {
    return this.widgetConfiguration.chart.type ? this.widgetConfiguration.chart.type.toString().toLowerCase() : '';
  }

  set selectChart(chartType: string) {
    if (this.selectChart === chartType) {
      return;
    } else {
      this.widgetConfiguration.chart.type = ChartType[chartType.toUpperCase()] as ChartType;

      const deepCopyUiOption = _.cloneDeep(this.uiOption);

      this.uiOption = OptionGenerator.initUiOption(this.uiOption);

      this.uiOption.minValue = deepCopyUiOption.minValue;
      this.uiOption.maxValue = deepCopyUiOption.maxValue;

      this.changeDetect.detectChanges();

      if ('map' === chartType) {
        this.shelf = this.convertPivotToShelf(this.shelf);

        this.geoType = this.getMapGeoType();

        this._setDefaultAreaForBBox(this.dataSource);
      } else {
        this.pivot = this.convertShelfToPivot(this.pivot, deepCopyUiOption);
      }

      this.changeDetect.detectChanges();

      this.getPivotComp().onChangePivotPosition(chartType);

      this.recommendChart();
    }

    this.selectChartSource.next({ chartType: chartType, type: EventType.CHART_TYPE });
  }

  get uiOption(): UIOption {
    return this.widgetConfiguration.chart;
  }

  set uiOption(uiOption: UIOption) {
    this.widgetConfiguration.chart = uiOption;
  }

  get pivot(): Pivot {
    if (this.widgetConfiguration.pivot === undefined) {
      return createPivot();
    }
    return this.widgetConfiguration.pivot;
  }

  set pivot(pivot: Pivot) {
    this.widgetConfiguration.pivot = pivot;
  }

  get shelf(): Shelf {
    if (this.widgetConfiguration.shelf === undefined) {
      return createShelf();
    }
    return this.widgetConfiguration.shelf;
  }

  set shelf(shelf: Shelf) {
    this.widgetConfiguration.shelf = shelf;
  }

  get sorts(): Sort[] {
    return this.widgetConfiguration.limit.sort;
  }

  set sorts(sorts: Sort[]) {
    this.widgetConfiguration.limit.sort = sorts;
  }

  get filters(): Filter[] {
    return this.widgetConfiguration.filters;
  }

  set filters(filters: Filter[]) {
    this.widgetConfiguration.filters = filters;
  }

  set setWidget(widget: PageWidget) {
    this.dataSourceList = getMainDataSources(this.dashboard);

    const widgetDataSource: Datasource = DashboardUtil.getDataSourceFromBoardDataSource(
      this.dashboard,
      widget.configuration.dataSource,
    );

    if (widget.configuration.filters) {
      widget.configuration.filters.forEach((item) => {
        item.ui ||
          (item.ui = {
            importanceType: 'general',
          });
        if (!item.dataSource) {
          item.dataSource = widgetDataSource.name.toLowerCase();
        }
      });
    }

    widgetDataSource.fields.forEach((item) => (item.dataSource = widgetDataSource.engineName));
    this.dashboard.configuration.fields = widgetDataSource.fields;

    this.originalWidget = widget;

    this.selectDataSource(widgetDataSource ? widgetDataSource : this.dataSourceList[0], false);
  }

  readonly #translateService = inject(TranslateService);

  constructor(
    private dashboardService: DashboardApiService,
    private widgetService: WidgetService,
    private activatedRoute: ActivatedRoute,
    private dragulaService: DragulaService,
    private datasourceService: DatasourceService,
    private imageService: ImageService,
    private widgetDomainService: WidgetApiToDomainService,
    private destroy$: DestroyService,
    protected elementRef: ElementRef,
    protected injector: Injector,
    private analysisPredictionService: AnalysisPredictionService,
    private dashboardDomainService: DashboardDomainService,
  ) {
    super(elementRef, injector);
  }

  public static updatePivotAliasFromField(pivot: Pivot, field: DatasourceField) {
    pivot.columns.forEach((col) => {
      if (col.name === field.name) {
        (col.fieldAlias === col.alias || col.name === col.alias) && (col.alias = field.nameAlias.nameAlias);
        col.fieldAlias = field.nameAlias.nameAlias;
        col.field = _.merge(col.field, field);
        return true;
      }
    });
    pivot.rows.forEach((row) => {
      if (row.name === field.name) {
        (row.fieldAlias === row.alias || row.name === row.alias) && (row.alias = field.nameAlias.nameAlias);
        row.fieldAlias = field.nameAlias.nameAlias;
        row.field = _.merge(row.field, field);
        return true;
      }
    });
    pivot.aggregations.forEach((aggr) => {
      if (aggr.name === field.name) {
        (aggr.fieldAlias === aggr.alias || aggr.name === aggr.alias) && (aggr.alias = field.nameAlias.nameAlias);
        aggr.fieldAlias = field.nameAlias.nameAlias;
        aggr.field = _.merge(aggr.field, field);
        return true;
      }
    });
  }

  public static updateShelfAliasFromField(shelf: Shelf, field: DatasourceField, layerNum: number) {
    shelf.layers[layerNum].fields.forEach((layer) => {
      if (layer.name === field.name) {
        (layer.fieldAlias === layer.alias || layer.name === layer.alias) && (layer.alias = field.nameAlias.nameAlias);
        layer.fieldAlias = field.nameAlias.nameAlias;
        layer.field = _.merge(layer.field, field);
        return true;
      }
    });
  }

  public ngOnInit() {
    super.ngOnInit();

    this.init();

    const resizeEvent$ = fromEvent(window, 'resize').pipe(
      map(() => document.documentElement.clientWidth + 'x' + document.documentElement.clientHeight),
      debounceTime(500),
    );
    const windowResizeSubscribe = resizeEvent$.subscribe(() => {
      this.dataPanelInnerScroll();

      if (this.colorComponent && this.colorComponent.colorPicker && this.colorComponent.colorPicker.show) {
        const $target = this.$element.find('.ddp-wrap-chart-side .ddp-box-color.ddp-selected');
        const $picker = this.$element.find('.ddp-pop-colorpicker');
        if ($target && $target.offset()) {
          const $targetX = $target.offset().left;

          $picker.css({
            left: $targetX - 30,
          });
        }
      }
    });
    this.subscriptions.push(windowResizeSubscribe);

    const changeChartSubs = this.selectChart$.subscribe((param) => {
      if (param['chartType'] !== '') {
        this.drawChart({ type: param['type'] });
      }
    });
    this.subscriptions.push(changeChartSubs);

    this.settingDragAndDrop();

    window.history.pushState(null, null, window.location.href);

    const paramSubs: Subscription = this.activatedRoute.queryParams.subscribe((params) => {
      params['loginToken'] && this.cookieService.set(CookieConstant.KEY.LOGIN_TOKEN, params['loginToken'], 0, '/');
      params['loginType'] && this.cookieService.set(CookieConstant.KEY.LOGIN_TOKEN_TYPE, params['loginType'], 0, '/');
      params['refreshToken'] &&
        this.cookieService.set(CookieConstant.KEY.REFRESH_LOGIN_TOKEN, params['refreshToken'], 0, '/');
      if (params['pageId']) {
        this.widgetDomainService
          .getWidget(params['pageId'])
          .pipe(takeUntil(this.destroy$))
          .subscribe((result) => {
            const pageWidget = <PageWidget>_.extend(createPageWidget(), result);
            this.setWidget = pageWidget;
          });
      } else {
        this.dashboardDomainService.loadDashboard(params['dashboardId']).pipe(takeUntil(this.destroy$)).subscribe();

        this.dashboardDomainService
          .getDashboard(params['dashboardId'])
          .pipe(takeUntil(this.destroy$))
          .subscribe((result) => {
            this.dashboard = result;
            const pageWidget = createPageWidget({
              dashBoardId: result.id,
              name: this.#translateService.instant('msg.board.chart.newDiagram'),
            });

            const boardDataSource: BoardDataSource = createBoardSource();
            {
              const datasource = this.dashboard.dataSources[0] as Datasource;

              boardDataSource.id = datasource.id;
              boardDataSource.type = 'default';
              boardDataSource.name = datasource.engineName;
              boardDataSource.engineName = datasource.engineName;
              boardDataSource.connType = datasource.connType.toString();
              pageWidget.configuration.dataSource = boardDataSource;
            }

            this.setWidget = pageWidget;
          });
      }
    });
    this.subscriptions.push(paramSubs);
  }

  public ngAfterViewInit() {
    super.ngAfterViewInit();
    setTimeout(() => {
      this.dataPanelInnerScroll();
    }, 1000);
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    this.dragulaService.destroy('dragbag');
  }

  private _setDefaultAreaForBBox(dataSource: Datasource) {
    if ((isNullOrUndefined(this.widgetConfiguration.chart['lowerCorner']) || !this.isChartShow) && dataSource.summary) {
      this.widgetConfiguration.chart['lowerCorner'] = dataSource.summary['geoLowerCorner'];
      this.widgetConfiguration.chart['upperCorner'] = dataSource.summary['geoUpperCorner'];
    }
  }

  private _setDataSourceCurrentLayer(dataSource: Datasource) {
    if (this.widgetConfiguration.shelf) {
      const currentLayer: ShelfLayers = this.widgetConfiguration.shelf.layers[(<UIMapOption>this.uiOption).layerNum];
      if (0 === currentLayer.fields.length) {
        currentLayer.ref = dataSource.engineName;
      }
    }
  }

  public selectDataSource(dataSource: Datasource, isBBoxChange: boolean) {
    this.widget || (this.widget = _.cloneDeep(this.originalWidget));

    if (ChartType.MAP === this.widget.configuration.chart.type) {
      this.boardFilters = this.dashboard.configuration.filters;
      this.dataSource = dataSource;

      isBBoxChange && this._setDefaultAreaForBBox(dataSource);

      this.setDatasourceFields(true);

      this.widget.configuration.dataSource = this.dashboard.configuration.dataSource;

      this._setDataSourceCurrentLayer(dataSource);

      this.geoType = this.getMapGeoType();
    } else {
      this.isChartShow = false;
      this.dataSource = dataSource;
      let widgetName: string = null;
      if (this.widget && this.widget.name) {
        widgetName = this.widget.name;
      }
      this.widget = _.cloneDeep(this.originalWidget);
      this.widget.name = !widgetName ? this.originalWidget.name : widgetName;
      const widgetDataSource: Datasource = DashboardUtil.getDataSourceFromBoardDataSource(
        this.dashboard,
        this.widget.configuration.dataSource,
      );

      if (widgetDataSource.id !== dataSource.id) {
        this.widget.configuration = createPageWidgetConfiguration({
          dataSource: DashboardUtil.findDataSourceOnBoard(this.dashboard, dataSource),
          filters: [],
          customFields: [],
        });
      }

      if (ConnectionType.LINK === this.dataSource.connType) {
        this.boardFilters = DashboardUtil.getAllFiltersDsRelations(this.dashboard, this.dataSource.engineName);
      } else {
        this.boardFilters = DashboardUtil.getAllFiltersDsRelations(
          this.dashboard,
          this.widget.configuration.dataSource.engineName,
        );
      }

      if (StringUtil.isEmpty(this.widget.name)) {
        this.widget.name = this.translateService.instant('msg.board.chart.newDiagram');
      }
      this.uiOption = this.widgetConfiguration.chart;
      this.originalWidgetConfiguration = _.cloneDeep(this.widgetConfiguration);

      this.setDatasourceFields(true);

      if (this.pagePivot) this.pagePivot.removeAnimation();
    }
  }

  public save() {
    if (!this.chart || !this.isChartShow) {
      return;
    }
    this.loadingShow();

    if (
      _.eq(this.selectChart, ChartType.MAP) &&
      this.uiOption['layers'] &&
      this.uiOption['layers'][this.uiOption['layerNum']]['type'] == MapLayerType.CLUSTER &&
      !_.isUndefined(this.uiOption['layers'][this.uiOption['layerNum']]['clustering']) &&
      this.uiOption['layers'][this.uiOption['layerNum']]['clustering']
    ) {
      this.uiOption['layers'][this.uiOption['layerNum']]['type'] = MapLayerType.SYMBOL;
    }

    if (this.isNewWidget()) {
      if (StringUtil.isEmpty(this.widget.name)) {
        this.widget.name = this.translateService.instant('msg.board.chart.newDiagram');
      }
      let param;

      if (_.eq(this.selectChart, ChartType.MAP)) {
        param = _.extend({}, this.widget, { shelf: this.shelf });
      } else {
        param = _.extend({}, this.widget, { pivot: this.pivot });
      }

      param.configuration = convertPageWidgetSpecToServer(param.configuration);

      const pageConf: PageWidgetConfiguration = param.configuration as PageWidgetConfiguration;

      if (!_.isEmpty(this.chart.saveDataZoomRange())) pageConf.chart.chartZooms = this.chart.saveDataZoomRange();

      pageConf.chart.version = 2;

      this.widgetService
        .createWidget(param, this.dashboard.id)
        .then((widget) => {
          const pageWidget: PageWidget = _.extend(
            createPageWidget({
              dashBoardId: this.dashboard.id,
            }),
            widget,
          );

          pageWidget.configuration.filters.forEach((filter) => {
            filter.ui ||
              (filter.ui = {
                importanceType: 'general',
                widgetId: pageWidget.id,
              });
          });
          const configuration = { configuration: pageWidget.configuration };
          this.widgetService.updateWidget(pageWidget.id, configuration).then(() => {
            if (window.opener) {
              window.opener.postMessage(pageWidget.id, '*');
            }
            window.close();
          });
        })
        .catch((err) => {
          this.loadingHide();
          console.error(err);
        });
    } else {
      const param = {
        configuration: this.widgetConfiguration,
        name: this.widget.name,
      };

      param.configuration = convertPageWidgetSpecToServer(param.configuration);

      const pageConf: PageWidgetConfiguration = param.configuration as PageWidgetConfiguration;

      if (!_.isEmpty(this.chart.saveDataZoomRange())) pageConf.chart.chartZooms = this.chart.saveDataZoomRange();

      pageConf.chart.version = 2;

      this.widgetService
        .updateWidget(this.widget.id, param)
        .then((widget) => {
          if (window.opener) {
            window.opener.postMessage(this.widget.id, '*');
          }
          window.close();
        })
        .catch((err) => {
          this.loadingHide();
          console.info(err);
        });
    }
  }

  public close() {
    window.close();
  }

  public onTableDataDownload(): void {
    this.widgetService.downloadWidget(this.widget.id, true, 1000000).subscribe((result) => {
      saveAs(result, 'TableData.csv');
    });
  }

  public onChartDataDownload(): void {
    this.widgetService.downloadWidget(this.widget.id, false, 1000000).subscribe((result) => {
      saveAs(result, 'ChartData.csv');
    });
  }

  public openCustomFieldPopup(customField?: ExpressionField, columnType?: string) {
    this.columnType = columnType;

    if (customField) {
      this.selectedCustomField = customField;
    } else {
      this.selectedCustomField = null;
    }

    this.isShowCustomFiled = true;
  }

  public onColumnDetailPopup(field: DatasourceField): void {
    this.selectedField = field;
    this.isColumnDetail = true;
    this.isShowDataDetail = true;
  }

  public onDataPreviewPopup(): void {
    this.selectedField = null;
    this.isColumnDetail = false;
    this.isShowDataDetail = true;
  }

  public toggleMapLayer(rnbMenu: string, layerNum: number) {
    this.setDisableShelf(layerNum);

    this.toggleRnb(rnbMenu);
  }

  public toggleRnb(rnbMenu: string) {
    if (!this.isChartShow && 'filter' !== rnbMenu) {
      return;
    }

    if (this.rnbMenu === rnbMenu) {
      this.rnbMenu = '';
    } else {
      this.rnbMenu = rnbMenu;
    }

    this.changeDetect.detectChanges();

    if (this.getPivotComp()) this.getPivotComp().onShelveAnimation(this.$element.find('.ddp-wrap-default'));

    if (this.selectChart == 'map' || this.rnbMenu == '') this.chartResize();
  }

  public isShowChartInfo(chartType: string) {
    return (this.selectChart === chartType && this.showInfoChart === '') || this.showInfoChart === chartType;
  }

  public changeMouseSelectMode(event) {
    const mode = $(event.currentTarget).data('mode');

    const brushType = $(event.currentTarget).data('type');

    const selectGroup = $('div[data-type="select-gruop"]');

    const selectedTool = $(event.currentTarget).children().first();

    const currentButton = selectGroup.find('.ddp-btn-tool').first();

    const currentTool = currentButton.children().first();

    currentButton.data('mode', mode);
    currentButton.data('type', brushType);
    currentTool.attr('class', selectedTool[0].className);

    this.chart.convertMouseMode(mode, brushType);
  }

  public possibleMouseModeCheck(type: string, chartType: ChartType) {
    return _.indexOf(POSSIBLE_MOUSE_MODE_OBJECT[type], chartType) > -1;
  }

  public changeMouseZoomMode(mode) {
    this.chart.convertMouseMode(mode);
  }

  public showLegend(show: boolean): void {
    const legend = _.cloneDeep(this.uiOption.legend);
    legend.auto = show;
    legend.convertType = LegendConvertType.SHOW;
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { legend });
  }

  public showDataZoom(show: boolean): void {
    const chartZooms = _.cloneDeep(this.uiOption.chartZooms);
    chartZooms.forEach((zoom) => {
      zoom.auto = show;
    });

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { chartZooms });
  }

  public onNameChange(): void {
    this.isPageNameEdit = false;

    if (StringUtil.isEmpty(this.editingPageName.trim())) {
      this.alertPrimeService.info(this.translateService.instant('msg.page.alert.insert.chart.name'));
      return;
    }

    this.widget.name = this.editingPageName.trim();
  }

  public onNameEdit($event: Event): void {
    $event.stopPropagation();
    this.isPageNameEdit = !this.isPageNameEdit;
    this.editingPageName = this.widget.name;
    this.changeDetect.detectChanges();
  }

  public onNameEditCancel(): void {
    if (!this.isPageNameEdit) {
      return;
    }

    this.editingPageName = this.widget.name;

    this.isPageNameEdit = !this.isPageNameEdit;
  }

  public onChangePivotFormat(field: PivotField): void {
    if (_.eq(this.selectChart, ChartType.MAP)) {
      if (!this.mapFormatOption) {
        this.toggleRnb('mapFormat');
      }

      if (field != null && this.mapFormatOption) {
        this.mapFormatOption.setFormatType(field);
      } else if (field && !this.mapFormatOption) {
        this.alertPrimeService.warn(this.translateService.instant('msg.page.alert.apply.after.chart'));
      }
    } else {
      if (!this.formatOption) {
        this.toggleRnb('format');
      }

      if (field != null && this.formatOption) {
        this.formatOption.setFormatType(field);
      } else if (field && !this.formatOption) {
        this.alertPrimeService.warn(this.translateService.instant('msg.page.alert.apply.after.chart'));
      }
    }
  }

  public onFormatEachChange(pivot: any): void {
    if (_.eq(this.selectChart, ChartType.MAP)) {
      this.shelf = pivot;
    } else {
      this.pivot = pivot;
    }
    delete this.widgetConfiguration.format;
  }

  public onShelfFormatEachChange(shelf: Shelf): void {
    this.shelf = shelf;
    delete this.widgetConfiguration.format;
  }

  public onFormatCommonChange(format: Format): void {
    this.widgetConfiguration.format = format;
  }

  public deleteCustomField(field: DatasourceField) {
    const useChartList: string[] = [];
    const useFilterList: string[] = [];

    let chartFilters: Filter[] = [];

    const widgets = this.dashboard.widgets;

    if (widgets && widgets.length > 0) {
      let customFields: CustomField[];
      if (this.dashboard.configuration.hasOwnProperty('customFields')) {
        customFields = this.dashboard.configuration.customFields;
      } else {
        customFields = [];
      }

      widgets.forEach((widget: PageWidget) => {
        if (this.widget.id !== widget.id) {
          if (
            widget.configuration &&
            widget.configuration.hasOwnProperty('pivot') &&
            widget.configuration['pivot'].hasOwnProperty('columns')
          ) {
            const idx = _.findIndex(widget.configuration['pivot']['columns'], { name: field.name });
            if (idx > -1) useChartList.push(widget.name);
          }

          if (
            widget.configuration &&
            widget.configuration.hasOwnProperty('pivot') &&
            widget.configuration['pivot'].hasOwnProperty('aggregations')
          ) {
            const idx = _.findIndex(widget.configuration['pivot']['aggregations'], { name: field.name });
            if (idx > -1) useChartList.push(widget.name);
          }

          if (
            widget.configuration &&
            widget.configuration.hasOwnProperty('pivot') &&
            widget.configuration['pivot'].hasOwnProperty('rows')
          ) {
            const idx = _.findIndex(widget.configuration['pivot']['rows'], { name: field.name });
            if (idx > -1) useChartList.push(widget.name);
          }

          if (widget.configuration.hasOwnProperty('filters') && widget.configuration.filters.length > 0) {
            widget.configuration.filters.forEach((filter: Filter) => {
              filter.ui.widgetId = widget.id;
              chartFilters.push(filter);
            });
          }
        }
      });
    }

    if (this.widget.configuration.filters) {
      chartFilters = chartFilters.concat(this.widget.configuration.filters);
    }

    if (
      this.widget.configuration &&
      this.widget.configuration.hasOwnProperty('pivot') &&
      this.widget.configuration['pivot'].hasOwnProperty('columns')
    ) {
      const idx = _.findIndex(this.widget.configuration['pivot']['columns'], { name: field.name });
      if (idx > -1) useChartList.push(this.widget.name);
    }

    if (
      this.widget.configuration &&
      this.widget.configuration.hasOwnProperty('pivot') &&
      this.widget.configuration['pivot'].hasOwnProperty('aggregations')
    ) {
      const idx = _.findIndex(this.widget.configuration['pivot']['aggregations'], { name: field.name });
      if (idx > -1) useChartList.push(this.widget.name);
    }

    if (
      this.widget.configuration &&
      this.widget.configuration.hasOwnProperty('pivot') &&
      this.widget.configuration['pivot'].hasOwnProperty('rows')
    ) {
      const idx = _.findIndex(this.widget.configuration['pivot']['rows'], { name: field.name });
      if (idx > -1) useChartList.push(this.widget.name);
    }

    let idx = _.findIndex(chartFilters, { field: field.name });
    if (idx > -1) useFilterList.push(chartFilters[idx].type + '_' + chartFilters[idx].field);

    const globalFilters = this.dashboard.configuration.filters;
    idx = _.findIndex(globalFilters, { field: field.name });
    if (idx > -1) useFilterList.push(globalFilters[idx].type + '_' + globalFilters[idx].field);

    if (useFilterList.length > 0 || useChartList.length > 0) {
      let description = '';
      if (useFilterList.length > 0 && useChartList.length > 0) {
        description =
          "'" +
          useChartList.join("' , '") +
          "','" +
          useFilterList.join("' , '") +
          "' " +
          this.translateService.instant('msg.board.ui.use.chart.filter');
      } else if (useChartList.length > 0) {
        description = "'" + useChartList.join("' , '") + "' " + this.translateService.instant('msg.board.ui.use.chart');
      } else if (useFilterList.length > 0) {
        description =
          "'" + useFilterList.join("' , '") + "' " + this.translateService.instant('msg.board.ui.use.filter');
      }

      const modal = new Modal();
      modal.name = this.translateService.instant('msg.board.ui.not.delete.custom');
      modal.description = description;
      modal.isShowCancel = false;
      modal.data = {
        type: 'deleteCustomField',
      };
      this.confirmModalComponent.init(modal);
      return;
    }

    if (this.dashboard.configuration && !this.dashboard.configuration.customFields)
      this.dashboard.configuration.customFields = [];
    const customFields = this.dashboard.configuration.customFields;

    idx = _.findIndex(customFields, { name: field.name });

    if (idx > -1) {
      customFields.splice(idx, 1);

      this.dashboard.configuration.customFields = customFields;
      (<PageWidgetConfiguration>this.widget.configuration).customFields = customFields;

      this.setDatasourceFields();
    }
  }

  public changeForecast(): void {
    this.lineChartComponent.analysis = _.cloneDeep(this.widgetConfiguration.analysis);
    this.lineChartComponent.changeForecast();
  }

  public changeConfidence(): void {
    this.lineChartComponent.analysis = _.cloneDeep(this.widgetConfiguration.analysis);
    this.lineChartComponent.changeConfidence();
  }

  public changeAnalysisPredictionLine(): void {
    if (this.isAnalysisPredictionEnabled() === false) {
      this.drawChart();
    } else {
      this.loadingShow();

      this.analysisPredictionService
        .changeAnalysisPredictionLine(
          this.widgetConfiguration,
          this.dashboard.configuration.filters,
          this.lineChartComponent,
          null,
        )
        .then((result) => {
          this.widgetConfiguration.analysis.forecast.parameters.forEach((parameter) => {
            const resultHyperParameter: HyperParameter = result.info.analysis[`${parameter.field}.params`];
            parameter.alpha = resultHyperParameter[0];
            parameter.beta = resultHyperParameter[1];
            parameter.gamma = resultHyperParameter[2];
            return parameter;
          });
        })
        .catch((error) => {
          this.isError = true;
          this.loadingHide();
          this.commonExceptionHandler(error);
          console.error(error);
        });
    }
  }

  public lineChartDrawComplete(): void {
    this.analysisComponent.drawComplete(this.uiOption, { pivot: this.pivot });
  }

  public onSetDrawChartParam(drawChartParam) {
    this.drawChart({
      resultFormatOptions: drawChartParam.resultFormatOptions,
      type: drawChartParam.type,
    });
  }

  public drawChartTooltip(event): void {
    if (!this.isChartShow) {
      const offsetTop = event.target.offsetTop;
      $(event.target)
        .find('.ddp-ui-tooltip-info')
        .css('top', offsetTop + 106);
    }

    if (this.uiOption['analysis'] != null && this.uiOption['analysis']['use'] == true) {
      $('.ddp-wrap-chart-menu a').mouseover(function () {
        const $tooltipTop = $(this).offset().top;
        $(this)
          .find('.ddp-ui-tooltip-info')
          .css('top', $tooltipTop + 15);
      });
    }
  }

  protected updateCustomFields(data: { customField: any; isEdit: boolean }) {
    const customField: any = data.customField;
    const isEdit: boolean = data.isEdit;

    if (this.dashboard.configuration && !this.dashboard.configuration.customFields)
      this.dashboard.configuration.customFields = [];
    const customFields = this.dashboard.configuration.customFields;

    if (!isEdit) {
      customFields.push(customField);
    } else {
      customFields.forEach((field) => {
        if (field.name === customField.oriColumnName) {
          field.alias = customField.alias;
          field.name = customField.name;
          field.expr = customField.expr;
          field.aggregated = customField.aggregated;

          field.oriColumnName = customField.oriColumnName;
        }
      });

      this.widget.configuration.filters.some((filter: Filter) => {
        if (filter.field === customField.oriColumnName) {
          filter.field = customField.name;
          return true;
        }
      });

      this.dashboard.configuration.filters.some((filter: Filter) => {
        if (filter.field === customField.oriColumnName) {
          filter.field = customField.name;
          return true;
        }
      });
    }

    if (isEdit) {
      this.alertPrimeService.success(
        this.translateService.instant('msg.board.custom.ui.update', { name: customField.name }),
      );
    } else {
      this.setDatasourceFields();
      this.alertPrimeService.success(
        this.translateService.instant('msg.board.custom.ui.create', { name: customField.name }),
      );
    }

    (<PageWidgetConfiguration>this.widget.configuration).customFields = this.dashboard.configuration.customFields;

    let currentField;

    if (customField.pivot && customField.pivot['length'] > 0) {
      const setPivot = (pivot) => {
        for (let index = pivot.length; index--; ) {
          const item = pivot[index];

          if (item.name === customField.oriColumnName) {
            item.name = customField.name;
            item['aggregated'] = customField.aggregated;
            item['expr'] = customField.expr;
            item['alias'] = customField.alias;
            item['biType'] = customField.biType;

            if (customField.aggregated) {
              const duplicateList = pivot.filter((data) => {
                return customField.oriColumnName == data.name;
              });

              if (duplicateList.length > 1) {
                pivot.splice(index, 1);

                item.field.pivot.splice(item.field.pivot.indexOf(item.currentPivot), 1);
              }
              delete item['aggregationType'];
            }

            currentField = item;
          }
        }
      };

      setPivot(this.pivot.aggregations);
      setPivot(this.pivot.columns);
      setPivot(this.pivot.rows);

      const currentTarget =
        currentField['currentPivot'] === FieldPivot.AGGREGATIONS
          ? 'aggregation'
          : currentField['currentPivot'] === FieldPivot.ROWS
          ? 'row'
          : 'column';
      this.getPivotComp().convertField(customField, currentTarget, false);
    }
    this.isShowCustomFiled = false;
  }

  protected openFieldDetailLayer(event, field) {
    event.stopPropagation();

    this.fieldDetailLayer = field;

    this.showFieldIconsFl = this.dataContext.init(
      field,
      this.dashboard.configuration.dataSource,
      $(event.currentTarget),
    );
  }

  protected isContainSearchText(sText: string, targetText: string) {
    if (StringUtil.isEmpty(sText)) return true;
    return targetText.toLowerCase().includes(sText.toLowerCase());
  }

  // private updateUIOption(uiOption) {
  //   this.uiOption = _.extend({}, this.uiOption, uiOption);
  // }

  protected chartSelectInfo(data: ChartSelectInfo) {}

  protected onChangeShelf(data: Object) {
    const shelf = data['shelf'];
    const eventType = data['eventType'];
    this.shelf = shelf;

    if (this.mapLayerOption) {
      this.mapLayerOption.setShelf = shelf;
    }

    if (this.mapFormatOption) {
      this.mapFormatOption.setShelf = shelf;
    }

    if (this.mapTooltipOption) {
      this.mapTooltipOption.setShelf = shelf;
    }

    this.recommendChart();

    this.setDatasourceFields(true);

    if (this.selectChart === 'map') {
      this.analysisComponent.mapSpatialChanges(this.uiOption, this.shelf);
    }

    this.drawChart({ type: eventType });
  }

  protected onChangePivot(data: Object) {
    const pivot = data['pivot'];

    const eventType = data['eventType'];
    this.pivot = pivot;

    this.uiOption = this.setUIOptionByPivot();

    if (this.formatOption) {
      this.formatOption.setPivot = pivot;
    }

    if (this.commonOption) {
      this.commonOption.setPivot = pivot;
    }

    if (this.dataLabelOption) {
      this.dataLabelOption.setPivot = pivot;
    }

    if (this.secondaryIndicatorOption) {
      this.secondaryIndicatorOption.setPivot = pivot;
    }

    if (this.secondaryIndicatorOption) {
      this.secondaryIndicatorOption.setPivot = pivot;
    }

    const sortFields: Sort[] = _.concat(pivot.columns, pivot.rows, pivot.aggregations)
      .filter((field: PivotField) => {
        return field.direction === DIRECTION.ASC || field.direction === DIRECTION.DESC;
      })
      .map((field: PivotField) => {
        const sort: Sort = createSort();

        sort.field = field.alias ? field.alias : field.name;
        if (field.type == 'measure' && field.aggregationType && (!field.alias || field.alias == field.name)) {
          const name: string = field['alias']
            ? field['alias']
            : field['fieldAlias']
            ? field['fieldAlias']
            : field['name'];
          sort.field = field.aggregationType + `(${name})`;
        }
        sort.direction = field.direction;
        sort.lastDirection = field.lastDirection;
        return sort;
      });

    if (this.chart instanceof GridChartComponent) {
      this.sorts = sortFields;
    } else {
      if (sortFields.length > 0) {
        const sortList: Sort[] = [];

        if (this.sorts) {
          for (const beforeField of this.sorts) {
            let isUse = false;
            for (const afterField of sortFields) {
              if (afterField.field == beforeField.field && !afterField.lastDirection) {
                isUse = true;
                break;
              }
            }
            if (isUse) {
              sortList.push(beforeField);
            }
          }
        }

        for (const afterField of sortFields) {
          if (afterField.lastDirection) {
            delete afterField.lastDirection;
            sortList.unshift(afterField);
            break;
          }
        }

        this.sorts = sortList;
      } else {
        this.sorts = [];
      }
    }

    this.recommendChart();

    this.drawChart({ type: eventType });
  }

  public isNewWidget() {
    return StringUtil.isEmpty(this.widget.id);
  }

  public openUpdateFilterPopup(filter?: Filter) {
    this._configFilterComp.open(this.dashboard, this.widget.configuration.filters, filter, this.widget);
  }

  public closeFilterPopup() {
    this._configFilterComp.close();
  }

  public updateFilter(filter: Filter, isSetPanel: boolean = false) {
    if (filter.ui.widgetId) {
      this._setChartFilter(filter, isSetPanel);
    }
    this.drawChart({ type: EventType.FILTER });
    this.closeFilterPopup();
  }

  public configureFilter(filter: Filter) {
    this.updateFilter(filter, true);
  }

  public deleteFilter(filter: Filter) {
    if (filter.ui.widgetId) {
      const idx = _.findIndex(this.widgetConfiguration.filters, { field: filter.field });
      if (idx < 0) {
        return;
      } else {
        this.widgetConfiguration.filters.splice(idx, 1);
      }

      this._setUseFilter();
      this.drawChart();
    } else {
      const idx = _.findIndex(this.dashboard.configuration.filters, { field: filter.field });
      if (idx < 0) {
        return;
      } else {
        this.dashboard.configuration.filters.splice(idx, 1);
      }

      this._setUseFilter();
      this.drawChart();
    }

    if (this._filterPanelComp) {
      this._filterPanelComp.setFilters(this.dashboard.configuration.filters, this.widget.configuration.filters);
    }
  }

  public isCustomMeasureField(field: DatasourceField) {
    return FieldRole.MEASURE === field.role && 'user_expr' === field.type;
  }

  public toggleFilter(field: DatasourceField, $event?: MouseEvent) {
    $event && $event.stopPropagation();

    if (this.isCustomMeasureField(field)) {
      return;
    }

    if (field.aggregated) {
      this.alertPrimeService.info(this.translateService.instant('msg.page.custom.measure.aggregation.unavailable'));
      return;
    }

    let selectedField: DatasourceField;

    if (field['field']) selectedField = field['field'];
    else selectedField = field;

    this.rnbMenu = 'filter';
    if (selectedField.useFilter) {
      const globalFilters = this.dashboard.configuration.filters;
      let idx = _.findIndex(globalFilters, { field: selectedField.name });
      if (-1 < idx) {
        this.alertPrimeService.warn(this.translateService.instant('msg.board.alert.global-filter.del.error'));
        return;
      }

      if (selectedField.filtering) {
        this.alertPrimeService.warn(this.translateService.instant('msg.board.alert.recomm-filter.del.error'));
        return;
      }

      selectedField.useFilter = false;

      const chartFilters = this.widgetConfiguration.filters;
      idx = _.findIndex(chartFilters, { field: selectedField.name });
      if (idx > -1) this.deleteFilter(chartFilters[idx]);

      this.getPivotComp().setWidgetConfig = this.widgetConfiguration;

      return;
    } else {
      selectedField.useFilter = true;

      let newFilter: Filter;
      if (selectedField.logicalType === LogicalType.TIMESTAMP) {
        const timeFilter = FilterUtil.getTimeRangeFilter(selectedField, undefined, undefined, this.dataSource);

        timeFilter.ui.widgetId = this.widget.id;
        if (this.isNewWidget()) {
          timeFilter.ui.widgetId = 'NEW';
        }

        newFilter = timeFilter;
      } else if (selectedField.role === FieldRole.MEASURE) {
        const boundFilter = FilterUtil.getBasicBoundFilter(selectedField);

        boundFilter.ui.widgetId = this.widget.id;
        if (this.isNewWidget()) {
          boundFilter.ui.widgetId = 'NEW';
        }

        if (selectedField.type === 'user_expr') {
          boundFilter.ref = 'user_defined';
        }

        newFilter = boundFilter;
      } else {
        const inclusionFilter = FilterUtil.getBasicInclusionFilter(selectedField);

        inclusionFilter.ui.widgetId = this.widget.id;
        if (this.isNewWidget()) {
          inclusionFilter.ui.widgetId = 'NEW';
        }

        if (selectedField.type === 'user_expr') {
          inclusionFilter.ref = 'user_defined';
        }

        newFilter = inclusionFilter;
      }
      newFilter.dataSource = this.dataSource.engineName;

      this.updateFilter(newFilter, true);
    }
  }

  public openConfirmPopup(filter: Filter, type: string) {
    if ('toChartFilter' === type) {
      const modal = new Modal();
      modal.name = this.translateService.instant('msg.board.filter.alert.change.chart');
      modal.description = this.translateService.instant('msg.board.filter.alert.change.chart.des');
      modal.data = {
        afterConfirm: () => {
          this._setChartFilter(filter);
        },
      };
      this.confirmModalComponent.init(modal);
    }
  }

  public confirm(modal: Modal) {
    if (modal.data.afterConfirm) {
      modal.data.afterConfirm.call(this);
    } else if (modal.data.eventType === EventType.GRID_ORIGINAL) {
      this.commonOption.changeGridViewType(modal.data.data);
    } else if (modal.data.eventType == EventType.SERIES_VIEW) {
      this.commonOption.changeBarSeriesViewType(modal.data.data);
    } else if (modal.data.eventType == EventType.GRANULARITY) {
      this.getPivotComp().onSetGranularity(modal.data.data.discontinuous, modal.data.data.unit, modal.data.data.byUnit);
    } else if (modal.data.eventType == EventType.CUMULATIVE) {
      this.commonOption.changeCumulative(modal.data.data);
    }
  }

  public changeAxisByStack(type: BarMarkType) {
    let checkDimensionExist = false;

    const allRowAggregations = this.pivot.rows.concat(this.pivot.aggregations);

    for (const item of allRowAggregations) {
      if (item.type === String(ShelveFieldType.DIMENSION)) {
        checkDimensionExist = true;
      }
    }

    if (checkDimensionExist) {
      if (String(BarMarkType.STACKED) === String(type)) {
        for (let num = this.pivot.aggregations.length; num--; ) {
          const item = this.pivot.aggregations[num];

          if (item.type === String(ShelveFieldType.DIMENSION)) {
            this.pivot.aggregations.splice(num, 1);

            item.currentPivot = FieldPivot.ROWS;

            this.pivot.rows.push(item);
          }
        }
      } else {
        for (let num = this.pivot.rows.length; num--; ) {
          const item = this.pivot.rows[num];

          if (item.type === String(ShelveFieldType.DIMENSION)) {
            this.pivot.rows.splice(num, 1);

            item.currentPivot = FieldPivot.AGGREGATIONS;

            this.pivot.aggregations.push(item);
          }
        }
      }
    }
  }

  public isAvaliableGrid() {
    const notAvaliableChart = ['grid', 'scatter', 'pie'];
    return notAvaliableChart.indexOf(this.selectChart) === -1;
  }

  public initGridChart() {
    try {
      if (this.gridChart && this.gridChart.isLoaded) {
        this.gridChart.resultData = this.resultData;
      }
    } catch (err) {
      console.error(err);
    }
  }

  // private gridUiOptionUpdatedHandler(uiOption) {
  //   this.gridUiOption = _.extend({}, this.gridUiOption, uiOption);
  // }

  public onModeChange(isChartView: boolean): void {
    if (this.isChartView != isChartView) {
      this.isChartView = isChartView;

      if (!this.isChartView) {
        this.initGridChart();
      }

      this.chartResize(true);
    }
  }

  public unescapeCustomColumnExpr(expr: string) {
    return StringUtil.unescapeCustomColumnExpr(expr);
  }

  public onChangePivotItem(data: Object) {
    const item: PivotField = data['data'];
    const measureTargetList: PivotField[] = data['list'];
    const addTargetType: FieldPivot = data['addType'];
    const deleteTargetType: FieldPivot = data['deleteType'];

    if (String(ShelveFieldType.DIMENSION) === item.type || String(ShelveFieldType.TIMESTAMP) === item.type) {
      item.field.pivot.splice(item.field.pivot.indexOf(deleteTargetType), 1);
      item.field.pivot.push(addTargetType);
    } else if (String(ShelveFieldType.MEASURE) === item.type) {
      const existIndex = _.findIndex(measureTargetList, (aggItem) => item.name === aggItem.name);

      if (-1 !== existIndex) {
        const field = item.field;

        const measureIndex = _.findIndex(this.measures, (measureItem) => {
          return (
            field.alias === measureItem.alias && field.name === measureItem.name && field.type === measureItem.type
          );
        });

        item.field.pivot.splice(item.field.pivot.indexOf(deleteTargetType), 1);

        if (-1 !== measureIndex && addTargetType) {
          this.measures[measureIndex].pivot.push(addTargetType);
        }
      } else {
        item.field.pivot.splice(item.field.pivot.indexOf(deleteTargetType), 1);
      }
    }
  }

  public onDeletePivotItem(data: Object) {
    const item: PivotField = data['data'];
    const addType: FieldPivot = data['addType'];
    const deleteType: FieldPivot = data['deleteType'];

    if (String(ShelveFieldType.DIMENSION) === item.type || String(ShelveFieldType.TIMESTAMP) === item.type) {
      delete item.field.pivot;
    } else if (String(ShelveFieldType.MEASURE) === item.type) {
      if (deleteType) item.field.pivot.splice(item.field.pivot.indexOf(deleteType), 1);

      if (addType) item.field.pivot.push(addType);
    }
  }

  public clickDataPanel(dataLayerKey: string) {
    if (JSON.stringify(this.dataLayerKey) === JSON.stringify(dataLayerKey)) {
      this.dataLayerKey = '';
    } else {
      this.dataLayerKey = dataLayerKey;
    }

    this.dataPanelInnerScroll();
  }

  public possibleChartCheck(type: string, chartType: string): boolean {
    if ('map' === chartType && -1 !== type.indexOf('mapLayer')) {
      return _.indexOf(POSSIBLE_CHART_OBJECT[type], chartType) > -1 && !this.setDisableMapLayer();
    }

    return _.indexOf(POSSIBLE_CHART_OBJECT[type], chartType) > -1;
  }

  public getCntShelfItem(type: 'DIMENSION' | 'MEASURE'): number {
    let cntShelfItems = 0;
    const strType: string = type.toLowerCase();
    if (ChartType.MAP === this.widgetConfiguration.chart.type) {
      this.shelf.layers.forEach((layer) => {
        cntShelfItems =
          cntShelfItems +
          layer.fields.filter((field) => {
            return strType === field.type && field.field.dataSource === this.dataSource.engineName;
          }).length;
      });
    } else {
      cntShelfItems =
        cntShelfItems +
        this.pivot.rows.filter((row) => {
          return strType === row.type && row.field.dataSource === this.dataSource.engineName;
        }).length;
      cntShelfItems =
        cntShelfItems +
        this.pivot.columns.filter((col) => {
          return strType === col.type && col.field.dataSource === this.dataSource.engineName;
        }).length;
      cntShelfItems =
        cntShelfItems +
        this.pivot.aggregations.filter((aggr) => {
          return strType === aggr.type && aggr.field.dataSource === this.dataSource.engineName;
        }).length;
    }
    return cntShelfItems;
  }

  public onNoData(): void {
    this.isNoData = true;
    this.changeDetect.detectChanges();
  }

  public onShowGuide(): void {
    this.isChartShow = false;
    this.changeDetect.detectChanges();
  }

  public onChangePivotData(data: any): void {
    const shelveTypeList: FieldPivot[] = data['shelveTypeList'];

    const shelveFieldTypeList: string[] = data['shelveFieldTypeList'];

    for (const type of shelveTypeList) {
      this.pivot[type].forEach((item) => {
        item.field.pivot = [];
      });
      this.pivot[type] = [];
    }
  }

  public getPivotComp(): PagePivotComponent {
    if (_.eq(this.selectChart, 'map')) {
      return this.mapPivot;
    } else {
      return this.pagePivot;
    }
  }

  public onPivotSelect(targetField: DatasourceField, isDimension: boolean): void {
    const pivotFiled: any = {
      name: targetField.name,
      alias: targetField.alias,
      role: targetField.role,
      type: targetField.type,
    };

    let isAlreadyPivot = false;
    let alreadyFieldPivot: FieldPivot;
    let alreadyPivot: PivotField[];
    let alreadyIndex: number;

    if (_.eq(this.selectChart, ChartType.MAP)) {
      this._setDataSourceCurrentLayer(this.dataSource);

      const layerNum = (<UIMapOption>this.uiOption).layerNum;
      const currentMapLayer = this.shelf.layers[layerNum].fields;

      if (
        !isNullOrUndefined(currentMapLayer) &&
        !isNullOrUndefined(currentMapLayer[0]) &&
        !isNullOrUndefined(currentMapLayer[0]['field']) &&
        targetField.dataSource != currentMapLayer[0].field.dataSource
      ) {
        this.alertPrimeService.warn(this.translateService.instant('msg.page.layer.multi.datasource.same.shelf'));
        return;
      }

      let fieldPivot: FieldPivot;

      if ('MAP_LAYER' + layerNum === FieldPivot.MAP_LAYER0.toString()) {
        fieldPivot = FieldPivot.MAP_LAYER0;
      } else if ('MAP_LAYER' + layerNum === FieldPivot.MAP_LAYER1.toString()) {
        fieldPivot = FieldPivot.MAP_LAYER1;
      } else if ('MAP_LAYER' + layerNum === FieldPivot.MAP_LAYER2.toString()) {
        fieldPivot = FieldPivot.MAP_LAYER2;
      }

      for (let num = 0; num < currentMapLayer.length; num++) {
        const field: PivotField = currentMapLayer[num];
        if (field.name == targetField.name) {
          isAlreadyPivot = true;
          alreadyFieldPivot = fieldPivot;
          alreadyPivot = currentMapLayer;
          alreadyIndex = num;
          break;
        }
      }

      if (isDimension) {
        if (!isAlreadyPivot) {
          this.shelf.layers[layerNum].fields.push(pivotFiled);
          this.mapPivot.convertField(targetField, 'layer' + layerNum);
        } else {
          this.mapPivot.removeField(null, alreadyFieldPivot, alreadyPivot, alreadyIndex);
        }
      } else {
        if ('user_expr' == targetField.type && targetField.aggregated && isAlreadyPivot) {
          this.mapPivot.removeField(null, alreadyFieldPivot, alreadyPivot, alreadyIndex);
        } else if (
          isAlreadyPivot &&
          MapLayerType.TILE !== (<UIMapOption>this.uiOption).layers[(<UIMapOption>this.uiOption).layerNum].type
        ) {
          this.mapPivot.removeField(null, alreadyFieldPivot, alreadyPivot, alreadyIndex);
        } else {
          this.shelf.layers[layerNum].fields.push(pivotFiled);
          this.mapPivot.convertField(targetField, 'layer' + layerNum);
        }
      }
      return;
    }

    if (
      !_.eq(this.selectChart, ChartType.MAP) &&
      !_.eq(this.selectChart, '') &&
      targetField.logicalType &&
      targetField.logicalType.toString().indexOf('GEO') != -1
    ) {
      this.alertPrimeService.warn(this.translateService.instant('msg.board.ui.invalid-column'));
      return;
    }

    for (let num = 0; num < this.pivot.columns.length; num++) {
      const field: PivotField = this.pivot.columns[num];
      if (field.name == targetField.name) {
        isAlreadyPivot = true;
        alreadyFieldPivot = FieldPivot.COLUMNS;
        alreadyPivot = this.pivot.columns;
        alreadyIndex = num;
        break;
      }
    }
    for (let num = 0; num < this.pivot.rows.length; num++) {
      const field: PivotField = this.pivot.rows[num];
      if (field.name == targetField.name) {
        isAlreadyPivot = true;
        alreadyFieldPivot = FieldPivot.ROWS;
        alreadyPivot = this.pivot.rows;
        alreadyIndex = num;
        break;
      }
    }
    for (let num = 0; num < this.pivot.aggregations.length; num++) {
      const field: PivotField = this.pivot.aggregations[num];
      if (field.name == targetField.name) {
        isAlreadyPivot = true;
        alreadyFieldPivot = FieldPivot.AGGREGATIONS;
        alreadyPivot = this.pivot.aggregations;
        alreadyIndex = num;
        break;
      }
    }

    if (isDimension) {
      if (
        targetField.logicalType &&
        targetField.logicalType.toString().indexOf('GEO') != -1 &&
        !_.eq(this.selectChart, '')
      ) {
        this.alertPrimeService.warn(this.translateService.instant('msg.board.ui.invalid-pivot'));
        return;
      }

      if (
        _.eq(this.selectChart, ChartType.BAR) ||
        _.eq(this.selectChart, ChartType.LINE) ||
        _.eq(this.selectChart, ChartType.HEATMAP) ||
        _.eq(this.selectChart, ChartType.CONTROL) ||
        _.eq(this.selectChart, ChartType.COMBINE) ||
        _.eq(this.selectChart, ChartType.WATERFALL) ||
        _.eq(this.selectChart, ChartType.SANKEY) ||
        _.eq(this.selectChart, ChartType.GRID) ||
        _.eq(this.selectChart, '')
      ) {
        if (!isAlreadyPivot) {
          this.pivot.columns.push(pivotFiled);
          this.pagePivot.convertField(targetField, 'column');
        } else {
          this.getPivotComp().removeField(null, alreadyFieldPivot, alreadyPivot, alreadyIndex);
        }
      } else if (_.eq(this.selectChart, ChartType.GAUGE)) {
        if (!isAlreadyPivot) {
          this.pivot.rows.push(pivotFiled);
          this.pagePivot.convertField(targetField, 'row');
        } else {
          this.getPivotComp().removeField(null, alreadyFieldPivot, alreadyPivot, alreadyIndex);
        }
      } else if (
        _.eq(this.selectChart, ChartType.SCATTER) ||
        _.eq(this.selectChart, ChartType.PIE) ||
        _.eq(this.selectChart, ChartType.WORDCLOUD) ||
        _.eq(this.selectChart, ChartType.RADAR)
      ) {
        if (!isAlreadyPivot) {
          this.pivot.aggregations.push(pivotFiled);
          this.pagePivot.convertField(targetField, 'aggregation');
        } else {
          this.getPivotComp().removeField(null, alreadyFieldPivot, alreadyPivot, alreadyIndex);
        }
      } else if (_.eq(this.selectChart, ChartType.BOXPLOT) || _.eq(this.selectChart, ChartType.GRAPH)) {
        if (!isAlreadyPivot) {
          let columnCount = 0;
          for (let num = 0; num < this.pivot.columns.length; num++) {
            columnCount++;
          }

          let rowCount = 0;
          for (let num = 0; num < this.pivot.rows.length; num++) {
            rowCount++;
          }

          if (columnCount == 0) {
            this.pivot.columns.push(pivotFiled);
            this.pagePivot.convertField(targetField, 'column');
          } else if (rowCount == 0) {
            this.pivot.rows.push(pivotFiled);
            this.pagePivot.convertField(targetField, 'row');
          } else {
            this.pivot.columns.push(pivotFiled);
            this.pagePivot.convertField(targetField, 'column');
          }
        } else {
          this.getPivotComp().removeField(null, alreadyFieldPivot, alreadyPivot, alreadyIndex);
        }
      } else if (_.eq(this.selectChart, ChartType.TREEMAP)) {
        if (!isAlreadyPivot) {
          let columnCount = 0;
          for (let num = 0; num < this.pivot.columns.length; num++) {
            columnCount++;
          }

          let rowCount = 0;
          for (let num = 0; num < this.pivot.rows.length; num++) {
            rowCount++;
          }

          if (columnCount == 0) {
            this.pivot.columns.push(pivotFiled);
            this.pagePivot.convertField(targetField, 'column');
          } else if (rowCount == 0) {
            this.pivot.rows.push(pivotFiled);
            this.pagePivot.convertField(targetField, 'row');
          } else {
            this.pivot.rows.push(pivotFiled);
            this.pagePivot.convertField(targetField, 'row');
          }
        } else {
          this.getPivotComp().removeField(null, alreadyFieldPivot, alreadyPivot, alreadyIndex);
        }
      }
    } else {
      if (
        _.eq(this.selectChart, ChartType.BAR) ||
        _.eq(this.selectChart, ChartType.LINE) ||
        _.eq(this.selectChart, ChartType.HEATMAP) ||
        _.eq(this.selectChart, ChartType.PIE) ||
        _.eq(this.selectChart, ChartType.CONTROL) ||
        _.eq(this.selectChart, ChartType.LABEL) ||
        _.eq(this.selectChart, ChartType.BOXPLOT) ||
        _.eq(this.selectChart, ChartType.WATERFALL) ||
        _.eq(this.selectChart, ChartType.WORDCLOUD) ||
        _.eq(this.selectChart, ChartType.COMBINE) ||
        _.eq(this.selectChart, ChartType.TREEMAP) ||
        _.eq(this.selectChart, ChartType.RADAR) ||
        _.eq(this.selectChart, ChartType.GRAPH) ||
        _.eq(this.selectChart, ChartType.SANKEY) ||
        _.eq(this.selectChart, ChartType.GAUGE) ||
        _.eq(this.selectChart, ChartType.GRID) ||
        _.eq(this.selectChart, ChartType.MAP) ||
        _.eq(this.selectChart, '')
      ) {
        if ('user_expr' == targetField.type && targetField.aggregated && isAlreadyPivot) {
          this.getPivotComp().removeField(null, alreadyFieldPivot, alreadyPivot, alreadyIndex);
        } else {
          this.pivot.aggregations.push(pivotFiled);
          this.pagePivot.convertField(targetField, 'aggregation');
        }
      } else if (_.eq(this.selectChart, ChartType.SCATTER)) {
        if (!isAlreadyPivot) {
          let columnCount = 0;
          for (let num = 0; num < this.pivot.columns.length; num++) {
            columnCount++;
          }

          let rowCount = 0;
          for (let num = 0; num < this.pivot.rows.length; num++) {
            rowCount++;
          }

          if (columnCount == 0) {
            this.pivot.columns.push(pivotFiled);
            this.pagePivot.convertField(targetField, 'column');
          } else if (rowCount == 0) {
            this.pivot.rows.push(pivotFiled);
            this.pagePivot.convertField(targetField, 'row');
          }
        } else {
          this.getPivotComp().removeField(null, alreadyFieldPivot, alreadyPivot, alreadyIndex);
        }
      }
    }
  }

  public fieldPrev(isDimension: boolean): void {
    if (isDimension) {
      if (this.dimensionPage <= 1) {
        return;
      }

      this.dimensionPage--;

      this.pageDimensions = [];

      const list: DatasourceField[] = this.getFieldSearchList(this.dimensions);

      const start: number = (this.dimensionPage - 1) * this.MAX_PAGE_COUNT;
      let end: number = Math.floor(this.dimensionPage * this.MAX_PAGE_COUNT);
      end = end > list.length ? list.length : end;
      for (let num: number = start; num < end; num++) {
        this.pageDimensions.push(list[num]);
      }
    } else {
      if (this.measurePage <= 1) {
        return;
      }

      this.measurePage--;

      this.pageMeasures = [];

      const list: DatasourceField[] = this.getFieldSearchList(this.measures);

      const start: number = (this.measurePage - 1) * this.MAX_PAGE_COUNT;
      let end: number = Math.floor(this.measurePage * this.MAX_PAGE_COUNT);
      end = end > list.length ? list.length : end;
      for (let num: number = start; num < end; num++) {
        this.pageMeasures.push(list[num]);
      }
    }
  }

  public fieldNext(isDimension: boolean): void {
    if (isDimension) {
      if (this.dimensionTotalPage <= this.dimensionPage) {
        return;
      }

      this.dimensionPage++;

      this.pageDimensions = [];

      const list: DatasourceField[] = this.getFieldSearchList(this.dimensions);

      const start: number = (this.dimensionPage - 1) * this.MAX_PAGE_COUNT;
      let end: number = Math.floor(this.dimensionPage * this.MAX_PAGE_COUNT);
      end = end > list.length ? list.length : end;
      for (let num: number = start; num < end; num++) {
        this.pageDimensions.push(list[num]);
      }
    } else {
      if (this.measureTotalPage <= this.measurePage) {
        return;
      }

      this.measurePage++;

      this.pageMeasures = [];

      const list: DatasourceField[] = this.getFieldSearchList(this.measures);

      const start: number = (this.measurePage - 1) * this.MAX_PAGE_COUNT;
      let end: number = Math.floor(this.measurePage * this.MAX_PAGE_COUNT);
      end = end > list.length ? list.length : end;
      for (let num: number = start; num < end; num++) {
        this.pageMeasures.push(list[num]);
      }
    }
  }

  public getFieldSearchList(list: DatasourceField[]): DatasourceField[] {
    const result: DatasourceField[] = [];

    if (StringUtil.isEmpty(this.fieldSearchText)) {
      return list;
    }

    for (const item of list) {
      if (item.name.toLowerCase().includes(this.fieldSearchText.toLowerCase())) {
        result.push(item);
      }
    }

    return result;
  }

  public setFieldTotalPage(page: number = 1): void {
    this.pageDimensions = [];
    this.pageMeasures = [];
    this.dimensionPage = page;
    this.measurePage = page;

    const dimensionList: DatasourceField[] = this.getFieldSearchList(this.dimensions);
    const measureList: DatasourceField[] = this.getFieldSearchList(this.measures);

    this.dimensionTotalPage =
      Math.floor(dimensionList.length / this.MAX_PAGE_COUNT) +
      (dimensionList.length % this.MAX_PAGE_COUNT == 0 ? 0 : 1);

    this.measureTotalPage =
      Math.floor(measureList.length / this.MAX_PAGE_COUNT) + (measureList.length % this.MAX_PAGE_COUNT == 0 ? 0 : 1);

    this.dimensionPage = 0;
    this.measurePage = 0;
    this.fieldNext(true);
    this.fieldNext(false);
  }

  public getChartTypeTransLate(selectChart): string {
    switch (selectChart) {
      case ChartType.BAR:
        return this.translateService.instant('msg.page.ui.bar');
      case ChartType.GRID:
        return this.translateService.instant('msg.page.ui.text-table');
      case ChartType.LINE:
        return this.translateService.instant('msg.page.ui.line');
      case ChartType.SCATTER:
        return this.translateService.instant('msg.page.ui.scatter');
      case ChartType.HEATMAP:
        return this.translateService.instant('msg.page.ui.heat-map');
      case ChartType.PIE:
        return this.translateService.instant('msg.page.ui.pie');
      case ChartType.LABEL:
        return this.translateService.instant('msg.page.ui.kpi');
      case ChartType.BOXPLOT:
        return this.translateService.instant('msg.page.ui.box-plot');
      case ChartType.WATERFALL:
        return this.translateService.instant('msg.page.ui.water-fall');
      case ChartType.WORDCLOUD:
        return this.translateService.instant('msg.page.ui.word-cloud');
      case ChartType.COMBINE:
        return this.translateService.instant('msg.page.ui.combine-chart');
      case ChartType.TREEMAP:
        return this.translateService.instant('msg.page.ui.tree-map');
      case ChartType.RADAR:
        return this.translateService.instant('msg.page.ui.radar-chart');
      case ChartType.GRAPH:
        return this.translateService.instant('msg.page.ui.network');
      case ChartType.SANKEY:
        return this.translateService.instant('msg.page.ui.sankey');
      case ChartType.GAUGE:
        return this.translateService.instant('msg.page.ui.gauge-chart');
    }
  }

  public onShowPopup(modalData: Modal) {
    this.confirmModalComponent.init(modalData);
  }

  public changeDatasourceFieldAlias(changeField: DatasourceField) {
    this.dashboard.configuration.fields.some((field: DatasourceField) => {
      if (field.name === changeField.name) {
        field = changeField;

        if (ChartType.MAP !== this.widgetConfiguration.chart.type) {
          PageViewComponent.updatePivotAliasFromField(this.widgetConfiguration.pivot, field);
        } else {
          PageViewComponent.updateShelfAliasFromField(
            this.widgetConfiguration.shelf,
            field,
            (<UIMapOption>this.widgetConfiguration.chart).layerNum,
          );
        }
        return true;
      }
    });
    this.dashboard.configuration.fields.some((field: DatasourceField) => {
      if (field.name === changeField.name) {
        field = changeField;
        if (ChartType.MAP !== this.widgetConfiguration.chart.type) {
          PageViewComponent.updatePivotAliasFromField(this.widgetConfiguration.pivot, field);
        } else {
          PageViewComponent.updateShelfAliasFromField(
            this.widgetConfiguration.shelf,
            field,
            (<UIMapOption>this.widgetConfiguration.chart).layerNum,
          );
        }
        return true;
      }
    });
    changeField = _.cloneDeep(changeField);
    delete changeField.pivot;
    this.changeFieldAliasEvent.emit(changeField);
    this.setDatasourceFields(true);

    if (_.eq(this.selectChart, ChartType.MAP)) {
      this.onChangeShelf({
        shelf: this.shelf,
        eventType: EventType.DASHBOARD_ALIAS,
      });
    } else {
      this.onChangePivot({
        pivot: this.pivot,
        eventType: EventType.DASHBOARD_ALIAS,
      });
    }
  }

  public setDisableShelf(layerNum: number): boolean {
    let valid = true;

    if ('map' == this.selectChart && this.uiOption['analysis'] != null && this.uiOption['analysis']['use'] == true) {
      layerNum == this.shelf.layers.length - 1 ? (valid = false) : (valid = true);
      return valid;
    }

    if (_.isUndefined(this.shelf.layers[layerNum])) {
      return valid;
    }

    const layers = this.shelf.layers[layerNum].fields;
    if (layers) {
      for (const layer of layers) {
        if (layer.field && layer.field.logicalType && -1 !== layer.field.logicalType.toString().indexOf('GEO')) {
          valid = false;
        }
      }
    }

    return valid;
  }

  public setDisableMapLayer(): boolean {
    let index: number;
    this.rnbMenu.indexOf('1') != -1 ? (index = 0) : (index = Number(this.rnbMenu.split('mapLayer')[1]) - 1);

    return this.setDisableShelf(index);
  }

  public onSetZIndex(value: any) {
    this.panelZIndex = value;
  }

  public changeDraw(value?: any) {
    if (!isNullOrUndefined(value) && value.action.toString().toLowerCase().indexOf('analysis') != -1) {
      if (value.action.toString().toLowerCase().indexOf('remove') != -1) {
        this.mapPivot.removeAnalysis();
        this.drawChart();
      } else {
        if (value.action == 'reAnalysis') {
          this.shelf.layers.pop();
          value.uiOption.layers.pop();
        }

        this.mapPivot.spatialAnalysisBtnClicked(value.uiOption);
        this.changeDetect.detectChanges();
        this.onChangeShelf({
          shelf: this.shelf,
          eventType: value.action == 'analysis' ? EventType.MAP_SPATIAL_ANALYSIS : EventType.MAP_SPATIAL_REANALYSIS,
        });
      }
    } else {
      this.drawChart();
    }
  }

  private settingDragAndDrop() {
    const acceptsContainer = [
      'column',
      'row',
      'aggregation',
      'column-guide',
      'row-guide',
      'aggregation-guide',
      'layer0',
      'layer1',
      'layer2',
      'layer-guide',
    ];

    function copy(el) {
      return ['dimension', 'measure'].indexOf(el['dataset']['source']) > -1;
    }

    function accepts(el, target, source, sibling) {
      return acceptsContainer.indexOf(target.dataset.container) > -1;
    }

    function moves(el, source) {
      return !el.classList.contains('dragIgnore');
    }

    this.dragulaService.setOptions('dragbag', {
      copy,
      accepts,
      moves,

      direction: 'horizontal',
      revertOnSpill: true,
    });

    const dragulaDragSubs = this.dragulaService.drag.subscribe((value) => {
      if (_.eq(this.selectChart, ChartType.MAP)) {
        this._setDataSourceCurrentLayer(this.dataSource);
      }
    });

    const dragulaDropSubs = this.dragulaService.drop.subscribe((value) => {});

    const dragulaOverSubs = this.dragulaService.over.subscribe((value) => {});

    const dragulaOutSubs = this.dragulaService.out.subscribe((value) => {});

    const dragulaDropModelSubs = this.dragulaService.dropModel.subscribe((value) => {
      this.dragulaService.find('dragbag').drake.cancel(true);

      const [el, target, source] = value.slice(1);

      if (undefined === target) {
        return;
      }

      const info = {
        name: el.dataset.name,
        source: el.dataset.source,
        target: target.dataset.container,
      };

      let targetField;
      if (info.source === 'dimension') {
        targetField = this.dimensions.find((field) => {
          return info.name === field.name;
        });
      } else if (info.source === 'measure') {
        targetField = this.measures.find((field) => {
          return info.name === field.name;
        });
      }

      if (acceptsContainer.indexOf(info.target) > -1) {
        if (info.target.includes('guide') && targetField) {
          this.getPivotComp().addField(targetField, info.target.replace(/-.*$/, ''), this.getPivotComp().dragField);
        } else {
          if (targetField) {
            if (
              !_.eq(this.selectChart, ChartType.MAP) &&
              !_.eq(this.selectChart, '') &&
              targetField.logicalType &&
              targetField.logicalType.toString().indexOf('GEO') != -1
            ) {
              if (info.target === 'column') {
                this.invalidGeoData(this.pivot.columns);
              } else if (info.target === 'row') {
                this.invalidGeoData(this.pivot.rows);
              } else if (info.target === 'aggregation') {
                this.invalidGeoData(this.pivot.aggregations);
              }
              this.alertPrimeService.warn(this.translateService.instant('msg.board.ui.invalid-column'));
              return;
            }
            this.getPivotComp().convertField(targetField, info.target);
          } else if (info.target) {
            targetField = _.concat(this.dimensions, this.measures).find((field) => {
              return this.getPivotComp().dragField.name === field.name;
            });

            this.getPivotComp().changeFieldPivot(targetField, info.target, this.getPivotComp().dragField);
          }
        }
      }
    });

    const dragulaRemoveModelSubs = this.dragulaService.removeModel.subscribe((value) => {});

    this.subscriptions.push(
      dragulaDragSubs,
      dragulaDropSubs,
      dragulaOverSubs,
      dragulaOutSubs,
      dragulaDropModelSubs,
      dragulaRemoveModelSubs,
    );
  }

  private setDatasourceFields(fieldPivotSetFl?: boolean) {
    this.dimensions = [];
    this.measures = [];

    const boardConf: BoardConfiguration = this.dashboard.configuration;
    let totalFields: DatasourceField[] = boardConf.fields;

    if (totalFields && totalFields.length > 0) {
      totalFields = DashboardUtil.getFieldsForMainDataSource(boardConf, this.dataSource.engineName);
      totalFields.forEach((field) => {
        if (field.role === FieldRole.MEASURE) {
          this.measures.push(field);
        } else if (field.role === FieldRole.DIMENSION || field.role === FieldRole.TIMESTAMP) {
          this.dimensions.push(field);
        } else {
          console.error(field);
        }
      });
      this.fields = totalFields;
    } else {
      this.fields = [];
    }

    this.customDimensions = [];
    this.customMeasures = [];
    if ('customFields' in boardConf && boardConf.customFields && boardConf.customFields.length > 0) {
      boardConf.customFields
        .filter((item) => item.dataSource === this.widget.configuration.dataSource.engineName)
        .forEach((field: CustomField) => {
          if (field.role === FieldRole.DIMENSION) {
            this.customDimensions.push(field);

            const dimension: DatasourceField = createDatasourceField({
              type: field.type,
              role: field.role,
              name: field.name,
              alias: field.name,
              expr: field.expr,
              ref: field.ref,
            });

            this.dimensions.push(dimension);
          } else if (field.role === FieldRole.MEASURE) {
            this.customMeasures.push(field);

            const measure: DatasourceField = createDatasourceField({
              type: field.type,
              role: field.role,
              name: field.name,
              alias: field.name,
              expr: field.expr,
              ref: field.ref,
              aggregated: field.aggregated,
            });
            this.measures.push(measure);
          }
        });
    }

    this.setFieldTotalPage();

    if (this.widgetConfiguration.pivot && fieldPivotSetFl) {
      _.concat(this.dimensions, this.measures).forEach((field) => {
        field.pivot = [];

        this.widgetConfiguration.pivot.rows.forEach((abstractField) => {
          if (String(field.type) == abstractField.type.toUpperCase() && field.name == abstractField.name) {
            abstractField.field = field;
            field.pivot = field.pivot ? field.pivot : [];
            field.pivot.push(FieldPivot.ROWS);
          }
        });

        this.widgetConfiguration.pivot.columns.forEach((abstractField) => {
          if (String(field.type) == abstractField.type.toUpperCase() && field.name == abstractField.name) {
            abstractField.field = field;
            field.pivot = field.pivot ? field.pivot : [];
            field.pivot.push(FieldPivot.COLUMNS);
          }
        });

        this.widgetConfiguration.pivot.aggregations.forEach((abstractField) => {
          if (String(field.type) == abstractField.type.toUpperCase() && field.name == abstractField.name) {
            abstractField.field = field;
            field.pivot = field.pivot ? field.pivot : [];
            field.pivot.push(FieldPivot.AGGREGATIONS);
          }
        });

        if (
          undefined !== this.widgetConfiguration.chart['layerNum'] &&
          this.widgetConfiguration.chart['layerNum'] >= 0
        ) {
          for (let layerIndex = 0; layerIndex < this.widgetConfiguration.chart['layers'].length; layerIndex++) {
            const fieldPivot: FieldPivot =
              layerIndex == 1 ? FieldPivot.MAP_LAYER1 : layerIndex == 2 ? FieldPivot.MAP_LAYER2 : FieldPivot.MAP_LAYER0;
            this.widgetConfiguration.shelf.layers[this.widgetConfiguration.chart['layerNum']].fields.forEach(
              (abstractField) => {
                if (String(field.type) == abstractField.type.toUpperCase() && field.name == abstractField.name) {
                  abstractField.field = field;
                  field.pivot = field.pivot ? field.pivot : [];
                  field.pivot.push(fieldPivot);
                }
              },
            );
          }
        }
      });
    }

    this.fieldsWCustom = _.concat(this.dimensions, this.measures);

    this._setUseFilter();
  }

  private init() {
    this.dataLayerKey = 'data';
    this.isModelLayerShow = false;
    this.isDataDimensionLayerShow = true;
    this.isDataMeasureLayerShow = true;
    this.recommendCharts = [];
    this.showInfoChart = '';
    this.isShowGuide = true;

    this.$fieldDetailLayer = $('#fieldDetailLayer');

    this.guideLayout = {
      layout1: ['pie', 'label', 'wordcloud', 'radar'],
      layout2: ['bar', 'grid', 'line', 'combine'],
      layout3: ['waterfall', 'sankey'],

      layout4: ['scatter', 'heatmap', 'boxplot', 'treemap', 'network'],
      layout5: ['gauge'],
      layout6: ['map'],
    };
  }

  private recommendChart() {
    this.recommendCharts = [];

    function getShelfCnt(shelfType: string, fieldType: string[], pivot): number {
      let shelf: PivotField[];
      if (shelfType === 'col') {
        shelf = pivot.columns;
      } else if (shelfType === 'row') {
        shelf = pivot.rows;
      } else if (shelfType === 'agg') {
        shelf = pivot.aggregations;
      } else {
        throw new Error('Unknown shelfType');
      }

      return shelf.filter((field: PivotField) => {
        return fieldType.indexOf(field.type) > -1;
      }).length;
    }

    function getAllShelfCntByType(fieldType: string[], allPivot): number {
      return allPivot.filter((field: PivotField) => {
        return fieldType.indexOf(field.type) > -1;
      }).length;
    }

    function getGeoType(logicalType: string, allPivot: PivotField[], uiOption: UIOption): number {
      if (
        !_.isUndefined(uiOption['analysis']) &&
        !_.isUndefined(uiOption['analysis']['use']) &&
        uiOption['analysis']['use']
      ) {
        return allPivot.length;
      }

      return allPivot.filter((item: PivotField) => {
        return item.field.logicalType && -1 !== item.field.logicalType.toString().indexOf(logicalType);
      }).length;
    }

    let pivotList = [];
    if (this.shelf && this.shelf.layers && undefined !== (<UIMapOption>this.uiOption).layerNum)
      pivotList = this.shelf.layers[(<UIMapOption>this.uiOption).layerNum].fields;
    else if (this.pivot) pivotList = this.pivot.aggregations.concat(this.pivot.rows.concat(this.pivot.columns));

    const geoCnt = getGeoType('GEO', pivotList, this.uiOption);

    if (geoCnt > 0) {
      this.recommendCharts.push('map');
      return;
    }

    const dimensionCnt = getAllShelfCntByType(['dimension'], pivotList);
    const measureCnt = getAllShelfCntByType(['measure'], pivotList);
    const timestampCnt = getAllShelfCntByType(['timestamp'], pivotList);

    if (dimensionCnt > 0 && measureCnt > 0) {
      this.recommendCharts.push('bar');
      this.recommendCharts.push('line');
      this.recommendCharts.push('pie');
    }

    if (timestampCnt > 0 && measureCnt > 0) {
      this.recommendCharts.push('bar');
      this.recommendCharts.push('line');
    }

    if (dimensionCnt > 0 && measureCnt > 0) {
      this.recommendCharts.push('grid');
    }

    if (dimensionCnt > 0 && measureCnt == 2) {
      this.recommendCharts.push('scatter');
    }

    if (dimensionCnt > 0 && measureCnt == 1) {
      this.recommendCharts.push('heatmap');
      this.recommendCharts.push('boxplot');
      this.recommendCharts.push('wordcloud');
      this.recommendCharts.push('treemap');
      this.recommendCharts.push('gauge');
    }

    if (timestampCnt > 0 && measureCnt > 0) {
      this.recommendCharts.push('control');
    }

    if (dimensionCnt == 0 && timestampCnt == 0 && measureCnt > 0) {
      this.recommendCharts.push('label');
    }

    if (timestampCnt === 1 && measureCnt === 1) {
      this.recommendCharts.push('waterfall');
    }

    if (dimensionCnt > 0 && measureCnt > 1 && 5 > measureCnt) {
      this.recommendCharts.push('combine');
    }

    if (dimensionCnt === 1 && measureCnt > 0) {
      this.recommendCharts.push('radar');
    }

    if (dimensionCnt == 2 && measureCnt == 1) {
      this.recommendCharts.push('network');
    }

    if (dimensionCnt > 2 && measureCnt == 1) {
      this.recommendCharts.push('sankey');
    }

    this.recommendCharts = _.uniq(this.recommendCharts);
  }

  private chartResize(isImmediate: boolean = false) {
    if (this.chart) {
      setTimeout(
        () => {
          if (this.chart.hasOwnProperty('barChart') && this.chart.hasOwnProperty('lineChart')) {
            const barChart: BarChartComponent = this.chart['barChart'];
            const lineChart: LineChartComponent = this.chart['lineChart'];
            barChart.chart.resize();
            lineChart.chart.resize();
          } else if (this.chart.uiOption.type === ChartType.LABEL) {
          } else if (this.widgetConfiguration.chart.type.toString() === 'grid') {
            if (this.chart && this.chart.chart) this.chart.chart.resize();
            // } else if (this.chart.uiOption.type === ChartType.GRAPH) {
            //   this.networkChart.draw();
          } else if (this.chart.uiOption.type === ChartType.MAP) {
            this.mapChart.resize();
          } else {
            if (this.chart && this.chart.chart) this.chart.chart.resize();
          }
        },
        isImmediate ? 0 : 300,
      );
    }

    if (this.gridChart?.pivotGrid) {
      setTimeout(() => this.gridChart.pivotGrid.arrange(), isImmediate ? 0 : 300);
    }
  }

  private drawChart(
    params: any = {
      successCallback: null,
      resultFormatOptions: {},
      filters: [],
      type: '',
    },
  ) {
    if (StringUtil.isEmpty(this.selectChart)) return;

    if (this.chart === undefined || this.chart === null) {
      setTimeout(() => this.drawChart(), 300);
      return;
    }

    if (!this.isVersionCheck) {
      if (!this.uiOption.version || this.uiOption.version < SPEC_VERSION) {
        this.uiOption = OptionGenerator.initUiOption(this.uiOption);

        if (this.selectChart == 'map') {
          this._setDefaultAreaForBBox(this.dataSource);
        }
      }

      this.isVersionCheck = true;
    }

    if (
      ('map' !== this.selectChart && false === this.chart.isValid(this.pivot as any)) ||
      ('map' === this.selectChart && false === this.chart.isValid(createPivot(), this.shelf as any))
    ) {
      this.isChartShow = false;
      this.isError = true;
      return;
    }

    const query: SearchQueryRequest = this.datasourceService.makeQuery(
      this.widgetConfiguration,
      this.fields,
      {
        url: this.router.url,
        dashboardId: this.dashboard.id,
        widgetId: this.widget.id,
      },
      params.resultFormatOptions,
    );

    const uiCloneQuery = _.cloneDeep(query);

    if (
      'map' !== this.selectChart &&
      uiCloneQuery.pivot.columns.length + uiCloneQuery.pivot.rows.length + uiCloneQuery.pivot.aggregations.length === 0
    ) {
      return;
    }

    if ('bar' == this.selectChart) {
      let isChangeDimensionType = false;

      this.pivot.rows.forEach((item) => {
        if (item.type === String(ShelveFieldType.DIMENSION)) {
          isChangeDimensionType = true;
        }
      });

      this.pivot.aggregations.forEach((item) => {
        if (item.type === String(ShelveFieldType.DIMENSION)) {
          isChangeDimensionType = true;
        }
      });

      if (isChangeDimensionType) {
        this.uiOption.color['schema'] = 'SC1';
        this.uiOption.color['type'] = ChartColorType.DIMENSION;
        this.uiOption.color['targetField'] = '';
      }
    }

    this.loadingShow();
    this.isNoData = false;
    this.isError = false;
    this.isChartShow = true;

    if (this.boardFilters && 0 < this.boardFilters.length) {
      uiCloneQuery.filters = this.boardFilters.concat(uiCloneQuery.filters);
    }

    if (params.filters && params.filters.length > 0) uiCloneQuery.filters = params.filters;

    const cloneQuery = this.makeSearchQueryParam(_.cloneDeep(uiCloneQuery));

    this.query = cloneQuery;
    if (this.selectChart === 'label') {
      this.chart['setQuery'] = this.query;
    }

    this.datasourceService
      .searchQuery(cloneQuery)
      .then((data) => {
        const resultData = {
          data: data,
          config: uiCloneQuery,
          uiOption: this.uiOption,
          type: params.type,
        };
        this.resultData = resultData;

        if (Object.keys(this.uiOption).length === 1) {
          delete resultData.uiOption;
        }

        this.initGridChart();

        this.limitInfo = DashboardUtil.getChartLimitInfo(this.widget.id, this.widget.configuration.chart.type, data);

        if (this.selectChart === 'line') {
          if (this.isAnalysisPredictionEnabled()) {
            Promise.resolve().then(() => {
              this.analysisComponent.synchronize(
                this.uiOption,
                { pivot: this.pivot },
                { type: params.type, widget: this.widget, lineChart: this.lineChartComponent },
              );

              if (this.analysisComponent.isValid() === false) {
                this.lineChartComponent.analysis = null;
                this.chart.resultData = resultData;
              }

              if (this.isAnalysisPredictionEnabled()) {
                this.loadingShow();
                this.analysisPredictionService
                  .getAnalysisPredictionLineFromPage(
                    this.widgetConfiguration,
                    cloneQuery.filters,
                    this.lineChartComponent,
                    resultData,
                  )
                  .catch((err) => {
                    this.loadingHide();
                    this.isError = true;
                    this.commonExceptionHandler(err);
                  });
              } else {
                this.lineChartComponent.analysis = null;
                this.chart.resultData = resultData;
              }
            });
          } else {
            if (this.analysisComponent.isValid()) {
              this.analysisComponent.changePredictionLineDisabled();
            }
            this.lineChartComponent.analysis = null;
            setTimeout(() => {
              this.chart.resultData = resultData;
            }, 300);
          }
        } else if (this.selectChart == 'map') {
          const mapUiOption = <UIMapOption>this.uiOption;

          for (let mapIndex = 0; mapUiOption.layers.length > mapIndex; mapIndex++) {
            if (
              mapUiOption.layers[mapIndex].type == MapLayerType.SYMBOL &&
              !_.isUndefined(mapUiOption.layers[mapIndex]['clustering']) &&
              mapUiOption.layers[mapIndex]['clustering']
            ) {
              mapUiOption.layers[mapIndex].type = MapLayerType.CLUSTER;
            }

            if (!_.isUndefined(params) && !_.isUndefined(params.type) && params.type == EventType.AGGREGATION) {
              mapUiOption.layers[mapIndex]['isColorOptionChanged'] = true;
            }
          }
          setTimeout(() => {
            this.chart.resultData = resultData;
          }, 300);
        } else {
          setTimeout(() => {
            this.chart.resultData = resultData;
          }, 300);
        }

        this.loadingHide();
        if (params.successCallback) {
          params.successCallback();
        }
      })
      .catch((reason) => {
        let err = {};

        if (!_.isUndefined(this.uiOption['analysis']) && this.uiOption['analysis']['use'] == true) {
          if (!_.isUndefined(reason) && (!_.isUndefined(reason['message']) || !_.isUndefined(reason['details']))) {
            err['code'] = reason.code;

            const message = reason['message'];
            const detailMessage = reason['details'];

            if (!_.isUndefined(message) && message.length > 30) {
              err['message'] = 'Spatial Config Error <br/>' + message.substring(0, 30);
              err['details'] = message + '<br/>' + detailMessage;
            } else {
              err['message'] = 'Spatial Config Error';
              err['details'] = message + '<br/>' + detailMessage;
            }
          } else {
            err['message'] = 'Spatial Config Error <br/>';
            err['details'] = reason;
          }
        } else {
          err = reason;
        }
        console.error('Search Query Error =>', err);
        this.isChartShow = false;
        this.isError = true;
        this.commonExceptionHandler(err);

        this.changeDetect.detectChanges();
        this.loadingHide();
      });
  }

  private dataPanelInnerScroll() {
    this.changeDetect.detectChanges();

    const $contentsHeight =
      this.$element.find('.ddp-ui-chart-lnb').outerHeight(true) -
      this.$element.find('.ddp-ui-drop-title').outerHeight(true) * 3;
    this.$element.find('.ddp-ui-dropmenu.ddp-selected .ddp-ui-drop-contents').height($contentsHeight);
  }

  private isAnalysisPredictionEnabled(): boolean {
    return !_.isUndefined(this.widgetConfiguration.analysis) && !_.isEmpty(this.widgetConfiguration.analysis);
  }

  private makeSearchQueryParam(cloneQuery): SearchQueryRequest {
    if (cloneQuery.pivot) {
      for (const field of _.concat(cloneQuery.pivot.columns, cloneQuery.pivot.rows, cloneQuery.pivot.aggregations)) {
        delete field['field'];
        delete field['currentPivot'];
        delete field['granularity'];
        delete field['segGranularity'];
      }
    }

    if (cloneQuery.shelf && cloneQuery.shelf.layers && cloneQuery.shelf.layers.length > 0) {
      cloneQuery.shelf.layers = _.remove(cloneQuery.shelf.layers, function (layer) {
        return layer['fields'].length != 0;
      });

      for (const layers of cloneQuery.shelf.layers) {
        for (const layer of layers.fields) {
          delete layer['field'];
          delete layer['currentPivot'];
          delete layer['granularity'];
          delete layer['segGranularity'];
        }
      }

      if (!_.isUndefined(cloneQuery.analysis)) {
        if (cloneQuery.analysis.use == true) {
          delete cloneQuery.analysis.operation.unit;
          delete cloneQuery.analysis.layer;
          delete cloneQuery.analysis.layerNum;
          delete cloneQuery.analysis.use;
        } else {
          delete cloneQuery.analysis;
        }
      }
    }

    for (let idx = 0, nMax = cloneQuery.filters.length; idx < nMax; idx++) {
      cloneQuery.filters[idx] = FilterUtil.convertToServerSpec(cloneQuery.filters[idx]);
    }

    cloneQuery.filters = cloneQuery.filters.filter((item) => !(item.type === 'bound' && item['min'] == null));

    cloneQuery.userFields = CommonUtil.objectToArray(cloneQuery.userFields);

    return cloneQuery;
  }

  private setUIOptionByPivot(): UIOption {
    if (this.uiOption.type == ChartType.BAR) {
      this.pivot.rows.forEach((item) => {
        if (item.type === String(ShelveFieldType.DIMENSION)) {
          this.uiOption['mark'] = BarMarkType.STACKED;
        }
      });

      this.pivot.aggregations.forEach((item) => {
        if (item.type === String(ShelveFieldType.DIMENSION)) {
          this.uiOption['mark'] = BarMarkType.MULTIPLE;
        }
      });
    }

    return this.uiOption;
  }

  private _setUseFilter() {
    if (!this.fields) return;

    let globalFilters = this.dashboard.configuration.filters;
    let chartFilters = this.widgetConfiguration.filters;

    if (!globalFilters) globalFilters = [];
    if (!chartFilters) chartFilters = [];

    const filters = globalFilters.concat(chartFilters);

    const fields: DatasourceField[] = this.dimensions.concat(this.measures);

    fields.concat(this.measures).forEach((field) => {
      field.useFilter = false;
    });

    fields.concat(this.measures).forEach((field) => {
      if (field.ref) {
        if (_.findIndex(filters, { field: field.name, ref: field.ref }) > -1) {
          field.useFilter = true;
        }
      } else {
        if (_.findIndex(filters, { field: field.name }) > -1) {
          field.useFilter = true;
        }
      }
    });
  }

  private _setChartFilter(targetFilter: Filter, isSetPanel: boolean = true) {
    _.remove(this.dashboard.configuration.filters, {
      field: targetFilter.field,
      dataSource: targetFilter.dataSource,
    });

    if (ChartType.MAP === this.widget.configuration.chart.type) {
      this.boardFilters = this.dashboard.configuration.filters;
    } else {
      this.boardFilters = DashboardUtil.getAllFiltersDsRelations(
        this.dashboard,
        this.widget.configuration.dataSource.engineName,
      );
    }

    targetFilter.ui.widgetId = this.isNewWidget() ? 'NEW' : this.widget.id;

    const chartFilters: Filter[] = this.widget.configuration.filters;
    const idx: number = chartFilters.findIndex((item) => item.field === targetFilter.field);
    if (-1 === idx) {
      chartFilters.push(_.cloneDeep(targetFilter));
    } else {
      chartFilters[idx] = _.cloneDeep(targetFilter);
    }
    this.widget.configuration.filters = chartFilters;

    if (isSetPanel && this._filterPanelComp) {
      this._filterPanelComp.setFilters(this.boardFilters, this.widget.configuration.filters);
    }
  }

  private convertShelfToPivot(pivot: Pivot, uiOption: UIOption) {
    if (
      this.shelf.layers &&
      this.shelf.layers[0] &&
      this.shelf.layers[0].fields[0] &&
      this.shelf.layers[0].fields.length > 0
    ) {
      pivot = createPivot();

      _.each(this.shelf.layers, (layer, layerNum) => {
        const layers = layer['fields'];
        _.each(layers, (item, index) => {
          if (item.field && item.field.pivot) {
            item.field.pivot = _.map(item.field.pivot, (pivotItem) => {
              pivotItem = FieldPivot.AGGREGATIONS;
              return pivotItem;
            });
          }

          if (
            MapLayerType.SYMBOL === (<UIMapOption>uiOption).layers[layerNum].type ||
            MapLayerType.CLUSTER === (<UIMapOption>uiOption).layers[layerNum].type ||
            MapLayerType.HEATMAP === (<UIMapOption>uiOption).layers[layerNum].type
          ) {
            this.pagePivot.distinctPivotItems(layers, item, index, layers, 'layer' + layerNum);
          }

          pivot.aggregations.push(item);
        });
      });
    }

    this.shelf = createShelf();

    return pivot;
  }

  private convertPivotToShelf(shelf: Shelf): Shelf {
    const currentLayer: ShelfLayers = shelf.layers[(<UIMapOption>this.uiOption).layerNum];
    if (!_.isUndefined(currentLayer) && 0 === currentLayer.fields.length) {
      currentLayer.ref = this.dataSource.engineName;

      _.forEach(_.cloneDeep(this.pivot), (value, key) => {
        this.pivot[key].map((item) => {
          if (item.field && item.field.pivot) {
            item.field.pivot = _.map(item.field.pivot, (pivotItem) => {
              pivotItem = FieldPivot.MAP_LAYER0;
              return pivotItem;
            });
          }

          delete item.aggregationType;

          currentLayer.fields.push(item);
        });
      });

      currentLayer.fields = _.uniqBy(currentLayer.fields, 'name');

      for (const item of currentLayer.fields) {
        item.field.pivot = _.uniq(item.field.pivot);
      }
    }

    this.pivot = createPivot();

    return shelf;
  }

  private getMapGeoType() {
    for (const item of this.pageDimensions) {
      if (item.logicalType && -1 !== item.logicalType.toString().indexOf('GEO')) {
        return (this.geoType = item.logicalType);
      }
    }
  }

  private invalidGeoData(targetPivot: PivotField[]) {
    _.remove(targetPivot, function (item: any) {
      return !_.isUndefined(item.logicalType) && item.logicalType.toString().indexOf('GEO') != -1;
    });
    return targetPivot;
  }

  public onChangeLayer(shelf) {
    this.shelf = this.convertPivotToShelf(shelf);

    this.changeDetect.detectChanges();
  }

  public removeAnalysisLayer(shelf) {
    this.changeDetect.detectChanges();

    this.onChangeShelf({
      shelf: shelf,
      eventType: EventType.MAP_SPATIAL_ANALYSIS,
    });
  }
}
