import { USER_PROFILE_DEFUALT_ID, USER_PROFILE_DEFUALT_USERNAME, USER_PROFILE_UNKNOWN_NAME } from "./defualt-profile-properties";

export class UserProfile {
    id: string;
    email?: string;
    username: string;
    firstName?: string;
	lastName?: string;
    emailVerified?: boolean;
    created?: Date;

    constructor(profile?: Keycloak.KeycloakProfile) {

        if(!profile) {
            this.id = USER_PROFILE_DEFUALT_ID;
            this.username = USER_PROFILE_DEFUALT_USERNAME
            return;
        }
        
        this.id = profile.id || USER_PROFILE_DEFUALT_ID,
        this.email = profile.email,
        this.username = profile.username || USER_PROFILE_UNKNOWN_NAME,
        this.created = profile.createdTimestamp ? new Date(profile.createdTimestamp) : undefined,
        this.emailVerified = profile.emailVerified;
        this.firstName = profile.firstName;
        this.lastName = profile.lastName;
        
    }
}

