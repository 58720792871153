<div class="ddp-wrap-edit {{ containerClass }}" *ngIf="isShowButtons">
  <label class="ddp-label-type">{{ title }}</label>

  <div class="ddp-ui-edit-option ddp-inline" *ngIf="dateType">
    <label class="ddp-label-radio" (change)="selectedDate = 'CREATED'">
      <input type="radio" [checked]="selectedDate === 'CREATED'" />
      <i class="ddp-icon-radio"></i>
      <span class="ddp-txt-radio">{{ 'msg.comm.ui.list.created' | translate }}</span>
    </label>
    <label class="ddp-label-radio" (change)="selectedDate = 'MODIFIED'">
      <input type="radio" [checked]="selectedDate === 'MODIFIED'" />
      <i class="ddp-icon-radio"></i>
      <span class="ddp-txt-radio">{{ 'msg.comm.ui.list.updated' | translate }}</span>
    </label>
  </div>

  <div class="ddp-ui-edit-option ddp-inline" *ngIf="usedCustomDateType">
    <label class="ddp-label-radio" *ngFor="let date of customDateTypeList" (change)="onChangeSelectedDateType(date)">
      <input type="radio" [checked]="selectedDate === date.value" />
      <i class="ddp-icon-radio"></i>
      <span class="ddp-txt-radio">{{ date.label }}</span>
    </label>
  </div>

  <div class="ddp-ui-edit-option">
    <a
      href="javascript:"
      class="ddp-btn-toggle ddp-all"
      [ngClass]="{ 'ddp-selected': periodType.ALL === selectedType }"
      *ngIf="useAllButton"
      (click)="setAll(); $event.stopImmediatePropagation()"
    >
      {{ 'msg.comm.ui.list.all' | translate }}
    </a>
    <a
      href="javascript:"
      class="ddp-btn-toggle ddp-today"
      [ngClass]="{ 'ddp-selected': periodType.TODAY === selectedType }"
      (click)="setToday(); $event.stopImmediatePropagation()"
    >
      {{ 'msg.comm.btn.today' | translate }}
    </a>
    <a
      href="javascript:"
      class="ddp-btn-toggle ddp-last"
      [ngClass]="{ 'ddp-selected': periodType.LAST_WEEK === selectedType }"
      (click)="setLastWeek(); $event.stopImmediatePropagation()"
    >
      {{ 'msg.comm.btn.last-7-days' | translate }}
    </a>

    <div class="ddp-ui-calen ddp-clear" style="width: 320px" [formGroup]="form">
      <p-calendar
        appendTo="body"
        formControlName="from"
        class="ddp-txt-calen"
        [showTime]="true"
        [showIcon]="true"
      ></p-calendar>
      <span class="ddp-bar">~</span>
      <p-calendar
        appendTo="body"
        formControlName="to"
        class="ddp-txt-calen"
        [showTime]="true"
        [showIcon]="true"
      ></p-calendar>
    </div>
    <a href="javascript:" class="ddp-btn-line-s" (click)="done()">{{ 'msg.page.btn.apply' | translate }}</a>
  </div>
</div>

<div class="ddp-ui-calen" *ngIf="!isShowButtons">
  <p-calendar
    appendTo="body"
    formControlName="from"
    class="ddp-txt-calen"
    [showTime]="true"
    [showIcon]="true"
    [disabled]="initialDate.mockup"
  ></p-calendar>
  <span class="ddp-bar">~</span>
  <p-calendar
    appendTo="body"
    formControlName="to"
    class="ddp-txt-calen"
    [showTime]="true"
    [showIcon]="true"
    [disabled]="initialDate.mockup"
  ></p-calendar>
</div>
