import { Injectable } from '@angular/core';
import { Observable, catchError, filter, map, take, tap, throwError } from 'rxjs';
import { Filter } from '../../workbook';
import { getAllFiltersDsRelations } from '../functions';
import { BoardWidgetOptions, Dashboard, LayoutWidgetInfo } from '../models';
import { DashboardStore, WidgetDashboardStore } from '../stores';
import { DashboardApiToDomainService } from './dashboard-api-to-domain.service';

@Injectable({ providedIn: 'root' })
export class DashboardDomainService {
  constructor(
    private readonly dashboardApiToDomainService: DashboardApiToDomainService,
    private readonly dashboardStore: DashboardStore,
    private readonly widgetDashboardStore: WidgetDashboardStore,
  ) {}

  loadDashboard(dashboardId: string): Observable<Dashboard> {
    this.dashboardStore.setLoading(dashboardId);

    return this.dashboardApiToDomainService.getDashboard(dashboardId).pipe(
      take(1),
      tap((dashboard) => {
        this.dashboardStore.updateDashboard(dashboardId, dashboard);
      }),
      catchError((err: unknown) => {
        this.dashboardStore.setError(dashboardId, err);

        return throwError(() => err);
      }),
    );
  }

  loadDashboardByWidgetId(widgetId: string): Observable<Dashboard> {
    this.widgetDashboardStore.setLoading(widgetId);

    return this.dashboardApiToDomainService.getDashboardByWidgetId(widgetId).pipe(
      take(1),
      tap((dashboard) => {
        this.dashboardStore.updateDashboard(widgetId, dashboard);
      }),
      catchError((err: unknown) => {
        this.dashboardStore.setError(widgetId, err);

        return throwError(() => err);
      }),
    );
  }

  getDashboard(dashboardId: string): Observable<Dashboard> {
    return this.dashboardStore.selectDashboard(dashboardId).pipe(filter(Boolean));
  }

  getDashboardLoading(dashboardId: string): Observable<boolean> {
    return this.dashboardStore.selectDashboardLoading(dashboardId);
  }

  getDashboardError(dashboardId: string): Observable<unknown> {
    return this.dashboardStore.selectDashboardError(dashboardId);
  }

  getBoardWidgetOptions(dashboardId: string): Observable<BoardWidgetOptions> {
    return this.getDashboard(dashboardId).pipe(
      map((dashboard) => {
        return dashboard.configuration.options.widget;
      }),
    );
  }

  getDashboardByWidgetId(widgetId: string): Observable<Dashboard> {
    return this.widgetDashboardStore.selectDashboard(widgetId).pipe(filter(Boolean));
  }

  getDashboardByWidgetIdLoading(widgetId: string): Observable<boolean> {
    return this.widgetDashboardStore.selectDashboardLoading(widgetId);
  }

  getLayoutWidgetInfoByWidgetId(widgetId: string): Observable<LayoutWidgetInfo> {
    return this.getDashboardByWidgetId(widgetId).pipe(
      map((dashboard) => dashboard.configuration.widgets.find((item) => item.ref === widgetId)),
    ) as Observable<LayoutWidgetInfo>;
  }

  getAllFiltersDsRelations(dashboardId: string, engineName: string, paramFilters?: Filter[]): Observable<Filter[]> {
    return this.getDashboard(dashboardId).pipe(
      map((dashboard) => {
        return getAllFiltersDsRelations(dashboard, engineName, paramFilters);
      }),
    );
  }
}
