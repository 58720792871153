<div class="bi-incapsulation">
  <button
    pButton
    icon="pi pi-times"
    type="button"
    appearance="icon"
    class="button-close p-button-text p-button-rounded p-button-link"
    pButton
    (click)="closeDialog()"
  ></button>

  <app-dashboard
    *ngIf="selectedDashboard"
    [isManagementUser]="false"
    [inputDashboard]="selectedDashboard"
    [readonly]="true"
  ></app-dashboard>
</div>
