import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';
import { MenubarModule } from 'primeng/menubar';
import { SettingsMenuComponentModule } from '../settings-menu/settings-menu.module';
import { HeaderLogoComponent } from './header-logo/header-logo.component';
import { HeaderComponent } from './header.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HeaderComponent, HeaderLogoComponent],
  exports: [HeaderComponent],
  imports: [CommonModule, MenubarModule, AvatarModule, SettingsMenuComponentModule, TranslateModule],
})
export class HeaderComponentModule {}
