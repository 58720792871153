<div class="ddp-wrap-shelf">
  <div id="shelfColumn" class="ddp-ui-shelf">
    <em *ngIf="'network' !== chartType" class="ddp-icon-rows"></em>
    <em *ngIf="'network' == chartType" class="ddp-icon-network ddp-source"></em>

    <div class="ddp-ui-drag-slide">
      <a
        (mouseover)="mouseOverPrev($event)"
        (mouseout)="animationPause = true"
        href="javascript:"
        class="ddp-btn-prev"
        style="display: none"
      >
        <em class="ddp-icon-prev"></em>
      </a>
      <a
        (mouseover)="mouseOverNext($event)"
        (mouseout)="animationPause = true"
        href="javascript:"
        class="ddp-btn-next"
        style="display: none"
      >
        <em class="ddp-icon-next"></em>
      </a>
      <div class="ddp-ui-drag-slide-in">
        <div
          class="ddp-wrap-default"
          [dragula]="'dragbag'"
          [dragulaModel]="pivot.columns"
          [attr.data-container]="'column'"
        >
          <div
            class="dragIgnore ddp-wrap-example"
            *ngIf="chartType == '' && pivot.columns.length == 0"
          >
            <div class="ddp-box-shelf">
              {{ "msg.page.ui.place.data" | translate }}
            </div>
          </div>

          <div
            class="dragIgnore ddp-wrap-example"
            *ngIf="chartType != '' && unavailableShelfs.indexOf('column') > -1"
          >
            <div class="ddp-box-unavailable">
              {{ "msg.page.ui.unavailable.shelf" | translate }}
            </div>
          </div>

          <div
            class="ddp-ui-default"
            *ngFor="let field of pivot.columns; let i = index"
            [ngStyle]="{ display: finishAnimation ? 'block' : 'none' }"
          >
            <a
              *ngIf="field"
              href="javascript:"
              unselectable="on"
              [class.ddp-ui-dimension-wrap]="
                field.type == 'dimension' || field.type == 'timestamp'
              "
              [class.ddp-ui-measure-wrap]="field.type == 'measure'"
              (mousedown)="dragField = pivot.columns.slice(i, i + 1)[0]"
            >
              <div class="ddp-data-txt">
                <span
                  class="ddp-type-value"
                  *ngIf="field.type && 'timestamp' === field.type"
                >
                  {{ getGranularityName(field) }}
                </span>

                <span
                  class="ddp-type-value"
                  *ngIf="
                    (!field.pivotAlias || field.name == field.pivotAlias) &&
                    'measure' == field.type &&
                    ((field.aggregationType && 'user_expr' !== field.subType) ||
                      (!field.aggregated && 'user_expr' == field.subType))
                  "
                >
                  {{ field.aggregationType }}
                </span>

                <em
                  class="ddp-icon-point-w ddp-icon-map-view-w"
                  *ngIf="
                    field.field?.logicalType &&
                    'GEO_POINT' == field.field?.logicalType?.toString()
                  "
                ></em>

                <em
                  class="ddp-icon-line-w ddp-icon-map-view-w"
                  *ngIf="
                    field.field?.logicalType &&
                    'GEO_LINE' == field.field?.logicalType?.toString()
                  "
                ></em>

                <em
                  class="ddp-icon-polygon-w ddp-icon-map-view-w"
                  *ngIf="
                    field.field?.logicalType &&
                    'GEO_POLYGON' == field.field?.logicalType?.toString()
                  "
                ></em>

                <div class="ddp-ui-icons">
                  <em
                    class="ddp-icon-control-calculat"
                    *ngIf="
                      field.subType == 'user_expr' && field.type == 'measure'
                    "
                  ></em>
                  <em
                    class="ddp-icon-control-user"
                    *ngIf="
                      field.subType == 'user_expr' && field.type == 'dimension'
                    "
                  ></em>
                  <span
                    class="ddp-icon-layer"
                    [class.ddp-selected]="editingField == field"
                    (click)="openFieldSetting($event, field)"
                  >
                    <em></em>
                  </span>
                  <span
                    class="ddp-icon-close"
                    (click)="removeField($event, 'COLUMNS', pivot.columns, i)"
                  >
                    <em></em>
                  </span>
                </div>
                <span
                  class="ddp-data"
                  title="{{ getDisplayPivotName(field) }}"
                  >{{ getDisplayPivotName(field) }}</span
                >
              </div>
            </a>
          </div>

          <div *ngIf="isGuide('columns')" class="dragIgnore ddp-wrap-example">
            <div [attr.class]="getGuideText('columns', false)">
              {{ getGuideText("columns") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="shelfRow" class="ddp-ui-shelf">
    <em *ngIf="'network' !== chartType" class="ddp-icon-columns"></em>
    <em *ngIf="'network' == chartType" class="ddp-icon-network ddp-target"></em>

    <div class="ddp-ui-drag-slide">
      <a
        (mouseover)="mouseOverPrev($event)"
        (mouseout)="animationPause = true"
        href="javascript:"
        class="ddp-btn-prev"
        style="display: none"
      >
        <em class="ddp-icon-prev"></em>
      </a>
      <a
        (mouseover)="mouseOverNext($event)"
        (mouseout)="animationPause = true"
        href="javascript:"
        class="ddp-btn-next"
        style="display: none"
      >
        <em class="ddp-icon-next"></em>
      </a>
      <div class="ddp-ui-drag-slide-in">
        <div
          class="ddp-wrap-default"
          [dragula]="'dragbag'"
          [dragulaModel]="pivot.rows"
          [attr.data-container]="'row'"
        >
          <div
            class="dragIgnore ddp-wrap-example"
            *ngIf="chartType == '' && pivot.rows.length == 0"
          >
            <div class="ddp-box-shelf">
              {{ "msg.page.ui.place.data" | translate }}
            </div>
          </div>

          <div
            class="dragIgnore ddp-wrap-example"
            *ngIf="chartType != '' && unavailableShelfs.indexOf('row') > -1"
          >
            <div class="ddp-box-unavailable">
              {{ "msg.page.ui.unavailable.shelf" | translate }}
            </div>
          </div>

          <div
            class="ddp-ui-default"
            *ngFor="let field of pivot.rows; let i = index"
            [ngStyle]="{ display: finishAnimation ? 'block' : 'none' }"
          >
            <a
              *ngIf="field"
              href="javascript:"
              unselectable="on"
              [class.ddp-ui-dimension-wrap]="
                field.type == 'dimension' || field.type == 'timestamp'
              "
              [class.ddp-ui-measure-wrap]="field.type == 'measure'"
              (mousedown)="dragField = pivot.rows.slice(i, i + 1)[0]"
            >
              <div class="ddp-data-txt">
                <span
                  class="ddp-type-value"
                  *ngIf="field.type && 'timestamp' === field.type"
                >
                  {{ getGranularityName(field) }}
                </span>

                <span
                  class="ddp-type-value"
                  *ngIf="
                    (!field.pivotAlias || field.name == field.pivotAlias) &&
                    'measure' == field.type &&
                    ((field.aggregationType && 'user_expr' !== field.subType) ||
                      (!field.aggregated && 'user_expr' == field.subType))
                  "
                >
                  {{ field.aggregationType }}
                </span>

                <em
                  class="ddp-icon-point-w ddp-icon-map-view-w"
                  *ngIf="
                    field.field?.logicalType &&
                    'GEO_POINT' == field.field?.logicalType?.toString()
                  "
                ></em>

                <em
                  class="ddp-icon-line-w ddp-icon-map-view-w"
                  *ngIf="
                    field.field?.logicalType &&
                    'GEO_LINE' == field.field?.logicalType?.toString()
                  "
                ></em>

                <em
                  class="ddp-icon-polygon-w ddp-icon-map-view-w"
                  *ngIf="
                    field.field?.logicalType &&
                    'GEO_POLYGON' == field.field?.logicalType?.toString()
                  "
                ></em>

                <div class="ddp-ui-icons">
                  <em
                    class="ddp-icon-control-calculat"
                    *ngIf="
                      field.subType == 'user_expr' && field.type == 'measure'
                    "
                  ></em>
                  <em
                    class="ddp-icon-control-user"
                    *ngIf="
                      field.subType == 'user_expr' && field.type == 'dimension'
                    "
                  ></em>
                  <span
                    class="ddp-icon-layer"
                    [class.ddp-selected]="editingField == field"
                    (click)="openFieldSetting($event, field)"
                  >
                    <em></em>
                  </span>
                  <span
                    class="ddp-icon-close"
                    (click)="removeField($event, 'ROWS', pivot.rows, i)"
                    ><em></em
                  ></span>
                </div>
                <span
                  class="ddp-data"
                  title="{{ getDisplayPivotName(field) }}"
                  >{{ getDisplayPivotName(field) }}</span
                >
              </div>
            </a>
          </div>

          <div *ngIf="isGuide('rows')" class="dragIgnore ddp-wrap-example">
            <div [attr.class]="getGuideText('rows', false)">
              {{ getGuideText("rows") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="shelfAggregation" class="ddp-ui-shelf">
    <em *ngIf="'network' !== chartType" class="ddp-icon-cross"></em>
    <em *ngIf="'network' == chartType" class="ddp-icon-network ddp-link"></em>

    <div class="ddp-ui-drag-slide">
      <a
        (mouseover)="mouseOverPrev($event)"
        (mouseout)="animationPause = true"
        href="javascript:"
        class="ddp-btn-prev"
        style="display: none"
      >
        <em class="ddp-icon-prev"></em>
      </a>
      <a
        (mouseover)="mouseOverNext($event)"
        (mouseout)="animationPause = true"
        href="javascript:"
        class="ddp-btn-next"
        style="display: none"
      >
        <em class="ddp-icon-next"></em>
      </a>
      <div class="ddp-ui-drag-slide-in">
        <div
          class="ddp-wrap-default"
          [dragula]="'dragbag'"
          [dragulaModel]="pivot.aggregations"
          [attr.data-container]="'aggregation'"
        >
          <div
            class="dragIgnore ddp-wrap-example"
            *ngIf="chartType == '' && pivot.aggregations.length == 0"
          >
            <div class="ddp-box-shelf">
              {{ "msg.page.ui.place.measures" | translate }}
            </div>
          </div>

          <div
            class="dragIgnore ddp-wrap-example"
            *ngIf="
              chartType != '' && unavailableShelfs.indexOf('aggregation') > -1
            "
          >
            <div class="ddp-box-unavailable">
              {{ "msg.page.ui.unavailable.shelf" | translate }}
            </div>
          </div>

          <div
            class="ddp-ui-default"
            *ngFor="let field of pivot.aggregations; let i = index"
            [ngStyle]="{ display: finishAnimation ? 'block' : 'none' }"
          >
            <a
              *ngIf="field"
              href="javascript:"
              unselectable="on"
              [class.ddp-ui-dimension-wrap]="
                field.type == 'dimension' || field.type == 'timestamp'
              "
              [class.ddp-ui-measure-wrap]="field.type == 'measure'"
              (mousedown)="dragField = pivot.aggregations.slice(i, i + 1)[0]"
            >
              <div class="ddp-data-txt">
                <span
                  class="ddp-type-value"
                  *ngIf="field.type && 'timestamp' === field.type"
                >
                  {{ getGranularityName(field) }}
                </span>

                <span
                  class="ddp-type-value"
                  *ngIf="
                    (!field.pivotAlias || field.name == field.pivotAlias) &&
                    'measure' == field.type &&
                    ((field.aggregationType && 'user_expr' !== field.subType) ||
                      (!field.aggregated && 'user_expr' == field.subType))
                  "
                >
                  {{ field.aggregationType }}
                </span>

                <em
                  class="ddp-icon-point-w ddp-icon-map-view-w"
                  *ngIf="
                    field.field?.logicalType &&
                    'GEO_POINT' == field.field?.logicalType?.toString()
                  "
                ></em>

                <em
                  class="ddp-icon-line-w ddp-icon-map-view-w"
                  *ngIf="
                    field.field?.logicalType &&
                    'GEO_LINE' == field.field?.logicalType?.toString()
                  "
                ></em>

                <em
                  class="ddp-icon-polygon-w ddp-icon-map-view-w"
                  *ngIf="
                    field.field?.logicalType &&
                    'GEO_POLYGON' == field.field?.logicalType?.toString()
                  "
                ></em>

                <div class="ddp-ui-icons">
                  <em
                    class="ddp-icon-control-calculat"
                    *ngIf="
                      field.subType == 'user_expr' && field.type == 'measure'
                    "
                  ></em>
                  <em
                    class="ddp-icon-control-user"
                    *ngIf="
                      field.subType == 'user_expr' && field.type == 'dimension'
                    "
                  ></em>
                  <span
                    class="ddp-icon-layer"
                    [class.ddp-selected]="editingField == field"
                    (click)="openFieldSetting($event, field)"
                  >
                    <em></em>
                  </span>
                  <span
                    class="ddp-icon-close"
                    (click)="
                      removeField($event, 'AGGREGATIONS', pivot.aggregations, i)
                    "
                  >
                    <em></em>
                  </span>
                </div>
                <span
                  class="ddp-data"
                  title="{{ getDisplayPivotName(field) }}"
                  >{{ getDisplayPivotName(field) }}</span
                >
              </div>
            </a>
          </div>

          <div
            *ngIf="isGuide('aggregations')"
            class="dragIgnore ddp-wrap-example"
          >
            <div [attr.class]="getGuideText('aggregations', false)">
              {{ getGuideText("aggregations") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<pivot-context
  #editFieldLayer
  [widget]="widget"
  [uiOption]="uiOption"
  [(editingField)]="editingField"
  [filterFiledList]="filterFiledList"
  [chartType]="chartType"
  [pivot]="pivot"
  [aggTypeList]="aggTypeList"
  [combineAggIndex]="combineAggIndex"
  (changePivotContext)="subscribeFromPivotContext($event)"
></pivot-context>
