import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetApiService } from './widgets';
import { DashboardApiService } from './dashboard';
import { KdBackendApiService } from './common';

@NgModule({
  imports: [CommonModule],
  providers: [WidgetApiService, DashboardApiService, KdBackendApiService],
})
export class BiApiModule {}
