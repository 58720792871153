import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'selfai-platform-header-logo',
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLogoComponent {
  @Input() path!: string;
}
