<div *ngIf="{ activeMenuItem: activeMenuItem$ | async } as obs" class="layout-topbar">
  <div class="layout-topbar-wrapper">
    <div class="layout-topbar-left">
      <div class="layout-topbar-logo" id="logolink" style="cursor: pointer; outline: none" routerLink="/">
        <img id="app-logo" [src]="logoPathWithBaseHref" alt="Logo" />
      </div>
    </div>

    <div class="layout-topbar-right">
      <a class="menu-button" href="#" (click)="onMenuButtonClick($event)">
        <i class="pi pi-bars"></i>
      </a>

      <ul class="layout-topbar-actions">
        <li
          #notifications
          class="topbar-item notifications"
          [ngClass]="{ 'active-topmenuitem': obs.activeMenuItem === notifications }"
        >
          <a href="#" (click)="onTopbarItemClick($event, notifications)">
            <span class="p-overlay-badge topbar-icon">
              <i class="pi pi-bell" pBadge value="2"></i>
            </span>
          </a>
          <ul class="fadeInDown">
            <li class="layout-submenu-header">
              <h6 class="header-text">{{ 'shell.notifications.title' | translate }}</h6>
              <span class="p-badge">0</span>
            </li>
          </ul>
        </li>
        <li
          #settings
          class="topbar-item settings"
          [ngClass]="{ 'active-topmenuitem': obs.activeMenuItem === settings }"
        >
          <a href="#" (click)="onTopbarItemClick($event, settings)">
            <span class="topbar-icon">
              <i class="pi pi-cog"></i>
            </span>
          </a>
          <ul selfaiPlatformSettingsMenu class="fadeInDown"></ul>
        </li>
        <li
          #profile
          class="topbar-item user-profile"
          [ngClass]="{ 'active-topmenuitem': obs.activeMenuItem === profile }"
          *ngIf="userProfile$ | async as userProfile"
        >
          <a href="#" (click)="onTopbarItemClick($event, profile)">
            <p-avatar shape="circle" icon="pi pi-user" size="large"></p-avatar>
            <div class="profile-info">
              <h6>{{ userProfile.username }}</h6>
              
            </div>
          </a>

          <ul class="fadeInDown">
            <li class="layout-submenu-header">
              <p-avatar icon="pi pi-user" styleClass="p-mr-2" shape="circle" size="xlarge"></p-avatar>
              <div class="profile-info">
                <h6>{{ userProfile.username }}</h6>
                <span>{{ userProfile.email }}</span>
              </div>
            </li>
            <li role="menuitem">
              <div>
                <i class="pi pi-info-circle"></i>
                <span>{{ "shell.header.version" | translate }} {{packageVersion}}</span>
              </div>
            </li>
            <li role="menuitem">
              <div (click)="logout()">
                <i class="pi pi-power-off"></i>
                <h6>{{ "shell.header.logout" | translate }}</h6>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
