import { Injectable } from '@angular/core';

import jwt_decode from 'jwt-decode';
import { KeycloakService } from 'keycloak-angular';
import { CookieService } from 'ng2-cookies';

import { CookieConstant } from '../common/constant/cookie.constant';
import { KeycloakParsedToken } from '../user/service/keycloakParsedToken';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private readonly cookieService: CookieService, private readonly keycloak: KeycloakService) {}

  public async setCredentialsFromKeycloak() {
    const token = await this.keycloak.getToken();
    this.setAuthCookies(token);
  }

  public async updateToken() {
    await this.keycloak.updateToken();
    await this.setCredentialsFromKeycloak();
  }

  public async logout() {
    await this.keycloak.logout();
  }

  public setCredentialsFromToken(token: string, refreshToken?: string) {
    this.setAuthCookies(token, refreshToken);
  }

  private setAuthCookies(token: string, refreshToken?: string) {
    const decodedToken = jwt_decode(token) as KeycloakParsedToken;
    this.cookieService.set(CookieConstant.KEY.LOGIN_TOKEN, token, 0, '/');
    this.cookieService.set(CookieConstant.KEY.LOGIN_TOKEN_TYPE, 'Bearer', 0, '/');
    this.cookieService.set(CookieConstant.KEY.LOGIN_USER_ID, decodedToken.preferred_username, 0, '/');
    if (refreshToken) {
      this.cookieService.set(CookieConstant.KEY.REFRESH_LOGIN_TOKEN, refreshToken, 0, '/');
    }
  }
}
