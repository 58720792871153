import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  Condition,
  CreateTriggerFormGroup,
  TriggerRule,
  TriggerRuleFormGroup,
  WorkflowTriggerData,
} from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { Table } from 'primeng/table';
import { distinctUntilChanged, filter, take, takeUntil } from 'rxjs';
import { DialogHelperService } from '../../../../dialog';
import { normalizeToLegacyData } from '../../../converters/create-trigger.normalizer';
import { SaveConfirmationService } from '../../../services/save-confirmation.service';
import { SelectionStoreService } from '../../../services/selection-store.service';
import { AbstractCubeDialogFormWithTableComponent } from '../../abstract-cube-dialog-form-with-table.component';
import { CreateTriggerComponentService } from './create-trigger-component.service';

@Component({
  selector: 'selfai-platform-create-trigger',
  templateUrl: './create-trigger.component.html',
  styleUrls: ['./create-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DestroyService,
    DialogHelperService,
    SelectionStoreService,
    SaveConfirmationService,
    CreateTriggerComponentService,
  ],
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          'max-height': '0px',
        }),
      ),
      state(
        'active',
        style({
          'max-height': '1000px',
        }),
      ),
      transition('* <=> *', animate('1000ms')),
    ]),
  ],
})
export class CreateTriggerComponent extends AbstractCubeDialogFormWithTableComponent implements OnInit {
  override itemsMap = new Map<string, FormGroup<TriggerRuleFormGroup>>();
  conditions = Object.values(Condition);
  override form: FormGroup<CreateTriggerFormGroup> = this.createTriggerComponentService.getFormGroup();

  override initialItem: TriggerRule = {
    id: '',
    attrColumnName: '',
    condition: null,
    primary: false,
    resultColumn: '',
    value: '',
    ignoreCase: null,
    startPosition: null,
    startRange: null,
    includeStartRange: null,
    endRange: null,
    includeEndRange: null,
  };

  @ViewChild('tableRef', { static: false, read: Table }) tableRef!: Table;

  get dataForWorkflow(): WorkflowTriggerData {
    return normalizeToLegacyData({
      resultColumn: this.form.controls.resultColumn.value || '',
      debugMode: this.form.controls.debugMode.value || false,
      conditions: this.items.map((v) => v.value as TriggerRule),
    });
  }

  constructor(private readonly createTriggerComponentService: CreateTriggerComponentService, injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this;
    this.createTriggerComponentService
      .getTriggerRulesFormGroups()
      .pipe(take(1), filter(Boolean), takeUntil(this.destroy$))
      .subscribe((items) => {
        items.forEach((formGroup) => this.itemsMap.set(formGroup.controls.id.value, formGroup));
        this.markFormAsInitialized();
      });
  }

  filter(event: Event): void {
    this.tableRef.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  mapItemToFormGroup(item: TriggerRule): FormGroup<TriggerRuleFormGroup> {
    return this.createTriggerComponentService.mapItemToFormGroup(item);
  }

  cloneSynchronizedControl(formControl: FormControl<unknown>): FormControl<unknown> {
    const cloneFormControl = new FormControl<unknown>(formControl.value);

    formControl.valueChanges
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe((value) => cloneFormControl.setValue(value));

    cloneFormControl.valueChanges
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe((value) => formControl.setValue(value));

    return cloneFormControl;
  }
}
