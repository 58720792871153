import { BiPermission } from './bi-permission.enum';
import { PermissionType } from './permission-type';
import { PermissionZone, PermissionZoneName } from './permission-zone';
import { populateRoleDefinitions } from './populate-role-definitions';
import { SelfaiRole } from './selfai-role';

export class UserRole {
  private roleNames: string[];
  private systemRoles: SelfaiRole[] = [];
  private effectivePermissions: PermissionZone[] = [];
  readonly permissions: UserPermissions;

  constructor(roleNames: string[]) {
    this.roleNames = [...roleNames];
    const selfaiRoleDefinitions = populateRoleDefinitions();

    for (const roleName of this.roleNames) {
      const systemRole = selfaiRoleDefinitions[roleName as BiPermission];
      if (systemRole && this.systemRoles.indexOf(systemRole) === -1) {
        this.systemRoles.push(systemRole);
      }
    }

    const permissions = {};

    for (const zoneName in PermissionZoneName) {
      let zonePermissions: PermissionType[] = [];
      for (const systemRole of this.systemRoles) {
        const roleZonePermissions = systemRole.zones.find((t) => t.name === zoneName);
        if (roleZonePermissions) {
          zonePermissions = [...zonePermissions, ...roleZonePermissions.permissions];
        }
      }
      const uniqZonePermissions = [...new Set(zonePermissions)];
      this.effectivePermissions.push(new PermissionZone(zoneName as PermissionZoneName, uniqZonePermissions));

      permissions[zoneName] = {};
      for (const permission in PermissionType) {
        permissions[zoneName][permission] = uniqZonePermissions.indexOf(permission as PermissionType) !== -1;
      }
    }

    this.permissions = permissions as UserPermissions;
  }

  hasPermission(zone: PermissionZoneName, permission: PermissionType) {
    const zonePermission = this.effectivePermissions.find((t) => t.name === zone);
    if (!zonePermission) {
      return false;
    }
    return zonePermission.permissions.indexOf(permission) !== -1;
  }
}

export type UserPermissions = {
  [zone in PermissionZoneName]: ZonePermissions;
};

export type ZonePermissions = {
  [permission in PermissionType]: boolean;
};
