<div class="ddp-layout-popuptype" *ngIf="isShow">
  <em class="ddp-bg-popup"></em>
  <div class="ddp-ui-popup">
    <div class="ddp-ui-popup-title">
      <span class="ddp-txt-title-name">{{ 'msg.permission.ui.change-ws-perms-schema' | translate }}</span>
    </div>

    <div class="ddp-pop-schema">
      <div class="ddp-pop-in">
        <div class="ddp-pop-head">
          <span class="ddp-pop-name">{{ 'msg.permission.ui.change-schema' | translate }}</span>
          <div class="ddp-ui-pop-buttons">
            <a (click)="close()" href="javascript:" class="ddp-btn-pop">{{ 'msg.comm.btn.cancl' | translate }}</a>
            <a (click)="done()" href="javascript:" class="ddp-btn-pop ddp-bg-black">
              {{ 'msg.comm.btn.done' | translate }}
            </a>
          </div>
        </div>

        <div class="ddp-form-table">
          <table class="ddp-table-list2">
            <colgroup>
              <col width="50%" />
              <col width="50%" />
            </colgroup>
            <thead>
              <tr>
                <th>Current schema</th>
                <th>New schema</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span class="ddp-name">{{ getRoleSetName(currentRoleSet) }}</span>

                  <div class="ddp-wrap-info">
                    <em class="ddp-icon-info"></em>

                    <div class="ddp-pop-form" *ngIf="currentRoleSet">
                      <app-permission-schema
                        [roleSet]="currentRoleSet"
                        [editMode]="false"
                        [desc]="false"
                      ></app-permission-schema>
                    </div>
                  </div>

                  <em class="ddp-view"></em>
                </td>

                <td>
                  <div
                    class="ddp-type-selectbox"
                    [class.ddp-selected]="isShowRoleSetList"
                    (click)="isShowRoleSetList = !isShowRoleSetList"
                  >
                    <span class="ddp-txt-selectbox">
                      {{ selectedRoleSetInfo ? selectedRoleSetInfo.name : 'Select Role Set' }}
                    </span>
                    <div class="ddp-wrap-popup2">
                      <ul class="ddp-list-popup">
                        <li
                          *ngFor="let item of roleSetList"
                          (click)="selectRoleSet(item)"
                          [ngClass]="{
                            'ddp-selected': selectedRoleSetInfo && item.name === selectedRoleSetInfo.name,
                            'ddp-disabled': currentRoleSet.name === item.name
                          }"
                        >
                          <a href="javascript:">
                            {{ item.name }}
                            <em class="ddp-icon-check"></em>
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="ddp-wrap-info" *ngIf="selectedRoleSetDetail">
                      <em class="ddp-icon-info"></em>

                      <div class="ddp-pop-form">
                        <app-permission-schema
                          [roleSet]="selectedRoleSetDetail"
                          [editMode]="false"
                          [desc]="false"
                        ></app-permission-schema>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="ddp-table-list2" *ngIf="selectedRoleSetDetail">
            <colgroup>
              <col width="50%" />
              <col width="50%" />
            </colgroup>
            <thead>
              <tr>
                <th>Current role</th>
                <th>
                  New role
                  <a
                    *ngIf="isPermSchemaEditMode"
                    (click)="onClickOpenPermissionSchemaSet()"
                    href="javascript:"
                    class="ddp-link-edit"
                  >
                    <em class="ddp-icon-edit"></em>
                    {{ 'msg.comm.ui.edit' | translate }}
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let currentItem of currentRoleSet.roles">
                <td>
                  <span class="ddp-name">{{ currentItem.name }}</span>

                  <div class="ddp-wrap-info">
                    <em class="ddp-icon-info"></em>

                    <div class="ddp-pop-form">
                      <app-role-schema [role]="currentItem"></app-role-schema>
                    </div>
                  </div>

                  <em class="ddp-view"></em>
                </td>
                <td>
                  <div
                    class="ddp-type-selectbox"
                    [class.ddp-selected]="currentItem['isOpenNewRoleOpts']"
                    (click)="clickRoleSelectbox(currentItem)"
                  >
                    <span class="ddp-txt-selectbox">
                      {{ currentItem['newRole'] ? currentItem['newRole'].name : 'Select Role' }}
                    </span>
                    <div class="ddp-wrap-popup2">
                      <ul class="ddp-list-popup">
                        <li
                          *ngFor="let newItem of selectedRoleSetDetail.roles"
                          [class.ddp-selected]="currentItem['newRole'] && currentItem['newRole'].name === newItem.name"
                          (click)="currentItem['newRole'] = newItem"
                        >
                          <a href="javascript:">
                            {{ newItem.name }}
                            <em class="ddp-icon-check"></em>
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="ddp-wrap-info" *ngIf="currentItem['newRole']">
                      <em class="ddp-icon-info"></em>

                      <div class="ddp-pop-form">
                        <app-role-schema [role]="currentItem['newRole']"></app-role-schema>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="ddp-ui-ex">
          <span class="ddp-label-ex">Explanation</span>
          <ul class="ddp-list-ex">
            <li>Default role : Role to be granted when adding new members and groups</li>
            <li>View of (item) : Enable to access to item and to read contents</li>
            <li>Create of (item) : Enable to create, modify and delete items</li>
            <li>Edit any of (item) : Enable to create, modify and delete items which is created by other users</li>
            <li>Create folders : Enable to create, modify and delete folders</li>
            <li>Set config. : Enable to edit information and to set configuration of workspace</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<app-permission-schema-set (afterUpdate)="afterUpdatePermissionRoles($event)"></app-permission-schema-set>
