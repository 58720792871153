<p-tree
  *ngIf="workflowList$ | async as workflowList; else emptyList"
  [value]="workflowList"
  selectionMode="single"
  [(selection)]="selectedNode"
  [filter]="true"
  filterBy="label,data.jsonParameters,data.node.operation.name"
  (onFilter)="onFilter($event)"
  (keydown.delete)="onKeydownDelete($event)"
  [loading]="loadingWorkflowList"
  (onNodeExpand)="nodeExpand($event)"
>
  <ng-template [pTemplate]="nodeType.WORKFLOW" let-node>
    <div class="flex align-items-center">
      <button
        icon="pi pi-sitemap"
        pButton
        class="p-button-text p-1"
        type="button"
        (click)="toggle($event, node)"
        [label]="node.label"
      ></button>
      <p-tag
        *ngIf="isCurrentWorkflowId(node.data.workflowId) | async"
        styleClass="ml-2"
        severity="success"
        value="current"
      ></p-tag>
    </div>
  </ng-template>

  <ng-template [pTemplate]="nodeType.OPERATION" let-node>
    <button
      [icon]="node.expanded ? 'pi pi-folder-open' : 'pi pi-folder'"
      pButton
      class="p-button-text p-1"
      type="button"
      (click)="toggle($event, node)"
      [label]="node.label"
    ></button>
  </ng-template>

  <ng-template [pTemplate]="nodeType.RECYCLEBIN" let-node>
    <button
      icon="pi pi-trash"
      pButton
      class="p-button-text p-1"
      type="button"
      (click)="toggle($event, node)"
      label="Recycle bin"
    ></button>
  </ng-template>

  <ng-template [pTemplate]="nodeType.REMOVEDCUBE" let-node>
    <div cdkDropList cdkDropListConnectedTo="workflowEditor">
      <div
        cdkDrag
        cdkDragPreviewContainer="global"
        [cdkDragData]="node.data.node | rxjsOf"
        tabindex="0"
        (copy)="copyToClipboard(node.data.node)"
        (contextmenu)="onContextMenu(node, $event)"
        class="p-1 flex node-item"
      >
        <span *cdkDragPlaceholder></span>
        <selfai-platform-cube-dnd-placeholder
          *cdkDragPreview
          [cubeUiName]="node.label"
          [operationName]="node.data.node.operation?.name"
        ></selfai-platform-cube-dnd-placeholder>
        <span>{{ node.label }}</span>
      </div>
    </div>
  </ng-template>

  <ng-template [pTemplate]="nodeType.CUBE" let-node>
    <div class="p-1 flex" *ngIf="node === (renamedNode$ | async); else nodeTemplate">
      <form class="p-inputgroup" (ngSubmit)="saveRenameCube()">
        <input
          pAutoFocus
          [autofocus]="true"
          pInputText
          type="text"
          placeholder="Type a node name"
          [(ngModel)]="newName"
          [ngModelOptions]="{ standalone: true }"
        />
        <button type="submit" tabindex="-1" pRipple pButton icon="pi pi-save"></button>
        <button tabindex="-1" pRipple pButton icon="pi pi-ban" (click)="cancelRenameCube()"></button>
      </form>
    </div>
    <ng-template #nodeTemplate>
      <div cdkDropList cdkDropListConnectedTo="workflowEditor">
        <div
          cdkDrag
          cdkDragPreviewContainer="global"
          [cdkDragData]="node.data.node | rxjsOf"
          tabindex="0"
          (copy)="copyToClipboard(node.data.node)"
          (contextmenu)="onContextMenu(node, $event)"
          (click)="showAndSelectCube(node)"
          class="p-1 flex node-item"
        >
          <span *cdkDragPlaceholder></span>
          <selfai-platform-cube-dnd-placeholder
            *cdkDragPreview
            [cubeUiName]="node.label"
            [operationName]="node.data.node.operation?.name"
          ></selfai-platform-cube-dnd-placeholder>
          <span>{{ node.label }}</span>
        </div>
      </div>
    </ng-template>
  </ng-template>
  <ng-template [pTemplate]="nodeType.LOADING"><div class="p-1">Loading...</div></ng-template>
  <ng-template [pTemplate]="nodeType.EMPTY"><div class="p-1">Nodes not found</div></ng-template>
</p-tree>

<ng-template #emptyList>
  <p>Workflows not loaded</p>
</ng-template>

<p-contextMenu appendTo="body"></p-contextMenu>
<p-confirmPopup appendTo="body"></p-confirmPopup>
