import { KeycloakConfigService } from '@selfai-platform/shared';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';

export function initializeKeycloak(
  keycloak: KeycloakService,
  keycloakConfigService: KeycloakConfigService,
): Promise<boolean> {
  const keycloakConfig = keycloakConfigService.getConfig();

  keycloak.keycloakEvents$.subscribe(({ type }) => {
    if (type === KeycloakEventType.OnAuthRefreshError || type === KeycloakEventType.OnAuthLogout) {
      keycloak.getKeycloakInstance().login({
        redirectUri: window.location.href,
      });
    }
  });

  return keycloak.init({
    config: keycloakConfig,
    initOptions: {
      checkLoginIframe: false,
      redirectUri: window.location.href,
    },
    enableBearerInterceptor: true,
    loadUserProfileAtStartUp: true,
  });
}
