import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { DragulaModule } from 'ng2-dragula';

import { BiChartEngineModule } from '@selfai-platform/bi-chart-engine';

import { CommonModule } from '../common/common.module';
import { DataPreviewModule } from '../common/data.preview.module';
import { DashboardShareModule } from '../dashboard/dashboard-share.module';
import { StorageService } from '../data-storage/service/storage.service';
import { TimezoneService } from '../data-storage/service/timezone.service';
import { DataconnectionService } from '../dataconnection/service/dataconnection.service';

import { AnalysisModule } from './component/analysis/analysis.module';
import { AnalysisPredictionService } from './component/analysis/service/analysis.prediction.service';
import { PageShareModule } from './page-share.module';
import { PageViewComponent } from './page-view.component';

const pageRoutes: Routes = [
  {
    path: '',
    component: PageViewComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    DragulaModule,
    BiChartEngineModule,
    DashboardShareModule,
    DataPreviewModule,
    AnalysisModule,
    PageShareModule,
    TranslateModule,
    RouterModule.forChild(pageRoutes),
  ],
  declarations: [PageViewComponent],
  exports: [],
  providers: [StorageService, TimezoneService, DataconnectionService, AnalysisPredictionService],
})
export class PageModule {}
