import { Component, ElementRef, EventEmitter, Injector, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { RoleSet } from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { PermissionService } from '../../../user/service/permission.service';

import { PermissionSchemaComponent } from './permission-schema.component';

@Component({
  selector: 'app-permission-schema-set',
  templateUrl: './permission-schema-set.component.html',
})
export class PermissionSchemaSetComponent extends AbstractComponent implements OnInit, OnDestroy {
  @ViewChild(PermissionSchemaComponent)
  private _permSchemaComp: PermissionSchemaComponent;

  @Output()
  public afterUpdate: EventEmitter<RoleSet> = new EventEmitter();

  public isShow = false;

  public roleSet: RoleSet;

  public useAPI = true;

  public editMode = true;

  constructor(
    protected permissionService: PermissionService,
    protected element: ElementRef,
    protected injector: Injector,
  ) {
    super(element, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public init(initialData: RoleSet, editMode: boolean = true, useAPI: boolean = true) {
    $('body').removeClass('body-hidden').addClass('body-hidden');
    this.roleSet = initialData;
    this.editMode = editMode;
    this.useAPI = useAPI;
    this.isShow = true;
  }

  public close() {
    $('body').removeClass('body-hidden');
    this.isShow = false;
  }

  public done() {
    this.loadingShow();
    this._permSchemaComp.setRoleSets().then((result) => {
      this.loadingHide();
      this.afterUpdate.emit(result);
      this.close();
    });
  }
}
