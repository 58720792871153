import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { WorkflowAddDialogComponent } from './workflow-add-dialog.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, ButtonModule, InputTextModule, AutoFocusModule],
  declarations: [WorkflowAddDialogComponent],
  exports: [WorkflowAddDialogComponent],
})
export class WorkflowAddDialogComponentModule {}
