import { FactoryProvider, ValueProvider } from '@angular/core';
import {
  provideBiAdmistrationMenuChunk,
  provideBiMainMenuChunk,
  providesKnowledgeDesignerMainMenuChunk,
} from '@selfai-platform/bi';
import { provideAggregationMainMenuChunk, provideWorkflowMainMenuChunk } from '@selfai-platform/pipeline-module';
import { provideMainMenuChunk } from '@selfai-platform/shell';

export const mainMenuProviders: (FactoryProvider | ValueProvider)[] = [
  provideBiMainMenuChunk(),
  providesKnowledgeDesignerMainMenuChunk(),
  provideAggregationMainMenuChunk(),
  provideWorkflowMainMenuChunk(),
  provideBiAdmistrationMenuChunk(),
  provideMainMenuChunk({
    id: 'hui',
    label: 'shell.menu.interface-designer',
    icon: 'pi pi-th-large',
    url: 'https://app-builder.selfai-dev1.cognive.com',
    target: '_blank',
    state: {
      sort: 550,
    },
  }),
  provideMainMenuChunk({
    id: 'apps',
    label: 'shell.menu.apps',
    icon: 'pi pi-th-large',
    state: {
      sort: 600,
    },
    items: [
      {
        label: 'shell.menu.kyc',
        icon: 'pi pi-id-card',
        url: 'https://kyc.selfai-dev1.cognive.com/',
      },
    ],
  }),
];
