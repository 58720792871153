import { isNullOrUndefined } from 'util';

import { ElementRef, Injector, Input, OnDestroy, OnInit, Directive } from '@angular/core';

import { LayoutMode, Widget } from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../common/component/abstract.component';
import { EventBroadcaster } from '../../common/event/event.broadcaster';

@Directive()
export abstract class AbstractWidgetComponent extends AbstractComponent implements OnInit, OnDestroy {
  public widget: Widget;

  public isEditMode = false;
  public isViewMode = false;
  public isAuthMgmtViewMode = false;

  public isVisibleScrollbar = false;

  public isMissingDataSource = false;
  public isError = false;
  public errorInfo: { show?: boolean; code?: string; details?: string };

  @Input()
  public layoutMode: LayoutMode;

  @Input()
  public isShowTitle = false;

  protected constructor(
    protected broadCaster: EventBroadcaster,
    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngAfterViewInit() {
    this.isViewMode =
      this.layoutMode === LayoutMode.VIEW ||
      this.layoutMode === LayoutMode.VIEW_AUTH_MGMT ||
      this.layoutMode === LayoutMode.STANDALONE;
    this.isAuthMgmtViewMode = this.layoutMode === LayoutMode.VIEW_AUTH_MGMT;
    this.isEditMode = this.layoutMode === LayoutMode.EDIT;
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public editWidget() {
    if (this.isMissingDataSource) {
      this.alertPrimeService.warn(this.translateService.instant('msg.board.alert.can-not-edit-missing-datasource'));
      return;
    }

    this.broadCaster.broadcast('MOVE_EDIT_WIDGET', {
      cmd: 'MODIFY',
      id: this.widget.id,
      type: this.widget.type.toUpperCase(),
    });
  }

  public processStart() {
    this.broadCaster.broadcast('START_PROCESS', { widgetId: this.widget.id });
  }

  public processEnd() {
    this.broadCaster.broadcast('STOP_PROCESS', { widgetId: this.widget.id });
  }

  protected _showError(error: { show?: boolean; code?: string; details?: string }) {
    if (this.isEditMode) {
      this.commonExceptionHandler(error);
    } else {
      (isNullOrUndefined(error) || typeof error != 'object') && (error = {});
      error.show = false;
      this.errorInfo = error;
    }
    this.isError = true;
  }

  protected _hideError() {
    this.errorInfo = undefined;
    this.isError = false;
  }
}
