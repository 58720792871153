import { FactoryProvider, Injector, ValueProvider } from '@angular/core';

import { MenuItem } from 'primeng/api';

import {
  BiPermission,
  KdConfigService,
  KdFeatureEnableService,
  KdFeatureName,
  PermissionService,
} from '@selfai-platform/shared';
import { provideMainMenuChunkFactory } from '@selfai-platform/shell';

import { BI_ROOT_ROUTE } from '../core';

export function provideBiAdmistrationMenuChunk(): FactoryProvider | ValueProvider {
  return provideMainMenuChunkFactory((injector: Injector) => {
    const biRootRoute = injector.get(BI_ROOT_ROUTE);
    const kdConfigService = injector.get(KdConfigService);
    const kdEnbaledFeatureService = injector.get(KdFeatureEnableService);
    const permissionService = injector.get(PermissionService);

    if (!kdConfigService.getConfig()?.enabled) {
      return null;
    }

    const biPermissions = permissionService.getBiPermissions();
    const items: MenuItem[] = [];

    return {
      id: 'administration',
      label: 'shell.menu.monitoring',
      state: { sort: 500 },
      items: [
        {
          label: 'shell.menu.monitoring-data',
          icon: 'pi pi-database',
          routerLink: [biRootRoute, 'management', 'monitoring'],
        },
        {
          label: 'shell.menu.engine-monitoring',
          icon: 'pi pi-server',
          routerLink: [biRootRoute, 'management', 'engine-monitoring'],
        },
        {
          label: 'shell.menu.explore',
          icon: 'pi pi-globe',
          routerLink: [biRootRoute, 'exploredata'],
        },
      ],
    };
  });
}
