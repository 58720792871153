import { FactoryProvider, Injector, ValueProvider } from '@angular/core';

import { MenuItem } from 'primeng/api';

import {
  BiPermission,
  KdConfigService,
  KdFeatureEnableService,
  KdFeatureName,
  PermissionService,
} from '@selfai-platform/shared';
import { provideMainMenuChunkFactory } from '@selfai-platform/shell';

import { BI_ROOT_ROUTE } from '../core';

export function provideBiMainMenuChunk(): FactoryProvider | ValueProvider {
  return provideMainMenuChunkFactory((injector: Injector) => {
    const biRootRoute = injector.get(BI_ROOT_ROUTE);
    const kdConfigService = injector.get(KdConfigService);
    const kdEnbaledFeatureService = injector.get(KdFeatureEnableService);
    const permissionService = injector.get(PermissionService);

    if (!kdConfigService.getConfig()?.enabled) {
      return null;
    }

    const biPermissions = permissionService.getBiPermissions();
    const items: MenuItem[] = [];

    if (
      kdEnbaledFeatureService.isEnabledFeature(KdFeatureName.DATASETS) &&
      biPermissions.includes(BiPermission.PERM_SELFAI_DATASET_VIEW)
    ) {
      items.push({
        label: 'shell.menu.datasets',
        icon: 'pi pi-list',
        routerLink: [biRootRoute, 'management', 'datapreparation', 'dataset'],
      });
    }

    if (
      kdEnbaledFeatureService.isEnabledFeature(KdFeatureName.DATAFLOWS) &&
      biPermissions.includes(BiPermission.PERM_SELFAI_DATAFLOW_VIEW)
    ) {
      items.push({
        label: 'shell.menu.dataflows',
        icon: 'pi pi-chart-bar',
        routerLink: [biRootRoute, 'management', 'datapreparation', 'dataflow'],
      });
    }

    if (
      kdEnbaledFeatureService.isEnabledFeature(KdFeatureName.DATASOURCES) &&
      biPermissions.includes(BiPermission.PERM_SELFAI_DATASOURCE_VIEW)
    ) {
      items.push({
        label: 'shell.menu.datasources',
        icon: 'pi pi-database',
        routerLink: [biRootRoute, 'management', 'storage', 'datasource'],
      });
    }

    if (items.length < 0) {
      return null;
    }

    return {
      id: 'data-prep',
      label: 'shell.menu.datapreparation',
      state: { sort: 400 },
      items,
    };
  });
}
