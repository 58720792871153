import {
  GraphNodeOptions,
  GraphNodeOptionSerialized,
  GraphNodeRemoved,
  TreeNodeCube,
  TreeNodeEmpty,
  TreeNodeOperation,
  TreeNodeRecycleBin,
  TreeNodeType,
  WorkflowSerialized,
} from '@selfai-platform/pipeline-common';

export function mapWorkflowToTreeNode(
  wokflow: WorkflowSerialized,
  isIncurrentWorkflow: boolean = false,
): (TreeNodeOperation | TreeNodeEmpty | TreeNodeRecycleBin)[] {
  const recycleBinNode = createRecycleBinNode(wokflow.thirdPartyData.removedNodes || [], isIncurrentWorkflow)

  if (wokflow.workflow.nodes.length) {
    const tree = mapNodesToTree(wokflow.workflow.nodes, isIncurrentWorkflow);
    tree.push(recycleBinNode);

    return tree;
  } else {

    return [recycleBinNode];

  }
}

function createRecycleBinNode(removedNodes: GraphNodeRemoved[], isIncurrentWorkflow: boolean): TreeNodeRecycleBin {
  return {
    label: 'Recycle Bin',
    data: {},
    leaf: false,
    type: TreeNodeType.RECYCLEBIN,
    selectable: false,
    draggable: false,
    children: removedNodes.map((node) => ({
      label: node.uiName || node.operation?.name || 'Unknown name',
      data: {
        node: node,
        jsonParameters: JSON.stringify(node.parameters),
        isInCurrentWorkflow: isIncurrentWorkflow,
      },
      leaf: true,
      type: TreeNodeType.REMOVEDCUBE,
      selectable: true,
      draggable: true,
    })),
  };
}

function mapNodesToTree(nodes: GraphNodeOptionSerialized[], isIncurrentWorkflow: boolean) {
  return nodes.reduce<(TreeNodeOperation | TreeNodeEmpty | TreeNodeRecycleBin)[]>((result, nodeOptions) => {
    if (nodeOptions.operation) {
      const nodeSameType: TreeNodeOperation | undefined = result.find(
        ({ data }) =>
          nodeOptions.operation.id === data?.operation?.id || nodeOptions.operation.name === data?.operation?.name,
      ) as TreeNodeOperation | undefined;

      const cubeNode: TreeNodeCube = {
        label: nodeOptions.uiName || nodeOptions.operation?.name || 'Unknown name',
        data: {
          node: nodeOptions,
          jsonParameters: JSON.stringify(nodeOptions.parameters),
          isInCurrentWorkflow: isIncurrentWorkflow,
        },
        leaf: true,
        type: TreeNodeType.CUBE,
        selectable: true,
        draggable: true,
      };

      if (nodeSameType) {
        nodeSameType.children?.push(cubeNode);
      } else {
        result.push({
          label: nodeOptions.operation.name,
          data: { operation: nodeOptions.operation },
          leaf: true,
          type: TreeNodeType.OPERATION,
          selectable: false,
          draggable: false,
          children: [cubeNode],
        });
      }
    }

    return result;
  }, []);
}
