import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CommentComponent } from './comment.component';

@NgModule({
  imports: [CommonModule, InputTextareaModule, FormsModule, ButtonModule],
  declarations: [CommentComponent],
  exports: [CommentComponent],
})
export class CommentComponentModule {}
