import { ChangeDetectionStrategy, Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { CubeState } from '@selfai-platform/pipeline-common';
import { DialogHelperService } from '../../../dialog';

import { DialogHeaderComponentService } from './dialog-header-component.service';

@Component({
  selector: 'selfai-platform-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogHeaderComponentService],
})
export class DialogHeaderComponent {
  @Input() header!: string;

  @Output() closeDialog = new EventEmitter<never>();
  @Output() clickSave = new EventEmitter<never>();

  get cubeState(): CubeState | undefined {
    return this.dialogHeaderComponentService.getCubeState();
  }

  constructor(
    private readonly dialogHeaderComponentService: DialogHeaderComponentService,
    @Optional() public readonly dialogHelperService?: DialogHelperService,
  ) {}

  maximizeDialog(): void {
    this.dialogHelperService?.maximizeDialog();
  }
}
