import { Injectable, OnDestroy } from '@angular/core';
import { ItemUsedInNodes, WorkflowSerialized } from '@selfai-platform/pipeline-common';
import { WorkflowStateService } from '@selfai-platform/storage';
import { BehaviorSubject, combineLatest, filter } from 'rxjs';
import { jsonToValues } from '../../../../utils';
import { SearhcRowItem } from './search-row-item';

@Injectable()
export class JsonToColumnTransformationUsedColumnsService
  extends BehaviorSubject<Map<string, ItemUsedInNodes[]>>
  implements OnDestroy
{
  columns = new BehaviorSubject<SearhcRowItem[]>([]);
  cubeID = new BehaviorSubject<string | null>(null);

  get usedInMap() {
    return this.asObservable();
  }

  constructor(private readonly workflowStateService: WorkflowStateService) {
    super(new Map());

    combineLatest([this.workflowStateService.getWorkflowState(), this.columns, this.cubeID])
      .pipe(filter(([workflow, columns, cubeID]) => !!workflow && !!columns && !!cubeID))
      .subscribe((data) => {
        this.calculateColumnsMap(data[0] as WorkflowSerialized, data[1], data[2] as string);
      });
  }

  private calculateColumnsMap(workflow: WorkflowSerialized, columns: SearhcRowItem[], cubeID: string): void {
    const columnsMap = new Map<string, ItemUsedInNodes[]>();
    const cubesForSearch = workflow.workflow.nodes
      .filter((cube) => cube.id !== cubeID)
      .map((cube) => {
        const result = jsonToValues(cube.parameters);
        return {
          id: cube.id,
          name: workflow.thirdPartyData.gui.nodes[cube.id]?.uiName || cube.operation?.name || '',
          searchText: result,
        };
      });
    for (const column of columns) {
      const usedIn: ItemUsedInNodes[] = [];
      cubesForSearch.forEach((cube) => {
        for (let i = 0; i < cube.searchText.length; i++) {
          if (cube.searchText[i].includes(column.columnName)) {
            usedIn.push({
              id: cube.id,
              title: cube.name,
            });
            return;
          }
        }
      });

      if (usedIn.length > 0) {
        columnsMap.set(column.id, usedIn);
      }
    }

    this.next(columnsMap);
  }

  ngOnDestroy(): void {
    this.complete();
  }
}
