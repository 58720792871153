import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { StorageService } from '../../data-storage/service/storage.service';

@Injectable()
export class ConnectionListGuard implements CanActivate {
  constructor(private storageService: StorageService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.storageService
      .setConnectionTypeList()
      .then(() => true)
      .catch(() => true);
  }
}
