export function jsonPathToColumnName(jsonPath: string): string {
    const spliitedPath = jsonPath.split(".");
    return spliitedPath[spliitedPath.length -1];
}

export function jsonPathToColumnNameWithNull(jsonPath: string | null | undefined): string | null {
    if(!jsonPath) {
        return null;
    }
    return jsonPathToColumnName(jsonPath);
}