import {
  Component,
  ElementRef,
  Injector,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import * as _ from 'lodash';

import { ColorOptionConverter, FormatOptionConverter, OptionGenerator } from '@selfai-platform/bi-chart-engine';
import {
  CellColorTarget,
  ChartColorList,
  ChartColorType,
  ChartType,
  ColorCustomMode,
  ColorRange,
  ColorRangeType,
  ShelveFieldType,
  UIChartColor,
  UIChartColorByDimension,
  UIChartColorBySeries,
  UIChartColorByValue,
  UIOption,
  Pivot,
  DashboardField as Field,
} from '@selfai-platform/bi-domain';

import { ColorPickerComponent } from '../../../common/component/color-picker/color.picker.component';
import { GradationGeneratorComponent } from '../../../common/component/gradation/gradation-generator.component';
import { RangeSliderComponent } from '../../component/analysis/slider/range-slider.component';
import { BaseOptionComponent } from '../base-option.component';

import UI = OptionGenerator.UI;

const colorTypeList: Object[] = [
  { label: 'None', id: ChartColorType.SINGLE },
  { label: 'Series', id: ChartColorType.SERIES },
  { label: 'Dimension', id: ChartColorType.DIMENSION },
  { label: 'Measure', id: ChartColorType.MEASURE },
];

@Component({
  selector: 'color-option',
  templateUrl: './color-option.component.html',
  styles: ['.sys-inverted {transform: scaleX(-1);}'],
})
export class ColorOptionComponent extends BaseOptionComponent implements OnInit, OnDestroy {
  @ViewChildren('colorChartSlider')
  public colorRangeComp: QueryList<RangeSliderComponent>;

  @ViewChild('colorPicker')
  public colorPicker: ColorPickerComponent;

  @ViewChild(GradationGeneratorComponent)
  public gradationComp: GradationGeneratorComponent;

  @Input() public pivot: Pivot;

  @Input('resultData')
  public set setResultData(resultData: Object) {
    this.resultData = resultData;
    if (resultData && resultData['data'] && resultData['data']['info'] && this.uiOption) {
      const tmpInfo = resultData['data']['info'];
      const tmpValFormat = this.uiOption.valueFormat;
      const minValue = this.checkMinZero(tmpInfo['minValue'], tmpInfo['minValue']);
      this.minValue = FormatOptionConverter.getDecimalValue(
        minValue,
        tmpValFormat.decimal,
        tmpValFormat.useThousandsSep,
      );
      this.maxValue = FormatOptionConverter.getDecimalValue(
        tmpInfo['maxValue'],
        tmpValFormat.decimal,
        tmpValFormat.useThousandsSep,
      );
    }
  }

  @Input('uiOption')
  public set setUiOption(uiOption: UIOption) {
    this.uiOption = uiOption;

    if (!this.uiOption.fieldList || 0 == this.uiOption.fieldList.length) {
      this.uiOption.fieldList = this.setFieldList();
    }

    if (!this.uiOption.color['ranges'] && ChartColorType.MEASURE == this.uiOption.color.type) {
      const colorList = <any>ChartColorList[this.uiOption.color['schema']];

      this.uiOption.color['ranges'] = ColorOptionConverter.setMeasureColorRange(
        this.uiOption,
        this.resultData['data'],
        colorList,
      );

      this.resultData['type'] = null;
    } else if (
      this.uiOption.color['customMode'] &&
      ChartColorType.MEASURE == this.uiOption.color.type &&
      ColorCustomMode.GRADIENT == this.uiOption.color['customMode']
    ) {
      this.changeDetect.detectChanges();

      if (!this.gradationComp.gradxObj) {
        const obj = this.gradationInit(this.uiOption.color['ranges'], true);

        if (obj) {
          this.uiOption.color['ranges'] = obj['ranges'];

          this.rangesViewList = this.uiOption.color['ranges'];
          this.uiOption.color['visualGradations'] = obj['visualGradations'];
        }
      }
    }

    setTimeout(() => {
      this.rangesViewList = this.setRangeViewByDecimal(this.uiOption.color['ranges']);
    }, 100);

    if (this.uiOption.valueFormat && undefined !== this.uiOption.valueFormat.decimal) {
      const minValue = this.checkMinZero(this.uiOption.minValue, this.uiOption.minValue);

      this.minValue = FormatOptionConverter.getDecimalValue(
        minValue,
        this.uiOption.valueFormat.decimal,
        this.uiOption.valueFormat.useThousandsSep,
      );
      this.maxValue = FormatOptionConverter.getDecimalValue(
        this.uiOption.maxValue,
        this.uiOption.valueFormat.decimal,
        this.uiOption.valueFormat.useThousandsSep,
      );
    }

    this.$colorPickerPopup = $('#colorPanelColorPicker');
  }

  public measureText: string;
  public defaultColorList: Object[] = [
    { index: 1, colorNum: 'SC1' },
    { index: 2, colorNum: 'SC2' },
    { index: 3, colorNum: 'SC3' },
    { index: 4, colorNum: 'SC4' },
    { index: 5, colorNum: 'SC5' },
    { index: 6, colorNum: 'SC6' },
    { index: 7, colorNum: 'SC7' },
    { index: 8, colorNum: 'SC8' },
    { index: 9, colorNum: 'SC9' },
  ];
  public measureColorList: Object[] = [
    { index: 1, colorNum: 'VC1' },
    { index: 2, colorNum: 'VC2' },
    { index: 3, colorNum: 'VC3' },
    { index: 4, colorNum: 'VC4' },
    { index: 5, colorNum: 'VC5' },
    { index: 6, colorNum: 'VC6' },
    { index: 7, colorNum: 'VC7' },
  ];
  public measureReverseColorList: Object[] = [
    { index: 8, colorNum: 'VC8' },
    { index: 9, colorNum: 'VC9' },
    { index: 10, colorNum: 'VC10' },
    { index: 11, colorNum: 'VC11' },
    { index: 12, colorNum: 'VC12' },
    { index: 13, colorNum: 'VC13' },
    { index: 14, colorNum: 'VC14' },
    { index: 15, colorNum: 'VC15' },
    { index: 16, colorNum: 'VC16' },
    { index: 17, colorNum: 'VC17' },
    { index: 18, colorNum: 'VC18' },
    { index: 19, colorNum: 'VC19' },
  ];
  public selectedDefaultColor: Object = this.defaultColorList[0];
  public selectedMeasureColor: Object = this.measureColorList[0];
  public colorListFlag = false;
  public colorTypeFlag = false;
  public currentRange: ColorRange;
  public colorRangeTypeList = [
    {
      name: this.translateService.instant('msg.page.chart.color.measure.new.range.type.default'),
      value: ColorCustomMode.SECTION,
    },
    {
      name: this.translateService.instant('msg.page.chart.color.measure.new.range.type.gradient'),
      value: ColorCustomMode.GRADIENT,
    },
  ];
  public minValue: string;
  public maxValue: string;
  public availableRangeValue: string;
  public separateValue = 10;
  public rangesViewList = [];
  public resultData: Object;
  public isTemplateColorInverted: boolean = undefined;

  private gradationIndex: number;
  private $colorPickerPopup: JQuery;

  constructor(protected elementRef: ElementRef, protected injector: Injector, private zone: NgZone) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public toggleColorList() {
    this.colorListFlag = !this.colorListFlag;
  }

  public changeColorType(typeId: string) {
    (<UIChartColorBySeries>this.uiOption.color).settingUseFl = false;

    const selectedColorType = _.find(colorTypeList, (item) => {
      return item['id'] === typeId;
    });

    let schema;

    if (JSON.stringify(this.uiOption.color.type['id']) !== JSON.stringify(selectedColorType['id'])) {
      schema =
        ChartColorType.MEASURE === selectedColorType['id']
          ? this.measureColorList[0]['colorNum']
          : this.defaultColorList[0]['colorNum'];

      if (ChartColorType.MEASURE.toString() == typeId) {
        this.uiOption.color['ranges'] = ColorOptionConverter.setMeasureColorRange(
          this.uiOption,
          this.resultData['data'],
          <any>ChartColorList[schema],
        );
      } else if (ChartColorType.SERIES.toString() == typeId) {
        this.uiOption.color = this.setMapping();
      }

      if (_.eq(this.uiOption.type, ChartType.GRID)) {
        schema = ChartColorType.MEASURE === selectedColorType['id'] ? this.measureColorList[0]['colorNum'] : [];

        if (_.isUndefined(this.uiOption.color['colorTarget'])) {
          this.uiOption.color['colorTarget'] = CellColorTarget.TEXT;
        }
      }
    } else {
      schema = (<UIChartColorByDimension>this.uiOption.color).schema;
    }

    const colorOption = {
      type: selectedColorType['id'],
      showFl: this.uiOption.color['showFl'],
    };

    if ('single' == typeId) {
      colorOption['code'] = '';
    } else {
      colorOption['schema'] = schema;
      colorOption['colorTarget'] = this.uiOption.color['colorTarget'];

      if ('series' == typeId) {
        colorOption['mapping'] = this.uiOption.color['mapping'];
        colorOption['mappingArray'] = this.uiOption.color['mappingArray'];
      } else if ('measure' == typeId) {
        colorOption['ranges'] = this.uiOption.color['ranges'];
      }
    }

    if (typeId == ChartColorType.DIMENSION.toString()) {
      colorOption['targetField'] = _.last(this.uiOption.fieldList);

      if (ChartType.GAUGE == this.uiOption.type) {
        this.uiOption.legend.auto = false;
      }
    }

    this.uiOption = <UIOption>_.extend({}, this.uiOption, {
      color: colorOption,
      legend: this.uiOption.legend,
    });

    this.update();
  }

  public colorByDimension(field: Field): void {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, {
      color: {
        type: ChartColorType.DIMENSION,
        schema: (<UIChartColorByDimension>this.uiOption.color).schema,
        targetField: !field ? _.last(this.uiOption.fieldList) : field.name,
        showFl: this.uiOption.color['showFl'],
      },
    });

    this.update();
  }

  public changeColor(colorObj: Object, gridColorObj?: Object) {
    let color = _.cloneDeep(colorObj);
    if ($('input#invertColor').is(':checked')) {
      color['colorNum'] = 'R' + color['colorNum'];
    }

    if (ChartColorType.MEASURE === this.uiOption.color.type) {
      this.uiOption.color['ranges'] = ColorOptionConverter.setMeasureColorRange(
        this.uiOption,
        this.resultData['data'],
        ChartColorList[color['colorNum']],
      );

      if (_.eq(this.uiOption.type, ChartType.GRID)) {
        this.selectedMeasureColor = color;
        const gridColor = _.cloneDeep(gridColorObj);
        if ($('input#invertColor').is(':checked')) {
          gridColor['colorNum'] = 'R' + gridColor['colorNum'];
        }
        color = gridColor;
      }
    } else {
      this.selectedDefaultColor = color;
    }

    this.setUserCodes(color);

    this.uiOption = <UIOption>_.extend({}, this.uiOption, {
      color: {
        type: this.uiOption.color.type,
        schema: color['colorNum'],
        ranges: this.uiOption.color['ranges'],
        targetField: this.uiOption.color['targetField'],
        colorTarget: this.uiOption.color['colorTarget'],
        settingUseFl: (<UIChartColorBySeries>this.uiOption.color).settingUseFl,
        showFl: this.uiOption.color['showFl'],
        mapping: (<UIChartColorBySeries>this.uiOption.color).mapping,
        mappingArray: (<UIChartColorBySeries>this.uiOption.color).mappingArray,
      },
    });

    this.update();
  }

  public invertColor() {
    event.stopPropagation();

    if ($('input#invertColor').is(':checked')) {
      this.isTemplateColorInverted = true;
    } else {
      this.isTemplateColorInverted = false;
    }

    let colorList: Object[] = [];

    colorList = colorList.concat(this.measureColorList);
    colorList = colorList.concat(this.measureReverseColorList);

    for (const item of colorList) {
      if (this.uiOption.color['schema'].endsWith(item['colorNum'])) {
        this.changeColor(item, item);
      }
    }
  }

  public isChartColorInverted() {
    return this.uiOption.color['schema'].indexOf('R') === 0;
  }

  public isChartColorSelected(item) {
    return this.uiOption.color['schema'].endsWith(item['colorNum']);
  }

  public checkDefaultSelectedColor(): number {
    for (const item of this.defaultColorList) {
      if (JSON.stringify(this.uiOption.color['schema']) === JSON.stringify(item['colorNum'])) {
        return item['index'];
      }
    }
  }

  public checkMeasureSelectedColor(): any {
    let colorList: Object[] = [];

    colorList = colorList.concat(this.measureColorList);
    colorList = colorList.concat(this.measureReverseColorList);

    for (const item of colorList) {
      if (this.uiOption.color['schema'].endsWith(item['colorNum'])) {
        return item['index'];
      }
    }
  }

  public lineChartCheck(chartType: string): boolean {
    return true;
  }

  public showUserColorSet() {
    const colorObj: UIChartColorBySeries = <UIChartColorBySeries>this.uiOption.color;

    colorObj.settingUseFl = !colorObj.settingUseFl;
  }

  public resetUserColorSet(item: any, index: number) {
    event.stopPropagation();

    const colorList = ChartColorList[(<UIChartColorBySeries>this.uiOption.color).schema];

    (<UIChartColorBySeries>this.uiOption.color).mapping[item.alias] = colorList[index];
    (<UIChartColorBySeries>this.uiOption.color).mappingArray[index]['color'] = colorList[index];

    this.uiOption = <UIOption>_.extend({}, this.uiOption, {
      color: {
        type: this.uiOption.color.type,
        schema: (<UIChartColorBySeries>this.uiOption.color).schema,
        mapping: (<UIChartColorBySeries>this.uiOption.color).mapping,
        mappingArray: (<UIChartColorBySeries>this.uiOption.color).mappingArray,
        settingUseFl: (<UIChartColorBySeries>this.uiOption.color).settingUseFl,
      },
    });

    this.update();
  }

  public colorPaletteSelected(colorCode: string, item?: any) {
    if (this.uiOption.color.type == ChartColorType.SERIES) {
      const index = _.findIndex(this.uiOption.fieldMeasureList, { alias: item.alias });

      const color = ChartColorList[(<UIChartColorBySeries>this.uiOption.color).schema];

      if (index !== -1) {
        if (!(<UIChartColorBySeries>this.uiOption.color).mapping) {
          (<UIChartColorBySeries>this.uiOption.color).mapping = _.cloneDeep(color);
        }

        (<UIChartColorBySeries>this.uiOption.color).mappingArray[index]['color'] = colorCode;

        (<UIChartColorBySeries>this.uiOption.color).mapping[
          (<UIChartColorBySeries>this.uiOption.color).mappingArray[index]['alias']
        ] = colorCode;

        if (_.eq(this.uiOption.type, ChartType.GRID)) {
          if (_.isUndefined(this.uiOption.color['colorTarget'])) {
            this.uiOption.color['colorTarget'] = CellColorTarget.TEXT;
          }
        }

        this.uiOption = <UIOption>_.extend({}, this.uiOption, {
          color: {
            type: this.uiOption.color.type,
            mapping: (<UIChartColorBySeries>this.uiOption.color).mapping,
            mappingArray: (<UIChartColorBySeries>this.uiOption.color).mappingArray,
            schema: (<UIChartColorBySeries>this.uiOption.color).schema,
            settingUseFl: (<UIChartColorBySeries>this.uiOption.color).settingUseFl,
            colorTarget: this.uiOption.color['colorTarget'],
          },
        });

        this.update();
      }
    } else if (this.uiOption.color.type == ChartColorType.MEASURE) {
      const index = this.rangesViewList.findIndex((rangeItem) => rangeItem.color === item.color);

      (<UIChartColorByValue>this.uiOption.color).ranges[index].color = colorCode;

      if (_.eq(this.uiOption.type, ChartType.GRID)) {
        if (_.isUndefined(this.uiOption.color['colorTarget'])) {
          this.uiOption.color['colorTarget'] = CellColorTarget.TEXT;
        }
      }

      this.uiOption = <UIOption>_.extend({}, this.uiOption, {
        color: {
          type: this.uiOption.color.type,
          schema: (<UIChartColorBySeries>this.uiOption.color).schema,
          ranges: (<UIChartColorByValue>this.uiOption.color).ranges,
          customMode: (<UIChartColorByValue>this.uiOption.color).customMode,
          colorTarget: this.uiOption.color['colorTarget'],
        },
      });

      this.currentRange = null;

      this.update();
    }
  }

  public addNewRange(index: number) {
    this.removeInputRangeStatus();

    const rangeList = (<UIChartColorByValue>this.uiOption.color).ranges;

    const uiMinValue =
      this.uiOption.minValue >= 0
        ? 0
        : Math.floor(Number(this.uiOption.minValue) * Math.pow(10, this.uiOption.valueFormat.decimal)) /
          Math.pow(10, this.uiOption.valueFormat.decimal);

    const maxValue = rangeList[index - 1].gt;
    let minValue = rangeList[index].gt ? rangeList[index].gt : uiMinValue;

    minValue = minValue + (maxValue - minValue) / 2;

    const formatMinValue =
      Math.floor(Number(minValue) * Math.pow(10, this.uiOption.valueFormat.decimal)) /
      Math.pow(10, this.uiOption.valueFormat.decimal);
    const formatMaxValue =
      Math.floor(Number(maxValue) * Math.pow(10, this.uiOption.valueFormat.decimal)) /
      Math.pow(10, this.uiOption.valueFormat.decimal);

    rangeList[index].lte = formatMinValue;
    rangeList[index].fixMax = formatMinValue;

    const currentColor = rangeList[index].color;

    rangeList.splice(
      index,
      0,
      UI.Range.colorRange(
        ColorRangeType.SECTION,
        currentColor,
        formatMinValue,
        formatMaxValue,
        formatMinValue,
        formatMaxValue,
      ),
    );

    this.uiOption = <UIOption>_.extend({}, this.uiOption, {
      color: {
        type: this.uiOption.color.type,
        schema: (<UIChartColorByValue>this.uiOption.color).schema,
        ranges: rangeList,
        customMode: (<UIChartColorByValue>this.uiOption.color).customMode,
        colorTarget: this.uiOption.color['colorTarget'],
      },
    });

    this.update();
  }

  public changeColorRange() {
    let colorOption = <any>this.uiOption.color;

    if (!this.uiOption.color['customMode']) {
      colorOption['customMode'] = ColorCustomMode.SECTION;

      if (!(<UIChartColorByValue>this.uiOption.color).ranges) {
        const ranges = ColorOptionConverter.setMeasureColorRange(
          this.uiOption,
          this.resultData['data'],
          ChartColorList[this.uiOption.color['schema']],
        );

        colorOption = {
          type: this.uiOption.color.type,
          schema: (<UIChartColorBySeries>this.uiOption.color).schema,
          customMode: (<UIChartColorByValue>this.uiOption.color).customMode,
          ranges: ranges,
        };
      }
    } else {
      const ranges = ColorOptionConverter.setMeasureColorRange(
        this.uiOption,
        this.resultData['data'],
        <any>ChartColorList[(<UIChartColorBySeries>this.uiOption.color).schema],
      );

      colorOption = {
        type: this.uiOption.color.type,
        schema: (<UIChartColorBySeries>this.uiOption.color).schema,
        ranges: ranges,
      };
    }

    if (_.eq(this.uiOption.type, ChartType.GRID)) {
      if (_.isUndefined(this.uiOption.color['colorTarget'])) {
        colorOption['colorTarget'] = CellColorTarget.TEXT;
      } else {
        colorOption['colorTarget'] = this.uiOption.color['colorTarget'];
      }
    }

    this.uiOption = <UIOption>_.extend({}, this.uiOption, {
      color: colorOption,
    });

    this.update();
  }

  public removeColorRange(range: ColorRange, index: number) {
    const rangeList = (<UIChartColorByValue>this.uiOption.color).ranges;

    if (1 == rangeList.length) return;

    const upperValue = rangeList[index - 1] ? rangeList[index - 1] : null;
    const lowerValue = rangeList[index + 1] ? rangeList[index + 1] : null;

    if (upperValue && lowerValue) {
      const upperMaxValue = rangeList[index - 1].lte ? rangeList[index - 1].lte : rangeList[index - 1].gt;

      const lowerMinValue = rangeList[index + 1].gt ? rangeList[index + 1].gt : rangeList[index + 1].lte;

      const autoChangeValue =
        Math.floor(Number((upperMaxValue + lowerMinValue) / 2) * Math.pow(10, this.uiOption.valueFormat.decimal)) /
        Math.pow(10, this.uiOption.valueFormat.decimal);

      rangeList[index - 1].gt = autoChangeValue;
      rangeList[index - 1].fixMin = autoChangeValue;

      rangeList[index + 1].lte = autoChangeValue;
      rangeList[index + 1].fixMax = autoChangeValue;
    }

    rangeList.splice(index, 1);

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { color: this.uiOption.color });

    this.update();
  }

  public selectColorRangeType(customMode: Object): void {
    if (this.uiOption.color['customMode'] == customMode['value']) return;

    this.uiOption.color['customMode'] = customMode['value'];

    if (ColorCustomMode.GRADIENT == customMode['value']) {
      delete this.uiOption.color['ranges'];
      delete this.uiOption.color['visualGradations'];

      const obj = this.gradationInit(this.uiOption.color['ranges'], true);

      this.uiOption.color['ranges'] = obj['ranges'];
      this.rangesViewList = this.uiOption.color['ranges'];
      this.uiOption.color['visualGradations'] = obj['visualGradations'];
    } else if (ColorCustomMode.SECTION == customMode['value']) {
      delete this.uiOption.color['ranges'];
      delete this.uiOption.color['visualGradations'];

      this.uiOption.color['ranges'] = ColorOptionConverter.setMeasureColorRange(
        this.uiOption,
        this.resultData['data'],
        <any>ChartColorList[this.uiOption.color['schema']],
      );
    }

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { color: this.uiOption.color });

    this.update();
  }

  private gradationInit(gradations: Object[], initFl?: boolean): Object {
    const colorList = ChartColorList[this.uiOption.color['schema']];

    const minValue = this.checkMinZero(this.uiOption.minValue, parseInt(this.uiOption.minValue.toFixed(0)));
    const maxValue = parseInt(this.uiOption.maxValue.toFixed(0));

    if (!gradations || gradations.length == 0) {
      gradations = [
        {
          color: colorList[0],
          position: -4,
          value: minValue,
        },
        {
          color: colorList[colorList.length - 1],
          position: 215,
          value: maxValue,
        },
      ];
    }

    const data: Object = {
      min: minValue,
      max: maxValue,
      separateValue: this.separateValue,
      positionMin: -4,
      positionMax: 215,
    };

    this.changeDetect.detectChanges();

    const obj = this.gradationComp.init(gradations, data, initFl);

    if (initFl)
      obj['ranges'].forEach((item) => {
        if (!item['type']) {
          item['type'] = ColorRangeType.GRADIENT;
          return item;
        } else {
          return item;
        }
      });

    return obj;
  }

  public changeRangeMinInput(range: any, index: number): void {
    const isNumber = range.gt;

    const rangeList = (<UIChartColorByValue>this.uiOption.color).ranges;

    if (!range.gt || isNaN(FormatOptionConverter.getNumberValue(range.gt)) || isNumber == false) {
      range.gt = _.cloneDeep(
        FormatOptionConverter.getDecimalValue(
          rangeList[index].fixMin,
          this.uiOption.valueFormat.decimal,
          this.uiOption.valueFormat.useThousandsSep,
        ),
      );
      return;
    }

    range = this.parseStrFloat(range);

    const decimalValue = this.uiOption.minValue;

    const uiMinValue = this.checkMinZero(this.uiOption.minValue, decimalValue);

    const minValue = rangeList[index + 1]
      ? rangeList[index + 1].gt
        ? rangeList[index + 1].gt
        : uiMinValue
      : rangeList[index].gt
      ? rangeList[index].gt
      : rangeList[index].lte;
    const maxValue = range.lte;

    if (!rangeList[index - 1]) {
      if (this.uiOption.maxValue < range.gt || rangeList[index + 1].fixMax > range.gt) {
        range.gt = range.fixMin;
      } else {
        range.fixMin = range.gt;
      }
    } else if (minValue > range.gt || maxValue < range.gt) {
      range.gt = range.fixMin;
    } else {
      range.fixMin = range.gt;
    }

    if (rangeList[index + 1]) {
      rangeList[index + 1].lte = range.gt;
      rangeList[index + 1].fixMax = range.gt;
    }

    rangeList[index] = range;

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { color: this.uiOption.color });

    this.update();
  }

  public changeRangeMaxInput(range: any, index: number): void {
    const isNumber = range.lte;

    const rangeList = (<UIChartColorByValue>this.uiOption.color).ranges;

    if (!range.lte || isNaN(FormatOptionConverter.getNumberValue(range.lte)) || isNumber == false) {
      range.lte = _.cloneDeep(
        FormatOptionConverter.getDecimalValue(
          rangeList[index].fixMax,
          this.uiOption.valueFormat.decimal,
          this.uiOption.valueFormat.useThousandsSep,
        ),
      );
      return;
    }

    range = this.parseStrFloat(range);

    const uiMinValue = this.checkMinZero(this.uiOption.minValue, this.uiOption.minValue);

    const lowerfixMin = rangeList[index + 1]
      ? rangeList[index + 1].fixMin
        ? rangeList[index + 1].fixMin
        : rangeList[index + 1].fixMax
      : null;

    if (!rangeList[index + 1]) {
      if (uiMinValue < range.lte && rangeList[index - 1].fixMin > range.lte) {
        range.fixMax = range.lte;
        rangeList[index - 1].gt = range.lte;
      } else {
        range.lte = range.fixMax;
      }
    } else if (range.fixMax < range.lte || lowerfixMin > range.lte) {
      range.lte = range.fixMax;
    } else {
      range.fixMax = range.lte;
    }

    if (rangeList[index - 1]) {
      rangeList[index - 1].fixMin = range.lte;
      rangeList[index - 1].gt = range.lte;
    }

    if (null != range.fixMin && rangeList[index + 1] && range.fixMin > range.fixMax) {
      range.gt = range.fixMax;
      rangeList[index + 1].lte = range.fixMax;
      rangeList[index + 1].fixMax = range.fixMax;
    }

    rangeList[index] = range;

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { color: this.uiOption.color });

    this.update();
  }

  public equalColorRange(): void {
    const rangeList = (<UIChartColorByValue>this.uiOption.color).ranges;

    const colorList = <any>_.cloneDeep(ChartColorList[this.uiOption.color['schema']]);

    rangeList.reverse().forEach((item, index) => {
      colorList[index] = item.color;
    });

    this.uiOption.color['ranges'] = ColorOptionConverter.setMeasureColorRange(
      this.uiOption,
      this.resultData['data'],
      colorList,
      rangeList,
    );

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { color: this.uiOption.color });

    this.update();
  }

  public availableRange(currentRnage: any, index: number): void {
    const rangeList = this.rangesViewList;

    let returnString = '';

    if (0 == index) {
      returnString += ': ' + currentRnage.fixMin;
    } else if (rangeList.length - 1 == index) {
      returnString += ': ' + currentRnage.fixMax;
    } else {
      const availableMin = !rangeList[index + 1]
        ? null
        : rangeList[index + 1].fixMin
        ? rangeList[index + 1].fixMin
        : rangeList[index + 1].fixMax;
      const availableMax = currentRnage.fixMax;

      if (null !== availableMin) returnString += ': ' + availableMin.toString() + ' ~ ';
      if (null !== availableMax) returnString += availableMax.toString();
    }

    this.availableRangeValue = returnString;
  }

  public colorGaugePaletteSelected(colorCode: string, item?: any) {
    const index = _.findIndex(this.uiOption.fieldDimensionDataList, (data) => {
      return item.alias == data;
    });

    const color = ChartColorList[(<UIChartColorByDimension>this.uiOption.color).schema];

    if (-1 !== index) {
      if (!(<UIChartColorByDimension>this.uiOption.color).mapping) {
        (<UIChartColorByDimension>this.uiOption.color).mapping = _.cloneDeep(color);
      }

      (<UIChartColorByDimension>this.uiOption.color).mappingArray[index]['color'] = colorCode;

      (<UIChartColorByDimension>this.uiOption.color).mapping[
        (<UIChartColorByDimension>this.uiOption.color).mappingArray[index]['alias']
      ] = colorCode;

      this.uiOption = <UIOption>_.extend({}, this.uiOption, {
        color: {
          type: this.uiOption.color.type,
          mapping: (<UIChartColorByDimension>this.uiOption.color).mapping,
          mappingArray: (<UIChartColorByDimension>this.uiOption.color).mappingArray,
          schema: (<UIChartColorByDimension>this.uiOption.color).schema,
          colorTarget: this.uiOption.color['colorTarget'],
          settingUseFl: (<UIChartColorBySeries>this.uiOption.color).settingUseFl,
        },
      });

      this.update();
    }
  }

  public resetGaugeUserColorSet(item: any, index: number) {
    event.stopPropagation();

    const colorList = ChartColorList[(<UIChartColorByDimension>this.uiOption.color).schema];

    (<UIChartColorByDimension>this.uiOption.color).mapping[item.alias] = colorList[index];
    (<UIChartColorByDimension>this.uiOption.color).mappingArray[index]['color'] = colorList[index];

    this.uiOption = <UIOption>_.extend({}, this.uiOption, {
      color: {
        type: this.uiOption.color.type,
        schema: (<UIChartColorByDimension>this.uiOption.color).schema,
        mapping: (<UIChartColorByDimension>this.uiOption.color).mapping,
        mappingArray: (<UIChartColorByDimension>this.uiOption.color).mappingArray,
        settingUseFl: (<UIChartColorByDimension>this.uiOption.color).settingUseFl,
      },
    });

    this.update();
  }

  public changeGradations(data: Object) {
    this.uiOption.color['visualGradations'] = data['visualGradations'];

    data['ranges'].forEach((item) => {
      if (!item.type) {
        item.type = ColorRangeType.GRADIENT;
        return item;
      } else {
        return item;
      }
    });

    if (JSON.stringify(data['ranges']) != JSON.stringify(this.uiOption.color['ranges'])) {
      this.uiOption.color['ranges'] = data['ranges'];

      this.rangesViewList = this.uiOption.color['ranges'];
    }

    this.zone.run(() => {
      this.uiOption = <UIOption>_.extend({}, this.uiOption, {
        color: this.uiOption.color,
      });
    });

    this.changeDetect.detectChanges();

    this.$element.find('.ddp-box-color').find('.sp-replacer').removeClass('sp-active');

    this.$element
      .find('.ddp-box-color#ddp-box-color-' + data['currentSliderIndex'])
      .find('.sp-replacer')
      .addClass('sp-active');

    this.update();
  }

  public addGradientRange(currentIndex: number) {
    currentIndex = 0 !== currentIndex ? currentIndex - 1 : 0;

    this.gradationComp.addNewRangeIndex(currentIndex);
  }

  public deleteGradientRange(currentIndex: number) {
    this.$element.find('.ddp-box-color').find('.sp-replacer').removeClass('sp-active');

    this.gradationComp.unselectSlider();

    this.gradationComp.deleteRange(currentIndex);
  }

  public gradationColorSelected(colorStr: string, item: any) {
    const rgbColor = this.setHextoRgb(colorStr);

    this.gradationComp.changeGradationColor(item.index, rgbColor);
  }

  public showGradationColor(item, index) {
    if (undefined == this.gradationIndex && undefined !== item.value) {
      let top = $('.sp-container').not('.sp-hidden').offset().top;
      top += 30;
      $('.sp-container').not('.sp-hidden').css({ top: top });
    }

    this.gradationIndex = index;

    this.$element
      .find('.ddp-box-color')
      .not('#ddp-box-color-' + index)
      .find('.sp-replacer')
      .removeClass('sp-active');

    this.gradationComp.unselectSlider(item.index);
  }

  public hideGradationColor(index?: number) {
    if (!$(event.target).attr('class')) return;

    if (-1 !== $(event.target).attr('class').indexOf('gradx_slider')) {
      this.gradationIndex = undefined;
    } else if (
      -1 == $(event.target).attr('class').indexOf('ddp-input-txt') &&
      -1 == $(event.target).attr('class').indexOf('sp-preview-inner') &&
      -1 == $(event.target).attr('class').indexOf('ddp-icon-apply') &&
      -1 == $(event.target).attr('class').indexOf('ddp-list-blank')
    ) {
      this.$element.find('.ddp-box-color').find('.sp-replacer').removeClass('sp-active');

      this.changeDetect.detectChanges();

      this.gradationComp.unselectSlider();

      this.gradationIndex = undefined;
    } else if (null !== index && -1 !== $(event.target).attr('class').indexOf('ddp-input-txt')) {
      this.$element.find('#ddp-box-color-' + index + ' .sp-replacer').addClass('sp-active');
    }
  }

  public returnColorType(type: string) {
    switch (type) {
      case ChartColorType.SINGLE.toString():
        return this.translateService.instant('msg.space.ui.none');
      case ChartColorType.SERIES.toString():
        return this.translateService.instant('msg.page.li.color.series');
      case ChartColorType.DIMENSION.toString():
        return this.translateService.instant('msg.page.li.color.dimension');
      case ChartColorType.MEASURE.toString():
        return this.translateService.instant('msg.page.li.color.measure');
    }
  }

  public getDimensionIndex() {
    if (!this.uiOption.fielDimensionList) return;

    const index = _.findIndex(this.uiOption.fielDimensionList, { name: this.uiOption.color['targetField'] });

    return index;
  }

  public showMinInputColorRange(item, inputShow: boolean, minElement, index?: number) {
    event.stopPropagation();

    this.removeInputRangeStatus();

    item['minInputShow'] = inputShow;

    if (undefined !== index) {
      this.changeDetect.detectChanges();
      this.availableRange(item, index);
      $(minElement).trigger('focus');
    }
  }

  public showMaxInputColorRange(item, inputShow: boolean, maxElement, index?: number) {
    event.stopPropagation();

    this.removeInputRangeStatus();

    item['maxInputShow'] = inputShow;

    if (undefined !== index) {
      this.changeDetect.detectChanges();
      this.availableRange(item, index);
      $(maxElement).trigger('focus');
    }
  }

  private setHextoRgb(color: string): string {
    color = _.cloneDeep(color.replace('#', ''));

    const rColor = parseInt(color.substring(0, 2), 16);
    const gColor = parseInt(color.substring(2, 4), 16);
    const bColor = parseInt(color.substring(4), 16);

    return 'rgb(' + rColor + ',' + gColor + ',' + bColor + ')';
  }

  private setUserCodes(color: Object): Object {
    if (
      (!_.eq(ChartColorType.SERIES, this.uiOption.color.type) && !_.eq(ChartType.GAUGE, this.uiOption.type)) ||
      (_.eq(ChartType.GAUGE, this.uiOption.type) && !_.eq(ChartColorType.DIMENSION, this.uiOption.color.type)) ||
      !(<UIChartColorBySeries>this.uiOption.color).mapping
    )
      return;

    const colorList = ChartColorList[(<UIChartColorBySeries>this.uiOption.color).schema];
    (<UIChartColorBySeries>this.uiOption.color).mappingArray.forEach((item, index) => {
      if (_.eq(colorList[index], item['color'])) {
        const changedColorList = ChartColorList[color['colorNum']];

        (<UIChartColorBySeries>this.uiOption.color).mapping[item['alias']] = changedColorList[index];
        (<UIChartColorBySeries>this.uiOption.color).mappingArray[index]['color'] = changedColorList[index];
      }
    });

    return <UIChartColorBySeries>this.uiOption.color;
  }

  private setMapping(): UIChartColor {
    let chartColorList = [];

    if (-1 == (<UIChartColorBySeries>this.uiOption.color).schema.indexOf('VC')) {
      chartColorList = ChartColorList[(<UIChartColorBySeries>this.uiOption.color).schema];
    } else {
      chartColorList = <any>ChartColorList['SC1'];
    }

    if (!(<UIChartColorBySeries>this.uiOption.color).mapping)
      (<UIChartColorBySeries>this.uiOption.color).mapping = {} as { alias: string; color: string };

    if ((<UIChartColorBySeries>this.uiOption.color).schema) {
      let colorChangedFl = false;

      for (const key in (<UIChartColorBySeries>this.uiOption.color).mapping) {
        const index = _.findIndex(this.uiOption.fieldMeasureList, { alias: key });

        if (-1 == index || colorChangedFl) {
          delete (<UIChartColorBySeries>this.uiOption.color).mapping[key];
          colorChangedFl = true;
        }
      }

      this.uiOption.fieldMeasureList.forEach((item, index) => {
        if (
          (<UIChartColorBySeries>this.uiOption.color).schema &&
          !(<UIChartColorBySeries>this.uiOption.color).mapping[item.alias]
        ) {
          (<UIChartColorBySeries>this.uiOption.color).mapping[item.alias] = chartColorList[index];
        }
      });

      (<UIChartColorBySeries>this.uiOption.color).mappingArray = [];

      Object.keys((<UIChartColorBySeries>this.uiOption.color).mapping).forEach((key) => {
        (<UIChartColorBySeries>this.uiOption.color).mappingArray.push({
          alias: key,
          color: (<UIChartColorBySeries>this.uiOption.color).mapping[key],
        });
      });
    }

    return this.uiOption.color;
  }

  private setFieldList(): string[] {
    const getShelveReturnString = (shelve: any, typeList: ShelveFieldType[]): string[] => {
      const resultList: string[] = [];
      _.forEach(shelve, (value, key) => {
        shelve[key].map((item) => {
          if (_.eq(item.type, typeList[0]) || _.eq(item.type, typeList[1])) {
            resultList.push(item.name);
          }
        });
      });
      return resultList;
    };

    return getShelveReturnString(this.pivot, [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP]);
  }

  private setRangeViewByDecimal(ranges: ColorRange[]) {
    if (!ranges || 0 == ranges.length) return;

    const decimal = this.uiOption.valueFormat != null ? this.uiOption.valueFormat.decimal : 0;

    const commaUseFl = this.uiOption.valueFormat != null ? this.uiOption.valueFormat.useThousandsSep : false;

    const returnList: any = _.cloneDeep(ranges);

    for (const item of returnList) {
      item['fixMax'] =
        null == item.fixMax ? null : <any>FormatOptionConverter.getDecimalValue(item.fixMax, decimal, commaUseFl);
      item['fixMin'] =
        null == item.fixMin ? null : <any>FormatOptionConverter.getDecimalValue(item.fixMin, decimal, commaUseFl);
      item['gt'] = null == item.gt ? null : <any>FormatOptionConverter.getDecimalValue(item.gt, decimal, commaUseFl);
      item['lte'] = null == item.lte ? null : <any>FormatOptionConverter.getDecimalValue(item.lte, decimal, commaUseFl);
    }

    return returnList;
  }

  private parseStrFloat(range: any): any {
    range.fixMax = null == range.fixMax ? null : FormatOptionConverter.getNumberValue(range.fixMax);
    range.fixMin = null == range.fixMin ? null : FormatOptionConverter.getNumberValue(range.fixMin);
    range.gt = null == range.gt ? null : FormatOptionConverter.getNumberValue(range.gt);
    range.lte = null == range.lte ? null : FormatOptionConverter.getNumberValue(range.lte);
    return range;
  }

  private checkMinZero(minValue: number, elseValue: number) {
    let returnValue: number = elseValue;

    switch (this.uiOption.type) {
      case ChartType.BAR:
      case ChartType.LINE:
      case ChartType.SCATTER:
      case ChartType.BOXPLOT:
      case ChartType.COMBINE:
        if (minValue >= 0) returnValue = 0;
    }

    return returnValue;
  }

  private isNumberRegex(value: any): boolean {
    if (value.indexOf(',') != -1) {
      value = value.replace(/,/g, '');
    }
    return !isNaN(Number(value)); // Number(value) !== NaN;
  }

  private removeInputRangeStatus() {
    _.each(this.rangesViewList, (rangeVal) => {
      if (rangeVal['minInputShow']) delete rangeVal['minInputShow'];
      if (rangeVal['maxInputShow']) delete rangeVal['maxInputShow'];
    });
    if (!_.isUndefined(this.uiOption.color['ranges']) && this.uiOption.color['ranges'].length > 0) {
      _.each(this.uiOption.color['ranges'], (uiRangeVal) => {
        if (uiRangeVal['minInputShow']) delete uiRangeVal['minInputShow'];
        if (uiRangeVal['maxInputShow']) delete uiRangeVal['maxInputShow'];
      });
    }
  }
}
