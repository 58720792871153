import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakLoginOptions } from 'keycloak-js';

@Injectable({ providedIn: 'root' })
export class KeycloakHashTokenService extends KeycloakService {
  private tokenFromHash?: string;

  get hasTokenInUrlHash(): boolean {
    return location.hash.startsWith('#authToken=');
  }

  override getToken(): Promise<string> {
    if (this.hasTokenInUrlHash) {
      this.tokenFromHash = location.hash.split('=')[1];
      history.replaceState(null, '', location.pathname + location.search);
    }

    if (this.tokenFromHash) {
      return Promise.resolve(this.tokenFromHash);
    }

    return super.getToken();
  }

  override isLoggedIn(): Promise<boolean> {
    if (this.tokenFromHash) {
      return Promise.resolve(true);
    }
    return super.isLoggedIn();
  }

  override isUserInRole(role: string, resource?: string): boolean {
    if (this.tokenFromHash) {
      return true;
    }
    return super.isUserInRole(role, resource);
  }

  override login(options?: KeycloakLoginOptions | undefined): Promise<void> {
    if (this.hasTokenInUrlHash) {
      return Promise.resolve();
    }

    return super.login(options);
  }
}
