import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AlertService } from '@selfai-platform/shared';
import { DialogService } from '@selfai-platform/shell';
import { WorkflowListComponentService } from '../workflow-list-component.service';

@Component({
  selector: 'selfai-platform-workflow-upload-dialog',
  templateUrl: './workflow-upload-dialog.component.html',
  styleUrls: ['./workflow-upload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowUploadDialogComponent implements OnInit {
  url!: string;

  constructor(
    private readonly dialogService: DialogService<{ success?: boolean }>,
    private readonly alertService: AlertService,
    private readonly workflowListComponentService: WorkflowListComponentService,
  ) {}

  ngOnInit(): void {
    this.url = this.workflowListComponentService.getWorkflowFileUploadUrl();
  }

  onError(): void {
    this.alertService.error(`Error. Can't upload file`);
  }

  onUpload(): void {
    this.dialogService.close({ success: true });
  }
}
