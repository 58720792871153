import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { AxisType, UIChartAxis } from '@selfai-platform/bi-domain';

@Injectable()
export class YAxisOptionsService {
  private axisConfig$ = new BehaviorSubject<UIChartAxis>({
    type: AxisType.VALUE,
    logBase: 3,
  });

  getAxisConfig(): Observable<UIChartAxis> {
    return this.axisConfig$.asObservable();
  }

  setAxisConfig(config: UIChartAxis): void {
    this.axisConfig$.next(config);
  }

  setAxisConfigPartly(config: UIChartAxis) {
    this.setAxisConfig({ ...this.axisConfig$.value, ...config });
  }
}
