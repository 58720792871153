import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'selfai-platform-page-access-denied',
  templateUrl: './page-access-denied.component.html',
  styleUrls: ['./page-access-denied.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageAccesDeniedComponent {
  message?: string;

  constructor(private router: Router) {
    this.message = this.router.getCurrentNavigation()?.extras.state?.['message'];
  }
}
