export class UserSetting {
  theme: Theme;
  language: Language;

  public static getLanguage(lang: string): Language {
    if (lang === 'ko') {
      return Language.KO;
    } else if (lang === 'zh') {
      return Language.ZH;
    } else if (lang === 'ru') {
      return Language.RU;
    } else {
      return Language.EN;
    }
  }
}

export enum Theme {
  WHITE = '',
  DARK = 'theme-dark',
}

export enum Language {
  KO = 'ko',
  EN = 'en',
  ZH = 'zh',
  RU = 'ru',
}
