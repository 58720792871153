import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CheckPermissionDirectiveModule, CheckPermissionPipeModule } from '@selfai-platform/shared';
import { ConfirmationService } from 'primeng/api';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { WorkflowAddDialogComponentModule } from './workflow-add-dialog/workflow-add-dialog.module';
import { WorkflowCloneDialogComponentModule } from './workflow-clone-dialog/workflow-clone-dialog.module';
import { WorkflowListComponent } from './workflow-list.component';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    TooltipModule,
    ConfirmPopupModule,
    WorkflowCloneDialogComponentModule,
    WorkflowAddDialogComponentModule,
    AutoFocusModule,
    CheckPermissionDirectiveModule,
    CheckPermissionPipeModule,
    RouterModule.forChild([
      {
        path: '',
        component: WorkflowListComponent,
      },
    ]),
  ],
  providers: [ConfirmationService],
  declarations: [WorkflowListComponent],
  exports: [WorkflowListComponent],
})
export class WorkflowListComponentModule {}
