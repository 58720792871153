import { Injectable } from '@angular/core';
import {
  PipelinePermissionEntity,
  PermissionService,
  UserProfileService,
  WorkflowPermissionAction,
} from '@selfai-platform/shared';
import { map, Observable, withLatestFrom } from 'rxjs';
import { WorkflowDetailService } from './workflow-detail.service';

@Injectable({
  providedIn: 'root',
})
export class WorkflowPermissionService {
  get workflowPermissionsCore(): WorkflowPermissionAction[] {
    return Object.values(WorkflowPermissionAction);
  }

  constructor(
    private readonly workflowDetailService: WorkflowDetailService,
    private readonly permissionService: PermissionService,
    private readonly userProfileService: UserProfileService,
  ) {}

  getWorkflowPermissions(workflowId: string): Observable<WorkflowPermissionAction[]> {
    return this.workflowDetailService.loadWorkflow(workflowId).pipe(
      withLatestFrom(this.userProfileService.getUserProfile()),
      map(([workflow, userProfile]) => {
        const isOwner = workflow.workflowInfo.ownerId === userProfile.id;

        return this.workflowPermissionsCore.filter((permissionAction: WorkflowPermissionAction) => {
          return this.permissionService.checkPermission({
            entity: PipelinePermissionEntity.Workflows,
            action: permissionAction,
            isOwner,
          });
        });
      }),
    );
  }
}
