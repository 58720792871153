import { ModuleWithProviders, NgModule } from '@angular/core';

import { ChartAdapter, ChartApiService } from './services';

@NgModule()
export class ChartAdapterModule {
  static forRoot(): ModuleWithProviders<ChartAdapterModule> {
    return {
      ngModule: ChartAdapterModule,
      providers: [{ provide: ChartAdapter, useClass: ChartApiService }],
    };
  }
}
