import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { CommonModule } from '../common/common.module';
import { PermissionService } from '../user/service/permission.service';

import { CreateWorkspaceComponent } from './component/management/create-workspace.component';
import { WorkspaceListComponent } from './component/management/workspace-list.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [CreateWorkspaceComponent, WorkspaceListComponent],
  exports: [CreateWorkspaceComponent, WorkspaceListComponent],
  providers: [PermissionService],
})
export class WorkspaceShareModule {}
