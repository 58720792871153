import { HistoryEntity, UserDetail } from '../../../core';
import { Workspace } from '../../../workspace';
import { RoleSetScope, WORKSPACE_PERMISSION } from '../../enums';

import { Role } from './role.model';

export class RoleSet implements Partial<HistoryEntity> {
  id?: string;
  name?: string;
  description?: string;
  scope: RoleSetScope;
  linkedWorkspaces?: number;
  workspaces: Workspace[] = [];
  roles: Role[] = [];
  predefined: boolean;
  readOnly: boolean;

  removeRoleNames: string[] = [];

  version?: number;
  createdBy?: UserDetail;
  createdTime?: Date | string;
  modifiedBy?: UserDetail;
  modifiedTime?: Date | string;
  lastAccessTime?: Date | string;

  constructor() {
    this.scope = RoleSetScope.PRIVATE;
    this.predefined = false;
    this.readOnly = false;
    this.roles.push(RoleSet.getDefaultRole());
  }

  static getDefaultRole(): Role {
    const managerRole: Role = new Role();
    managerRole.name = 'Manager';
    managerRole.editName = 'Manager';
    managerRole.defaultRole = true;
    managerRole.permissionNames = [];

    Object.values(WORKSPACE_PERMISSION).forEach((permission) => {
      managerRole.permissionNames.push(permission);
    });

    return managerRole;
  }

  static convertRoleSetToParam(roleSet: RoleSet): any {
    const mapper: any = {};
    const param: any = {
      name: roleSet.name,
      description: roleSet.description,
      scope: roleSet.scope,
      roles: roleSet.roles.map((item: Role) => {
        item['orgName'] && item['orgName'] !== item.name && (mapper[item['orgName']] = item.name);
        return {
          name: item.name,
          defaultRole: item.defaultRole,
          permissionNames: item.permissionNames,
        };
      }),
    };

    if (0 < roleSet.removeRoleNames.length) {
      const defaultRole: Role = roleSet.roles.find((item) => item.defaultRole) as Role;
      roleSet.removeRoleNames.forEach((item) => (mapper[item] = defaultRole.name));
    }

    0 < Object.keys(mapper).length && (param['mapper'] = mapper);
    return param;
  }

  getRequestParam(): any {
    return {
      name: this.name,
      description: this.description,
      scope: this.scope,
      roles: this.roles.map((item: Role) => {
        return {
          name: item.name,
          defaultRole: item.defaultRole,
          permissionNames: item.permissionNames,
        };
      }),
    };
  }
}
