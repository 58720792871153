import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { CheckPermissionService } from '../../user/service/check-permission.service';
import { UserService } from '../../user/service/user.service';
import { PermissionType } from '../permission/permission-type';
import { PermissionZoneName } from '../permission/permission-zone';

@Injectable()
export class DatasourceManagementGuard implements CanActivate {
  constructor(
    private readonly userService: UserService,
    private readonly checkPermissionService: CheckPermissionService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise<boolean>((resolve, reject) => {
      this.userService
        .isLoggedIn()
        .then((loggedIn) => {
          const canActivate =
            loggedIn && this.checkPermissionService.hasPermisson(PermissionZoneName.DATASOURCE, PermissionType.VIEW);
          resolve(canActivate);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
