import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ItemUsedInNodes } from '@selfai-platform/pipeline-common';
import { WorkflowEditorFacadeservice } from 'libs/pipeline-module/src/lib/workflow-editor/workflow-editor-facade.service';

@Component({
  selector: 'selfai-platform-used-in-panel',
  templateUrl: './used-in-panel.component.html',
  styleUrls: ['./used-in-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsedInPanelComponent implements OnInit {

  @Input()
  usedInNodes: ItemUsedInNodes[] | undefined = undefined;

  constructor(private readonly workflowEditorService: WorkflowEditorFacadeservice) {
    
  }

  ngOnInit(): void {}

  navigateToNode(node: ItemUsedInNodes) {
    this.workflowEditorService.openNodeModaldialogUnselectedNode(node.id);
  }
}
