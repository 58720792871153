import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import moment from 'moment';
import { Subject, skip, takeUntil, tap } from 'rxjs';

import { DestroyService } from '../../../shared/services';
import { AbstractComponent } from '../abstract.component';

export enum PeriodType {
  ALL = <any>'ALL',
  TODAY = <any>'TODAY',
  LAST_WEEK = <any>'LAST_WEEK',
  NOT = <any>'NOT',
  YEAR = <any>'YEAR',
}

@Component({
  selector: 'component-period',
  templateUrl: './period.component.html',
  providers: [DestroyService],
})
export class PeriodComponent extends AbstractComponent implements OnInit {
  @Input() public selectedDate = 'CREATED';
  @Input() public containerClass = '';
  @Input() public title = 'Time';
  @Input() public withTime = true;
  @Input() public returnFormat: string;
  @Input() public isShowButtons = true;
  @Input() public useDefaultAllRange = false;
  @Input() public startDateDefault: any;
  @Input() public endDateDefault: any;
  @Input() public defaultType: PeriodType = PeriodType.ALL;
  @Input() public dateType = false;
  @Input() public roundSecond = false;
  @Input() public useAllButton?: boolean = true;
  @Input() public usedCustomDateType = false;
  @Input() public customDateTypeList: { label: string; value: string }[];
  @Input() public startPlaceholder?: string = 'msg.storage.ui.criterion.time.past';
  @Input() public endPlaceholder?: string = 'msg.storage.ui.criterion.time.current';

  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
    this.updateDisabledState();
  }

  get disabled(): boolean {
    return this._disabled;
  }


  @Output() public changeDate = new EventEmitter();

  public form = new FormGroup({
    from: new FormControl(null),
    to: new FormControl(null),
  });

  public periodType = PeriodType;
  public selectedType = PeriodType.ALL;

  private _startDate: Date;
  private _endDate: Date;
  private _disabled = false;

  constructor(
    protected elementRef: ElementRef,
    protected injector: Injector,
    private readonly destroy$: DestroyService,
  ) {
    super(elementRef, injector);

    this.form
      .get('from')
      .valueChanges.pipe(
        skip(1),
        tap((date) => {
          this._startDate = date;
          this.selectedType = PeriodType.NOT;
          this.validation(true);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.form
      .get('to')
      .valueChanges.pipe(
        skip(1),
        tap((date) => {
          this._endDate = date;
          this.selectedType = PeriodType.NOT;
          this.validation(false);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngAfterViewInit() {
    let isDefaultValue = false;
    if (this.startDateDefault && this.endDateDefault) {
      isDefaultValue = true;
    }

    if (this.returnFormat == null) {
      this.returnFormat = 'YYYY-MM-DDTHH:mm';
    }

    this.selectedType = this.defaultType ? this.defaultType : !isDefaultValue ? PeriodType.ALL : PeriodType.NOT;

    if (!this.useAllButton) {
      this.selectedType = PeriodType.TODAY;
      this.setToday();
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public setAll() {
    if (this.useDefaultAllRange && this.startDateDefault) {
      const startDate = moment(this.startDateDefault);
      this.form.get('from').setValue(startDate.toDate());
    } else {
      this.form.get('from').setValue(null);
    }

    if (this.useDefaultAllRange && this.endDateDefault) {
      const endDate = moment(this.endDateDefault);
      this.form.get('to').setValue(endDate.toDate());
    } else {
      this.form.get('to').setValue(null);
    }

    this.selectedType = PeriodType.ALL;

    this.done();
  }

  public setToday() {
    const startDate = moment({ hour: 0 });
    const endDate = moment({ hour: 23, minute: 59, seconds: 59 });
    this.form.get('from').setValue(startDate.toDate());
    this.form.get('to').setValue(endDate.toDate());

    this.selectedType = PeriodType.TODAY;

    this.done();
  }

  public setLastWeek() {
    const startDate = moment({ hour: 0 }).subtract(6, 'days');
    const endDate = moment({ hour: 23, minute: 59, seconds: 59 });
    this.form.get('from').setValue(startDate.toDate());
    this.form.get('to').setValue(endDate.toDate());

    this.selectedType = PeriodType.LAST_WEEK;

    this.done();
  }

  public done() {
    const returnData = this.getReturnData();

    this.changeDate.emit(returnData);
  }

  public getReturnData() {
    let startDateStr: string;
    if (this._startDate) {
      startDateStr = moment(this._startDate).format(this.returnFormat);
    } else {
      startDateStr = null;
    }

    let endDateStr: string = null;
    if (this._endDate) {
      if (this.roundSecond) {
        endDateStr = moment(this._endDate).add(59, 'seconds').format(this.returnFormat);
      } else {
        endDateStr = moment(this._endDate).format(this.returnFormat);
      }
    } else {
      endDateStr = null;
    }

    const returnData = {
      startDate: this._startDate,
      endDate: this._endDate,
      type: this.selectedType.toString(),
      startDateStr: startDateStr,
      endDateStr: endDateStr,
    };

    if (this.dateType || this.usedCustomDateType) {
      returnData['dateType'] = this.selectedDate;
    }

    return returnData;
  }

  public onChangeSelectedDateType(data: { label: string; value: string }) {
    this.selectedDate = data.value;
    this.changeDate.emit(this.getReturnData());
  }

  protected validation(isStart: boolean) {
    if (this._startDate && this._endDate && this._startDate.getTime() - this._endDate.getTime() > 0) {
      if (isStart) {
        this.form.get('to').setValue(this._startDate);
      } else {
        this.form.get('from').setValue(this._endDate);
      }
    }

    if (
      this.useDefaultAllRange &&
      moment(this.startDateDefault).isSame(this._startDate) &&
      moment(this.endDateDefault).isSame(this._endDate)
    ) {
      this.selectedType = PeriodType.ALL;
    }

    this.isShowButtons || this.done();
  }

  private updateDisabledState() {
    this._disabled ? this.form.get('from').disable() : this.form.get('from').enable();
    this._disabled ? this.form.get('to').disable() : this.form.get('to').enable();
  }
}
