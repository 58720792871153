import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SsoKeycloakGuard extends KeycloakAuthGuard {
  constructor(
    readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private readonly authService: AuthService,
  ) {
    super(router, keycloak);
  }

  async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }

    await this.authService.setCredentialsFromKeycloak();

    return true;
  }
}
